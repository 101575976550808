import Grid from "@material-ui/core/Grid";
import React from "react";
import SpText from "../../atoms/SpText";
import { psTranslate } from "../../../pages/shared/translations";
import HumanBodyBackUrl from "../../../assets/human_body_back.png";
import HumanBodyFrontUrl from "../../../assets/human_body_front.png";

//"react-to-print" package requires this to be a class component
class AssessmentPrintSheet extends React.Component {
  render() {
    return (
      <Grid container xs={12} direction="row" style={{ padding: "10px" }}>
        <Grid xs={8} item>
          {this.props.assessmentData?.map((item) => (
            <Grid
              key={item.id}
              container
              xs={12}
              style={{
                borderTop: "1px solid #000",
                padding: "8px",
                marginTop: "5px",
              }}
              direction="column"
            >
              <Grid item container direction="row" xs={12}>
                <Grid item xs={12}>
                  <SpText variant="textPrint">{psTranslate(item?.name)}</SpText>
                </Grid>
                {item?.assessment_elements?.map(
                  (currentAssessmentTemplateElement) => (
                    <Grid
                      key={currentAssessmentTemplateElement}
                      container
                      xs={12}
                      style={{ marginTop: "5px" }}
                    >
                      <Grid item xs={6} style={{ paddingRight: "5px" }}>
                        <SpText variant="h4ComponentLabelPrint">
                          {psTranslate(currentAssessmentTemplateElement)}
                        </SpText>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          border: "1px solid #000",
                          paddingLeft: "8px",
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid xs={4} container item direction="column">
          <img
            src={HumanBodyFrontUrl}
            alt={"Human body front"}
            style={{ objectFit: "contain", height: "400px" }}
          />
          <img
            src={HumanBodyBackUrl}
            alt={"Human body back"}
            style={{ objectFit: "contain", height: "400px" }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default AssessmentPrintSheet;
