import React, { useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels, psTranslate } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
import SpTextArea from "../../../components/atoms/SpTextArea";
import {
  saveAssessmentTemplate,
  updateAssessmentTemplate,
} from "../../../models/actions/Assessment";
import AddAssessmentTemplate from "../../../components/bundles/assessment/AddAssessmentTemplate";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { theme } from "../../../components/theme";
import ReactToPrint from "react-to-print";
import { getReferenceButton } from "./AddFunctionHelper";
const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddAssessment = ({ isClone, isHandbook, titleLabel, ...props }) => {
  const [templateDetailData, setTemplateDetailData] = useState(null);
  const [assessmentElements, setAssessmentElements] = useState({});
  const [showReferenceButton, setShowReferenceButton] = useState(false);
  const [name, setName] = useState("");
  const history = useHistory();
  const printRef = useRef();

  const { idElement } = useParams();
  const readOnly = idElement && !isClone;

  const { register, getValues, setValue } = useForm({
    shouldUnregister: false,
  });

  const editTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const data = {
        name: name,
        id_assessment_template: idElement,
        template_note: getValues("template_note"),
        template_reference: getValues("template_reference"),
      };
      const results = await updateAssessmentTemplate(data);
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.goBack();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const data = {};
      data.name = name;
      data.template_note = getValues("template_note");
      data.template_reference = getValues("template_reference");
      data.assessment_element_ids = []
        .concat(...Object.values(assessmentElements))
        .map((elem) => elem.id);
      data.is_handbook = isHandbook;
      const results = await saveAssessmentTemplate({ ...data });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.goBack();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const createHandbookTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const data = {};
      data.name = name;
      data.assessment_element_ids = []
        .concat(templateDetailData?.AssesmentElement)
        .map((elem) => elem.id);
      data.is_handbook = true;
      const results = await saveAssessmentTemplate({ ...data });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.goBack();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid
        direction="column"
        container
        spacing={2}
        style={{ paddingLeft: "1%" }}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.goBack()}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {titleLabel}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {readOnly && (
            <Grid
              item
              xs={isHandbook ? 4 : 2}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              {isHandbook && (
                <ReactToPrint
                  trigger={() => (
                    <SpButton
                      style={{ marginLeft: "2%" }}
                      text={labels.mylab.tempAdd.handbook.actions.print}
                      buttonType="accept"
                    />
                  )}
                  content={() => printRef.current}
                />
              )}
              <SpButton
                style={{ marginLeft: "2%" }}
                text={labels.mylab.tempAdd.assesment.actions.edit}
                buttonType="accept"
                onClick={() => (readOnly ? editTemplate : saveTemplate)()}
              />
            </Grid>
          )}
          {readOnly && !isHandbook && (
            <Grid
              item
              xs={2}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                text={labels.mylab.tempAdd.assesment.actions.createHandbook}
                buttonType="accept"
                onClick={() => createHandbookTemplate()}
              />
            </Grid>
          )}
          {!readOnly && Object.keys(assessmentElements).length > 0 && (
            <Grid
              item
              xs={4}
              spacing={0}
              style={{ paddingLeft: "0" }}
              container
              justify={"flex-end"}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                buttonType="accept"
                variant="h1"
                text={labels.mylab.tempAdd.assesment.actions.create}
                onClick={() => saveTemplate()}
              />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ padding: "8px", paddingTop: "1.6em" }}>
            <SpTextInput
              style={{ width: "100%" }}
              name={`name`}
              label={labels.mylab.tempAdd.assesment.inputs.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {!name && (
              <SpText variant="inputError">
                {labels.mylab.tempAdd.assesment.inputs.requiredName}
              </SpText>
            )}
          </Grid>
          {getReferenceButton({
            showReferenceButton,
            setShowReferenceButton,
            register,
            getValues,
          })}
          <Grid item xs={6} style={{ padding: "8px" }}>
            <SpTextArea
              name="template_note"
              id="template_note"
              label={psTranslate(
                labels.patient.monitoring.pathologiesStep.note
              )}
              inputRef={register}
            />
          </Grid>
        </Grid>

        <Divider
          style={{
            padding: "1px",
            margin: "2%",
            marginLeft: "0",
            width: "100%",
            backgroundColor: theme.colors.primary.lightBlue,
          }}
        />
        <AddAssessmentTemplate
          setTemplateDetailData={setTemplateDetailData}
          assessmentElements={assessmentElements}
          setAssessmentElements={setAssessmentElements}
          setName={setName}
          isClone={isClone}
          isHandbook={isHandbook}
          printRef={printRef}
          setValue={setValue}
        />
        {Object.keys(assessmentElements).length > 0 && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            {!readOnly && (
              <SpButton
                text={labels.mylab.tempAdd.treatment.actions.create}
                buttonType="accept"
                variant="h1"
                onClick={() => saveTemplate()}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddAssessment);
