import React from "react";
import { useParams } from "react-router-dom";
import {
  createExcerciseTemplate,
  getExerciseTemplateDetail,
  updateExerciseTemplate,
} from "../../models/actions/Excercise";
import {
  createProtocolTemplate,
  createRehabTemplate,
  createTrainingTemplate,
  getProtocolTemplateById,
  getRehabTemplateDetail,
  getTrainingTemplateById,
  updateProtocolTemplate,
  updateRehabTemplate,
  updateTrainingTemplate,
} from "../../models/actions/Activity";
import AddActivity from "./MyLabAddTemp/AddActivity";
import AddAssessment from "./MyLabAddTemp/AddAssessment";
import AddCommon from "./MyLabAddTemp/AddCommon";
import AddDysfunctionWeights from "./MyLabAddTemp/AddDysfunctionWeights";
import AddRiskFactor from "./MyLabAddTemp/AddRiskFactor";
import AddExercise from "./MyLabAddTemp/AddExercise";
import AssessmentTestDetails from "./MyLabAddTemp/AssessmentTestDetails";
import { labels } from "../shared/translations";

const MyLabAddTemp = ({ setLoading, isClone = false }) => {
  const { sectionName } = useParams();

  switch (sectionName) {
    case "activity-plan":
      return <AddActivity isClone={isClone} />;
    case "assesment":
      return (
        <AddAssessment
          setLoading={setLoading}
          isHandbook={false}
          isClone={isClone}
          titleLabel={labels.mylab.tempDetail.assesment.title}
          sectionName={sectionName}
        />
      );
    case "custom-exercise":
      return <AddExercise setLoading={setLoading} isClone={isClone} />;
    case "assessment-test":
      return <AssessmentTestDetails setLoading={setLoading} />;
    case "excercise":
      return (
        <AddCommon
          setLoading={setLoading}
          isClone={isClone}
          sectionName={sectionName}
          titleLabel={labels.mylab.tempDetail.excerciseSheet.title}
          templateRowsKey={"excercise_sheet_template_rows"}
          templateIdKey={"id_exercise_template"}
          getTemplateFn={getExerciseTemplateDetail}
          createTemplateFn={createExcerciseTemplate}
          updateTemplateFn={updateExerciseTemplate}
        />
      );
    case "training":
      return (
        <AddCommon
          setLoading={setLoading}
          isClone={isClone}
          sectionName={sectionName}
          titleLabel={labels.mylab.tempDetail.training.title}
          templateRowsKey={"row_training_templates"}
          templateIdKey={"id_training_template"}
          getTemplateFn={getTrainingTemplateById}
          createTemplateFn={createTrainingTemplate}
          updateTemplateFn={updateTrainingTemplate}
        />
      );
    case "treatment":
      return (
        <AddCommon
          setLoading={setLoading}
          isClone={isClone}
          sectionName={sectionName}
          titleLabel={labels.mylab.tempDetail.treatment.title}
          templateRowsKey={"protocol_template_rows"}
          templateIdKey={"id_protocol_template"}
          getTemplateFn={getProtocolTemplateById}
          createTemplateFn={createProtocolTemplate}
          updateTemplateFn={updateProtocolTemplate}
        />
      );
    case "rehab":
      return (
        <AddCommon
          setLoading={setLoading}
          isClone={isClone}
          sectionName={sectionName}
          titleLabel={labels.mylab.tempDetail.rehab.title}
          templateRowsKey={"rehab_template_rows"}
          templateIdKey={"id_rehab_template"}
          getTemplateFn={getRehabTemplateDetail}
          createTemplateFn={createRehabTemplate}
          updateTemplateFn={updateRehabTemplate}
        />
      );
    case "handbook":
      return (
        <AddAssessment
          setLoading={setLoading}
          isHandbook={true}
          isClone={isClone}
          titleLabel={labels.mylab.tempDetail.handbook.title}
          sectionName={sectionName}
        />
      );
    case "risk_factor":
      return <AddRiskFactor setLoading={setLoading} isClone={isClone} />;
    case "dysfunction_weights":
      return <AddDysfunctionWeights setLoading={setLoading} />;
    default:
      break;
  }
};

export default MyLabAddTemp;
