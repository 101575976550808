import React, { useEffect, useState } from "react";

export const createAccountFunction = async ({
  action,
  data,
  idProfessional,
  getValues,
  devicesProducer,
  setError,
  error,
  labels,
}) => {
  try {
    data.idProfessional = idProfessional;
    data.idProducer = getValues("idProducer");
    data.credentials = {};
    devicesProducer[data.idProducer].credentials.map((credential) => {
      if (!getValues(credential.name)) {
        setError(labels.gpsDevices.account.allDataAreRequired);
      } else {
        data.credentials[credential.name] = getValues(credential.name);
      }
    });
    if (!error) {
      data.idProfessional = idProfessional;
      data.idProducer = getValues("idProducer");
      const createdAccount = await action(data);
      return createdAccount;
    }
  } catch (error) {
    return { exception: "Error during execution" };
  }
};
