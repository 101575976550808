import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import SpText from "../../../../components/atoms/SpText";
import { SpList } from "../../../../components/bundles/SpList";
import { styled } from "../../../../components/styled";
import { theme } from "../../../../components/theme";
import { labels } from "../../../shared/translations";
import CommonRow from "./Single/CommonRow";

import { nanoid } from "nanoid";
import CommonRowTreatment from "./Single/CommonRowTreatment";

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const RenderRehabMinutesCount = ({ rows }) => {
  const minutesSum = rows.reduce(
    (prev, cur) => prev + (cur.duration ? parseInt(cur.duration) : 0),
    0
  );
  return (
    <SpText variant="h1PageSubtitleColored" style={{ display: "inline-block" }}>
      {isNaN(minutesSum) ? 0 : minutesSum}{" "}
      {labels.agenda.inputDetails.totalMins}
    </SpText>
  );
};

const CommonRows = ({ rows, updateRow, subactivities, sectionName }) => {
  rows = rows ?? [];
  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <RenderRehabMinutesCount rows={rows} />
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
      >
        <Grid item xs={12}>
          <SpList
            rows={rows}
            rowKey="id"
            checkable={false}
            deletable={false}
            HeaderRow={
              <StyledRowList container style={{ justifyContent: "flex-start" }}>
                {labels.mylab.tempAdd.treatment.list.header.map((head) => (
                  <Grid key={head} item xs={4}>
                    <SpText variant="listHeader">{head}</SpText>
                  </Grid>
                ))}
              </StyledRowList>
            }
          >
            {sectionName == "treatment" || sectionName == "rehab" ? (
              <CommonRowTreatment
                subactivities={subactivities}
                //row and index comes from SpList
                updateRow={updateRow}
              />
            ) : (
              <CommonRow
                //row and index comes from SpList
                updateRow={updateRow}
              />
            )}
          </SpList>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommonRows;
