import { Grid } from "@material-ui/core";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import printContext from "../../utils/printContext";
import Statistics from "./statistics/Statistics";
import { getGroupsById } from "../../models/actions/Groups";
const reference = createRef(null);

const GroupReports = ({ ...props }) => {
  const [isPrint, setIsPrint] = useState(false);
  const [fetchDataBe, setFetchDataBe] = useState(false);
  const [currentGroup, setCurrentGroup] = useState([]);

  const { groupId, date, activity, eventId } = useParams();

  const history = useHistory();

  useEffect(async () => {
    const groupResults = await getGroupsById({ id_group: groupId });
    setCurrentGroup(groupResults);
    localStorage.removeItem("pdfScreenshot");

    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
  }, []);

  useEffect(() => {
    // quando fetchData � a true significa che le modifiche sono terminate
    // quindi posso procedere con settare a true isPrint in modo da far partire
    // il trigger per la stampa del PDF
    if (fetchDataBe) {
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div ref={reference}>
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            style={{ paddingLeft: "1%" }}
            direction="column"
            container
            spacing={2}
          >
            {!isPrint && (
              <Grid item xs={12} container alignItems={"center"}>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  direction="row"
                  style={{ paddingLeft: "1%" }}
                >
                  <Grid item xs={12} container spacing={2} direction="row">
                    <Statistics
                      isPrint={isPrint}
                      date={date}
                      activity={activity}
                      groupId={groupId}
                      eventId={eventId}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </printContext.Provider>
  );
};

export default withSnackbar(GroupReports);
