import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextArea from "../../../components/atoms/SpTextArea";
import { styled } from "../../../components/styled";
import {
  createRiskFactorTemplate,
  getRegionAreaStructureLists,
  getRiskFactorTemplateById,
} from "../../../models/actions/Activity";
import {
  getAreasByRegion,
  getDisorders,
  getDysfunctionsCategoriesByDisorderTypeAndStructure,
  getStructuresByArea,
} from "../../../models/actions/Pathologies";
import { getSortedAndTranslatedArray, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddRiskFactor = (props) => {
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [structures, setStructures] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [riskFactorDetail, setRiskFactorDetail] = useState(false);
  const [disorderTypes, setDisorderTypes] = useState();
  const [dysfunctionsCategories, setDysfunctionsCategories] = useState([]);
  const [possibleDysfunctions, setPossibleDysfunctions] = useState([]);
  const [dysfunctions, setDysfunctions] = useState([]);
  const [structureSelected, setStructureSelected] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const { idElement } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, watch, handleSubmit, getValues, setValue, reset } =
    useForm({
      shouldUnregister: false,
    });

  const watchRegionsSelect = watch("id_region");
  const watchAreasSelect = watch("id_area");
  const watchDisorderTypeSelect = watch("id_disorder_type");
  const watchDysfunctionCategorySelect = watch("id_dysfunction_category");
  const watchDysfunction = watch("id_dysfunction");

  const fetchData = async () => {
    try {
      setLoading(true);
      if (idElement) {
        const result = await getRiskFactorTemplateById({
          id_template: idElement,
        });
        result?.structure_dysfunction && setRiskFactorDetail(result);
      } else {
        const result = await getRegionAreaStructureLists();
        if (result) {
          setRegions(getSortedAndTranslatedArray(result.regionList));
          setAreas(getSortedAndTranslatedArray(result.areaList));
          setStructures(getSortedAndTranslatedArray(result.structureList));
        }

        const currentDisorderTypes = await getDisorders();
        setDisorderTypes(currentDisorderTypes);
      }
    } catch (error) {
      rollbar.error("AddRiskFactor - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAreasByRegion = async () => {
    try {
      setAreas([]);
      setStructures([]);
      setDysfunctions([]);
      setDysfunctionsCategories([]);
      formClearFieldsAfter("id_region");

      const id_region = getValues("id_region");
      if (id_region) {
        const currentAreas = await getAreasByRegion({ id_region: id_region });
        setAreas(getSortedAndTranslatedArray(currentAreas));
      }
    } catch (error) {
      rollbar.error("AddRiskFactor - fetchAreasByRegion", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchStructuresByArea = async () => {
    try {
      setStructures([]);
      setDysfunctions([]);
      formClearFieldsAfter("id_area");

      const id_area = getValues("id_area");
      if (id_area) {
        const { structures } = await getStructuresByArea({ id_area: id_area });
        setStructures(getSortedAndTranslatedArray(structures));
      }
    } catch (error) {
      rollbar.error("AddRiskFactor - fetchStructuresByArea", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(() => {
    const foundRegion = regions.find((region) => region.key === regionSelected);
    setValue("id_region", foundRegion?.id);
  }, [regionSelected]);

  useEffect(() => {
    const foundArea = areas.find((area) => area.key === areaSelected);
    setValue("id_area", foundArea?.id);
  }, [areaSelected]);

  useEffect(async () => {
    await fetchAreasByRegion();
  }, [watchRegionsSelect]);
  useEffect(async () => {
    await fetchStructuresByArea();
  }, [watchAreasSelect]);
  useEffect(async () => {
    await fetchDysfunctionsCategoriesByDisorderTypeAndStructures();
  }, [watchDisorderTypeSelect]);
  useEffect(async () => {
    const newDysfunctions = possibleDysfunctions.filter(
      (dysfunction) =>
        dysfunction.id_dysfunction_category ==
        getValues("id_dysfunction_category")
    );
    setDysfunctions(newDysfunctions);
  }, [watchDysfunctionCategorySelect]);

  // useEffect(async () => {
  //     //Autofill if only 1 element
  //     if (dysfunctionsCategories.length === 1) setValue("id_dysfunction_category", dysfunctionsCategories[0].id);
  // }, [dysfunctionsCategories]);
  useEffect(() => {
    //Autofill if only 1 element
    if (dysfunctions.length === 1)
      setValue("id_dysfunction", dysfunctions[0].id);
  }, [dysfunctions]);

  useEffect(() => {
    setShowButton(
      (getValues("id_disorder_type") &&
        getValues("id_disorder_type") !== 1 &&
        getValues("id_dysfunction_category")) ||
        getValues("id_dysfunction")
    );
  }, [watchDysfunctionCategorySelect, watchDysfunction]);

  const fetchDysfunctionsCategoriesByDisorderTypeAndStructures = async () => {
    try {
      formClearFieldsAfter("id_disorder_type");

      const structure = getValues("id_structure");
      const disorder_type = getValues("id_disorder_type");
      if (structure && disorder_type) {
        const currentDysfunctionCategories =
          await getDysfunctionsCategoriesByDisorderTypeAndStructure({
            id_structure: structure,
            id_disorder_type: disorder_type,
          });
        const newDysfunctionCategories = currentDysfunctionCategories
          .map((obj) => {
            if (obj.dysfunction) return obj.dysfunction?.dysfunction_category;
          })
          .filter((x) => x);

        //Unique select of dysfunctions
        const newDysfunctionsSet = new Set();
        const newDysfunctions = currentDysfunctionCategories
          .filter((x) => x.dysfunction)
          .map((obj) => obj.dysfunction)
          .filter((elem) => {
            const keep = !newDysfunctionsSet.has(elem.id);
            newDysfunctionsSet.add(elem.id);
            return keep;
          });
        setDysfunctionsCategories(newDysfunctionCategories);
        setPossibleDysfunctions(newDysfunctions);
      }
    } catch (error) {
      rollbar.error(
        "AddRiskFactor - fetchDysfunctionsCategoriesByDisorderTypeAndStructures",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveRiskFactorTemplate = async (data) => {
    try {
      const result = await createRiskFactorTemplate({ ...data });
      if (result.message) {
        setAreas([]);
        setRegions([]);
        setStructures([]);
        setValue("id_region", null);
        setValue("name", null);
        setValue("id_area", null);
        setValue("id_structure", null);
        setValue("id_dysfunction_type", null);
        setValue("id_dysfunction", null);
        setValue("note", null);
        setValue("reference", null);
        props.snackbarShowMessage(result.message);
        history.push("/mylab/risk_factor");
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      rollbar.error("AddRiskFactor - saveRiskFactorTemplate", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const formClearFieldsAfter = (startField) => {
    const ids = [
      "",
      "id_region",
      "id_area",
      "id_structure",
      "id_disorder_type",
      "id_dysfunction_category",
      "id_dysfunction",
      "severity",
    ];
    const startId = ids.findIndex((elem) => elem === startField) + 1;
    if (startId !== 0)
      for (let i = startId; i < ids.length; i++) setValue(ids[i], null);
  };

  const LabelValue = (props) =>
    props.value ? (
      <Grid direction="column" xs={12} style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    ) : null;

  const DetailRiskFactor = (props) => (
    <Grid container xs={12}>
      <Divider
        style={{
          padding: "1px",
          width: "100%",
          backgroundColor: theme.colors.primary.lightBlue,
          marginTop: "1%",
          marginBottom: "1%",
        }}
      />
      <Grid
        container
        xs={12}
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
        }}
        direction="row"
      >
        <Grid item xs={6}>
          <LabelValue
            label={labels.mylab.riskFactor.detail.name}
            value={props.riskFactor?.name}
          />
        </Grid>

        <Grid item xs={6}>
          <LabelValue
            label={labels.mylab.riskFactor.detail.form.region}
            value={psTranslate(
              props.riskFactor?.structure_dysfunction?.structure?.area?.region
                ?.name
            ).toUpperCase()}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={labels.mylab.riskFactor.detail.form.area}
            value={psTranslate(
              props.riskFactor?.structure_dysfunction?.structure?.area?.name
            ).toUpperCase()}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={labels.mylab.riskFactor.detail.form.structure}
            value={psTranslate(
              props.riskFactor?.structure_dysfunction?.structure?.name
            ).toUpperCase()}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={labels.mylab.riskFactor.detail.form.dysfunction}
            value={psTranslate(
              props.riskFactor?.structure_dysfunction?.dysfunction?.name
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={labels.patient.monitoring.pathologiesStep.note}
            value={props.riskFactor?.note}
          />
        </Grid>

        <SpButton
          style={{
            marginTop: "2%",
            maxWidth: "100%",
            width: "25%",
          }}
          text={labels.mylab.reference}
          buttonType="accept"
          variant="none"
          onClick={() => {
            window.open(props.riskFactor?.reference, "_blank");
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab/risk_factor")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>
                {labels.mylab.tempDetail.riskFactor.title}
              </span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>

      {!idElement ? (
        <Grid item xs={12} style={{ marginTop: "2em" }} container spacing={3}>
          <Grid xs={3} item>
            <form onSubmit={handleSubmit(saveRiskFactorTemplate)}>
              <Grid container spacing={3}>
                <Grid item xs={12} container direction="row">
                  <Grid item style={{ width: "100%" }}>
                    <SpAutocomplete
                      formControlWidth={"100%"}
                      value={
                        regions.find((el) => el.key == regionSelected) ?? ""
                      }
                      displayLabel={false}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectRegion
                      }
                      onChange={(e, newValue) => {
                        setRegionSelected(newValue?.key);
                        setValue("id_region", newValue?.id);
                        formClearFieldsAfter("id_region");
                      }}
                      options={regions}
                      getOptionLabel={(option) => psTranslate(option.name)}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                    />
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <SpAutocomplete
                      formControlWidth={"100%"}
                      value={areas.find((el) => el.key == areaSelected) ?? ""}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectArea
                      }
                      onChange={(e, newValue) => {
                        setAreaSelected(newValue?.key);
                        setValue("id_area", newValue?.id);
                        formClearFieldsAfter("id_area");
                      }}
                      options={areas}
                      getOptionLabel={(option) => psTranslate(option.name)}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                    />
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <SpAutocomplete
                      formControlWidth={"100%"}
                      value={
                        structures.find((el) => el.id == structureSelected) ??
                        ""
                      }
                      selectPlaceholder={
                        labels.patient.presentation.add.selectStructure
                      }
                      onChange={(e, newValue) => {
                        setStructureSelected(newValue?.id);
                        setValue("id_structure", newValue?.id);
                        formClearFieldsAfter("id_structure");
                      }}
                      options={structures}
                      getOptionLabel={(option) => psTranslate(option.name)}
                      getOptionSelected={(option, value) =>
                        option.id === value?.id
                      }
                    />
                  </Grid>
                  {getValues("id_structure") && (
                    <Grid item style={{ width: "100%" }}>
                      <Controller
                        render={(props) => (
                          <SpAutocomplete
                            formControlWidth={"100%"}
                            selectPlaceholder={
                              labels.mylab.riskFactor.add.select.disorder
                            }
                            onChange={(e, newValue) => {
                              props.onChange(newValue?.id);
                              formClearFieldsAfter("id_disorder_type");
                            }}
                            options={disorderTypes}
                            getOptionLabel={(option) =>
                              psTranslate(option.name)
                            }
                            getOptionSelected={(option, value) =>
                              option.id === value?.id
                            }
                          />
                        )}
                        defaultValue={""}
                        name={`id_disorder_type`}
                        control={control}
                      />
                    </Grid>
                  )}
                  {getValues("id_disorder_type") && (
                    <Grid item style={{ width: "100%" }}>
                      <Controller
                        render={(props) => (
                          <SpAutocomplete
                            formControlWidth={"100%"}
                            selectPlaceholder={
                              labels.mylab.riskFactor.add.select
                                .dysfunction_category
                            }
                            onChange={(e, newValue) => {
                              props.onChange(newValue?.id);
                              formClearFieldsAfter("id_dysfunction_category");
                            }}
                            options={dysfunctionsCategories}
                            getOptionLabel={(option) =>
                              psTranslate(option.name)
                            }
                            getOptionSelected={(option, value) =>
                              option.id === value?.id
                            }
                          />
                        )}
                        defaultValue={""}
                        name={`id_dysfunction_category`}
                        control={control}
                      />
                    </Grid>
                  )}
                  {getValues("id_disorder_type") === 1 &&
                    getValues("id_dysfunction_category") && (
                      <Grid item style={{ width: "100%" }}>
                        <Controller
                          render={(props) => (
                            <SpAutocomplete
                              formControlWidth={"100%"}
                              selectPlaceholder={
                                labels.mylab.riskFactor.add.select.dysfunction
                              }
                              onChange={(e, newValue) => {
                                props.onChange(newValue?.id);
                                formClearFieldsAfter("id_dysfunction");
                              }}
                              options={dysfunctions}
                              getOptionLabel={(option) =>
                                psTranslate(option.name)
                              }
                              getOptionSelected={(option, value) =>
                                option.id === value?.id
                              }
                            />
                          )}
                          defaultValue={""}
                          name={`id_dysfunction`}
                          control={control}
                        />
                      </Grid>
                    )}
                  {(getValues("id_dysfunction") ||
                    (getValues("id_disorder_type") !== 1 &&
                      getValues("id_dysfunction_category"))) && (
                    <>
                      <Grid item style={{ width: "100%", marginTop: "10px" }}>
                        <Controller
                          render={(props) => (
                            <SpTextArea
                              disabled={idElement}
                              name={"note"}
                              label={labels.mylab.riskFactor.add.select.note}
                              style={{ width: "100%" }}
                              inputRef={register}
                            />
                          )}
                          defaultValue={""}
                          name={`note`}
                          control={control}
                        />
                      </Grid>{" "}
                      <Grid item style={{ width: "100%", marginTop: "10px" }}>
                        <Controller
                          render={(props) => (
                            <SpTextArea
                              disabled={idElement}
                              name={"reference"}
                              label={labels.mylab.reference}
                              style={{ width: "100%" }}
                              inputRef={register}
                            />
                          )}
                          defaultValue={""}
                          name={`reference`}
                          control={control}
                        />
                      </Grid>
                    </>
                  )}
                  {showButton && (
                    <Grid item style={{ width: "100%" }}>
                      {!idElement && (
                        <SpButton
                          style={{
                            marginTop: "2%",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          text={labels.mylab.tempAdd.risk_factor.actions.create}
                          buttonType="accept"
                          variant="none"
                          onClick={() => handleSubmit(saveRiskFactorTemplate)()}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={9} style={{ minHeight: "500px", maxHeight: "800px" }}>
            <Sp3DModel
              modelBehaviour={"INFORMATIVE"}
              type="PATIENT"
              setSelectedRegion={setRegionSelected}
              setSelectedArea={setAreaSelected}
              selectedRegion={regionSelected}
              selectedArea={areaSelected}
              hideFilters={true}
            />
          </Grid>
        </Grid>
      ) : (
        <DetailRiskFactor riskFactor={riskFactorDetail} />
      )}
    </Grid>
  );
};

export default withSnackbar(AddRiskFactor);
