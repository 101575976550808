import { Chip, Grid } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { createFilterOptions } from "@material-ui/lab";
import React from "react";
import { SpAutocomplete } from "../../../../../components/atoms/SpAutocomplete";
import SpIconButton from "../../../../../components/atoms/SpIconButton";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { theme } from "../../../../../components/theme";
import { labels, psTranslate } from "../../../../shared/translations";

const ExerciseCategoryRow = ({
  exercises,
  exerciseCategory,
  updateExerciseCategory,
  removeExerciseCategory,
}) => {
  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) => option.name + option.tags,
  });

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        border: `1px solid ${theme.colors.primary.lightBlue}`,
        padding: "25px",
      }}
    >
      <Grid item container>
        <Grid item xs={11}>
          <Grid item xs={12}>
            <SpTextInput
              label={labels.mylab.tempAdd.exercise.name}
              style={{ width: "100%" }}
              value={exerciseCategory.name}
              onChange={(evnt) =>
                updateExerciseCategory("name", evnt.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SpAutocomplete
              multiple
              formControlWidth={"100%"}
              value={exerciseCategory.id_exercises}
              displayLabel={true}
              label={labels.mylab.tempAdd.exercise.categories}
              selectPlaceholder={labels.mylab.tempAdd.exercise.categories}
              onChange={(e, newValue) => {
                updateExerciseCategory(
                  "id_exercises",
                  newValue.map((val) => val?.id ?? val)
                );
              }}
              filterOptions={filterOptions}
              options={exercises}
              getOptionLabel={(option) => psTranslate(option.name)}
              getOptionSelected={(option, value) => option?.id === value}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: "white",
                    }}
                    label={psTranslate(
                      exercises.find((ex) => option === ex.id)?.name ?? ""
                    )}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <SpIconButton
            variant="lightBlueFill"
            onClick={() => removeExerciseCategory()}
          >
            <DeleteForever />
          </SpIconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExerciseCategoryRow;
