import { Avatar, Button as MuiButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import "../../App.css";

import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpTextInput from "../../components/atoms/SpTextInput";
import { styled } from "../../components/styled";
import SpButton from "../../components/atoms/SpButton";
import GroupEditSidebar from "./shared/GroupEditSidebar";

import {
  getGroupsById,
  updateGroup,
  updateGroupAvatar,
} from "../../models/actions/Groups";
import {
  getProfessionalById,
  getResourceFromServer,
} from "../../models/actions/Professionals";
import { getUserPermission, rollbar } from "../../utils/common";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
import CreateIcon from "@material-ui/icons/Create";
import SpIconButton from "../../components/atoms/SpIconButton";
import { uploadFile } from "../../models/actions/awsUtils";
import { UPLOAD_LIMIT, loadFile } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "../../components/theme";
import PatientsGroup from "./PatientsGroup";
import ProfessionalsGroup from "./ProfessionalsGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
}));

const GroupsDetail = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentGroup, setCurrentGroup] = useState([]);
  const [currentProfessionalRole, setCurrentProfessionalRole] = useState(null);
  const [profileImage, setProfileImg] = useState(null);
  const [editGroupName, setEditGroupName] = useState(false);
  const [isFederation, setIsFederation] = useState(false);
  const { groupId } = useParams();
  const classes = useStyles();
  const { setLoading } = props;
  const history = useHistory();
  const { reset, control, register, handleSubmit, getValues, setValue } =
    useForm({
      shouldUnregister: false,
    });

  const fetchData = async () => {
    try {
      setLoading(true);
      const permissionFederationGroups = await getUserPermission(
        "manage:federation-groups",
        getAccessTokenSilently
      );
      setIsFederation(permissionFederationGroups);

      const ProfessionalProfile = await getProfessionalById();
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);

      if (groupResults.id_file) {
        const profileBase64 = await getResourceFromServer({
          id_file: groupResults.id_file,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }

      const formattedProfessionalArray = [];
      groupResults.professionals.map((prof) => {
        if (prof.id == ProfessionalProfile.id)
          setCurrentProfessionalRole(prof.professional_group.role);
        formattedProfessionalArray.push({
          id: prof.id,
          professional: prof,
          role: prof.professional_group.role,
        });
      });
      reset({
        id: groupResults?.id,
        name: groupResults.name,
        description: groupResults.description,
        patients: groupResults.patients,
        professionals: formattedProfessionalArray,
      });
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const uploadProfileImage = async (file) => {
    if (file.size / (1024 * 1024) > UPLOAD_LIMIT) {
      throw labels.patient.resourcesUpload.errors.uploadLimit;
    }
    await uploadFile(file);

    try {
      //setting up the file to be uploaded
      if (file) {
        await uploadFile(file);
        await updateGroupAvatar({
          id_group: groupId,
          fileName: file.name,
          fileSize: file.size,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
        });
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("PatientsDetail - uploadProfileImage", error);
    }
  };

  const onFileChange = (e) => {
    uploadProfileImage(e.target.files[0]);
    fetchData();
  };

  const handleClick = (event) => {
    if (profileImage) {
      event.preventDefault();
      setProfileImg(null);
    }
  };

  const updateGroupData = async (data) => {
    try {
      const results = await updateGroup(data);
      props.snackbarShowMessage(results.message);
      setEditGroupName(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - updateGroupData", error);
      setEditGroupName(false);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
              sectionName={labels.group.sideBarGroup.general}
            />
          </Grid>
          <Grid item xs={12} container spacing={1} direction="column">
            <form onSubmit={handleSubmit(updateGroupData)}>
              <Grid item xs={12} container spacing={1} direction="column">
                <Grid item xs={12}>
                  <Grid item container direction="row">
                    <Grid item xs={4}>
                      <Avatar
                        imgProps={{
                          style: {
                            width: "100%",
                            mHeight: "100%",
                            maxWidth: "100%",
                          },
                        }}
                        className={classes.largeAvatar}
                        src={profileImage ? profileImage : ""}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      {!editGroupName && (
                        <>
                          {!editGroupName && (
                            <SpIconButton
                              style={{ width: "auto", height: "auto" }}
                              alignSelf="auto"
                              color={theme.colors.primary.lightBlue}
                              variant="raised"
                              onClick={() => {
                                setEditGroupName(true);
                              }}
                              small
                            >
                              <SpText
                                style={{ paddingTop: "4em" }}
                                variant={"h1GroupTitle"}
                              >
                                {getValues("name")}
                              </SpText>{" "}
                              <CreateIcon />
                            </SpIconButton>
                          )}
                        </>
                      )}
                      {editGroupName && (
                        <SpTextInput
                          style={{
                            width: "100%",
                            paddingTop: "4em",
                          }}
                          disabled={currentProfessionalRole !== "admin"}
                          label={
                            !isFederation
                              ? labels.groups.groupDetail.name
                              : labels.groups.groupDetail.federationName
                          }
                          name="name"
                          inputRef={register}
                        />
                      )}
                      {editGroupName && (
                        <SpButton
                          buttonType={"accept"}
                          type="submit"
                          disabled={currentProfessionalRole !== "admin"}
                          variant="none"
                          text={labels.groups.groupDetail.actions.save}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {currentProfessionalRole == "admin" && (
                    <>
                      <input
                        accept="image/*"
                        hidden
                        id="avatar-image-upload"
                        type="file"
                        name="file"
                        onChange={onFileChange}
                      />
                      <label
                        htmlFor="avatar-image-upload"
                        style={{
                          color: "white",
                          textAlign: "flex-end",
                          margin: "0 auto",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="white"
                          component="span"
                          mb={2}
                          onClick={handleClick}
                        >
                          <UploadIcon />
                        </Button>
                      </label>
                    </>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <ProfessionalsGroup
            snackbarShowErrorMessage={props.snackbarShowErrorMessage}
            snackbarShowMessage={props.snackbarShowMessage}
            setLoading={props.setLoading}
            loading={props.loading}
          />
          <PatientsGroup
            props={props}
            snackbarShowErrorMessage={props.snackbarShowErrorMessage}
            snackbarShowMessage={props.snackbarShowMessage}
            setLoading={props.setLoading}
            loading={props.loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(GroupsDetail);
