import React from "react";
import {
  getTrainingMinutesFromGroups,
  selectPatientsInjuryRate as selectPatientsTrainingInjuryRate,
  selectedGroupsInjuryRate as selectedGroupsTrainingInjuryRate,
} from "./TrainingInjuryFunction";

import { filterActivity, filterGroupByType } from "./SevereInjuryFunction";
import {
  selectedGroupsDaysAbsence as selectedGroupsDaysAbsenceTraining,
  selectPatientsDaysAbsence as selectPatientsDaysAbsenceTraining,
  selectPatientsInjuryBurden as selectPatientsInjuryBurdenTraining,
  selectedGroupsBurder as selectedGroupsBurderTraining,
} from "./TrainingInjuryFunction";

import {
  selectedGroupsInjury as selectedGroupsInjuryGeneral,
  selectPatientsInjury as selectPatientsInjuryGeneral,
  injuryGroupsByGravity as injuryGroupsByGravityGeneral,
  injuryPatientsByGravity as injuryPatientsByGravityGeneral,
  injuryPatientsByReinjury as injuryPatientsByReinjuryGeneral,
  injuryGroupsByReinjury as injuryGroupsByReinjuryGeneral,
  createBarschartDataPoints,
} from "./GeneralInjuryFunction";
import { matchTrainingArrayUnion } from "./InjuryReportCommonFunction";
import { labels } from "../../../shared/translations";
import {
  barColors,
  barWithDimension,
  titleCase,
} from "../../../../utils/common";

const gravityRange = labels.analytics.injuryReport.gravityRange;
const injuryRateLabel = labels.analytics.injuryReport.graphs.injuryRate;

const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const trainingInjuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;

export const filterByTissueType = (idTissueType, patients) => {
  let patientsFiltered = [];
  patients.forEach((patient) => {
    let injuriesFilteredByTissue = [];
    patient?.injuries.forEach((injury) => {
      if (
        injury?.pathology &&
        injury?.pathology?.id_tissue_type === idTissueType
      )
        injuriesFilteredByTissue.push(injury);
    });
    let patientFiltered = patient;
    patientFiltered.injuries = injuriesFilteredByTissue;
    patientsFiltered.push(patientFiltered);
  });
  return patientsFiltered;
};

export const injuryGroupsByGravity = (
  injuriesPatient,
  gravities,
  isAvarageEnabled,
  idTissueType
) => {
  injuriesPatient.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  return injuryGroupsByGravityGeneral(
    injuriesPatient,
    gravities,
    isAvarageEnabled
  );
};
export const injuryPatientsByGravity = (
  selectedInjuryPatient,
  gravities,
  idTissueType
) => {
  selectedInjuryPatient = filterByTissueType(
    idTissueType,
    selectedInjuryPatient
  );
  return injuryPatientsByGravityGeneral(selectedInjuryPatient, gravities);
};
export const injuryPatientsByReinjury = (
  selectedInjuryPatient,
  reinjuries,
  idTissueType
) => {
  selectedInjuryPatient = filterByTissueType(
    idTissueType,
    selectedInjuryPatient
  );

  return injuryPatientsByReinjuryGeneral(selectedInjuryPatient, reinjuries);
};

export const injuryGroupsByReinjury = (
  injuriesPatient,
  reinjuries,
  isAvarageEnabled,
  idTissueType
) => {
  injuriesPatient.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  return injuryGroupsByReinjuryGeneral(
    injuriesPatient,
    reinjuries,
    isAvarageEnabled
  );
};

export const selectPatientsInjury = (
  newPatientsSelected,
  regionSelected,
  areaSelected,
  idTissueType
) => {
  // filtro gli infortuni con id tissue type pari a quello di muscle
  newPatientsSelected = filterByTissueType(idTissueType, newPatientsSelected);
  return selectPatientsInjuryGeneral(
    newPatientsSelected,
    regionSelected,
    areaSelected
  );
};

export const selectedGroupsInjury = (
  newSelectedGroups,
  regionSelected,
  areaSelected,
  idTissueType
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });

  return selectedGroupsInjuryGeneral(
    newSelectedGroups,
    regionSelected,
    areaSelected
  );
};

export const selectPatientsInjuryRate = async (
  selectedPatients,
  groups,
  dateRange,
  idTissueType
) => {
  let resultArray = [];
  let bars = [];
  const barColorValueTemp = barColors();
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);
  const activities = await filterActivity(groups, dateRange);
  for (let patient of selectedPatients) {
    let item = {
      hours: 0,
      groupDate: titleCase(`${patient?.givenName} ${patient?.familyName}`),
      injuries: 0,
      [injuryRateLabel]: 0,
    };
    const minutes = getTrainingMinutesFromGroups(activities, patient);
    item.hours = minutes && minutes !== 0 ? minutes / 60 : 0;

    item.injuries = patient?.injuries.length;
    item[injuryRateLabel] =
      item.hours === 0
        ? 1000 * item.injuries
        : ((1000 * item.injuries) / item.hours).toFixed(1);
    resultArray.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const selectedGroupsInjuryRate = async (
  newSelectedGroups,
  dateRange,
  idTissueType
) => {
  const barColorValueTemp = barColors();
  const activities = await filterActivity(newSelectedGroups, dateRange);
  let resultArray = [];
  let bars = [];

  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
    let patientResultArray = [];

    for (let patient of group.patients) {
      let item = {
        hours: 0,
        groupDate: titleCase(`${patient?.givenName} ${patient?.familyName}`),
        injuries: 0,
      };
      item.hours = getTrainingMinutesFromGroups(activities, patient) / 60;
      item.injuries = patient?.injuries.length;

      item[injuryRateLabel] =
        item.hours === 0
          ? 1000 * item.injuries
          : ((1000 * item.injuries) / item.hours).toFixed(1);
      patientResultArray.push(item);
    }

    let endItem = {
      hours: 0,
      groupDate: titleCase(`${group?.name}`),
      injuries: 0,
    };
    for (let item of patientResultArray) {
      endItem = {
        ...endItem,
        hours: endItem.hours + item.hours,
        injuries: endItem.injuries + item.injuries,
      };
    }
    endItem[injuryRateLabel] =
      endItem.hours === 0
        ? 1000 * endItem.injuries
        : ((1000 * endItem.injuries) / endItem.hours).toFixed(1);
    resultArray.push(endItem);
  });
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

const trainingMatchAbsenceArrayUnion = (trainingArray, matchArray) => {
  let dataChart = [];
  if (trainingArray.dataChart) {
    trainingArray.dataChart.forEach((data) => {
      const matchPatient = matchArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      if (matchPatient !== undefined) {
        let result = {
          groupDate: data.groupDate,
        };
        result[daysAbsenceLabel] =
          data[daysAbsenceLabel] + matchPatient[daysAbsenceLabel];

        dataChart.push(result);
      } else {
        dataChart.push(data);
      }
    });

    // 2- unisco i dati di match con training
    matchArray.dataChart.forEach((data) => {
      const trainingPatient = trainingArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      // solo se non e' gia' stato fatto l'inverso posso considerare
      if (
        dataChart.find(({ groupDate }) => groupDate === data.groupDate) ===
        undefined
      ) {
        if (trainingPatient !== undefined) {
          let result = {
            groupDate: data.groupDate,
          };
          result[daysAbsenceLabel] =
            data[daysAbsenceLabel] + trainingPatient[daysAbsenceLabel];
          dataChart.push(result);
        } else {
          dataChart.push(data);
        }
      }
    });
  }
  return dataChart;
};

export const selectedGroupsDaysAbsence = (newSelectedGroups, idTissueType) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });

  const daysAbsence = selectedGroupsDaysAbsenceTraining(newSelectedGroups);

  const result = {
    barChart: daysAbsence.barChart ? daysAbsence.barChart : {},
    dataChart: daysAbsence.dataChart,
  };
  return result;
};

export const selectPatientsDaysAbsence = (selectedPatients, idTissueType) => {
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);

  const daysAbsence = selectPatientsDaysAbsenceTraining(selectedPatients);

  const result = {
    barChart: daysAbsence.barChart ? daysAbsence.barChart : {},
    dataChart: daysAbsence.dataChart,
  };
  return result;
};

const burdenTrainingMatchUnion = (trainingArray, matchArray) => {
  let dataChart = [];

  // 1- unisco i dati di training con match
  if (trainingArray.dataChart) {
    trainingArray.dataChart.forEach((data) => {
      const matchPatient = matchArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      if (matchPatient !== undefined) {
        let result = {
          groupDate: data.groupDate,
          minutes: data.minutes,
          days: data.days + matchPatient.days,
        };
        result[trainingInjuriesBurdenLabel] =
          parseFloat(data[trainingInjuriesBurdenLabel]) +
          parseFloat(matchPatient[trainingInjuriesBurdenLabel]);
        dataChart.push(result);
      } else {
        dataChart.push(data);
      }
    });

    // 2- unisco i dati di match con training
    matchArray.dataChart.forEach((data) => {
      const trainingPatient = trainingArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      // solo se non e' gia' stato fatto l'inverso posso considerare
      if (
        dataChart.find(({ groupDate }) => groupDate === data.groupDate) ===
        undefined
      ) {
        if (trainingPatient !== undefined) {
          let result = {
            groupDate: data.groupDate,
            minutes: data.minutes,
            days: data.days + trainingPatient.days,
          };
          result[trainingInjuriesBurdenLabel] =
            parseFloat(data[trainingInjuriesBurdenLabel]) +
            parseFloat(trainingPatient[trainingInjuriesBurdenLabel]);
          dataChart.push(result);
        } else {
          dataChart.push(data);
        }
      }
    });
  }
  return dataChart;
};

export const selectPatientsInjuryBurden = async (
  selectedPatients,
  groups,
  dateRange,
  idTissueType
) => {
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);
  // prendo i valori del injury ratio
  const groupFiltered = await filterGroupByType(groups, dateRange);

  const trainingBurder = selectPatientsInjuryBurdenTraining(
    selectedPatients,
    groupFiltered
  );

  const result = {
    barChart: trainingBurder.barChart ? trainingBurder.barChart : {},
    dataChart: trainingBurder.dataChart,
  };
  return result;
};

export const selectedGroupsBurder = async (
  newSelectedGroups,
  dateRange,
  idTissueType
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  // prendo i valori del injury ratio
  const groupFiltered = await filterGroupByType(newSelectedGroups, dateRange);

  const groupBurder = selectedGroupsBurderTraining(groupFiltered);

  const result = {
    barChart: groupBurder.barChart ? groupBurder.barChart : {},
    dataChart: groupBurder.dataChart,
  };

  return result;
};
