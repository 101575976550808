import React from "react";
import backgroundImageUrl from "../../../assets/icon/card_bg_1.png";

export const ThresholdCard = ({
  title,
  description,
  value,
  perc,
  threshold1,
  threshold2,
  threshold3,
  ava = false,
  isSelected = false,
}) => {
  // Determine the background color based on the value
  let backgroundColor;

  if (ava === false) {
    if (perc >= threshold3) {
      backgroundColor = "rgba(255, 0, 0, 0.3)";
    } else if (perc >= threshold2) {
      backgroundColor = "rgba(255, 255, 0, 0.3)";
    } else if (perc >= threshold1) {
      backgroundColor = "rgba(0, 255, 0, 0.15)";
    } else {
      backgroundColor = "rgba(0, 255, 0, 0.3)";
    }
  } else {
    if (perc >= threshold3) {
      backgroundColor = "rgba(0, 255, 0, 0.3)";
    } else if (perc >= threshold2) {
      backgroundColor = "rgba(255, 255, 0, 0.15)";
    } else if (perc >= threshold1) {
      backgroundColor = "rgba(255, 255, 0, 0.3)";
    } else {
      backgroundColor = "rgba(255, 0, 0, 0.3)";
    }
  }

  // Set the background image URL

  return (
    <div
      style={{
        display: "flex",
        border: `${isSelected ? "5px" : "1px"} solid #ccc`,
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        margin: "16px 0",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // minWidth: "20vw",
        backgroundColor: backgroundColor,
        boxSizing: "border-box",
        background: `linear-gradient(${backgroundColor}, ${backgroundColor}), url(${backgroundImageUrl}) center / cover no-repeat`,
      }}
    >
      <div
        style={{
          flex: "1",
          padding: "16px",
          margin: "0",
          justifyContent: "flex-start",
        }}
      >
        <h1
          style={{
            fontSize: "1.2em",
            marginBottom: "8px",
            color: "#fff",
            margin: "0",
          }}
        >
          {title}
        </h1>
        <p style={{ color: "#fff", margin: "0" }}>{description}</p>
      </div>
      <div
        style={{
          alignSelf: "flex-end",
          padding: "16px",
          textAlign: "right",
          margin: "0",
        }}
      >
        <p
          style={{
            fontSize: "3em",
            color: "#fff",
            fontWeight: "bold",
            margin: "0",
          }}
        >
          {`${value} (${perc}%)`}
        </p>
      </div>
    </div>
  );
};
