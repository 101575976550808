import moment from "moment";
import React from "react";

export const mapAssessmentForExcelExport = (assessmentInput) => {
  let objetToExcel = {};

  const dates = assessmentInput.dates;

  assessmentInput.categories.map((item) => {
    const columns = item.columns;
    objetToExcel[item.name] = [];
    item.elements.forEach((element) => {
      let tempElement = { Nome: element.name };
      dates.forEach((date) => {
        tempElement = {
          ...tempElement,
          Data: moment(date).format("DD-MM-YYYY"),
        };
        columns.forEach((column) => {
          tempElement = {
            ...tempElement,
            [column]: element.answers[`${date}_${column}`]?.answer,
          };
        });
        objetToExcel[item.name].push(tempElement);
      });
    });
  });
  return objetToExcel;
};
