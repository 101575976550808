import React, { forwardRef, useEffect, useState } from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "../../../../../../components/styled";
import { theme } from "../../../../../../components/theme";
import SpButton from "../../../../../../components/atoms/SpButton";
import SpIconButton from "../../../../../../components/atoms/SpIconButton";
import SpText from "../../../../../../components/atoms/SpText";
import SpAutocomplete from "../../../../../../components/atoms/SpAutocomplete";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";

const StyledGridContainerA = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "1%",
  paddingTop: "2%",
});

const StyledGridContainerB = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
});

export const GRFScore = forwardRef(
  ({ scores, setScores, selectedScore, setSelectedScore, ruleAdded }) => {
    const logicals = Object.values(
      labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
        .ruleLogicals
    );
    return (
      <Grid
        item
        container
        xs={12}
        spacing={2}
        style={{
          display: "flex",
          alignItems: "center",
          direction: "row",
        }}
      >
        <Grid container item xs={5}>
          <StyledGridContainerB key="">
            <Grid item container xs={12} style={{ flexDirection: "column" }}>
              <SpText variant="h1PageSubtitle">
                {labels.mylab.generalRiskFactor.addComp.score}
              </SpText>
            </Grid>
            <StyledGridContainerA>
              {/* regola di riferimento */}
              <Grid item xs={12}>
                <SpAutocomplete
                  id={"selectRuleScore"}
                  formControlWidth={"100%"}
                  value={selectedScore?.rule}
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.rule
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.rule
                  }
                  onChange={(e, newValue) => {
                    setSelectedScore({
                      ...selectedScore,
                      rule: newValue?.rule,
                      type: newValue?.type,
                    });
                  }}
                  options={ruleAdded}
                  getOptionLabel={({ rule }) => rule}
                  getOptionSelected={(
                    { rule: rule_opt },
                    { rule: rule_value }
                  ) => rule_opt === rule_value}
                />
              </Grid>
            </StyledGridContainerA>
            <StyledGridContainerA>
              {/* predicato logico */}
              <Grid item xs={12}>
                <SpAutocomplete
                  id={"selectLogicalPredicate"}
                  formControlWidth={"100%"}
                  value={selectedScore?.logical}
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.label
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.selLogical
                  }
                  onChange={(e, newValue) => {
                    setSelectedScore({ ...selectedScore, logical: newValue });
                  }}
                  options={logicals}
                  getOptionLabel={({ label }) => psTranslate(label)}
                  getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                    key_opt === key_value
                  }
                />
              </Grid>
            </StyledGridContainerA>
            <StyledGridContainerA>
              {/* threshold */}
              <Grid item xs={5}>
                <SpTextInput
                  type="number"
                  onChange={(e, newValue) => {
                    setSelectedScore({
                      ...selectedScore,
                      threshold: e.target.value,
                    });
                  }}
                  value={selectedScore?.threshold}
                  id={"inputThresoldScore"}
                  label={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.threshold
                  }
                  placeholder={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.selThresh
                  }
                  formControlStyle={{ width: "100%", marginTop: "1%" }}
                />
              </Grid>
              <Grid item xs={5}>
                <SpAutocomplete
                  id={"inputThresoldScore"}
                  formControlWidth={"100%"}
                  value={
                    labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
                      ({ key }) => key === selectedScore?.threshold
                    ) ?? ""
                  }
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.subparameter
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.selSubparam
                  }
                  onChange={(e, newValue) => {
                    setSelectedScore({
                      ...selectedScore,
                      threshold: newValue.key,
                    });
                  }}
                  options={
                    labels.mylab.generalRiskFactor.addComp.ruleSection
                      .ruleComposition.study
                  }
                  getOptionLabel={({ label }) => psTranslate(label)}
                  getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                    key_opt === key_value
                  }
                />
              </Grid>
            </StyledGridContainerA>

            {selectedScore?.logical?.key === "betw" && (
              <StyledGridContainerA>
                <Grid item xs={5}>
                  <SpTextInput
                    type="number"
                    onChange={(e, newValue) => {
                      setSelectedScore({
                        ...selectedScore,
                        threshold1: e.target.value,
                      });
                    }}
                    value={selectedScore?.threshold1}
                    id={"inputThresoldScore"}
                    label={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.threshold1
                    }
                    placeholder={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.selThresh
                    }
                    formControlStyle={{ width: "100%", marginTop: "1%" }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <SpAutocomplete
                    id={"inputThresoldScore"}
                    formControlWidth={"100%"}
                    value={
                      labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
                        ({ key }) => key === selectedScore?.threshold1
                      ) ?? ""
                    }
                    disableListWrap
                    label={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.subparameter
                    }
                    selectPlaceholder={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.selSubparam
                    }
                    onChange={(e, newValue) => {
                      setSelectedScore({
                        ...selectedScore,
                        threshold1: newValue.key,
                      });
                    }}
                    options={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.study
                    }
                    getOptionLabel={({ label }) => psTranslate(label)}
                    getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                      key_opt === key_value
                    }
                  />
                </Grid>
              </StyledGridContainerA>
            )}

            <StyledGridContainerA>
              <Grid item xs={12}>
                <SpTextInput
                  onChange={(e, newValue) => {
                    setSelectedScore({
                      ...selectedScore,
                      score: e.target.value,
                    });
                  }}
                  value={selectedScore?.score}
                  id={"inputScore"}
                  label={"Score"}
                  placeholder={"Score"}
                  formControlStyle={{ width: "100%", marginTop: "1%" }}
                />
              </Grid>
            </StyledGridContainerA>
          </StyledGridContainerB>
        </Grid>
        {/* add button */}
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <SpButton
            id="addRuleScoreBtn"
            buttonType="accept"
            text={labels.mylab.generalRiskFactor.addComp.ruleSection.button}
            onClick={() => {
              if (scores) {
                setScores([...scores, selectedScore]);
              } else {
                setScores([selectedScore]);
              }
              setSelectedScore({
                score: "",
                threshold: "",
                logical: "",
                rule: "",
              });
            }}
            variant="h1"
          />
        </Grid>
        {/* rule list */}
        <Grid container item xs={4}>
          <StyledGridContainerB>
            <StyledGridContainerA>
              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SpText variant="listHeader">
                    {
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleList.header
                    }
                  </SpText>
                </Grid>
                <Divider
                  style={{
                    padding: "1px",
                    width: "100%",
                    backgroundColor: theme.colors.primary.lightBlue,
                  }}
                />
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: "1%",
                  }}
                >
                  {scores?.map(
                    (
                      { logical, threshold, threshold1, score, rule },
                      index
                    ) => {
                      return (
                        <Chip
                          variant="outlined"
                          key={index}
                          id={`ruleScore-${index}`}
                          avatar={
                            <SpIconButton
                              variant="redFill"
                              onClick={(e) => {
                                scores.splice(index, 1);
                                setScores([...scores]);
                              }}
                            >
                              <CloseIcon
                                style={{ color: theme.colors.secondary.red }}
                              />
                            </SpIconButton>
                          }
                          style={{
                            backgroundColor: theme.colors.primary.lightBlue,
                            color: theme.colors.primary.white,
                            margin: "8px",
                          }}
                          label={`[${rule}] ${logical?.label} ${threshold} ${
                            threshold1 ? ".. .. " + threshold1 : ""
                          }=> ${score}`}
                          size="medium"
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </StyledGridContainerA>
          </StyledGridContainerB>
        </Grid>
      </Grid>
    );
  }
);
