import React from "react";
import "../../App.css";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import PatientsResources from "./PatientsResources";

const PatientsResourcesMedical = ({
  setLoading,
  snackbarShowErrorMessage,
  ...props
}) => (
  <PatientsResources
    type="medical"
    setLoading={setLoading}
    snackbarShowErrorMessage={snackbarShowErrorMessage}
    props={props}
  />
);
export default withSnackbar(PatientsResourcesMedical);
