import React from "react";
import "./App.css";
import { styled } from "../styled";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import { labels } from "../../pages/shared/translations";
import { theme } from "../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  "@global": {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      background: theme.colors.primary.lightBlue,
    },
  },
});

const CustomFormControl = styled(({ formControlWidth, m, ...other }) => (
  <FormControl {...other} />
))({
  margin: (props) => theme.spacing(props.m),
  minWidth: 120,
  maxWidth: "100%",
  flex: 1,
  width: (props) => props.formControlWidth,
});

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: theme.colors.primary.lightBlue,
  fontWeight: "bold",
  fontSize: 14,
  paddingTop: 8,
  textDecoration: "none",
});

const CustomSelect = styled(Autocomplete)(({ selectvariant }) => ({
  ...selectvariant,
  minWidth: "120px",
  // height: 40,
  marginTop: "0px !important",
  // marginBottom: 8,
  // paddingRight: 10,
  // maxHeight: 28,
  borderColor: theme.colors.primary.lightBlue,
  color: "white !important",
  border: "1px solid",
  backgroundColor: "#333333 !important",
  textAlign: "left",
  "& input::placeholder": {
    color: "#FFF",
  },
}));

const CustomSelectPrint = styled(Autocomplete)(({ selectvariant }) => ({
  ...selectvariant,
  minWidth: "120px",
  // height: 40,
  marginTop: "0px !important",
  // marginBottom: 8,
  // paddingRight: 10,
  // maxHeight: 28,
  borderColor: "white",
  color: "white !important",
  border: "1px solid",
  backgroundColor: "white !important",
  textAlign: "left",
  "& input::placeholder": {
    color: "#FFF",
  },
}));

const CustomInput = styled(TextField)({
  color: "white !important",
  backgroundColor: "#333333",
  textAlign: "left",
  paddingLeft: 3,
});

const CustomInputPrint = styled(TextField)({
  color: "black !important",
  backgroundColor: "#C8C8C8",
  textAlign: "left",
  paddingLeft: 3,
});

const CustomInputComponent = ({
  params,
  classes,
  selectPlaceholder,
  isPrint,
}) => {
  const InputContent = isPrint ? CustomInputPrint : CustomInput;
  return (
    <div className="custom-autocomplete-input" ref={params.InputProps.ref}>
      <InputContent
        id="custom-autocomplete-input"
        placeholder={selectPlaceholder}
        {...params}
        enabled={true}
        style={{ color: "white" }}
        type="text"
      />
    </div>
  );
};

const formControlLabelPlacementTop = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  darkGrey: {
    background: "#eceaf1",
  },
  lightGreen: {
    background: "transparent",
  },
  white: {
    background: "white",
  },
  noMargin: {
    margin: "0px !important",
    padding: "0px  !important",
    background: "transparent",
  },
};

const CommonSelect = ({
  selectVariant,
  classes,
  selectPlaceholder,
  disabled,
  isPrint,
  ...rest
}) => (
  <CustomSelect
    classes={classes && classes}
    disabled={disabled}
    renderInput={(params) => (
      <CustomInputComponent
        selectPlaceholder={selectPlaceholder}
        classes={classes}
        params={params}
        isPrint={isPrint}
      />
    )}
    selectvariant={variants[selectVariant]}
    {...rest}
  />
);

export const SpAutocomplete = ({
  label,
  labelPaddingTop,
  labelMarginBottom,
  classes,
  disabled,
  displayLabel = true,
  selectVariant = "lightGreen",
  formControlWidth,
  formControlStyle,
  labelPlacementLeft,
  selectPlaceholder = "",
  isPrint = false,
  m = 1,
  isRequired = false,
  ...rest
}) => {
  useStyles();
  const localClasses = {};

  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft
    ? formLabelLabelPlacementTop
    : {
        color: !isPrint ? theme.colors.primary.lightBlue : "white",
        paddingTop: labelPaddingTop ? labelPaddingTop : null,
        marginBottom: labelMarginBottom ? labelMarginBottom : null,
      };
  return (
    <CustomFormControl formControlWidth={formControlWidth} style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle}>
          {" "}
          {isRequired && <span style={{ color: "red" }}>*</span>} {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}

      <CommonSelect
        disablePortal
        disabled={disabled}
        classes={localClasses}
        selectVariant={selectVariant}
        loadingText={labels.general.loading_data}
        selectPlaceholder={selectPlaceholder}
        {...rest}
        disableCloseOnSelect={rest.multiple}
        isPrint={isPrint}
      />
    </CustomFormControl>
  );
};

export default SpAutocomplete;
