import Grid from "@material-ui/core/Grid";
import React from "react";
import SpText from "../../../../../components/atoms/SpText";
import { labels } from "../../../../shared/translations";
import { theme } from "../../../../../components/theme";
import { Box, Typography, IconButton } from "@material-ui/core";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { Draggable } from "react-beautiful-dnd";
import ClearIcon from "@material-ui/icons/Clear";
import { Repetition } from "./3-Repetition";

export const Exercise = ({
  exercise,
  id,
  index,
  rows,
  setSchedaEsercizi,
  schedaEsercizi,
}) => {
  return (
    <Draggable
      key={exercise.id_excercise}
      draggableId={`${id}_${index}_${exercise.id_excercise}`.toString()}
      index={index}
    >
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            background: theme.colors.primary.lightBlue,
            borderRadius: 4,
            padding: 8,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  flexGrow: 1,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {
                  rows?.find(
                    ({ id_excercise }) => id_excercise == exercise?.id_excercise
                  )?.name
                }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SpText variant={"textWhite"}>
                {labels.mylab.tempAdd.excerciseSheet.list.header.series}
              </SpText>
              <SpTextInput
                type="number"
                minValue={0}
                style={{ width: "100%" }}
                onChange={(evnt) => {
                  const newArray = schedaEsercizi[id].map((item) => {
                    if (item.id_excercise === exercise.id_excercise) {
                      let seriesDetails = [...(exercise.repetition || [])]; // Copia sicura dell'array
                      const newSeriesCount = evnt.target.value;

                      if (newSeriesCount > seriesDetails.length) {
                        for (
                          let i = seriesDetails.length;
                          i < newSeriesCount;
                          i++
                        ) {
                          seriesDetails.push({
                            repetition_time: 1,
                            load: 1,
                          });
                        }
                      } else {
                        seriesDetails = seriesDetails.slice(0, newSeriesCount);
                      }

                      return {
                        ...item,
                        series: newSeriesCount,
                        repetition: seriesDetails,
                      };
                    }
                    return item; // Mantieni invariati gli altri esercizi
                  });

                  setSchedaEsercizi({
                    ...schedaEsercizi,
                    [id]: newArray,
                  });
                }}
                value={exercise?.series}
              />
            </Grid>
            <Grid item xs={6}>
              <SpText variant={"textWhite"}>
                {labels.mylab.exercise.restEndExercise}
              </SpText>
              <SpTextInput
                type="number"
                minValue={0}
                style={{ width: "100%" }}
                onChange={(evnt) => {
                  const newArray = schedaEsercizi[id].map((item) =>
                    item.id_excercise === exercise.id_excercise
                      ? { ...item, restExercise: evnt.target.value }
                      : item
                  );

                  setSchedaEsercizi({
                    ...schedaEsercizi,
                    [id]: newArray,
                  });
                }}
                value={exercise?.restExercise}
              />
            </Grid>
            {exercise?.repetition?.map((item, index) => {
              {
                /* 
                ELEMENTI CHE SI VEDONO ALL'INTERNO DELLA SCHEDA ESERCIZI
                NELLA SEZIONE DELLE RIPETIZIONI
                */
              }
              return (
                <Repetition
                  item={item}
                  index={index}
                  exercise={exercise}
                  id={id}
                  setSchedaEsercizi={setSchedaEsercizi}
                  schedaEsercizi={schedaEsercizi}
                />
              );
            })}
          </Grid>
          <IconButton
            size="small"
            onClick={() => {
              setSchedaEsercizi({
                ...schedaEsercizi,
                [id]: schedaEsercizi[id].filter(
                  (item) => item.id_excercise !== exercise.id_excercise
                ),
              });
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      )}
    </Draggable>
  );
};
