import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import { styled } from "../../../../components/styled";
import { labels } from "../../../shared/translations";
import { theme } from "../../../../components/theme";
import SpLoader from "../../../../components/atoms/SpLoader";
import { Box } from "@material-ui/core";
import SpButton from "../../../../components/atoms/SpButton";
import SpIconButton from "../../../../components/atoms/SpIconButton";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@material-ui/icons/Add";
import {
  downloadPDFExercise,
  saveExercise,
} from "./ExerciseDetailsHelper/ExerciseCommonFunction";
import { Block } from "./ExerciseDetailsHelper/1-Block";
import { SelectedExercise } from "./ExerciseDetailsHelper/SelectedExercise";
import { getResourceFromServer } from "../../../../models/actions/Professionals";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const ExerciseDetails = ({
  setRows,
  rows,
  setExerciseDetails,
  schedaEsercizi,
  setSchedaEsercizi,
  exerciseList,
  setExerciseLists,
  countIndex,
  setCountIndex,
  getValues,
  idTemplate,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [updatedRow, setUpdatedRows] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    const temp = await Promise.all(
      rows.map(async (item) => {
        const url = await getResourceFromServer({
          id_file: item.id_file,
        });
        return { ...item, url };
      })
    );
    setUpdatedRows(temp);
  }, [rows]);

  function move(arr, fromIndex, toIndex) {
    //move the item
    const [itemToMove] = arr.splice(fromIndex, 1); //take next item from the iterator
    arr.splice(toIndex, 0, itemToMove);

    //iterate and adjust indexes
    let index = toIndex;
    while (index !== fromIndex) {
      let nextIndex =
        fromIndex < toIndex
          ? index - 1 //descending
          : index + 1; //ascending

      const current = arr[index];
      const next = arr[nextIndex];

      //swap the order parameters
      [current.order, next.order] = [next.order, current.order];

      //go to the next item
      index = nextIndex;
    }

    return arr;
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId == 0) {
      handleConfirm(updatedRow[source.index], destination.droppableId);
    } else {
      let temp = JSON.parse(JSON.stringify(schedaEsercizi));
      const newArray = move(
        temp[source.droppableId],
        source.index,
        destination.index
      );
      temp[source.droppableId] = newArray;
      setSchedaEsercizi(temp);
    }
  };

  const handleConfirm = (item, index) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === item.id ? { ...item, ...row } : row))
    );
    let prevEse = JSON.parse(JSON.stringify(schedaEsercizi));
    if (!prevEse[index]) {
      prevEse = { ...prevEse, [index]: [] };
    }
    prevEse[index].push(item);
    setSchedaEsercizi(prevEse);
  };
  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      {!idTemplate && (
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.exercise.titleAdd}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
        </Grid>
      )}

      <SpText variant="h1">{labels.mylab.exercise.addStep2}</SpText>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "1em",
        }}
      >
        <div>
          <SpButton
            style={{
              width: "100%",
            }}
            buttonType={"accept"}
            text={labels.mylab.exercise.back}
            onClick={() => {
              setExerciseDetails(false);
            }}
          />
        </div>
        <div>
          <SpButton
            buttonType={"accept"}
            text={"Download"}
            onClick={async () => {
              setLoading(true);
              await downloadPDFExercise({
                rows: updatedRow,
                name: getValues("name"),
                schedaEsercizi,
                exerciseList,
              });
              setLoading(false);
            }}
          />
        </div>
        <div>
          <SpButton
            buttonType={"accept"}
            text={labels.mylab.tempDetail.exerciseCategory.save}
            onClick={async () => {
              saveExercise({
                idTemplate,
                schedaEsercizi,
                exerciseList,
                name: getValues("name"),
                setExerciseDetails,
                props,
                rows: updatedRow,
              });
            }}
          />
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container direction="row" spacing={2}>
          {/* Sidebar */}
          <Grid item xs={3} style={{ paddingTop: "2.5em" }}>
            <Droppable droppableId={0} isDropDisabled={true}>
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    borderRadius: 4,
                    background: "#f7f7f7",
                  }}
                >
                  {updatedRow.map((row, index) => {
                    {
                      /* 
                    LISTA DEGLI ESERCIZI SELEZIONATI NELLA PRIMA PARTE
                     */
                    }
                    return <SelectedExercise index={index} row={row} />;
                  })}
                </Box>
              )}
            </Droppable>
            <SpIconButton
              id="add"
              onClick={async () => {
                const newIndex = countIndex + 1;
                setExerciseLists([
                  ...exerciseList,
                  { id: newIndex, is_circuit: false, circuitLength: 1 },
                ]);
                setCountIndex(parseInt(newIndex));
              }}
              width={"200px"}
              additionalText={labels.mylab.exercise.addScheme}
            >
              <AddIcon />
            </SpIconButton>
          </Grid>

          {/* Contenitore Scrollabile */}
          <Grid item xs={8}>
            <div
              style={{
                maxWidth: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{
                  display: "inline-flex",
                  flexWrap: "nowrap",
                }}
              >
                {exerciseList.map(({ id, is_circuit, circuitLength }) => {
                  {
                    /* 
                  CONTIENE IL BLOCCO 
                  CHE A SUA VOLTA CONTIENE GLI ESERCIZI 
                  CHE A LORO VOLTA CONTENGONO LE RIPETIZIONI
                   */
                  }
                  return (
                    <Block
                      id={id}
                      is_circuit={is_circuit}
                      circuitLength={circuitLength}
                      setExerciseLists={setExerciseLists}
                      setSchedaEsercizi={setSchedaEsercizi}
                      schedaEsercizi={schedaEsercizi}
                      rows={updatedRow}
                    />
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DragDropContext>
    </Grid>
  );
};
export default withSnackbar(ExerciseDetails);
