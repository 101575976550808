import React, { useEffect } from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
// material ui
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
// models
// components
import { styled } from "../../../../../../components/styled";
import { theme } from "../../../../../../components/theme";
// atoms
import { SpAutocomplete } from "../../../../../../components/atoms/SpAutocomplete";
import SpCheckbox from "../../../../../../components/atoms/SpCheckbox";
import SpText from "../../../../../../components/atoms/SpText";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import SpButton from "../../../../../../components/atoms/SpButton";
import SpIconButton from "../../../../../../components/atoms/SpIconButton";
//utils
import { handleNewRAS } from "../../../genericRiskFactorFunction";

const StyledGridContainerA = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "1%",
});

const StyledGridContainerB = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
});

export const RASImpactedSection = ({
  impactRef,
  setImpactRef,
  weightRef,
  setWeightRef,
  impRegions,
  impAreas,
  impStructures,
  impactedZone,
  setImpactedZone,
  zonesImpacted,
  setZonesImpacted,
  inputValues,
  setInputValues,
  filterLocks,
  setFilterLocks,
}) => {
  return (
    <>
      <StyledGridContainerA key="">
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item container xs={12} style={{ flexDirection: "column" }}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.generalRiskFactor.addComp.titleImpacted}
            </SpText>
          </Grid>

          {/* selezione zona del corpo impattante */}
          <Grid
            container
            xs={5}
            spacing={2}
            style={{
              display: "flex",
              direction: "column",
              paddingLeft: "2%",
              paddingBottom: "2%",
              paddingTop: "2%",
            }}
          >
            {/* region */}
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-end",
                direction: "row",
                marginBottom: "0.1em",
              }}
            >
              <Grid item xs={12}>
                <SpAutocomplete
                  id={"selectImpRegion"}
                  formControlWidth={"100%"}
                  value={
                    impRegions.find(
                      ({ id }) => id == inputValues.impRegionSelected
                    ) ?? ""
                  }
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .impRegion
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .impRegion
                  }
                  onChange={(e, newValue) => {
                    setInputValues({
                      ...inputValues,
                      impRegionSelected: newValue?.id,
                    });
                    setImpactedZone({ ...impactedZone, region: newValue });
                  }}
                  options={impRegions}
                  getOptionLabel={({ name }) => psTranslate(name)}
                  getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                    id_opt === id_value
                  }
                />
              </Grid>
            </Grid>
            {/* area */}
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-end",
                direction: "row",
                marginBottom: "0.1em",
              }}
            >
              <Grid item xs={12}>
                <SpAutocomplete
                  id={"selectImpArea"}
                  formControlWidth={"100%"}
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.inputSection.impArea
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.inputSection.impArea
                  }
                  value={
                    impAreas.find(
                      ({ id }) => id == inputValues.impAreaSelected
                    ) ?? ""
                  }
                  onChange={(e, newValue) => {
                    // set new area and update region
                    const updateRegion = impRegions.find(
                      ({ id }) => id === newValue?.id_region
                    );
                    setInputValues({
                      ...inputValues,
                      impRegionSelected: updateRegion?.id,
                      impAreaSelected: newValue?.id,
                      impStructureSelected: null,
                    });
                    setImpactedZone({
                      ...impactedZone,
                      region: updateRegion,
                      area: newValue,
                    });
                  }}
                  options={
                    inputValues.impRegionSelected
                      ? impAreas.filter(
                          ({ id_region }) =>
                            id_region === inputValues.impRegionSelected
                        )
                      : impAreas
                  }
                  getOptionLabel={({ name }) => psTranslate(name)}
                  getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                    id_opt === id_value
                  }
                />
              </Grid>
            </Grid>
            {/* structure */}
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-end",
                direction: "row",
                marginBottom: "0.1em",
              }}
            >
              <Grid item xs={12}>
                <SpAutocomplete
                  id={"selectImpStructure"}
                  formControlWidth={"100%"}
                  disableListWrap
                  label={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .impStructure
                  }
                  selectPlaceholder={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .impStructure
                  }
                  value={
                    impStructures.find(
                      ({ id }) => id == inputValues.impStructureSelected
                    ) ?? ""
                  }
                  onChange={(e, newValue) => {
                    // set new structure and update area and region
                    const updateArea = impAreas.find(
                      ({ id }) => id === newValue?.id_area
                    );
                    const updateRegion = impRegions.find(
                      ({ id }) => id === updateArea?.id_region
                    );
                    setInputValues({
                      ...inputValues,
                      impRegionSelected: updateRegion?.id,
                      impAreaSelected: updateArea?.id,
                      impStructureSelected: newValue?.id,
                    });
                    setImpactedZone({
                      ...impactedZone,
                      region: updateRegion,
                      area: updateArea,
                      structure: newValue,
                    });
                  }}
                  options={impStructures}
                  getOptionLabel={({ name }) => psTranslate(name)}
                  getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                    id_opt === id_value
                  }
                />
              </Grid>
            </Grid>
            {/* impact percentage and weight */}
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {/* impact percentage */}
              <Grid item xs={3}>
                <SpTextInput
                  type="number"
                  id={"inputImpact"}
                  value={impactRef}
                  onChange={(e) => {
                    setImpactRef(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <SpText
                        style={{ marginRight: "0.4em", marginLeft: "0.4em" }}
                      >
                        %
                      </SpText>
                    ),
                  }}
                  label={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .inputImpact
                  }
                  placeholder={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .inputImpactPlaceholder
                  }
                  defaultValue={1}
                  formControlStyle={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              {/* weight */}
              <Grid item xs={3}>
                <SpTextInput
                  type="number"
                  id={"inputWeight"}
                  value={weightRef}
                  onChange={(e) => {
                    setWeightRef(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <SpText
                        style={{ marginRight: "0.4em", marginLeft: "0.4em" }}
                      ></SpText>
                    ),
                  }}
                  label={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .inputWeight
                  }
                  placeholder={
                    labels.mylab.generalRiskFactor.addComp.inputSection
                      .inputWeightPlaceholder
                  }
                  defaultValue={1}
                  formControlStyle={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* add zone button */}
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingLeft: "2%",
            }}
          >
            <SpButton
              id="addRASZoneBtn"
              buttonType="accept"
              text={labels.mylab.generalRiskFactor.addComp.inputSection.button}
              onClick={() => {
                // send
                handleNewRAS({
                  inputs: inputValues,
                  setInputValues: setInputValues,
                  impactedZone: impactedZone,
                  setImpactedZone: setImpactedZone,
                  percentageRef: impactRef,
                  weightRef: weightRef,
                  zonesImpacted: zonesImpacted,
                  setZonesImpacted: setZonesImpacted,
                });
                // clear inputs
                setImpactedZone({
                  region: null,
                  area: null,
                  structure: null,
                });
                setInputValues({
                  ...inputValues,
                  impRegionSelected: null,
                  impAreaSelected: null,
                  impStructureSelected: null,
                });
                setImpactRef("");
                setWeightRef("");
              }}
              variant="h1"
            />
          </Grid>

          {/* impacted zones list */}
          <Grid container item xs={4}>
            <StyledGridContainerB>
              <StyledGridContainerA>
                <Grid container xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SpText variant="listHeader">
                      {
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .zoneList
                      }
                    </SpText>
                  </Grid>
                  <Divider
                    style={{
                      padding: "1px",
                      width: "100%",
                      backgroundColor: theme.colors.primary.lightBlue,
                    }}
                  />
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      padding: "1%",
                    }}
                  >
                    {zonesImpacted.map(({ zone }, index) => (
                      <Chip
                        variant="outlined"
                        key={index}
                        id={"rule-" + index}
                        avatar={
                          <SpIconButton
                            variant="redFill"
                            onClick={(e) => {
                              zonesImpacted.splice(index, 1),
                                setZonesImpacted([...zonesImpacted]);
                            }}
                          >
                            <CloseIcon
                              style={{ color: theme.colors.secondary.red }}
                            />
                          </SpIconButton>
                        }
                        style={{
                          backgroundColor: theme.colors.primary.lightBlue,
                          color: theme.colors.primary.white,
                          margin: "8px",
                        }}
                        label={zone}
                        size="medium"
                      />
                    ))}
                  </Grid>
                </Grid>
              </StyledGridContainerA>
            </StyledGridContainerB>
          </Grid>
          {/* lock RAS list */}
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockImpList"}
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.lockList
              }
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                display: "flex",
              }}
              checked={filterLocks.impListToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  impListToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>
      </StyledGridContainerA>
    </>
  );
};
