import { call } from "./utils";

export const convertPngToBW = async (data) =>
  await call({
    url: "/convert/png/to/bw",
    data: { data },
  });
export const getTokenOrganization = async (data) =>
  await call({
    url: "/getTokenOrganization",
    data: { data },
  });

export const linkUserToOrganization = async (data) =>
  await call({
    url: "/linkUserToOrganization",
    data: { data },
  });

export const getAuth0Roles = async (data) =>
  await call({
    url: "/getAuth0Roles",
    data: { data },
  });

export const assignRolesToUserAuth0 = async (data) =>
  await call({
    url: "/assignRoletoUserAuth0",
    data: { data },
  });

export const createUserAuth0 = async (data) =>
  await call({
    url: "/createUserAuth0",
    data: { data },
  });

export const createOrganizationAuth0 = async (data) =>
  await call({
    url: "/createOrganizationAuth0",
    data: { data },
  });

export const getPostUrl = async (data) =>
  await call({
    url: "/get/post/url",
    data: { data },
  });

export const payment = async (data) =>
  await call({
    url: "/payment",
    data: { data },
  });
export const updatePayment = async (data) =>
  await call({
    url: "/update/payment/method",
    data: { data },
  });
export const subscription = async (data) =>
  await call({
    url: "/subscription",
    data: { data },
  });

export const enableUserAuth0 = async (data) =>
  await call({
    url: "/enableUserAuth0",
    data: { data },
  });
