import { createTheme } from "@material-ui/core/styles";
import { DataGrid, GridOverlay, itIT, enUS } from "@mui/x-data-grid";
import React from "react";
import { labels, getLanguage } from "../../pages/shared/translations";
import "./App.css";
import SpText from "./SpText";
import { styled } from "../styled";

const themeMUI = createTheme({
  root: {
    background: "transparent",
  },
});

const StyledDataGrid = styled(DataGrid)({
  height: 400,
  width: "100%",
  maxWidth: "100%",
  background: "transparent",
  color: "#FFFFFF",
  border: 0,
  "& .MuiTablePagination-root": {
    color: "#FFF",
    background: "#31caad",
  },
  "& .MuiDataGrid-menuIcon": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0 !important",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 500,
    whiteSpace: "normal",
    lineHeight: 1,
    fontSize: "0.9rem",
  },
});

const SpDataGrid = ({
  hideFooterPagination = true,
  showPagination = false,
  rows,
  headers,
  children: Row,
  emptyText = labels.general.no_data,
  ...props
}) => {
  return (
    <div style={{ width: "100%" }}>
      <StyledDataGrid
        components={{
          NoRowsOverlay: () => (
            <GridOverlay
              style={{ background: "transparent", flexDirection: "column" }}
            >
              <SpText variant="tableText">{emptyText}</SpText>
            </GridOverlay>
          ),
        }}
        theme={themeMUI}
        localeText={
          getLanguage() === "it"
            ? itIT.props.MuiDataGrid.localeText
            : enUS.props.MuiDataGrid.localeText
        }
        headerHeight={37}
        rows={rows}
        columns={headers ?? []}
        pageSize={rows?.length > 100 ? 100 : rows?.length}
        rowsPerPageOptions={[rows.length > 100 ? 100 : rows.length]}
        disableEnforceFocus
        rowCount={rows.length}
        autoHeight={true}
        hideFooterPagination={hideFooterPagination}
        checkboxSelection={false}
        pagination={showPagination}
        disableSelectionOnClick
        {...props}
      />
    </div>
  );
};

export default SpDataGrid;
