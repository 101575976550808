import React from "react";
import { rollbar, isFeatureFlagEnabled } from "../../utils/common";
import ProfessionalsFiled from "./inputDetails/ProfessionalsFiled";
import { getGroupsById } from "../../models/actions/Groups";
import SpText from "../../components/atoms/SpText";
import { labels, psTranslate } from "../shared/translations";
import {
  archiveActivityById,
  deleteCalendarActivityById,
} from "../../models/actions/CalendarActivity";
import A1_AssessmentDisabledDetails from "./disabledDetails/A1_AssessmentDisabledDetails";
import A2_TreatmentDisabledDetails from "./disabledDetails/A2_TreatmentDisabledDetails";
import A3_ExerciseDisabledDetails from "./disabledDetails/A3_ExerciseDisabledDetails";
import A4_TrainingDisabledDetails from "./disabledDetails/A4_TrainingDisabledDetails";
import A5_RestDisabledDetails from "./disabledDetails/A5_RestDisabledDetails";
import A6_OtherDisabledDetails from "./disabledDetails/A6_OtherDisabledDetails";
import A7_RaceDisabledDetails from "./disabledDetails/A7_RaceDisabledDetails";
import A8_RehabilitationDisabledDetails from "./disabledDetails/A8_RehabilitationDisabledDetails";
import A9_SupplementDisabledDetails from "./disabledDetails/A9_SupplementDisabledDetails";

import A1_AssessmentInputDetails from "./inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "./inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "./inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "./inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "./inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "./inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "./inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "./inputDetails/A8_RehabilitationInputDetails";
import A9_SupplementDetails from "./inputDetails/A9_SupplementDetails";
import { Grid } from "@material-ui/core";

export const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
  A9_SupplementDetails,
];

export const DISABLED_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentDisabledDetails,
  A2_TreatmentDisabledDetails,
  A3_ExerciseDisabledDetails,
  A4_TrainingDisabledDetails,
  A5_RestDisabledDetails,
  A6_OtherDisabledDetails,
  A7_RaceDisabledDetails,
  A8_RehabilitationDisabledDetails,
  A9_SupplementDisabledDetails,
];

export const fetchDataGroup = async ({
  groupId,
  isGroupCard,
  setProfessionalsSelected,
  loadParametersList,
}) => {
  let isEnabled = await isFeatureFlagEnabled("professional_notification");
  let groupSelected;
  let renderProfessionalAlert;
  if (isGroupCard) {
    // recupero il gruppo dalle props passate
    groupSelected = await getGroupsById({ id_group: groupId });
    if (groupSelected.professionals) {
      // per abilitare la notifica del professionista deve essere abiltiata la feature flag
      // e il numero dei professionisti del gruppo deve essere maggiore di 0
      if (isEnabled && groupSelected.professionals.length > 0) {
        renderProfessionalAlert = (
          <ProfessionalsFiled
            loadParametersData={groupSelected.professionals}
            setLoadParametersList={setProfessionalsSelected}
            loadParametersList={loadParametersList}
          />
        );
      }
    }
  }
  return {
    renderProfessionalAlert: renderProfessionalAlert,
    groupSelected: groupSelected,
  };
};

export const deleteCalendarActivity = async (
  e,
  event,
  updateActivity,
  fetchData
) => {
  e.stopPropagation();
  try {
    if (event.is_from_template) {
      await updateActivity(event, true /*delete*/);
      return { message: "Attività aggiornata correttamente" };
    } else {
      const result = await deleteCalendarActivityById({
        id_activity: event.id,
        id_activity_groups: event.id_activity_groups
          ? event.id_activity_groups
          : null, //for groups
      });
      if (result.error) {
        throw new Error(result.error);
      } else {
        await fetchData();
        return result.message;
      }
    }
  } catch (error) {
    throw new Error(error);
  }
};

//Render input popup details
export const renderInputDetails = (
  isDisabled,
  event,
  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  control,
  setValue,
  register,
  handleSubmit,
  updateActivityFeedback,
  allowFeedback,
  isGroupCard,
  handleSync,
  snackbarShowErrorMessage,
  snackbarErrorMessage,
  isPrint,
  subactivities,
  getValues,
  activitySelectedKey
) => {
  if (isDisabled()) {
    const Detail = DISABLED_DETAILS[event.id_activity_type];
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Detail
          watchTemplateSelect={null}
          selectedTemplate={null}
          templateData={event.data}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
          disabled={isDisabled()}
          handleSubmit={handleSubmit}
          updateActivityFeedback={updateActivityFeedback}
          handleSync={handleSync}
          event={event}
          allowFeedback={allowFeedback}
          isGroupCard={isGroupCard}
          isPrint={isPrint}
          snackbarShowErrorMessage={snackbarShowErrorMessage}
          snackbarShowMessage={snackbarErrorMessage}
          subactivities={subactivities}
          getValues={getValues}
        />
      </Grid>
    );
  } else {
    //Still editable
    const Detail = INPUT_DETAILS[event.id_activity_type];
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Detail
          watchTemplateSelect={null}
          selectedTemplate={null}
          templateData={event.data}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
          disabled={isDisabled()}
          handleSubmit={handleSubmit}
          updateActivityFeedback={updateActivityFeedback}
          handleSync={handleSync}
          event={event}
          allowFeedback={allowFeedback}
          isGroupCard={isGroupCard}
          isPrint={isPrint}
          snackbarShowErrorMessage={snackbarShowErrorMessage}
          snackbarShowMessage={snackbarErrorMessage}
          subactivities={subactivities}
          getValues={getValues}
        />
      </Grid>
    );
  }
};
