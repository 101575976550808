import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { Divider, Grid, TableCell } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import {
  fetchPatAnswersToQuest,
  findPatientByPk,
  removeFeedbackById,
} from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpDialog from "../../components/atoms/SpDialog";
import { isFeatureFlagEnabled } from "../../utils/common";
import { theme } from "../../components/theme";
import PatientsMonitoringDetailProms from "./patientsMonitoring/PatientsMonitoringDetailProms";
import moment from "moment";

const headCellsControlQuestions = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label:
      labels.patient.presentation.detail.stepper.step6.table.header.type.toUpperCase(),
    isAction: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label:
      labels.patient.presentation.detail.stepper.step6.table.header.date.toUpperCase(),
    isAction: false,
  },
];

const PatientsControlQuestionHelper = (props) => {
  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [selectedRecordDetail, setSelectedRecordDetail] = useState(null);
  const [openDeleteFeedbackDialog, setOpenDeleteFeedbackDialog] =
    useState(false);
  const [feedbackToRemove, setFeedbackToRemove] = useState(false);
  const [isProfessionalNotifEnabled, setIsProfessionalNotifEnabled] =
    useState(false);
  const [selectedProms, setSelectedProms] = useState();
  const [detailPresentationProms, setDetailPresentationProms] = useState(false);
  const [addPresentationProms, setAddPresentationProms] = useState(false);

  const history = useHistory();
  const { setLoading, patId } = props;

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm({
      defaultValues: {
        proms: null,
        frequency: null,
        duration: null,
      },
      shouldUnregister: false,
    });

  const removeFeedback = async () => {
    try {
      const result = await removeFeedbackById({
        id_questionnaire: feedbackToRemove.id,
      });
      if (result.message) {
        setOpenDeleteFeedbackDialog(false);
        props.snackbarShowMessage(result.message);
        await fetchData();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchQuestAnsData = async () => {
    try {
      const responseQuestionnaireList = await fetchPatAnswersToQuest({
        id_patient: patId,
      });
      setQuestionnaireList(responseQuestionnaireList);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchData = async () => {
    // controllo se la feature flag é abilitata
    let isEnabled = await isFeatureFlagEnabled("professional_notification");
    setIsProfessionalNotifEnabled(isEnabled);
    try {
      setLoading(true);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);

      await fetchQuestAnsData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const closeAssessmentAdd = () => {
    setAddPresentationProms(false);
  };

  const handlePromsDetail = async (id_proms) => {
    setSelectedProms(id_proms);
    setDetailPresentationProms(true);
  };

  const _onRowClick = (proms) => {
    if (proms?.monitoring_proms_answers?.length === 0) {
      props.snackbarShowErrorMessage(labels.patient.proms.errors.no_answers);
    } else {
      handlePromsDetail(proms.id);
    }
  };

  const ControlQuestionsRow = ({ row, index }) => {
    return (
      <>
        <SpTableRow key={row.id} tabIndex={-1}>
          <SpTableCell
            style={{ cursor: "pointer" }}
            onClick={() => _onRowClick(row)}
          >
            <SpText variant="tableText">
              {psTranslate(row.prom?.name?.toUpperCase())}
            </SpText>
          </SpTableCell>
          <SpTableCell
            style={{ cursor: "pointer" }}
            onClick={() => _onRowClick(row)}
          >
            <SpText variant="tableText">
              {row.date ? moment(row.date).format("DD/MM/YYYY") : ""}
            </SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>{props.value}</SpText>
    </Grid>
  );

  return (
    <>
      {!detailPresentationProms && (
        <Grid item xs={12} spacing={1} container direction="column">
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <SpText variant="h4ComponentLabel">
              {labels.patient.controlQuestions.responseTable.title +
                " " +
                currentPatient?.givenName +
                " " +
                currentPatient?.familyName}
            </SpText>
            <SpTable
              headCells={headCellsControlQuestions}
              rows={questionnaireList}
              pagination={true}
              rowKey="id"
              padding={false}
              notCheckable={false}
              tableContainerMaxHeight={
                isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
              }
            >
              <ControlQuestionsRow />
            </SpTable>
          </Grid>
        </Grid>
      )}

      <SpDialog
        open={dialogDetailOpen}
        setOpen={setDialogDetailOpen}
        title={labels.patient.controlQuestions.detailDialog.title}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <>
            <Grid item container xs={12} spacing={2}>
              <Grid xs={12}>
                <SpText variant="text">{`${labels.patient.controlQuestions.detailDialog.questTitle}`}</SpText>
              </Grid>
              <Grid xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedRecordDetail?.title)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: theme.colors.primary.lightBlue,
                  width: "100%",
                }}
              />

              <Grid xs={12} direction="column" style={{ marginTop: "1%" }}>
                {selectedRecordDetail?.data?.questions?.map((question) => (
                  <LabelValue
                    label={psTranslate(question.question)}
                    value={question?.answers
                      ?.map((a) => psTranslate(a.answer))
                      ?.join(", ")}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        </Grid>
      </SpDialog>
      {detailPresentationProms && (
        <Grid item xs={4} spacing={1} container direction="column">
          <SpButton
            buttonType="accept"
            variant="none"
            style={{ width: "100%" }}
            text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
            onClick={() => setDetailPresentationProms(false)}
          />
        </Grid>
      )}
      {detailPresentationProms && (
        <PatientsMonitoringDetailProms
          closeAssessmentAdd={closeAssessmentAdd}
          proms={selectedProms}
          setLoading={setLoading}
        />
      )}
      {/*Dialog delete feedback*/}
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteFeedbackDialog}
        setOpen={setOpenDeleteFeedbackDialog}
        title={labels.patient.controlQuestions.deleteDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.patient.controlQuestions.deleteDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.mylab.dialog.delete.yes}
                buttonType="accept"
                onClick={removeFeedback}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.mylab.dialog.delete.no}
                buttonType="accept"
                onClick={() => setOpenDeleteFeedbackDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsControlQuestionHelper);
