import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { Grid } from "@material-ui/core";

import {
  getPatientPastHistory,
  updatePatientPastHistory,
} from "../../../models/actions/Patients";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { HeaderButton } from "../patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { theme } from "../../../components/theme";

const StyledAnswerAddInfo = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "2%",
});

const PatientsPastHistoryInputStep = (props) => {
  const [pastHistoryData, setPastHistoryData] = useState(null);
  const [itemSelected, setItemSelected] = useState({ selected: "" });

  const { patId } = useParams();

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    shouldUnregister: false,
  });

  const updateRegistry = async () => {
    try {
      let newAnswers = [];
      pastHistoryData.questions.map(({ id }) => {
        if (getValues(`question${id}`) || getValues(`questionNote[${id}]`)) {
          newAnswers.push({
            id_patient: patId,
            answer: getValues(`question${id}`),
            id_question: id,
            note: getValues(`questionNote[${id}]`),
          });
        }
      });
      setPastHistoryData({ ...pastHistoryData, answers: newAnswers });
      const response = await updatePatientPastHistory({
        id_patient: patId,
        answers: newAnswers,
      });
      if (response) {
        props.snackbarShowMessage(response?.message);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  //Fetching patient data
  const fetchData = async () => {
    try {
      const dataFromTheServerPastHistory = await getPatientPastHistory({
        id_patient: patId,
      });
      setPastHistoryData(dataFromTheServerPastHistory);
      setItemSelected({
        selected: dataFromTheServerPastHistory.categories[0].id,
      });
      dataFromTheServerPastHistory?.answers?.forEach(
        ({ id_question, note, answer }) => {
          setValue(`question${id_question}`, answer);
          setValue(`questionNote[${id_question}]`, note);
        }
      );
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!pastHistoryData)
    return (
      <SpText variant="h4ComponentLabel">{labels.general.loading_data}</SpText>
    );

  const NewSelected = () => {
    const questions = pastHistoryData?.questions?.filter(
      ({ id_medical_history_chapter }) =>
        id_medical_history_chapter === itemSelected?.selected
    );

    return (
      <Grid item container xs={12} spacing={2}>
        {questions?.length > 0 &&
          questions.map(({ id, name, answer_type }) => {
            return (
              <Grid item container xs={12}>
                <StyledAnswerAddInfo>
                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <SpText
                        variant={"h4ComponentLabelRegular"}
                        style={{ marginTop: "1.5em" }}
                      >
                        {psTranslate(name)}
                      </SpText>
                    </Grid>
                    {answer_type === "boolean" ? (
                      <Controller
                        render={(props) => (
                          <SpSelect
                            label={
                              labels.mylab.generalRiskFactor.addComp
                                .inputSection.selectAnswer
                            }
                            style={{ marginTop: "1%" }}
                            value={props.value ?? []}
                            onChange={(e) => {
                              const newAnswers = pastHistoryData.answers.filter(
                                ({ id_question }) => id_question !== id
                              );
                              const previuosAnswer =
                                pastHistoryData?.answers.find(
                                  ({ id_question }) => id_question === id
                                );
                              newAnswers.push({
                                id_patient: patId,
                                answer: e.target.value,
                                id_question: id,
                                note: previuosAnswer?.note
                                  ? previuosAnswer?.note
                                  : "",
                              }),
                                setPastHistoryData({
                                  ...pastHistoryData,
                                  answers: newAnswers,
                                });
                              setValue(`question${id}`, e.target.value);
                            }}
                          >
                            {[
                              { id: 1, answer: "Sì" },
                              { id: 2, answer: "No" },
                            ]?.map((item) => (
                              <SpSelectMenuItem key={item.id} value={item.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <SpText
                                    style={{
                                      color: "black !important",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {item.answer}
                                  </SpText>
                                </div>
                              </SpSelectMenuItem>
                            ))}
                          </SpSelect>
                        )}
                        defaultValue={
                          pastHistoryData?.answers?.find(
                            ({ id_question }) => id_question === id
                          )?.answer
                        }
                        name={`question${id}`}
                        control={control}
                      />
                    ) : (
                      <SpTextInput
                        style={{
                          marginTop: "0.4em",
                          paddingRight: "1em",
                          paddingLeft: "1em",
                        }}
                        type={answer_type === "date" ? "date" : "text"}
                        label={
                          labels.mylab.generalRiskFactor.addComp.inputSection
                            .selectAnswer
                        }
                        onChange={(e) => {
                          let newAnswer = pastHistoryData.answers.find(
                            ({ id_question }) => id_question === id
                          );

                          newAnswer = { answer: e.target.value, ...newAnswer };
                          setValue(`question${id}`, e.target.value);
                        }}
                        name={`question${id}`}
                        defaultValue={
                          pastHistoryData?.answers?.find(
                            ({ id_question }) => id_question === id
                          )?.answer
                        }
                      />
                    )}

                    <SpTextInput
                      style={{
                        marginTop: "0.4em",
                        paddingRight: "1em",
                        paddingLeft: "1em",
                      }}
                      type="text"
                      label={
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .notes
                      }
                      onChange={(e) => {
                        let newAnswer = pastHistoryData.answers.find(
                          ({ id_question }) => id_question === id
                        );

                        newAnswer = { note: e.target.value, ...newAnswer };
                        setValue(`questionNote[${id}]`, e.target.value);
                      }}
                      name={`questionNote[${id}]`}
                      defaultValue={
                        pastHistoryData?.answers?.find(
                          ({ id_question }) => id_question === id
                        )?.note
                      }
                    />
                  </Grid>
                </StyledAnswerAddInfo>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      {pastHistoryData && (
        <Grid contaier xs={12} direction="row" style={{ paddingRight: "2em" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              item
              container
              xs={12}
              style={{
                alignSelf: "flex-start",
              }}
            ></Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SpButton
                buttonType={"accept"}
                onClick={() => handleSubmit(updateRegistry)()}
                variant="none"
                text={labels.patient.patientRegistry.submitButton}
              />
            </div>
          </div>

          <Grid
            container
            direction="row"
            xs={12}
            style={{
              marginTop: "2em",
            }}
          >
            {pastHistoryData?.categories.map(({ id, name }) => (
              <HeaderButton
                setFilters={setItemSelected}
                filters={itemSelected}
                buttonName={id}
                buttonLabel={psTranslate(name)}
              />
            ))}
          </Grid>

          <div
            style={{
              border: `solid 1px ${theme.colors.primary.lightBlue}`,
              width: "100%",
              marginTop: "2em",
              padding: "1em",
            }}
          >
            <NewSelected />
          </div>
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(PatientsPastHistoryInputStep);
