import Grid from "@material-ui/core/Grid";
import { DeleteForever } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { createFilterOptions } from "@material-ui/lab";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { getDysfunctionsCategories } from "../../../models/actions/Pathologies";
import {
  getDysfunctionsCategoriesByDisorderTypeAndStructure,
  getPathologies,
  getPathologyByStructure,
  getStructuresByPathology,
} from "../../../models/actions/Pathologies";
import {
  deletePastHistoryPathology,
  getPatientPastHistoryPathologies,
  savePastHistoryPathologies,
} from "../../../models/actions/Patients";
import { dateFormat } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";
import { getPresentationTypeList } from "../../../models/actions/Presentation";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
  paddingBottom: "2%",
});

const StyledColumn = styled("div")({
  flex: "1 1 100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "3%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
  "&:disabled": {
    color: theme.colors.primary.lightBlue,
  },
});

const PathologiesInput = (props) => {
  const [pathologies, setPathologies] = useState({});
  const [structures, setStructures] = useState({});
  const [openDeletePathology, setOpenDeletePathology] = useState(false);
  const [pathologyRows, setPathologyRows] = useState([]);
  const [pathologyToDelete, setPathologyToDelete] = useState(null);

  const { patId } = useParams();
  const { setLoading } = props;

  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) => psTranslate(option.name),
  });

  /** Fetch all pathologies */
  const fetchDataInitial = async () => {
    try {
      setLoading(true);

      let [resultPathologies, resultStructures] = await Promise.all([
        getPathologies(),
        getRegionAreaStructureLists({ no_regions: true, no_areas: true }),
      ]);
      resultPathologies = resultPathologies.reduce((accum, pat) => {
        accum[pat.id] = pat;
        return accum;
      }, {});
      setPathologies(resultPathologies);
      resultStructures = resultStructures.structureList.reduce(
        (accum, struc) => {
          accum[struc.id] = struc;
          return accum;
        },
        {}
      );
      setStructures(resultStructures);

      await fetchPathologyRows();
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  /**
   * Fetch pathologies' structures and store them
   * @param {Array} pathologyFields Pathology ids to fetch
   */
  async function updatePathologiesData(pathologyRowsData) {
    let modifiedPathologiesRows = false;
    //Make ids unique
    await Promise.all(
      pathologyRowsData.map(async (pathologyRow) => {
        //Update dysfunctions
        if (
          !pathologyRow.added &&
          pathologyRow.id_pathology &&
          pathologyRow.id_structure &&
          pathologyRow.dysfunction_categories.length === 0
        ) {
          const dysfunctions =
            await getDysfunctionsCategoriesByDisorderTypeAndStructure({
              id_disorder_type: null, //Any disorder
              id_structure: pathologyRow.id_structure,
            });
          //Set dysfunction categories
          pathologyRow.dysfunction_categories = dysfunctions.reduce(
            (accum, d) => {
              let existing = accum.find(
                (el) => el.id === d.dysfunction.id_dysfunction_category
              );
              if (!existing) {
                accum.push({
                  id: d.dysfunction.id_dysfunction_category,
                  name: d.dysfunction.dysfunction_category.name,
                  dysfunctions: [],
                });
                existing = accum[accum.length - 1];
              }

              existing.dysfunctions.push({
                id: d.dysfunction.id,
                name: d.dysfunction.name,
              });

              return accum;
            },
            []
          );
          modifiedPathologiesRows = true;
        }
      })
    );
    //Update if modified
    if (modifiedPathologiesRows) setPathologyRows([...pathologyRows]);
  }

  /** Fetch remote history pathology rows */
  const fetchPathologyRows = async () => {
    try {
      setLoading(true);

      //Obtain history pathologies
      const formattedResults = await getPatientPastHistoryPathologies({
        id_patient: patId,
      });
      formattedResults.forEach((p) => {
        p.added = true;
        p.date = moment(p.date).format(dateFormat);
      });

      //Populate selected pathology structures
      await updatePathologyStructureRelations(formattedResults);
      await updatePathologiesData(formattedResults);

      //Show pathologies
      setPathologyRows(formattedResults);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }

    setLoading(false);
  };

  /** Add new pathology with placeholder id */
  const addPathology = async () => {
    // set presentation type as injury

    const resutlGetAllDysfunctionCategory = await getDysfunctionsCategories();
    const resultPresentationTypeList = await getPresentationTypeList();
    const tempCurrPresType = resultPresentationTypeList.find(
      (synthom) => synthom.name === "Infortunio"
    );

    const tempCurrDysfCategory = resutlGetAllDysfunctionCategory.find(
      (synthom) => synthom.name === "Infortunio"
    );
    const newPathologyRows = [...pathologyRows];
    newPathologyRows.push({
      id: nanoid(),
      id_pathology: null,
      operation_type: "",
      current_status: "",
      rehab_duration: 0,
      id_structure: null,
      id_dysfunction: tempCurrPresType.id,
      id_dysfunction_category: tempCurrDysfCategory.id,
      dysfunction_categories: [],
      date: moment().format(dateFormat),

      added: false,
    });
    setPathologyRows(newPathologyRows);
  };

  /**
   * Remove a pathology
   * @param {any} pathologyRow Row to be removed
   */
  const removePathology = async (pathologyRow) => {
    try {
      let msg = null;

      //Remove pathology from db
      if (pathologyRow.added) {
        const result = await deletePastHistoryPathology({
          id_pathology_past_history: pathologyRow.id,
        });
        msg = result.message;
      } else {
        msg = labels.patient.pastHistory.stepper.pathologies.pathologyRemoved;
      }

      //Remove pathology from array
      const newPathologyRows = pathologyRows.filter(
        (p) => p.id !== pathologyRow.id
      );
      setPathologyRows(newPathologyRows);
      props.snackbarShowMessage(msg);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }

    setOpenDeletePathology(false);
  };

  /** Update all pathology rows */
  const updatePathologies = async () => {
    try {
      //Ensure can update
      if (
        pathologyRows.filter((p) => !p.id_pathology).length > 0 ||
        pathologyRows.length === 0
      ) {
        props.snackbarShowErrorMessage(
          labels.patient.pastHistory.stepper.pathologies.compileAllFields
        );
      } else {
        //Update db data
        const result = await savePastHistoryPathologies({
          pathologies: pathologyRows,
          id_patient: patId,
        });
        //Refresh data (to obtain new ids)
        if (result?.message) {
          props.snackbarShowMessage(result.message);
          fetchPathologyRows(pathologies);
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateActivePathologies = (id, keys, newValues) => {
    setPathologyRows((prevValue) => {
      const newPathologyRows = [...prevValue];
      const pathologyRow = newPathologyRows.find((p) => p.id === id);
      keys.forEach((k, idx) => {
        pathologyRow[k] = newValues[idx];
      });
      return newPathologyRows;
    });
  };

  /**
   * Update pathology-structure associations for selected elements
   * @param {any} pathologyRowsData Current pathology rows values
   */
  const updatePathologyStructureRelations = async (pathologyRowsData) => {
    let pathologiesEdited = false;
    let structuresEdited = false;

    await Promise.all(
      pathologyRowsData.map(async (pathologyRow) => {
        //Update pathologies-structure
        const idPathology = pathologyRow.id_pathology;
        const pathology = pathologies[idPathology];
        if (pathology && !pathology.structures) {
          const structures = await getStructuresByPathology({
            id_pathology: idPathology,
          });
          pathology.structures = structures;
          pathologiesEdited = true;
        }

        const idStructure = pathologyRow.id_structure;
        const structure = structures[idStructure];
        //Update structure-pathology
        if (structure && !structure.pathologies) {
          const structurePathologies = await getPathologyByStructure({
            id_structure: idStructure,
          });
          structure.pathologies = structurePathologies[0].pathologies;
          structuresEdited = true;
        }
      })
    );

    if (structuresEdited) setStructures({ ...structures });
    if (pathologiesEdited) setPathologies({ ...pathologies });
  };

  useEffect(() => {
    fetchDataInitial();
  }, []);

  useEffect(() => {
    updatePathologyStructureRelations(pathologyRows);
    updatePathologiesData(pathologyRows);
  }, [pathologyRows]);

  return (
    <div>
      <StyledBodyColumn>
        <StyledRow style={{ paddingBottom: 0 }}>
          <Grid container xs={12}>
            <Grid
              xs={2}
              item
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <SpButton
                id="saveButton"
                buttonType="accept"
                style={{ marginRight: "0", width: "100%" }}
                onClick={() => updatePathologies()}
                text={labels.patient.pastHistory.stepper.pathologies.save}
              />
            </Grid>
          </Grid>
        </StyledRow>
        <form>
          <StyledColumn>
            {pathologyRows.map((pathologyRow) => {
              const pathology = pathologies[pathologyRow.id_pathology];
              const structure = structures[pathologyRow.id_structure];

              return (
                <StyledGridContainer key={pathologyRow.id}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid container item xs={11} spacing={2}>
                      <Grid container item xs={6}>
                        <SpAutocomplete
                          id={"pathology"}
                          formControlWidth={"100%"}
                          disableListWrap
                          label={
                            labels.patient.presentation.add.selectPathology
                          }
                          selectPlaceholder={
                            labels.patient.presentation.add.selectAnswer
                          }
                          value={pathology ?? null}
                          onChange={(_, newValue) => {
                            updateActivePathologies(
                              pathologyRow.id,
                              ["id_pathology", "osiics_code"],
                              [newValue?.id ?? null, newValue?.osiics_code]
                            );
                          }}
                          options={
                            pathologyRow.id_structure
                              ? structure?.pathologies ?? []
                              : Object.values(pathologies)
                          }
                          getOptionLabel={(option) => psTranslate(option.name)}
                          getOptionSelected={(option, value) =>
                            option.id === value?.id
                          }
                          filterOptions={filterOptions}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SpAutocomplete
                          id={"structure"}
                          formControlWidth={"100%"}
                          disableListWrap
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .structure
                          }
                          selectPlaceholder={
                            labels.patient.pastHistory.stepper.pathologies
                              .structure
                          }
                          value={structure ?? null}
                          onChange={(_, newValue) => {
                            updateActivePathologies(
                              pathologyRow.id,
                              ["id_structure"],
                              [newValue?.id ?? null, null]
                            );
                          }}
                          options={
                            pathologyRow.id_pathology
                              ? pathology?.structures ?? []
                              : Object.values(structures)
                          }
                          getOptionLabel={(option) => psTranslate(option.name)}
                          getOptionSelected={(option, value) =>
                            option.id === value?.id
                          }
                          filterOptions={filterOptions}
                        />
                      </Grid>
                      {
                        <Grid item xs={6}>
                          <SpTextInput
                            id={"osiicsCode"}
                            label={
                              labels.patient.pastHistory.stepper.pathologies
                                .osiicsCode
                            }
                            disabled
                            value={
                              pathologyRow?.pathology?.osiics_code
                                ? pathologyRow?.pathology?.osiics_code
                                : pathologyRow?.osiics_code
                            }
                            formControlStyle={{ width: "100%" }}
                          />
                        </Grid>
                      }

                      <Grid item xs={4}>
                        <SpTextInput
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .operationType
                          }
                          value={pathologyRow.operation_type}
                          formControlStyle={{ width: "100%" }}
                          onChange={(evnt) =>
                            updateActivePathologies(
                              pathologyRow.id,
                              ["operation_type"],
                              [evnt.target.value]
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SpTextInput
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .currentStatus
                          }
                          value={pathologyRow.current_status}
                          formControlStyle={{ width: "100%" }}
                          onChange={(evnt) =>
                            updateActivePathologies(
                              pathologyRow.id,
                              ["current_status"],
                              [evnt.target.value]
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SpTextInput
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .rehabDuration
                          }
                          type="number"
                          minValue={0}
                          value={pathologyRow.rehab_duration}
                          formControlStyle={{ width: "100%" }}
                          onChange={(evnt) =>
                            updateActivePathologies(
                              pathologyRow.id,
                              ["rehab_duration"],
                              [evnt.target.value]
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SpTextInput
                          type="date"
                          maxValue={"2100-12-31"}
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .pathologyDate
                          }
                          value={pathologyRow.date}
                          formControlStyle={{ width: "100%" }}
                          onChange={(evnt) =>
                            updateActivePathologies(
                              pathologyRow.id,
                              ["date"],
                              [evnt.target.value]
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                      <SpIconButton
                        id={"deletePreviousInjuryBtn"}
                        variant="lightBlueFill"
                        onClick={() => {
                          setPathologyToDelete(pathologyRow);
                          setOpenDeletePathology(true);
                        }}
                      >
                        <DeleteForever />
                      </SpIconButton>
                    </Grid>
                  </Grid>
                </StyledGridContainer>
              );
            })}
            <SpIconButton
              id="addPathology"
              onClick={async () => await addPathology()}
              width={"300px"}
              additionalText={
                labels.patient.pastHistory.stepper.pathologies.addPathology
              }
            >
              <AddIcon />
            </SpIconButton>
          </StyledColumn>
        </form>
      </StyledBodyColumn>

      {/*Delete dialog*/}
      <SpDialog
        open={openDeletePathology}
        setOpen={setOpenDeletePathology}
        title={
          labels.patient.pastHistory.stepper.pathologies.pathologyDelete.title
        }
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.pastHistory.stepper.pathologies.pathologyDelete
                  .content
              }
            </SpText>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <SpButton
                id={"deletePreviousInjuryDialYes"}
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={() => removePathology(pathologyToDelete)}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenDeletePathology(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </div>
  );
};

export default withSnackbar(PathologiesInput);
