import Grid from "@material-ui/core/Grid";
import React from "react";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../../shared/translations";
import SpCheckbox from "../../../../components/atoms/SpCheckbox";
import SpText from "../../../../components/atoms/SpText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export const TestsChecks = ({ filters, setFilters }) => (
  <Grid container xs={12} direction="row">
    {/* COMPAREPERC */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.COMPAREASS}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkCOMPAREASS}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkCOMPAREASS: value,
            },
          })
        }
      />
    </Grid>

    {/* COMPAREASS */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.COMPAREPERC}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkCOMPAREPERC}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkCOMPAREPERC: value,
            },
          })
        }
      />
    </Grid>

    {/* CONFIDENCE */}
    {/* <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.CONFIDENCE}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkCONFIDENCE}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkCONFIDENCE: value,
            },
          })
        }
      />
    </Grid> */}

    {/* SYMMETRY */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.SYMMETRY}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkSYMMETRY}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkSYMMETRY: value,
            },
          })
        }
      />
    </Grid>

    {/* ASYMMETRY */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.ASYMMETRY}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkASYMMETRY}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkASYMMETRY: value,
            },
          })
        }
      />
    </Grid>

    {/* SYMMETRYASS */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.SYMMETRYASS}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.measurementsStudy.checkSYMMETRYASS}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            measurementsStudy: {
              ...filters.measurementsStudy,
              checkSYMMETRYASS: value,
            },
          })
        }
      />
    </Grid>
    {/* Z-SCORE */}
    <Grid item xs={12} style={{ padding: "8px" }} contaier direction="row">
      <Grid item xs={12} contaier direction="row">
        <SpCheckbox
          label={labels.patient.graphReport.section.feedback.studies.ZSCORE}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.measurementsStudy.checkZscore}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              measurementsStudy: {
                ...filters.measurementsStudy,
                checkZscore: value,
              },
            })
          }
        />
      </Grid>
    </Grid>

    {filters.measurementsStudy.checkZscore && (
      <>
        <Grid style={{ padding: "8px" }} item xs={3}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_REFERENCE
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkPeriod}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkPeriod: value,
                },
              })
            }
          />
          {filters.measurementsStudy.checkPeriod && (
            <SpTextInput
              label={labels.patient.graphReport.section.feedback.filters.period}
              value={filters.measurementsStudy.zScorePeriod}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  measurementsStudy: {
                    ...filters.measurementsStudy,
                    zScorePeriod: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>
        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies.ZSCORE_VALUES
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkPreviousValues}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkPreviousValues: value,
                },
              })
            }
          />
          {filters.measurementsStudy.checkPreviousValues && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.measurementsStudy.zScorePreviousValues}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  measurementsStudy: {
                    ...filters.measurementsStudy,
                    zScorePreviousValues: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies.ZSCORE_FIXED
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkFixedPeriod}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkFixedPeriod: value,
                },
              });
            }}
          />
          {filters.measurementsStudy.checkFixedPeriod && (
            <>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters
                      .start_date
                  }
                  value={filters.measurementsStudy.startDateFixedZscore}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) => {
                    setFilters({
                      ...filters,
                      measurementsStudy: {
                        ...filters.measurementsStudy,
                        startDateFixedZscore: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters.end_date
                  }
                  value={filters.measurementsStudy.endDateFixedZscore}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) =>
                    setFilters({
                      ...filters,
                      measurementsStudy: {
                        ...filters.measurementsStudy,
                        endDateFixedZscore: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    })
                  }
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_REFERENCE
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkPreviousPeriod}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkPreviousPeriod: value,
                },
              });
            }}
          />
          {filters.measurementsStudy.checkPreviousPeriod && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.measurementsStudy.previousPeriodValue}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  measurementsStudy: {
                    ...filters.measurementsStudy,
                    previousPeriodValue: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px", paddingLeft: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_VALUES
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkPreviousSubject}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkPreviousSubject: value,
                },
              })
            }
          />
          {filters.measurementsStudy.checkPreviousSubject && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.measurementsStudy.previousSubjectValue}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  measurementsStudy: {
                    ...filters.measurementsStudy,
                    previousSubjectValue: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_FIXED
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.measurementsStudy.checkFixedSubject}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                measurementsStudy: {
                  ...filters.measurementsStudy,
                  checkFixedSubject: value,
                },
              })
            }
          />
          {filters.measurementsStudy.checkFixedSubject && (
            <>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters
                      .start_date
                  }
                  value={filters.measurementsStudy.startDateFixedSubject}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) => {
                    setFilters({
                      ...filters,
                      measurementsStudy: {
                        ...filters.measurementsStudy,
                        startDateFixedSubject: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters.end_date
                  }
                  value={filters.measurementsStudy.endDateFixedSubject}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) =>
                    setFilters({
                      ...filters,
                      measurementsStudy: {
                        ...filters.measurementsStudy,
                        endDateFixedSubject: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    })
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    )}
  </Grid>
);
