/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";
import moment from "moment";
import { Grid } from "@material-ui/core";
import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientInjury,
  getPatientsSelection,
  getRange,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";
import SpIconButton from "../../../components/atoms/SpIconButton";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import {
  groupAbsenseByMonth,
  groupAttendanceByMonth,
  groupsActionMissing,
  patientAttendanceByMonth,
  patientsAbsenseByMonth,
  patientsActionMissing,
  resultGroup,
  resultPatient,
} from "./patientsReportInjury/AvailabilityFunction";
import { theme } from "../../../components/theme";
// set data range
const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";

const PatientsReportsStep13Availability = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [patientGroups, setPatientGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barCharTraining, setBarCharTraining] = useState([]);
  const [dataChartTraining, setDataChartTraining] = useState({});
  const [barCharMatch, setBarCharMatch] = useState([]);
  const [dataChartMatch, setDataChartMatch] = useState({});
  const [barCharMonthTraining, setBarCharMonthTraining] = useState([]);
  const [dataChartMonthTraining, setDataChartMonthTraining] = useState({});
  const [barCharMonthMatch, setBarCharMonthMatch] = useState([]);
  const [dataChartMonthMatch, setDataChartMonthMatch] = useState({});
  const [barCharMissingMatch, setBarCharMissingMatch] = useState([]);
  const [dataChartMissingMatch, setDataChartMissingMatch] = useState({});
  const [barCharMonthAbsenseTraining, setBarCharMonthAbsenseTraining] =
    useState([]);
  const [dataChartMonthAbsenseTraining, setDataChartMonthAbsenseTraining] =
    useState({});
  const [barCharMonthAbsenseMatch, setBarCharMonthAbsenseMatch] = useState([]);
  const [dataChartMonthAbsenseMatch, setDataChartMonthAbsenseMatch] = useState(
    {}
  );
  const [barCharMissingTraining, setBarCharMissingTraining] = useState([]);
  const [dataChartMissingTraining, setDataChartMissingTraining] = useState({});
  const [fullSizeTraining, setFullSizeTraining] = useState(false);
  const [fullSizeMatch, setFullSizeMatch] = useState(false);
  const [fullSizeMonthTraining, setFullSizeMonthTraining] = useState(false);
  const [fullSizeMonthMatch, setFullSizeMonthMatch] = useState(false);
  const [fullSizeMissingTraining, setFullSizeMissingTraining] = useState(false);
  const [fullSizeMissingMatch, setFullSizeMissingMatch] = useState(false);
  const [fullSiteMonthAbsenseTraining, setFullSiteMonthAbsenseTraining] =
    useState(false);
  const [fullSiteMonthAbsenseMatch, setFullSiteMonthAbsenseMatch] =
    useState(false);
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(false);

  const { patId, groupId } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);
        setPatients(result.allPatients);
        setPatient(
          result.allPatients.find(({ id }) => parseInt(id) === parseInt(patId))
        );
        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );
        setGroups(result.mapGroup);
        await getPatientInjury(result.mapGroup, dateRange);
        setPatientGroups(tempGroupPatient);
        // get allenamenti per ogni gruppo
        selectExposureActivity(
          tempGroupPatient,
          dateRange,
          result.allPatients,
          result.mapGroup
        );
      } else {
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );

        await getPatientInjury(tempGroups, dateRange);

        setPatientGroups(groupsSelected.patients);
        selectExposureActivity(
          [groupsSelected],
          dateRange,
          groupsSelected.patients,
          tempGroups
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error.message);
    }
  }, []);

  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param tempPatients
   * @param tempGroups
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    tempPatients = JSON.parse(JSON.stringify(patients)),
    tempGroups = groups
  ) => {
    let temp = [];
    for (let temppatient of tempPatients) {
      temppatient["injuries"] = temppatient?.injuries?.filter((injury) => {
        const end_date = moment(injury?.end_date).format("YYYY-MM-DD");
        return (
          moment(end_date).isSame(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) ||
          moment(end_date).isSame(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          (moment(end_date).isBefore(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) &&
            moment(end_date).isAfter(
              moment(dateRange?.start).format("YYYY-MM-DD")
            ))
        );
      });
    }
    setLoading(true);
    for (let tempGroup of tempGroups) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
      });
      let item = {};
      item["idGruppo"] = tempGroup.id;
      item["nameGruppo"] = tempGroup.name;
      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );

      item["training"] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "training"
      );
      item["match"] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "race"
      );
      item["patients"] = tempGroup?.patients;
      temp.push(item);
    }

    setSelectedGroups(mapGroup);
    setTrainingGroups(temp);
    setSelectedPatients([]);
    const tempPat = tempPatients.find(
      ({ id }) => parseInt(id) === parseInt(patId)
    );
    await selectionActionFunction(
      tempPat ? [tempPat] : [],
      "gruppo",
      mapGroup,
      temp
    );
    if (patId) {
      await selectMonthFunctionPatient(tempPat ? [tempPat] : [], temp);
    } else {
      const selectedGroup = temp.find(
        ({ idGruppo }) => parseInt(idGruppo) === parseInt(groupId)
      );
      await selectMonthFunctionGroup(selectedGroup);
    }
    setLoading(false);
  };

  const selectMonthFunctionGroup = (trainingGroup) => {
    return new Promise((resolve, reject) => {
      try {
        const resultMonthTrainignAttendanceGroup = groupAttendanceByMonth(
          trainingGroup,
          "training",
          dateRange
        );
        const resultMonthMatchAttendanceGroup = groupAttendanceByMonth(
          trainingGroup,
          "match",
          dateRange
        );

        const resultMonthTrainignAbsenseGroup = groupAbsenseByMonth(
          trainingGroup,
          "training",
          dateRange
        );
        const resultMonthMatchAbsenseGroup = groupAbsenseByMonth(
          trainingGroup,
          "match",
          dateRange
        );
        setDataChartMonthTraining(resultMonthTrainignAttendanceGroup.dataChart);
        setBarCharMonthTraining(resultMonthTrainignAttendanceGroup.barChart);

        setDataChartMonthMatch(resultMonthMatchAttendanceGroup.dataChart);
        setBarCharMonthMatch(resultMonthMatchAttendanceGroup.barChart);

        setDataChartMonthAbsenseTraining(
          resultMonthTrainignAbsenseGroup.dataChart
        );
        setBarCharMonthAbsenseTraining(
          resultMonthTrainignAbsenseGroup.barChart
        );

        setDataChartMonthAbsenseMatch(resultMonthMatchAbsenseGroup.dataChart);
        setBarCharMonthAbsenseMatch(resultMonthMatchAbsenseGroup.barChart);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  const selectMonthFunctionPatient = (
    patients,
    tempTrainingGroups = trainingGroups
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const resultMonthTrainingAttendance = patientAttendanceByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          "training",
          dateRange
        );

        const resultMonthMatchAttendance = patientAttendanceByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          "match",
          dateRange
        );
        const resultMonthTrainingAbsense = patientsAbsenseByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          "training",
          dateRange
        );

        const resultMonthMatchAbsense = patientsAbsenseByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          "match",
          dateRange
        );

        setDataChartMonthTraining(resultMonthTrainingAttendance.dataChart);
        setBarCharMonthTraining(resultMonthTrainingAttendance.barChart);

        setDataChartMonthMatch(resultMonthMatchAttendance.dataChart);
        setBarCharMonthMatch(resultMonthMatchAttendance.barChart);

        setDataChartMonthAbsenseTraining(resultMonthTrainingAbsense.dataChart);
        setBarCharMonthAbsenseTraining(resultMonthTrainingAbsense.barChart);

        setDataChartMonthAbsenseMatch(resultMonthMatchAbsense.dataChart);
        setBarCharMonthAbsenseMatch(resultMonthMatchAbsense.barChart);

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
  const actionAfterGroupSelection = (tempGroups) => {
    selectionActionFunction([], "group", tempGroups);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   *
   * @param tempTrainingGroups
   */
  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups,
    tempTrainingGroups = trainingGroups
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const groupPatientsFiltered = tempTrainingGroups.filter(
          ({ idGruppo }) =>
            newSelectedGroups.map(({ id }) => id).includes(idGruppo)
        );
        setSelectedGroups(newSelectedGroups);
        // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
        let newPatientsSelected = getPatientSelected(
          patients,
          type,
          selectedPatients
        );

        setSelectedPatients(newPatientsSelected);
        // calcolo delle attivita' per ogni paziente selezionato
        const resultTraining = resultPatient(
          newPatientsSelected,
          tempTrainingGroups,
          "training"
        );
        const resultGroupTraining = resultGroup(
          tempTrainingGroups,
          "training",
          newSelectedGroups
        );
        const resultMatch = resultPatient(
          newPatientsSelected,
          tempTrainingGroups,
          "match"
        );
        const resultGroupMatch = resultGroup(
          tempTrainingGroups,
          "match",
          newSelectedGroups
        );

        const resultMissingTraining = patientsActionMissing(
          newPatientsSelected,
          tempTrainingGroups,
          "training",
          dateRange
        );
        const resultMissingMatch = patientsActionMissing(
          newPatientsSelected,
          tempTrainingGroups,
          "match",
          dateRange
        );

        const resultGroupMissingMatch = groupsActionMissing(
          groupPatientsFiltered,
          "match",
          dateRange
        );
        const resultGroupMissingTraining = groupsActionMissing(
          groupPatientsFiltered,
          "training",
          dateRange
        );

        // concateno il risultato dei gruppi con quello dei pazienti
        resultTraining.dataChart =
          resultGroupTraining.dataChart !== undefined
            ? resultTraining.dataChart.concat(resultGroupTraining.dataChart)
            : resultTraining.dataChart;

        resultMatch.dataChart =
          resultGroupMatch.dataChart !== undefined
            ? resultMatch.dataChart.concat(resultGroupMatch.dataChart)
            : resultMatch.dataChart;

        resultMissingTraining.dataChart =
          resultGroupMissingTraining.dataChart !== undefined
            ? resultMissingTraining.dataChart.concat(
                resultGroupMissingTraining.dataChart
              )
            : resultMissingTraining.dataChart;

        resultMissingMatch.dataChart =
          resultGroupMissingMatch.dataChart !== undefined
            ? resultMissingMatch.dataChart.concat(
                resultGroupMissingMatch.dataChart
              )
            : resultMissingMatch.dataChart;

        setDataChartTraining(resultTraining.dataChart);
        setBarCharTraining(resultTraining.barChart);

        setDataChartMatch(resultMatch.dataChart);
        setBarCharMatch(resultMatch.barChart);

        setDataChartMissingTraining(resultMissingTraining.dataChart);
        setBarCharMissingTraining(resultMissingTraining.barChart);

        setDataChartMissingMatch(resultMissingMatch.dataChart);
        setBarCharMissingMatch(resultMissingMatch.barChart);

        setLoading(false);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      setDateRange(newDateRange);

      if (patId) {
        setSelectedGroups([]);
        selectExposureActivity(patientGroups, newDateRange);
      } else {
        selectExposureActivity(selectedGroups, newDateRange);
      }
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>
      <Grid item container xs={8}>
        {/* selezione dei pazienti */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}
        {groupId &&
          getGroupsSelection(groups, selectedGroups, actionAfterGroupSelection)}
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={fullSizeTraining ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.analytics.injuryReport.graphs
                  .trainingAttendancePercentage
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeTraining(!fullSizeTraining);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeTraining ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartTraining}
            barChar={barCharTraining}
          />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeMatch ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.graphs.matchAttendancePercentage}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMatch(!fullSizeMatch);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMatch ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport dataChart={dataChartMatch} barChar={barCharMatch} />
        </Grid>
        {/* Month avaibility chart for training */}
        <Grid
          container
          item
          xs={fullSizeMonthTraining ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {patId
                ? labels.analytics.injuryReport.graphs
                    .trainingAttendanceAtlete +
                  patient?.givenName +
                  " " +
                  patient?.familyName
                : labels.analytics.injuryReport.graphs
                    .trainingAttendanceAtlete + selectedGroups[0]?.name}
            </SpText>

            <SpIconButton
              onClick={() => {
                setFullSizeMonthTraining(!fullSizeMonthTraining);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMonthTraining ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMonthTraining}
            barChar={barCharMonthTraining}
          />
        </Grid>

        {/* Month avaibility chart for match */}
        <Grid
          container
          item
          xs={fullSizeMonthMatch ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {patId
                ? labels.analytics.injuryReport.graphs.matchAttendanceAtlete +
                  patient?.givenName +
                  " " +
                  patient?.familyName
                : labels.analytics.injuryReport.graphs.matchAttendanceAtlete +
                  selectedGroups[0]?.name}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMonthMatch(!fullSizeMonthMatch);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMonthMatch ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMonthMatch}
            barChar={barCharMonthMatch}
          />
        </Grid>

        {/* Month absence chart for training */}
        <Grid
          container
          item
          xs={fullSiteMonthAbsenseTraining ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {patId
                ? labels.analytics.injuryReport.graphs.trainingAbsenceMonth +
                  patient?.givenName +
                  " " +
                  patient?.familyName
                : labels.analytics.injuryReport.graphs.trainingAbsenceMonth +
                  selectedGroups[0]?.name}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSiteMonthAbsenseTraining(!fullSiteMonthAbsenseTraining);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSiteMonthAbsenseTraining ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMonthAbsenseTraining}
            barChar={barCharMonthAbsenseTraining}
          />
        </Grid>

        {/* Month absence chart for match */}
        <Grid
          container
          item
          xs={fullSiteMonthAbsenseMatch ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {patId
                ? labels.analytics.injuryReport.graphs.matchAbsenceMonth +
                  patient?.givenName +
                  " " +
                  patient?.familyName
                : labels.analytics.injuryReport.graphs.matchAbsenceMonth +
                  selectedGroups[0]?.name}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSiteMonthAbsenseMatch(!fullSiteMonthAbsenseMatch);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSiteMonthAbsenseMatch ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMonthAbsenseMatch}
            barChar={barCharMonthAbsenseMatch}
          />
        </Grid>

        {/* Missing chart for training */}
        <Grid
          container
          item
          xs={fullSizeMissingTraining ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.graphs.monthTrainingAbsense}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMissingTraining(!fullSizeMissingTraining);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMissingTraining ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMissingTraining}
            barChar={barCharMissingTraining}
          />
        </Grid>

        {/* Missing chart for match */}
        <Grid
          container
          item
          xs={fullSizeMissingMatch ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.graphs.monthMatchAbsense}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMissingMatch(!fullSizeMissingMatch);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMissingMatch ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartMissingMatch}
            barChar={barCharMissingMatch}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep13Availability);
