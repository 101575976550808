import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../App.css";
import { dateFormat } from "../../../utils/common";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { DeleteForever } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import SpTextArea from "../../../components/atoms/SpTextArea";
import Moment from "moment";
import { extendMoment } from "moment-range";
import SpButton from "../../../components/atoms/SpButton";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../shared/translations";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";

const moment = extendMoment(Moment);
import {
  getMedicalNotes,
  addMedicalNotes,
  removeMedicalNotes,
} from "../../../models/actions/MedicalNotes";

const MEDICAL_NOTE_TYPE = "report";

const DEFAULT_RANGE = moment.rangeFromInterval("month", -6, moment());
const constPadding = { padding: "5px" };
const userDateFormat = "DD/MM/yyyy";

const PatientsMedicalNotesReports = (props) => {
  const { patId } = useParams();

  const [displayReports, setDisplayReports] = useState();
  const [dateSelected, setDateSelected] = useState(moment());
  const [noteSelected, setNoteSelected] = useState("");
  const [objectSelected, setObjectSelected] = useState([]);
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);

  const { register } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    const res = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date: dateRange.start,
        end_date: dateRange.end,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDisplayReports(res.answer);
  }, []);

  const headCellsFirst = [
    {
      type: "string",
      field: "subject",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.report.subject),
      flex: 1,
      valueGetter: (params) => params.row.subject,
      renderCell: (params) => (
        <SpText variant={"tableText"} id="subjectCell">
          {params.row.subject}
        </SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "string",
      field: "note",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.monitoring.pathologiesStep.note),
      flex: 1.5,
      valueGetter: (params) => params.row.subject,
      renderCell: (params) => (
        <SpText variant={"tableText"} id="noteCell">
          {params.row.note_report}
        </SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "date",
      field: "date",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.report.date),
      flex: 0.5,
      valueGetter: (params) =>
        moment(params.row.endDate).format(userDateFormat),
      renderCell: (params) => (
        <SpText variant={"tableText"}>
          {moment(params.row.date).format(userDateFormat)}
        </SpText>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
    {
      type: "action",
      field: "action",
      numeric: false,
      editable: false,
      sortable: false,
      headerName: " ",
      flex: 0.5,
      renderCell: (params) => (
        <SpIconButton
          id="deleteButton"
          variant="lightBlueFill"
          onClick={async () => {
            const resRemove = await removeMedicalNotes({ id: params.id });
            if (resRemove.error) {
              props.snackbarShowErrorMessage(resRemove.message);
            } else {
              props.snackbarShowMessage(resRemove.message);
            }
            props.snackbarShowMessage(resRemove.message);
            setDisplayReports(
              displayReports.filter(({ id }) => id != params.id)
            );
          }}
        >
          <DeleteForever />
        </SpIconButton>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
  ];

  const searchReport = async ({ start_date, end_date }) => {
    const resultSearch = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date,
        end_date,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDisplayReports(resultSearch.answer);
  };

  const addReport = async () => {
    const resAdd = await addMedicalNotes({
      data: {
        id_patient: patId,
        subject: objectSelected,
        note_report: noteSelected,
        date: dateSelected,
        type: MEDICAL_NOTE_TYPE,
      },
    });

    if (resAdd.message) {
      setDisplayReports([...displayReports, resAdd.body]);
      props.snackbarShowMessage(resAdd.message);
    } else {
      props.snackbarShowErrorMessage(resAdd.error);
    }

    setNoteSelected("");
    setDateSelected(moment());
    setObjectSelected("");
  };

  const gridReport = (
    <>
      {/* Add report section */}
      <Grid container spacing={3}>
        <Grid container>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.report.add)}
            </SpText>
          </Grid>
          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              name="subject"
              id="subject"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setObjectSelected(evnt.target.value);
              }}
              value={objectSelected}
              label={psTranslate(
                labels.patient.medicalNotes.report.subject.toUpperCase()
              )}
            />
          </Grid>

          {/* Dates input*/}
          <Grid item xs={2} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.medicalNotes.report.date.toUpperCase()
              )}
              value={moment(dateSelected).format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                setDateSelected(moment(evnt.target.value).format(dateFormat));
              }}
            />
          </Grid>
          <Grid item xs={8} style={constPadding}>
            <SpTextArea
              value={noteSelected}
              id="notes"
              name="notes"
              label={psTranslate(
                labels.patient.monitoring.pathologiesStep.note.toUpperCase()
              )}
              onChange={(event) => setNoteSelected(event.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <SpButton
              id="addFormButton"
              style={{ marginTop: "1em" }}
              text={psTranslate(labels.patient.medicalNotes.report.addButton)}
              buttonType={"accept"}
              variant="none"
              onClick={addReport}
            />
          </Grid>
        </Grid>

        {/* Search report section */}
        <Grid container style={{ paddingTop: "4em" }}>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.report.search)}
            </SpText>
          </Grid>

          {/* Dates */}
          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.start_date
              )}
              value={dateRange.start.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                moment(evnt.target.value).isBefore(dateRange.end) &&
                  setDateRange(moment.range(evnt.target.value, dateRange.end));
                searchReport({
                  start_date: evnt.target.value,
                  end_date: dateRange.end,
                });
              }}
            />
          </Grid>

          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.end_date
              )}
              value={dateRange.end.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                dateRange.start.isBefore(moment(evnt.target.value)) &&
                  setDateRange(
                    moment.range(dateRange.start, evnt.target.value)
                  );
                searchReport({
                  start_date: dateRange.start,
                  end_date: evnt.target.value,
                });
              }}
            />
          </Grid>
        </Grid>

        {/* Table report section */}
        {displayReports?.length > 0 && (
          <Grid container style={{ marginLeft: "4px" }} id="reportGrid">
            <SpDataGrid
              rows={displayReports}
              headers={headCellsFirst}
              emptyText={psTranslate(labels.groups.groupReports.no_patients)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );

  return gridReport;
};

export default withSnackbar(PatientsMedicalNotesReports);
