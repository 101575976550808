import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, Sector, ResponsiveContainer } from "recharts";
import "../../../../App.css";
import { SpAutocomplete } from "../../../../components/atoms/SpAutocomplete";
import SpButton from "../../../../components/atoms/SpButton";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import { getDysfunctionsCategories } from "../../../../models/actions/Pathologies";
import { labels, psTranslate } from "../../../shared/translations";
import { theme } from "../../../../components/theme";

const DysfunctionsDisorder = ({
  config,
  setConfig,
  requestSaveFlag,
  allData,
  ...props
}) => {
  const [dysfunctionCategories, setDysfunctionCategories] = useState([]);

  const [selectedDysfunctionCategory, setSelectedDysfunctionCategory] =
    useState();

  const [disorderCakeData, setDisorderCakeData] = useState([]);
  const [activeIndexDisorder, setActiveIndexDisorder] = useState([0]);

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    theme.colors.primary.lightBlue,
  ];

  const onPieEnterDisorder = (_, index) => {
    setActiveIndexDisorder(index);
  };
  const fetchData = async () => {
    try {
      const dysfunctionCategories = await getDysfunctionsCategories();
      setDysfunctionCategories(dysfunctionCategories);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateGraph = () => {
    setDisorderCakeData(
      allData?.reduce((accum, data) => {
        const dysfCat =
          data.structure_dysfunction?.dysfunction?.dysfunction_category;
        if (
          !selectedDysfunctionCategory ||
          selectedDysfunctionCategory.id === dysfCat.id
        ) {
          const regionName = psTranslate(
            data.structure_dysfunction?.structure?.area?.region?.name
          );
          if (
            accum.filter((e) => regionName === psTranslate(e.name)).length > 0
          ) {
            accum.find(
              (element) => psTranslate(element.name) === regionName
            ).value += dysfCat?.weight * data?.severity;
          } else {
            accum.push({
              name: regionName,
              value: dysfCat?.weight * data?.severity,
            });
          }
        }
        return accum;
      }, [])
    );
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(() => {
    if (allData) {
      updateGraph();
    }
  }, [allData, selectedDysfunctionCategory]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={theme.colors.primary.white}
          style={{ fontWeight: "bold" }}
        >{`x${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={theme.colors.primary.lightBlue}
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const NoData = () => (
    <Grid item xs={12}>
      <Grid
        direction="column"
        container
        xs={12}
        style={{ marginTop: "3%", padding: "1%" }}
      >
        <SpText variant="h1PageTitle">{labels.general.no_data}</SpText>
      </Grid>
    </Grid>
  );

  const ResetButton = ({ onClick }) => (
    <Grid item xs={3}>
      <SpButton
        buttonType={"accept"}
        variant="none"
        text={labels.general.reset}
        style={{ width: "100%" }}
        onClick={onClick}
      />
    </Grid>
  );

  const MyAutocomplete = ({ label, value, onChange, options }) => (
    <Grid item xs={9}>
      <SpAutocomplete
        label={label}
        multiple={false}
        formControlStyle={{ width: "99%" }}
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => psTranslate(option.name)}
        getOptionSelected={(option, value) => option.id === value?.id}
      />
    </Grid>
  );

  return (
    <Grid
      item
      xs={12}
      style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
      direction="column"
    >
      <Grid container xs={12} style={{ padding: "1%" }} direction="column">
        <SpText variant="h1PageTitle">
          {labels.analytics.disturbedRegions}
        </SpText>
        <SpText variant="h4ComponentLabel">
          {
            labels.patient.graphReport.section.disorder.filters
              .disorder_description
          }
        </SpText>
      </Grid>
      <Grid container xs={12} style={{ padding: "1%", alignItems: "self-end" }}>
        <MyAutocomplete
          label={labels.analytics.filterByDisorder}
          value={selectedDysfunctionCategory}
          onChange={(_, newValue) => setSelectedDysfunctionCategory(newValue)}
          options={dysfunctionCategories}
        />
        <ResetButton
          onClick={() => {
            setSelectedDysfunctionCategory(null);
            updateGraph(false, true);
          }}
        />
      </Grid>
      {disorderCakeData.length === 0 && <NoData />}
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart style={{ margin: "0 auto" }}>
            <Pie
              activeIndex={activeIndexDisorder}
              activeShape={renderActiveShape}
              data={disorderCakeData}
              cx="50%"
              cy="50%"
              innerRadius={120}
              outerRadius={140}
              nameKey="name"
              dataKey="value"
              onMouseEnter={onPieEnterDisorder}
            >
              {disorderCakeData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(DysfunctionsDisorder);
