import { call } from "./utils";

export const getPresentationById = async (data) =>
  await call({ url: "/presentation/by/id", data: { data } });

export const getPresentationDetailById = async (data) =>
  await call({ url: "/presentationdetail/by/id", data: { data } });

export const getPresentationResourcesById = async (data) =>
  await call({ url: "/get/presentation/resources/by/id", data: { data } });

export const updatePresentationResourcesById = async (data) =>
  await call({ url: "/update/presentation/resources/by/id", data: { data } });

export const removePresentationResourcesById = async (data) =>
  await call({ url: "/remove/presentation/resources/by/id", data: { data } });

export const getPresentationTypeList = async () =>
  await call({ url: "/presentation/type/list" });

export const getRedFlagsByPresentationId = async (data) =>
  await call({
    url: "/red/flags/by/presentation/id",
    data: { data },
  });

export const getPresentationsByPatProf = async (data) =>
  await call({
    url: "/presentation/by/prof/pat",
    data: { data },
  });
export const getGroupPresentations = async (data) =>
  await call({
    url: "/group/presentations",
    data: { data },
  });

export const createPresentation = async (data) =>
  await call({ url: "/presentation/create", data: { data } });

export const deletePresentationById = async (data) =>
  await call({ url: "/presentation/delete", data: { data } });

export const updatePresentation = async (data) => {
  return await call({ url: "/presentation/update", data: { data } });
};

export const deleteExitCriteria = async (data) =>
  await call({
    url: "/presentation/delete/exitcriteria",
    data: { data },
  });

export const updateExitCriteria = async (data) =>
  await call({
    url: "/presentation/update/exitcriteria",
    data: { data },
  });

export const getExitCriteriaByPresentation = async (data) =>
  await call({
    url: "/presentation/exitcriteria/by/presentation",
    data: { data },
  });

export const closePresentation = async (data) =>
  await call({ url: "/presentation/close", data: { data } });

export const getMilestonesByPresentation = async (data) =>
  await call({
    url: "/presentation/milestones/by/presentation",
    data: { data },
  });

export const updateMilestone = async (data) =>
  await call({ url: "/presentation/update/milestone", data: { data } });

export const deleteMilestone = async (data) =>
  await call({ url: "/presentation/delete/milestone", data: { data } });

export const updateSinsrPresentation = async (data) =>
  await call({
    url: "/presentation/update/sinsr",
    data: { data },
  });

export const createSinsrPresentation = async (data) =>
  await call({
    url: "/presentation/create/sinsr",
    data: { data },
  });

export const getSinsrByPresentation = async (data) =>
  await call({ url: "/presentation/get/sinsr", data: { data } });

export const getSinsr = async () =>
  await call({ method: "GET", url: "/sinsr/list" });
