import React, { useEffect, useState, createRef } from "react";
import "../App.css";
import SpText from "../components/atoms/SpText";
import { labels, psTranslate } from "./shared/translations";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import { isMobile } from "react-device-detect";
import { Divider, Grid } from "@material-ui/core";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import {
  getProfessionalStats,
  professionalsStats,
} from "../models/actions/Professionals";
import SpTextInput from "../components/atoms/SpTextInput";
import SpButton from "../components/atoms/SpButton";
import { useForm } from "react-hook-form";
import moment from "moment";
import { isFeatureFlagEnabled, downloadScreenshot } from "../utils/common";
import PatientsReportsPDF from "./patients/patientsReports/PatientsReportsPDF";
import printContext from "../utils/printContext";
import { theme } from "../components/theme";
import { getBookingsList } from "../models/actions/Organization";
import ReportGenerator from "./ReportGenerator";
import SpLoader from "../components/atoms/SpLoader";
import ProfessionalReportGenerator from "./ProfessionalReportGenerator";

const presentationHeadCells = [
  {
    id: "region",
    numeric: false,
    disablePadding: false,
    label: labels.account.accountReports.table.header.region,
    isAction: false,
  },
  {
    id: "presentationNumber",
    numeric: false,
    disablePadding: false,
    label: labels.account.accountReports.table.header.presentationNumber,
    isAction: false,
  },
];

const activitiesHeadCells = [
  {
    id: "activity",
    numeric: false,
    disablePadding: false,
    label: labels.analytics.account.activity,
    isAction: false,
  },
  {
    id: "activityNumber",
    numeric: false,
    disablePadding: false,
    label: labels.analytics.account.sessionNumber,
    isAction: false,
  },
];

const AccountReports = (props) => {
  const [stats, setStats] = useState(null);
  const [professionalStats, setProfessionalStats] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(
    moment().subtract(6, "month").format("YYYY-MM-DD")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [refreshData, setRefreshData] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const reference = createRef(null);
  const [isPrint, setIsPrint] = useState(false);
  const [handleClick, setHandleClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm({
    shouldUnregister: false,
    defaultValues: {
      type: null,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    localStorage.removeItem("pdfScreenshot");
    try {
      const result = await getProfessionalStats({
        start_date: filterStartDate,
        end_date: filterEndDate,
      });

      const temp = await professionalsStats({});
      setProfessionalStats(temp);
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setStats(result);
        setIsUpdate(filterStartDate && filterEndDate);
      }
      setLoading(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const checkNotification = async () => {
    const ret = await getBookingsList();
    props.setMyLabNotification(ret.some(({ status }) => status == "pending"));
  };

  useEffect(async () => {
    await checkNotification();
    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    localStorage.removeItem("pdfScreenshot");

    setPdfFeatureFlag(tempPdfFeatureFlag);
    await fetchData();
  }, []);

  const defaultValues = {
    filter_start_date: null,
    filter_end_date: null,
  };

  const resetForm = async () => {
    reset(defaultValues);
    setIsUpdate(false);
    setFilterStartDate(null);
    setFilterEndDate(null);
    setRefreshData(!refreshData);
  };

  useEffect(async () => {
    await fetchData();
  }, [refreshData]);

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <div ref={reference}>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container spacing={1}>
            <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1"}>
              {labels.account.accountReports.title}{" "}
              {filterStartDate && filterEndDate && isUpdate
                ? `(${labels.analytics.account.helperText1} ${moment(
                    filterStartDate
                  ).format("DD/MM/YYYY")} ${
                    labels.analytics.account.helperText2
                  } ${moment(filterEndDate).format("DD/MM/YYYY")})`
                : ""}
            </SpText>
          </Grid>
          {!isPrint && (
            <form onSubmit={handleSubmit(fetchData)}>
              <Grid item container xs={12} direction="row">
                <Grid item xs={4}>
                  <SpTextInput
                    name="filter_start_date"
                    label={labels.analytics.account.startDate}
                    type="date"
                    maxValue={"2100-12-31"}
                    variant="text"
                    style={{ marginRight: "2px", width: "49%" }}
                    value={filterStartDate}
                    inputRef={register}
                    onChange={(e) => {
                      setIsUpdate(false);
                      setFilterStartDate(e.target.value);
                    }}
                  />
                  <SpTextInput
                    name="filter_end_date"
                    label={labels.analytics.account.endDate}
                    type="date"
                    maxValue={"2100-12-31"}
                    variant="text"
                    style={{ marginRight: "2px", width: "49%" }}
                    value={filterEndDate}
                    inputRef={register}
                    onChange={(e) => {
                      setIsUpdate(false);
                      setFilterEndDate(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={8} style={{ marginTop: 19 }}>
                  <SpButton
                    buttonType="accept"
                    text={labels.analytics.account.updateData}
                    type="submit"
                  />
                  <SpButton
                    buttonType="accept"
                    text={labels.analytics.account.reset}
                    onClick={() => {
                      resetForm();
                    }}
                  />
                  <SpButton
                    buttonType="accept"
                    text={"download"}
                    onClick={() => {
                      setHandleClick(!handleClick);
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          )}

          <Grid item xs={12} container style={{ marginTop: "1%" }}>
            <Grid
              item
              container
              xs={3}
              style={{
                border: `1px solid ${theme.colors.primary.lightBlue}`,
                padding: "8px",
              }}
              direction="column"
            >
              <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}>
                {labels.analytics.account.avgPresSatisfaction}
              </SpText>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}
              >
                {stats?.averageSatisfaction
                  ? parseFloat(stats?.averageSatisfaction).toFixed(2)
                  : 0}
              </SpText>
            </Grid>
            <Grid
              item
              container
              xs={3}
              style={{
                border: `1px solid ${theme.colors.primary.lightBlue}`,
                padding: "8px",
              }}
              direction="column"
            >
              <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}>
                {labels.analytics.account.patNumber}
              </SpText>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}
              >
                {stats?.patientNumber}
              </SpText>
            </Grid>
            <Grid
              item
              container
              xs={3}
              style={{
                border: `1px solid ${theme.colors.primary.lightBlue}`,
                padding: "8px",
              }}
              direction="column"
            >
              <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}>
                {labels.analytics.account.groupNumber}
              </SpText>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}
              >
                {stats?.groupNumber}
              </SpText>
            </Grid>
            <Grid
              item
              container
              xs={3}
              style={{
                border: `1px solid ${theme.colors.primary.lightBlue}`,
                padding: "8px",
              }}
              direction="column"
            >
              <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}>
                {labels.analytics.account.actNumber}
              </SpText>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}
              >
                {stats?.activityNumber}
              </SpText>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  marginTop: "1%",
                  height: 3,
                  marginBottom: "1%",
                }}
              />
            </Grid>
            <Grid item xs={12} spacing={2}>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}
                style={{ marginTop: "1%", marginBottom: "1%" }}
              >
                {labels.analytics.account.summaryPresentation}
              </SpText>
              <Grid item xs={12} container>
                <Grid
                  item
                  container
                  xs={3}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                  direction="column"
                >
                  <SpText
                    variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}
                  >
                    {labels.analytics.account.presTypeFreq}
                  </SpText>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                    }
                  >
                    {psTranslate(stats?.mostFrequentPresentationType)}
                  </SpText>
                </Grid>
                <Grid
                  item
                  container
                  xs={3}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                  direction="column"
                >
                  <SpText
                    variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}
                  >
                    {labels.analytics.account.presLonger}
                  </SpText>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                    }
                  >
                    {stats?.longerPresentationDays}{" "}
                    {labels.analytics.account.days}
                  </SpText>
                </Grid>
                <Grid
                  item
                  container
                  xs={3}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                  direction="column"
                >
                  <SpText
                    variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}
                  >
                    {labels.analytics.account.areaAffected}
                  </SpText>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                    }
                  >
                    {psTranslate(stats?.mostAffectedArea)}
                  </SpText>
                </Grid>
                <Grid
                  item
                  container
                  xs={3}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                  direction="column"
                >
                  <SpText
                    variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}
                  >
                    {labels.analytics.account.structureAffected}
                  </SpText>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                    }
                  >
                    {psTranslate(stats?.mostAffectedStructure)}
                  </SpText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  marginTop: "1%",
                  height: 3,
                  marginBottom: "1%",
                }}
              />
            </Grid>
            <Grid item xs={12} spacing={2}>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}
                style={{ marginTop: "1%", marginBottom: "1%" }}
              >
                {labels.analytics.account.summaryActivity}
              </SpText>
              <Grid item xs={12} container>
                <Grid
                  item
                  container
                  xs={3}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                  direction="column"
                >
                  <SpText
                    variant={isPrint ? "h4ComponentLabelPrint" : "h4regular"}
                  >
                    {labels.analytics.account.activityFreq}
                  </SpText>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                    }
                  >
                    {psTranslate(stats?.mostFrequentActivity)}
                  </SpText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {stats && (
        <ReportGenerator
          reportData={stats}
          handleClick={handleClick}
          setHandleClick={setHandleClick}
          setLoading={setLoading}
        />
      )}
      {professionalStats && (
        <ProfessionalReportGenerator
          reportData={professionalStats}
          handleClick={handleClick}
          setHandleClick={setHandleClick}
          setLoading={setLoading}
        />
      )}
    </printContext.Provider>
  );
};

export default withSnackbar(AccountReports);
