import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getProfessionalById } from "../../../models/actions/Professionals";
import SpText from "../../../components/atoms/SpText";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { styled } from "../../../components/styled";
import { labels } from "../translations";
import LanguagesFlags from "../../../components/bundles/LanguagesFlags";
import { theme } from "../../../components/theme";

const TrialDiv = styled(Grid)({
  border: "1px yellow solid",
  borderRadius: "3px",
  paddingLeft: "5px",
  paddingRight: "5px",
});

const Profile = () => {
  const [professionalData, setProfessionalData] = useState(null);
  const history = useHistory();

  const fetchData = async () => {
    try {
      const response = await getProfessionalById();
      setProfessionalData(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      container
      style={{ alignItems: "center", justifyContent: "flex-end" }}
    >
      {professionalData?.email && (
        <Grid
          id={"profileContainer"}
          container
          item
          xs={professionalData?.name && professionalData?.surname ? 12 : 1}
          direction="row"
          style={{ alignItems: "center" }}
        >
          {professionalData?.trial && (
            <TrialDiv>
              <SpText id={"trialText"} variant={"trialText"}>
                {labels.general.trialText}
              </SpText>
            </TrialDiv>
          )}
          <SpIconAndTextButton
            id={"profileIconButton"}
            isIconRight={true}
            text={
              professionalData?.name
                ? `${professionalData?.name} ${professionalData?.surname}`
                : ""
            }
            textVariant={"nameSurnameHeader"}
            onClick={() => history.push(`/account`)}
          >
            <AccountCircle
              id={"profileIconCircle"}
              style={{
                marginLeft: "5px",
                width: 25,
                color: theme.colors.primary.lightBlue,
                height: 25,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
          <LanguagesFlags />
        </Grid>
      )}
    </Grid>
  );
};

export default Profile;
