import { Avatar, Button as MuiButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  CloudUpload as MuiCloudUpload,
  DeleteForever,
} from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import SpIconButton from "../../components/atoms/SpIconButton";
import SpSearch from "../../components/atoms/SpSearch";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import { getUserPermission, titleCase } from "../../utils/common";
import GroupEditSidebar from "./shared/GroupEditSidebar";

import {
  deletePatientGroup,
  deleteProfessionalGroup,
  getGroupsById,
  setGroupProfileImage,
  updateGroup,
} from "../../models/actions/Groups";
import { findPatients } from "../../models/actions/Patients";
import {
  getProfessionalById,
  getResourceFromServer,
} from "../../models/actions/Professionals";
import { rollbar } from "../../utils/common";
import ProfessionalShareDialog from "../shared/dialogs/ProfessionalShareDialog";
import { labels } from "../shared/translations";
import { uploadFile } from "../../models/actions/awsUtils";
import SpTooltip from "../../components/atoms/SpTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
}));

import { useAuth0 } from "@auth0/auth0-react";

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});
const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});
const rolesRows = [
  { id: 1, name: labels.groups.roles.admin, value: "admin" },
  { id: 2, name: labels.groups.roles.reader, value: "reader" },
];

const PatientsGroup = (props) => {
  const [openProfessional, setOpenAddProfessional] = useState(false);
  const [openPatient, setOpenAddPatient] = useState(false);
  const [openDeleteProfessional, setOpenDeleteProfessional] = useState(false);
  const [openDeletePatient, setOpenDeletePatient] = useState(false);
  const [searchStringPat, setSearchStringPat] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [usersFound, setUsersFound] = useState([]);
  const [patientsList, setPatients] = useState([]);
  const [professionalsList, setProfessionals] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [currentProfessionalRole, setCurrentProfessionalRole] = useState(null);
  const [profileImage, setProfileImg] = useState();
  const [professionalData, setProfessionalData] = useState({});
  const [currentGroup, setCurrentGroup] = useState([]);
  const [isPatientLabel, setIsPatientLabel] = useState(false);

  const { groupId } = useParams();
  const classes = useStyles();
  const { setLoading } = props;

  const history = useHistory();

  const { reset, control, register, handleSubmit, getValues, setValue } =
    useForm({
      shouldUnregister: false,
    });

  const {
    fields: fieldsPatient,
    append: appendPatient,
    remove: removePatient,
  } = useFieldArray({
    control: control,
    key: "id",
    name: "patients",
  });

  const {
    fields: fieldsProf,
    append: appendProf,
    remove: removeProfessional,
  } = useFieldArray({
    control: control,
    key: "id",
    name: "professionals",
  });

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      setLoading(true);
      const ProfessionalProfile = await getProfessionalById();
      setProfessionalData(ProfessionalProfile);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);

      setPatients(groupResults.patients);
      setProfessionals(groupResults.professionals);
      if (groupResults.file) {
        const profileBase64 = await getResourceFromServer({
          file: groupResults.file,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }
      const formattedProfessionalArray = [];
      groupResults.professionals.map((prof) => {
        if (prof.id == ProfessionalProfile.id)
          setCurrentProfessionalRole(prof.professional_group.role);
        formattedProfessionalArray.push({
          id: prof.id,
          professional: prof,
          role: prof.professional_group.role,
        });
      });
      reset({
        id: groupResults?.id,
        name: groupResults.name,
        description: groupResults.description,
        patients: groupResults.patients,
        professionals: formattedProfessionalArray,
      });

      const patientLabelTemp = await getUserPermission(
        "patient-label",
        getAccessTokenSilently
      );
      setIsPatientLabel(patientLabelTemp);
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //upload group image functions
  const uploadProfileImage = async (file) => {
    try {
      //setting up the file to be uploaded
      if (file) {
        await uploadFile(file);
        const fileData = {
          name: file.name,
          extension: file.name.split(".")[file.name.split(".").length - 1],
        };

        const result = await setGroupProfileImage({
          id_group: groupId,
          file: fileData,
        });
        if (result) {
          props.snackbarShowMessage(result.message);
          fetchData();
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - uploadProfileImage", error);
    }
  };

  const onFileChange = (e) => {
    uploadProfileImage(e.target.files[0]);
    fetchData();
  };

  const handleClick = (event) => {
    if (profileImage) {
      event.preventDefault();
      setProfileImg(null);
    }
  };

  const updateGroupData = async (data) => {
    try {
      const results = await updateGroup(data);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - updateGroupData", error);
    }
  };

  const addProfessionalToGroup = async (professional, selectValue) => {
    try {
      if (!selectValue) {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.selectRole
        );
        return;
      }
      if (
        fieldsProf.filter((prof) => prof?.id == professional?.id).length <= 0
      ) {
        appendProf({
          id: professional?.id,
          professional: professional,
          role: rolesRows[selectValue - 1].value,
        });
        setUsersFound([]);
        setSearchString(null);
        updateGroupData(getValues());
        setOpenAddProfessional(false);
      } else {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.professionalAlreadyExists
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - addProfessionalToGroup", error);
    }
  };

  const addPatientToGroup = async (patient) => {
    try {
      if (fieldsPatient.filter((prof) => prof?.id == patient?.id).length <= 0) {
        appendPatient({ id: patient?.id, patient: patient });
        setUsersFound([]);
        setSearchString(null);
        setOpenAddPatient(false);
        await updateGroup(getValues());
      } else {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.patientAlreadyExists
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - addPatientToGroup", error);
    }
  };

  const searchPatients = async () => {
    if (searchString) {
      try {
        await findPatients({ pattern: searchString }).then(async (response) => {
          if (response.length > 0) {
            setUsersFound(response);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("GroupsDetail - searchPatients", error);
      }
    }
  };

  const removePatientFromServer = async (answer) => {
    try {
      if (answer) {
        const idx = selectedPatient?.index;
        const profListNoCurrent = professionalsList.filter(
          (prof) => prof.id !== professionalData.id
        );
        if (selectedPatient?.patient) {
          const result = await deletePatientGroup({
            id_patient: selectedPatient?.patient,
            id_group: groupId,
            professionals: profListNoCurrent,
          });
          if (result.message) {
            removePatient(idx);
            props.snackbarShowMessage(result.message);
          }
        }
      } else {
        const idx = selectedPatient?.index;
        if (selectedPatient?.patient) {
          const result = await deletePatientGroup({
            id_patient: selectedPatient?.patient,
            id_group: groupId,
          });
          if (result.message) {
            removePatient(idx);
            props.snackbarShowMessage(result.message);
          }
        }
      }
      setOpenDeletePatient(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removePatientFromServer", error);
    }
  };

  const removeProfessionalFromServer = async (answer) => {
    try {
      if (answer) {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
            patients: patientsList,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      } else {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      }
      setOpenDeleteProfessional(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removeProfessionalFromServer", error);
    }
  };

  const resetStateDialog = () => {
    setUsersFound(null);
    setSearchString(null);
  };

  // Columns
  const headCellsPatient = [
    {
      id: "givenName",
      width: "18em",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.name,
    },
    {
      id: "familyName",
      width: "18em",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.surname,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.email,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.actions,
    },
  ];

  const filterPat = (e) => {
    setSearchStringPat(e.currentTarget.value);
    let newDisplayFilters = [];
    newDisplayFilters = patientsList?.filter((patient) =>
      `${patient.givenName} ${patient.familyName}`
        .toString()
        .trim()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().trim().toLowerCase())
    );
    setValue("patients", newDisplayFilters);
    if (e.currentTarget.value === "") setValue("patients", patientsList);
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  const GroupAddPatientRow = ({ row, index }) => {
    const { patient } = row;
    return (
      <>
        <SpTableRow role="checkbox" key={patient?.id} tabIndex={index}>
          <SpTableCell>
            <SpIconAndTextButton
              isIconRight={true}
              text={`${titleCase(
                patient ? patient?.givenName : row.givenName
              )}`}
              textVariant={"tableTextDetails"}
              onClick={() =>
                history.push(`/patients/edit/${patient ? patient?.id : row.id}`)
              }
            ></SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <SpIconAndTextButton
              isIconRight={true}
              text={`${titleCase(
                patient ? patient?.familyName : row.familyName
              )}`}
              textVariant={"tableTextDetails"}
              onClick={() =>
                history.push(`/patients/edit/${patient ? patient?.id : row.id}`)
              }
            >
              <ArrowForwardIosIcon
                style={{
                  width: 15,
                  color: theme.colors.primary.lightBlue,
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableTextDetails">
              {row.email ? row.email : ""}
            </SpText>
          </SpTableCell>
          <SpTableCell align={"right"}>
            {currentProfessionalRole == "admin" && (
              <SpIconButton
                buttonType={"accept"}
                onClick={() => {
                  setSelectedPatient({
                    patient: patient ? patient?.id : row.id,
                    index: index,
                  });
                  setOpenDeletePatient(true);
                }}
              >
                <DeleteForever />
              </SpIconButton>
            )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            container
            xs={12}
            direction="row"
            spacing={3}
            alignItems="center"
          >
            <Grid item xs={4}>
              <SpText variant="h1" id={"backButtonLista"}>
                {isPatientLabel
                  ? labels.groups.groupDetail.addPatientTable.groupPatient
                  : labels.groups.groupDetail.addPatientTable.groupAthlete}
              </SpText>
            </Grid>

            <Grid
              item
              container
              xs={8}
              direction="row"
              spacing={2}
              style={{ justifyContent: "flex-end" }}
            >
              <SpButton
                variant="h1PageTitle"
                buttonType={"accept"}
                disabled={currentProfessionalRole !== "admin"}
                style={{ marginLeft: "5px" }}
                text={
                  isPatientLabel
                    ? labels.patient.list.addPatient
                    : labels.patient.list.addAthlete
                }
                onClick={() => setOpenAddPatient(true)}
              />
              <SpSearch
                style={{
                  marginLeft: "5px",
                  marginRight: 0,
                }}
                onKeyDown={prevent}
                onChange={(e) => filterPat(e)}
                value={searchStringPat}
              />
            </Grid>

            <form onSubmit={handleSubmit(updateGroupData)}>
              <SpDialog
                style={{ padding: "1%" }}
                open={openPatient}
                setOpen={setOpenAddPatient}
                onCloseFunctions={resetStateDialog}
                title={labels.groups.groupDetail.searchPatient}
              >
                <Grid container direction="column">
                  <Grid
                    container
                    direction="row"
                    xs={12}
                    style={{ alignItems: "self-end", marginBottom: "10px" }}
                  >
                    <SpTextInput
                      value={searchString}
                      label={labels.patient.patientLink.inputSearch.label2}
                      onChange={(e) => setSearchString(e.currentTarget.value)}
                    />
                    <SpButton
                      text={
                        labels.patient.patientLink.inputSearch.buttons.search
                      }
                      style={{ marginLeft: "10px" }}
                      variant="none"
                      buttonType="accept"
                      onClick={() => searchPatients()}
                    />
                  </Grid>

                  <StyledWrapperPatientsList
                    item
                    container
                    direction="column"
                    xs={12}
                    style={{ marginBottom: "2%" }}
                  >
                    {usersFound ? (
                      usersFound.map((patientFound) => (
                        <StyledRowPatientFound
                          item
                          container
                          direction={"row"}
                          xs={12}
                          key={patientFound.id}
                        >
                          <Grid item xs={6} alignSelf={"center"}>
                            <SpText variant="text">{`${patientFound.givenName} ${patientFound.familyName} - ${patientFound.email}`}</SpText>
                          </Grid>
                          <Grid item xs={6}>
                            <SpButton
                              text={
                                labels.groups.groupAdd.addPatientTable.dialog
                                  .addProfessionalButton
                              }
                              variant="none"
                              style={{ width: "100%" }}
                              buttonType="accept"
                              onClick={() => addPatientToGroup(patientFound)}
                            />
                          </Grid>
                        </StyledRowPatientFound>
                      ))
                    ) : (
                      <SpText variant={"text"}>
                        {
                          labels.groups.groupAdd.addPatientTable.dialog
                            .usersFoundPlaceholder
                        }
                      </SpText>
                    )}
                  </StyledWrapperPatientsList>
                </Grid>
              </SpDialog>
              <ProfessionalShareDialog
                openProfessional={openProfessional}
                setOpenAddProfessional={setOpenAddProfessional}
                addCallback={addProfessionalToGroup}
                selectValues={rolesRows}
              />
            </form>
            <Grid item xs={12}>
              <SpTable
                pagination={true}
                headCells={headCellsPatient}
                rows={fieldsPatient}
                rowKey="id"
                padding={false}
                notCheckable={false}
                sortable={true}
              >
                <GroupAddPatientRow />
              </SpTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deletePatientDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deletePatientDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={() => removePatientFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => removePatientFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteProfessional}
        setOpen={setOpenDeleteProfessional}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deleteProfessionalDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deleteProfessionalDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.yes}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.no}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsGroup);
