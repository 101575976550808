import Grid from "@material-ui/core/Grid";
import React from "react";
import { labels } from "../../../../shared/translations";
import { Typography, Paper } from "@material-ui/core";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { Droppable } from "react-beautiful-dnd";
import { Exercise } from "./2-Exercise";
import SpCheckbox from "../../../../../components/atoms/SpCheckbox";

export const Block = ({
  id,
  is_circuit,
  circuitLength,
  setExerciseLists,
  setSchedaEsercizi,
  schedaEsercizi,
  rows,
}) => {
  return (
    <Grid item key={id} style={{ minWidth: 400, flexShrink: 0, maxWidth: 400 }}>
      <Typography variant="h6">{id}</Typography>

      <Droppable droppableId={parseInt(id)}>
        {(provided) => (
          <Paper
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              minHeight: 100,
              padding: 8,
              background: "#fafafa",
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <SpCheckbox
                  id={"cartilageLabel"}
                  label={labels.mylab.exercise.enableCircuit}
                  rightlabel={true}
                  formControlStyle={{
                    justifyContent: "flex-start",
                    marginLeft: "0%",
                    marginTop: "1.2em",
                    marginBottom: "5%",
                  }}
                  checked={is_circuit}
                  onChange={() => {
                    setExerciseLists((prevRows) =>
                      prevRows.map((row) =>
                        row.id === id
                          ? { ...row, is_circuit: !is_circuit }
                          : row
                      )
                    );
                  }}
                />
              </Grid>
              {is_circuit && (
                <Grid item xs={8} style={{ marginBottom: "5%" }}>
                  <SpTextInput
                    type="number"
                    minValue={0}
                    label={labels.mylab.exercise.numRepCir}
                    style={{ width: "100%" }}
                    onChange={(evnt) =>
                      setExerciseLists((prevRows) =>
                        prevRows.map((row) =>
                          row.id === id
                            ? {
                                ...row,
                                circuitLength: evnt.target.value,
                              }
                            : row
                        )
                      )
                    }
                    value={circuitLength}
                  />
                </Grid>
              )}
            </Grid>
            {schedaEsercizi[id]?.map((exercise, index) => {
              {
                /* 
                CONTTIENE IL SINGOLO ESERCIZIO CHE A SUA VOLTA CONTIENE LE RIPETIZIONI
                */
              }
              return (
                <Exercise
                  exercise={exercise}
                  id={id}
                  index={index}
                  rows={rows}
                  setSchedaEsercizi={setSchedaEsercizi}
                  schedaEsercizi={schedaEsercizi}
                />
              );
            })}

            {provided.placeholder}
          </Paper>
        )}
      </Droppable>
    </Grid>
  );
};
