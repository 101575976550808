const markers = {
  planeSize: 0.1, //Marker plane size

  dynamic: {
    //Dynamic texture sizes are in pixels
    size: 128,
    multi_current: {
      x: 640,
      y: 384,
    },
    multi_other: {
      x: 640,
      y: 384,
    },
  },

  markerScaling: 0.35,
  hoverScaling: 1.3 * 0.35,

  textures: [
    {
      id: 1,
      x: 0.5,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 2,
      x: 0.125,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 3,
      x: 0.625,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 4,
      x: 0.25,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 5,
      x: 0.75,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 6,
      x: 0.875,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 7,
      x: 0.0,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 8,
      x: 0.625,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 9,
      x: 0.25,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 10,
      x: 0.625,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 11,
      x: 0.875,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 12,
      x: 0.0,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 13,
      x: 0.5,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 14,
      x: 0.25,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 15,
      x: 0.375,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 16,
      x: 0.5,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 17,
      x: 0.375,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 18,
      x: 0.125,
      y: 0.0,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 19,
      x: 0.0,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 20,
      x: 0.125,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 21,
      x: 0.25,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 22,
      x: 0.625,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 23,
      x: 0.5,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 24,
      x: 0.75,
      y: 0.25,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 25,
      x: 0.75,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 26,
      x: 0.875,
      y: 0.75,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 27,
      x: 0.0,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 28,
      x: 0.125,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 29,
      x: 0.25,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },
    {
      id: 30,
      x: 0.375,
      y: 0.5,
      sizeX: 0.125,
      sizeY: 0.25,
    },

    {
      id: "multi_current", //Managed by dynamicTexture (settings above at beginning in pixels)
      x: 0.0,
      y: 0.0,
      sizeX: 1.0,
      sizeY: 1.0,
    },
    {
      id: "multi_other", //Managed by dynamicTexture (settings above at beginning in pixels)
      x: 0.0,
      y: 0.0,
      sizeX: 1.0,
      sizeY: 1.0,
    },
  ],

  data: {
    testa: {
      current: {
        position: [
          -0.0654827126494721, 1.7272904463089742, 0.11538371695668263,
        ],
        lookAt: [-0.06934243166345776, 1.7272904463089742, 1.1153762682135073],
      },
      child: {
        position: [
          0.06451631901391512, 1.7272904463089742, 0.11588548042850076,
        ],
        lookAt: [0.060656599999929464, 1.7272904463089742, 1.1158780316853254],
      },
    },
    collo: {
      current: {
        position: [
          0.06585221943190563, 1.6109896678028186, -0.17666113200273958,
        ],
        lookAt: [0.07403390747304514, 1.6109896678028186, -1.176627661432999],
      },
      child: {
        position: [
          -0.06414342939402808, 1.6109896678028186, -0.17772475144808775,
        ],
        lookAt: [-0.055961741352888554, 1.6109896678028186, -1.177691280878347],
      },
    },
    toracica: {
      current: {
        position: [
          0.06496196641977967, 1.3927562087980836, -0.22500072389899417,
        ],
        lookAt: [0.06445058771244946, 1.3927562087980836, -1.2250005931448946],
      },
      child: {
        position: [
          -0.06503801658218739, 1.3927562087980836, -0.22493424466704126,
        ],
        lookAt: [-0.06554939528951759, 1.3927562087980836, -1.2249341139129417],
      },
    },
    addominale: {
      current: {
        position: [
          -0.06501086908291469, 1.1343514474568537, 0.14003336033818856,
        ],
        lookAt: [-0.06515077429891307, 1.1343514474568537, 1.1400333505514537],
      },
      child: {
        position: [
          0.06498912964480981, 1.1343514474568537, 0.14005154801626832,
        ],
        lookAt: [0.06484922442881143, 1.1343514474568537, 1.1400515382295335],
      },
    },
    costale: {
      current: {
        position: [
          -0.06491559682596455, 1.3675519994513137, 0.13047178372858195,
        ],
        lookAt: [-0.06456487204192421, 1.3675519994513137, 1.1304717222246432],
      },
      child: {
        position: [0.0650843951785234, 1.3675519994513137, 0.13042618950665671],
        lookAt: [0.06543511996256374, 1.3675519994513137, 1.130426128002718],
      },
    },
    lombosacrale: {
      current: {
        position: [
          0.0649847798054714, 1.0688226346560845, -0.18083255960897143,
        ],
        lookAt: [0.06541059322191875, 1.0688226346560845, -1.1808324689504346],
      },
      child: {
        position: [
          -0.0650152084089188, 1.0688226346560845, -0.1808879153531096,
        ],
        lookAt: [-0.06458939499247146, 1.0688226346560845, -1.1808878246945727],
      },
    },
    perineale: {
      current: {
        position: [
          -0.0660816572741134, 0.8755601572330198, 0.10544167949719933,
        ],
        lookAt: [-0.07648100682013244, 0.8755601572330198, 1.1053876047996727],
      },
      child: {
        position: [
          0.06391131301520815, 0.8755601572330198, 0.10679359493818182,
        ],
        lookAt: [0.053511963469189114, 0.8755601572330198, 1.1067395202406551],
      },
    },
    spalladx: {
      current: {
        position: [
          -0.22312513613686086, 1.5028748341890665, -0.22979027633695473,
        ],
        lookAt: [-1.0783259248526778, 1.5028748341890665, -0.7480871130819275],
      },
      child: {
        position: [
          -0.2905037249137073, 1.5028748341890665, -0.11861417380389852,
        ],
        lookAt: [-1.145704513629524, 1.5028748341890665, -0.6369110105488713],
      },
    },
    spallasx: {
      current: {
        position: [
          0.29167241213873174, 1.5016894963757177, -0.11796742203998095,
        ],
        lookAt: [1.1530219875181387, 1.5016894963757177, -0.6259801275835681],
      },
      child: {
        position: [
          0.2256307604180654, 1.5016894963757177, -0.22994286683930387,
        ],
        lookAt: [1.0869803357974723, 1.5016894963757177, -0.7379555723828911],
      },
    },
    bracciosx: {
      current: {
        position: [
          0.3095141372902524, 1.326780931502525, -0.021538889961488422,
        ],
        lookAt: [1.3078514755759094, 1.326780931502525, 0.036102752838744145],
      },
      child: {
        position: [0.3170075508542826, 1.326780931502525, -0.15132274393862385],
        lookAt: [1.3153448891399397, 1.326780931502525, -0.09368110113839129],
      },
    },
    bracciodx: {
      current: {
        position: [
          -0.30790755231729644, 1.3260013067680778, -0.1609430508970453,
        ],
        lookAt: [-1.3043242257771805, 1.3260013067680778, -0.245523266379974],
      },
      child: {
        position: [
          -0.3189029803300772, 1.3260013067680778, -0.03140888334726036,
        ],
        lookAt: [-1.3153196537899614, 1.3260013067680778, -0.11598909883018904],
      },
    },
    gomitodx: {
      current: {
        position: [
          -0.18740923709514362, 1.169291228074187, -0.18262796553813745,
        ],
        lookAt: [-0.34694088893068475, 1.169291228074187, -1.1698207795631506],
      },
      child: {
        position: [
          -0.31574430291839534, 1.169291228074187, -0.16188885079951712,
        ],
        lookAt: [-0.47527595475393647, 1.169291228074187, -1.1490816648245301],
      },
    },
    gomitosx: {
      current: {
        position: [
          0.31560111665478996, 1.1693009063824769, -0.16206718879667673,
        ],
        lookAt: [0.47348945659231556, 1.1693009063824769, -1.1495241610804177],
      },
      child: {
        position: [
          0.18723171025790364, 1.1693009063824769, -0.18259267298855505,
        ],
        lookAt: [0.34512005019542924, 1.1693009063824769, -1.170049645272296],
      },
    },
    avambracciodx: {
      current: {
        position: [
          -0.36955715816207735, 1.0987029604909018, -0.07648242001129797,
        ],
        lookAt: [-1.3174959172652376, 1.0987029604909018, 0.2419702593461871],
      },
      child: {
        position: [
          -0.32815830984560423, 1.0987029604909018, 0.04674961867211286,
        ],
        lookAt: [-1.2760970689487645, 1.0987029604909018, 0.3652022980295979],
      },
    },
    avambracciosx: {
      current: {
        position: [0.32820309566140754, 1.0987089098983425, 0.0467142407813342],
        lookAt: [1.2763159946131786, 1.0987089098983425, 0.3646480874244199],
      },
      child: {
        position: [
          0.3695344957250087, 1.0987089098983425, -0.07654043608239602,
        ],
        lookAt: [1.3176473946767797, 1.0987089098983425, 0.2413934105606897],
      },
    },
    polsodx: {
      current: {
        position: [
          -0.3972220389914448, 0.9693608026320124, -0.005418782097741881,
        ],
        lookAt: [-1.2464031728924616, 0.9693608026320124, 0.5226829143837062],
      },
      child: {
        position: [
          -0.32856881844885655, 0.9693608026320124, 0.10497476530939032,
        ],
        lookAt: [-1.1777499523498736, 0.9693608026320124, 0.6330764617908384],
      },
    },
    polsosx: {
      current: {
        position: [0.3288941399442314, 0.9693654188549254, 0.10484159133737203],
        lookAt: [1.1797166158315116, 0.9693654188549254, 0.6302948380920561],
      },
      child: {
        position: [
          0.3972030620223403, 0.9693654188549254, -0.0057653305279743786,
        ],
        lookAt: [1.2480255379096203, 0.9693654188549254, 0.5196879162267097],
      },
    },
    manodx: {
      current: {
        position: [
          -0.3874319956883234, 0.8414832272719215, 0.08683019432151662,
        ],
        lookAt: [-0.400072633514574, 0.8414832272719215, 1.0867502982675],
      },
      child: {
        position: [
          -0.2576314361825327, 0.8414832272719215, 0.08842833197936333,
        ],
        lookAt: [-0.2702720740087833, 0.8414832272719215, 1.0883484359253466],
      },
    },
    manosx: {
      current: {
        position: [0.2574586273319744, 0.8414944371075074, 0.08845168690371884],
        lookAt: [0.26997162592592505, 0.8414944371075074, 1.0883733962721012],
      },
      child: {
        position: [0.3876355974578572, 0.8414944371075074, 0.08678010594072175],
        lookAt: [0.40014859605180786, 0.8414944371075074, 1.0867018153091041],
      },
    },
    ancadx: {
      current: {
        position: [
          -0.2356064992574506, 0.9239599051451903, -0.058276472759952756,
        ],
        lookAt: [-1.2104859120072924, 0.9239599051451903, 0.16445684445263625],
      },
      child: {
        position: [
          -0.20665116801981406, 0.9239599051451903, 0.06845785089752669,
        ],
        lookAt: [-1.181530580769656, 0.9239599051451903, 0.2911911681101157],
      },
    },
    ancasx: {
      current: {
        position: [0.206622611608222, 0.923967014434913, 0.06843824894496248],
        lookAt: [1.1815192852942011, 0.923967014434913, 0.2910960034382958],
      },
      child: {
        position: [
          0.23556811969235533, 0.923967014434913, -0.05829831863421483,
        ],
        lookAt: [1.2104647933783346, 0.923967014434913, 0.16435943585911844],
      },
    },
    cosciadx: {
      current: {
        position: [
          -0.13040222971433754, 0.7222301687858037, -0.15453411112119003,
        ],
        lookAt: [-0.82540670239702, 0.7222301687858037, -0.8735395234450936],
      },
      child: {
        position: [-0.223872933316445, 0.7222301687858037, -0.0641835296724413],
        lookAt: [-0.9188774059991274, 0.7222301687858037, -0.7831889419963449],
      },
    },
    cosciasx: {
      current: {
        position: [
          0.2238338339865843, 0.7222184558315431, -0.06423948565533973,
        ],
        lookAt: [0.9184243171611315, 0.7222184558315431, -0.7836448377923315],
      },
      child: {
        position: [
          0.13031113820877538, 0.7222184558315431, -0.15453624846803088,
        ],
        lookAt: [0.8249016213833226, 0.7222184558315431, -0.8739416006050227],
      },
    },
    ginocchiodx: {
      current: {
        position: [
          -0.10574993513854322, 0.5280514325044503, -0.1559946786986167,
        ],
        lookAt: [-0.7859855907296478, 0.5280514325044503, -0.8889881662976133],
      },
      child: {
        position: [
          -0.2010390885264128, 0.5280514325044503, -0.06756404347177311,
        ],
        lookAt: [-0.8812747441175174, 0.5280514325044503, -0.8005575310707698],
      },
    },
    ginocchiosx: {
      current: {
        position: [
          0.20102229853708178, 0.5280519471072542, -0.06766105280773443,
        ],
        lookAt: [0.8806668508841654, 0.5280519471072542, -0.8012026552168496],
      },
      child: {
        position: [0.1056618902238968, 0.5280519471072542, -0.1560148446128553],
        lookAt: [0.7853064425709805, 0.5280519471072542, -0.8895564470219706],
      },
    },
    gambadx: {
      current: {
        position: [
          -0.07927501672455026, 0.285465599877757, -0.19401938924517445,
        ],
        lookAt: [-0.6703501170305893, 0.285465599877757, -1.000635918829218],
      },
      child: {
        position: [
          -0.1841351655704759, 0.285465599877757, -0.11717962620538938,
        ],
        lookAt: [-0.775210265876515, 0.285465599877757, -0.9237961557894329],
      },
    },
    gambasx: {
      current: {
        position: [
          0.18317566482937386, 0.2859613130968942, -0.11915752334446039,
        ],
        lookAt: [0.7596905188695158, 0.2859613130968942, -0.9362441913736688],
      },
      child: {
        position: [
          0.07695439798557675, 0.2859613130968942, -0.19410445436967882,
        ],
        lookAt: [0.6534692520257187, 0.2859613130968942, -1.0111911223988872],
      },
    },
    cavigliadx: {
      current: {
        position: [
          -0.07362648100431232, 0.08092892499452847, -0.171338014399102,
        ],
        lookAt: [-0.7344728905920933, 0.08092892499452847, -0.9218591819453206],
      },
      child: {
        position: [
          -0.17119423278532073, 0.08092892499452847, -0.0854279811526905,
        ],
        lookAt: [-0.8320406423731017, 0.08092892499452847, -0.835949148698909],
      },
    },
    cavigliasx: {
      current: {
        position: [
          0.17114340615547285, 0.08093850166867633, -0.08579443065662816,
        ],
        lookAt: [0.8295786335070029, 0.08093850166867633, -0.8384318276696513],
      },
      child: {
        position: [
          0.07330054454377985, 0.08093850166867633, -0.17139101021232706,
        ],
        lookAt: [0.7317357718953099, 0.08093850166867633, -0.9240284072253502],
      },
    },
    piededx: {
      current: {
        position: [
          -0.20348070763943696, 0.02751568436637481, 0.11536823664148474,
        ],
        lookAt: [-0.6911844505634657, 0.02751568436637481, 0.9883774342665115],
      },
      child: {
        position: [
          -0.08998951194818347, 0.02751568436637481, 0.17876972322160847,
        ],
        lookAt: [-0.5776932548722121, 0.02751568436637481, 1.0517789208466353],
      },
    },
    piedesx: {
      current: {
        position: [
          0.09001954924751937, 0.02753370918091279, 0.18058665604583107,
        ],
        lookAt: [0.5714048036346988, 0.02753370918091279, 1.0570957757123582],
      },
      child: {
        position: [
          0.2039657348041679, 0.02753370918091279, 0.11800657297549773,
        ],
        lookAt: [0.6853509891913474, 0.02753370918091279, 0.994515692642025],
      },
    },
    testa_boccamento: {
      current: {
        position: [
          -0.019994211450388415, 1.653087447306791, 0.09491699639544988,
        ],
        lookAt: [-0.019883177093415173, 1.653087447306791, 1.0949169902311358],
      },
      child: {
        position: [
          0.020005788303039018, 1.653087447306791, 0.09491255502117095,
        ],
        lookAt: [0.02011682266001226, 1.653087447306791, 1.0949125488568567],
      },
    },
    testa_naso: {
      current: {
        position: [
          -0.020701015855207436, 1.700702244131456, 0.0957640973073744,
        ],
        lookAt: [-0.04511658265156495, 1.700702244131456, 1.0954659929232688],
      },
      child: {
        position: [0.01928705996942834, 1.700702244131456, 0.0967407199792287],
        lookAt: [-0.005128506826929176, 1.700702244131456, 1.096442615595123],
      },
    },
    testa_zigomaticomascellaresx: {
      current: {
        position: [0.06049409513685155, 1.69530731092437, 0.06481883598444646],
        lookAt: [0.8281888021958814, 1.69530731092437, 0.7056345948056154],
      },
      child: {
        position: [0.08612672548969831, 1.69530731092437, 0.034111047702085256],
        lookAt: [0.8538214325487282, 1.69530731092437, 0.6749268065232542],
      },
    },
    testa_zigomaticomascellaredx: {
      current: {
        position: [
          -0.0861268920477613, 1.6953067226890763, 0.03411098602943462,
        ],
        lookAt: [-0.8538198132699644, 1.6953067226890763, 0.6749288842698945],
      },
      child: {
        position: [
          -0.0604941761181429, 1.6953067226890763, 0.06481870287832274,
        ],
        lookAt: [-0.8281870973403459, 1.6953067226890763, 0.7056366011187826],
      },
    },
    testa_temporomandibolaredx: {
      current: {
        position: [
          -0.09828590041593031, 1.6680101020408171, -0.01399912595193041,
        ],
        lookAt: [-1.0597095326553456, 1.6680101020408171, 0.2610728634827804],
      },
      child: {
        position: [
          -0.08728302083854188, 1.6680101020408171, 0.0244578193376462,
        ],
        lookAt: [-1.0487066530779572, 1.6680101020408171, 0.299529808772357],
      },
    },
    testa_temporomandibolaresx: {
      current: {
        position: [
          0.08727871832230362, 1.6680106122448983, 0.024457599858429047,
        ],
        lookAt: [1.0486907774557097, 1.6680106122448983, 0.29957003606018306],
      },
      child: {
        position: [
          0.09828321577037379, 1.6680106122448983, -0.013998882506907196,
        ],
        lookAt: [1.0596952749037798, 1.6680106122448983, 0.26111355369484684],
      },
    },
    testa_orbitalesx: {
      current: {
        position: [
          0.02142792386538963, 1.7325770493163346, 0.06922469362587176,
        ],
        lookAt: [0.28138124724187946, 1.7325770493163346, 1.03484587694028],
      },
      child: {
        position: [
          0.06005277119796596, 1.7325770493163346, 0.05882656069081217,
        ],
        lookAt: [0.32000609457445583, 1.7325770493163346, 1.0244477440052204],
      },
    },
    testa_orbitaledx: {
      current: {
        position: [
          -0.060055019188353514, 1.732574172422395, 0.05883390692000275,
        ],
        lookAt: [-0.32001091637291723, 1.732574172422395, 1.0244543973400064],
      },
      child: {
        position: [-0.02143019957155336, 1.732574172422395, 0.0692321428073853],
        lookAt: [-0.28138609675611703, 1.732574172422395, 1.034852633227389],
      },
    },
    testa_frontalemediale: {
      current: {
        position: [
          -0.019982682476924342, 1.7751242951807231, 0.07712728921538323,
        ],
        lookAt: [-0.019405717458926906, 1.7751242951807231, 1.0771271227710535],
      },
      child: {
        position: [
          0.020017310865302465, 1.7751242951807231, 0.07710421061466333,
        ],
        lookAt: [0.0205942758832999, 1.7751242951807231, 1.0771040441703335],
      },
    },
    testa_frontalesx: {
      current: {
        position: [
          0.06069610858662197, 1.7671437089552238, 0.060193418218131484,
        ],
        lookAt: [0.8516142351098255, 1.7671437089552238, 0.6721154043707994],
      },
      child: {
        position: [
          0.08517298803272869, 1.7671437089552238, 0.02855669315720334,
        ],
        lookAt: [0.8760911145559321, 1.7671437089552238, 0.6404786793098712],
      },
    },
    testa_frontaledx: {
      current: {
        position: [
          -0.08490253150251965, 1.7670564328358205, 0.028293704282310962,
        ],
        lookAt: [-0.8773404803777071, 1.7670564328358205, 0.6382462411099611],
      },
      child: {
        position: [
          -0.06050443002941365, 1.7670564328358205, 0.05999122223731846,
        ],
        lookAt: [-0.852942378904601, 1.7670564328358205, 0.6699437590649686],
      },
    },
    testa_orecchiodx: {
      current: {
        position: [
          -0.10699444855518372, 1.7124069965034983, -0.07861961380200912,
        ],
        lookAt: [-1.1069747234151506, 1.7124069965034983, -0.07233869898128309],
      },
      child: {
        position: [
          -0.10660588291473397, 1.7124069965034983, -0.03799316150516584,
        ],
        lookAt: [-1.106586157774701, 1.7124069965034983, -0.03171224668443981],
      },
    },
    testa_orecchiosx: {
      current: {
        position: [
          0.10668130837661197, 1.7123007351916395, -0.038621275727566164,
        ],
        lookAt: [1.1066622021053882, 1.7123007351916395, -0.032439675821550086],
      },
      child: {
        position: [
          0.10679334555787727, 1.7123007351916395, -0.07800317560919584,
        ],
        lookAt: [1.1067742392866535, 1.7123007351916395, -0.07182157570317976],
      },
    },
    testa_occipitomastoideasx: {
      current: {
        position: [
          0.09382831687300532, 1.7048001680672262, -0.11510036407785866,
        ],
        lookAt: [0.9106734028283232, 1.7048001680672262, -0.691957456909913],
      },
      child: {
        position: [
          0.07075403315972313, 1.7048001680672262, -0.14777416751607136,
        ],
        lookAt: [0.8875991191150411, 1.7048001680672262, -0.7246312603481257],
      },
    },
    testa_occipitomastoideadx: {
      current: {
        position: [
          -0.07077538366652321, 1.7048009075630248, -0.1477587166175221,
        ],
        lookAt: [-0.8880409437783812, 1.7048009075630248, -0.7240199457966605],
      },
      child: {
        position: [
          -0.09382583283368874, 1.7048009075630248, -0.1150680942130478,
        ],
        lookAt: [-0.9110913929455466, 1.7048009075630248, -0.6913293233921861],
      },
    },
    testa_occipitale: {
      current: {
        position: [
          0.019923770872069937, 1.740048005076143, -0.18469592385901026,
        ],
        lookAt: [0.01788547682939451, 1.740048005076143, -1.1846938465355505],
      },
      child: {
        position: [
          -0.020076146034991672, 1.740048005076143, -0.18461439209730326,
        ],
        lookAt: [-0.0221144400776671, 1.740048005076143, -1.1846123147738434],
      },
    },
    testa_sagittale: {
      current: {
        position: [
          0.018813230718476882, 1.827129056338028, -0.1530402456393959,
        ],
        lookAt: [0.004455918551003841, 1.827129056338028, -1.15293717412119],
      },
      child: {
        position: [
          -0.021182646420794878, 1.827129056338028, -0.15246595315269695,
        ],
        lookAt: [-0.03553995858826792, 1.827129056338028, -1.1523628816344909],
      },
    },
    testa_temporoparietalesx: {
      current: {
        position: [
          0.10741556835768798, 1.7757850141509435, -0.03481056480857583,
        ],
        lookAt: [1.1073841369413888, 1.7757850141509435, -0.02688201862000794],
      },
      child: {
        position: [
          0.1077327102052307, 1.7757850141509435, -0.07480930755192386,
        ],
        lookAt: [1.1077012787889315, 1.7757850141509435, -0.06688076136335597],
      },
    },
    testa_temporoparietaledx: {
      current: {
        position: [
          -0.10777810822354922, 1.7757771698113203, -0.07472755440356303,
        ],
        lookAt: [-1.1077246681368522, 1.7757771698113203, -0.06438940575155956],
      },
      child: {
        position: [
          -0.10736458227746909, 1.7757771698113203, -0.03472969200703091,
        ],
        lookAt: [-1.1073111421907722, 1.7757771698113203, -0.02439154335502744],
      },
    },
    collo_cervicalevertebralesuperiore: {
      current: {
        position: [
          0.019993580743039405, 1.6641828727272725, -0.15419071652059002,
        ],
        lookAt: [0.019945462193609053, 1.6641828727272725, -1.1541907153628927],
      },
      child: {
        position: [
          -0.020011231088292915, 1.6641828727272725, -0.15418926335580815,
        ],
        lookAt: [
          -0.020059349637723267, 1.6641828727272725, -1.1541892621981107,
        ],
      },
    },
    collo_cervicalevertebraleinferiore: {
      current: {
        position: [0.0200032438150824, 1.5949787777777777, -0.1608532013224578],
        lookAt: [0.01995888490915863, 1.5949787777777777, -1.1608532003386016],
      },
      child: {
        position: [
          -0.02000119205154593, 1.5949787777777777, -0.16085177381512825,
        ],
        lookAt: [-0.020045550957469703, 1.5949787777777777, -1.160851772831272],
      },
    },
    collo_nucalesx: {
      current: {
        position: [
          0.07858755121381356, 1.6605081199999996, -0.11444458439101773,
        ],
        lookAt: [0.7614237873736418, 1.6605081199999996, -0.8450160556291708],
      },
      child: {
        position: [
          0.04936469236428744, 1.6605081199999996, -0.14175803383741087,
        ],
        lookAt: [0.7322009285241157, 1.6605081199999996, -0.872329505075564],
      },
    },
    collo_nucaledx: {
      current: {
        position: [
          -0.04938840206905484, 1.660512439999999, -0.1417880807544142,
        ],
        lookAt: [-0.732060490421096, 1.660512439999999, -0.8725129399080241],
      },
      child: {
        position: [
          -0.07861739643519924, 1.660512439999999, -0.11448119722033255,
        ],
        lookAt: [-0.7612894847872405, 1.660512439999999, -0.8452060563739425],
      },
    },
    collo_posterioresx: {
      current: {
        position: [
          0.10066295561281514, 1.5949050833333325, -0.11579433977191626,
        ],
        lookAt: [0.8383550863959329, 1.5949050833333325, -0.7909316000239804],
      },
      child: {
        position: [
          0.07365746520273257, 1.5949050833333325, -0.14530202500324096,
        ],
        lookAt: [0.8113495959858503, 1.5949050833333325, -0.8204392852553052],
      },
    },
    collo_posterioredx: {
      current: {
        position: [
          -0.07341954903935366, 1.5949878680555551, -0.14522140499314734,
        ],
        lookAt: [-0.8056017665500932, 1.5949878680555551, -0.8263302071393657],
      },
      child: {
        position: [
          -0.10066390112520238, 1.5949878680555551, -0.11593411629271777,
        ],
        lookAt: [-0.8328461186359419, 1.5949878680555551, -0.797042918438936],
      },
    },
    collo_infraioidea: {
      current: {
        position: [
          -0.019885120735201708, 1.5606801654135334, 0.027931789397661312,
        ],
        lookAt: [-0.01428979121754404, 1.5606801654135334, 1.0279161354189323],
      },
      child: {
        position: [
          0.02011425310564913, 1.5606801654135334, 0.027707976216955005,
        ],
        lookAt: [0.025709582623306797, 1.5606801654135334, 1.027692322238226],
      },
    },
    collo_ioideadx: {
      current: {
        position: [
          -0.06318014724262189, 1.6122605053763444, 0.005760708565314861,
        ],
        lookAt: [-0.9842066452106333, 1.6122605053763444, 0.39526063159583474],
      },
      child: {
        position: [
          -0.04760015032140109, 1.6122605053763444, 0.04260176848403532,
        ],
        lookAt: [-0.9686266482894125, 1.6122605053763444, 0.4321016915145552],
      },
    },
    collo_ioideasx: {
      current: {
        position: [
          0.047627552315980086, 1.612258387096775, 0.04258329163903035,
        ],
        lookAt: [0.9690424030003887, 1.612258387096775, 0.4311636217359474],
      },
      child: {
        position: [
          0.06317076551985677, 1.612258387096775, 0.005726697611654008,
        ],
        lookAt: [0.9845856162042654, 1.612258387096775, 0.39430702770857107],
      },
    },
    collo_anterioredx: {
      current: {
        position: [
          -0.09640493418834899, 1.582963852941176, -0.036609312994953985,
        ],
        lookAt: [-0.8111593818617857, 1.582963852941176, 0.6627661795203618],
      },
      child: {
        position: [
          -0.0688270553352427, 1.582963852941176, -0.008706360167457745,
        ],
        lookAt: [-0.7835815030086795, 1.582963852941176, 0.690669132347858],
      },
    },
    collo_anterioresx: {
      current: {
        position: [
          0.06802380431009476, 1.5826482731092433, -0.008481233213509163,
        ],
        lookAt: [0.7830451117845002, 1.5826482731092433, 0.6906214272431593],
      },
      child: {
        position: [
          0.09638464970398067, 1.5826482731092433, -0.03780733813377625,
        ],
        lookAt: [0.811405957178386, 1.5826482731092433, 0.6612953223228922],
      },
    },
    toracica_superioresx: {
      current: {
        position: [
          0.09599892964848628, 1.4976381562499999, -0.2033559733265696,
        ],
        lookAt: [
          -0.033491561846456575, 1.4976381562499999, -1.1949366370208543,
        ],
      },
      child: {
        position: [
          0.056335703100714875, 1.4976381562499999, -0.19817635366677186,
        ],
        lookAt: [-0.07315478839422798, 1.4976381562499999, -1.1897570173610565],
      },
    },
    toracica_superioredx: {
      current: {
        position: [
          -0.05634156557615071, 1.4976372187500002, -0.19817867630765576,
        ],
        lookAt: [0.07307962319542662, 1.4976372187500002, -1.1897683877795688],
      },
      child: {
        position: [
          -0.09600515403502723, 1.4976372187500002, -0.20335552385851888,
        ],
        lookAt: [0.033416034736550096, 1.4976372187500002, -1.194945235330432],
      },
    },
    toracica_vertebralesuperiore: {
      current: {
        position: [
          0.01999968303395109, 1.5143632500000006, -0.18443133561253983,
        ],
        lookAt: [0.01999424868344542, 1.5143632500000006, -1.1844313355977738],
      },
      child: {
        position: [
          -0.020000588683578988, 1.5143632500000006, -0.18443122780637605,
        ],
        lookAt: [-0.020006023034084656, 1.5143632500000006, -1.18443122779161],
      },
    },
    toracica_vertebralemedia: {
      current: {
        position: [
          0.020000012072123718, 1.4072308809523808, -0.19770871710663307,
        ],
        lookAt: [0.020000202532793467, 1.4072308809523808, -1.197708717106615],
      },
      child: {
        position: [
          -0.019999978404839757, 1.4072308809523808, -0.19770873689217902,
        ],
        lookAt: [-0.019999787944170008, 1.4072308809523808, -1.197708736892161],
      },
    },
    toracica_vertebraleinferiore: {
      current: {
        position: [
          0.019999990678257543, 1.2895215714285715, -0.18484484016365502,
        ],
        lookAt: [0.020000187212444294, 1.2895215714285715, -1.1848448401636358],
      },
      child: {
        position: [
          -0.019999999495032846, 1.2895215714285715, -0.18484484550182373,
        ],
        lookAt: [
          -0.019999802960846094, 1.2895215714285715, -1.1848448455018044,
        ],
      },
    },
    toracica_mediasx: {
      current: {
        position: [
          0.09084828242281419, 1.3813305135135137, -0.22105072066268433,
        ],
        lookAt: [0.08364113256660896, 1.3813305135135137, -1.2210247488208912],
      },
      child: {
        position: [
          0.05084932129648591, 1.3813305135135137, -0.22076243466843615,
        ],
        lookAt: [0.04364217144028067, 1.3813305135135137, -1.220736462826643],
      },
    },
    toracica_inferioresx: {
      current: {
        position: [
          0.08234215360485359, 1.2579939333333336, -0.18515439404785275,
        ],
        lookAt: [0.10790494579540003, 1.2579939333333336, -1.1848276124824686],
      },
      child: {
        position: [
          0.042355224867468955, 1.2579939333333336, -0.1861769057354746,
        ],
        lookAt: [0.0679180170580154, 1.2579939333333336, -1.1858501241700905],
      },
    },
    toracica_mediadx: {
      current: {
        position: [
          -0.051069424087887005, 1.3811016216216216, -0.22084770452254301,
        ],
        lookAt: [-0.04800713910595126, 1.3811016216216216, -1.2208430157168955],
      },
      child: {
        position: [
          -0.0910692365356611, 1.3811016216216216, -0.22097019592182046,
        ],
        lookAt: [-0.08800695155372536, 1.3811016216216216, -1.220965507116173],
      },
    },
    toracica_inferioredx: {
      current: {
        position: [
          -0.04235956509685816, 1.2579887333333335, -0.1861764125963838,
        ],
        lookAt: [-0.06806074737246669, 1.2579887333333335, -1.1858460826522659],
      },
      child: {
        position: [
          -0.08234635189909344, 1.2579887333333335, -0.18514836530535944,
        ],
        lookAt: [-0.10804753417470196, 1.2579887333333335, -1.1848180353612414],
      },
    },
    addominale_epigastrica: {
      current: {
        position: [
          -0.02000004765062471, 1.2602294623655914, 0.12366357008083056,
        ],
        lookAt: [-0.020000400956919382, 1.2602294623655914, 1.1236635700807682],
      },
      child: {
        position: [
          0.019999934684056888, 1.2602294623655914, 0.12366358753189649,
        ],
        lookAt: [0.019999581377762216, 1.2602294623655914, 1.1236635875318342],
      },
    },
    addominale_ipocondriosx: {
      current: {
        position: [
          0.08787696934539367, 1.2312818974358974, 0.11237572529121614,
        ],
        lookAt: [0.6611404404826896, 1.2312818974358974, 0.9317468211077693],
      },
      child: {
        position: [0.1206518131780558, 1.2312818974358974, 0.0894451864457243],
        lookAt: [0.6939152843153518, 1.2312818974358974, 0.9088162822622774],
      },
    },
    addominale_ipocondriodx: {
      current: {
        position: [
          -0.12072210604597719, 1.2312873846153845, 0.08933876513667033,
        ],
        lookAt: [-0.6961665547162672, 1.2312873846153845, 0.907179633823572],
      },
      child: {
        position: [
          -0.08800847129850112, 1.2312873846153845, 0.11235654308348192,
        ],
        lookAt: [-0.6634529199687911, 1.2312873846153845, 0.9301974117703837],
      },
    },
    addominale_ombelicale: {
      current: {
        position: [
          -0.019994906187488337, 1.1298213070866145, 0.11682295595889768,
        ],
        lookAt: [-0.019912546448760053, 1.1298213070866145, 1.1168229525673345],
      },
      child: {
        position: [
          0.02000921208004998, 1.1298213070866145, 0.11682471562199019,
        ],
        lookAt: [0.020091571818778263, 1.1298213070866145, 1.1168247122304271],
      },
    },
    addominale_fiancodx: {
      current: {
        position: [-0.15380093747878484, 1.1389081, 0.04902217818272756],
        lookAt: [-0.9429803605820545, 1.1389081, 0.6631848912729889],
      },
      child: {
        position: [-0.12923442895517437, 1.1389081, 0.08058935510685836],
        lookAt: [-0.918413852058444, 1.1389081, 0.6947520681971197],
      },
    },
    addominale_fiancosx: {
      current: {
        position: [
          0.12929797808083174, 1.1389246454545459, 0.08063000817023422,
        ],
        lookAt: [0.918839307303143, 1.1389246454545459, 0.694327400579446],
      },
      child: {
        position: [
          0.1538458737772002, 1.1389246454545459, 0.049048355001341776,
        ],
        lookAt: [0.9433872029995114, 1.1389246454545459, 0.6627457474105535],
      },
    },
    addominale_iliacadx: {
      current: {
        position: [
          -0.16017790157015166, 1.0507842676056338, 0.0467736211088491,
        ],
        lookAt: [-0.950501460580975, 1.0507842676056338, 0.6594633240355963],
      },
      child: {
        position: [
          -0.13567031345308178, 1.0507842676056338, 0.07838656346928204,
        ],
        lookAt: [-0.9259938724639051, 1.0507842676056338, 0.6910762663960293],
      },
    },
    addominale_iliacasx: {
      current: {
        position: [
          0.13578956323994157, 1.0509443943661971, 0.07836697419439664,
        ],
        lookAt: [0.9271123197928066, 1.0509443943661971, 0.6897656125324085],
      },
      child: {
        position: [
          0.16024550877346205, 1.0509443943661971, 0.04671406393228203,
        ],
        lookAt: [0.9515682653263271, 1.0509443943661971, 0.658112702270294],
      },
    },
    addominale_ipogastrica: {
      current: {
        position: [
          -0.020007995219319077, 0.9769815681818182, 0.12258031298262885,
        ],
        lookAt: [-0.020184581872101152, 0.9769815681818182, 1.122580297391206],
      },
      child: {
        position: [
          0.019992004157024006, 0.9769815681818182, 0.12258737644874014,
        ],
        lookAt: [0.01981541750424193, 0.9769815681818182, 1.1225873608573171],
      },
    },
    costale_sternale: {
      current: {
        position: [
          -0.01989794467207513, 1.4219429619047628, 0.08621108253940839,
        ],
        lookAt: [-0.015408737254017736, 1.4219429619047628, 1.086201005997019],
      },
      child: {
        position: [
          0.020101652266229297, 1.4219429619047628, 0.08603151424268608,
        ],
        lookAt: [0.02459085968428669, 1.4219429619047628, 1.0860214377002968],
      },
    },
    costale_pettoralesx: {
      current: {
        position: [0.09598531534193884, 1.419826037234042, 0.09632141336848885],
        lookAt: [0.49038332814981983, 1.419826037234042, 1.0152611325520675],
      },
      child: {
        position: [0.132742904109282, 1.419826037234042, 0.0805454928561736],
        lookAt: [0.527140916917163, 1.419826037234042, 0.9994852120397524],
      },
    },
    costale_pettoraledx: {
      current: {
        position: [
          -0.13254651107711787, 1.4203269999999988, 0.08000183135886457,
        ],
        lookAt: [-0.5272426242077448, 1.4203269999999988, 0.998813552158929],
      },
      child: {
        position: [
          -0.09579404224511529, 1.4203269999999988, 0.09578967588408965,
        ],
        lookAt: [-0.4904901553757422, 1.4203269999999988, 1.014601396684154],
      },
    },
    costale_costaledx: {
      current: {
        position: [
          -0.17767488191187833, 1.2879178406374505, -0.00964016592435851,
        ],
        lookAt: [-1.073677240789731, 1.2879178406374505, 0.4344091251293757],
      },
      child: {
        position: [
          -0.15991291026972895, 1.2879178406374505, 0.026199928430755595,
        ],
        lookAt: [-1.0559152691475815, 1.2879178406374505, 0.4702492194844898],
      },
    },
    costale_costalesx: {
      current: {
        position: [
          0.16038143581183417, 1.2877461574803144, 0.026394168586215207,
        ],
        lookAt: [1.0580562007277843, 1.2877461574803144, 0.4670527852421179],
      },
      child: {
        position: [
          0.1780077804780703, 1.2877461574803144, -0.009512822010422795,
        ],
        lookAt: [1.0756825453940204, 1.2877461574803144, 0.4311457946454799],
      },
    },
    lombosacrale_lombarevertebrale: {
      current: {
        position: [
          0.019992708315014925, 1.1301220634920635, -0.13726536366109252,
        ],
        lookAt: [0.019877192976388555, 1.1301220634920635, -1.1372653569891957],
      },
      child: {
        position: [
          -0.020013067365909715, 1.1301220634920635, -0.13726230847299212,
        ],
        lookAt: [
          -0.020128582704536085, 1.1301220634920635, -1.1372623018010954,
        ],
      },
    },
    lombosacrale_lombidx: {
      current: {
        position: [
          -0.08758973639314387, 1.1196904666666672, -0.13433458068451085,
        ],
        lookAt: [-0.5887877557215127, 1.1196904666666672, -0.999667201947179],
      },
      child: {
        position: [
          -0.1222030412436506, 1.1196904666666672, -0.11428665991137608,
        ],
        lookAt: [-0.6234010605720194, 1.1196904666666672, -0.9796192811740442],
      },
    },
    lombosacrale_lombisx: {
      current: {
        position: [
          0.12221690000136748, 1.1196947777777777, -0.11428589173796035,
        ],
        lookAt: [0.6232299929260383, 1.1196947777777777, -0.9797255954250833],
      },
      child: {
        position: [
          0.08759931185388256, 1.1196947777777777, -0.13432641545494717,
        ],
        lookAt: [0.5886124047785534, 1.1196947777777777, -0.9997661191420701],
      },
    },
    lombosacrale_sacrale: {
      current: {
        position: [0.01995501350702789, 0.9890665, -0.1800439471950782],
        lookAt: [0.019344846861885714, 0.9890665, -1.1800437610433936],
      },
      child: {
        position: [-0.02007549690296184, 0.9890665, -0.18003513974160562],
        lookAt: [-0.020685663548104014, 0.9890665, -1.180034953589921],
      },
    },
    lombosacrale_sacroiliacasx: {
      current: {
        position: [
          0.06804126748949703, 1.0270794166666666, -0.1718793579531587,
        ],
        lookAt: [-0.11718322275756267, 1.0270794166666666, -1.1545755918997007],
      },
      child: {
        position: [
          0.02873341813163534, 1.0270794166666666, -0.1644703783432763,
        ],
        lookAt: [-0.15649107211542435, 1.0270794166666666, -1.1471666122898183],
      },
    },
    lombosacrale_sacroiliacadx: {
      current: {
        position: [
          -0.028920027266458093, 1.0272825833333332, -0.1643119695580172,
        ],
        lookAt: [0.1596731556043553, 1.0272825833333332, -1.146367269108229],
      },
      child: {
        position: [
          -0.06820223924846656, 1.0272825833333332, -0.17185569687284974,
        ],
        lookAt: [0.12039094362234683, 1.0272825833333332, -1.1539109964230616],
      },
    },
    perineale_perineale: {
      current: {
        position: [
          0.019886791610286166, 0.8685826405529962, -0.16334017724775712,
        ],
        lookAt: [0.016794817937368704, 0.8685826405529962, -1.1633353970857352],
      },
      child: {
        position: [
          -0.020113017183232957, 0.8685826405529962, -0.16321649830084045,
        ],
        lookAt: [-0.02320499085615042, 0.8685826405529962, -1.1632117181388184],
      },
    },
    perineale_urogenitale: {
      current: {
        position: [
          -0.02019181710208568, 0.8825376739130435, 0.08844242915420693,
        ],
        lookAt: [-0.025583407999792487, 0.8825376739130435, 1.0884278944223735],
      },
      child: {
        position: [
          0.019807601508640985, 0.8825376739130435, 0.0886580927901152,
        ],
        lookAt: [0.014416010610934176, 0.8825376739130435, 1.0886435580582818],
      },
    },
    spalladx_anterolaterale: {
      current: {
        position: [
          -0.2760270838608678, 1.4885948372093019, -0.08031802968136437,
        ],
        lookAt: [-1.250838436476881, 1.4885948372093019, 0.1427129699822227],
      },
      child: {
        position: [
          -0.2671058438743243, 1.4885948372093019, -0.04132557557672384,
        ],
        lookAt: [-1.2419171964903375, 1.4885948372093019, 0.18170542408686322],
      },
    },
    spalladx_posteriore: {
      current: {
        position: [
          -0.11838767636072953, 1.5171548311688314, -0.19810564974530745,
        ],
        lookAt: [-0.42225589673359154, 1.5171548311688314, -1.1508197226168222],
      },
      child: {
        position: [
          -0.15649623927559012, 1.5171548311688314, -0.18595092093039298,
        ],
        lookAt: [-0.4603644596484521, 1.5171548311688314, -1.1386649938019078],
      },
    },
    spallasx_anterolaterale: {
      current: {
        position: [
          0.26564211660222664, 1.4862193953488378, -0.0413910699875194,
        ],
        lookAt: [1.23915231771965, 1.4862193953488378, 0.18725251360999653],
      },
      child: {
        position: [
          0.27478785994612726, 1.4862193953488378, -0.08033147803221632,
        ],
        lookAt: [1.2482980610635506, 1.4862193953488378, 0.1483121055652996],
      },
    },
    spallasx_posteriore: {
      current: {
        position: [
          0.15666230853846536, 1.5171595974025973, -0.18575477117972325,
        ],
        lookAt: [0.4668414036445429, 1.5171595974025973, -1.1364329128024153],
      },
      child: {
        position: [
          0.11863518287355768, 1.5171595974025973, -0.19816193498396636,
        ],
        lookAt: [0.4288142779796352, 1.5171595974025973, -1.1488400766066584],
      },
    },
    bracciosx_anteriore: {
      current: {
        position: [
          0.19936033237323844, 1.3237400852272727, 0.002263630219300955,
        ],
        lookAt: [0.29062879101253813, 1.3237400852272727, 0.998089954664285],
      },
      child: {
        position: [
          0.23919338535103782, 1.3237400852272727, -0.0013871081262710335,
        ],
        lookAt: [0.3304618439903375, 1.3237400852272727, 0.9944392163187129],
      },
    },
    bracciosx_posteriore: {
      current: {
        position: [
          0.24853807973532396, 1.3298217777777772, -0.17690589667700793,
        ],
        lookAt: [0.4942471595425524, 1.3298217777777772, -1.1462495138222828],
      },
      child: {
        position: [
          0.20976433504951295, 1.3298217777777772, -0.18673425986929706,
        ],
        lookAt: [0.4554734148567414, 1.3298217777777772, -1.156077877014572],
      },
    },
    bracciodx_anteriore: {
      current: {
        position: [
          -0.24955302358203366, 1.3276238456790121, -0.009482714440753715,
        ],
        lookAt: [-0.5051721104663268, 1.3276238456790121, 0.9572948620578121],
      },
      child: {
        position: [
          -0.210881920522091, 1.3276238456790121, 0.0007420490346180123,
        ],
        lookAt: [-0.4665010074063842, 1.3276238456790121, 0.9675196255331839],
      },
    },
    bracciodx_posteriore: {
      current: {
        position: [
          -0.18548415799810403, 1.3243787678571435, -0.1840000021396634,
        ],
        lookAt: [-0.11113099657510528, 1.3243787678571435, -1.181231974845315],
      },
      child: {
        position: [
          -0.2253734369063301, 1.3243787678571435, -0.18697412859658336,
        ],
        lookAt: [-0.15102027548333136, 1.3243787678571435, -1.1842061013022351],
      },
    },
    gomitodx_anteriore: {
      current: {
        position: [-0.2494876118635149, 1.1948715, -0.0010098382310922385],
        lookAt: [-0.09226124320293658, 1.1948715, 0.9865527516829891],
      },
      child: {
        position: [-0.20998510826695163, 1.1948715, -0.007298892977515372],
        lookAt: [-0.05275873960637331, 1.1948715, 0.9802636969365659],
      },
    },
    gomitodx_posteriore: {
      current: {
        position: [
          -0.22070122115048058, 1.1961869374999998, -0.15389823535560973,
        ],
        lookAt: [-0.3582607825231007, 1.1961869374999998, -1.1443917320909354],
      },
      child: {
        position: [
          -0.2603209610198936, 1.1961869374999998, -0.14839585290070492,
        ],
        lookAt: [-0.3978805223925137, 1.1961869374999998, -1.1388893496360306],
      },
    },
    gomitodx_mediale: {
      current: {
        position: [
          -0.1615506995730398, 1.185937341463415, -0.05499529894332246,
        ],
        lookAt: [0.8384302074097167, 1.185937341463415, -0.04881584347024352],
      },
      child: {
        position: [
          -0.16130352135411663, 1.185937341463415, -0.09499453522263272,
        ],
        lookAt: [0.8386773856286399, 1.185937341463415, -0.08881507974955379],
      },
    },
    gomitodx_laterale: {
      current: {
        position: [
          -0.3079867784661639, 1.200169133333333, -0.09791012830475788,
        ],
        lookAt: [-1.285909411840393, 1.200169133333333, -0.30687740596902047],
      },
      child: {
        position: [
          -0.3163454695727344, 1.200169133333333, -0.058793222969788714,
        ],
        lookAt: [-1.2942681029469636, 1.200169133333333, -0.26776050063405127],
      },
    },
    gomitosx_anteriore: {
      current: {
        position: [
          0.20998495936661382, 1.1948716428571429, -0.00729904411560249,
        ],
        lookAt: [0.05275451488729918, 1.1948716428571429, 0.9802628968930804],
      },
      child: {
        position: [
          0.24948743700696113, 1.1948716428571429, -0.001009826336429904,
        ],
        lookAt: [0.09225699252764649, 1.1948716428571429, 0.986552114672253],
      },
    },
    gomitosx_posteriore: {
      current: {
        position: [0.2603021505869937, 1.1962249062499999, -0.1484056533226544],
        lookAt: [0.3975978408443338, 1.1962249062499999, -1.1389357606070118],
      },
      child: {
        position: [0.2206809462956194, 1.1962249062499999, -0.153897480932948],
        lookAt: [0.3579766365529595, 1.1962249062499999, -1.1444275882173056],
      },
    },
    gomitosx_laterale: {
      current: {
        position: [
          0.31634511269384946, 1.2001694666666665, -0.05879334143310905,
        ],
        lookAt: [1.294267114424356, 1.2001694666666665, -0.26776357503445025],
      },
      child: {
        position: [
          0.3079863033497958, 1.2001694666666665, -0.09791022150232931,
        ],
        lookAt: [1.2859083050803024, 1.2001694666666665, -0.30688045510367046],
      },
    },
    gomitosx_mediale: {
      current: {
        position: [
          0.16130349969781455, 1.1859376097560976, -0.09499422809798541,
        ],
        lookAt: [-0.8386773896928386, 1.1859376097560976, -0.08881192646521813],
      },
      child: {
        position: [
          0.16155079176312526, 1.1859376097560976, -0.05499499252235929,
        ],
        lookAt: [
          -0.8384300976275278, 1.1859376097560976, -0.048812690889591996,
        ],
      },
    },
    avambracciodx_laterale: {
      current: {
        position: [
          -0.31789345989438056, 1.0767040795454548, -0.07567179520202048,
        ],
        lookAt: [-1.2601549022789966, 1.0767040795454548, -0.4105499434817538],
      },
      child: {
        position: [
          -0.33128858582556986, 1.0767040795454548, -0.037981337506635834,
        ],
        lookAt: [-1.273550028210186, 1.0767040795454548, -0.3728594857863691],
      },
    },
    avambracciodx_posteriore: {
      current: {
        position: [
          -0.22195750345244547, 1.0767550693069308, -0.10469077061101101,
        ],
        lookAt: [-0.00804142616875747, 1.0767550693069308, -1.081542812570058],
      },
      child: {
        position: [
          -0.2610315851308074, 1.0767550693069308, -0.11324741370235852,
        ],
        lookAt: [
          -0.047115507847119376, 1.0767550693069308, -1.0900994556614054,
        ],
      },
    },
    avambracciodx_anteriore: {
      current: {
        position: [
          -0.24729447073276756, 1.0676497326203205, 0.03286589028461902,
        ],
        lookAt: [0.19542671739204076, 1.0676497326203205, 0.9295252177804961],
      },
      child: {
        position: [
          -0.21142809763293247, 1.0676497326203205, 0.01515704275962668,
        ],
        lookAt: [0.23129309049187585, 1.0676497326203205, 0.9118163702555038],
      },
    },
    avambracciosx_laterale: {
      current: {
        position: [
          0.3312884762970781, 1.0767039772727274, -0.03798109814549267,
        ],
        lookAt: [1.273552260005821, 1.0767039772727274, -0.37285265846509286],
      },
      child: {
        position: [
          0.3178936138842941, 1.0767039772727274, -0.07567164949384239,
        ],
        lookAt: [1.260157397593037, 1.0767039772727274, -0.4105432098134426],
      },
    },
    avambracciosx_posteriore: {
      current: {
        position: [
          0.26102097697548604, 1.0767670198019803, -0.11325399880968048,
        ],
        lookAt: [0.04698217147869138, 1.0767670198019803, -1.0900791570347874],
      },
      child: {
        position: [
          0.22194797064648178, 1.0767670198019803, -0.1046924465898087,
        ],
        lookAt: [0.00790916514968712, 1.0767670198019803, -1.0815176048149158],
      },
    },
    avambracciosx_anteriore: {
      current: {
        position: [
          0.21142719652456868, 1.0676557326203204, 0.015156960755295419,
        ],
        lookAt: [-0.23127200290355807, 1.0676557326203204, 0.911827144728029],
      },
      child: {
        position: [
          0.24729400388347803, 1.0676557326203204, 0.03286492873242049,
        ],
        lookAt: [-0.19540519554464872, 1.0676557326203204, 0.9295351127051541],
      },
    },
    polsodx_centralepalmare: {
      current: {
        position: [
          -0.30350659241553796, 0.9747670348837207, 0.07407337155822113,
        ],
        lookAt: [-0.30106026448822304, 0.9747670348837207, 1.0740703792935804],
      },
      child: {
        position: [
          -0.2632619937679666, 0.9747670348837207, 0.07401018802181712,
        ],
        lookAt: [-0.2608156658406517, 0.9747670348837207, 1.0740071957571764],
      },
    },
    polsodx_radialepalmare: {
      current: {
        position: [
          -0.3546854081504695, 0.9815938163265306, 0.02580756770653773,
        ],
        lookAt: [-1.1313107851556758, 0.9815938163265306, 0.655770283898156],
      },
      child: {
        position: [
          -0.3294868995028048, 0.9815938163265306, 0.05687258278674598,
        ],
        lookAt: [-1.1061122765080111, 0.9815938163265306, 0.6868352989783643],
      },
    },
    polsodx_ulnarepalmare: {
      current: {
        position: [-0.2545273091004648, 0.968678425925926, 0.04926901406780686],
        lookAt: [0.46097008251443844, 0.968678425925926, 0.7478844179863505],
      },
      child: {
        position: [
          -0.22584789807745145, 0.968678425925926, 0.019007100177698105,
        ],
        lookAt: [0.4896494935374518, 0.968678425925926, 0.7176225040962418],
      },
    },
    polsodx_centraledorsale: {
      current: {
        position: [
          -0.26791771382901136, 0.9611982888888891, -0.06997998941809286,
        ],
        lookAt: [-0.3362960397162544, 0.9611982888888891, -1.0676394526363084],
      },
      child: {
        position: [
          -0.30782409235773994, 0.9611982888888891, -0.06724485638260314,
        ],
        lookAt: [-0.37620241824498296, 0.9611982888888891, -1.0649043196008185],
      },
    },
    polsodx_ulnaredorsale: {
      current: {
        position: [
          -0.22288304543849466, 0.9583566551724138, -0.011882041116025675,
        ],
        lookAt: [0.5909824084931544, 0.9583566551724138, -0.5929354146512209],
      },
      child: {
        position: [
          -0.24612518037990247, 0.9583566551724138, -0.04443665927329164,
        ],
        lookAt: [0.5677402735517466, 0.9583566551724138, -0.6254900328084869],
      },
    },
    polsodx_radialedorsale: {
      current: {
        position: [
          -0.3246131015057403, 0.9715705945945943, -0.04681133105224348,
        ],
        lookAt: [-1.1050887751934528, 0.9715705945945943, -0.6719974815560047],
      },
      child: {
        position: [
          -0.3496205475258908, 0.9715705945945943, -0.015592304104734979,
        ],
        lookAt: [-1.1300962212136032, 0.9715705945945943, -0.6407784546084963],
      },
    },
    polsosx_centralepalmare: {
      current: {
        position: [
          0.26350253091618275, 0.9747744418604649, 0.07397312598640168,
        ],
        lookAt: [0.2610333747894877, 0.9747744418604649, 1.0739700776157666],
      },
      child: {
        position: [
          0.30325540593106254, 0.9747744418604649, 0.07410699923320066,
        ],
        lookAt: [0.30078624980436747, 0.9747744418604649, 1.0741039508625656],
      },
    },
    polsosx_radialepalmare: {
      current: {
        position: [
          0.32948703118967937, 0.9815936530612245, 0.05687358407341205,
        ],
        lookAt: [1.1061060440967108, 0.9815936530612245, 0.6868441459184149],
      },
      child: {
        position: [0.3546858536634795, 0.9815936530612245, 0.0258088235571308],
        lookAt: [1.1313048665705108, 0.9815936530612245, 0.6557793854021337],
      },
    },
    polsosx_ulnarepalmare: {
      current: {
        position: [
          0.22658102507991426, 0.9686993518518516, 0.02064678712088712,
        ],
        lookAt: [-0.4889034511607605, 0.9686993518518516, 0.7192754182680849],
      },
      child: {
        position: [
          0.25379198606431147, 0.9686993518518516, 0.047627189501314934,
        ],
        lookAt: [-0.4616924901763633, 0.9686993518518516, 0.7462558206485128],
      },
    },
    polsosx_centraledorsale: {
      current: {
        position: [
          0.30799573454694973, 0.9611984000000002, -0.06709829838779316,
        ],
        lookAt: [0.3830817424534716, 0.9611984000000002, -1.064275359604437],
      },
      child: {
        position: [
          0.26810865209828394, 0.9611984000000002, -0.07010173870405403,
        ],
        lookAt: [0.3431946600048058, 0.9611984000000002, -1.0672787999206979],
      },
    },
    polsosx_ulnaredorsale: {
      current: {
        position: [
          0.24612972451182857, 0.9583562068965519, -0.04443762748749206,
        ],
        lookAt: [-0.5676675612671644, 0.9583562068965519, -0.6255864704345845],
      },
      child: {
        position: [
          0.2228837707939449, 0.9583562068965519, -0.011885736056332342,
        ],
        lookAt: [-0.5909135149850482, 0.9583562068965519, -0.5930345790034248],
      },
    },
    polsosx_radialedorsale: {
      current: {
        position: [
          0.34962027714125415, 0.9715704594594593, -0.01558794097961499,
        ],
        lookAt: [1.1301858619762928, 0.9715704594594593, -0.6406618308287544],
      },
      child: {
        position: [
          0.32461732154728856, 0.9715704594594593, -0.04681056437301654,
        ],
        lookAt: [1.1051829063823273, 0.9715704594594593, -0.6718844542221559],
      },
    },
    manodx_pollice: {
      current: {
        position: [
          -0.3868270331873757, 0.9148518712643678, 0.09776519298254129,
        ],
        lookAt: [-0.633816250927472, 0.9148518712643678, 1.0667834210464595],
      },
      child: {
        position: [
          -0.34806630406481903, 0.9148518712643678, 0.10764476169214514,
        ],
        lookAt: [-0.5950555218049153, 0.9148518712643678, 1.0766629897560633],
      },
    },
    manodx_palmare: {
      current: {
        position: [
          -0.37203313684334505, 0.8463899532467533, 0.020736913527805604,
        ],
        lookAt: [-1.3620990972503402, 0.8463899532467533, -0.11986676367650659],
      },
      child: {
        position: [
          -0.37765728393151754, 0.8463899532467533, 0.06033955194408541,
        ],
        lookAt: [-1.3677232443385128, 0.8463899532467533, -0.08026412526022678],
      },
    },
    manodx_dorsale: {
      current: {
        position: [
          -0.24002335900476957, 0.8382078573046433, 0.06815380106944008,
        ],
        lookAt: [0.7432749478022691, 0.8382078573046433, 0.25015500939073443],
      },
      child: {
        position: [
          -0.23274331067191778, 0.8382078573046433, 0.02882186879715854,
        ],
        lookAt: [0.7505549961351209, 0.8382078573046433, 0.2108230771184529],
      },
    },
    manosx_pollice: {
      current: {
        position: [0.3479030941872541, 0.9148444551724138, 0.1075687183751712],
        lookAt: [0.5919924497444776, 0.9148444551724138, 1.0773214634105537],
      },
      child: {
        position: [0.3866932039886694, 0.9148444551724138, 0.09780514415288226],
        lookAt: [0.6307825595458929, 0.9148444551724138, 1.0675578891882649],
      },
    },
    manosx_palmare: {
      current: {
        position: [
          0.23891125824707635, 0.8464317587548642, 0.03968104463155259,
        ],
        lookAt: [-0.7510080387587281, 0.8464317587548642, 0.1813136165051646],
      },
      child: {
        position: [
          0.24457656112202084, 0.8464317587548642, 0.07927781651178477,
        ],
        lookAt: [-0.7453427358837836, 0.8464317587548642, 0.22091038838539678],
      },
    },
    manosx_dorsale: {
      current: {
        position: [
          0.3914523019838225, 0.8382070973952442, 0.040047221653433926,
        ],
        lookAt: [1.3746036741830645, 0.8382070973952442, -0.14274604775971317],
      },
      child: {
        position: [
          0.3841405712072966, 0.8382070973952442, 0.0007211667654642429,
        ],
        lookAt: [1.3672919434065387, 0.8382070973952442, -0.18207210264768284],
      },
    },
    lombosacrale_posterioredx: {
      current: {
        position: [
          -0.09223610341900232, 0.9223447763157896, -0.17577921710940325,
        ],
        lookAt: [-0.4278124169943739, 0.9223447763157896, -1.1177922413119163],
      },
      child: {
        position: [
          -0.12991662438710283, 0.9223447763157896, -0.1623561645663884,
        ],
        lookAt: [-0.4654929379624744, 0.9223447763157896, -1.1043691887689013],
      },
    },
    ancadx_anterolaterale: {
      current: {
        position: [
          -0.19793348984344836, 0.9496611604938272, -0.010009214870340902,
        ],
        lookAt: [-1.1748131597486264, 0.9496611604938272, 0.20378065983365512],
      },
      child: {
        position: [
          -0.18938189485528853, 0.9496611604938272, 0.029065971925866223,
        ],
        lookAt: [-1.1662615647604666, 0.9496611604938272, 0.24285584662986223],
      },
    },
    ancadx_anteromediale: {
      current: {
        position: [
          -0.0620973179073646, 0.8998737786259543, 0.09594517749229213,
        ],
        lookAt: [0.21882986326252246, 0.8998737786259543, 1.0556742636827345],
      },
      child: {
        position: [
          -0.023708154459746892, 0.8998737786259543, 0.08470809024549665,
        ],
        lookAt: [0.2572190267101402, 0.8998737786259543, 1.0444371764359393],
      },
    },
    lombosacrale_posterioresx: {
      current: {
        position: [
          0.12988852661538597, 0.9223043618421052, -0.16228800100418694,
        ],
        lookAt: [0.4655325253551277, 0.9223043618421052, -1.1042769107637427],
      },
      child: {
        position: [
          0.09220897022500374, 0.9223043618421052, -0.1757137609537766,
        ],
        lookAt: [0.42785296896474545, 0.9223043618421052, -1.1177026707133324],
      },
    },
    ancasx_anterolaterale: {
      current: {
        position: [
          0.18937576375320236, 0.9496607654320994, 0.029045044174996336,
        ],
        lookAt: [1.1662877003666443, 0.9496607654320994, 0.2426874278496186],
      },
      child: {
        position: [
          0.19792145910018727, 0.9496607654320994, -0.010031433289541342,
        ],
        lookAt: [1.1748333957136292, 0.9496607654320994, 0.20361095038508092],
      },
    },
    ancasx_anteromediale: {
      current: {
        position: [
          0.023616321551561157, 0.8999359160305345, 0.08465609701189644,
        ],
        lookAt: [-0.259763194695299, 0.8999359160305345, 1.043663943576092],
      },
      child: {
        position: [
          0.06197663541412898, 0.8999359160305345, 0.09599127766177085,
        ],
        lookAt: [-0.2214028808327312, 0.8999359160305345, 1.0549991242259664],
      },
    },
    cosciadx_posteriore: {
      current: {
        position: [
          -0.07207560854955375, 0.7145348142857142, -0.13763938806300346,
        ],
        lookAt: [-0.2220393522048646, 0.7145348142857142, -1.126330884733671],
      },
      child: {
        position: [
          -0.11162326841638046, 0.7145348142857142, -0.13164083831679102,
        ],
        lookAt: [-0.2615870120716913, 0.7145348142857142, -1.1203323349874585],
      },
    },
    cosciadx_laterale: {
      current: {
        position: [
          -0.19187207974739387, 0.7306858333333329, -0.04731198348119748,
        ],
        lookAt: [-1.1736770882881857, 0.7306858333333329, -0.23720386114903244],
      },
      child: {
        position: [
          -0.19946775485410725, 0.7306858333333329, -0.008039783139565805,
        ],
        lookAt: [-1.1812727633948992, 0.7306858333333329, -0.19793166080740077],
      },
    },
    cosciadx_anteriore: {
      current: {
        position: [
          -0.1203651245123937, 0.7305813367346936, 0.10710700197205605,
        ],
        lookAt: [-0.24881867886551007, 0.7305813367346936, 1.0988225279037878],
      },
      child: {
        position: [
          -0.08069650347512444, 0.7305813367346936, 0.1122451441461807,
        ],
        lookAt: [-0.2091500578282408, 0.7305813367346936, 1.1039606700779123],
      },
    },
    cosciadx_mediale: {
      current: {
        position: [
          -0.0026166234668799446, 0.713118690789474, -0.07515240566917637,
        ],
        lookAt: [0.8599726984457399, 0.713118690789474, -0.5810572007861299],
      },
      child: {
        position: [
          -0.022852815271558087, 0.713118690789474, -0.10965597854568117,
        ],
        lookAt: [0.8397365066410617, 0.713118690789474, -0.6155607736626347],
      },
    },
    cosciasx_posteriore: {
      current: {
        position: [
          0.11158026330769397, 0.7145327071428569, -0.13165301118824238,
        ],
        lookAt: [0.2609632363169173, 0.7145327071428569, -1.1204324240882713],
      },
      child: {
        position: [
          0.07202908679169281, 0.7145327071428569, -0.13762833010861134,
        ],
        lookAt: [0.2214120598009162, 0.7145327071428569, -1.1264077430086403],
      },
    },
    cosciasx_laterale: {
      current: {
        position: [
          0.19946067402502088, 0.7306832738095237, -0.008070366233204188,
        ],
        lookAt: [1.1811936029039314, 0.7306832738095237, -0.198334541407713],
      },
      child: {
        position: [
          0.1918501070180405, 0.7306832738095237, -0.04733968338836061,
        ],
        lookAt: [1.173583035896951, 0.7306832738095237, -0.23760385856286942],
      },
    },
    cosciasx_anteriore: {
      current: {
        position: [
          0.08075138089793701, 0.7305649081632655, 0.11225585790502665,
        ],
        lookAt: [0.20982834497376657, 0.7305649081632655, 1.1038904364393611],
      },
      child: {
        position: [0.1204167640393104, 0.7305649081632655, 0.10709277934199346],
        lookAt: [0.24949372811513995, 0.7305649081632655, 1.098727357876328],
      },
    },
    cosciasx_mediale: {
      current: {
        position: [
          0.022846130045270308, 0.7130929342105262, -0.10961353338008634,
        ],
        lookAt: [-0.8398491085807867, 0.7130929342105262, -0.615337692632866],
      },
      child: {
        position: [
          0.0026171636751591166, 0.7130929342105262, -0.07510572383504405,
        ],
        lookAt: [-0.8600780749508978, 0.7130929342105262, -0.5808298830878238],
      },
    },
    ginocchiodx_anteriore: {
      current: {
        position: [
          -0.11180715776117739, 0.5222656142857143, 0.0694145962208232,
        ],
        lookAt: [-0.3060790435984005, 0.5222656142857143, 1.050362318769405],
      },
      child: {
        position: [
          -0.0725692488592341, 0.5222656142857143, 0.07718547165431212,
        ],
        lookAt: [-0.2668411346964572, 0.5222656142857143, 1.058133194202894],
      },
    },
    ginocchiodx_laterale: {
      current: {
        position: [
          -0.15862130314957748, 0.5230446166666668, -0.059624671770728166,
        ],
        lookAt: [-1.1572825790318497, 0.5230446166666668, -0.11135141585145036],
      },
      child: {
        position: [
          -0.16069037291280638, 0.5230446166666668, -0.019678220735437277,
        ],
        lookAt: [-1.1593516487950786, 0.5230446166666668, -0.07140496481615946],
      },
    },
    ginocchiodx_posteriore: {
      current: {
        position: [-0.07264112118203242, 0.5325342, -0.1223853564648395],
        lookAt: [-0.23568661404301489, 0.5325342, -1.1090039085191374],
      },
      child: {
        position: [-0.11210586326420435, 0.5325342, -0.1158635367504002],
        lookAt: [-0.2751513561251868, 0.5325342, -1.1024820888046982],
      },
    },
    ginocchiodx_mediale: {
      current: {
        position: [
          -0.0023635239164301602, 0.5343612990654205, -0.04006175127448182,
        ],
        lookAt: [0.9309227292772121, 0.5343612990654205, -0.39919511034847943],
      },
      child: {
        position: [
          -0.016728858279390066, 0.5343612990654205, -0.07739320140222751,
        ],
        lookAt: [0.9165573949142521, 0.5343612990654205, -0.4365265604762251],
      },
    },
    ginocchiosx_anteriore: {
      current: {
        position: [
          0.07263768020425208, 0.5222641142857144, 0.07731219596283845,
        ],
        lookAt: [0.2674441105106857, 0.5222641142857144, 1.0581539032282181],
      },
      child: {
        position: [0.11187134849486728, 0.5222641142857144, 0.0695199387505811],
        lookAt: [0.30667777880130087, 0.5222641142857144, 1.0503616460159608],
      },
    },
    ginocchiosx_laterale: {
      current: {
        position: [
          0.16069127782290227, 0.5230471166666666, -0.019697067455682706,
        ],
        lookAt: [1.1593526242946088, 0.5230471166666666, -0.07142244868500003],
      },
      child: {
        position: [
          0.15862226257372958, 0.5230471166666666, -0.05964352131455097,
        ],
        lookAt: [1.1572836090454361, 0.5230471166666666, -0.11136890254386829],
      },
    },
    ginocchiosx_posteriore: {
      current: {
        position: [0.11205580379197515, 0.53253595, -0.11588222584016222],
        lookAt: [0.27411441727872643, 0.53253595, -1.1026633595241986],
      },
      child: {
        position: [0.07258455844461369, 0.53253595, -0.12236457037963228],
        lookAt: [0.23464317193136497, 0.53253595, -1.1091457040636685],
      },
    },
    ginocchiosx_mediale: {
      current: {
        position: [
          0.016716946499218746, 0.5343606074766357, -0.07735411950322887,
        ],
        lookAt: [-0.9166239558974009, 0.5343606074766357, -0.4363454284697047],
      },
      child: {
        position: [
          0.0023572941405597125, 0.5343606074766357, -0.040020483407364096,
        ],
        lookAt: [-0.9309836082560599, 0.5343606074766357, -0.39901179237383994],
      },
    },
    gambadx_anteromediale: {
      current: {
        position: [
          -0.04082436594428838, 0.28575760897435876, 0.00893899619797652,
        ],
        lookAt: [0.6667513744626411, 0.28575760897435876, 0.7155765069399309],
      },
      child: {
        position: [
          -0.012558865514610204, 0.28575760897435876, -0.01936403341830066,
        ],
        lookAt: [0.6950168748923193, 0.28575760897435876, 0.6872734773236537],
      },
    },
    gambadx_laterale: {
      current: {
        position: [
          -0.15637941628179822, 0.2922912410714286, -0.06973665024300696,
        ],
        lookAt: [-1.146775199302348, 0.2922912410714286, 0.06852467108408039],
      },
      child: {
        position: [
          -0.1508489634287147, 0.2922912410714286, -0.030120818922184968,
        ],
        lookAt: [-1.1412447464492645, 0.2922912410714286, 0.10814050240490239],
      },
    },
    gambadx_posteriore: {
      current: {
        position: [
          -0.026886595691327928, 0.37002493181818163, -0.14164554208468896,
        ],
        lookAt: [0.3649070914250394, 0.37002493181818163, -1.0616986420391223],
      },
      child: {
        position: [
          -0.06368871968950526, 0.37002493181818163, -0.15731728956934368,
        ],
        lookAt: [0.3281049674268621, 0.37002493181818163, -1.077370389523777],
      },
    },
    gambadx_achillea: {
      current: {
        position: [
          -0.03601894822587105, 0.1937886176470588, -0.13577402714181735,
        ],
        lookAt: [0.2114868083047448, 0.1937886176470588, -1.1046604500440105],
      },
      child: {
        position: [
          -0.07477440514195878, 0.1937886176470588, -0.14567425740304202,
        ],
        lookAt: [0.17273135138865708, 0.1937886176470588, -1.1145606803052353],
      },
    },
    gambasx_anteromediale: {
      current: {
        position: [
          0.01257040691374111, 0.28575823076923074, -0.019343169312589704,
        ],
        lookAt: [-0.6946866509977662, 0.28575823076923074, 0.6876133032048906],
      },
      child: {
        position: [
          0.04084866581444032, 0.28575823076923074, 0.00894711300387059,
        ],
        lookAt: [-0.6664083920970669, 0.28575823076923074, 0.7159035855213509],
      },
    },
    gambasx_laterale: {
      current: {
        position: [0.150769983917438, 0.2923567053571428, -0.03028571883096152],
        lookAt: [1.141465567052835, 0.2923567053571428, 0.1058107967600541],
      },
      child: {
        position: [
          0.15621384454107862, 0.2923567053571428, -0.0699135421563774,
        ],
        lookAt: [1.1469094276764757, 0.2923567053571428, 0.06618297343463822],
      },
    },
    gambasx_achillea: {
      current: {
        position: [
          0.07180645503204436, 0.19570122535211262, -0.1453225789579958,
        ],
        lookAt: [
          -0.21619924597044837, 0.19570122535211262, -1.1029512732806246,
        ],
      },
      child: {
        position: [
          0.0335013072591392, 0.19570122535211262, -0.13380235091789608,
        ],
        lookAt: [-0.25450439374335354, 0.19570122535211262, -1.091431045240525],
      },
    },
    gambasx_posteriore: {
      current: {
        position: [
          0.06363466913934945, 0.37002909090909075, -0.157310259046473,
        ],
        lookAt: [-0.3286104427994713, 0.37002909090909075, -1.0771709942450693],
      },
      child: {
        position: [
          0.026840239731405594, 0.37002909090909075, -0.1416204545689202,
        ],
        lookAt: [-0.3654048722074152, 0.37002909090909075, -1.0614811897675165],
      },
    },
    cavigliadx_anteriore: {
      current: {
        position: [
          -0.10098174711677974, 0.10551723880597016, 0.016876371868148783,
        ],
        lookAt: [-0.33079816704180265, 0.10551723880597016, 0.9901103689792474],
      },
      child: {
        position: [
          -0.06205238723233578, 0.10551723880597016, 0.0260690286651497,
        ],
        lookAt: [-0.29186880715735875, 0.10551723880597016, 0.9993030257762483],
      },
    },
    cavigliadx_posteriore: {
      current: {
        position: [
          -0.03539601938143408, 0.07369541836734692, -0.14341611478820182,
        ],
        lookAt: [0.17122805311163738, 0.07369541836734692, -1.121836521702519],
      },
      child: {
        position: [
          -0.07453283565800677, 0.07369541836734692, -0.15168107768792466,
        ],
        lookAt: [0.13209123683506468, 0.07369541836734692, -1.130101484602242],
      },
    },
    cavigliadx_mediale: {
      current: {
        position: [
          -0.0026360361106228998, 0.07890258227848101, -0.04299735965979992,
        ],
        lookAt: [0.9969014395499406, 0.07890258227848101, -0.07340845549846527],
      },
      child: {
        position: [
          -0.0038524799441695136, 0.07890258227848101, -0.08297885868622246,
        ],
        lookAt: [0.995684995716394, 0.07890258227848101, -0.1133899545248878],
      },
    },
    cavigliadx_laterale: {
      current: {
        position: [
          -0.11793095041416461, 0.0656004605263158, -0.100201490473021,
        ],
        lookAt: [-1.073678561733368, 0.0656004605263158, -0.3943893614970274],
      },
      child: {
        position: [
          -0.12969846525512488, 0.0656004605263158, -0.061971586020252864,
        ],
        lookAt: [-1.0854460765743281, 0.0656004605263158, -0.3561594570442593],
      },
    },
    cavigliasx_anteriore: {
      current: {
        position: [0.06208333460893932, 0.1055213283582089, 0.0260655689595806],
        lookAt: [0.29285364302274053, 0.1055213283582089, 0.9990738241941387],
      },
      child: {
        position: [
          0.10100366481832164, 0.1055213283582089, 0.01683475662302855,
        ],
        lookAt: [0.3317739732321228, 0.1055213283582089, 0.9898430118575867],
      },
    },
    cavigliasx_posteriore: {
      current: {
        position: [
          0.0744889814304241, 0.0737124081632653, -0.15168505857434997,
        ],
        lookAt: [-0.1327272565863783, 0.0737124081632653, -1.1299802240035592],
      },
      child: {
        position: [
          0.03535717481325572, 0.0737124081632653, -0.14339640905367787,
        ],
        lookAt: [-0.17185906320354666, 0.0737124081632653, -1.121691574482887],
      },
    },
    cavigliasx_laterale: {
      current: {
        position: [
          0.129713467285298, 0.06562144736842106, -0.06204333818414885,
        ],
        lookAt: [1.084925892551902, 0.06562144736842106, -0.3579643117840144],
      },
      child: {
        position: [
          0.11787662834130339, 0.06562144736842106, -0.10025183519481302,
        ],
        lookAt: [1.0730890536079074, 0.06562144736842106, -0.39617280879467853],
      },
    },
    cavigliasx_mediale: {
      current: {
        position: [
          0.0039018024771976557, 0.07889882278481007, -0.08302911015342058,
        ],
        lookAt: [
          -0.9955652898287491, 0.07889882278481007, -0.11567158857489168,
        ],
      },
      child: {
        position: [
          0.0025961033403388116, 0.07889882278481007, -0.04305042646118272,
        ],
        lookAt: [-0.996870988965608, 0.07889882278481007, -0.07569290488265382],
      },
    },
    piededx_dorsalemesopiede: {
      current: {
        position: [
          -0.13949626636888093, 0.06474502631578948, 0.013002607213943026,
        ],
        lookAt: [-0.9811586717735221, 0.06474502631578948, 0.5530066769471935],
      },
      child: {
        position: [
          -0.11789610357955091, 0.06474502631578948, 0.04666910343012867,
        ],
        lookAt: [-0.9595585089841921, 0.06474502631578948, 0.5866731731633792],
      },
    },
    piededx_plantareretropiede: {
      current: {
        position: [
          -0.03694329574941469, 0.010794930693069308, -0.14229104261198555,
        ],
        lookAt: [
          0.19874172641404148, 0.010794930693069308, -1.1141205391430442,
        ],
      },
      child: {
        position: [
          -0.07581647561065705, 0.010794930693069308, -0.15171844349852381,
        ],
        lookAt: [0.1598685465527991, 0.010794930693069308, -1.1235479400295825],
      },
    },
    piededx_plantaremesopiede: {
      current: {
        position: [
          -0.1557836351467731, 0.012952129870129872, -0.03369597164770924,
        ],
        lookAt: [
          -1.1527347645230441, 0.012952129870129872, -0.1117244641064128,
        ],
      },
      child: {
        position: [
          -0.15890477484512125, 0.012952129870129872, 0.006182073527341606,
        ],
        lookAt: [
          -1.1558559042213923, 0.012952129870129872, -0.07184641893136196,
        ],
      },
    },
    piededx_dorsaleavampiedealluce: {
      current: {
        position: [
          -0.08829694512708355, 0.042966369774919584, 0.16300170340445655,
        ],
        lookAt: [0.04606568733992081, 0.042966369774919584, 1.1539339326696758],
      },
      child: {
        position: [
          -0.048659655956474784, 0.042966369774919584, 0.15762719810577638,
        ],
        lookAt: [0.08570297651052958, 0.042966369774919584, 1.1485594273709956],
      },
    },
    piededx_dorsaleavampiededita: {
      current: {
        position: [
          -0.1544694018577238, 0.03706276400000003, 0.10463546454179681,
        ],
        lookAt: [-0.7946401819722742, 0.03706276400000003, 0.8728680935595498],
      },
      child: {
        position: [
          -0.12374009669701368, 0.03706276400000003, 0.13024229574637883,
        ],
        lookAt: [-0.7639108768115641, 0.03706276400000003, 0.8984749247641317],
      },
    },
    piededx_plantareavampiedealluce: {
      current: {
        position: [
          -0.08734309331308229, 0.0019893704379562067, 0.16002781704803115,
        ],
        lookAt: [0.0713481531008262, 0.0019893704379562067, 1.1473560746764657],
      },
      child: {
        position: [
          -0.04784996300794492, 0.0019893704379562067, 0.15368016719147481,
        ],
        lookAt: [
          0.11084128340596358, 0.0019893704379562067, 1.1410084248199093,
        ],
      },
    },
    piededx_plantareavampiededita: {
      current: {
        position: [
          -0.15479486911906165, -0.007900800527240803, 0.0990296417501518,
        ],
        lookAt: [
          -0.9127913903565243, -0.007900800527240803, 0.7512882360146544,
        ],
      },
      child: {
        position: [
          -0.12870452534848154, -0.007900800527240803, 0.1293495025996503,
        ],
        lookAt: [
          -0.8867010465859443, -0.007900800527240803, 0.7816080968641529,
        ],
      },
    },
    piedesx_dorsalemesopiede: {
      current: {
        position: [
          0.11738428146148583, 0.06478284210526314, 0.04698506660716975,
        ],
        lookAt: [0.9530749987486269, 0.06478284210526314, 0.5961854171534015],
      },
      child: {
        position: [
          0.1393522954833351, 0.06478284210526314, 0.013557437915684107,
        ],
        lookAt: [0.9750430127704761, 0.06478284210526314, 0.5627577884619158],
      },
    },
    piedesx_plantareretropiede: {
      current: {
        position: [
          0.07580494932005509, 0.01079310396039604, -0.15172274232617977,
        ],
        lookAt: [
          -0.16029159370876567, 0.01079310396039604, -1.1234523458527166,
        ],
      },
      child: {
        position: [
          0.03693576517899362, 0.01079310396039604, -0.14227888060502691,
        ],
        lookAt: [-0.1991607778498271, 0.01079310396039604, -1.1140084841315638],
      },
    },
    piedesx_plantaremesopiede: {
      current: {
        position: [
          0.03502576267430397, 0.012909155844155844, -0.02427618137789015,
        ],
        lookAt: [-0.9620458103892204, 0.012909155844155844, 0.0521978546646062],
      },
      child: {
        position: [
          0.038084724116003825, 0.012909155844155844, 0.01560668154465083,
        ],
        lookAt: [
          -0.9589868489475206, 0.012909155844155844, 0.09208071758714718,
        ],
      },
    },
    piedesx_dorsaleavampiedealluce: {
      current: {
        position: [
          0.04859833494020672, 0.042967842443729885, 0.1575699507965774,
        ],
        lookAt: [
          -0.08759712264414066, 0.042967842443729885, 1.1482519367705781,
        ],
      },
      child: {
        position: [
          0.08822561437916676, 0.042967842443729885, 0.16301776909995128,
        ],
        lookAt: [-0.04796984320518061, 0.042967842443729885, 1.153699755073952],
      },
    },
    piedesx_dorsaleavampiededita: {
      current: {
        position: [0.1295829057137346, 0.03715390810810809, 0.1343591352783601],
        lookAt: [0.8075045898422675, 0.03715390810810809, 0.8694932660248067],
      },
      child: {
        position: [
          0.15898827094359247, 0.03715390810810809, 0.1072422679132188,
        ],
        lookAt: [0.8369099550721254, 0.03715390810810809, 0.8423763986596654],
      },
    },
    piedesx_plantareavampiedealluce: {
      current: {
        position: [
          0.04784423199344749, 0.0019909799270072873, 0.1536782278946214,
        ],
        lookAt: [
          -0.11115672515127516, 0.0019909799270072873, 1.1409566565190201,
        ],
      },
      child: {
        position: [
          0.08733536913842345, 0.0019909799270072873, 0.1600382661804103,
        ],
        lookAt: [-0.0716655880062992, 0.0019909799270072873, 1.147316694804809],
      },
    },
    piedesx_plantareavampiededita: {
      current: {
        position: [
          0.12624984926639632, -0.007861868122270754, 0.130034549223357,
        ],
        lookAt: [0.832682578501579, -0.007861868122270754, 0.8378147404305787],
      },
      child: {
        position: [
          0.1545610569146852, -0.007861868122270754, 0.1017772400539497,
        ],
        lookAt: [0.8609937861498678, -0.007861868122270754, 0.8095574312611714],
      },
    },
  },
};

export default markers;
