import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../../App.css";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { labels } from "../shared/translations";
import PatientsPresentations from "./PatientsPresentations";
import { SpStepper } from "../../components/bundles";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { Grid } from "@material-ui/core";
import SpText from "../../components/atoms/SpText";
import PatientsMonitoringStepPathologies from "./patientsMonitoring/PatientsMonitoringStepPathologies";
import InjurySurveillanceConfigurable from "./patientsReports/InjurySurveillanceConfigurable";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserPermission } from "../../utils/common";
import PatientsReportsStep5Exposure from "./patientInjSurGen/PatInjSurGen";

const PatientsInjuryIllness = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState(null);
  const [injurySuerveillancePermission, setInjurySurveillancePermission] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { control, register, errors, getValues } = useForm({
    shouldUnregister: false,
  });
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      const permission = await getUserPermission(
        "injury-surveillance",
        getAccessTokenSilently
      );
      setInjurySurveillancePermission(permission);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;
  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justyfyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const titles = injurySuerveillancePermission
    ? labels.patient.injury.steps.map((t, idx) => (
        <StepTitle key={idx}>{t}</StepTitle>
      ))
    : labels.patient.injury.stepsNoSurveillance.map((t, idx) => (
        <StepTitle key={idx}>{t}</StepTitle>
      ));
  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid item xs={2} style={{ margin: "auto 0", padding: 0 }}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ paddingTop: "2em" }}
          >
            <Grid item xs={12}>
              <SpStepper
                titles={titles}
                stepperControls={StepperControls(errors)}
                containerWidth={"92%"}
              >
                <PatientsPresentations setLoading={setLoading} />
                <PatientsMonitoringStepPathologies
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                
                {injurySuerveillancePermission && (
                  <>
                    <PatientsReportsStep5Exposure />
                    <br />
                    <hr></hr>
                    <h1 style={{color:'#FFFFFF'}}> {labels.injSur.details} </h1>
                    <InjurySurveillanceConfigurable />
                  </>
                )}
              </SpStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsInjuryIllness);
