import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import { theme } from "../../../components/theme";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import { getProfessionalByEmail } from "../../../models/actions/Professionals";
import { ProfessionalCard } from "./OrganizationHelper";
import {
  getOrganization,
  updateOrganization,
} from "../../../models/actions/Organization";
import SpLoader from "../../../components/atoms/SpLoader";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import OrganizationScheduler from "./OrganizationScheduler";
import DragAndDropPage from "./DragAndDropPeople";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});

const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});

const Organization = (props) => {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const [usersFound, setUsersFound] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(false);

  const addCallback = (newProfessiona) => {
    setUsersFound(null);
    setSearchString("");
    setProfessionals([newProfessiona, ...professionals]);
  };

  const onDelete = (person) => {
    setProfessionals(professionals.filter(({ id }) => id !== person.id));
  };

  const fetchData = async () => {
    setLoading(true);
    const res = await getOrganization({});
    setProfessionals(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const searchProfessionals = () => {
    if (searchString) {
      try {
        getProfessionalByEmail({ email: searchString }).then((response) => {
          if (response.length > 0) {
            setUsersFound(response);
          } else {
            setUsersFound([]);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("SearchProfessionals", error);
      }
    }
  };

  const updateOrganizationFunction = async () => {
    setLoading(true);
    const res = await updateOrganization({
      professionals: professionals.map(({ id }) => id),
    });
    if (res?.message) {
      props.snackbarShowMessage(res?.message);
    }
    if (res?.error) {
      props.snackbarShowErrorMessage(res?.error);
    }
    await fetchData();
    setLoading(false);
  };

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"Organization"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          xs={12}
          style={{ alignItems: "self-end", marginBottom: "10px" }}
          spacing={2}
        >
          <Grid item xs={6} style={{ paddingTop: "2em" }}>
            <SpTextInput
              value={searchString}
              label={
                labels.groups.groupAdd.addProfessionalTable.dialog.searchText
              }
              style={{ width: "100%" }}
              onChange={(e) => setSearchString(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingTop: "2em" }}>
            <SpButton
              text={labels.patient.patientLink.inputSearch.buttons.search}
              variant="none"
              buttonType="accept"
              onClick={() => searchProfessionals()}
            />
          </Grid>
          <Grid item xs={4} style={{ paddingTop: "2em", paddingLeft: "5em" }}>
            <SpButton
              buttonType={"accept"}
              variant="standardOppositeFull"
              text={labels.groups.groupDetail.actions.save}
              style={{ width: "100%" }}
              onClick={async () => {
                await updateOrganizationFunction();
              }}
            />
          </Grid>
        </Grid>
        <StyledWrapperPatientsList
          item
          container
          direction="column"
          xs={12}
          style={{ marginBottom: "2%" }}
        >
          {usersFound ? (
            usersFound.map((professional) => (
              <StyledRowPatientFound
                key={professional.id}
                item
                container
                direction={"row"}
                xs={12}
              >
                <Grid item xs={6} alignSelf={"center"}>
                  <SpText variant="text">{`${professional.name} ${professional.surname} - ${professional.email}`}</SpText>
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    text={
                      labels.groups.groupAdd.addProfessionalTable.dialog
                        .addProfessionalButton
                    }
                    buttonType="accept"
                    variant="none"
                    style={{ width: "100%" }}
                    onClick={() => addCallback(professional)}
                  />
                </Grid>
              </StyledRowPatientFound>
            ))
          ) : (
            <SpText variant={"text"}>
              {
                labels.groups.groupAdd.addProfessionalTable.dialog
                  .usersFoundPlaceholder
              }
            </SpText>
          )}
        </StyledWrapperPatientsList>
      </Grid>
      <ProfessionalCard displayPatients={professionals} onDelete={onDelete} />
      {professionals && professionals.length > 0 && (
        <DragAndDropPage professionals={professionals} props={props} />
      )}
      {/* <OrganizationScheduler props={props} professionals={professionals} /> */}
    </Grid>
  );
};

export default withSnackbar(Organization);
