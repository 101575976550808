import { Chip, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { SpAutocomplete } from "../../../../../components/atoms/SpAutocomplete";
import SpButton from "../../../../../components/atoms/SpButton";
import SpText from "../../../../../components/atoms/SpText";
import SpTextArea from "../../../../../components/atoms/SpTextArea";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { theme } from "../../../../../components/theme";
import { getAllExerciseCategories } from "../../../../../models/actions/Excercise";
import { getResourceFromServer } from "../../../../../models/actions/Professionals";
import { labels } from "../../../../shared/translations";

const ExerciseRow = ({ exercise, updateExercise, setFile }) => {
  const [categories, setCategories] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileExtention, setFileExtention] = useState("");
  const fileInput = useRef();

  useEffect(async () => {
    //Fetch exercise categories

    const newCategories = await getAllExerciseCategories();
    setCategories(newCategories);
    if (exercise?.file) {
      setFileExtention(exercise?.file?.extension);
      const resourceDetail = await getResourceFromServer({
        id_file: exercise?.file.id,
      });
      setFileUrl(resourceDetail);
    }
  }, []);

  return (
    <Grid container item xs={12}>
      <Grid item container style={{ justifyContent: "flex-start" }}>
        <Grid item xs={12}>
          <SpTextInput
            label={labels.mylab.tempAdd.exercise.tags}
            style={{ width: "100%" }}
            value={exercise.tags}
            onChange={(evnt) => updateExercise("tags", evnt.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: "1%" }}>
          <SpAutocomplete
            multiple
            formControlWidth={"100%"}
            value={exercise.id_categories}
            displayLabel={true}
            label={labels.mylab.tempAdd.exercise.categories}
            selectPlaceholder={labels.mylab.tempAdd.exercise.categories}
            onChange={(e, newValue) => {
              updateExercise(
                "id_categories",
                newValue.map((el) => el?.id ?? el)
              );
            }}
            options={categories}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option?.id === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: "white",
                  }}
                  label={
                    categories.find((cat) => option === cat.id)?.name ?? ""
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: "1%" }}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.exercise.gif}
          </SpText>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <SpButton
              type={"submit"}
              buttonType={"accept"}
              text={labels.mylab.tempAdd.exercise.selectGif}
              onClick={() => fileInput.current.click()}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            {fileExtention === "mp4" || fileExtention === "mov" ? (
              <video
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  border: "1px solid white",
                }}
                controls
                src={fileUrl}
              />
            ) : (
              <img
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  border: "1px solid white",
                }}
                src={fileUrl}
              />
            )}
          </Grid>
          <input
            ref={fileInput}
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {
              setFileExtention(e.target.files[0].name.split(".")[1]);
              setFile(e.target.files[0]);
              setFileUrl(window.URL.createObjectURL(e.target.files[0]));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExerciseRow;
