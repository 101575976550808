import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "../../../App.css";
import { dateFormat } from "../../../utils/common";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { DeleteForever } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { extendMoment } from "moment-range";
import SpButton from "../../../components/atoms/SpButton";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../shared/translations";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpDialog from "../../../components/atoms/SpDialog";
const moment = extendMoment(Moment);
import {
  getMedicalNotes,
  addMedicalNotes,
  removeMedicalNotes,
} from "../../../models/actions/MedicalNotes";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import SpTextArea from "../../../components/atoms/SpTextArea";
const DEFAULT_RANGE = moment.rangeFromInterval("month", -6, moment());
const constPadding = { padding: "5px" };
const userDateFormat = "DD/MM/yyyy";

const MEDICAL_NOTE_TYPE = "drug";

const PatientsMedicalNotesDrugs = (props) => {
  const { patId } = useParams();

  const [displayReports, setDisplayReports] = useState();
  const [dateSelected, setDateSelected] = useState(moment());
  const [drugTypes, setDrugTypes] = useState([]);
  const [drugName, setDrugName] = useState("");
  const [days, setDays] = useState("");
  const [drugDoseDaily, setDrugDoseDaily] = useState("");
  const [time, setTime] = useState("");
  const [drugDetailModal, setDrugDetailModal] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [drugsList, setDrugsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteContent, setOpenDeleteContent] = useState({});

  const { register, control, getValues, setValue } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    const res = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date: dateRange.start,
        end_date: dateRange.end,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDrugsList(res.question);
    setDrugTypes(res.categories);
    setDisplayReports(res.answer);
  }, []);

  const headCellsFirst = [
    {
      type: "string",
      field: "subject",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.drug.drugName),
      flex: 2.5,
      valueGetter: (params) => params.row.drug_name,
      renderCell: (params) => (
        <SpText variant={"tableText"}>{params.row.drug_name}</SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "date",
      field: "date",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.report.date),
      flex: 0.5,
      valueGetter: (params) =>
        moment(params.row.endDate).format(userDateFormat),
      renderCell: (params) => (
        <SpText variant={"tableText"}>
          {moment(params.row.date).format(userDateFormat)}
        </SpText>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
    {
      type: "action",
      field: "action",
      numeric: false,
      editable: false,
      sortable: false,
      headerName: " ",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <SpIconButton
            id="infoButton"
            variant="lightBlueFill"
            onClick={async () => {
              setDrugDetailModal(params?.row);
              setDialog(true);
            }}
          >
            <InfoIcon />
          </SpIconButton>

          <SpIconButton
            id="deleteButton"
            variant="lightBlueFill"
            onClick={async () => {
              setOpenDeleteContent({ id: params.id });
              setOpenDelete(true);
            }}
          >
            <DeleteForever />
          </SpIconButton>
        </>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
  ];

  const searchReport = async ({ start_date, end_date }) => {
    const resultSearch = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date,
        end_date,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDisplayReports(resultSearch.answer);
  };

  const addReport = async () => {
    if (getValues("drugName")?.name && getValues("drugDose")?.name) {
      const resAdd = await addMedicalNotes({
        data: {
          note_report: getValues("notes"),
          subject: "drugs",
          id_patient: patId,
          drug_type: getValues("drugType")?.name,
          drug_name: getValues("drugName")?.name,
          dose: getValues("drugDose")?.name,
          days,
          drug_dose_daily: drugDoseDaily,
          time,
          date: dateSelected,
          type: MEDICAL_NOTE_TYPE,
        },
      });

      if (resAdd.message) {
        setDisplayReports([...displayReports, resAdd.body]);
        setValue("drugType", "");
        setValue("drugName", "");
        setValue("drugDose", "");
        setValue("notes", "");
        setDays(0);
        setDrugDoseDaily(0);
        setTime("");
        props.snackbarShowMessage(resAdd.message);
      } else {
        props.snackbarShowErrorMessage(resAdd.error);
      }
    } else {
      props.snackbarShowErrorMessage(
        labels.patient.controlQuestions.insertAllData
      );
    }
  };

  const gridReport = (
    <>
      {/* Add report section */}
      <Grid container spacing={3}>
        <Grid container>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.drug.add)}
            </SpText>
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <Controller
              render={(props) => (
                <SpSelect
                  id="drugType"
                  label={psTranslate(
                    labels.patient.medicalNotes.drug.drugType.toUpperCase()
                  )}
                  allowDeselect={true}
                  labelPaddingTop={"0px"}
                  labelMarginBottom={"0px"}
                  formControlStyle={{ width: "100%" }}
                  value={props.value}
                  onChange={(e) => {
                    const medicineSel = new Set();
                    e.target.value?.medicine_categories.map(({ medicine }) =>
                      medicineSel.add(medicine)
                    );
                    setDrugsList([...medicineSel]);
                    props.onChange(e.target.value); //Get the answer
                  }}
                >
                  {drugTypes?.map((p, index) => (
                    <SpSelectMenuItem
                      key={p.id}
                      value={p}
                      id={`drugType-option-${index}`}
                    >
                      {psTranslate(p.name)}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name={`drugType`}
              control={control}
            />
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <Controller
              render={(props) => (
                <SpAutocomplete
                  id="drugName"
                  style={{ width: "100%" }}
                  formControlWidth={"100%"}
                  selectPlaceholder={labels.patient.medicalNotes.drug.drugName.toUpperCase()}
                  value={props.value}
                  onChange={(_, newValue) => {
                    setDrugName(newValue);
                    props.onChange(newValue); //Get the answer
                  }}
                  displayLabel={true}
                  renderTags={(tagValue) =>
                    tagValue.map((option) => (
                      <SpText
                        style={{ marginLeft: "8px" }}
                        variant="h4ComponentLabel"
                      >
                        {psTranslate(option.name)}
                      </SpText>
                    ))
                  }
                  options={drugsList}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) =>
                    option.name === value?.name
                  }
                />
              )}
              defaultValue={""}
              name={`drugName`}
              control={control}
            />
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <Controller
              render={(props) => (
                <SpSelect
                  id="drugDose"
                  label={psTranslate(
                    labels.patient.medicalNotes.drug.drugDose.toUpperCase()
                  )}
                  allowDeselect={true}
                  labelPaddingTop={"0px"}
                  labelMarginBottom={"0px"}
                  formControlStyle={{ width: "100%" }}
                  value={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.value); //Get the answer
                  }}
                >
                  {drugName?.dosages?.map((p, index) => (
                    <SpSelectMenuItem
                      key={p.id}
                      value={p}
                      id={`drugDose-option-${index}`}
                    >
                      {psTranslate(p.name)}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name={`drugDose`}
              control={control}
            />
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <SpTextInput
              type="number"
              name="days"
              id="days"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setDays(evnt.target.value);
              }}
              value={days}
              label={psTranslate(
                labels.patient.medicalNotes.drug.days.toUpperCase()
              )}
            />
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <SpTextInput
              type="number"
              name="drugDoseDaily"
              id="drugDoseDaily"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setDrugDoseDaily(evnt.target.value);
              }}
              value={drugDoseDaily}
              label={psTranslate(
                labels.patient.medicalNotes.drug.drugDoseDaily.toUpperCase()
              )}
            />
          </Grid>
          <Grid item xs={4} style={constPadding}>
            <SpTextInput
              name="time"
              id="time"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setTime(evnt.target.value);
              }}
              value={time}
              label={psTranslate(
                labels.patient.medicalNotes.drug.time.toUpperCase()
              )}
            />
          </Grid>

          {/* Dates input*/}
          <Grid item xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.medicalNotes.report.date.toUpperCase()
              )}
              value={moment(dateSelected).format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) =>
                setDateSelected(moment(evnt.target.value).format(dateFormat))
              }
            />
          </Grid>
          <Grid item xs={8} style={constPadding}>
            <Controller
              render={(props) => (
                <SpTextArea
                  value={props.value}
                  id="notes"
                  name="notes"
                  label={psTranslate(
                    labels.patient.monitoring.pathologiesStep.note.toUpperCase()
                  )}
                  onChange={(e) => {
                    props.onChange(e.target.value); //Get the answer
                  }}
                />
              )}
              defaultValue={""}
              name={`notes`}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <SpButton
              id="addFormButton"
              style={{ marginTop: "1em" }}
              text={psTranslate(labels.patient.medicalNotes.report.addButton)}
              buttonType={"accept"}
              variant="none"
              onClick={addReport}
            />
          </Grid>
        </Grid>

        {/* Search report section */}
        <Grid container style={{ paddingTop: "4em" }}>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.drug.search)}
            </SpText>
          </Grid>

          {/* Dates */}
          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.start_date
              )}
              value={dateRange.start.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                moment(evnt.target.value).isBefore(dateRange.end) &&
                  setDateRange(moment.range(evnt.target.value, dateRange.end));
                searchReport({
                  start_date: evnt.target.value,
                  end_date: dateRange.end,
                });
              }}
            />
          </Grid>

          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.end_date
              )}
              value={dateRange.end.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                dateRange.start.isBefore(moment(evnt.target.value)) &&
                  setDateRange(
                    moment.range(dateRange.start, evnt.target.value)
                  );
                searchReport({
                  start_date: dateRange.start,
                  end_date: evnt.target.value,
                });
              }}
            />
          </Grid>
        </Grid>

        {/* Table report section */}
        {displayReports?.length > 0 && (
          <Grid container style={{ marginLeft: "4px" }} id="drugGrid">
            <SpDataGrid
              rows={displayReports}
              headers={headCellsFirst}
              emptyText={psTranslate(labels.groups.groupReports.no_patients)}
            />
          </Grid>
        )}
      </Grid>

      <SpDialog
        style={{ padding: "1%" }}
        open={dialog}
        setOpen={setDialog}
        title={psTranslate(labels.patient.medicalNotes.drug.modalNote)}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.drugType)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.drug_type}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.drugName)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.drug_name}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.drugDose)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.dose}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.days)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.days}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.drugDoseDaily)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.drug_dose_daily}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.drug.time)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.time}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.monitoring.pathologiesStep.note)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{drugDetailModal?.note_report}</SpText>
          </Grid>
        </Grid>
      </SpDialog>

      <SpDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={labels.patient.medicalNotes.drug.deleteTitle}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.patient.medicalNotes.drug.deleteConfirm}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                id="deleteTrue"
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={async () => {
                  const resRemove = await removeMedicalNotes(openDeleteContent);
                  if (resRemove.error) {
                    props.snackbarShowErrorMessage(resRemove.message);
                  } else {
                    props.snackbarShowMessage(resRemove.message);
                  }
                  props.snackbarShowMessage(resRemove.message);
                  setDisplayReports(
                    displayReports.filter(
                      ({ id }) => id != openDeleteContent.id
                    )
                  );
                  setOpenDelete(false);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenDelete(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );

  return gridReport;
};

export default withSnackbar(PatientsMedicalNotesDrugs);
