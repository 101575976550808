import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getAssessmentCount } from "../../../models/actions/Assessment";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsAssessments = ({
  dateRange,
  currActivities,
  setCurrActivities,
  setData,
  setAssessmentsIds,

  dateLoaded,
  loadConfiguration,
  setConfig,
  requestSaveFlag,

  enabled,
  ...props
}) => {
  const [assessments, setAssessments] = useState(null);
  const [currAssessments, setCurrAssessments] = useState([]);

  const [assessmentsData, setAssessmentsData] = useState([]);

  const { groupId, patId } = useParams();

  useEffect(async () => {
    if (dateLoaded) {
      try {
        const assessmentsCounts = await getAssessmentCount({
          id_group: groupId,
          id_patients: patId ? [patId] : null,
          startDate: dateRange.start.format(dateFormat),
          endDate: dateRange.end.format(`${dateFormat} 23:59:59`),
        });
        setAssessmentsData(assessmentsCounts);

        //Extract unique assessment names
        const newAssessments = [
          ...new Set(
            Object.values(assessmentsCounts)
              .map((el) => Object.keys(el))
              .flat()
          ),
        ];
        setAssessments(newAssessments);
      } catch (error) {
        rollbar.error("StatisticsAssessments - fetchDataInitial", error);
        props.snackbarShowErrorMessage(error);
      }
    }
  }, [dateLoaded, dateRange]);

  useEffect(() => {
    //Ensure ready
    if (assessments) {
      const assessmentIds = {
        ids_monitoring_assessment: [],
        ids_presentation_assessment: [],
      };

      //Adapt data
      const newData = Object.entries(assessmentsData).reduce(
        (accum, [patientKey, patientData]) => {
          accum[patientKey] = Object.entries(patientData).reduce(
            (accum, [assessmentName, assessmentData]) => {
              if (currAssessments.includes(assessmentName)) {
                //Table data
                accum[
                  `${
                    labels.groups.groupReports.secondTable.sessions
                  } ${psTranslate(assessmentName)}`
                ] = assessmentData.count;
                //Ids for measurements fetching
                assessmentIds.ids_monitoring_assessment.push(
                  ...assessmentData.ids_monitoring_assessment
                );
                assessmentIds.ids_presentation_assessment.push(
                  ...assessmentData.ids_presentation_assessment
                );
              }
              return accum;
            },
            {}
          );
          return accum;
        },
        {}
      );
      setData(newData);

      assessmentIds.ids_monitoring_assessment = [
        ...new Set(assessmentIds.ids_monitoring_assessment),
      ];
      assessmentIds.ids_presentation_assessment = [
        ...new Set(assessmentIds.ids_presentation_assessment),
      ];
      setAssessmentsIds(assessmentIds);
    }
  }, [assessments, currAssessments]);

  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currAssessments)
        setCurrAssessments(loadConfiguration.currAssessments);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currAssessments: currAssessments,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <SpSelect
      formControlStyle={{ minWidth: "100%", display: enabled ? null : "none" }}
      label={labels.groups.groupReports.secondTable.chooseAssessment}
      selectPlaceholder={labels.groups.groupReports.secondTable.selectValue}
      multiple
      value={currAssessments}
      onChange={(evnt) => setCurrAssessments(evnt.target.value)}
    >
      {assessments?.map((assessment) => (
        <SpSelectMenuItem key={assessment} value={assessment}>
          {psTranslate(assessment)}
        </SpSelectMenuItem>
      ))}
    </SpSelect>
  );
};

export default withSnackbar(StatisticsAssessments);
