/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import { getPathologies } from "../../../models/actions/Pathologies";
import SpLoader from "../../../components/atoms/SpLoader";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import { Grid } from "@material-ui/core";
import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";

import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientInjury,
  getPatientsSelection,
  getRange,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  getAutocomplete,
  injuryGroupsByGravity,
  injuryGroupsByMechanism,
  injuryGroupsByReinjury,
  injuryGroupsByTissueTypes,
  injuryLocalizationGroups,
  injuryPatientsByGravity,
  injuryPatientsByMechanis,
  injuryPatientsByReinjury,
  selectedGroupsInjury,
  selectPatientsInjury,
  selectPatientsTissueTypeInjury,
} from "./patientsReportInjury/GeneralInjuryFunction";

import SpIconButton from "../../../components/atoms/SpIconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { theme } from "../../../components/theme";

// set data range
const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";
const PatientsReportsStep5Exposure = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barCharLocalization, setBarCharLocalization] = useState([]);
  const [barCharTissue, setBarCharTissue] = useState([]);
  const [barCharMechanism, setBarCharMechanism] = useState([]);
  const [barCharGravity, setBarCharGravity] = useState([]);
  const [barCharReinjury, setBarCharReinjury] = useState([]);
  const [injuryGroup, setInjuryGroup] = useState([]);
  const [dataChartLocalization, setDataChartLocalization] = useState({});
  const [dataChartTissue, setDataChartTissue] = useState({});
  const [dataChartMechanism, setDataCharMechanism] = useState({});
  const [dataChartGravity, setDataCharGravity] = useState({});
  const [dataChartReinjury, setDataCharReinjury] = useState({});
  const [allRegions, setAllRegions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAreasAvailable, setAllAreasAvailable] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState([]);
  const [tissueTypes, setTissueTypes] = useState([]);
  const [mechanism, setMechanism] = useState([]);
  const [gravities, setGravities] = useState([]);
  const [reinjuries, setReinjuries] = useState([]);

  const [fullSizeRegion, setFullSizeRegion] = useState(false);
  const [fullSizeTissue, setFullSizeTissue] = useState(false);
  const [fullSizeMechanism, setFullSizeMechanism] = useState(false);
  const [fullSizeGravity, setFullSizeGravity] = useState(false);
  const [fullSizeReinjury, setFullSizeReinjury] = useState(false);

  const { patId, groupId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      const allRegionTemp = await getRegionAreaStructureLists({});
      setAllRegions(allRegionTemp?.regionList);
      setAllAreas(allRegionTemp?.areaList);
      setAllAreasAvailable(allRegionTemp?.areaList);

      const tempPathologies = await getPathologies();
      let tempTissueType = [];
      tempPathologies.forEach(({ tissue_type }) => {
        if (tissue_type) tempTissueType.push(tissue_type);
      });

      // elimino i duplicati delle tissue type
      tempTissueType = tempTissueType.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      setTissueTypes(tempTissueType);

      // set mechanism da label
      let tempArrayMechanism = [];
      labels.patient.presentation.add.stepper.step0.detail.mechanism.choise.forEach(
        (tempMechanism) => {
          tempArrayMechanism.push({
            name: tempMechanism.value,
            id: tempMechanism.key,
          });
        }
      );
      setMechanism(tempArrayMechanism);
      // set delle gravities
      setGravities(labels.analytics.injuryReport.gravityRange);
      setReinjuries(labels.analytics.injuryReport.reinjuryRange);

      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);
        setPatients(result.allPatients);
       
        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        let tempInjuryGroup = await getPatientInjury(
          tempGroupPatient,
          dateRange
        );

        for (let g = 0; g<tempGroupPatient.length; g++) {
          let groupP = tempGroupPatient[g]
          let groupInj = tempInjuryGroup.find(({ group }) => parseInt(group.id) === parseInt(groupP.id) )

          for (let p = 0; p < groupP?.patients.length; p++) {
            let pat = groupP?.patients[p];

            let patientId = parseInt(pat?.id);
            if (!isNaN(patientId)) {
                let inj_lst = groupInj?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
                if (inj_lst !== undefined) {
                  tempGroupPatient[g].patients[p].injuries = inj_lst;
                } 
            }
          }
        }

        setGroups(tempGroupPatient);
        setSelectedGroups(result.mapGroup);
       tempInjuryGroup = await Promise.all([
          getPatientInjury(result.mapGroup, dateRange),
        ]);
        setInjuryGroup(tempInjuryGroup);

        // calcolo del paziente attuale in base al suo id
        const tempPat = result.allPatients.find(
          ({ id }) => id === parseInt(patId)
        );
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction(
          tempPat ? [tempPat] : [],
          "group",
          tempGroupPatient,
          tempArrayMechanism,
          labels.analytics.injuryReport.gravityRange,
          labels.analytics.injuryReport.reinjuryRange,
          tempTissueType
        );
      } else {
        // groups
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );

        let tempInjuryGroup = await getPatientInjury(tempGroups, dateRange);
        setInjuryGroup(tempInjuryGroup);

        let tempInjuryGroupI = []
        for (let g of tempInjuryGroup) {
          if ( parseInt(g?.group?.id) === parseInt(groupId) ) {
            tempInjuryGroupI.push(g)
          }
        }

        for (let p = 0; p < groupsSelected?.patients.length; p++) {
          let pat = groupsSelected?.patients[p];

          let patientId = parseInt(pat?.id);
          if (!isNaN(patientId)) {
              let inj_lst = tempInjuryGroupI[0]?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
              if (inj_lst !== undefined) {
                groupsSelected.patients[p].injuries = inj_lst;
              } 
          }
        }

        setInjuryGroup([groupsSelected]);
        setSelectedGroups([groupsSelected]);

        selectionActionFunction(
          [],
          "group",
          [groupsSelected],
          tempArrayMechanism,
          labels.analytics.injuryReport.gravityRange,
          labels.analytics.injuryReport.reinjuryRange,
          tempTissueType
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  /**
   * Funzione viene richiamata quando cambia la regione selezionata
   * @param newRegionSelected: nuova regione selezionata
   *
   */
  const selectRegionFunction = (newRegionSelected) => {
    // pulisco i gruppi selezionati
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, newRegionSelected, areaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        newRegionSelected,
        areaSelected
      );

      let result = selectPatientsInjury(
        selectedPatients,
        newRegionSelected,
        areaSelected
      );
      result.dataChart = result.dataChart.concat(resultInjuryGroups.dataChart);
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }

    setRegionSelected(newRegionSelected);
    let tempArea = [];
    newRegionSelected.forEach((region) => {
      const tempAreas = allAreas.filter(
        (area) => parseInt(area?.id_region) === parseInt(region?.id)
      );
      tempArea = tempArea.concat(tempAreas);
    });
    setAllAreasAvailable(tempArea);
  };

  /**
   * Funzione viene richiamata quando cambia l'area selezionata
   * @param newAreaSelected: nuova area selezionata
   *
   */
  const selectAreaFuncion = (newAreaSelected) => {
    setAreaSelected(newAreaSelected);
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, newAreaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        regionSelected,
        newAreaSelected
      );
      let result = selectPatientsInjury(
        selectedPatients,
        regionSelected,
        newAreaSelected
      );
      result.dataChart = result.dataChart.concat(resultInjuryGroups.dataChart);
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }
  };

  /**
   * Funzione che mostra tutti gli infortuni divisi per gruppo
   *
   * @param tempInjuryGroup: lista di gruppi
   * @param regions: regioni selezionate
   * @param areas: aree selezionate
   *
   */
  const setAllInjuryGroup = (tempInjuryGroup, regions, areas) => {
    let result = injuryLocalizationGroups(tempInjuryGroup, regions, areas);

    setDataChartLocalization(result.dataChart);
    setBarCharLocalization(result.barChart);
  };

  const actionAfterGroupSelection = (tempGroups) => {
    selectionActionFunction([], "group", tempGroups);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   *
   * @param newMechanism: mechanism selected
   * @param newGravity: gravities selected
   * @param newReinjury: reinjury [yes or not]
   * @param newTissue: tissue type selected
   */

  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups,
    newMechanism = mechanism,
    newGravity = gravities,
    newReinjury = reinjuries,
    newTissue = tissueTypes
  ) => {
    setLoading(true);
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );

    const resultInjuryGroupsTissue = injuryGroupsByTissueTypes(
      newSelectedGroups,
      newTissue,
      true
    );
    const resultTissue = selectPatientsTissueTypeInjury(
      newPatientsSelected,
      newTissue
    );

    const resultInjuryGroupsMechanism = injuryGroupsByMechanism(
      newSelectedGroups,
      newMechanism,
      true
    );
    const resultMechanism = injuryPatientsByMechanis(
      newPatientsSelected,
      newMechanism
    );

    const resultInjuryGroupsGravity = injuryGroupsByGravity(
      newSelectedGroups,
      newGravity,
      true
    );
    const resultGravity = injuryPatientsByGravity(
      newPatientsSelected,
      newGravity
    );

    const resultInjuryGroups = selectedGroupsInjury(
      newSelectedGroups,
      regionSelected,
      areaSelected
    );
    const result = selectPatientsInjury(
      newPatientsSelected,
      regionSelected,
      areaSelected
    );

    const resultInjuryGroupsReinjury = injuryGroupsByReinjury(
      newSelectedGroups,
      newReinjury,
      true
    );
    const resultReinjury = injuryPatientsByReinjury(
      newPatientsSelected,
      newReinjury
    );

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    result.dataChart =
      resultInjuryGroups.dataChart !== undefined
        ? result.dataChart.concat(resultInjuryGroups.dataChart)
        : result.dataChart;

    resultTissue.dataChart =
      resultInjuryGroupsTissue.dataChart !== undefined
        ? resultTissue.dataChart.concat(resultInjuryGroupsTissue.dataChart)
        : resultTissue.dataChart;

    resultMechanism.dataChart =
      resultInjuryGroupsMechanism.dataChart !== undefined
        ? resultMechanism.dataChart.concat(
            resultInjuryGroupsMechanism.dataChart
          )
        : resultMechanism.dataChart;

    resultGravity.dataChart =
      resultInjuryGroupsGravity.dataChart !== undefined
        ? resultGravity.dataChart.concat(resultInjuryGroupsGravity.dataChart)
        : resultGravity.dataChart;

    resultReinjury.dataChart =
      resultInjuryGroupsReinjury.dataChart !== undefined
        ? resultReinjury.dataChart.concat(resultInjuryGroupsReinjury.dataChart)
        : resultReinjury.dataChart;

    setDataChartLocalization(result.dataChart);
    setBarCharLocalization(result.barChart);

    setDataChartTissue(resultTissue.dataChart);
    setBarCharTissue(resultTissue.barChart);

    setDataCharMechanism(resultMechanism.dataChart);
    setBarCharMechanism(resultMechanism.barChart);

    setDataCharGravity(resultGravity.dataChart);
    setBarCharGravity(resultGravity.barChart);

    setDataCharReinjury(resultReinjury.dataChart);
    setBarCharReinjury(resultReinjury.barChart);
    setSelectedPatients(newPatientsSelected);
    if (newPatientsSelected.length === 0 && newSelectedGroups.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, areaSelected);
    }
    setLoading(false);
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      setDateRange(newDateRange);
      if (patId) {
        let [tempInjuryGroup] = await Promise.all([
          getPatientInjury(groups, newDateRange),
        ]);
        setInjuryGroup(tempInjuryGroup);
        setSelectedGroups([]);
        let tempPat = [patients.find(({ id }) => id === parseInt(patId))];
        selectionActionFunction(tempPat, "group", groups);
      } else {
        let [tempInjuryGroup] = await Promise.all([
          getPatientInjury(selectedGroups, newDateRange),
        ]);
        setInjuryGroup(tempInjuryGroup);
        selectionActionFunction([], "group", selectedGroups);
      }
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>
      <Grid item container xs={8}>
        {/* selezione dei pazienti */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}

        {groupId &&
          getGroupsSelection(groups, selectedGroups, actionAfterGroupSelection)}
      </Grid>

      {/* autocomplete per selezionare la regione */}
      {getAutocomplete(
        labels.analytics.injuryReport.selectRegion,
        regionSelected,
        allRegions,
        selectRegionFunction
      )}

      {/* autocomplete per selezionare l'area */}
      {regionSelected.length > 0 &&
        getAutocomplete(
          labels.analytics.injuryReport.selectArea,
          areaSelected,
          allAreasAvailable,
          selectAreaFuncion
        )}

      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={fullSizeRegion ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.patient.graphReport.section.feedback.filters.injuryRegion}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeRegion(!fullSizeRegion);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeRegion ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartLocalization}
            barChar={barCharLocalization}
          />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeTissue ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.patient.graphReport.section.feedback.filters.injuryTissue}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeTissue(!fullSizeTissue);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeTissue ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartTissue} barChar={barCharTissue} />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeMechanism ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .injuryMechanism
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMechanism(!fullSizeMechanism);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMechanism ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartMechanism}
            barChar={barCharMechanism}
          />
        </Grid>

        <Grid
          container
          item
          xs={fullSizeGravity ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .injuryGravity
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeGravity(!fullSizeGravity);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeGravity ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartGravity}
            barChar={barCharGravity}
          />
        </Grid>

        <Grid
          container
          item
          xs={fullSizeReinjury ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .injuryReinjury
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeReinjury(!fullSizeReinjury);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeReinjury ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartReinjury}
            barChar={barCharReinjury}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
