import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import { styled } from "../../../../components/styled";
import { labels } from "../../../shared/translations";
import { theme } from "../../../../components/theme";
import ExerciseSheetRows from "./ExerciseSheetRows";
import SpLoader from "../../../../components/atoms/SpLoader";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import SpButton from "../../../../components/atoms/SpButton";
import SpDialog from "../../../../components/atoms/SpDialog";
import { Controller, useForm } from "react-hook-form";
import ExerciseDetails from "./ExerciseDetails";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddExercise = (props) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([{}]);
  const [exerciseDetails, setExerciseDetails] = useState(false);
  const [circuits, setCircuits] = useState([]);
  const [circuitsCreated, setCircuitsCreated] = useState(1);
  const [circuitDialod, setCircuitDialog] = useState(false);
  const [schedaEsercizi, setSchedaEsercizi] = useState({});
  const [exerciseList, setExerciseLists] = useState([]);
  const [countIndex, setCountIndex] = useState(0);

  const history = useHistory();

  const { control, getValues, setValue } = useForm({
    name: "category",
    shouldUnregister: false,
  });

  useEffect(() => {
    const uniqueExercises = [];
    const exerciseIds = new Set();

    if (props?.exercise) {
      props?.exercise.details.forEach((group) => {
        group.exercises.forEach((exercise) => {
          if (!exerciseIds.has(exercise.id_excercise)) {
            exerciseIds.add(exercise.id_excercise);
            uniqueExercises.push(exercise);
          }
        });
      });
      const temp = props?.exercise?.details.map((item, index) => {
        return { ...item };
      });
      const tempScheda = props?.exercise?.details.reduce((acc, item, index) => {
        acc[index + 1] = item.exercises;
        return acc;
      }, {});

      setRows(uniqueExercises);
      setSchedaEsercizi(tempScheda);
      setExerciseLists(temp);
      setValue("name", props?.exercise?.name);
    }
  }, []);

  const deleteCircuit = (index) => {
    const tempCir = JSON.parse(JSON.stringify(rows));
    delete tempCir[index].circuit;
    delete tempCir[index].sessionCircuit;
    setRows(tempCir);
  };

  return (
    <>
      {!exerciseDetails && (
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          {loading && <SpLoader />}
          {React.Children.map(props.children, (child) =>
            React.cloneElement(child, {
              setLoading: setLoading,
              componentName: props.componentName,
            })
          )}

          {!props?.exercise && (
            <Grid item xs={12} container alignItems={"center"}>
              <Grid item xs={8}>
                <TitleToolbarSection>
                  <ArrowBack
                    onClick={() => history.push("/mylab")}
                    style={{
                      width: 30,
                      color: theme.colors.primary.lightBlue,
                      height: 50,
                      cursor: "pointer",
                    }}
                  />
                  <SpText variant="h1">
                    {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                    <span style={{ color: "#fff" }}>
                      {labels.mylab.exercise.titleAdd}
                    </span>
                  </SpText>
                </TitleToolbarSection>
              </Grid>
            </Grid>
          )}

          <SpText variant="h1">{labels.mylab.exercise.addStep1}</SpText>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border: `1px solid ${theme.colors.primary.lightBlue}`,
              padding: "1em",
            }}
          >
            <div>
              <SpText variant="listHeader">
                {labels.mylab.exercise.numExe}
              </SpText>
              <SpTextInput
                type="number"
                minValue={1}
                style={{ width: "100%" }}
                onChange={(evnt) => {
                  const value = parseInt(evnt.target.value, 10);
                  setRows((prevRows) => {
                    if (value > prevRows.length) {
                      return [
                        ...prevRows,
                        ...Array(value - prevRows.length).fill({}),
                      ];
                    } else {
                      return prevRows.slice(0, value);
                    }
                  });
                }}
                value={rows.length}
              />
            </div>
            <div style={{ width: "50%", paddingTop: "1.8em" }}>
              <Controller
                render={(props) => (
                  <SpTextInput
                    type="text"
                    displayLabel={false}
                    formControlStyle={{ width: "100%" }}
                    placeholder={labels.mylab.exercise.templateName}
                    value={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  />
                )}
                defaultValue={""}
                name={`name`}
                control={control}
              />
            </div>
            <div>
              <SpButton
                style={{
                  marginTop: "2em",
                }}
                buttonType={"accept"}
                text={labels.mylab.exercise.next}
                onClick={() => {
                  setExerciseDetails(true);
                }}
              />
            </div>
          </div>

          <ExerciseSheetRows
            deleteCircuit={deleteCircuit}
            isPrint={false}
            rows={rows}
            setCircuits={setCircuits}
            circuits={circuits}
            updateRow={(idx, key, value) => {
              setRows((prevRows) => {
                const newRows = [...prevRows];
                newRows[idx] = { ...newRows[idx], [key]: value };
                return newRows;
              });
            }}
          />

          {circuits.length > 0 && (
            <Grid item xs={12}>
              <SpButton
                buttonType={"accept"}
                text={"crea circuito"}
                onClick={() => {
                  setCircuitDialog(true);
                }}
                style={{
                  position: "fixed",
                  bottom: "10px",
                  right: "10px",
                  width: "100%",
                  zIndex: 1000,
                }}
              />
            </Grid>
          )}

          <SpDialog
            open={circuitDialod}
            setOpen={setCircuitDialog}
            title={labels.patient.presentation.add.stepper.step2.redFlagsTitle}
            style={{ marginRight: "60%" }}
          >
            <Grid
              style={{ paddingLeft: "1%" }}
              direction="column"
              container
              spacing={2}
            >
              <SpText variant="listHeader">
                {labels.mylab.exercise.numRepCir}
              </SpText>
              <Controller
                render={(props) => (
                  <SpTextInput
                    type="number"
                    minValue={0}
                    style={{ width: "100%" }}
                    onChange={(evnt) => props.onChange(evnt.target.value)}
                    value={props.value}
                  />
                )}
                defaultValue={0}
                name={`circuit`}
                control={control}
              />

              <SpButton
                buttonType={"accept"}
                text={labels.mylab.exercise.enableCircuit}
                onClick={() => {
                  circuits.map((idx) => {
                    setRows((prevRows) => {
                      const newRows = [...prevRows];
                      newRows[idx] = {
                        ...newRows[idx],
                        circuit: circuitsCreated,
                        sessionCircuit: getValues("circuit"),
                      };
                      return newRows;
                    });
                  });
                  setCircuitsCreated(parseInt(circuitsCreated) + 1);
                  setCircuits([]);
                  setCircuitDialog(false);
                }}
              />
            </Grid>
          </SpDialog>
        </Grid>
      )}
      {/* ---------------------
            EXERCISE DETAILS
          ---------------------
       */}
      {exerciseDetails && (
        <ExerciseDetails
          {...props}
          rows={rows}
          setRows={setRows}
          setExerciseDetails={setExerciseDetails}
          schedaEsercizi={schedaEsercizi}
          setSchedaEsercizi={setSchedaEsercizi}
          exerciseList={exerciseList}
          setExerciseLists={setExerciseLists}
          countIndex={countIndex}
          setCountIndex={setCountIndex}
          getValues={getValues}
          idTemplate={props?.exercise?.id}
        />
      )}
    </>
  );
};
export default withSnackbar(AddExercise);
