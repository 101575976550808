import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { getProfessionalByEmail } from "../../../models/actions/Professionals";
import { rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../translations";

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});

const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});

const ProfessionalShareDialog = ({
  openProfessional,
  setOpenAddProfessional,
  addCallback,
  selectValues,
  ...props
}) => {
  const [searchString, setSearchString] = useState("");
  const [usersFound, setUsersFound] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const searchProfessionals = () => {
    if (searchString) {
      try {
        getProfessionalByEmail({ email: searchString }).then((response) => {
          if (response.length > 0) {
            setUsersFound(response);
          } else {
            setUsersFound([]);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("SearchProfessionals", error);
      }
    }
  };

  const resetStateDialog = () => {
    setUsersFound([]);
    setSearchString("");
    setSelectedRole(null);
  };

  return (
    <SpDialog
      style={{ padding: "1%" }}
      open={openProfessional}
      setOpen={setOpenAddProfessional}
      onCloseFunctions={resetStateDialog}
      title={labels.groups.groupDetail.searchProfessional}
    >
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          xs={12}
          style={{ alignItems: "self-end", marginBottom: "10px" }}
        >
          {selectValues && (
            <SpSelect
              label={labels.groups.groupDetail.assignRole}
              selectPlaceholder={labels.groups.groupDetail.chooseRole}
              value={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.target.value);
              }}
            >
              {selectValues.map((p) => (
                <SpSelectMenuItem key={p.id} value={p.id}>
                  {psTranslate(p.name)}
                </SpSelectMenuItem>
              ))}
            </SpSelect>
          )}
          <SpTextInput
            value={searchString}
            label={
              labels.groups.groupAdd.addProfessionalTable.dialog.searchText
            }
            style={{ marginLeft: "10px" }}
            onChange={(e) => setSearchString(e.currentTarget.value)}
          />
          <SpButton
            text={labels.patient.patientLink.inputSearch.buttons.search}
            style={{ marginLeft: "10px" }}
            variant="none"
            buttonType="accept"
            onClick={() => searchProfessionals()}
          />
        </Grid>
        <StyledWrapperPatientsList
          item
          container
          direction="column"
          xs={12}
          style={{ marginBottom: "2%" }}
        >
          {usersFound ? (
            usersFound.map((professional) => (
              <StyledRowPatientFound
                key={professional.id}
                item
                container
                direction={"row"}
                xs={12}
              >
                <Grid item xs={6} alignSelf={"center"}>
                  <SpText variant="text">{`${professional.name} ${professional.surname} - ${professional.email}`}</SpText>
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    text={
                      labels.groups.groupAdd.addProfessionalTable.dialog
                        .addProfessionalButton
                    }
                    buttonType="accept"
                    variant="none"
                    style={{ width: "100%" }}
                    onClick={() => addCallback(professional, selectedRole)}
                  />
                </Grid>
              </StyledRowPatientFound>
            ))
          ) : (
            <SpText variant={"text"}>
              {
                labels.groups.groupAdd.addProfessionalTable.dialog
                  .usersFoundPlaceholder
              }
            </SpText>
          )}
        </StyledWrapperPatientsList>
      </Grid>
    </SpDialog>
  );
};

export default withSnackbar(ProfessionalShareDialog);
