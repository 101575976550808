import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import {
  getDisorders,
  getDysfunctionsCategories,
  updateDysfunctionsWeights,
} from "../../../models/actions/Pathologies";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddDysfunctionWeights = (props) => {
  const [dysfunctions, setDysfunctions] = useState([]);
  const [sections, setSections] = useState([]);

  const history = useHistory();

  useEffect(async () => {
    try {
      const [categories, disorders] = await Promise.all([
        getDysfunctionsCategories(),
        getDisorders(),
      ]);

      setSections(disorders);

      const sortedResults = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setDysfunctions(sortedResults);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const saveWeights = async () => {
    try {
      const newWeights = dysfunctions.map((d) => ({
        id_dysfunction_category: d.id,
        weight: d.weight,
      }));
      const result = await updateDysfunctionsWeights({
        new_weights: newWeights,
      });
      props.snackbarShowMessage(result.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        {/*Header*/}
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.dysfunctionWeights.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems={"center"}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              variant="none"
              style={{ marginLeft: "2%" }}
              text={labels.mylab.tempAdd.dysfunctionWeights.actions.edit}
              buttonType="accept"
              onClick={saveWeights}
            />
          </Grid>
        </Grid>

        {/*Dysfunction weights section*/}
        {sections.map((disorder) => (
          <Grid
            key={disorder.id}
            item
            xs={12}
            container
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={12}>
              <SpText variant="h2">{psTranslate(disorder.name)}</SpText>
            </Grid>
            {dysfunctions
              .filter((d) => d.id_disorder == disorder.id)
              .map((cat) => (
                <Grid key={cat.id} item xs={2}>
                  <SpTextInput
                    label={psTranslate(cat.name)}
                    value={cat.weight}
                    onChange={(evnt) => {
                      const value = evnt.target.value;
                      if (value > 0) {
                        const newDysfunctions = [...dysfunctions];
                        const idx = newDysfunctions.findIndex(
                          (el) => el.id == cat.id
                        );
                        newDysfunctions[idx].weight = value;
                        setDysfunctions(newDysfunctions);
                      }
                    }}
                    type="number"
                  />
                </Grid>
              ))}
            <Divider
              style={{
                padding: "1px",
                width: "100%",
                backgroundColor: theme.colors.primary.lightBlue,
                marginTop: "1%",
                marginBottom: "1%",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default withSnackbar(AddDysfunctionWeights);
