import { Box, Grid } from "@material-ui/core";
import React from "react";
import { psTranslate } from "../../../pages/shared/translations";
import SpText from "../../atoms/SpText";
import SpTextInput from "../../atoms/SpTextInput";
import { theme } from "../../theme";

const FeedbackElement = ({
  feedbacks,
  event,
  setValue,
  register,
  isPrint = false,
}) => {
  const feedbacksSorted = feedbacks?.sort((fA, fB) =>
    fA?.feedback_parameter?.name.localeCompare(fB?.feedback_parameter?.name)
  );

  return (
    feedbacksSorted &&
    feedbacksSorted.length > 0 && (
      <Grid item xs={12}>
        <form style={{ marginTop: "5px" }}>
          {feedbacksSorted?.map((feed) => {
            setValue(`feed_resp_ans[${feed.id}].id`, feed.id);
            setValue(`feed_resp_ans[${feed.id}].answer`, feed.answer);
            return (
              <Grid
                key={`pat_feed_${feed.id}`}
                direction="row"
                container
                alignItems="center"
                style={{ marginTop: "5px" }}
              >
                <Grid item xs={12}>
                  {event.archiving_status ===
                    "completed_and_feedback_not_sent" ||
                  event.archiving_status === "completed_and_feedback_sent" ? (
                    <SpText
                      variant={!isPrint ? "h4ComponentLabel" : "textPrint"}
                    >
                      {`${psTranslate(feed?.feedback_parameter?.name)} (in ${
                        feed?.feedback_parameter?.um
                      })`}
                      :{" "}
                      <Box
                        color={theme.colors.primary.lightBlue}
                        style={{ display: "inline-block" }}
                      >
                        {feed.answer}
                      </Box>
                    </SpText>
                  ) : (
                    <SpTextInput
                      id={`inputFeed-${feed?.feedback_parameter?.name
                        .split(" ")
                        .join("")}`}
                      isPrint={isPrint}
                      name={`feed_resp_ans[${feed.id}].answer`}
                      type="number"
                      formControlStyle={{ width: "100%" }}
                      minValue={0}
                      inputRef={register}
                      defaultValue={feed.answer}
                      label={`${psTranslate(
                        feed?.feedback_parameter?.name
                      )} (in ${feed?.feedback_parameter?.um})`}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </form>
      </Grid>
    )
  );
};

export default FeedbackElement;
