import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Divider, Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getPatientPastHistorySumup } from "../../../models/actions/Patients";
import { useParams } from "react-router-dom";
import SpText from "../../../components/atoms/SpText";
import { psTranslate, labels } from "../../shared/translations";
import { theme } from "../../../components/theme";

const PastHistorySumup = (props) => {
  const [pastHistoryData, setPastHistoryData] = useState([]);

  const { patId } = useParams();
  const fetchData = async () => {
    try {
      const resultPastHistory = await getPatientPastHistorySumup({
        id_patient: patId,
      });
      resultPastHistory && setPastHistoryData(resultPastHistory);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "5px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>{props.value}</SpText>
    </Grid>
  );

  const RenderPastHistoryAnswer = () => (
    <>
      {pastHistoryData?.map((item) => (
        <>
          <Grid xs={12} container direction="row">
            <Grid item xs={12} style={{ marginTop: "1%" }}>
              <Divider
                style={{
                  padding: "1px",
                  backgroundColor: theme.colors.primary.lightBlue,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              style={{ marginTop: "1%" }}
            >
              <SpText variant={"h4ComponentLabel"}>
                {psTranslate(item.category?.name)}
              </SpText>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1%" }}>
              <Divider
                style={{
                  padding: "1px",
                  backgroundColor: theme.colors.primary.lightBlue,
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} container style={{ marginTop: "1%" }}>
            {item.questions?.map((question) => (
              <Grid xs={6} direction="column" style={{ marginBottom: "10px" }}>
                <SpText variant={"text"}>
                  {psTranslate(question.question)}
                </SpText>
                {question.answers?.map((answer) => {
                  let notes = (
                    <SpText component="span" color="red">
                      {answer.note ? ` (${psTranslate(answer?.note)})` : ""}
                    </SpText>
                  );
                  return answer.has_additional_info ? (
                    <Grid
                      style={{
                        border: `1px solid ${theme.colors.primary.lightBlue}`,
                        padding: "8px",
                        marginTop: "5px",
                      }}
                    >
                      <SpText variant={"h4ComponentLabelRegular"}>
                        {psTranslate(answer.answer)} {notes}
                      </SpText>
                      {answer.additional_info?.map((additional_info) => (
                        <LabelValue
                          label={psTranslate(additional_info.name)}
                          value={psTranslate(additional_info.value)}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <SpText variant={"h4ComponentLabelRegular"}>
                      {psTranslate(answer.answer)} {notes}
                    </SpText>
                  );
                })}
              </Grid>
            ))}
          </Grid>
        </>
      ))}
    </>
  );

  return (
    <>
      {pastHistoryData?.length === 0 && (
        <Grid direction="column" container spacing={4} xs={12}>
          <SpText variant="h1PageTitle">{labels.general.no_data}</SpText>
        </Grid>
      )}
      {pastHistoryData && pastHistoryData.length > 0 && (
        <Grid direction="column" container spacing={4}>
          <RenderPastHistoryAnswer />
        </Grid>
      )}
    </>
  );
};
export default withSnackbar(PastHistorySumup);
