import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Controller, useForm } from "react-hook-form";
import { updatePayment } from "../models/actions/Common";
import "./udpatePaymentStyle.css";
import SpTextInput from "../components/atoms/SpTextInput";
import { labels, psTranslate } from "./shared/translations";
import { Grid } from "@material-ui/core";
import SpButton from "../components/atoms/SpButton";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpLoader from "../components/atoms/SpLoader";

const UpdatePaymentMethod = ({ emailProfessional, ...props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, control, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: data.name,
        email: data.email,
      },
    });

    if (error) {
      props.snackbarShowErrorMessage(error.message);
      setLoading(false);
      return;
    }

    // Invia il paymentMethod.id al server per aggiornare il metodo di pagamento
    const response = await updatePayment({
      paymentMethodId: paymentMethod.id,
      email: emailProfessional,
    });

    if (response.error) {
      props.snackbarShowErrorMessage(response.error);
    } else {
      props.snackbarShowMessage(response.message);
    }
    reset();
    setLoading(false);
  };
  const constPadding = { padding: "8px" };
  const cardStyle = {
    style: {
      base: {
        color: "#fff",
        backgroundColor: "#333",
        iconColor: "#fff",
        "::placeholder": {
          color: "#bfbfbf",
        },
      },
      invalid: {
        color: "#ff6b6b",
      },
    },
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props?.componentName,
        })
      )}
      <Grid
        item
        container
        xs={12}
        direction="row"
        style={{ paddingLeft: "10em", paddingRight: "10em", paddingTop: "2em" }}
      >
        <Grid item xs={3} style={constPadding}>
          <Controller
            control={control}
            name={"name"}
            id="name"
            render={(props) => (
              <>
                <SpTextInput
                  inputRef={register}
                  style={{ width: "100%" }}
                  label={psTranslate(
                    labels.account.accountForm.givenName.toUpperCase()
                  )}
                  value={props.value}
                  onChange={(event) => {
                    props.onChange(event.target.value);
                  }}
                />
                {props.value === "" && (
                  <span style={{ color: "red" }}>
                    {labels.mylab.tempDetail.riskFactor.title}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={3} style={constPadding}>
          <Controller
            control={control}
            name={"email"}
            id="email"
            render={(props) => (
              <>
                <SpTextInput
                  inputRef={register}
                  style={{ width: "100%" }}
                  label={psTranslate(
                    psTranslate(labels.account.accountForm.email.toUpperCase())
                  )}
                  value={props.value}
                  onChange={(event) => {
                    props.onChange(event.target.value);
                  }}
                />
                {props.value === "" && (
                  <span style={{ color: "red" }}>
                    {labels.mylab.tempDetail.riskFactor.title}
                  </span>
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={3} style={constPadding}>
          <label htmlFor="card">Card Details</label>
          <CardElement id="card" options={cardStyle} />
        </Grid>
        <Grid item xs={12} style={constPadding}>
          <SpButton
            buttonType={"accept"}
            variant="none"
            text={labels.general.update}
            style={{ marginTop: "4%" }}
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default withSnackbar(UpdatePaymentMethod);
