import moment from "moment";
import React from "react";
import { Bar, Label, LabelList } from "recharts";
import {
  barColors,
  barWithDimension,
  titleCase,
} from "../../../../utils/common";
import { labels } from "../../../shared/translations";

const injuryRateLabel = labels.analytics.injuryReport.graphs.injuryRate;
const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const trainingInjuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;
const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="16" fill="#FFFFFF" fontWeight="Bold" />;
};

const createBarschartDataPoints = (labelKey, size, color) => {
  return (
    <Bar barSize={size} dataKey={labelKey} fill={color} stackId="a">
      <LabelList
        dataKey={labelKey}
        position="center"
        content={renderCustomizedLabel}
      />
    </Bar>
  );
};

const getDays = (start_date, end_date) => {
  return end_date
    ? moment(end_date).diff(moment(start_date), "days")
    : moment().diff(moment(start_date), "days");
};

export const groupsByDaysAbsence = (groups, type) => {
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();

  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2
  let id_type;
  if (type) id_type = type === "training" ? 1 : 2;
  groups.forEach((group) => {
    let item = {
      [daysAbsenceLabel]: 0,
      groupDate: titleCase(group?.name),
    };
    group.patients.forEach(({ injuries }) => {
      injuries.forEach((injury) => {
        // l'attivita' svolta deve essere training
        if (!type || injury?.activity_during_injury === id_type) {
          if (injury?.end_date) {
            item[daysAbsenceLabel] += getDays(
              injury?.start_date,
              injury?.end_date
            );
          } else if (injury?.estimated_end_date) {
            item[daysAbsenceLabel] += getDays(
              injury?.start_date,
              injury?.estimated_end_date
            );
          } else {
            item[daysAbsenceLabel] += getDays(injury?.start_date, moment());
          }
        }
      });
    });
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      daysAbsenceLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

const getTrainingMinutes = (group) => {
  let minutes = 0;
  group["action"].forEach(({ patients }) => {
    patients.forEach(({ patientFeedbacks }) => {
      patientFeedbacks.forEach(({ answer, feedback_parameter }) => {
        // aggiungo il minutaggiso al totale solo se e' maggiore di 0
        if (feedback_parameter?.name === "Durata" && answer > 0)
          minutes += parseInt(answer);
      });
    });
  });
  return minutes;
};

export const groupsByInjuryBurden = (groups, type) => {
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();
  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2
  let id_type;
  if (type) id_type = type === "training" ? 1 : 2;
  groups.forEach((group) => {
    let item = {
      hours: 0,
      groupDate: titleCase(group?.name),
      days: 0,
      [trainingInjuriesBurdenLabel]: 0,
    };
    // set del numero di ore per allenamento
    item.hours = getTrainingMinutes(group) / 60;
    // set del numero di giorni d'assenza
    group.patients.forEach(({ injuries }) => {
      injuries.forEach((injury) => {
        if (!type || injury?.activity_during_injury === id_type) {
          if (injury?.end_date) {
            item.days += getDays(injury?.start_date, injury?.end_date);
          } else if (injury?.estimated_end_date) {
            item.days += getDays(
              injury?.start_date,
              injury?.estimated_end_date
            );
          } else {
            item.days += getDays(injury?.start_date, moment());
          }
        }
      });
    });
    item[trainingInjuriesBurdenLabel] =
      item.hours === 0
        ? 1000 * item.days
        : ((1000 * item.days) / item.hours).toFixed(1);
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      trainingInjuriesBurdenLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const groupsByInjuryRate = (groups, type) => {
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();
  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2
  const id_type = type === "training" ? 1 : 2;
  groups.forEach((group) => {
    let item = {
      hours: 0,
      groupDate: titleCase(group?.name),
      injuries: 0,
    };
    // set del numero di ore per allenamento
    const minutes = getTrainingMinutes(group);
    item.hours = minutes ? minutes / 60 : 0;
    // set del numero di infortuni per ogni utente di ogni
    group?.patients.forEach(({ injuries }) => {
      item.injuries += injuries.filter(
        ({ activity_during_injury }) => activity_during_injury === id_type
      ).length;
    });
    item[injuryRateLabel] =
      item.hours === 0 ? 0 : ((1000 * item.injuries) / item.hours).toFixed(1);
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

const groupIterAction = (newSelectedGroups, type, actionGroup) => {
  let res = {};
  for (let gruppo of newSelectedGroups) {
    let temp = actionGroup([gruppo], type);
    res["barChart"] = temp["barChart"];
    res["dataChart"] =
      res["dataChart"] === undefined
        ? temp["dataChart"]
        : res["dataChart"].concat(temp["dataChart"]);
  }
  return res;
};

export const selectedGroupsDaysAbsence = (newSelectedGroups, type) => {
  return groupIterAction(newSelectedGroups, type, groupsByDaysAbsence);
};

export const selectedGroupsBurder = (newSelectedGroups, type) => {
  return groupIterAction(newSelectedGroups, type, groupsByInjuryBurden);
};

export const selectedGroupsInjuryRate = (newSelectedGroups, type) => {
  return groupIterAction(newSelectedGroups, type, groupsByInjuryRate);
};

export const selectPatientsDaysAbsence = (selectedPatients, type) => {
  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2
  let id_type;
  if (type) id_type = type === "training" ? 1 : 2;
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();

  selectedPatients.forEach(({ givenName, familyName, injuries }) => {
    let item = {
      minutes: 0,
      groupDate: titleCase(`${givenName} ${familyName}`),
      [daysAbsenceLabel]: 0,
    };

    injuries?.forEach((injury) => {
      if (!type || injury.activity_during_injury === id_type) {
        if (injury?.end_date) {
          item[daysAbsenceLabel] += getDays(
            injury?.start_date,
            injury?.end_date
          );
        } else if (injury?.estimated_end_date) {
          item[daysAbsenceLabel] += getDays(
            injury?.start_date,
            injury?.estimated_end_date
          );
        } else {
          item[daysAbsenceLabel] += getDays(injury?.start_date, moment());
        }
      }
    });
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      daysAbsenceLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const getTrainingMinutesFromGroups = (groups, selectedPatient) => {
  let minutes = 0;
  groups.forEach((group) => {
    if (group.action) {
      group.action.forEach(({ patients }) => {
        patients.forEach(({ patientName, patientFeedbacks }) => {
          if (
            patientName.includes(selectedPatient?.familyName) &&
            patientName.includes(selectedPatient?.givenName)
          ) {
            patientFeedbacks.forEach(({ answer, feedback_parameter }) => {
              // aggiungo il minutaggiso al totale solo se e' maggiore di 0
              if (feedback_parameter?.name === "Durata" && answer > 0) {
                minutes += parseInt(answer);
              }
            });
          }
        });
      });
    }
  });
  return minutes;
};

export const selectPatientsInjuryBurden = (selectedPatients, groups, type) => {
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();
  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2
  let id_type;
  if (type) id_type = type === "training" ? 1 : 2;
  selectedPatients.forEach((selectedPatient) => {
    let item = {
      hours: 0,
      groupDate: titleCase(
        `${selectedPatient?.givenName} ${selectedPatient?.familyName}`
      ),
      days: 0,
      [trainingInjuriesBurdenLabel]: 0,
    };
    selectedPatient?.injuries?.forEach((injury) => {
      if (!type || injury?.activity_during_injury === id_type) {
        if (injury?.end_date) {
          item.days += moment(injury?.end_date).diff(
            moment(injury?.start_date),
            "days"
          );
        } else if (injury?.estimated_end_date) {
          item.days += moment(injury?.estimated_end_date).diff(
            moment(injury?.start_date),
            "days"
          );
        } else {
          item.days += moment().diff(moment(injury?.start_date), "days");
        }
      }
    });
    item.hours = getTrainingMinutesFromGroups(groups, selectedPatient) / 60;
    item[trainingInjuriesBurdenLabel] =
      item.hours === 0
        ? 1000 * item.days
        : ((1000 * item.days) / item.hours).toFixed(1);
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      trainingInjuriesBurdenLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const selectPatientsInjuryRate = (selectedPatients, groups, type) => {
  let bars = [];
  let resultArray = [];
  const barColorValueTemp = barColors();
  // type training -> activity during injury = training id 1
  // type match -> activity during injury = match id 2

  const id_type = type === "training" ? 1 : 2;
  selectedPatients.forEach((selectedPatient) => {
    const injuries = selectedPatient?.injuries?.filter(
      ({ activity_during_injury }) => activity_during_injury === id_type
    ).length;
    let item = {
      hours: 0,
      groupDate: titleCase(
        `${selectedPatient?.givenName} ${selectedPatient?.familyName}`
      ),
      injuries: injuries ? injuries : 0,
      [injuryRateLabel]: 0,
    };
    const minutes = getTrainingMinutesFromGroups(groups, selectedPatient);
    item.hours = minutes ? minutes / 60 : 0;

    item[injuryRateLabel] =
      item.hours === 0
        ? (1000 * item.injuries).toFixed(1)
        : ((1000 * item.injuries) / item.hours).toFixed(1);
    resultArray.push(item);
  });
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};
