import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getCombinedParametersFor,
  getGroupsSameGroupOf,
  getPatientsNames,
  getPatientsNamesSameGroupOf,
  getStudies,
} from "../../../models/actions/Patients";
import { rollbar } from "../../../utils/common";
import { labels } from "../../shared/translations";
import Report from "./patientsReportFeedback/Report";

const UNLINKED_PATIENTS_LABEL =
  labels.patient.graphReport.section.feedback.filters.unlinked_patients;
const LINKED_PATIENTS_LABEL =
  labels.patient.graphReport.section.feedback.filters.linked_patients;
const GROUPS_LABEL =
  labels.patient.graphReport.section.feedback.filters.patients_group;

const TemplatePatientsReports = ({
  elements,
  excludedStudies,
  getStudyParams,
  getStudyParamsInRange,

  fixProxyParameters,
  fixProxyParamsListAvailable,
  fixForProxyParams,
  fixForProxyParamsResults,

  fetchPatientsData,
  fetchGroupData,

  enableCombinedParameters,
  defaultZero,

  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [studies, setStudies] = useState([]);
  const [mainPatientKey, setMainPatientKey] = useState("");
  const [studyParameters, setStudyParameters] = useState([]);
  const [activeStudyParameters, setActiveStudyParameters] = useState([]);

  const [combinedParams, setCombinedParams] = useState([]);

  const [additionalStudySubjects, setAdditionalStudySubjects] = useState([]);

  const [fetchedData, setFetchedData] = useState([]);

  let { patId, groupId } = useParams();
  patId = patId ? parseInt(patId) : patId;
  groupId = groupId ? parseInt(groupId) : groupId;

  //Effects
  useEffect(async () => {
    try {
      //Fetch data
      let [newStudies, studyParams, unlinkedPatients, linkedPatients, groups] =
        await Promise.all([
          getStudies(),
          getStudyParams(),
          getPatientsNames(),
          getPatientsNamesSameGroupOf({ id_patient: patId, id_group: groupId }),
          getGroupsSameGroupOf({ id_patient: patId }),
        ]);
      if (fixProxyParameters) studyParams = fixProxyParameters(studyParams);
      //Housekeeping
      const newMainPatientKey = patId
        ? unlinkedPatients.find((pat) => pat.id === patId).name ?? ""
        : `${labels.analytics.average} ${
            groups.find((group) => group.id === groupId).name ?? ""
          }`;
      unlinkedPatients = unlinkedPatients.filter((pat) => pat.id !== patId);
      groups = groups.filter((group) => group.id !== groupId);
      linkedPatients.forEach((pat) => {
        pat.groupByKey = pat.group.name;
      });
      newStudies = newStudies.filter(
        (study) => !excludedStudies.includes(study.key)
      );
      //Set params
      setStudies(newStudies);
      setMainPatientKey(newMainPatientKey);
      setStudyParameters(studyParams);
      setActiveStudyParameters([]);
      setAdditionalStudySubjects({
        [UNLINKED_PATIENTS_LABEL]: unlinkedPatients,
        [LINKED_PATIENTS_LABEL]: linkedPatients,
        [GROUPS_LABEL]: groups,
      });
    } catch (error) {
      rollbar.error("PatientsReportsTemplate - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  //Requested new data fetch
  const onNewDataRequest = async (
    currElements,
    currStudyParameters,
    currAdditionalStudySubjects,
    fetchDateRange
  ) => {
    try {
      //Filter available study params
      const studyParamIds = await getStudyParamsInRange(
        currElements,
        fetchDateRange,
        patId,
        groupId
      );
      const newActiveParams = studyParamIds
        ? studyParameters.filter((param) => studyParamIds.includes(param.id))
        : studyParameters;
      if (fixProxyParamsListAvailable)
        fixProxyParamsListAvailable(newActiveParams);
      if (
        newActiveParams.length !== activeStudyParameters.length ||
        newActiveParams.some(
          (newParam) =>
            !activeStudyParameters.find(
              (activeParam) => activeParam.id === newParam.id
            )
        )
      ) {
        setActiveStudyParameters(newActiveParams);
      }

      //Fetch new data
      if (currStudyParameters.length > 0) {
        const currStudyParameterIdsClean = currStudyParameters.map(
          (param) => param.id
        );
        const currStudyParameterIds = fixForProxyParams
          ? fixForProxyParams(currStudyParameterIdsClean)
          : currStudyParameterIdsClean;

        //Get single patients data
        let patientIds = new Set();
        if (patId) patientIds.add(patId);
        currAdditionalStudySubjects[UNLINKED_PATIENTS_LABEL]?.forEach((subj) =>
          patientIds.add(subj.id)
        );
        currAdditionalStudySubjects[LINKED_PATIENTS_LABEL]?.forEach((subj) =>
          patientIds.add(subj.id)
        );
        patientIds = [...patientIds];
        const newFetchedDataPromise =
          patientIds.length > 0
            ? fetchPatientsData(
                currElements,
                fetchDateRange,
                currStudyParameterIds,
                patientIds
              )
            : Promise.resolve({});

        //Get groups data
        let groups = currAdditionalStudySubjects[GROUPS_LABEL]?.map(
          (group) => group.id
        );
        groups = groups ?? [];
        if (groupId) groups.push(groupId);
        const newGroupDataPromise =
          groups.length > 0
            ? fetchGroupData(
                currElements,
                fetchDateRange,
                currStudyParameterIds,
                groups
              )
            : Promise.resolve({});

        //Fetch data
        let [newFetchedData, newGroupData] = await Promise.all([
          newFetchedDataPromise,
          newGroupDataPromise,
        ]);
        //Add average label to groups
        newGroupData = Object.entries(newGroupData).reduce(
          (accum, [key, value]) => {
            accum[`${labels.analytics.average} ${key}`] = value;
            return accum;
          },
          {}
        );
        const results = { ...newFetchedData, ...newGroupData };
        setFetchedData(results);
      } else setFetchedData({});
    } catch (error) {
      rollbar.error("PatientsReportsStep1Feedback - onNewDataRequest", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  //Requested available combined parameters with given ones
  const onCombinedParamRequest = async (currStudyParameters) => {
    try {
      //Query compatible parameters
      let combinedParameters = await getCombinedParametersFor({
        parameters: currStudyParameters.map((elem) => elem.id),
      });
      combinedParameters = new Set(combinedParameters);

      //Add currently selected parameters
      currStudyParameters.forEach((param) => combinedParameters.add(param.id));

      //Update params
      setCombinedParams([...combinedParameters]);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <Grid container item xs={12}>
      <Report
        mainSubjectKey={mainPatientKey}
        studies={studies}
        studyParameters={activeStudyParameters}
        fetchedData={fetchedData}
        activities={elements}
        additionalStudySubjects={additionalStudySubjects}
        combinedParams={enableCombinedParameters ? combinedParams : null}
        onCombinedParamRequest={
          enableCombinedParameters ? onCombinedParamRequest : () => {}
        }
        onNewDataRequest={onNewDataRequest}
        defaultZero={defaultZero}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
        {...props}
      />
    </Grid>
  );
};

export default withSnackbar(TemplatePatientsReports);
