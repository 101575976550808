import React from "react";
import { labels } from "../../../shared/translations";
import { Grid } from "@material-ui/core";
import SpCheckbox from "../../../../components/atoms/SpCheckbox";

export const ComponetFilterToolbar = ({ setCheckboxState, checkboxState }) => {
  const checks = [
    {
      label: labels.patient.patientAlerts.filterToolbar.all,
      checked: checkboxState.all,
      onChange: () =>
        setCheckboxState({
          all: true,
          unchecked: 0,
          checked: 0,
          modifiable: 0,
          notModifiable: 0,
        }),
    },
    {
      label: labels.patient.patientAlerts.filterToolbar.unchecked,
      checked: checkboxState.unchecked,
      onChange: (event) =>
        setCheckboxState({
          all: event.target.checked ? 0 : 1,
          unchecked: event.target.checked ? 1 : 0,
          checked: 0,
          modifiable: 0,
          notModifiable: 0,
        }),
    },
    {
      label: labels.patient.patientAlerts.filterToolbar.checked,
      checked: checkboxState.checked,
      onChange: (event) =>
        setCheckboxState({
          all: event.target.checked ? 0 : 1,
          unchecked: 0,
          checked: event.target.checked ? 1 : 0,
          modifiable: 0,
          notModifiable: 0,
        }),
    },
    {
      label: labels.patient.patientAlerts.table.header.modifiable,
      checked: checkboxState.modifiable,
      onChange: (event) =>
        setCheckboxState({
          all: event.target.checked ? 0 : 1,
          unchecked: 0,
          checked: 0,
          modifiable: event.target.checked ? 1 : 0,
          notModifiable: 0,
        }),
    },
    {
      label: labels.patient.patientAlerts.table.header.notModifiable,
      checked: checkboxState.notModifiable,
      onChange: (event) =>
        setCheckboxState({
          all: event.target.checked ? 0 : 1,
          unchecked: 0,
          checked: 0,
          modifiable: 0,
          notModifiable: event.target.checked ? 1 : 0,
        }),
    },
  ];

  return (
    <Grid xs={12} container>
      <Grid item xs={12} style={{ marginTop: "1%" }}>
        <Grid item xs={12} container>
          {checks.map(({ label, onChange, checked }) => (
            <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
              <SpCheckbox
                id={"filterAll"}
                label={label}
                rightlabel={true}
                formControlStyle={{
                  justifyContent: "flex-start",
                  marginLeft: "5%",
                  marginTop: "5%",
                }}
                checked={checked}
                onChange={onChange}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
