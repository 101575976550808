import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getActivityFeedbackParameters,
  getAllActivitiesTypes,
} from "../../../models/actions/Activity";
import { getSecondTableGroupsData } from "../../../models/actions/Groups";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import { Grid } from "@material-ui/core";
import { theme } from "../../../components/theme";
import {
  fixForProxyParams,
  fixProxyParamsListAvailable,
} from "../../patients/patientsReports/FeedbackDataHelper";
import { getActivityFeedbacksInRange } from "../../../models/actions/CalendarActivity";

const PATIENT_KEY = "patient";

const StatisticsActivities = ({
  eventId,
  dateRange,
  currActivities,
  setCurrActivities,
  activities,
  loadConfiguration,
  setConfig,
  requestSaveFlag,
  currStudyParameters,
  tableData,
  setTableData,
  setActiveStudyParameters,
  setStudyParameters,
  isPatientLabel,
  ...props
}) => {
  const { groupId, patId } = useParams();
  useEffect(async () => {
    try {
      if (Array.isArray(currActivities) && currActivities.length > 0) {
        const studyParams = await getActivityFeedbackParameters();
        setStudyParameters(studyParams);
        //Fetch activity data
        const secondTableData = await getSecondTableGroupsData({
          eventId: eventId,
          id_group: groupId,
          id_patients: patId ? [patId] : null,
          activitiesSelected: currActivities,
          feedbacksSelected: fixForProxyParams(currStudyParameters),
          filterActStartDate: dateRange.start.format(dateFormat),
          filterActEndDate: dateRange.end.format(dateFormat),
        });
        setTableData(secondTableData.activities);

        const feedbackIds = await getActivityFeedbacksInRange({
          id_group: groupId,
          ids_patient: [patId],
          id_activity_types: currActivities,
          startDate: dateRange.start.format(dateFormat),
          endDate: dateRange.end.format(dateFormat),
        });
        fixProxyParamsListAvailable(feedbackIds);
        if (studyParams)
          setActiveStudyParameters(
            feedbackIds.sort((idA, idB) =>
              studyParams
                .find((s) => s.id === idA)
                ?.name.localeCompare(
                  studyParams.find((s) => s.id === idB)?.name
                )
            )
          );
      }
    } catch (error) {
      rollbar.error("StatisticsActivities - fetchDataActivities", error);
      props.snackbarShowErrorMessage(error);
    }
  }, [currActivities, dateRange, currStudyParameters]);

  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currActivities)
        setCurrActivities(loadConfiguration.currActivities);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currActivities: currActivities,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  const TableRow = ({ data, maxValues }) => {
    let fillPercentage = 100;
    if (maxValues) {
      fillPercentage = Math.floor((data.value / maxValues[data.field]) * 100);
      fillPercentage = isNaN(fillPercentage) ? 0 : fillPercentage;
    }
    //A simple antialiasing trick
    const antialisingIncrease = fillPercentage
      ? Math.min(2, 0.1 * (Object.keys(maxValues ?? {}).length + 1))
      : 0;

    //Create data row
    const value = data.value;
    return (
      <div
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          height: 32,
          minWidth: "100%",
        }}
      >
        <div
          style={{
            background: `linear-gradient(70deg, ${
              theme.colors.primary.lightBlue
            } ${fillPercentage}%, ${theme.colors.primary.black} ${
              fillPercentage + antialisingIncrease
            }%)`,
            height: 30,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: theme.colors.primary.white,
              fontWeight: 800,
              fontSize: "1.2rem",
              display: "flex",
            }}
          >
            {value}
          </span>
        </div>
      </div>
    );
  };

  const createRowsData = (data) => {
    if (!data) return [];
    const reitime = data?.map((item) => {
      let retItem = {
        id: `${item.patient.givenName} ${item.patient.familyName}`,
        patient: `${item.patient.givenName} ${item.patient.familyName}`,
      };

      currActivities.map((activity) => {
        const labelsel = `${
          labels.groups.groupReports.secondTable.sessions
        } ${psTranslate(activities.find(({ id }) => id == activity)?.name)}`;

        retItem[labelsel] =
          item?.activity_types?.filter(
            ({ id_activity_type }) =>
              id_activity_type ==
              activities.find(({ id }) => id == activity)?.name
          )?.[0]?.activities?.length ?? 0;
      });
      return retItem;
    });
    //Return row data
    return reitime;
  };

  const createColumnHeaders = (data) => {
    //Prepare headers
    const headers = currActivities.map(
      (item) =>
        `${labels.groups.groupReports.secondTable.sessions} ${psTranslate(
          activities.find(({ id }) => id == item)?.name
        )}`
    );

    const newData = [];
    newData.push({
      id: PATIENT_KEY,
      field: PATIENT_KEY,
      headerName: isPatientLabel
        ? labels.groups.groupReports.firstTable.header.patient
        : labels.groups.groupReports.firstTable.header.athlete,
      editable: false,
      width: 200,
    });
    newData.push(
      ...headers.map((header) => ({
        id: header,
        field: header,
        headerName: header,
        editable: false,
        minWidth: 100,
        maxWidth: 250,
        flex: 1,
        renderCell: (params) => <TableRow data={params} />,
      }))
    );
    return newData;
  };

  return (
    <Grid item container xs={12} direction="row">
      <Grid item xs={4}>
        <SpSelect
          label={labels.groups.groupReports.secondTable.chooseActivity}
          selectPlaceholder={labels.groups.groupReports.secondTable.selectValue}
          multiple
          formControlStyle={{ minWidth: "100%" }}
          value={currActivities}
          onChange={(evnt) => setCurrActivities(evnt.target.value)}
        >
          {activities?.map((lp) => (
            <SpSelectMenuItem key={lp.id} value={lp.id}>
              {psTranslate(lp.name)}
            </SpSelectMenuItem>
          ))}
        </SpSelect>
      </Grid>
      <Grid item xs={12}>
        <SpDataGrid
          headers={createColumnHeaders(tableData)}
          rows={createRowsData(tableData)}
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(StatisticsActivities);
