import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import ReportGlobalFilters from "./ReportGlobalFilters";
import ReportGraph from "./ReportGraph";
import ReportStudies from "./ReportStudies";

const moment = extendMoment(Moment);

const DEFAULT_RANGE = moment.rangeFromInterval("month", -1, moment().startOf('day'));

///This is the main feedback components that connect global filter and graph data
///FetchedData format: { subject_name: { study_parameter_id: { "YYYY-MM-DD": datapoint, ... }, ... }, ... }
const Report = ({
  mainSubjectKey,
  studies,
  enabledStudies,
  onNewDataRequest,
  onCombinedParamRequest,
  combinedParams,
  fetchedData,
  activities,
  studyParameters,
  additionalStudySubjects,
  defaultZero = false,

  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  //Global filters
  const [currActivities, setCurrActivities] = useState([]);
  const [currStudyParameters, setCurrStudyParameters] = useState([]);
  const [currAdditionalStudySubjects, setCurrAdditionalStudySubjects] =
    useState([]);
  const [graphType, setGraphType] = useState(null);
  const [graphDateView, setGraphDateView] = useState(null);
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [studiesMeasureUnits, setStudiesMeasureUnits] = useState({});

  //Fetch data
  const [fetchDateRange, setFetchDateRange] = useState(DEFAULT_RANGE);

  //Real graph data
  //Format { study_key: { study_curve_custom_name: { subject_name: { parameter_id: { "YYYY-MM-DD": datapoint, ... }, ... }, ... }, ... }, ... }
  const [graphData, setGraphData] = useState({});

  //Effects
  useEffect(
    () => onCombinedParamRequest(currStudyParameters),
    [currStudyParameters]
  );
  useEffect(
    () =>
      onNewDataRequest(
        currActivities,
        currStudyParameters,
        currAdditionalStudySubjects,
        fetchDateRange
      ),
    [
      currActivities,
      currStudyParameters,
      currAdditionalStudySubjects,
      fetchDateRange,
    ]
  );

  return (
    <Grid container>
      {/*Global filter grid*/}
      <Grid container item xs={12}>
        <ReportGlobalFilters
          dateRange={dateRange}
          graphType={graphType}
          graphDateView={graphDateView}
          studyParameters={studyParameters}
          additionalStudySubjects={additionalStudySubjects}
          activities={activities}
          currActivities={currActivities}
          studiesMeasureUnits={studiesMeasureUnits}
          currStudyParameters={currStudyParameters}
          currAdditionalStudySubjects={currAdditionalStudySubjects}
          combinedParams={combinedParams}
          onActivitiesChange={(activities) => setCurrActivities(activities)}
          onDateRangeChange={(range) => setDateRange(range)}
          onStudyParametersChange={(params) => setCurrStudyParameters(params)}
          onGraphTypeChange={(type) => setGraphType(type)}
          onGraphDateViewChange={(dateView) => setGraphDateView(dateView)}
          onAdditionalStudySubjectsChange={(subjects) =>
            setCurrAdditionalStudySubjects(subjects)
          }
          config={config?.reportGlobalFilters}
          setConfig={(data) => setConfig({ reportGlobalFilters: data })}
          requestSaveFlag={requestSaveFlag}
        />
      </Grid>

      <Grid container item xs={12}>
        {/*Graph grid*/}
        <Grid container item xs={9}>
          <ReportGraph
            studies={studies}
            dateRange={dateRange}
            graphType={graphType}
            graphDateView={graphDateView}
            currStudyParameters={currStudyParameters}
            defaultZero={defaultZero}
            data={graphData}
          />
        </Grid>

        {/*Studies grid*/}
        <Grid container item xs={3}>
          <ReportStudies
            mainSubjectKey={mainSubjectKey}
            studies={studies}
            graphDateView={graphDateView}
            graphType={graphType}
            currStudyParameters={currStudyParameters}
            dateRange={dateRange}
            fetchDateRange={fetchDateRange}
            defaultZero={defaultZero}
            studiesMeasureUnits={studiesMeasureUnits}
            setStudiesMeasureUnits={setStudiesMeasureUnits}
            onFetchDateRangeChange={(range) => setFetchDateRange(range)}
            data={fetchedData}
            setStateGraphData={setGraphData}
            config={config?.reportStudies}
            setConfig={(data) => setConfig({ reportStudies: data })}
            requestSaveFlag={requestSaveFlag}
            {...props}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Report);
