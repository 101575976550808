import React, { forwardRef, useEffect, useState } from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
// material ui
import Grid from "@material-ui/core/Grid";
// models
// components
import { styled } from "../../../../../../components/styled";
import { theme } from "../../../../../../components/theme";
// atoms
import { SpAutocomplete } from "../../../../../../components/atoms/SpAutocomplete";
import SpCheckbox from "../../../../../../components/atoms/SpCheckbox";
// utils
// mocks

const StyledGridContainerA = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "1%",
});

const StyledGridContainerB = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
});

export const StrDysfFilter = forwardRef(
  ({
    structures,
    dysfunctions,
    dysfunctionsType,
    inputValues,
    setInputValues,
    filterLocks,
    setFilterLocks,
  }) => {
    return (
      <StyledGridContainerB key="">
        <Grid
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginLeft: "0.5%",
          }}
        >
          <Grid item xs={3}>
            <SpAutocomplete
              id={"selectRfStructure"}
              formControlWidth={"100%"}
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.structure
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.structure
              }
              value={
                structures.find(
                  ({ id }) => parseInt(id) === parseInt(inputValues.rfStructure)
                ) ?? ""
              }
              onChange={(e, { id, name }) => {
                setInputValues({
                  ...inputValues,
                  rfStructure: id,
                  rfStructureName: name,
                });
              }}
              options={structures}
              getOptionLabel={({ name }) => psTranslate(name)} // get from options.name
              getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                id_opt === id_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockRfStructure"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.rfStructureToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  rfStructureToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SpAutocomplete
              id={"selectRfDysfunction"}
              formControlWidth={"100%"}
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.dysfunction_category
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.dysfunction_category
              }
              value={
                dysfunctions.find(
                  ({ dysfunction_category }) =>
                    dysfunction_category?.id === inputValues.rfDysfunction
                ) ?? ""
              }
              onChange={(_, { dysfunction_category }) => {
                setInputValues({
                  ...inputValues,
                  rfDysfunction: dysfunction_category?.id,
                });
              }}
              options={dysfunctions}
              getOptionLabel={({ dysfunction_category }) =>
                psTranslate(dysfunction_category?.name)
              } // get from option.name
              getOptionSelected={(
                { dysfunction_category: dysfCat_opt },
                { dysfunction_category: dysfCat_value }
              ) => dysfCat_opt?.id === dysfCat_value?.id}
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockRfDysfunction"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.rfDysfunctionToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  rfDysfunctionToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SpAutocomplete
              id={"selectRfDysfunctionSub"}
              formControlWidth={"100%"}
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.dysfunction_type
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.ruleSection
                  .structure_dysfunction.dysfunction_type
              }
              value={
                dysfunctionsType.find(
                  ({ id }) => id === inputValues.rfDysfunctionType
                ) ?? ""
              }
              onChange={(_, { id, name }) => {
                setInputValues({
                  ...inputValues,
                  rfDysfunctionType: id,
                  rfDysfunctionTypeName: name,
                });
              }}
              options={dysfunctionsType}
              getOptionLabel={({ name }) => psTranslate(name)} // get from option.name
              getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                id_opt === id_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockRfDysfunctionSub"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.rfDysfunctionTypeToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  rfDysfunctionTypeToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>
      </StyledGridContainerB>
    );
  }
);
