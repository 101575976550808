import { Chip, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { theme } from "../../../components/theme";
import {
  getMeasurementsParameters,
  getMeasurementsParametersAnswers,
} from "../../../models/actions/Patients";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsMeasurements = ({
  dateRange,
  currActivities,
  setData,
  assessmentsIds,

  dateLoaded,
  loadConfiguration,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [measurementsMapping, setMeasurementsMapping] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [currMeasurements, setCurrMeasurements] = useState([]);

  const [measurementsData, setMeasurementsData] = useState([]);

  const { groupId, patId } = useParams();

  useEffect(async () => {
    if (dateLoaded) {
      try {
        //Obtain id-name mapping
        const newMeasurementsMapping = await getMeasurementsParameters();
        setMeasurementsMapping(
          newMeasurementsMapping.reduce((accum, mapping) => {
            accum[mapping.id] = `${psTranslate(
              mapping.category_name
            )} - ${psTranslate(mapping.element_name)} (${psTranslate(
              mapping.column_name
            )})`;
            return accum;
          }, {})
        );
      } catch (error) {
        rollbar.error("StatisticsMeasurements - fetchDataInitial", error);
        props.snackbarShowErrorMessage(error);
      }
    }
  }, [dateLoaded]);

  useEffect(async () => {
    if (measurementsMapping) {
      try {
        //Fetch measurements
        const measurementsAnswers = await getMeasurementsParametersAnswers({
          id_group: groupId,
          id_patients: patId ? [patId] : null,
          parameters: null,
          ids_monitoring_assessment:
            assessmentsIds.ids_monitoring_assessment ?? [],
          ids_presentation_assessment:
            assessmentsIds.ids_presentation_assessment ?? [],
          startDate: dateRange.start.format(dateFormat),
          endDate: dateRange.end.format(`${dateFormat} 23:59:59`),
        });
        //Average measurements
        const newData = Object.entries(measurementsAnswers).reduce(
          (accum, [patientKey, patientData]) => {
            accum[patientKey] = Object.entries(patientData).reduce(
              (accum, [measurementId, measurementData]) => {
                const currMeasurementName = measurementsMapping[measurementId];
                //Convert days into plain array (sorted)
                accum[currMeasurementName] = Object.entries(measurementData)
                  .sort(([k1], [k2]) => moment(k1) - moment(k2))
                  .map(([_, val]) => val);
                return accum;
              },
              {}
            );
            return accum;
          },
          {}
        );
        setMeasurementsData(newData);

        //Set measurements available
        const newMeasurements = [
          ...new Set(Object.keys(Object.values(newData)[0] ?? {})),
        ];
        setMeasurements(newMeasurements.sort());

        //Remove from selection items not available anymore
        setCurrMeasurements(
          currMeasurements.filter((el) => newMeasurements.includes(el))
        );
      } catch (error) {
        rollbar.error("StatisticsMeasurements - fetchMeasurements", error);
        props.snackbarShowErrorMessage(error);
      }
    }
  }, [measurementsMapping, assessmentsIds, dateRange]);

  useEffect(() => {
    if (measurementsMapping) {
      //Filter measurements data
      if (currMeasurements.length > 0) {
        const newData = Object.entries(measurementsData).reduce(
          (accum, [patientKey, patientData]) => {
            accum[patientKey] = Object.entries(patientData).reduce(
              (accum, [measurementName, measurementData]) => {
                if (currMeasurements.includes(measurementName)) {
                  //Table data
                  accum[measurementName] = measurementData;
                }
                return accum;
              },
              {}
            );
            return accum;
          },
          {}
        );
        setData(newData);
      } else setData({});
    }
  }, [measurementsMapping, currMeasurements]);

  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currMeasurements)
        setCurrMeasurements(loadConfiguration.currMeasurements);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currMeasurements: currMeasurements,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={10}>
        <SpAutocomplete
          label={labels.groups.groupReports.secondTable.chooseMeasurements}
          multiple
          formControlWidth={"100%"}
          value={currMeasurements}
          onChange={(_, value) => setCurrMeasurements(value)}
          options={measurements}
          getOptionLabel={(elem) => psTranslate(elem) ?? null}
          getOptionSelected={(option, value) => option === value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                }}
                label={option}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Grid>
      <Grid item xs={1} style={{ marginTop: "auto", marginBotton: "0px" }}>
        <SpButton
          buttonType={"accept"}
          variant="h4ComponentLabel"
          onClick={() => {
            currMeasurements.length === measurements.length &&
            measurements.length !== 0
              ? setCurrMeasurements([])
              : setCurrMeasurements(measurements);
          }}
          text={
            currMeasurements.length === measurements.length &&
            measurements.length !== 0
              ? labels.groups.plan.deselectAll
              : labels.groups.plan.selectAll
          }
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(StatisticsMeasurements);
