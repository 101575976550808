import React from "react";

import { styled } from "../styled";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import { theme } from "../theme";

const CustomFormControl = styled(FormControl)({
  flex: 1,
});
const CustomTextField = styled(TextField)(
  ({ textfieldvariant, componentvariant, isPrint = false }) => ({
    ...textfieldvariant,
    maxHeight: "30px",
    borderColor:
      componentvariant !== "displayText"
        ? theme.colors.primary.lightBlue
        : "none",
    color: "white",
    border: componentvariant !== "displayText" ? "1px solid" : "none",
    backgroundColor: !isPrint
      ? componentvariant !== "displayText"
        ? "#333333"
        : "none"
      : "white",
    textAlign: componentvariant !== "displayText" ? "center" : "left",
    paddingLeft: componentvariant !== "displayText" ? 3 : 0,
  })
);

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: theme.colors.primary.lightBlue,
  fontWeight: "bold",
  fontSize: 14,
});

const formControlLabelPlacementTop = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const formLabelLabelPlacementBottom = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  grey: {
    backgroundColor: "#f9f5fa",
    color: "#FFFFFF",
  },
  white: {
    backgroundColor: "white",
    color: "#FFFFFF",
    textAlign: "center",
    verticalAlign: "center",
  },
  column: {
    marginTop: 8,
  },
  fullWidth: {
    width: "100%",
  },
};

export const SpTextInput = ({
  id,
  style,
  variant,
  errorLabel,
  label,
  componentvariant,
  displayLabel = true,
  displayErrorLabel = false,
  textAlign,
  formControlStyle,
  textfieldVariant = "white",
  labelPlacementLeft,
  InputProps,
  inputPropsStyle,
  minValue,
  maxValue,
  step,
  defaultValue,
  className,
  disableKeyboardInput,
  isPrint = false,
  isRequired = false,
  ...rest
}) => {
  const controlStyle = {
    ...style,
    ...formControlStyle,
    ...variants[variant],
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};
  const errorLabelStyle = labelPlacementLeft
    ? formLabelLabelPlacementBottom
    : {};

  return (
    <CustomFormControl style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle}>
          {isRequired && <span style={{ color: "red" }}>* </span>}
          {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      <CustomTextField
        id={id}
        {...rest}
        defaultValue={defaultValue}
        textfieldvariant={variants[textfieldVariant]}
        isPrint={isPrint}
        componentvariant={componentvariant}
        variant="standard"
        onKeyDown={disableKeyboardInput ? (e) => e.preventDefault() : () => {}}
        InputProps={{
          ...InputProps,
          disableUnderline: true,
        }}
        inputProps={{
          style: {
            color: !isPrint ? "#FFFFFF" : "black",
            textAlign: textAlign ? textAlign : "left",
            ...inputPropsStyle,
          },
          min: minValue,
          max: maxValue,
          step: step,
        }}
      />
      {
        <p className={className} style={{ display: "none" }}>
          {defaultValue}
        </p>
      }
      {displayErrorLabel ? (
        <CustomFormLabel style={errorLabelStyle}>{errorLabel}</CustomFormLabel>
      ) : (
        <></>
      )}
    </CustomFormControl>
  );
};

export default SpTextInput;
