import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import "../../../../App.css";
import SpText from "../../../../components/atoms/SpText";
import { labels, psTranslate } from "../../../shared/translations";
import { theme } from "../../../../components/theme";
import SpTooltip from "../../../../components/atoms/SpTooltip";

const DysfunctionsArea = ({
  config,
  setConfig,
  requestSaveFlag,
  allData,
  ...props
}) => {
  const [areaData, setAreaData] = useState([]);

  const updateAreaGraph = () => {
    const newAreaData = allData?.reduce((accum, data) => {
      const dysfCat =
        data.structure_dysfunction?.dysfunction?.dysfunction_category;
      if (
        accum.filter(
          (e) =>
            psTranslate(data.structure_dysfunction?.structure?.area?.name) ===
            psTranslate(e.name)
        ).length > 0
      ) {
        accum.find(
          (element) =>
            psTranslate(element.name) ===
            psTranslate(data.structure_dysfunction?.structure?.area?.name)
        ).value += dysfCat?.weight * data?.severity;
      } else {
        accum.push({
          name: psTranslate(data.structure_dysfunction?.structure?.area?.name),
          value: dysfCat?.weight * data?.severity,
        });
      }
      return accum;
    }, []);
    setAreaData(newAreaData);
  };

  useEffect(() => {
    if (allData) updateAreaGraph();
  }, [allData]);

  const NoData = () => (
    <Grid item xs={12}>
      <Grid
        direction="column"
        container
        xs={12}
        style={{ marginTop: "3%", padding: "1%" }}
      >
        <SpText variant="h1PageTitle">{labels.general.no_data}</SpText>
      </Grid>
    </Grid>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "10px" }}
        >
          <p className="label">{`${label}: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Grid
      item
      xs={12}
      style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
    >
      <Grid container xs={12} style={{ padding: "1%" }} direction="column">
        <SpText variant="h1PageTitle">{labels.analytics.disturbedAreas}</SpText>
        <SpText variant="h4ComponentLabel">
          {labels.patient.graphReport.section.disorder.filters.area_description}
        </SpText>
      </Grid>
      {areaData.length === 0 && <NoData />}
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart style={{ margin: "0 auto" }} data={areaData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fill: "white" }}
              textAnchor="end"
              scaleToFit="true"
              verticalAnchor="start"
              interval={0}
              angle="-40"
              height={150}
            />
            <YAxis dataKey="value" tick={{ fill: "white" }} />
            <SpTooltip content={<CustomTooltip />} />
            <Bar
              dataKey="value"
              fill={theme.colors.primary.lightBlue}
              barSize={30}
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default DysfunctionsArea;
