import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "../../App.css";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import Grid from "@material-ui/core/Grid";
import { Avatar } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpTextInput from "../../components/atoms/SpTextInput";
import { ArrowBack } from "@material-ui/icons";
import { createGroup } from "../../models/actions/Groups";
import { getUserPermission } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const GroupsAdd = (props) => {
  useEffect(() => {
    reset();
  }, []);

  const { reset, register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const saveGroupDetail = async (data) => {
    try {
      const dataRequest = { ...data, isFederation: isFederation };
      const response = await createGroup(dataRequest);
      props.snackbarShowMessage(response.message);
      history.push("/groups");
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const history = useHistory();

  const [isFederation, setIsFederation] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    setIsFederation(permissionFederationGroups);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(saveGroupDetail)}>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container alignItems={"center"}>
            <Grid item xs={8}>
              <TitleToolbarSection>
                <ArrowBack
                  onClick={() => history.push("/groups")}
                  style={{
                    width: 30,
                    color: theme.colors.primary.lightBlue,
                    height: 50,
                    cursor: "pointer",
                  }}
                />
                <SpText variant="h1">
                  {isFederation
                    ? labels.groups.toolbar.backFederation
                    : labels.groups.toolbar.back}
                </SpText>
              </TitleToolbarSection>
            </Grid>
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                id={"save"}
                buttonType={"accept"}
                type="submit"
                text={labels.groups.groupDetail.actions.save}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid container spacing={4} direction={"column"}>
              <Grid item container spacing={2} alignItems={"center"}>
                <Grid item xs={2}>
                  <Avatar
                    style={{
                      width: theme.spacing(20),
                      height: theme.spacing(20),
                    }}
                  />
                </Grid>
                <Grid item container direction="column" xs={10} spacing={4}>
                  <Grid item xs={12}>
                    <SpTextInput
                      id="federationAddName"
                      variant="fullWidth"
                      label={
                        isFederation
                          ? labels.groups.federationAdd.title
                          : labels.groups.groupAdd.title
                      }
                      name="name"
                      inputRef={register}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default withSnackbar(GroupsAdd);
