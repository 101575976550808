import React from "react";
import { getPostUrl } from "./Common";

export const uploadFile = async (file) => {
  const url = await getPostUrl({ filename: file.name });
  await fetch(url, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": file.type },
  });
};
