import React from "react";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";
import SpText from "../atoms/SpText";
import { theme } from "../theme";

const useStyles = makeStyles({
  paginationToolbar: {
    paddingLeft: isMobile ? 0 : 16,
    paddingRight: isMobile ? 0 : 16,
    background: "transparent",
    color: theme.colors.primary.lightBlue,
    border: `1px solid ${theme.colors.primary.lightBlue}`,
  },
});

export const SpPagination = ({
  elementsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
}) => {
  const classes = useStyles();
  const pageUpperLimit = () => {
    return (page + 1) * rowsPerPage > elementsCount
      ? elementsCount
      : (page + 1) * rowsPerPage;
  };

  const handleChangePage = (event, newPage) => {
    if (newPage * rowsPerPage <= elementsCount) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `Pagina corrente: ${page + 1} di ${Math.ceil(elementsCount / rowsPerPage)}`;
  return (
    elementsCount > rowsPerPage && (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TablePagination
            classes={{ toolbar: classes.paginationToolbar }}
            labelRowsPerPage={""}
            labelDisplayedRows={labelDisplayedRows}
            count={elementsCount}
            page={page}
            onChangePage={handleChangePage}
          />
        </div>
        {!isMobile && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <>
              <SpText variant="text">{`Totale: ${elementsCount} elementi`}</SpText>
              <Pagination
                count={Math.ceil(elementsCount / rowsPerPage)}
                page={page + 1}
                onChange={(e, newPage) => handleChangePage(e, newPage - 1)}
                variant="outlined"
                shape="rounded"
              />
            </>
          </div>
        )}
      </div>
    )
  );
};
