import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import "./PricingTableStyle.css";
import SpButton from "../../components/atoms/SpButton";
import { labels } from "../shared/translations";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  getAuth0Roles,
  payment,
  subscription,
} from "../../models/actions/Common";
import moment from "moment";
import SpLoader from "../../components/atoms/SpLoader";
import { RegistrationPage } from "./RegistrationPage";
import LanguagesFlags from "../../components/bundles/LanguagesFlags";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
export const userType = { basic: "Basic", advanced: "Advanced", pro: "Pro" };

export const period = { month: "monthly", year: "yearly" };

export const paymentTable = {
  Basic: { monthly: 40, yearly: 480 },
  Advanced: { monthly: 70, yearly: 840 },
  Pro: { monthly: 125, yearly: 1500 },
};
const stripePromise = loadStripe(process.env.STRIPE);

const PaymentSection = (props) => {
  const stripe = useStripe();
  const [selectedPlan, setSelectedPlan] = useState(userType.basic);
  const [loading, setLoading] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(period.year);
  const [expiredDate, setExpiredDate] = useState(moment().add(1, "year"));
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const elements = useElements();
  const name = useRef();
  const surname = useRef();
  const email = useRef();
  const password = useRef();
  const ragionesociale = useRef();
  const address = useRef();
  const city = useRef();
  const cap = useRef();
  const paese = useRef();
  const piva = useRef();
  const fiscalcode = useRef();

  const PricingPlan = ({
    type,
    description,
    price,
    features,
    noAvailable,
    isSelected,
    action,
  }) => {
    return (
      <div
        class={!isSelected ? "basic-plan" : "basic-plan selected"}
        onClick={action}
      >
        <p className="price">{price}</p>
        <p>{description}</p>
        <ul>
          {features.map((feature) => (
            <li>
              <CheckCircleIcon className="icon" /> {feature}
            </li>
          ))}
          {noAvailable.map((feature) => (
            <li>
              <BlockIcon className="icon" /> {feature}
            </li>
          ))}
        </ul>
        {isSelected && (
          <Grid item xs={12}>
            <SpButton
              buttonType={"accept"}
              style={{ marginTop: "2em" }}
              onClick={() => {
                setShowRegistration(true);
              }}
              text={labels.manageUsers.payment}
            />
          </Grid>
        )}
      </div>
    );
  };

  const createProfessional = async () => {
    if (
      name.current.value &&
      surname.current.value &&
      email.current.value &&
      password.current.value &&
      ragionesociale.current.value &&
      address.current.value &&
      city.current.value &&
      cap.current.value &&
      paese.current.value &&
      piva.current.value &&
      fiscalcode.current.value
    ) {
      setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js non è ancora stato caricato o il componente è stato dismontato.
        setLoading(false);
        return;
      }

      const userMetadata = {
        expiry_date: `${moment(expiredDate).format(
          "YYYY-MM-DD"
        )}T22:59:59+01:00`,
        name: name.current.value,
        surname: surname.current.value,
        subscription_type: selectedPlan,
      };

      const roles = await getAuth0Roles({});
      if (selectedPeriod === period.year) {
        const res = await payment({
          amount: paymentTable[selectedPlan][selectedPeriod],
          dataUser: {
            email: email.current.value,
            password: password.current.value,

            ragionesociale: ragionesociale.current.value,
            address: address.current.value,
            city: city.current.value,
            cap: cap.current.value,
            paese: paese.current.value,
            piva: piva.current.value,
            fiscalcode: fiscalcode.current.value,

            metadata: userMetadata,
            roles: [roles.find(({ name }) => name === selectedPlan)?.id],
            type: selectedPlan,
          },
        });
        if (res.error) {
          props.snackbarShowErrorMessage(res.error);
          setLoading(false);
        } else {
          const result = stripe.redirectToCheckout({
            sessionId: res.id,
          });
          if (result.error) {
            props.snackbarShowErrorMessage(result.error);
            setLoading(false);
          }
          setLoading(false);
        }
      } else {
        const res = await subscription({
          amount: paymentTable[selectedPlan][selectedPeriod],
          dataUser: {
            email: email.current.value,
            password: password.current.value,

            ragionesociale: ragionesociale.current.value,
            address: address.current.value,
            city: city.current.value,
            cap: cap.current.value,
            paese: paese.current.value,
            piva: piva.current.value,
            fiscalcode: fiscalcode.current.value,

            metadata: userMetadata,
            roles: [roles.find(({ name }) => name === selectedPlan)?.id],
            type: selectedPlan,
          },
        });
        if (res.error) {
          props.snackbarShowErrorMessage(res.error);
          setLoading(false);
        } else {
          const result = stripe.redirectToCheckout({
            sessionId: res.id,
          });
          if (result.error) {
            props.snackbarShowErrorMessage(result.error);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    } else {
      props.snackbarShowErrorMessage("Enter all the informations");
      setLoading(false);
    }
  };

  useEffect(() => {
    let newDate;
    if (selectedPeriod === period.month) {
      newDate = moment().add(1, "months");
    }
    if (selectedPeriod === period.year) {
      newDate = moment().add(1, "year");
    }
    setExpiredDate(newDate);
  }, [selectedPeriod]);

  return (
    <Grid container className="backgroundPricing">
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid container direction="row" justifyContent="center" spacing={1}>
        <Grid item>
          <p className="titlePrice">{labels.registrationPage.title}</p>
        </Grid>
        {showRegistration && (
          <RegistrationPage
            name={name}
            surname={surname}
            email={email}
            password={password}
            ragionesociale={ragionesociale}
            address={address}
            city={city}
            cap={cap}
            paese={paese}
            piva={piva}
            fiscalcode={fiscalcode}
            privacyPolicy={privacyPolicy}
            setPrivacyPolicy={setPrivacyPolicy}
            createProfessional={createProfessional}
          />
        )}

        {!showRegistration && (
          <>
            <Grid
              container
              justifyContent="center"
              spacing={1}
              style={{ paddingTop: "2em" }}
            >
              <Grid item>
                <ToggleButtonGroup size="small" exclusive>
                  <ToggleButton
                    value={labels.registrationPage.month}
                    onClick={() => {
                      setSelectedPeriod(period.month);
                      setSelectedPlan(userType.basic);
                    }}
                  >
                    <div
                      class={
                        selectedPeriod === period.month
                          ? "toggle-plan selected"
                          : "toggle-plan"
                      }
                    >
                      <p className="toggle-plan-price">
                        {labels.registrationPage.month}
                      </p>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    value={labels.registrationPage.year}
                    onClick={() => {
                      setSelectedPeriod(period.year);
                      setSelectedPlan(userType.basic);
                    }}
                  >
                    <div
                      class={
                        selectedPeriod === period.year
                          ? "toggle-plan selected"
                          : "toggle-plan"
                      }
                    >
                      <p className="toggle-plan-price">
                        {labels.registrationPage.year}
                      </p>
                    </div>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              spacing={1}
              style={{ paddingTop: "2em" }}
            >
              <Grid item>
                <PricingPlan
                  description={labels.registrationPage.advancedDescription}
                  isSelected={selectedPlan == userType.basic}
                  action={() => {
                    setSelectedPlan(userType.basic);
                  }}
                  type={userType.basic}
                  price={
                    selectedPeriod === period.month ? "€37,90/mo" : "€390/y"
                  }
                  features={[
                    "Dashboard",
                    "Athletes management (up to 25)",
                    "Agenda",
                    "Alert system",
                  ]}
                  noAvailable={[]}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const PaymentPage = (props) => {
  return (
    <div className="App">
      <Grid container direction="row">
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <LanguagesFlags />
        </Grid>
      </Grid>
      <Elements stripe={stripePromise}>
        <PaymentSection {...props} />
      </Elements>
    </div>
  );
};
export default withSnackbar(PaymentPage);
