import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useState } from "react";
import "../../../App.css";
import {
  calculateFinalScore,
  getAllPromsSurveyAnswers,
  getAllPromsSurveyAnswersGroups,
  getAllPromsNames,
} from "../../../models/actions/Proms";
import { dateFormat } from "../../../utils/common";
import { psTranslate } from "../../shared/translations";
import TemplatePatientsReports from "./TemplatePatientsReports";

const moment = extendMoment(Moment);

const EXCLUDED_STUDIES = [
  "MONOTONY",
  "STRAIN",
  "ACWRRA",
  "ACWREWMA",
  "SYMMETRY",
  "ASYMMETRY",
  "SYMMETRYASS",
];

const PatientsReportsStep2PROMs = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [mcidList, setMcidList] = useState(new Set());

  const getStudyParams = async () => {
    const proms = await getAllPromsNames();
    proms.sort((a, b) =>
      psTranslate(a.name).localeCompare(psTranslate(b.name))
    );
    return proms;
  };

  const getStudyParamsInRange = async () => {
    return null; //Always show all
  };

  const fetchPatientsData = async (
    _,
    fetchDateRange,
    currStudyParameterIds,
    patientIds
  ) => {
    //Fetch data
    const patientResults = await getAllPromsSurveyAnswers({
      id_patients: patientIds,
      starting_date: fetchDateRange.start.format(dateFormat),
      ending_date: fetchDateRange.end.format(dateFormat),
      proms: currStudyParameterIds,
    });
    //Process data
    const newMcidList = new Set();
    const newPromsGraphData = {};
    patientResults.forEach((p) =>
      processPatientData(newPromsGraphData, p, newMcidList)
    );
    averageGraphData(newPromsGraphData);
    setMcidList(newMcidList);
    return newPromsGraphData;
  };

  const fetchGroupData = async (
    _,
    fetchDateRange,
    currStudyParameterIds,
    groups
  ) => {
    //Fetch data
    const groupResults = await getAllPromsSurveyAnswersGroups({
      id_groups: groups,
      starting_date: fetchDateRange.start.format(dateFormat),
      ending_date: fetchDateRange.end.format(dateFormat),
      proms: currStudyParameterIds,
    });
    //Process data
    const newMcidList = new Set();
    const groupData = {};
    const processedData = groupResults.map((group) => {
      //Average single patients
      const groupProcessedData = {};
      group.patients.forEach((p) =>
        processPatientData(groupProcessedData, p, newMcidList)
      );
      averageGraphData(groupProcessedData);
      return {
        name: group.name,
        data: groupProcessedData,
      };
    });
    //Average patients in group
    processGroupData(groupData, processedData);
    setMcidList((oldMcidList) => new Set([...oldMcidList, ...newMcidList]));
    return groupData;
  };

  const processPatientData = (newPromsGraphData, patientData, newMcidList) => {
    if (patientData.data) {
      patientData.data.forEach((promInstance) => {
        const answer = promInstance.proms_answers[0];
        if (answer) {
          //Obtain date
          const date = moment(promInstance.date)
            .startOf("day")
            .format(dateFormat);

          //Generate graph data
          const finalScores = calculateFinalScore(
            promInstance.prom,
            promInstance.proms_answers
          );
          newMcidList.add(promInstance.prom.id);

          //Save scores
          const name = promInstance.prom.key;
          finalScores.forEach(({ label, value }) => {
            if (!isNaN(value)) {
              const lineKey = `${name} - ${label}`;
              if (!newPromsGraphData[patientData.name])
                newPromsGraphData[patientData.name] = {};
              if (!newPromsGraphData[patientData.name][lineKey])
                newPromsGraphData[patientData.name][lineKey] = {};
              if (!newPromsGraphData[patientData.name][lineKey][date])
                newPromsGraphData[patientData.name][lineKey][date] = [];
              newPromsGraphData[patientData.name][lineKey][date].push(
                parseFloat(value)
              );
            }
          });
        }
      });
    }
  };

  const processGroupData = (groupData, processedData) => {
    //Group patient values per group
    processedData.forEach(({ name, data }) => {
      if (!groupData[name]) groupData[name] = {};
      Object.values(data).forEach((lines) => {
        Object.entries(lines).forEach(([line, days]) => {
          if (!groupData[name][line]) groupData[name][line] = {};
          Object.entries(days).forEach(([day, value]) => {
            if (!groupData[name][line][day]) groupData[name][line][day] = [];
            groupData[name][line][day].push(value);
          });
        });
      });
    });
    //Average all
    averageGraphData(groupData);
  };

  const averageGraphData = (allData) => {
    Object.values(allData).forEach((lines) => {
      Object.entries(lines).forEach(([line, days]) => {
        Object.entries(days).forEach(([day, arr]) => {
          lines[line][day] = arr.reduce((tot, a) => tot + a, 0) / arr.length;
        });
      });
    });
  };

  return (
    <Grid container item xs={12}>
      <TemplatePatientsReports
        elements={null}
        excludedStudies={EXCLUDED_STUDIES}
        getStudyParams={getStudyParams}
        getStudyParamsInRange={getStudyParamsInRange}
        addProxyParameters={null}
        fixProxyParamsListAvailable={null}
        fixForProxyParams={null}
        fixForProxyParamsResults={null}
        fetchPatientsData={fetchPatientsData}
        fetchGroupData={fetchGroupData}
        enableCombinedParameters={false}
        defaultZero={false}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
        mcidList={mcidList}
      />
    </Grid>
  );
};

export default PatientsReportsStep2PROMs;
