import axios from "axios";
import { rollbar } from "../../utils/common";
/*
 * Status codes meaning:
 * 200 --> Everything ok
 * 520 --> Licence expired
 * 210 --> Everything ok but is in trial
 * 500 --> Something went wrong
 * 452 --> Not authorized to access to the page
 */

const STATUS_CODES = {
  EVERYTHING_OK: 200,
  LICENCE_EXPIRED: 520,
  EVERYTHING_OK_IS_IN_TRIAL: 210,
  SOMETHING_WENT_WRONG: 500,
  NOT_AUTHORIZED: 452,
};

export const call = async ({ method = "POST", url, data = {} }) => {
  const accessToken = await localStorage.getItem("accessToken");
  const headers = accessToken ? { "x-access-token": accessToken } : {};

  const __baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api${url}`;

  let __internalCall =
    method === "POST"
      ? axios.post(__baseUrl, data, { headers })
      : axios.get(__baseUrl, { headers });

  return __internalCall
    .then((response) => {
      //Check licence information
      if (response.data) {
        if (response.data.newToken) {
          localStorage.removeItem("accessToken");
          localStorage.setItem("accessToken", response.data.newToken);
          return response.data.data;
        } else {
          return response.data;
        }
      } else {
        return null;
      }
    })
    .catch((err) => {
      rollbar.error({ method, url, data }, JSON.stringify(err));
      if (err?.response?.status === STATUS_CODES.LICENCE_EXPIRED) {
        // redirect to licence-expired page
        if (location.pathname !== "/licence-expired")
          location.replace("/licence-expired");
        return;
      }
      if (err?.response?.status === STATUS_CODES.NOT_AUTHORIZED) {
        // redirect to 404
        location.replace("/not-found");
        return;
      }
      if (err?.response?.status === STATUS_CODES.SOMETHING_WENT_WRONG || err) {
        if (
          err.response?.data.error === "NOT AUTHORIZED" ||
          err.response?.data.error === "NO USER"
        ) {
          //reset the local storage and reload the page to go to login
          localStorage.removeItem("userId");
          localStorage.removeItem("accessToken");
          location.reload();
          return Promise.reject("Session expired, please log in again");
        }
        return Promise.reject("Server error, please contact support");
      }
      return Promise.reject(err.response.data.error);
    });
};
