import { Chip, Grid } from "@material-ui/core";
import React, { useState, createRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpTextArea from "../../components/atoms/SpTextArea";
import { theme } from "../../components/theme";
import { setActivityFeedbackParameters } from "../../models/actions/Activity";
import { archiveActivityById } from "../../models/actions/CalendarActivity";
import {
  rollbar,
  isFeatureFlagEnabled,
  downloadScreenshot,
  getLoggedEvents,
  ELEMENT_TYPE,
} from "../../utils/common";
import { SpElementCrudDetails } from "../../components/bundles/SpElementCrudDetails";

import {
  fetchDataGroup,
  deleteCalendarActivity,
  renderInputDetails,
} from "./AgendaCalendarEventCardFunction";

import moment from "moment";
import { labels, psTranslate } from "../shared/translations";
import printContext from "../../utils/printContext";
import PatientsReportsPDF from "../../pages/patients/patientsReports/PatientsReportsPDF";
import { syncDeviceDataByPatientsName } from "../../models/actions/Devices";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";

const AgendaCalendarEventCard = ({
  event,
  setOpenDetailActivity,
  fetchData,
  updateActivity,
  createActivityObject,
  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  archiveEnabled = true,
  forceDisabled = false,
  allowFeedback = true,

  isGroupCard = false,
  groupId,
  groupDetail,
  title,
  subactivities,
  activitySelectedKey,
  setValue,
  getValues,
  handleSubmit,
  register,
  control,
  groupsPatients,
  setGroupsPatientsSelected,
  groupsPatientsSelected,
  renderProfessionalAlert,
  ...props
}) => {
  const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
  const [professionalsSelected, setProfessionalsSelected] = useState([]);
  const history = useHistory();
  const [isPrint, setIsPrint] = useState(false);
  const [loggedEvents, setLoggedEvents] = useState({});

  const reference = createRef(null);

  useEffect(() => {
    if (event?.id_subactivity)
      setValue("id_subactivity", event?.id_subactivity);

    if (groupsPatients) {
      setGroupsPatientsSelected(
        groupsPatients.filter(({ id }) =>
          event.patients.map(({ id }) => id).includes(id)
        )
      );
    }
  }, [event]);

  const isDisabled = () => {
    return (
      forceDisabled ||
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent"
    );
  };

  useEffect(async () => {
    const resultEvent = await getLoggedEvents({
      record_table_id: event?.id,
      element_type: ELEMENT_TYPE.CALENDAR_ACTIVITY,
    });
    const resultEventGroup = await getLoggedEvents({
      record_table_id: event?.id,
      element_type: ELEMENT_TYPE.CALENDAR_ACTIVITY,
    });
    const result = resultEvent?.createdBy ? resultEvent : resultEventGroup;
    setLoggedEvents(result);
  }, []);

  const deleteCalendarActivityFunction = async (e) => {
    try {
      const resultDeleteActivity = await deleteCalendarActivity(
        e,
        event,
        updateActivity,
        fetchData
      );
      props.snackbarShowMessage(resultDeleteActivity.message);
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - deleteCalendarActivity", error);
      props.snackbarShowErrorMessage(error);
    }
    setOpenDetailActivity(false);
  };

  const updateActivityFeedback = async (data) => {
    try {
      if (data.feed_resp_ans) {
        const answers = data.feed_resp_ans.filter((a) => a);
        const result = await setActivityFeedbackParameters({
          feedback_parameters_answers: answers,
        });
        if (result.setActivity?.error) {
          props.snackbarShowErrorMessage(result.setActivity?.error);
        } else {
          props.snackbarShowMessage(result?.setActivity?.message);
          await fetchData();
        }
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - updateActivityFeedback", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateActivityContents = async (data) => {
    const updatedEvnt = {
      id: isGroupCard ? null : event.id,
      id_activity_groups: isGroupCard ? event.id_activity_groups : null, //for groups
      ids_patients: groupsPatients?.map(({ id }) => id), //for groups
      start_date: event.start_date,
      start_time: data.start_time,
      end_time: data.end_time,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
      emails_professionals_to_alert: professionalsSelected?.map(
        (professionalIter) => professionalIter.email
      ),
      group_name: groupDetail ? groupDetail.name : null,
    };
    await updateActivity(updatedEvnt);
    setOpenDetailActivity(false);
    await fetchData();
  };

  const archiveActivity = async (status) => {
    try {
      const result = await archiveActivityById({
        id: event.id,
        id_activity_groups: isGroupCard ? event.id_activity_groups : null, //for groups
        archiving_status: status,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result?.message);
        setOpenDetailActivity(false);
        await fetchData();
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - archiveActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateAndArchiveActivity = async (data) => {
    const updatedEvnt = {
      id: event.id,
      id_activity_groups: isGroupCard ? event.id_activity_groups : null, //for groups
      start_date: event.start_date,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
      emails_professionals_to_alert: professionalsSelected?.map(
        (professionalIter) => professionalIter.email
      ),
    };
    let archiveStatus;
    if (event.feedbacks.map((elem) => elem.answer).length > 0) {
      archiveStatus = "completed_and_feedback_sent";
    } else {
      archiveStatus = "completed_and_feedback_not_sent";
    }
    try {
      await updateActivity(updatedEvnt);
    } catch (error) {
      rollbar.error(
        "AgendaCalendarEventCard - updateAndArchiveActivity",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
    await archiveActivity(archiveStatus);
  };

  const reopenActivity = () => {
    archiveActivity(null);
  };

  const handleSync = async (data) => {
    const feedbackSync = await syncDeviceDataByPatientsName({
      patients: data.patients,
      event: data,
      start_time: data.start_time,
      end_time: data.end_time,
      id_activity_type: data?.id_activity_type,
    });
    return feedbackSync;
  };

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div ref={reference}>
        {/* Necessario per quando si stampa su PDF altrimenti il nome del gruppo o del paziente
    non si vedrebbe  */}
        {isPrint && <h2>{title}</h2>}
        <Grid
          container
          direction="column"
          style={{
            padding: 15,
            background: !isPrint ? theme.colors.primary.grey : null,
            minWidth: 800,
            width: "100%",
          }}
        >
          <Grid item xs={12} direction="row" container>
            <Grid xs={3} item container direction="column">
              <img
                src={event.media}
                style={{ width: 60, height: 60, marginRight: "8px" }}
                alt={""}
              />
              <Grid
                item
                direction="column"
                xs={6}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "100%",
                }}
              >
                {/* I pulsanti non devono essere visti nel PDF */}
                {!isPrint && (
                  <SpText variant={"h1PageSubtitleColored"}>
                    {psTranslate(event.title)}
                    {(event.id_activity_type === 3 ||
                      event.id_activity_type === 4) && (
                      <SpButton
                        buttonType={"accept"}
                        style={{ marginLeft: "5px", height: 20 }}
                        variant="h4ComponentLabel"
                        onClick={() =>
                          history.push(
                            `/mylab/${
                              event.id_activity_type === 3
                                ? `excercise`
                                : `training`
                            }/add`
                          )
                        }
                        text={labels.agenda.card.prepare}
                      />
                    )}
                  </SpText>
                )}
              </Grid>
            </Grid>
            <Grid xs={3}></Grid>
            <Grid xs={6} container direction="column" justify="flex-end">
              <SpElementCrudDetails crudDetails={loggedEvents} />
            </Grid>
            <Grid item xs={12}>
              {groupsPatientsSelected && (
                <>
                  <SpText>{labels.analytics.injuryReport.patientsGroup}</SpText>
                  {groupsPatientsSelected?.map(
                    ({ givenName, familyName, id }) => (
                      <Chip
                        key={id}
                        onClick={() =>
                          history.push(`/patients/edit/${id}/plan`)
                        }
                        style={{
                          backgroundColor: theme.colors.primary.lightBlue,
                          color: theme.colors.primary.white,
                          margin: "8px",
                        }}
                        label={`${givenName} ${familyName}`}
                        size="medium"
                      />
                    )
                  )}
                </>
              )}
            </Grid>
            <Grid containter xs={12} direction="row" spacing={1}>
              {event.patient ? (
                <Grid item xs={6} container direction="row">
                  <Chip
                    key={event.patient.id}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: theme.colors.primary.white,
                      margin: "8px",
                    }}
                    onClick={() =>
                      history.push(`/patients/edit/${event.patient.id}/plan`)
                    }
                    label={`${event.patient.givenName} ${event.patient.familyName}`}
                    size="medium"
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid
              container
              xs={12}
              direction="row"
              style={{ marginTop: "10px" }}
            >
              <SpTextInput
                type="time"
                name="start_time"
                min="05:00"
                max="22:00"
                style={{ paddingTop: "0.5em" }}
                onChange={(e) => {
                  let temoEnd = moment(e.target.value, "HH:mm");
                  temoEnd = moment(temoEnd).add(1, "hours");
                  setValue("end_time", moment(temoEnd).format("HH:mm"));
                }}
                defaultValue={moment(event.start).format("HH:mm")}
                inputRef={register({ required: true })}
                label={labels.patient.viewPlan.addActivity.inputForm.start_time}
              />
              <SpTextInput
                type="time"
                style={{ paddingTop: "0.5em" }}
                name="end_time"
                min="05:00"
                max="22:00"
                defaultValue={moment(event.end).format("HH:mm")}
                inputRef={register({ required: true })}
                label={labels.patient.viewPlan.addActivity.inputForm.end_time}
              />
              {activitySelectedKey &&
                !["rehabilitation", "treatment", "supplement"].includes(
                  activitySelectedKey
                ) && (
                  <Controller
                    render={(props) => (
                      <SpSelect
                        label={labels.patient.patientPlan.selectSubactivity}
                        value={props.value}
                        formControlWidth={"100%"}
                        selectPlaceholder={
                          labels.patient.patientPlan.selectSubactivity
                        }
                        onChange={(e) => {
                          setValue("id_subactivity", e.target.value);
                          props.onChange(e.target.value);
                        }}
                      >
                        {subactivities.map(({ id, name }) => (
                          <SpSelectMenuItem key={id} value={id}>
                            {name}
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    defaultValue={
                      subactivities?.find(
                        ({ id }) => id == event?.id_subactivity
                      )?.id
                    }
                    name={`id_subactivity`}
                    control={control}
                  />
                )}
            </Grid>
          </Grid>
          {event.data &&
            renderInputDetails(
              isDisabled,
              event,
              loadParametersData,
              loadParametersList,
              setLoadParametersList,
              control,
              setValue,
              register,
              handleSubmit,
              updateActivityFeedback,
              allowFeedback,
              isGroupCard,
              handleSync,
              props.snackbarShowErrorMessage,
              props.snackbarErrorMessage,
              isPrint,
              subactivities,
              getValues
            )}
          {renderProfessionalAlert}
          <Grid
            item
            xs={12}
            container
            direction="column"
            style={{ width: "100%", marginTop: "1%" }}
          >
            {!(isDisabled() && !event?.event_note) && (
              <Grid item xs={12}>
                <SpText variant="text">
                  {labels.patient.patientPlan.activityDetailDialog.note + ": "}
                </SpText>
              </Grid>
            )}
            <Grid item xs={12}>
              {isDisabled() ? (
                <SpText variant="h4ComponentLabel">{event?.event_note}</SpText>
              ) : (
                <SpTextArea
                  formControlStyle={{ width: "100% !important" }}
                  textAlign="left"
                  variant="text"
                  defaultValue={event.event_note}
                  inputRef={register}
                  name={"note"}
                  isPrint={isPrint}
                />
              )}
            </Grid>
          </Grid>
          {/* I pulsanti non devono essere visti nel PDF */}
          {!isPrint && (
            <>
              {!isDisabled() ? (
                <Grid item xs={12} container direction="row">
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                      marginTop: "2%",
                    }}
                    direction="row"
                  >
                    <SpButton
                      id="updateActivityBtn"
                      buttonType="accept"
                      style={{ flex: "auto" }}
                      text={
                        labels.patient.patientPlan.activityDetailDialog
                          .updateActivityButton
                      }
                      onClick={handleSubmit(updateActivityContents)}
                    />
                    {archiveEnabled && (
                      <SpButton
                        id="updateArchiveActivityBtn"
                        buttonType="accept"
                        style={{ flex: "auto" }}
                        text={
                          labels.patient.patientPlan.activityDetailDialog
                            .archiveActivityButton
                        }
                        onClick={handleSubmit(updateAndArchiveActivity)}
                      />
                    )}

                    <SpButton
                      id="deleteActivityBtn"
                      buttonType="cancel"
                      style={{ flex: "auto" }}
                      text={
                        labels.patient.patientPlan.activityDetailDialog
                          .openDeleteButtonActivity
                      }
                      variant="h1PageTitle"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDeleteActivity(true);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container item xs={12} direction="row">
                  <Grid item xs={9}>
                    <SpText variant="text" style={{ marginTop: 5 }}>
                      {
                        labels.patient.patientPlan.activityDetailDialog
                          .archivedAct
                      }
                    </SpText>
                  </Grid>
                  <Grid item xs={3}>
                    <SpButton
                      buttonType="accept"
                      style={{ flex: "auto" }}
                      text={
                        labels.patient.patientPlan.activityDetailDialog
                          .reopenButton
                      }
                      onClick={handleSubmit(reopenActivity)}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <SpDialog
          open={openDeleteActivity}
          setOpen={setOpenDeleteActivity}
          title={labels.calendar.eventDetailDialog.title}
        >
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <SpText variant="h4ComponentLabel">
                {labels.calendar.eventDetailDialog.deleteMessage}
              </SpText>
            </Grid>
            {/* I pulsanti non devono essere visti nel PDF */}
            {!isPrint && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <SpButton
                    id="deleteActivityYesBtn"
                    buttonType="cancel"
                    text={
                      labels.patient.patientPlan.activityDetailDialog.confirm
                    }
                    variant="h1PageTitle"
                    onClick={(e) => deleteCalendarActivityFunction(e)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    id="deleteActivityNoBtn"
                    buttonType="accept"
                    text={labels.patient.patientPlan.activityDetailDialog.deny}
                    variant="h1PageTitle"
                    onClick={() => setOpenDeleteActivity(false)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </SpDialog>
      </div>
    </printContext.Provider>
  );
};

export default withSnackbar(AgendaCalendarEventCard);
