import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import { styled } from "../../../../components/styled";
import { labels, psTranslate } from "../../../shared/translations";
import { theme } from "../../../../components/theme";
import ExerciseSheetRows from "./ExerciseSheetRows";
import SpLoader from "../../../../components/atoms/SpLoader";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import SpButton from "../../../../components/atoms/SpButton";
import SpDialog from "../../../../components/atoms/SpDialog";
import { Controller, useForm } from "react-hook-form";
import ExerciseDetails from "./ExerciseDetails";
import {
  deleteExercise,
  duplicateExerciseTemplate,
  getExerciseTemplates,
} from "../../../../models/actions/Excercise";
import {
  SpTable,
  SpTableCell,
  SpTableRow,
} from "../../../../components/bundles";
import { titleCase } from "../../../../utils/common";
import { TableCell } from "@material-ui/core";
import { getProfessionalByEmail } from "../../../../models/actions/Professionals";
import AddExercise from "../Rows/ExerciseAdd";
import { downloadPDFExercise } from "./ExerciseDetailsHelper/ExerciseCommonFunction";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const ExerciseList = (props) => {
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [usersFound, setUsersFound] = useState([]);
  const history = useHistory();

  const searchProfessionals = () => {
    if (searchString) {
      try {
        getProfessionalByEmail({ email: searchString }).then((response) => {
          if (response.length > 0) {
            setUsersFound(response);
          } else {
            setUsersFound([]);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("SearchProfessionals", error);
      }
    }
  };

  const shareTemplate = async ({ id_professional, id_template }) => {
    let body = {
      id_template: id_template,
    };
    if (id_professional) body["id_professional"] = id_professional;
    const ret = await duplicateExerciseTemplate(body);
    if (ret.message) {
      props.snackbarShowMessage(ret.message);
    } else {
      props.snackbarShowErrorMessage(ret.error);
    }
    fetchData();
  };

  const MyLabTableRow = ({ row, index }) => {
    return (
      <SpTableRow key={row.id} role="checkbox" id={row.id} tabIndex={index}>
        <SpTableCell>
          <SpText variant="tableText">
            {titleCase(psTranslate(row.name))}
          </SpText>
        </SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
            maxWidth: "512px",
          }}
        >
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <SpButton
                variant={"standardMax"}
                type="tableList"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  setSelectedRow(row);
                  setUpdateDialog(true);
                }}
              />
            </Grid>
            {
              <>
                <Grid item xs={2}>
                  <SpButton
                    variant={"standardOppositeMax"}
                    type="tableList"
                    text={labels.mylab.tempList.action.clone}
                    onClick={async () => {
                      await shareTemplate({ id_template: row.id });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SpButton
                    variant={"standardMax"}
                    type="tableList"
                    text={labels.mylab.tempList.action.share}
                    onClick={() => {
                      setSelectedRow(row);
                      setShareDialog(true);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SpButton
                    variant={"standardOppositeMax"}
                    type="tableList"
                    text={labels.mylab.tempList.action.delete}
                    onClick={() => {
                      setSelectedRow(row);
                      setDeleteDialog(true);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SpButton
                    variant={"standardMax"}
                    type="tableList"
                    text={"Download"}
                    onClick={async () => {
                      const uniqueExercises = [];
                      const exerciseIds = new Set();

                      if (row) {
                        row?.details.forEach((group) => {
                          group.exercises.forEach((exercise) => {
                            if (!exerciseIds.has(exercise.id_excercise)) {
                              exerciseIds.add(exercise.id_excercise);
                              uniqueExercises.push(exercise);
                            }
                          });
                        });
                        const temp = row?.details.map((item, index) => {
                          return { ...item };
                        });
                        const tempScheda = row?.details.reduce(
                          (acc, item, index) => {
                            acc[index + 1] = item.exercises;
                            return acc;
                          },
                          {}
                        );

                        await downloadPDFExercise({
                          rows: uniqueExercises,
                          name: row?.name,
                          schedaEsercizi: tempScheda,
                          exerciseList: temp,
                        });
                      }
                    }}
                  />
                </Grid>
              </>
            }
          </Grid>
        </TableCell>
      </SpTableRow>
    );
  };

  const removeTemplate = async () => {
    const res = await deleteExercise({ id_exercise: selectedRow?.id });
    if (res?.message) {
      props.snackbarShowMessage(res.message);
    } else {
      props.snackbarShowErrorMessage(res.error);
    }
    setDeleteDialog(false);
    await fetchData();
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.name,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.actions,
      isAction: true,
    },
  ];

  const fetchData = async () => {
    const temp = await getExerciseTemplates();
    setExerciseList(temp);
  };

  const StyledWrapperPatientsList = styled(Grid)({
    background: "transparent",
    border: `1px solid ${theme.colors.primary.lightBlue}`,
    marginTop: "2em",
    padding: "3%",
    paddingTop: "4em",
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"Esercizi"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
        {exerciseList.length > 0 && (
          <Grid item xs={12}>
            <SpTable
              pagination={true}
              headCells={headCells}
              rows={exerciseList}
              rowKey="id"
              padding={false}
              notCheckable={false}
            >
              <MyLabTableRow />
            </SpTable>
          </Grid>
        )}
      </Grid>

      <SpDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        title={labels.mylab.exercise.deleteTemplate}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <SpText variant="h2" style={{ color: "white" }}>
              {"Template: "}
              {selectedRow?.name}
            </SpText>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            style={{ marginBottom: "10px" }}
          >
            <Grid item container xs={12} alignItems={"flex-start"}>
              <Grid item xs={4}>
                <SpButton
                  text={labels.mylab.dialog.delete.yes}
                  onClick={() => removeTemplate()}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: "1%" }}>
                <SpButton
                  text={labels.mylab.dialog.delete.no}
                  buttonType="accept"
                  onClick={() => setDeleteDialog(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>

      <SpDialog
        open={shareDialog}
        setOpen={setShareDialog}
        title={labels.mylab.exercise.deleteTemplate}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="row"
          container
          spacing={2}
        >
          <SpTextInput
            value={searchString}
            label={
              labels.groups.groupAdd.addProfessionalTable.dialog.searchText
            }
            style={{ marginLeft: "10px" }}
            onChange={(e) => setSearchString(e.currentTarget.value)}
          />
          <SpButton
            text={labels.patient.patientLink.inputSearch.buttons.search}
            style={{ marginLeft: "10px", marginTop: "1.3em" }}
            variant="none"
            buttonType="accept"
            onClick={() => searchProfessionals()}
          />
          <StyledWrapperPatientsList
            item
            container
            direction="column"
            xs={12}
            style={{ marginBottom: "2%", marginLeft: "10px" }}
          >
            {usersFound ? (
              usersFound.map((professional) => (
                <Grid
                  key={professional.id}
                  item
                  container
                  direction={"row"}
                  xs={12}
                  style={{ margin: "2%" }}
                >
                  <Grid item xs={6} alignSelf={"center"}>
                    <SpText variant="text">{`${professional.name} ${professional.surname} - ${professional.email}`}</SpText>
                  </Grid>
                  <Grid item xs={6}>
                    <SpButton
                      text={
                        labels.groups.groupAdd.addProfessionalTable.dialog
                          .addProfessionalButton
                      }
                      buttonType="accept"
                      variant="none"
                      style={{ width: "90%" }}
                      onClick={() =>
                        shareTemplate({
                          id_professional: professional.id,
                          id_template: selectedRow.id,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <SpText variant={"text"}>
                {
                  labels.groups.groupAdd.addProfessionalTable.dialog
                    .usersFoundPlaceholder
                }
              </SpText>
            )}
          </StyledWrapperPatientsList>
        </Grid>
      </SpDialog>

      <SpDialog
        open={updateDialog}
        setOpen={setUpdateDialog}
        title={""}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="row"
          container
          spacing={2}
        >
          <AddExercise {...props} exercise={selectedRow} />
        </Grid>
      </SpDialog>
    </Grid>
  );
};
export default withSnackbar(ExerciseList);
