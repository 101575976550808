import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels, psTranslate } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import SupplementRows from "../../mylab/MyLabAddTemp/Rows/SupplementRows";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";

const SupplementDetails = ({
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  control,
  setValue,
  getValues,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  event,
  allowFeedback,
  isGroupCard,
  subactivities,
  snackbarShowErrorMessage,
  snackbarShowMessage,
  isPrint = false,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    setLoadParametersList(loadParametersData);
  }, [loadParametersData]);

  const {
    fields: fieldsSupplement,
    append: appendSupplement,
    remove: removeSupplement,
  } = useFieldArray({
    control,
    name: "supplements",
    keyName: "id",
    defaultValues: [],
  });

  useEffect(() => {
    if (templateData) {
      setValue("supplement_motivation", templateData.supplement_motivation);
      setValue("quantity_supplement", templateData.supplements.length);
      appendSupplement(templateData.supplements);
    }
  }, [templateData]);

  const fieldTrainingSupplements = useWatch({
    name: "supplements",
    control: control,
  });

  const RenderRehabMinutesCount = ({ rows }) => {
    if (rows) {
      const minutesSum = rows.reduce(
        (prev, cur) => prev + (cur.duration ? parseInt(cur.duration) : 0),
        0
      );
      return (
        <SpText
          variant="h1PageSubtitleColored"
          style={{ display: "inline-block", padding: "1em" }}
        >
          {isNaN(minutesSum) ? 0 : minutesSum}{" "}
          {labels.agenda.inputDetails.totalMins}
        </SpText>
      );
    }
    return <></>;
  };

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="supplement_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeSupplement(fieldsSupplement.length - 1);
              if (fieldsSupplement.length > 0)
                setValue("quantity_supplement", fieldsSupplement.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            name="quantity_supplement"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.treatment.inputs.quantity}
            disabled={disabled}
            isPrint={isPrint}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendSupplement({ id: fieldsSupplement.length });
              setValue("quantity_supplement", fieldsSupplement.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>

      {fieldsSupplement.length > 0 && !showFeedback && subactivities && (
        <Grid container spacing={2}>
          <RenderRehabMinutesCount rows={fieldTrainingSupplements} />
          {fieldsSupplement.map((item) => {
            return (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={3} style={{ paddingTop: "1.2em" }}>
                  <SpAutocomplete
                    formControlWidth={"100%"}
                    disableListWrap
                    selectPlaceholder={"attività"}
                    value={
                      subactivities?.find(
                        (el) =>
                          el.key ===
                          getValues(`supplements[${item.id}].activity`)
                      ) ?? null
                    }
                    onChange={(e, newValue) => {
                      setValue(
                        `supplements[${item.id}].activity`,
                        newValue.key
                      );
                    }}
                    options={subactivities}
                    getOptionLabel={(option) => psTranslate(option.name)}
                    getOptionSelected={(option, value) =>
                      option.key === value?.key
                    }
                    // filterOptions={filterOptions}
                    renderOption={(option) => (
                      <Grid
                        direction="row"
                        container
                        alignItems={"center"}
                        alignSelf={"center"}
                      >
                        <Grid item xs={11}>
                          <SpText variant="text">
                            {psTranslate(option.name)}
                          </SpText>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SpText variant="listHeader">
                    {labels.patient.monitoring.table.minutesNumber}
                  </SpText>
                  <SpTextInput
                    isPrint={isPrint}
                    type="number"
                    minValue={0}
                    style={{ width: "100%" }}
                    onChange={(evnt) =>
                      setValue(
                        `supplements[${item.id}].duration`,
                        evnt.target.value
                      )
                    }
                    value={getValues(`supplements[${item.id}].duration`)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SpText variant="listHeader">
                    {labels.dashboard.operations.table.header.description}
                  </SpText>
                  <SpTextInput
                    isPrint={isPrint}
                    style={{ width: "100%" }}
                    onChange={(evnt) =>
                      setValue(
                        `supplements[${item.id}].description`,
                        evnt.target.value
                      )
                    }
                    value={getValues(`supplements[${item.id}].description`)}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}

      {allowFeedback && (
        <Grid item xs={12} style={{ paddingTop: "1em" }}>
          <SpButton
            style={{ width: "100%" }}
            text={
              showFeedback
                ? labels.patient.viewPlan.addActivity.inputForm.hideFeedbacks
                : labels.patient.viewPlan.addActivity.inputForm.showFeedbacks
            }
            onClick={() => setShowFeedback(!showFeedback)}
          />
        </Grid>
      )}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default SupplementDetails;
