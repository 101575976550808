import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../../shared/translations";
import SpCheckbox from "../../../../components/atoms/SpCheckbox";
import SpText from "../../../../components/atoms/SpText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { WorkloadChecks } from "./WorkloadChecks";
import { PromsChecks } from "./PromsChecks";
import { TestsChecks } from "./TestsChecks";
import { theme } from "../../../../components/theme";

const WORKLOAD = "workload";
const PROMS = "proms";
const TEST = "test";

const checkFilters = [
  { key: WORKLOAD, label: "Workload" },
  { key: PROMS, label: "PROMS" },
  { key: TEST, label: "Test" },
];

export const AdvancedFilters = ({ filters, setFilters }) => {
  const [currentFilters, setCurrentFilters] = useState(WORKLOAD);

  const AdvancedFilter = () => {
    return checkFilters.map(({ label, key }) => (
      <div
        onClick={(e) => {
          setCurrentFilters(key);
        }}
      >
        <SpText variant={currentFilters === key ? "h1PageTitle" : "textWhite"}>
          {`${label} `}
          <ArrowForwardIosIcon
            style={{
              width: currentFilters === key ? 25 : 15,
              color: theme.colors.primary.lightBlue,
              height: currentFilters === key ? 20 : 10,
              cursor: "pointer",
            }}
          />
        </SpText>
      </div>
    ));
  };

  return (
    <Grid container xs={12} direction="row">
      <Grid container xs={3} direction="row">
        <Grid container xs={12} direction="column">
          <AdvancedFilter />
        </Grid>
      </Grid>

      <Grid container xs={9} direction="row">
        {currentFilters === WORKLOAD && (
          <WorkloadChecks filters={filters} setFilters={setFilters} />
        )}
        {currentFilters === PROMS && (
          <PromsChecks filters={filters} setFilters={setFilters} />
        )}
        {currentFilters === TEST && (
          <TestsChecks filters={filters} setFilters={setFilters} />
        )}
      </Grid>
    </Grid>
  );
};
