/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";
import { getPatientInjury } from "../../patients/patientsReports/patientsReportInjury/InjuryReportCommonFunction";
import { labels } from "../../shared/translations";
import TrainingReport from "./plot_expo.jsx";
import TrainingReportA from "./plot_ava.jsx";
import { selectGroupFilteredByMonth } from "../../patients/patientsReports/patientsReportInjury/ExposureFunction";
import { groupAbsenseByMonth } from "../../patients/patientsReports/patientsReportInjury/AvailabilityFunction";
import TrainingReportRatio from "./plot_expo_ratio.jsx";
import { Cards_ } from "./Cards_.jsx";
import { getPatientDisponibility } from "../../../models/actions/Disponibility";

export const trainingLabel = labels.analytics.injuryReport.graphs.training;
export const partitaLabel = labels.analytics.injuryReport.graphs.partita;
const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;

const PatientsReportsStep5Exposure = ({ dateRangeGen }, props) => {
  const [dateRange, setDateRange] = useState(dateRangeGen);
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [dataChartTraining, setDataChartTraining] = useState({});
  const [dataChartMonth, setDataChartMonth] = useState({});
  const [dataChartTrainingPatients, setDataChartTrainingPatients] = useState(
    []
  );

  const { groupId } = useParams();
  const [loading, setLoading] = useState(false);

  // Effect to update the date range if dateRangeGen prop changes
  useEffect(() => {
    setDateRange(dateRangeGen);
  }, [dateRangeGen]);

  // get groups data
  useEffect(async () => {
    setLoading(true);
    setDateRange(dateRangeGen);
    try {
      let tempGroups = await listGroupsByProfessional();
      tempGroups = tempGroups.map(({ group }) => group);
      const groupsSelected = tempGroups.find(
        ({ id }) => parseInt(id) === parseInt(groupId)
      );
      const injuryD = await getPatientInjury(tempGroups, dateRange);
      const injurydata = [];
      for (let p of injuryD[0].patient) {
        injurydata.push(p?.patients);
      }
      const dispon = await getPatientDisponibility({
        id_patients: groupsSelected?.patients.map(({ id }) => id),
        start_date: dateRange.start,
        end_date: dateRange.end,
      });
      await selectExposureActivity(
        [groupsSelected],
        dateRange,
        injurydata,
        dispon,
        dateRange
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error.message);
    }
  }, [dateRange]);

  /**
   * Funzione che filtra le attivita' legate all'esposizione
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param dateRangeGen: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param tempPatients: patients
   * @param tempGroups
   */
  const selectExposureActivity = async (
    mapGroup,
    dateRangeGen,
    tempGroups,
    tempDisponibilityPatients,
    dateRange
  ) => {
    let temp = [];
    setLoading(true);

    for (let temppatient of tempGroups) {
      temppatient["injuries"] = temppatient?.injuries?.filter((injury) => {
        let end_date;
        if (injury?.end_date === null) {
          end_date = moment().format("YYYY-MM-DD");
        } else {
          end_date = moment(injury?.end_date).format("YYYY-MM-DD");
        }
        let start_date;
        if (injury?.start_date === null) {
          start_date = moment().format("YYYY-MM-DD");
        } else {
          start_date = moment(injury?.start_date).format("YYYY-MM-DD");
          start_date = moment(injury?.start_date).format("YYYY-MM-DD");
        }
        return (
          moment(end_date).isSame(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          (moment(end_date).isBefore(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) &&
            (moment(end_date).isAfter(
              moment(dateRange?.start).format("YYYY-MM-DD")
            ) ||
              moment(end_date).isSame(
                moment(dateRange?.start).format("YYYY-MM-DD")
              ))) ||
          moment(start_date).isSame(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) ||
          (moment(start_date).isAfter(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) &&
            (moment(start_date).isBefore(
              moment(dateRange?.end).format("YYYY-MM-DD")
            ) ||
              moment(start_date).isSame(
                moment(dateRange?.end).format("YYYY-MM-DD")
              )))
        );
      });
    }
    setLoading(true);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        start_date: moment(dateRange?.start).format("YYYY-MM-DD"),
        end_date: moment(dateRange?.end).format("YYYY-MM-DD"),
        id_group: tempGroup.id,
      });
      let item = {};
      item["idGruppo"] = tempGroup.id;
      item["nameGruppo"] = tempGroup.name;
      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > dateRangeGen.start &&
          moment(item.start_date) < dateRangeGen.end
      );

      item[trainingLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "training"
      );

      item[partitaLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "race"
      );
      item["patients"] = tempGroup?.patients;

      temp.push(item);
    }
    setTrainingGroups(temp);
    await setDataByMonthFunctionGroup(
      mapGroup,
      temp,
      tempDisponibilityPatients
    );
    const selectedGroup = temp.find(
      ({ idGruppo }) => parseInt(idGruppo) === parseInt(groupId)
    );

    const { patAva, resultTraining2 } = selectionActionFunction({
      tempTrainingGroups: temp,
      tempDisponibilityPatients: tempDisponibilityPatients,
      groupsSelected: selectedGroup,
      dateRange: dateRange,
    });

    setDataChartTrainingPatients(patAva);
    setDataChartTraining(resultTraining2);

    setLoading(false);
  };

  // funzione che raggruppa dati per mese
  const setDataByMonthFunctionGroup = (
    group,
    tempTrainingGroups = trainingGroups,
    disponibilityPatients
  ) => {
    return new Promise((resolve, reject) => {
      try {
        let result = selectGroupFilteredByMonth(
          group,
          dateRange,
          tempTrainingGroups,
          disponibilityPatients
        );
        setDataChartMonth(result);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  const selectionActionFunction = ({
    tempTrainingGroups,
    tempDisponibilityPatients,
    groupsSelected,
    dateRange,
  }) => {
    let date_start = moment(dateRange?.start).format("YYYY-MM-DD");
    let date_end = moment(dateRange?.end).format("YYYY-MM-DD");

    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = tempTrainingGroups[0]?.patients;

    // Find matching training group
    let groupsMatch = groupsSelected[partitaLabel];
    let groupsTrain = groupsSelected[trainingLabel];

    let nPat = groupsSelected?.patients.length || 0;

    let trainLen = 0;
    for (let tr in groupsTrain) {
      if (groupsTrain.hasOwnProperty(tr)) {
        const dateStartFormatted = moment(dateRange.start).format("YYYY-MM-DD");
        const dateEndFormatted = moment(dateRange.end).format("YYYY-MM-DD");
        const dateStartRangeFormatted = moment(date_start).format("YYYY-MM-DD");

        if (
          dateStartFormatted === dateEndFormatted ||
          dateStartFormatted === dateStartRangeFormatted ||
          (moment(dateRange.start).isBefore(date_end, "day") &&
            moment(dateRange.start).isAfter(date_start, "day"))
        ) {
          for (let p in groupsTrain[tr]?.patients) {
            const patient = groupsTrain[tr].patients[p];
            if (
              patient &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(patient.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              trainLen += 1;
            }
          }
        }
      }
    }

    let MatchLen = 0;
    for (let tr in groupsMatch) {
      if (groupsMatch.hasOwnProperty(tr)) {
        const dateStartFormatted = moment(dateRange.start).format("YYYY-MM-DD");
        const dateEndFormatted = moment(date_end).format("YYYY-MM-DD");
        const dateStartRangeFormatted = moment(date_start).format("YYYY-MM-DD");

        if (
          dateStartFormatted === dateEndFormatted ||
          dateStartFormatted === dateStartRangeFormatted ||
          (moment(dateRange.start).isBefore(date_end, "day") &&
            moment(dateRange.start).isAfter(date_start, "day"))
        ) {
          for (let p in groupsTrain[tr]?.patients) {
            const patient = groupsTrain[tr].patients[p];
            if (
              patient &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(patient.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              MatchLen += 1;
            }
          }
        }
      }
    }

    let patAva = [];
    for (let pat of newPatientsSelected) {
      let trainLen = 0;
      let trainLenPat = 0;
      for (let tr in groupsTrain) {
        if (groupsTrain.hasOwnProperty(tr)) {
          const dateStartFormatted = moment(dateRange.start).format(
            "YYYY-MM-DD"
          );
          const dateEndFormatted = moment(date_end).format("YYYY-MM-DD");
          const dateStartRangeFormatted =
            moment(date_start).format("YYYY-MM-DD");

          if (
            dateStartFormatted === dateEndFormatted ||
            dateStartFormatted === dateStartRangeFormatted ||
            (moment(dateRange.start).isBefore(date_end, "day") &&
              moment(dateRange.start).isAfter(date_start, "day"))
          ) {
            if (
              pat &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(pat?.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              trainLen += 1;
              let patient = groupsTrain[tr]?.patients.find(
                ({ id }) => parseInt(id) === parseInt(pat?.id)
              );
              trainLenPat += patient ? 1 : 0;
            }
          }
        }
      }

      let MatchLen = 0;
      let MatchLenPat = 0;
      for (let tr in groupsMatch) {
        if (groupsMatch.hasOwnProperty(tr)) {
          const dateStartFormatted = moment(dateRange.start).format(
            "YYYY-MM-DD"
          );
          const dateEndFormatted = moment(date_end).format("YYYY-MM-DD");
          const dateStartRangeFormatted =
            moment(date_start).format("YYYY-MM-DD");

          if (
            dateStartFormatted === dateEndFormatted ||
            dateStartFormatted === dateStartRangeFormatted ||
            (moment(dateRange.start).isBefore(date_end, "day") &&
              moment(dateRange.start).isAfter(date_start, "day"))
          ) {
            if (
              pat &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(pat?.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              MatchLen += 1;
              let patient = groupsMatch[tr]?.patients.find(
                ({ id }) => parseInt(id) === parseInt(pat?.id)
              );
              MatchLenPat += patient ? 1 : 0;
            }
          }
        }
      }

      if (trainLenPat / MatchLenPat > 0) {
        patAva.push({
          patientName: pat?.givenName + " " + pat?.familyName,
          [trainingLabel]: trainLenPat,
          [trainingLabel + "_ava"]: parseFloat(
            (trainLenPat * 100) / (groupsTrain.length || 1)
          ).toFixed(1),
          [partitaLabel]: MatchLenPat,
          [partitaLabel + "_ava"]: parseFloat(
            (MatchLenPat * 100) / (groupsMatch.length || 1)
          ).toFixed(1),
          ratio: trainLenPat / MatchLenPat,
        });
      }
    }

    let resultTraining2 = {
      [trainingLabel]: groupsTrain.length,
      [partitaLabel]: groupsMatch.length,
      [trainingLabel + " + " + partitaLabel]:
        groupsTrain.length + groupsMatch.length,
      [trainingLabel + "_ava"]: parseFloat(
        (trainLen * 100) / (nPat * groupsTrain.length || 1)
      ).toFixed(1), // Avoid division by zero
      [partitaLabel + "_ava"]: parseFloat(
        (MatchLen * 100) / (nPat * groupsMatch.length || 1)
      ).toFixed(1), // Avoid division by zero
      [trainingLabel + " + " + partitaLabel + "_ava"]: parseFloat(
        ((trainLen + MatchLen) * 100) /
          (nPat * groupsTrain.length + nPat * groupsMatch.length || 1)
      ).toFixed(1), // Avoid division by zero
    };

    return { patAva, resultTraining2 };
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <Cards_ dataChartTraining={dataChartTraining} />
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico esposizione */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
              {labels.injSur.groupExpM}
            </SpText>
          </Grid>
          <TrainingReport
            dataChart={dataChartMonth}
            labT={trainingLabel + "_n"}
            labM={partitaLabel + "_n"}
            type="Area"
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico esposizione ratio */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
              {labels.injSur.groupRatioM}
            </SpText>
          </Grid>
          <TrainingReportRatio
            dataChart={dataChartMonth}
            labR={ratioTMLabel}
            type="Area"
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico disponibilità */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.injSur.groupDispM}
            </SpText>
          </Grid>
          <TrainingReportA
            dataChartT={dataChartMonth}
            labT={trainingLabel}
            labM={partitaLabel}
            monthly={true}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico esposizione Pazienti */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
              {labels.injSur.athExp}
            </SpText>
          </Grid>
          <TrainingReport
            dataChart={dataChartTrainingPatients}
            labT={trainingLabel}
            labM={partitaLabel}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico esposizione Pazienti */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
              {labels.injSur.athRatio}
            </SpText>
          </Grid>
          <TrainingReportRatio
            dataChart={dataChartTrainingPatients}
            labT={trainingLabel}
            labM={partitaLabel}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico disponibilità Pazienti */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
              {labels.injSur.athDisp}
            </SpText>
          </Grid>
          <TrainingReportA
            dataChartT={dataChartTrainingPatients}
            dataChartM={dataChartTraining}
            labT={trainingLabel}
            labM={partitaLabel}
            monthly={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
