import React, { useEffect, useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SpText from "../../../components/atoms/SpText";
import SpLoader from "../../../components/atoms/SpLoader";
import { FilterAutocomplete } from "../../patients/patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Chip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import moment from "moment";
import {
  getProfessionalSchedule,
  updateProfessionalSchedule,
} from "../../../models/actions/Organization";
import { generateSlotsForDay, Schedule } from "./OrganizationHelper";
const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const OrganizationDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [schedule, setSchedule] = useState({
    schedule: {},
    unavailableDays: [],
  });
  const [slotTime, setSlotTime] = useState(30);
  const [newDate, setNewDate] = useState();

  const history = useHistory();

  const CustomAccordion = styled(Accordion)({
    textAlign: "center",
    maxWidth: "100%",
    marginTop: "1%",
    marginBottom: "1%",
    borderRadius: 0,
    color: "white",
    borderColor: theme.colors.primary.lightBlue,
    background: "transparent",
    border: "1px solid",
  });

  const handleDaySelection = (newSelectedDays) => {
    setSelectedDays(newSelectedDays);

    labels.week
      .filter(({ key }) => !newSelectedDays.map(({ key }) => key).includes(key))
      .map(({ key }) => {
        delete schedule.schedule[key];
      });

    newSelectedDays.forEach(({ key }) => {
      if (!schedule[key]) {
        addSlotsForDay(key);
      }
    });
  };

  const addSlotsForDay = (day) => {
    let newSched = {
      ...schedule,
      schedule: {
        ...schedule.schedule,
        [day]: generateSlotsForDay(slotTime, true, true),
      },
    };
    setSchedule(newSched);
  };

  const fetchData = async () => {
    let ret = await getProfessionalSchedule({});
    if (!ret.unavailableDays) ret.unavailableDays = [];
    setSelectedDays(
      labels.week.filter(({ key }) => Object.keys(ret.schedule).includes(key))
    );
    setSchedule(ret);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"Schedule Manager"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>

      <div style={{ padding: 20 }}>
        {selectedDays.map(({ key, name }) => {
          return (
            <CustomAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id={1}
              >
                <SpText variant="h4ComponentLabel">{name}</SpText>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      {`${name} ${labels.general.morning}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      {`${name} ${labels.general.afternoon}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {(schedule?.schedule[key] || []).map((slot, index) => {
                      if (
                        moment(slot.startTime, "HH:mm").isBefore(
                          moment("13:00", "HH:mm")
                        ) ||
                        moment(slot.startTime, "HH:mm").isSame(
                          moment("13:00", "HH:mm"),
                          "hour"
                        )
                      )
                        return <Schedule slot={slot} index={index} />;
                    })}
                  </Grid>
                  <Grid item xs={6}>
                    {(schedule?.schedule[key] || []).map((slot, index) => {
                      if (
                        moment(slot.startTime, "HH:mm").isAfter(
                          moment("13:00", "HH:mm")
                        )
                      )
                        return <Schedule slot={slot} index={index} />;
                    })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          );
        })}
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={3}>
            <SpTextInput
              name="slot_time"
              label={labels.requestsList.unavailablesDays}
              type="date"
              maxValue="100"
              minValue={moment().format("YYYY-MM-DD")}
              defaultValue={moment().format("YYYY-MM-DD")}
              variant="text"
              style={{ width: "100%" }}
              onChange={(e) => setNewDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingTop: "22px" }}>
            <SpButton
              type="submit"
              style={{ height: 40 }}
              buttonType={"accept"}
              variant="none"
              onClick={async () => {
                let newSched = JSON.parse(JSON.stringify(schedule));
                if (!newSched.unavailableDays) newSched.unavailableDays = [];
                newSched.unavailableDays.push(newDate);
                setSchedule(newSched);
              }}
              text={labels.patient.medicalNotes.report.addButton}
            />
          </Grid>
          <Grid item style={{ paddingTop: "22px" }}>
            {schedule?.unavailableDays?.map((option, index) => (
              <Chip
                key={`date_${index}`}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: "white",
                }}
                deleteIcon={<CancelIcon style={{ color: "white" }} />}
                onDelete={() => {
                  let newSched = JSON.parse(JSON.stringify(schedule));
                  newSched.unavailableDays = newSched.unavailableDays.filter(
                    (day) => !moment(day).isSame(option)
                  );
                  setSchedule(newSched);
                }}
                label={psTranslate(`${moment(option).format("DD/MM/YYYY")}`)}
                size="medium"
              />
            ))}
          </Grid>
        </Grid>
        <SpButton
          type="submit"
          style={{ height: 20 }}
          buttonType={"accept"}
          variant="none"
          onClick={async () => {
            setLoading(true);
            const ret = await updateProfessionalSchedule({
              data: {
                ...schedule,
              },
            });
            if (ret?.message) {
              props.snackbarShowMessage(ret.message);
            }
            if (ret?.error) {
              props.snackbarShowErrorMessage(ret.error);
            }

            setLoading(false);
          }}
          text={labels.groups.groupDetail.actions.save}
        />
      </div>
    </Grid>
  );
};
export default withSnackbar(OrganizationDetails);
