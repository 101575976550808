import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { isMobile } from "react-device-detect";
import PatientsMonitoringAddPathology from "./patientsMonitoringStepPathologies/PatientsMonitoringAddPathology";
import PatientsMonitoringDetailPathology from "./patientsMonitoringStepPathologies/PatientsMonitoringDetailPathology";
import { getPathologiesByMonitoring } from "../../../models/actions/Pathologies";
import moment, { locale } from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import NoData from "../shared/utils/NoData";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserPermission } from "../../../utils/common";
import { Controller, useForm, useFieldArray } from "react-hook-form";

locale("it");

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
});

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "33%",
  flexDirection: "column",
});

const PatientsMonitoringStepPathologies = (props) => {
  const [addIllnessPermission, setAddIllnessPermission] = useState();
  const [displayPathology, setDisplayPathology] = useState([]);
  const [addPresentationPathology, setAddPresentationPathology] =
    useState(false);
  const [detailPresentationPathology, setDetailPresentationPathology] =
    useState(false);
  const [pathologyDetailData, setPathologyDetailData] = useState();
  const [selectedPathology, setSelectedPathology] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const { control } = useForm({
    shouldUnregister: false,
  });
  useEffect(() => {}, []);
  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { endDate } = props;
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);

      const tempPermission = await getUserPermission(
        "new-illness",
        getAccessTokenSilently
      );
      setAddIllnessPermission(tempPermission);

      let results = await getPathologiesByMonitoring({ id_patient: patId });
      if (results) {
        results.reverse();
        setDisplayPathology(results);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!addPresentationPathology) {
      fetchData();
    }
  }, [addPresentationPathology]);
  useEffect(() => {
    if (!detailPresentationPathology) {
      fetchData();
    }
  }, [detailPresentationPathology]);

  const closeAssessmentAdd = () => {
    setAddPresentationPathology(false);
    setDetailPresentationPathology(false);
  };
  const handlePathologyDetail = async (id_pathology) => {
    setSelectedPathology(id_pathology);
    setDetailPresentationPathology(true);
  };

  // Columns
  const headCellsPresentation = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableReason.toUpperCase(),
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.monitoring.pathologiesStep.table.toDate.toUpperCase(),
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.monitoring.pathologiesStep.table.fromDate.toUpperCase(),
    },
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.buyLicenseDialog.duration,
      isAction: false,
    },
  ];

  const _onRowClick = (path) => handlePathologyDetail(path);

  //rendering the profile editing form
  const PathologyRow = ({ row }) => {
    return (
      <>
        <SpTableRow
          tabIndex={-1}
          onClick={() => _onRowClick(row)}
          style={{ cursor: "pointer" }}
        >
          <SpTableCell>
            <SpText variant="h4ComponentLabel">
              {psTranslate(row?.pathology?.name)}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row.start_date || row.createdAt
                ? moment
                    .utc(row.start_date ? row.start_date : row.createdAt)
                    .format("DD/MM/yyyy")
                : ""}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row.end_date ? moment(row.end_date).format("DD/MM/yyyy") : ""}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row?.end_date
                ? parseInt(
                    moment
                      .duration(
                        moment(row.end_date).diff(
                          moment(
                            row.start_date ? row.start_date : row.createdAt
                          )
                        )
                      )
                      .asDays()
                  )
                : parseInt(
                    moment
                      .duration(
                        moment().diff(
                          moment(
                            row.start_date ? row.start_date : row.createdAt
                          )
                        )
                      )
                      .asDays()
                  )}
            </SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <StyledBodyColumn>
        <StyledBodyHalfColumnToolbar>
          <SpText variant="h1PageTitle">
            {labels.patient.monitoring.pathologiesStep.title.toUpperCase()}
          </SpText>
          {endDate &&
            (displayPathology.length > 0 ? (
              <SpText variant="h1PageSubtitle">
                {
                  labels.patient.presentation.detail.stepper.stepPathologies
                    .closedPresentation
                }
              </SpText>
            ) : (
              <SpText variant="h1PageSubtitle">
                {
                  labels.patient.presentation.detail.stepper.stepPathologies
                    .noData
                }
              </SpText>
            ))}
          {!endDate && !detailPresentationPathology && addIllnessPermission && (
            <SpButton
              buttonType="accept"
              text={
                addPresentationPathology
                  ? "CHIUDI"
                  : labels.patient.presentation.detail.stepper.stepPathologies.add.toUpperCase()
              }
              onClick={() =>
                setAddPresentationPathology(!addPresentationPathology)
              }
            />
          )}
          {detailPresentationPathology && (
            <SpButton
              buttonType="accept"
              variant="none"
              text={labels.patient.presentation.detail.stepper.stepPathologies.close.toUpperCase()}
              onClick={() => setDetailPresentationPathology(false)}
            />
          )}
        </StyledBodyHalfColumnToolbar>
        {displayPathology.length === 0 && (
          <NoData label={labels.patient.monitoring.noPathologies} />
        )}
        {!addPresentationPathology ? (
          displayPathology?.length > 0 &&
          !detailPresentationPathology && (
            <StyledTableColumn>
              <StyledRow>
                <SpTable
                  headCells={headCellsPresentation}
                  pagination={true}
                  rows={displayPathology}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                  tableContainerMaxHeight={
                    isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                  }
                >
                  <PathologyRow />
                </SpTable>
              </StyledRow>
            </StyledTableColumn>
          )
        ) : (
          <PatientsMonitoringAddPathology
            closeAssessmentAdd={closeAssessmentAdd}
            handlePathologyDetail={handlePathologyDetail}
            setLoading={setLoading}
          />
        )}
        {detailPresentationPathology && (
          <PatientsMonitoringDetailPathology
            closeAssessmentAdd={closeAssessmentAdd}
            pathology={selectedPathology}
            setLoading={setLoading}
            control={control}
          />
        )}
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PatientsMonitoringStepPathologies);
