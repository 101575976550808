import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";

import moment from "moment";
import { Grid } from "@material-ui/core";
import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";

import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientInjury,
  getPatientsSelection,
  getRange,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  selectedGroupsBurder,
  selectedGroupsDaysAbsence,
  selectedGroupsInjuryRate,
  selectPatientsDaysAbsence,
  selectPatientsInjuryBurden,
  selectPatientsInjuryRate,
} from "./patientsReportInjury/TrainingInjuryFunction";
import SpIconButton from "../../../components/atoms/SpIconButton";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { theme } from "../../../components/theme";
// set data range
const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";

const PatientsReportsStep7TrainingInjury = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barCharRate, setBarCharRate] = useState([]);
  const [barCharDays, setBarCharDays] = useState([]);
  const [barCharBurder, setBarCharBurder] = useState([]);
  const [dataChartRate, setDataChartRate] = useState({});
  const [dataChartBurder, setDataChartBurder] = useState({});
  const [dataChartDays, setDataChartDays] = useState({});

  const [fullSizeRatio, setFullSizeRatio] = useState(false);
  const [fullSizeDays, setFullSizeDays] = useState(false);
  const [fullSizeBurder, setFullSizeBurder] = useState(false);
  const [loading, setLoading] = useState(false);

  const { patId, groupId } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);

        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        let tempInjuryGroup = await getPatientInjury(
          tempGroupPatient,
          dateRange
        );

        for (let g = 0; g<tempGroupPatient.length; g++) {
          let groupP = tempGroupPatient[g]
          let groupInj = tempInjuryGroup.find(({ group }) => parseInt(group.id) === parseInt(groupP.id) )

          for (let p = 0; p < groupP?.patients.length; p++) {
            let pat = groupP?.patients[p];

            let patientId = parseInt(pat?.id);
            if (!isNaN(patientId)) {
                let inj_lst = groupInj?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
                if (inj_lst !== undefined) {
                  tempGroupPatient[g].patients[p].injuries = inj_lst;
                } 
            }
          }
        }

        setPatients(result.allPatients);
        setGroups(tempGroupPatient);
        setSelectedGroups(tempGroupPatient);
        await getPatientInjury(tempGroupPatient, dateRange);
        selectExposureActivity(tempGroupPatient, dateRange, result.allPatients);
      } else {
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );

        let tempInjuryGroup = await getPatientInjury(tempGroups, dateRange);

        let tempInjuryGroupI = []
        for (let g of tempInjuryGroup) {
          if ( parseInt(g?.group?.id) === parseInt(groupId) ) {
            tempInjuryGroupI.push(g)
          }
        }

        for (let p = 0; p < groupsSelected?.patients.length; p++) {
          let pat = groupsSelected?.patients[p];

          let patientId = parseInt(pat?.id);
          if (!isNaN(patientId)) {
              let inj_lst = tempInjuryGroupI[0]?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
              if (inj_lst !== undefined) {
                groupsSelected.patients[p].injuries = inj_lst;
              } 
          }
        }

        setSelectedGroups([groupsSelected]);

        await getPatientInjury(tempGroups, dateRange);
        selectExposureActivity([groupsSelected], dateRange, []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups
  ) => {
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );
    const resultInjuryGroupsRate = selectedGroupsInjuryRate(
      newSelectedGroups,
      props.type
    );
    const resultRate = selectPatientsInjuryRate(
      newPatientsSelected,
      newSelectedGroups,
      props.type
    );

    const resultInjuryGroupsDays = selectedGroupsDaysAbsence(
      newSelectedGroups,
      props.type
    );
    const resultDays = selectPatientsDaysAbsence(
      newPatientsSelected,
      props.type
    );

    const resultInjuryGroupsBurder = selectedGroupsBurder(
      newSelectedGroups,
      props.type
    );
    const resultBurder = selectPatientsInjuryBurden(
      newPatientsSelected,
      newSelectedGroups,
      props.type
    );

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    resultRate.dataChart =
      resultInjuryGroupsRate.dataChart !== undefined
        ? resultRate.dataChart.concat(resultInjuryGroupsRate.dataChart)
        : resultRate.dataChart;

    resultDays.dataChart =
      resultInjuryGroupsDays.dataChart !== undefined
        ? resultDays.dataChart.concat(resultInjuryGroupsDays.dataChart)
        : resultDays.dataChart;

    resultBurder.dataChart =
      resultInjuryGroupsBurder.dataChart !== undefined
        ? resultBurder.dataChart.concat(resultInjuryGroupsBurder.dataChart)
        : resultBurder.dataChart;

    setLoading(true);

    setDataChartRate(resultRate.dataChart);
    setBarCharRate(resultRate.barChart);

    setDataChartBurder(resultBurder.dataChart);
    setBarCharBurder(resultBurder.barChart);

    setDataChartDays(resultDays.dataChart);
    setBarCharDays(resultDays.barChart);

    setSelectedPatients(newPatientsSelected);

    setLoading(false);
    if (newPatientsSelected.length === 0 && newSelectedGroups.length === 0) {
      selectExposureActivity(newSelectedGroups, dateRange);
    }
  };

  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param newPatients: lista dei pazienti selezionati
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    newPatients = patients
  ) => {
    if (!newDateRange) newDateRange = dateRange;
    let temp = [];
    setSelectedGroups([]);
    setLoading(true);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
      });

      let item = {};
      item["id"] = tempGroup.id;
      item["name"] = tempGroup.name;
      item["patients"] = tempGroup.patients;

      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );

      if (props.type === "training") {
        item["action"] = allCalendarActivity.filter(
          (all) => all?.activity_datum?.activity_type?.key === "training"
        );
        temp.push(item);
      } else if (props.type === "match") {
        item["action"] = allCalendarActivity.filter(
          (all) => all?.activity_datum?.activity_type?.key === "race"
        );
        temp.push(item);
      }
    }
    setSelectedGroups([]);
    setSelectedPatients([]);

    // calcolo del paziente attuale in base al suo id
    let tempPat = patId
      ? [newPatients.find(({ id }) => parseInt(id) === parseInt(patId))]
      : [];
    // inizializzo il grafico con le statistiche del paziente
    selectionActionFunction(tempPat, "group", temp);
    setLoading(false);
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      if (patId) {
        await getPatientInjury(groups, newDateRange);
        selectExposureActivity(groups, newDateRange, patients);
      } else {
        await getPatientInjury(selectedGroups, dateRange);
        selectExposureActivity(selectedGroups, newDateRange, []);
      }
      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      <Grid item container xs={8}>
        {/* selezione dei pazienti e dei gruppi  */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}

        {groupId &&
          getGroupsSelection(groups, selectedGroups, selectExposureActivity)}
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={fullSizeRatio ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters[
                  `ratioInjury${props.type}`
                ]
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeRatio(!fullSizeRatio);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeRatio ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartRate} barChar={barCharRate} />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeDays ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters[
                  `daysAbsense${props.type}`
                ]
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeDays(!fullSizeDays);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeDays ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartDays} barChar={barCharDays} />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeBurder ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters[
                  `burden${props.type}`
                ]
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeBurder(!fullSizeBurder);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeBurder ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartBurder} barChar={barCharBurder} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withSnackbar(PatientsReportsStep7TrainingInjury);
