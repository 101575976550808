import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { rollbar } from "../../utils/common";
import { nestedObjectAssign } from "../patients/patientsReports/ReportHelperFns";

const withSaveConfig = (Element, getConfigFunction, saveConfigFunction) => {
  const SaveConfigWrapper = ({ ...props }) => {
    const [requestSaveFlag, setRequestSaveFlag] = useState(false);
    const [prevConfigurationString, setPrevConfigurationString] =
      useState("{}");
    const [savedConfiguration, setSavedConfiguration] = useState({});
    const [loadConfiguration, setLoadConfiguration] = useState(null);
    const [savedConfigUpdateFlag, setSavedConfigUpdateFlag] = useState(false);

    const { patId, groupId } = useParams();

    const setConfig = (data) => {
      //This is where the fun happens
      setSavedConfiguration((prev) => nestedObjectAssign(prev, data));
    };

    const startSave = () => {
      setSavedConfiguration({});
      setRequestSaveFlag(true);
    };

    useEffect(async () => {
      props.setLoading?.(true);
      try {
        //Fetch config
        const newConfiguration = await getConfigFunction({
          id_patient: patId,
          id_group: groupId,
        });
        const jsonConfigString = newConfiguration?.config ?? "{}";
        const parsedConfig = JSON.parse(jsonConfigString);
        setPrevConfigurationString(jsonConfigString);
        setLoadConfiguration(parsedConfig);
      } catch (error) {
        rollbar.error("SaveWrapper - fetchDataInitial", error);
        props.snackbarShowErrorMessage(error);
      }
      props.setLoading?.(false);
    }, []);

    useEffect(async () => {
      props.setLoading?.(true);
      setRequestSaveFlag(false);
      try {
        //Save graph data to database
        const newConfig = JSON.stringify(savedConfiguration);
        if (prevConfigurationString != newConfig) {
          setPrevConfigurationString(newConfig);
          await saveConfigFunction({
            id_patient: patId,
            id_group: groupId,
            config: newConfig,
          });
        }
      } catch (error) {
        rollbar.error("SaveWrapper - saveConfig", error);
        props.snackbarShowErrorMessage(error);
      }
      props.setLoading?.(false);
    }, [savedConfigUpdateFlag]);

    useEffect(() => {
      setSavedConfigUpdateFlag(!savedConfigUpdateFlag);
    }, [savedConfiguration]);

    return (
      <Element
        {...props}
        requestSaveFlag={requestSaveFlag}
        startSave={startSave}
        setConfig={setConfig}
        loadConfiguration={loadConfiguration}
      />
    );
  };
  return withSnackbar(SaveConfigWrapper);
};

export default withSaveConfig;
