import React, { useRef, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import SpButton from "../../../../components/atoms/SpButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpText from "../../../../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import { labels } from "../../../shared/translations";

const PaginatedTable = ({ data }) => {
  
  data.forEach(entry => {
    for (let key in entry) {
      if (entry[key] === null) {
        entry[key] = 0;
      }
    }
  });

  const tableRef = useRef();

  const columns = React.useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    // Prendi le colonne in base alle keys del primo elemento nel JSON
    return [
      {
        Header: "Date",
        accessor: "Date",
      },
      ...Object.keys(data[0]).filter(key => !key.includes('SWC') && key !== "Date").map((key) => ({
        Header: key,
        accessor: key,
      }))
    ];
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    usePagination
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [page]);

  const calculatePercentile = (dataArray, percentile) => {
    const sortedData = dataArray.slice().sort((a, b) => a - b);
    const index =
      sortedData.length > 0
        ? Math.ceil((percentile / 100) * sortedData.length) - 1
        : 0;

    if (index >= 0 && index < sortedData.length) {
      return sortedData[index];
    } else {
      return null; // handle out-of-bounds cases
    }
  };

  const colorColumnBasedOnValues = ({ columnId, value }) => {
    const columnValues = data.map((row) => parseFloat(row[columnId]));
    const max = Math.max(...columnValues);
    const q1 = calculatePercentile(columnValues, 25);
    const q3 = calculatePercentile(columnValues, 75);
    const min = Math.min(...columnValues);

    if (columnId === "Date")
      return {
        fontWeight: "bold",
        padding: "0.5em",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        position: 'sticky',
        left: 0,
        zIndex: 2,
      };
    if (value >= min && value <= q1) {
      return {
        textAlign: "center",
        padding: "0.5em",
        backgroundColor: "rgba(0, 255, 0, 0.4)",
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    } else if (value > q1 && value <= q3) {
      return {
        textAlign: "center",
        padding: "0.5em",
        backgroundColor: "#383838",
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    } else if (value > q3 && value <= max) {
      return {
        textAlign: "center",
        padding: "0.5em",
        backgroundColor: "rgba(255, 0, 0, 0.6)",
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    }
  };

  return (
    <Grid item container xs={12}>
      <Accordion
        style={{
          background: "transparent",
          width: "95vw",
          display: "block",
          padding: "2em",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#31caad" }} />}
          style={{ background: "transparent", border: "1px solid #31caad" }}
        >
          <SpText variant="text">{labels.patient.chart.tableDetails}</SpText>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ overflowX: "auto" }}>
            <table
              ref={tableRef}
              {...getTableProps()}
              style={{
                width: "90vw",
                tableLayout: "auto",
                borderSpacing: "0",
                borderCollapse: "separate",
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid white",
                          backgroundColor: "#3d988e",
                          color: "white",
                          position: index === 0 ? 'sticky' : 'static',
                          left: index === 0 ? 0 : 'auto',
                          zIndex: index === 0 ? 2 : 'auto',
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                ...colorColumnBasedOnValues({
                                  columnId: cell.column.id,
                                  value: cell.value,
                                }),
                                position: cellIndex === 0 ? 'sticky' : 'static',
                                left: cellIndex === 0 ? 0 : 'auto',
                                zIndex: cellIndex === 0 ? 1 : 'auto',
                              }}
                            >
                              {typeof cell.value === "number" 
                                ? cell.value.toFixed(1) 
                                : cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                      {index !== page.length - 1 && (
                        <tr key={`divider-${index}`}>
                          {columns.map((column, columnIndex) => (
                            <td
                              key={`divider-${index}-${columnIndex}`}
                              style={{
                                borderBottom: "1px solid white",
                                height: "1px",
                                backgroundColor: "white",
                              }}
                            />
                          ))}
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
            <div>
              <SpButton
                buttonType={"accept"}
                text={"Previous Page"}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />

              <span>
                Page{" "}
                <strong>
                  {page[0]?.index + 1} of {Math.ceil(rows.length / 10)}
                </strong>{" "}
              </span>

              <SpButton
                buttonType={"accept"}
                text={"Next Page"}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default PaginatedTable;
