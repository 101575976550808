import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import SpText from "../../../../components/atoms/SpText";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import moment from "moment";
import ReportGraphStatisticAnalytic from "../ReportGraphStatisticAnalytics";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import TableChartOutlinedIcon from "@material-ui/icons/TableChart";
import BarChartOutlinedIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import ReportTable from "../../patientsReports/patientsReportFeedback/ReportTable";
import ReportStatistics from "../../patientsReports/patientsReportFeedback/ReportStatistics";
import { labels, psTranslate } from "../../../shared/translations";
import {
  FilterAutocomplete,
  divideByUnits,
  studies,
} from "./PatientsStatisticsAnalyticsFiltersHelper";
import { theme } from "../../../../components/theme";
const ANALYTICS_VIEW = {
  char: "char",
  table: "table",
  statistics: "statistics",
};
const PatientsStatisticsAnalyticsChar = ({ data, filters, ...props }) => {
  const [selectedView, setSelectedView] = useState([]);
  const [selectedGraphType, setSelectedGraphType] = useState([]);
  const [selectedDateView, setSelectedDateView] = useState([]);
  const [charToDisplay, setCharToDisplay] = useState([]);

  const defaultZero = true;

  const ToggleCharTable = ({
    selectedViewSelected,
    index,
    setSelectedView,
  }) => {
    const control = {
      value: selectedViewSelected,
      onChange: (event, newSelectedView) => {
        let item = JSON.parse(JSON.stringify(selectedView));
        item[index] = newSelectedView;
        setSelectedView(item);
      },
      exclusive: true,
    };
    return (
      <ToggleButtonGroup
        size="small"
        {...control}
        style={{ paddingRight: "2em" }}
      >
        <ToggleButton
          value={ANALYTICS_VIEW.char}
          key={ANALYTICS_VIEW.char}
          style={{ borderColor: theme.colors.primary.lightBlue }}
        >
          <BarChartOutlinedIcon
            style={{
              color:
                selectedView[index] === ANALYTICS_VIEW.char
                  ? theme.colors.primary.lightBlue
                  : "grey",
            }}
          />
        </ToggleButton>

        <ToggleButton
          value={ANALYTICS_VIEW.table}
          key={ANALYTICS_VIEW.char}
          style={{ borderColor: theme.colors.primary.lightBlue }}
        >
          <TableChartOutlinedIcon
            style={{
              color:
                selectedView[index] === ANALYTICS_VIEW.table
                  ? theme.colors.primary.lightBlue
                  : "grey",
            }}
          />
        </ToggleButton>

        <ToggleButton
          value={ANALYTICS_VIEW.statistics}
          key={ANALYTICS_VIEW.statistics}
          style={{ borderColor: theme.colors.primary.lightBlue }}
        >
          <TimelineIcon
            style={{
              color:
                selectedView[index] === ANALYTICS_VIEW.statistics
                  ? theme.colors.primary.lightBlue
                  : "grey",
            }}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const initFunction = () => {
    if (data?.dataChart && data?.promsData && data?.testData) {
      let charToDisplayUpdated = [
        {
          title: "Workload Competition",
          currStudyParameters: filters.activity.selectedStudyParams,
          dataChart: data.dataChartRace,
          dataTable: data.dataTableRace,
          dataZscore: data.dataZscoreRace,
          flex: 8,
        },
      ];
      const newData = divideByUnits({
        dataChart: data?.dataChart,
        selectedStudyParams: filters.activity.selectedStudyParams,
      });
      const testData = divideByUnits({
        dataChart: data?.testData,
        selectedStudyParams: filters.activity.selectedMeasurements,
      });
      Object.keys(newData).forEach((um) => {
        const study = Object.keys(newData[um])[0];
        charToDisplayUpdated.push({
          title: `Workload:`,
          subtitle: study !== "TEMPORAL" ? study : `[${um}]`,
          currStudyParameters: filters.activity.selectedStudyParams,
          dataChart: newData[um],
          dataTable: data.dataTable,
          dataZscore: data.dataZscore,
          flex: 8,
        });
      });
      data?.promsData.forEach((proms, index) => {
        let labelPROMSelected = "";
        try {
          const patients = Object.keys(proms["TEMPORAL"]["Line"]);
          if (patients.length > 0)
            labelPROMSelected = filters.activity.selectedStudyParamsPROMS.find(
              ({ key }) =>
                key ===
                Object.keys(proms["TEMPORAL"]["Line"][patients[0]])[0].split(
                  " "
                )[0]
            );
        } catch (error) {
          labelPROMSelected = "";
        }

        charToDisplayUpdated.push({
          title: `${labels.patient.graphReport.section.proms.title}:`,
          subtitle: psTranslate(labelPROMSelected?.name),
          currStudyParameters: filters.activity.selectedStudyParamsPROMS,
          dataChart: proms,
          dataTable: data.dataTablePROMS[index],
          dataZscore: data.dataPROMSzscore[index],
          flex: 8,
        });
      });

      Object.keys(testData).forEach((um) => {
        charToDisplayUpdated.push({
          title: `${labels.patient.graphReport.section.assessment.title}:`,
          subtitle: `[${um}]`,
          currStudyParameters: filters.activity.selectedMeasurements,
          dataChart: testData[um],
          dataTable: data.dataTableMeasurement,
          dataZscore: data.dataMeasurementZscore,
          flex: 8,
        });
      });

      Object.keys(data.testList).forEach((item, index) => {
        charToDisplayUpdated.push({
          title: `${labels.patient.graphReport.section.assessment.title} - Template:`,
          subtitle: item,
          currStudyParameters: filters.activity.selectedAssessmentsTemplates,
          dataChart: data.testList[item],
          dataTable: data.testTableList[index],
          dataZscore: data.testZscoreList[index],
          flex: 8,
        });
      });

      const newSelectedView = charToDisplayUpdated.map(
        () => ANALYTICS_VIEW.char
      );
      const newSelectedGraph = charToDisplayUpdated.map(
        () => filters.graph.graphType[0]
      );
      const newSelectedDateView = charToDisplayUpdated.map(
        () => filters.activity.dateView[0]
      );
      setSelectedView(newSelectedView);
      setSelectedGraphType(newSelectedGraph);
      setSelectedDateView(newSelectedDateView);
      setCharToDisplay(charToDisplayUpdated);
    }
  };

  useEffect(() => {
    initFunction();
  }, [data]);

  return (
    <Grid
      container
      direction="row"
      xs={12}
      style={{ paddingTop: "8px", paddingLeft: "8px" }}
    >
      {charToDisplay.map((item, index) => {
        return (
          <>
            <Grid
              item
              xs={selectedView[index] === ANALYTICS_VIEW.char ? item.flex : 12}
              style={{ paddingBottom: "2em", paddingRight: "2em" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  alignItems="baseline"
                >
                  <SpText variant={"h1PageTitleDialog"}>{item.title}</SpText>
                  <SpText
                    variant={"h1PageTitleDialogColored"}
                    style={{ paddingLeft: "1em" }}
                  >
                    {item.subtitle}
                  </SpText>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Grid item xs={2}>
                  <ToggleCharTable
                    selectedView={selectedView[index]}
                    setSelectedView={setSelectedView}
                    index={index}
                  />
                </Grid>

                {selectedView[index] === ANALYTICS_VIEW.char && (
                  <Grid item container direction="row" xs={4}>
                    <Grid item xs={6}>
                      <FilterAutocomplete
                        multiple={false}
                        placeholder={""}
                        value={selectedGraphType[index]}
                        onChange={(_, newValue) => {
                          const item = JSON.parse(
                            JSON.stringify(selectedGraphType)
                          );
                          item[index] = newValue;
                          setSelectedGraphType(item);
                        }}
                        renderOptions={selectedGraphType[index]}
                        options={filters.graph.graphType}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FilterAutocomplete
                        multiple={false}
                        placeholder={""}
                        value={selectedDateView[index]}
                        onChange={(_, newValue) => {
                          const item = JSON.parse(
                            JSON.stringify(selectedDateView)
                          );
                          item[index] = newValue;

                          setSelectedDateView(item);
                        }}
                        renderOptions={selectedDateView[index]}
                        options={filters.activity.dateView}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {selectedView[index] === ANALYTICS_VIEW.char && (
                <ReportGraphStatisticAnalytic
                  dateRange={moment.range(data.startDate, data.endDate)}
                  graphDateView={selectedDateView[index]}
                  graphType={selectedGraphType[index]}
                  studies={studies}
                  currStudyParameters={item.currStudyParameters}
                  defaultZero={defaultZero}
                  data={item.dataChart}
                />
              )}
              {selectedView[index] === ANALYTICS_VIEW.table && (
                <ReportTable
                  data={item.dataTable}
                  currStudyParameters={item.currStudyParameters}
                />
              )}
              {selectedView[index] === ANALYTICS_VIEW.statistics && (
                <ReportStatistics
                  data={item.dataTable}
                  currStudyParameters={item.currStudyParameters}
                />
              )}
            </Grid>
            {selectedView[index] === ANALYTICS_VIEW.char && (
              <Grid
                item
                xs={4}
                style={{ paddingBottom: "2em", marginTop: "2em" }}
              >
                <Grid
                  container
                  direction="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={12}>
                    <SpText variant={"h1PageTitleDialog"}>{`Z score ${moment(
                      data.endDate
                    ).format("DD/MM/YYYY")}`}</SpText>
                  </Grid>
                </Grid>

                {/* Z Score */}
                <ReportGraphStatisticAnalytic
                  dateRange={moment.range(data.startDate, data.endDate)}
                  graphDateView={selectedDateView[index]}
                  graphType={filters.graph.selectedGraphType}
                  studies={studies}
                  currStudyParameters={item.currStudyParameters}
                  defaultZero={defaultZero}
                  data={item.dataZscore}
                  isZscoreChar={true}
                />
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default withSnackbar(PatientsStatisticsAnalyticsChar);
