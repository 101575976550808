import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";
import Statistics from "../groups/statistics/Statistics";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";

const PatientsStatistics = ({ ...props }) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const { patId, date, activity, eventId } = useParams();

  const history = useHistory();

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      if (responseDetail) {
        setCurrentPatientDetail(responseDetail);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container spacing={2} direction="row">
        <Grid item xs={12} container direction="row" spacing={1}>
          <Statistics
            date={date}
            activity={activity}
            patientId={patId}
            eventId={eventId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsStatistics);
