import React from "react";
import { Bar, Label, LabelList } from "recharts";
import {
  barColors,
  barWithDimension,
  getMonthList,
  rollbar,
} from "../../../../utils/common";
import moment from "moment";
import { getLanguage, labels, psTranslate } from "../../../shared/translations";


const training = labels.analytics.injuryReport.graphs.training;
const match = labels.analytics.injuryReport.graphs.partita;
const ratioTM = labels.analytics.injuryReport.graphs.ratioTM;

const language = getLanguage();
moment.locale(String(language));

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="16" fill="#FFFFFF" fontWeight="Bold" />;
};

const createBarschartDataPoints = (labelKey, size, color) => {
  return (
    <Bar barSize={size} dataKey={labelKey} fill={color} stackId="a">
      <LabelList
        dataKey={labelKey}
        position="center"
        content={renderCustomizedLabel}
      />
    </Bar>
  );
};

const selectGroupTrainingMatch = (
  groupActivities,
  result,
  selectedActivity
) => {
  groupActivities[selectedActivity]?.forEach((activity) => {
    // se esiste la chiave, incremento il valore altrimento lo creo
    activity.patients.forEach((patient) => {
      // se il numero di pazienti selezionati e' 0 di default prendi tutti altrimenti solo quelli scelti
      if (result.hasOwnProperty(patient?.patientName)) {
        result[patient?.patientName] = result[patient?.patientName] + 1;
      } else {
        result[patient?.patientName] = 1;
      }
    });
  });
  return groupActivities;
};

/**
 * Funzione che restituisce i nuovi pazienti selezionati, perche' viene utilizzato un solo array
 * sia per i pazienti del gruppo che quelli che non ne fanno parte
 *
 * @param newPatientsSelected: nuovi pazienti selezionati
 * @param type: gruppo o altri per indicare da quale dropdown fa parte la sezione e calcolare correttamente il nuovo
 * gruppo selezionato quando vengono deselezionati tutti i pazienti
 * @param selectedPatients: vecchi pazienti selezionati
 */
export const getPatientSelected = (
  newPatientsSelected,
  type,
  selectedPatients
) => {
  let resultNewSelectedPatients = [];
  if (newPatientsSelected.length > 0) {
    if (selectedPatients.length > 0) {
      // se i nuovi selezionati fanno parte del gruppo, devo includere anche quelli che non ne fanno parte selezionati
      // in eventi precedenti
      if (newPatientsSelected[0].groupPatient) {
        resultNewSelectedPatients = selectedPatients.filter(
          ({ groupPatient }) => !groupPatient
        );
        resultNewSelectedPatients =
          resultNewSelectedPatients.concat(newPatientsSelected);
      } else {
        // stessa ocsa per i non appartenenti al gruppo
        resultNewSelectedPatients = selectedPatients.filter(
          ({ groupPatient }) => groupPatient
        );
        resultNewSelectedPatients =
          resultNewSelectedPatients.concat(newPatientsSelected);
      }
    } else {
      resultNewSelectedPatients = newPatientsSelected;
    }
  } else {
    // se vengono deselezionati tutti i pazienti del gruppo , il nuovo gruppo e' formato da
    // coloro che non ne fanno parte se prima erano stati selezionati e viceversa
    if (type === "gruppo") {
      resultNewSelectedPatients = selectedPatients.filter(
        ({ groupPatient }) => !groupPatient
      );
    } else if (type === "altri") {
      resultNewSelectedPatients = selectedPatients.filter(
        ({ groupPatient }) => groupPatient
      );
    } else {
      resultNewSelectedPatients = selectedPatients;
    }
  }

  // rimozione dei duplicati
  resultNewSelectedPatients = resultNewSelectedPatients.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );
  return resultNewSelectedPatients;
};

/**
 * Funzione che restituisce tante bar quanti sono i gruppi selezionati e per ogni gruppo
 * somma il numero di training e li divide per la somma dei match
 *
 * @param result: oggetto utilizzato
 * @param groupActivities: lista di attivita' per ogni gruppo
 */
const selectGroupRT = (groupActivities, result) => {
  result[training] = {};
  result[match] = {};
  let tempAttivita = [training, match];
  tempAttivita.forEach((itemAct) => {
    groupActivities[itemAct].forEach(({ patients }) => {
      // se esiste la chiave, incremento il valore altrimento lo creo
      patients.forEach(({ patientName }) => {
        // se il numero di pazienti selezionati e' 0 di default prendi tutti altrimenti solo quelli scelti
        if (result[itemAct].hasOwnProperty(patientName)) {
          result[itemAct][patientName] = result[itemAct][patientName] + 1;
        } else {
          result[itemAct][patientName] = 1;
        }
      });
    });
  });

  // oggetto di appoggio per calcolare la media
  let temp = {};
  for (let key in result[training]) {
    temp[key] =
      result[match][key] > 0
        ? (result[training][key] / result[match][key]).toFixed(1)
        : result[training][key];
  }
  return temp;
};

/**
 * Funzione che restituisce tante bar quanti sono i gruppi selezionati e per ogni gruppo
 * somma il numero di training e li divide per il numero di pazienti
 *
 * @param groups: lista dei gruppi selezionati
 * @param groupActivities: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 */
export const selectGroup = (groups, groupActivities, selectedActivity) => {
  try {
    const barColorValueTemp = barColors();
    let activityGroup = groupActivities.find(
      ({ nameGruppo }) => nameGruppo === groups[0]?.name
    );
    let result = {};
    let resultArray = [];
    let nValue = 0;

    if (selectedActivity === training || selectedActivity === match) {
      activityGroup = selectGroupTrainingMatch(
        activityGroup,
        result,
        selectedActivity
      );
    }

    if (selectedActivity === ratioTM) {
      result = selectGroupRT(activityGroup, result);
    }
    let bars = [];
    // creo l'oggeto necessario per mappare i dati da mostrare nel grafico
    for (let key in result) {
      nValue += result[key];
      let item = {};
      item["groupDate"] = key;
      item[selectedActivity] = result[key];
      resultArray.push(item);
    }
    let item = {};
    // aggiungo una bar per il totale
    item["groupDate"] = labels.analytics.injuryReport.total;
    item[selectedActivity] =
      selectedActivity === training || selectedActivity === match
        ? activityGroup[selectedActivity].length
        : activityGroup[match].length > 0
        ? (
            activityGroup[training].length / activityGroup[match].length
          ).toFixed(1)
        : activityGroup[training].length;
    resultArray.push(item);
    bars.push(
      createBarschartDataPoints(
        selectedActivity,
        barWithDimension(resultArray.length),
        barColorValueTemp[
          Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
            barColorValueTemp.length
        ]
      )
    );
    return {
      dataChart: resultArray,
      allPatients: resultArray,
      barChart: bars,
      patients: groups[0]?.patients,
    };
  } catch (error) {
    rollbar.error("ExposureFunction - selectGroup", error);
  }
};

export const groupsDetails = (newSelectedGroups, trainingGroups, action) => {
  let groupsAvarage = [];

  newSelectedGroups.forEach((group) => {
    let res = selectGroup([group], trainingGroups, action);
    groupsAvarage.push({
      [action]: res.dataChart.find(
        (result) => result.groupDate === labels.analytics.injuryReport.total
      )[action],
      groupDate: `${labels.analytics.injuryReport.total} ${group.name}`,
    });
  });

  return groupsAvarage;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 * quando il numero dei pazienti e' 1 e l'attivita' scelta e' training o match
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param groupActivities: lista di attivita' per ogni gruppo
 * @param dateRange: intervallo di tempo selezionato
 */
const selectPatientsTrainingMatch = (
  groupActivities,
  selectedPatients,
  dateRange,
  patId,
  tempDisponibilityPatients
) => {
  const trainingLabel = labels.analytics.injuryReport.graphs.training;
  const partitaLabel = labels.analytics.injuryReport.graphs.partita;
  const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;

  const monthList = getMonthList(
    moment(dateRange.start).format("YYYY-MM-DD"),
    moment(dateRange.end).format("YYYY-MM-DD")
  );
  let resultArray = [];
  let resultTraining = [];

  for (let day of monthList) {
    let item = {};
    item["groupDate"] = `${moment(day).format("MMMM")}`;
    item[trainingLabel] = 0;
    item[partitaLabel] = 0;
    item[trainingLabel + '_ava'] = 0;
    item[partitaLabel + '_ava'] = 0;

    for (let tr of groupActivities[trainingLabel]) {
      if (moment(tr?.start_date).format("MM") === moment(day).format("MM")) {
        if (tr?.patients.find(({ id }) => parseInt(id) === parseInt(patId))) {
          const patient = tr?.patients.find(({ id }) => id === parseInt(patId));
          if (patient && tempDisponibilityPatients.filter(({ id_patient, date, patient_disponibility }) =>
              parseInt(id_patient) === parseInt(patient.id) &&
              moment(date).format('YYYY-MM-DD') === moment(tr?.start_date).format('YYYY-MM-DD') &&
              (patient_disponibility === 'fit' || patient_disponibility === null))) {
                item[trainingLabel]++;
          }
        }
      item[trainingLabel + '_ava'] += 1;
      }
    }

    for (let mt of groupActivities[partitaLabel]) {
      if (moment(mt?.start_date).format("MM") === moment(day).format("MM")) {
        if (mt?.patients.find(({ id }) => id === parseInt(patId))) {
          const patient = mt?.patients.find(({ id }) => id === parseInt(patId));
          if (patient && tempDisponibilityPatients.filter(({ id_patient, date, patient_disponibility }) =>
              parseInt(id_patient) === parseInt(patient.id) &&
              moment(date).format('YYYY-MM-DD') === moment(mt?.start_date).format('YYYY-MM-DD') &&
              (patient_disponibility === 'fit' || patient_disponibility === null))) {
                item[partitaLabel]++;
          } 
        }
      item[partitaLabel + '_ava'] += 1;
      }
    }

    item[trainingLabel+'_ava'] = parseFloat(item[trainingLabel]*100)/(item[trainingLabel+'_ava']).toFixed(1) || 0;
    item[partitaLabel+'_ava'] = parseFloat(item[partitaLabel]*100)/(item[partitaLabel+'_ava']).toFixed(1) || 0  
    
    resultTraining.push(item);
  }

  for (let day of monthList) {
    let item = {};
    item["groupDate"] = `${moment(day).format("MMMM")}`;
    item[partitaLabel] = 0;
    for (let mt of groupActivities[partitaLabel]) {
      if (moment(mt?.start_date).format("MM") === moment(day).format("MM")) {
        if (mt?.patients.find(({ id }) => id === parseInt(patId))) {
          item[partitaLabel]++;
        }
      }
    }
    for (let mt of groupActivities[partitaLabel]) {
      if (moment(mt?.start_date).format("MM") === moment(day).format("MM")) {
        if (mt?.patients.find(({ id }) => id === parseInt(patId))) {
          item[partitaLabel]++;
        }
      }
    }

    const found = resultTraining.find(
      ({ groupDate }) => groupDate === `${moment(day).format("MMMM")}`
    );
    if (found && found.groupDate !== 0) {
      if (found[trainingLabel] !== 0) {
        if (item[partitaLabel] === 0) {
          found[ratioTMLabel] = found[trainingLabel];
        } else {
          found[ratioTMLabel] = (
            found[trainingLabel] / item[partitaLabel]
          ).toFixed(1);
        }
      } else {
        found[ratioTMLabel] = 0;
      }
    }
    found[partitaLabel] = item[partitaLabel]
    found[partitaLabel] = item[partitaLabel]
    resultArray.push(found);
  }


  return resultArray;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 * quando il numero dei pazienti e' maggiore di
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param activityGroups: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param dateRange: intervallo di tempo selezionato
 * @param groups: lista di tutti i gruppi disponibili
 */
export const selectMultiplePatients = (
  groups,
  selectedPatients,
  activityGroups,
  selectedActivity
) => {
  let result = {};
  for (let group of groups) {
    // richiamo la funzione che dato un gruppo mi restituisce il per ogni paziente
    // del gruppo il numero di attivita' svolte
    let oneGroupDataCharts = selectGroup(
      [group],
      activityGroups,
      selectedActivity
    );
    // sommo tutte le attivita' per ogni paziente perche un paziente puo' appartenere
    // a piu' gruppi
    oneGroupDataCharts?.dataChart?.forEach((groupDataChart) => {
      result[groupDataChart.groupDate] = result[groupDataChart.groupDate]
        ? result[groupDataChart.groupDate] + groupDataChart[selectedActivity]
        : groupDataChart[selectedActivity];
    });
  }

  let tot = result[labels.analytics.injuryReport.total]
  let tempDataChartOut = [];
  // costruisco l'oggetto da restituire formattato in modo che il grafico possa essere
  // facilmente creato

  for (let key in result) {
    tempDataChartOut.push({
      groupDate: key,
      [selectedActivity]: result[key],
      [selectedActivity + '_ava'] : (result[key]*100)/tot
    });
  }
  let dataChartOut = [];
  // filtro il risultato di out in quanto non tutti i pazienti devono essere visualizzati
  selectedPatients.forEach(({ familyName, givenName }) => {
    const item = tempDataChartOut.find(
      ({ groupDate }) =>
        groupDate.includes(familyName) && groupDate.includes(givenName)
    );
    if (item !== undefined) dataChartOut.push(item);
  });
  return dataChartOut;
};

export const selectGroupFilteredByMonth = (
  group,
  dateRange,
  groupActivities,
  tempDisp
) => {

  const trainingLabel = labels.analytics.injuryReport.graphs.training;
  const partitaLabel = labels.analytics.injuryReport.graphs.partita;
  const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;

  const monthList = getMonthList(
    moment(dateRange.start).format("YYYY-MM-DD"),
    moment(dateRange.end).format("YYYY-MM-DD")
  );
  let resultArray = [];
  let resultTraining = [];

  for (let day of monthList) {
    let item = {};
    item["groupDate"] = `${moment(day).format("MMMM")}`;
    item[trainingLabel] = 0;
    item[partitaLabel] = 0;
    item[trainingLabel+'_n'] = 0;
    item[partitaLabel+'_n'] = 0;
    item[trainingLabel+'_ava'] = 0;
    item[partitaLabel+'_ava'] = 0;

    for (let tr of groupActivities[0][trainingLabel]) {
      if (moment(tr?.start_date).format("MM") === moment(day).format("MM")) {
        for (let p in tr?.patients) {
          const patient = tr?.patients[p];
          if (patient && tempDisp.filter(({ id_patient, date, patient_disponibility }) =>
              parseInt(id_patient) === parseInt(patient.id) &&
              moment(date).format('YYYY-MM-DD') === moment(tr?.start_date).format('YYYY-MM-DD') &&
              (patient_disponibility === 'fit' || patient_disponibility === null))) {
                item[trainingLabel]++;
          }
        item[trainingLabel+'_ava'] += tr?.patients.length || 0;
        }
      item[trainingLabel+'_n'] += 1
      }
    }
    for (let mt of groupActivities[0][partitaLabel]) {
      if (moment(mt?.start_date).format("MM") === moment(day).format("MM")) {
        for (let p in mt?.patients) {
          const patient = mt?.patients[p];
          if (patient && tempDisp.filter(({ id_patient, date, patient_disponibility }) =>
              parseInt(id_patient) === parseInt(patient.id) &&
              moment(date).format('YYYY-MM-DD') === moment(mt?.start_date).format('YYYY-MM-DD') &&
              (patient_disponibility === 'fit' || patient_disponibility === null))) {
                item[partitaLabel]++;
          }
        item[partitaLabel+'_ava'] += mt?.patients.length || 0;
        }
      item[partitaLabel+'_n'] += 1
      }
    }
    item[trainingLabel+'_ava'] = parseFloat(item[trainingLabel]*100)/(item[trainingLabel+'_n']*groupActivities[0]?.patients.length).toFixed(1) || 0;
    item[partitaLabel+'_ava'] = parseFloat(item[partitaLabel]*100)/(item[partitaLabel+'_n']*groupActivities[0]?.patients.length).toFixed(1) || 0;

    resultTraining.push(item);
  }

  for (let day of monthList) {
    let item = {};
    item["groupDate"] = `${moment(day).format("MMMM")}`;
    item[partitaLabel] = 0;
    for (let { start_date } of groupActivities[0][partitaLabel]) {
      if (moment(start_date).format("MM") === moment(day).format("MM")) {
        item[partitaLabel]++;
      }
    }

    item[trainingLabel] = 0;
    for (let { start_date } of groupActivities[0][trainingLabel]) {
      if (moment(start_date).format("MM") === moment(day).format("MM")) {
        item[trainingLabel]++;
      }
    }
    
    const found = resultTraining.find(
      ({ groupDate }) => groupDate === `${moment(day).format("MMMM")}`
    );
    if (found && found.groupDate !== 0) {
      if (item[trainingLabel] !== 0) {
        if (item[partitaLabel] === 0) {
          found[ratioTMLabel] = item[trainingLabel];
        } else {
          found[ratioTMLabel] = parseFloat(
            item[trainingLabel] / item[partitaLabel]
          ).toFixed(1);
        }
      } else {
        found[ratioTMLabel] = 0;
      }
    }
    //found[partitaLabel] = item[partitaLabel]
    resultArray.push(found);
  }
  return resultArray;
};

export const selectPatientsFilteredByMonth = (
  selectedPatients,
  activityGroups,
  selectedActivity,
  dateRange,
  patId,
  tempDisponibilityPatients
) => {
  let result = {
    dataChart: [],
    selectedPatients: selectedPatients,
  };

  const barColorValueTemp = barColors();

  let attivitaGruppo = activityGroups.find(
    ({ idGruppo }) => idGruppo === selectedPatients?.patient_group?.id_group
  );
  result["dataChart"] = selectPatientsTrainingMatch(
    attivitaGruppo,
    selectedPatients,
    dateRange,
    patId,
    tempDisponibilityPatients
  );
  {/*// creo il grafico
  result["barChart"] = createBarschartDataPoints(
    selectedActivity,
    barWithDimension(result["dataChart"]?.length),
    barColorValueTemp[
      Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
        barColorValueTemp.length
    ]
  );*/}
  return result;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param activityGroups: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param groups: lista di tutti i gruppi disponibili
 */
export const selectPatients = (
  selectedPatients,
  activityGroups,
  selectedActivity,
  groups
) => {
  // aggiungi tutti gli elementi selezionati nell'array allData
  const barColorValueTemp = barColors();

  let result = {
    dataChart: [],
    selectedPatients: selectedPatients,
  };

  result["dataChart"] = selectMultiplePatients(
    groups,
    selectedPatients,
    activityGroups,
    selectedActivity
  );

  {/*// creo il grafico
  result["barChart"] = createBarschartDataPoints(
    selectedActivity,
    barWithDimension(result["dataChart"]?.length),
    barColorValueTemp[
      Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
        barColorValueTemp.length
    ]
  );*/}
  return result;
};
