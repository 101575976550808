import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { styled } from "../components/styled";
import SpButton from "../components/atoms/SpButton";
import SpTextInput from "../components/atoms/SpTextInput";
import SpText from "../components/atoms/SpText";
import SpDialog from "../components/atoms/SpDialog";
import { theme } from "../components/theme";
import {
  changePassword,
  loginCompany,
  loginProfessional,
} from "../models/actions/Auth";
import { labels } from "./shared/translations";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpLoadingButton from "../components/atoms/SpLoadingButton";
import LoginIcon from "../assets/icon/loginIcon.png";
import { rollbar, setRollbarUser } from "../utils/common";

const StyledRowDialog = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "4%",
});

const StyledColumnLogin = styled(Grid)({
  alignSelf: "center",
  padding: "2%",
  marginTop: "10%",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "40%",
  borderRadius: "10px",
  boxShadow: `1px 1px 11px 4px ${theme.colors.primary.lightBlue}`,
});
const StyledColumn = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  padding: "2%",
});

const LoginUsernamePsw = (props) => {
  const logObj = {
    component: "LoginUsernamePsw",
    file: "LoginUsernamePsw.js",
  };

  const [open, setOpen] = useState(false);
  const [emailForgotPassword, setEmailForgot] = useState(null);
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();

  const loginUser = async (data) => {
    // console.log("LOGIN-DATA: ", data);
    setIsLoading(true);
    if (data.companyAccess) {
      const response = await loginCompany(data);
      if (response.status !== 200) {
        setInputError(response.data.message);
        rollbar.error(logObj, JSON.stringify(response.data.message));
      } else {
        setInputError(null);
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.token)
        );
        localStorage.setItem("userId", response.data.id);
        setRollbarUser(response.data.id, data?.email);
        return history.push("/agency");
      }
      history.push("/agency");
    } else {
      try {
        const response = await loginProfessional(data);
        if (response.status !== 200) {
          if (response.status === 401 || response.status === 404)
            props.snackbarShowErrorMessage(response.data.message);
          //setInputError(response.data.message);
          rollbar.error(logObj, JSON.stringify(response.data.message));
        } else {
          setInputError(null);
          props.snackbarShowMessage(response.message);
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("userId", response.data.id);

          // console.log("loginUser - userId", response.data.id);
          // console.log("loginUser - userEmail", response.data.id);
          setRollbarUser(response.data.id, data.email);

          history.push("/");
        }
      } catch (err) {
        props.snackbarShowErrorMessage(err);
        rollbar.error(logObj, JSON.stringify(err));
      }
    }
    setIsLoading(false);
  };

  const sendForgotPassEmail = async () => {
    const response = await changePassword(emailForgotPassword);
    if (response.status !== 200) {
      setInputError(response.data.message);
    } else {
      return setOpen(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(loginUser)}>
        <StyledColumnLogin
          container
          spacing={2}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={10} style={{ width: "100%" }}>
            <img style={{ width: "100%" }} src={LoginIcon} alt="logo" />
          </Grid>
          <Grid item xs={6} container direction="column">
            <SpTextInput
              name="email"
              id="email"
              inputRef={register({ required: true })}
              label={labels.login.email.toUpperCase()}
            />
            {errors.email && (
              <SpText variant="inputError">{labels.login.emailRequired}</SpText>
            )}
            <SpTextInput
              name="password"
              id="password"
              inputRef={register({ required: true })}
              type="password"
              style={{ marginTop: "5px" }}
              label={labels.login.password.toUpperCase()}
            />
            {errors.password && (
              <SpText variant="inputError">
                {labels.login.passwordRequired}
              </SpText>
            )}
            {inputError && <SpText variant="inputError">{inputError}</SpText>}
          </Grid>
          <Grid item xs={6} container direction="column">
            <SpLoadingButton
              id="loginbutton"
              loading={isLoading}
              style={{ marginTop: "3%", marginRight: "0 !important" }}
              text={labels.login.buttons.login}
              buttonType={"accept"}
              variant="none"
              type="submit"
            />

            {/*<SpButton style={{marginTop: "3%", marginRight: "0 !important"}}
                                  text={labels.login.buttons.registration} buttonType={"accept"} variant='none'
                                  onClick={() => history.push("/registration")}/>*/}
          </Grid>
        </StyledColumnLogin>
      </form>
      <SpDialog
        open={open}
        setOpen={setOpen}
        title={labels.login.passwordLostDialog.title}
      >
        <StyledColumn>
          <SpText>{labels.login.passwordLostDialog.subtitle}</SpText>
          <StyledRowDialog>
            <SpTextInput
              name="emailForgotPass"
              value={emailForgotPassword}
              onChange={(e) => setEmailForgot(e.currenTarget.value)}
              label={labels.login.passwordLostDialog.label}
            />
          </StyledRowDialog>
          <StyledRowDialog>
            <SpButton
              onClick={() => {
                sendForgotPassEmail();
              }}
              buttonType="accept"
              text={labels.login.passwordLostDialog.send}
            />
          </StyledRowDialog>
        </StyledColumn>
      </SpDialog>
    </>
  );
};

export default withSnackbar(LoginUsernamePsw);
