const compositeParams = [
  {
    id: -1,
    name: "Volume di Kg sollevati",
    um: "a.u.",
    operator: () => {},
    sourceParams: [], //Comes from backend
  },
  {
    id: -2,
    name: "Session RPE",
    um: "a.u.",
    operator: (params) => params.reduce((a, b) => a * b, 1),
    sourceParams: [6 /*RPE*/, 11 /*Durata*/],
  },
];

export const addProxyParameters = (params) => {
  params.push(...compositeParams);
  return params;
};

export const fixProxyParamsListAvailable = (availableParamsIds) => {
  compositeParams.forEach((compositeParam) => {
    if (compositeParam.sourceParams.length > 0) {
      if (
        compositeParam.sourceParams.every((param) =>
          availableParamsIds.includes(param)
        )
      )
        availableParamsIds.push(compositeParam.id);
    }
  });
  return availableParamsIds;
};

export const fixForProxyParams = (currParamsIds) => {
  const requestParams = currParamsIds.filter((paramId) => paramId >= 0);
  const requestCompositeParams = currParamsIds.filter((paramId) => paramId < 0);
  if (requestCompositeParams.length > 0) {
    //Add source parameters of composite param to request
    requestCompositeParams.forEach((paramId) => {
      const elem = compositeParams.find((el) => el.id == paramId);
      requestParams.push(...elem.sourceParams);
    });
  }
  return requestParams;
};

const _fixForProxyParamsResults = (results, currParamsIds, fn) => {
  const requestCompositeParams = currParamsIds.filter((paramId) => paramId < 0);

  //Composite param -1 is always computed in backend, remove it if not requested
  if (!requestCompositeParams.includes(-1))
    Object.keys(results).forEach((personKey) => delete results[personKey][-1]);

  if (requestCompositeParams.length > 0) {
    //Here we search for composite params in request and provide composite results
    Object.entries(results).forEach(([personKey, paramObjs]) => {
      //Iterate composite params
      requestCompositeParams.forEach((compositeParamId) => {
        const compositeParam = compositeParams.find(
          (el) => el.id == compositeParamId
        );

        //Process each composite param
        fn(compositeParam, personKey, paramObjs);
      });
    });
  }
  return results;
};

export const fixForProxyParamsResults = (
  results,
  currParamsIds,
  studyParams,
  useId = true
) => {
  return _fixForProxyParamsResults(
    results,
    currParamsIds,
    (compositeParam, personKey, paramObjs) => {
      if (compositeParam.sourceParams.length > 0) {
        //[{ day: value, ... }] arrays
        let paramValues;

        //Obtain values
        if (useId) {
          paramValues = compositeParam.sourceParams.map(
            (paramId) => paramObjs[paramId] ?? {}
          );
        } else {
          paramValues = compositeParam.sourceParams.map((paramId) => {
            const studyParam = studyParams.find(
              (param) => param.id === paramId
            );
            return paramObjs[studyParam.name] ?? 0;
          });
        }

        //Obtain possible days
        const dayKeys = paramValues.reduce((days, a) => {
          Object.keys(a).forEach((b) => days.add(b));
          return days;
        }, new Set());

        //Generate a { day: operatedValue } object
        results[personKey][useId ? compositeParam.id : compositeParam.name] = [
          ...dayKeys,
        ].reduce((obj, day) => {
          const dayValues = paramValues.map((dayVals) => dayVals[day] ?? 0);
          obj[day] = compositeParam.operator(dayValues);
          return obj;
        }, {});
      }
    }
  );
};

export const fixForProxyParamsResultsSingle = (
  results,
  currParamsIds,
  studyParams
) => {
  results = fixForProxyParamsResults(
    results,
    currParamsIds,
    studyParams,
    false
  );

  //Remove unnecessary data
  Object.entries(results).forEach(([personKey, paramObjs]) => {
    Object.keys(paramObjs).forEach((paramName) => {
      const paramId =
        studyParams.find((param) => param.name === paramName)?.id ??
        parseInt(paramName);
      if (!currParamsIds.includes(paramId)) {
        delete results[personKey][paramName];
      } else if (paramId === -1) {
        //Special treatment for "Volume di Kg sollevati"
        const volName = compositeParams.find((param) => param.id === -1).name;
        results[personKey][volName] = results[personKey][paramName];
        delete results[personKey][paramName];
      }
    });
  });

  return results;
};
