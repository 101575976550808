import { Avatar, Grid, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import { theme } from "../../../components/theme";
import { getFirstTableGroupsData } from "../../../models/actions/Groups";
import { labels, psTranslate } from "../../shared/translations";
import {
  getColorFromPatientStatus,
  getLabelFromPatientStatus,
  keysStatus,
  rollbar,
  titleCase,
} from "../../../utils/common";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { updatePatient } from "../../../models/actions/Patients";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpDialog from "../../../components/atoms/SpDialog";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getPatientsWithUncheckedAlerts } from "../../../models/actions/PatientAlerts";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import SpLoader from "../../../components/atoms/SpLoader";
import {
  ImageWithBackgroundColor,
  ActivityFilter,
  HealthFilter,
} from "./SituationTableHelper";
import SpTooltip from "../../../components/atoms/SpTooltip";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";
import { updatePatientDisponibility } from "../../../models/actions/Disponibility";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const SituationTable = ({
  snackbarShowMessage,
  snackbarShowErrorMessage,
  ...props
}) => {
  const [firstReports, setFirstReports] = useState([]);
  const [firstReportsFiltered, setFirstReportsFiltered] = useState([]);
  const [statusSelected, setStatusSelected] = useState("");
  const [healthSelected, sethealthSelected] = useState("");
  const [detailModal, setDetailModal] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [patIdUnchekedAlert, setPatIdUnchekedAlert] = useState([]);

  const [newAvailability, setNewAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState([]);
  const [dateSelected, setDateSelected] = useState(
    moment().format("YYYY-MM-DD")
  );
  const classes = useStyles();
  const history = useHistory();
  const offsetFormatted = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { groupId } = useParams();

  const { control, register, handleSubmit, setValue, getValues, reset } =
    useForm({
      shouldUnregister: false,
    });

  const selectFilter = ({ health, status, reason }) => {
    if (health) {
      sethealthSelected(health);
      if (healthSelected === health) {
        setFirstReportsFiltered([]);
        sethealthSelected("");
      } else
        setFirstReportsFiltered(
          firstReports.filter(({ status }) => status === health)
        );
    }
    if (status) {
      setStatusSelected(status);
      if (statusSelected === status) {
        setFirstReportsFiltered([]);
        setStatusSelected("");
      } else
        setFirstReportsFiltered(
          firstReports.filter(
            ({ training_status }) => training_status === status
          )
        );
    }
    if (reason) {
      setStatusSelected(reason);
      if (statusSelected === reason) {
        setFirstReportsFiltered([]);
        setStatusSelected("");
      } else
        setFirstReportsFiltered(
          firstReports.filter(({ status }) => reason === status)
        );
    }
  };

  const updatePatientFunction = async ({ data, id_patient }) => {
    setDialog(false);
    setLoading(true);
    const resultMessUpdate = await updatePatient({
      id_patient,
      data,
    });
    setLoading(false);
    if (resultMessUpdate.error) {
      snackbarShowErrorMessage(resultMessUpdate.error);
    } else {
      snackbarShowMessage(resultMessUpdate.message);
    }
  };

  //Table fixed headers
  const headCellsFirst = [
    {
      type: "string",
      field: "name_surname",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.patient,
      flex: 0.6,
      valueGetter: (params) => params.row.familyName,
      renderCell: (params) => (
        <>
          <Avatar
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                borderRadius: "50%", // Rende l'immagine rotonda
                overflow: "hidden", // Nasconde le parti fuoriuscite
                border: "2px solid white", // Opzionale: Aggiunge un bordo bianco
              },
            }}
            className={classes.largeAvatar}
            src={params.row?.profileImage ? params.row?.profileImage : ""}
          />
          <SpIconAndTextButton
            isIconRight={true}
            text={`${titleCase(params.row.givenName)} ${titleCase(
              params.row.familyName
            )}`}
            textVariant={"tableTextDetails"}
            onClick={() => history.push(`/patients/edit/${params.row.id}`)}
          >
            <ArrowForwardIosIcon
              style={{
                width: 15,
                color: theme.colors.primary.lightBlue,
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "string",
      field: "alert",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.status,
      flex: 0.4,
      valueGetter: (params) => params.row.presentations,
      renderCell: (params) => {
        return (
          <>
            <SpIconButton
              variant="lightBlue"
              style={{ marginLeft: 0, cursor: "default" }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: "2em",
                  color: getColorFromPatientStatus({
                    status: params.row.status,
                  }),
                }}
              />
            </SpIconButton>
            <SpText variant={"nameSurnameHeader"}>
              {getLabelFromPatientStatus({ status: params.row.status })}
            </SpText>
          </>
        );
      },
      sortComparator: (v1, v2) => (v1 >= v2 ? 1 : 0),
    },
    {
      type: "string",
      field: "status",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.patient.table.header.alert,
      flex: 0.1,
      valueGetter: (params) => params.row.presentations,
      renderCell: (params) =>
        patIdUnchekedAlert.find((id) => id === params.row.id) ? (
          <SpTooltip
            id={`alertIconTooltip-${params.row.id}`}
            title={labels.patient.list.table.patientRow.alert.some}
          >
            <NotificationImportantIcon
              id={`alertIcon-${params.row.id}`}
              onClick={() => {
                history.push(`/patients/edit/${params.row.id}/alerts`);
              }}
              style={{
                cursor: "default",
                color: "yellow",
                // rgb(255,255,0)
              }}
            />
          </SpTooltip>
        ) : (
          <SpTooltip
            id={`alertIconTooltip-${params.row.id}`}
            title={labels.patient.list.table.patientRow.alert.none}
          >
            <NotificationsRoundedIcon
              id={`alertIcon-${params.row.id}`}
              onClick={() => {
                history.push(`/patients/edit/${params.row.id}/alerts`);
              }}
              style={{
                cursor: "default",
                color: "green",
                // rgb(0,128,0)
              }}
            />
          </SpTooltip>
        ),
      sortComparator: (v1, v2) => (v1 >= v2 ? 1 : 0),
    },

    {
      type: "string",
      field: "trainingStatus",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.trainingStatus,
      flex: 0.3,
      valueGetter: (params) => params.row.presentations,
      renderCell: (params) => {
        const status = params.row.training_status;
        const color = status === keysStatus.unavailable ? "red" : "green";
        return (
          <>
            <SpIconButton
              variant="lightBlue"
              style={{
                marginLeft: 0,
                cursor: "default",
                height: "4em",
                width: "4em",
                marginBottom: "1em",
              }}
              onClick={() => {
                const oldFirsTable = firstReports.filter(
                  ({ id }) => id != params.row.id
                );
                const newRep = {
                  training_status: status === "fit" ? "unavailable" : "fit",
                  id: params.row.id,
                  id_patient: params.row.id,
                  givenName: params.row.givenName,
                  familyName: params.row.familyName,
                  presentations: params.row.presentations,
                  training_notes: params.row.training_notes,
                  status: params.row.status,
                  date_selected: moment(dateSelected).format("YYYY-MM-DD"),
                  id_calendar_activity: selectedEvent ? selectedEvent : null,
                };

                const index = firstReports.findIndex(
                  ({ id }) => id == params.row.id
                );
                let newArray = oldFirsTable;
                newArray.splice(index, 0, newRep);
                setFirstReports(newArray);

                setNewAvailability([
                  ...newAvailability.filter(
                    ({ id_patient, id_calendar_activity }) =>
                      id_patient != params.row.id ||
                      id_calendar_activity != selectedEvent
                  ),
                  newRep,
                ]);
              }}
            >
              <ImageWithBackgroundColor
                imageUrl={require(`../../../assets/activities/training.png`)}
                backgroundColor={color}
              />
            </SpIconButton>
            <SpText variant={"nameSurnameHeader"}>
              {
                labels.groups.groupReports.firstTable.trainingStatus.find(
                  ({ key }) => key === status
                )?.label
              }
            </SpText>
          </>
        );
      },
      sortComparator: (v1, v2) => (v1 >= v2 ? 1 : 0),
    },
    {
      type: "string",
      field: "detail",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.details,
      flex: 0.5,
      valueGetter: (params) => params.row.familyName,
      renderCell: (params) => (
        <Grid
          container
          direction="row"
          spacing={2}
          style={{ marginTop: "3px" }}
        >
          <Grid item xs={4}>
            <SpButton
              buttonType={"accept"}
              variant="none"
              text={labels.groups.groupReadiness.dialog.updateNotesButton}
              style={{ marginBottom: "12px", width: "100%" }}
              onClick={async () => {
                setDetailModal({
                  id: params.row.id,
                  givenName: params.row.givenName,
                  familyName: params.row.familyName,
                  training_status: params.row.training_status,
                  status: params.row.status,
                  note: params.row.training_notes,
                });
                setDialog(true);
              }}
            />
          </Grid>
        </Grid>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const firstTableData = await getFirstTableGroupsData({
        id_group: groupId,
        start_date: moment(dateSelected).format("YYYY-MM-DD"),
        id_calendar_activity: selectedEvent,
      });
      const resultAlerts = await getPatientsWithUncheckedAlerts({
        ids: firstTableData.map((atl) => atl.id),
      });
      setPatIdUnchekedAlert(resultAlerts.uncheckedIds);
      setFirstReports(firstTableData ?? []);

      const responseEvents = await getAllCalendarActivityByMonitGroup({
        id_group: groupId,
        start_date: moment(dateSelected).format("YYYY-MM-DD"),
        end_date: moment(dateSelected).format("YYYY-MM-DD"),
      });
      const responseEventsFiltered = responseEvents.filter(
        ({ activity_datum }) =>
          activity_datum?.activity_type?.key === "training" ||
          activity_datum?.activity_type?.key === "race"
      );
      setEvents(responseEventsFiltered);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      rollbar.error("SituationTable - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateSelected, selectedEvent]);

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          container
          direction="row"
          style={{ margin: "auto 0", padding: 0 }}
        >
          <Grid item xs={12} style={{ paddingLeft: "1%" }}>
            <SpText variant={"h1PageTitle"}>
              {labels.groups.groupReports.firstTable.title}
            </SpText>

            <Grid container xs={12} spacing={1} justifyContent="space-between">
              <Grid item container xs={6}>
                <Grid item xs={8}>
                  <SpTextInput
                    name="start_date"
                    type="date"
                    id="startDate"
                    style={{ width: "100%", paddingTop: "0.5em" }}
                    maxValue={"2100-12-31"}
                    defaultValue={moment(dateSelected).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setDateSelected(
                        moment(e.target.value).format("YYYY-MM-DD")
                      );
                      setSelectedEvent(null);
                    }}
                    inputRef={register}
                    label={labels.groups.groupReports.firstTable.selectDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SpSelect
                    style={{ width: "100%" }}
                    displayLabel={true}
                    label={labels.groups.groupReports.firstTable.selectAtivity}
                    onChange={(e) => setSelectedEvent(e.target.value)}
                    value={selectedEvent}
                    labelStyle={{ fontSize: "2em" }}
                  >
                    <SpSelectMenuItem key={"null-key"} value={null}>
                      {labels.groups.groupReports.firstTable.selectAtivity}
                    </SpSelectMenuItem>
                    {events.map(
                      ({ id, start_time, end_time, activity_datum }, index) => (
                        <SpSelectMenuItem key={index} value={id}>
                          {`${psTranslate(
                            activity_datum.activity_type.name
                          )}:  ${moment
                            .utc(start_time, "HH:mm:ss")
                            .tz(offsetFormatted)
                            .format("HH:mm")} - ${moment
                            .utc(end_time, "HH:mm:ss")
                            .tz(offsetFormatted)
                            .format("HH:mm")}`}
                        </SpSelectMenuItem>
                      )
                    )}
                  </SpSelect>
                </Grid>
              </Grid>

              <Grid item xs={2} style={{ paddingTop: "2em" }}>
                <SpButton
                  buttonType={"accept"}
                  variant="none"
                  text={labels.groups.groupDetail.actions.save}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    await Promise.all(
                      newAvailability.map(async (item) => {
                        await updatePatientDisponibility({
                          id_patient: item.id_patient,
                          status: item.training_status,
                          date_selected: item.date_selected,
                          id_calendar_activity: item.id_calendar_activity,
                        });
                      })
                    );
                    await fetchData();
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={5}>
                <ActivityFilter
                  data={firstReports}
                  filterFunction={selectFilter}
                />
              </Grid>
              <Grid item xs={7}>
                <HealthFilter
                  data={firstReports}
                  filterFunction={selectFilter}
                />
              </Grid>
            </Grid>
            <SpDataGrid
              headers={headCellsFirst}
              rows={
                firstReportsFiltered.length > 0
                  ? firstReportsFiltered
                  : firstReports
              }
              emptyText={labels.groups.groupReports.no_patients}
            />
          </Grid>
        </Grid>

        <SpDialog
          style={{ padding: "1%" }}
          open={dialog}
          setOpen={setDialog}
          title={psTranslate(
            labels.groups.groupReports.firstTable.header.statusDetail
          )}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <SpText variant={"nameSurnameHeader"}>
                {psTranslate(
                  labels.groups.groupReports.firstTable.header.patient
                )}
              </SpText>
            </Grid>
            <Grid item xs={4}>
              <SpText>
                {detailModal?.givenName} {detailModal?.familyName}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpText variant={"nameSurnameHeader"}>
                {psTranslate(
                  labels.groups.groupReports.firstTable.header.status
                )}
              </SpText>
            </Grid>
            <Grid item xs={4}>
              <SpText>
                {getLabelFromPatientStatus({
                  status: detailModal?.status,
                })}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpText variant={"nameSurnameHeader"}>
                {psTranslate(
                  labels.groups.groupReports.firstTable.header.trainingStatus
                )}
              </SpText>
            </Grid>
            <Grid item xs={4}>
              <SpText>
                {
                  labels.groups.groupReports.firstTable.trainingStatus.find(
                    ({ key }) => key === detailModal?.training_status
                  )?.label
                }
              </SpText>
            </Grid>
            <Grid item xs={4}>
              <SpText>
                {getValues(`trainingStat${detailModal?.id}`) &&
                  titleCase(
                    labels.groups.groupReports.firstTable.trainingStatus.find(
                      ({ key }) =>
                        key === getValues(`trainingStat${detailModal?.id}`)
                    )?.label
                  )}
              </SpText>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginTop: "2em" }}
          >
            <Grid item xs={8}>
              <Controller
                render={(props) => (
                  <SpTextInput
                    style={{ width: "100%" }}
                    type="text"
                    name="training_notes"
                    label={labels.groups.groupReadiness.dialog.inputLabel}
                    value={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  />
                )}
                defaultValue={detailModal?.note}
                name={`training_notes_${detailModal?.id}`}
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <SpButton
                buttonType={"accept"}
                variant="none"
                text={labels.groups.groupReadiness.dialog.updateNotesButton}
                style={{ marginTop: "1.3em", width: "100%" }}
                onClick={async () => {
                  await updatePatientFunction({
                    data: {
                      training_notes: getValues(
                        `training_notes_${detailModal?.id}`
                      ),
                    },
                    id_patient: detailModal.id,
                  });
                }}
              />
            </Grid>
          </Grid>
        </SpDialog>
      </Grid>
    </>
  );
};

export default withSnackbar(SituationTable);
