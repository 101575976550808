import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpButton from "../../../../components/atoms/SpButton";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import {
  SpTable,
  SpTableCell,
  SpTableRow,
} from "../../../../components/bundles/SpTable";
import {
  getAssessmentMonitoringAnswers,
  getAssessmentPresentationAnswers,
} from "../../../../models/actions/Assessment";

import printContext from "../../../../utils/printContext";
import { labels, psTranslate } from "../../../shared/translations";
import * as XLSX from "xlsx";
import { mapAssessmentForExcelExport } from "./AssessmentDetailFunction";
import { downloadExcel } from "../../../../utils/common";

const AssessmentDetails = ({
  assessmentDetail,
  setLoading,
  close,
  ...props
}) => {
  const [currentAssessments, setCurrentAssessments] = useState();
  const [jsonExcel, setJsonExcel] = useState();
  const [isPrint, setIsPrint] = useState(false);
  const reference = createRef(null);

  const { presentationId, patId } = useParams();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = presentationId
        ? await getAssessmentPresentationAnswers({
            id_presentation_assessment: assessmentDetail.id,
            id_patient: patId,
          })
        : await getAssessmentMonitoringAnswers({
            id_monitoring_assessment: assessmentDetail.id,
          });

      const objetToExcel = mapAssessmentForExcelExport(response);
      setJsonExcel(objetToExcel);
      setCurrentAssessments(response);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(fetchData, []);

  const createHeaders = (category) => {
    const mainColSize = 25.0; //%
    const otherColSize = 100.0 - mainColSize; //%

    const dates = currentAssessments.dates;
    dates.sort((a, b) => b.localeCompare(a));

    const headers = [
      [
        {
          id: "category",
          numeric: false,
          disablePadding: false,
          label: psTranslate(category.name),
          isAction: false,
          rowSpan: 2,
          width: `${mainColSize}%`,
        },
      ],
      [],
    ];
    headers[0].push(
      ...dates.map((date) => {
        headers[1].push(
          ...category.columns.map((col) => ({
            id: `${date}_${col}`,
            numeric: false,
            disablePadding: false,
            label: psTranslate(col),
            isAction: false,
            width: `${otherColSize / dates.length / category.columns.length}%`,
          }))
        );
        return {
          id: date,
          numeric: false,
          disablePadding: false,
          label: moment(date).format("DD-MM-YYYY"),
          isAction: false,
          colSpan: category.columns.length,
          width: `${otherColSize / dates.length}%`,
        };
      })
    );
    return headers;
  };

  const AssessmentRow = ({ row, index, headers }) => {
    return (
      <SpTableRow key={row.id} tabIndex={index}>
        <SpTableCell>
          <SpText
            variant={isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"}
          >
            {psTranslate(row.name)}
          </SpText>
        </SpTableCell>
        {headers.map((header) => {
          const answerObj = row.answers[header.id] ?? {};
          return (
            <SpTableCell key={header.id}>
              <SpText
                variant={isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"}
              >
                {answerObj.answer ? psTranslate(answerObj.answer) : "-"}
              </SpText>
              {answerObj.note && (
                <SpText variant={isPrint ? "h4ComponentLabelPrint" : "text"}>
                  {answerObj.note}
                </SpText>
              )}
              {answerObj.possible_meaning && (
                <SpText
                  variant={isPrint ? "h4ComponentLabelPrint" : "inputError"}
                >
                  {psTranslate(answerObj.possible_meaning)}
                </SpText>
              )}
            </SpTableCell>
          );
        })}
      </SpTableRow>
    );
  };

  return (
    <>
      <SpButton
        buttonType="accept"
        style={{ maxWidth: "25%", marginBottom: "10px" }}
        onClick={() => close()}
        text={labels.patient.monitoring.closeAddAssessment}
      />

      <printContext.Provider value={{ isPrint, setIsPrint }}>
        <div ref={reference}>
          <Grid item xs={12}>
            <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}>
              {assessmentDetail.assessment?.name
                ? psTranslate(assessmentDetail.assessment.name)
                : assessmentDetail.assessment_template.name}
            </SpText>
          </Grid>
          {currentAssessments &&
            currentAssessments.categories.map((category) => {
              const headers = createHeaders(currentAssessments.categories[0]);
              console.log("headers", headers);
              return (
                <SpTable
                  key={currentAssessments.categories[0].id}
                  headCells={headers}
                  headCellsHasRows={true}
                  pagination={true}
                  rows={currentAssessments.categories[0].elements}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                >
                  <AssessmentRow headers={headers[1]} />
                </SpTable>
              );
            })}
        </div>
      </printContext.Provider>
      <SpButton
        style={{ maxWidth: "25%", marginBottom: "10px" }}
        onClick={() =>
          downloadExcel(jsonExcel, assessmentDetail?.assessment_template?.name)
        }
        text={"Download excel"}
      />
    </>
  );
};

export default withSnackbar(AssessmentDetails);
