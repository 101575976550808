/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import { getPathologies } from "../../../models/actions/Pathologies";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import SpLoader from "../../../components/atoms/SpLoader";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import moment from "moment";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { green } from "@material-ui/core/colors";
import { labels, psTranslate } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import {
  getGroupPatients,
  getHeader,
  getPatientsAndGroupsSelection,
  getPatientInjury,
  getRange,
  getPatientsSelection,
  getGroupsSelection,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  injuryLocalizationGroups,
  getAutocomplete,
} from "./patientsReportInjury/GeneralInjuryFunction";

import {
  selectPatientsInjury,
  selectedGroupsInjury,
  injuryGroupsByTissueTypes,
  selectPatientsTissueTypeInjury,
  injuryGroupsByGravity,
  injuryPatientsByGravity,
  injuryPatientProportion,
  injuryGroupsProportion,
} from "./patientsReportInjury/ReinjuryFunction";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { theme } from "../../../components/theme";

const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";

const PatientsReportsStep5Exposure = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [
    selectedGroupsFilteredByReinjury,
    setSelectedGroupsFilteredByReinjury,
  ] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [injuryGroup, setInjuryGroup] = useState([]);

  const [barCharLocalization, setBarCharLocalization] = useState([]);
  const [dataChartLocalization, setDataChartLocalization] = useState({});
  const [barCharTissue, setBarCharTissue] = useState([]);
  const [dataChartTissue, setDataChartTissue] = useState({});
  const [barCharGravity, setBarCharGravity] = useState([]);
  const [dataChartGravity, setDataChartGravity] = useState({});
  const [barCharReinjury, setBarCharReinjury] = useState([]);
  const [dataChartReinjury, setDataChartReinjury] = useState({});

  const [allRegions, setAllRegions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAreasAvailable, setAllAreasAvailable] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState([]);
  const [tissueTypes, setTissueTypes] = useState([]);
  const [fullSizeLocalization, setFullSizeLocalization] = useState(false);
  const [fullSizeTissue, setFullSizeTissue] = useState(false);
  const [fullSizeGravity, setFullSizeGravity] = useState(false);
  const [fullSizeReinjury, setFullSizeReinjury] = useState(false);
  const [loading, setLoading] = useState(false);
  const { patId, groupId } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      const tempPathologies = await getPathologies();
      let tempTissueType = [];
      tempPathologies.forEach(({ tissue_type }) => {
        if (tissue_type) tempTissueType.push(tissue_type);
      });

      // elimino i duplicati delle tissue type
      tempTissueType = tempTissueType.filter(
        (value, index, self) =>
          index === self.findIndex((t) => parseInt(t.id) === parseInt(value.id))
      );
      setTissueTypes(tempTissueType);
      const allRegionTemp = await getRegionAreaStructureLists({});
      setAllRegions(allRegionTemp?.regionList);
      setAllAreas(allRegionTemp?.areaList);
      setAllAreasAvailable(allRegionTemp?.areaList);

      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);
        setPatients(result.allPatients);
        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        let tempInjuryGroup = await getPatientInjury(
          tempGroupPatient,
          dateRange
        );

        for (let g = 0; g<tempGroupPatient.length; g++) {
          let groupP = tempGroupPatient[g]
          let groupInj = tempInjuryGroup.find(({ group }) => parseInt(group.id) === parseInt(groupP.id) )

          for (let p = 0; p < groupP?.patients.length; p++) {
            let pat = groupP?.patients[p];

            let patientId = parseInt(pat?.id);
            if (!isNaN(patientId)) {
                let inj_lst = groupInj?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
                if (inj_lst !== undefined) {
                  tempGroupPatient[g].patients[p].injuries = inj_lst;
                } 
            }
          }
        }
        setGroups(tempGroupPatient);
        setSelectedGroupsFilteredByReinjury(result.mapGroup);
        setInjuryGroup(tempInjuryGroup);
        // calcolo del paziente attuale in base al suo id
        const tempPat = result.allPatients.find(
          ({ id }) => parseInt(id) === parseInt(patId)
        );
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction(
          tempPat ? [tempPat] : [],
          "group",
          tempGroupPatient,
          tempTissueType
        );
      } else {
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );
        let tempInjuryGroup = await getPatientInjury(tempGroups, dateRange);
        setInjuryGroup(tempInjuryGroup);

        let tempInjuryGroupI = []
        for (let g of tempInjuryGroup) {
          if ( parseInt(g?.group?.id) === parseInt(groupId) ) {
            tempInjuryGroupI.push(g)
          }
        }

        for (let p = 0; p < groupsSelected?.patients.length; p++) {
          let pat = groupsSelected?.patients[p];

          let patientId = parseInt(pat?.id);
          if (!isNaN(patientId)) {
              let inj_lst = tempInjuryGroupI[0]?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
              if (inj_lst !== undefined) {
                groupsSelected.patients[p].injuries = inj_lst;
              } 
          }
        }

        setSelectedGroupsFilteredByReinjury([groupsSelected]);

        setInjuryGroup(tempInjuryGroup);
        selectionActionFunction([], "group", [groupsSelected], tempTissueType);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  /**
   * Funzione viene richiamata quando cambia la regione selezionata
   * @param newRegionSelected: nuova regione selezionata
   *
   */
  const selectRegionFunction = (newRegionSelected) => {
    // pulisco i gruppi selezionati
    if (
      selectedGroupsFilteredByReinjury.length === 0 &&
      selectedPatients.length === 0
    ) {
      setAllInjuryGroup(injuryGroup, newRegionSelected, areaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroupsFilteredByReinjury,
        newRegionSelected,
        areaSelected
      );

      let result = selectPatientsInjury(
        selectedPatients,
        newRegionSelected,
        areaSelected
      );
      result.dataChart = result.dataChart.concat(resultInjuryGroups.dataChart);
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }

    setRegionSelected(newRegionSelected);
    let tempArea = [];
    newRegionSelected.forEach((region) => {
      const tempAreas = allAreas.filter(
        (area) => area?.id_region === region?.id
      );
      tempArea = tempArea.concat(tempAreas);
    });
    setAllAreasAvailable(tempArea);
  };

  /**
   * Funzione viene richiamata quando cambia l'area selezionata
   * @param newAreaSelected: nuova area selezionata
   *
   */
  const selectAreaFuncion = (newAreaSelected) => {
    setAreaSelected(newAreaSelected);
    if (
      selectedGroupsFilteredByReinjury.length === 0 &&
      selectedPatients.length === 0
    ) {
      setAllInjuryGroup(injuryGroup, regionSelected, newAreaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroupsFilteredByReinjury,
        regionSelected,
        newAreaSelected
      );
      let result = selectPatientsInjury(
        selectedPatients,
        regionSelected,
        newAreaSelected
      );
      result.dataChart = result.dataChart.concat(resultInjuryGroups.dataChart);
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }
  };

  /**
   * Funzione che mostra tutti gli infortuni divisi per gruppo
   *
   * @param tempInjuryGroup: lista di gruppi
   * @param regions: regioni selezionate
   * @param areas: aree selezionate
   *
   */
  const setAllInjuryGroup = (tempInjuryGroup, regions, areas) => {
    let result = injuryLocalizationGroups(tempInjuryGroup, regions, areas);
    setDataChartLocalization(result.dataChart);
    setBarCharLocalization(result.barChart);
  };

  const actionAfterGroupSelection = (tempGroups) => {
    selectionActionFunction([], "group", tempGroups);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   * @param newTissueType
   */
  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroupsFilteredByReinjury,
    newTissueType = tissueTypes
  ) => {
    setSelectedGroupsFilteredByReinjury(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );

    const newSelectedGroupTemp = JSON.parse(JSON.stringify(newSelectedGroups));
    let resultInjuryGroupsLocalization = selectedGroupsInjury(
      newSelectedGroupTemp,
      regionSelected,
      areaSelected
    );
    let resultLocalization = selectPatientsInjury(
      newPatientsSelected,
      regionSelected,
      areaSelected
    );

    let resultInjuryGroupsTissue = injuryGroupsByTissueTypes(
      newSelectedGroupTemp,
      newTissueType,
      true
    );
    let resultTissue = selectPatientsTissueTypeInjury(
      newPatientsSelected,
      newTissueType
    );

    let resultInjuryGroupsReinjury = injuryGroupsProportion(newSelectedGroups);
    let resultReinjury = injuryPatientProportion(newPatientsSelected);

    let resultInjuryGroupsGravity = injuryGroupsByGravity(
      newSelectedGroupTemp,
      labels.analytics.injuryReport.gravityRange,
      true
    );
    let resultGravity = injuryPatientsByGravity(
      newPatientsSelected,
      labels.analytics.injuryReport.gravityRange
    );

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    resultLocalization.dataChart =
      resultInjuryGroupsLocalization.dataChart !== undefined
        ? resultLocalization.dataChart.concat(
            resultInjuryGroupsLocalization.dataChart
          )
        : resultLocalization.dataChart;

    resultTissue.dataChart =
      resultInjuryGroupsTissue.dataChart !== undefined
        ? resultTissue.dataChart.concat(resultInjuryGroupsTissue.dataChart)
        : resultTissue.dataChart;

    resultReinjury.dataChart =
      resultInjuryGroupsReinjury.dataChart !== undefined
        ? resultReinjury.dataChart.concat(resultInjuryGroupsReinjury.dataChart)
        : resultReinjury.dataChart;

    resultGravity.dataChart =
      resultInjuryGroupsGravity.dataChart !== undefined
        ? resultGravity.dataChart.concat(resultInjuryGroupsGravity.dataChart)
        : resultGravity.dataChart;

    setLoading(true);

    setSelectedPatients(newPatientsSelected);

    setDataChartLocalization(resultLocalization.dataChart);
    setBarCharLocalization(resultLocalization.barChart);

    setDataChartTissue(resultTissue.dataChart);
    setBarCharTissue(resultTissue.barChart);

    setDataChartReinjury(resultReinjury.dataChart);
    setBarCharReinjury(resultReinjury.barChart);

    setDataChartGravity(resultGravity.dataChart);
    setBarCharGravity(resultGravity.barChart);
    setLoading(false);
    if (newPatientsSelected.length === 0 && newSelectedGroupTemp.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, areaSelected);
    }
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      setDateRange(newDateRange);
      if (patId) {
        let tempInjuryGroup = await getPatientInjury(groups, newDateRange);
        setInjuryGroup(tempInjuryGroup);
        setSelectedGroupsFilteredByReinjury([]);
        let tempPat = [
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
        ];
        selectionActionFunction(tempPat, "group", groups);
      } else {
        let tempInjuryGroup = await getPatientInjury(
          selectedGroupsFilteredByReinjury,
          newDateRange
        );
        setInjuryGroup(tempInjuryGroup);
        selectionActionFunction([], "group", selectedGroupsFilteredByReinjury);
      }
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      <Grid item container xs={8}>
        {/* selezione dei pazienti e dei gruppi  */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}
        {groupId &&
          getGroupsSelection(
            groups,
            selectedGroupsFilteredByReinjury,
            actionAfterGroupSelection
          )}
      </Grid>
      {/* autocomplete per selezionare la regione */}
      {getAutocomplete(
        labels.analytics.injuryReport.selectRegion,
        regionSelected,
        allRegions,
        selectRegionFunction
      )}

      {/* autocomplete per selezionare l'area */}
      {regionSelected.length > 0 &&
        getAutocomplete(
          labels.analytics.injuryReport.selectArea,
          areaSelected,
          allAreasAvailable,
          selectAreaFuncion
        )}
      <Grid container item xs={12} spacing={2}>
        {/* Localization char */}
        <Grid
          container
          item
          xs={fullSizeLocalization ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.patient.graphReport.section.feedback.filters.injuryRegion}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeLocalization(!fullSizeLocalization);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeLocalization ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartLocalization}
            barChar={barCharLocalization}
          />
        </Grid>

        {/* Tissue type char */}
        <Grid
          container
          item
          xs={fullSizeTissue ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.patient.graphReport.section.feedback.filters.injuryTissue}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeTissue(!fullSizeTissue);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeTissue ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartTissue} barChar={barCharTissue} />
        </Grid>

        {/* Gravity char */}
        <Grid
          container
          item
          xs={fullSizeGravity ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .injuryGravity
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeGravity(!fullSizeGravity);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeGravity ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartGravity}
            barChar={barCharGravity}
          />
        </Grid>

        {/* Reinjury char */}
        <Grid
          container
          item
          xs={fullSizeReinjury ? 12 : 6}
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .injuryReinjuryProportion
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeReinjury(!fullSizeReinjury);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeReinjury ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartReinjury}
            barChar={barCharReinjury}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
