export function computeRollingMeanForColumns(data, windowSize, type) {
  const numColumns = Object.keys(data[0]).length - 1; // Exclude the 'name' column
  const rollingMeans = [];

  for (let i = 0; i < data.length - windowSize + 1; i++) {
    const rollingMeanObj = { name: data[i].name };

    for (let col = 1; col <= numColumns; col++) {
      const columnName = Object.keys(data[0])[col];
      const columnData = data.map((entry) => entry[columnName]);
      const window = columnData.slice(i, i + windowSize);
      const mean = window.reduce((sum, value) => sum + value, 0) / windowSize;

      rollingMeanObj[columnName] = mean;
      rollingMeanObj["type"] = type;
    }

    rollingMeans.push(rollingMeanObj);
  }

  return rollingMeans;
}

export function computeRollingStatisticsForColumns(data, windowSize, type) {
  const numColumns = Object.keys(data[0]).length - 1; // Exclude the 'name' column
  const rollingStatistics = [];

  for (let i = 0; i < data.length - windowSize + 1; i++) {
    const rollingStatsObj = { name: data[i].name, type };

    for (let col = 1; col <= numColumns; col++) {
      const columnName = Object.keys(data[0])[col];
      const columnData = data.map((entry) => entry[columnName]);
      const window = columnData.slice(i, i + windowSize);

      const mean = window.reduce((sum, value) => sum + value, 0) / windowSize;
      const squaredDifferences = window.map((value) =>
        Math.pow(value - mean, 2)
      );
      const variance =
        squaredDifferences.reduce((sum, value) => sum + value, 0) / windowSize;
      const stdDev = Math.sqrt(variance);

      rollingStatsObj[columnName] = stdDev;
      rollingStatsObj["type"] = type;
    }

    rollingStatistics.push(rollingStatsObj);
  }

  return rollingStatistics;
} // Function to calculate the mean of an array
export function calculateMean(arr) {
  return arr.reduce((sum, value) => sum + value, 0) / arr.length;
}

// Function to calculate the standard deviation of an array
export function calculateStandardDeviation(arr) {
  const mean = calculateMean(arr);
  const squaredDifferences = arr.map((value) => Math.pow(value - mean, 2));
  const variance = calculateMean(squaredDifferences);
  const standardDeviation = Math.sqrt(variance);
  return standardDeviation;
}
