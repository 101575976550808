import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";

import { SpDragDropFileUploader } from "../../../components/atoms/SpDragDropFileUploader";

import PatientsEditSidebar from "../shared/PatientsEditSidebar";
import { findPatientByPk } from "../../../models/actions/Patients";
import { getResourceFromServer } from "../../../models/actions/Professionals";
import {
  getPresentationResourcesById,
  removePresentationResourcesById,
  updatePresentationResourcesById,
} from "../../../models/actions/Presentation";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { UPLOAD_LIMIT } from "../../../utils/common";
import { uploadFile } from "../../../models/actions/awsUtils";
import { theme } from "../../../components/theme";

const util = require("util");

const StyledTransferWrapper = styled(Grid)({
  padding: "3%",
  backgroundColor: "#333333",
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
});

const PatientsPresentationResources = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [files, setFiles] = useState([]);
  const { patId, presentationId } = useParams();
  const { setLoading } = props;

  const onLoadingData = async () => {
    try {
      const result = await getPresentationResourcesById({
        id_presentation: presentationId,
      });
      setFiles(result.map(({ file }) => file));
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      if (responseDetail) {
        setCurrentPatientDetail(responseDetail);
        await onLoadingData();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadFile = async (item) => {
    try {
      const res = await getResourceFromServer({ fileName: item?.name });
      window.open(res, "_blank");
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const uploadFiles = async (files) => {
    try {
      setLoading(true);
      await Promise.all(
        files?.map(async (file) => {
          if (file) {
            if (file.size / (1024 * 1024) > UPLOAD_LIMIT) {
              throw labels.patient.resourcesUpload.errors.uploadLimit;
            }
            await uploadFile(file);
            await updatePresentationResourcesById({
              id_presentation: presentationId,
              fileName: file.name,
              fileSize: file.size,
              fileExtension:
                file.name.split(".")[file.name.split(".").length - 1],
            });
            await onLoadingData();
          }
        })
      );
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const removeFile = async (item) => {
    //remove file from server
    await removePresentationResourcesById({
      id_file: item.id,
    });

    await onLoadingData();
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12} container direction="row" spacing={1}>
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.resourcesUpload.title.toUpperCase()}
              </SpText>
              <SpText variant="h1Subtitle">
                {labels.patient.resourcesUpload.subtitle}
              </SpText>
            </Grid>
            <StyledTransferWrapper item xs={12} container direction="row">
              <Grid item xs={12}>
                <SpText variant={"h1"}>
                  {labels.patient.resourcesUpload.form.title}
                </SpText>
                <SpText variant={"h4ComponentLabel"}>
                  {labels.patient.resourcesUpload.form.subtitle} (
                  {util.format(labels.general.uploadLimitSize, UPLOAD_LIMIT)})
                </SpText>
              </Grid>
              <Grid item xs={12}>
                <SpDragDropFileUploader
                  clickText={labels.patient.resourcesUpload.dd1}
                  fileLimitText={labels.patient.resourcesUpload.dd2}
                  onLoadingData={onLoadingData}
                  onUploadFile={uploadFiles}
                  onRemove={removeFile}
                  onDownload={downloadFile}
                  files={files}
                />
              </Grid>
            </StyledTransferWrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsPresentationResources);
