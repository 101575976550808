import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SpAutocomplete from "../../components/atoms/SpAutocomplete";
import { labels, psTranslate } from "../shared/translations";
import { theme } from "../../components/theme";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpButton from "../../components/atoms/SpButton";
import SpText from "../../components/atoms/SpText";
import { getProfessionalByEmail } from "../../models/actions/Professionals";
import { getSortedAndTranslatedArray, rollbar } from "../../utils/common";
import { Checkbox, Chip } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  createTeam,
  getTeamDetails,
  updateTeam,
} from "../../models/actions/Team";

const StyledGridContainer = styled("div")({
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
  "&:disabled": {
    color: theme.colors.primary.lightBlue,
  },
});

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});

const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});

export const ManageTeam = ({
  idGroup,
  setTeams,
  teams,
  setIsEditing,
  isEditing,
  selectedTeam,
  snackbarShowErrorMessage,
  snackbarShowMessage,
}) => {
  const [searchString, setSearchString] = useState("");
  const [usersFound, setUsersFound] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [nameEdited, setNameEdited] = useState(false);
  const [listProfessional, setListProfessional] = useState([]);
  const [oldProfessional, setOldProfessional] = useState([]);
  const fetchData = async () => {
    if (isEditing) {
      const { professional_list, team_details } = await getTeamDetails({
        id_team: selectedTeam.id,
      });
      setTeamName(team_details.name);
      setListProfessional(professional_list);
      setOldProfessional(professional_list);
    } else {
      setListProfessional([]);
      setTeamName("");
    }
  };

  useEffect(() => {
    fetchData();
  }, [isEditing, selectedTeam]);

  const searchProfessionals = () => {
    if (searchString) {
      try {
        getProfessionalByEmail({ email: searchString }).then((response) => {
          if (response.length > 0) {
            setUsersFound(response);
          } else {
            setUsersFound([]);
          }
        });
      } catch (error) {
        snackbarShowErrorMessage(error);
        rollbar.error("SearchProfessionals", error);
      }
    }
  };

  const teamFunction = () => {
    if (checkValidity()) {
      if (isEditing) {
        editTeamFunction();
      } else {
        createTeamFunction();
      }
    }
  };

  const editTeamFunction = async () => {
    try {
      let professionalToRemove = oldProfessional.filter(
        ({ id }) => !listProfessional.map(({ id }) => id).includes(id)
      );

      let professionalToAdd = listProfessional.filter(
        ({ id }) => !oldProfessional.map(({ id }) => id).includes(id)
      );

      const result = await updateTeam({
        team_name: nameEdited ? teamName : null,
        id_team: selectedTeam.id,
        professional_to_remove: professionalToRemove.map(({ id }) => id),
        professional_to_add: professionalToAdd.map(({ id }) => id),
      });
      if (result.message) {
        const teamsFiltered = teams.filter(({ id }) => id !== selectedTeam.id);
        setTeams([
          ...teamsFiltered,
          {
            id: selectedTeam.id,
            name: teamName,
            professionals: listProfessional,
          },
        ]);
        snackbarShowMessage(result.message);
        setListProfessional([]);
        setTeamName("");
        setIsEditing(false);
      } else {
        snackbarShowErrorMessage(result.error);
      }
    } catch (error) {
      snackbarShowErrorMessage(error);
    }
  };

  const createTeamFunction = async () => {
    try {
      const data = {
        list_professional: listProfessional.map(({ id }) => id),
        team_name: teamName,
        id_group: idGroup,
      };
      const result = await createTeam(data);
      if (result.message) {
        setTeamName("");
        setListProfessional([]);
        snackbarShowMessage(result.message);
        setTeams([
          ...teams,
          { ...result.data, professionals: listProfessional },
        ]);
      } else {
        snackbarShowErrorMessage(result.error);
      }
    } catch (error) {
      snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - updateGroupData", error);
    }
  };

  const checkValidity = () => {
    if (teamName !== "" && listProfessional.length > 0) return true;
    snackbarShowErrorMessage("Enter team name and at least one professional");
    return false;
  };

  return (
    <StyledGridContainer>
      <Grid item xs={12}>
        <SpText variant="h1PageTitle" id={"title"}>
          {isEditing
            ? labels.groups.federationAdd.editGroup
            : labels.groups.federationAdd.addGroup}
        </SpText>
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid container direction="column" xs={5} style={{ marginTop: "1.5%" }}>
          <Grid
            container
            direction="row"
            xs={12}
            style={{ alignItems: "self-end", marginBottom: "10px" }}
          >
            <SpTextInput
              id={"searchEmail"}
              value={searchString}
              label={
                labels.groups.groupAdd.addProfessionalTable.dialog.searchText
              }
              style={{ marginLeft: "10px" }}
              onChange={(e) => setSearchString(e.currentTarget.value)}
            />
            <SpButton
              id={"searchButton"}
              text={labels.patient.patientLink.inputSearch.buttons.search}
              style={{ marginLeft: "1%" }}
              variant="none"
              buttonType="accept"
              onClick={() => searchProfessionals()}
            />
          </Grid>
          <StyledWrapperPatientsList
            item
            container
            direction="column"
            xs={12}
            style={{ marginBottom: "2%", marginLeft: "1.5%" }}
          >
            {usersFound ? (
              usersFound.map((professional, index) => (
                <StyledRowPatientFound
                  key={professional.id}
                  item
                  container
                  direction={"row"}
                  xs={12}
                >
                  <Grid item xs={6} alignSelf={"center"}>
                    <SpText variant="text">{`${professional.name} ${professional.surname} - ${professional.email}`}</SpText>
                  </Grid>
                  <Grid item xs={6}>
                    <SpButton
                      id={`professionaFound${index}`}
                      text={
                        labels.groups.groupAdd.addProfessionalTable.dialog
                          .addProfessionalButton
                      }
                      buttonType="accept"
                      variant="none"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setSearchString("");
                        setUsersFound([]);
                        setListProfessional([
                          ...listProfessional,
                          professional,
                        ]);
                      }}
                    />
                  </Grid>
                </StyledRowPatientFound>
              ))
            ) : (
              <SpText variant={"text"}>
                {
                  labels.groups.groupAdd.addProfessionalTable.dialog
                    .usersFoundPlaceholder
                }
              </SpText>
            )}
          </StyledWrapperPatientsList>
        </Grid>
        <Grid container direction="column" xs={1} alignItems="center">
          <Grid item xs={12}>
            <ArrowForwardIcon style={{ color: "#FFF" }} />
          </Grid>
        </Grid>
        <Grid item xs={6} direction="column">
          <Grid container direction="row" xs={12} style={{ marginLeft: "1%" }}>
            <SpTextInput
              id={"teamName"}
              value={teamName}
              label={labels.groups.federationAdd.teamName}
              style={{ marginLeft: "1%" }}
              onChange={(e) => {
                setNameEdited(true);
                setTeamName(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid container direction="row" xs={12} style={{ marginLeft: "1%" }}>
            <SpAutocomplete
              multiple
              style={{ marginLeft: "1%" }}
              formControlWidth={"100%"}
              selectPlaceholder={labels.bundles.assessment.selectElements}
              options={getSortedAndTranslatedArray(listProfessional)}
              getOptionLabel={(option) =>
                `${option.givenName} ${option.familyName}`
              }
              getOptionSelected={(opt, value) => opt.id === value?.id}
              value={listProfessional}
              onChange={(_, newValue) => {
                setListProfessional(newValue);
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`option_${props.id}`}
                  style={{
                    color: option.selected
                      ? theme.colors.primary.white
                      : theme.colors.primary.black,
                  }}
                >
                  <Checkbox
                    name={props.id}
                    icon={<CheckBoxOutlineBlankIcon small />}
                    checkedIcon={<CheckBoxIcon small />}
                    style={{
                      marginRight: 5,
                      color: option.selected
                        ? theme.colors.primary.white
                        : theme.colors.primary.black,
                    }}
                    checked={option.selected}
                  />
                  {psTranslate(props?.name)}
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.email}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: theme.colors.primary.white,
                    }}
                    label={option.email}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SpButton
              id={"addTeamFunction"}
              text={
                isEditing
                  ? labels.groups.federationAdd.edit
                  : labels.groups.federationAdd.add
              }
              style={{ marginLeft: "2%", marginTop: "2%" }}
              variant="none"
              buttonType="accept"
              onClick={() => teamFunction()}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledGridContainer>
  );
};
