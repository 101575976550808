import { Avatar, Grid, makeStyles } from "@material-ui/core";
import React, { createRef, useEffect, useRef, useState } from "react";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import { labels, psTranslate } from "../../shared/translations";
import { getColorFromPatientStatus, titleCase } from "../../../utils/common";
import moment from "moment";
import {
  getMonitoringData,
  getMonitoringDataPatients,
} from "../../../models/actions/Monitoring";

import {
  ChangeAvailabilityDialog,
  LegendAvailability,
  LegendStatus,
  SelectPeriod,
} from "./SituationTableHelper";
import { getPatientDisponibility } from "../../../models/actions/Disponibility";
export const MonitoringTable = ({
  patients,
  setLoading,
  fetchDataSituationTable,
  snackbarShowErrorMessage,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        height: 10,
      },
      "&::-webkit-scrollbar-track": {
        background: "#012e29",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#31caad",
      },
    },
  }));

  const scrollingDivMonitoring = createRef();
  const scrollingDivAvailability = createRef();
  const classes = useStyles();
  const [filterStartDate, setFilterStartDate] = useState(
    moment().subtract(1, "months")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment());
  const [monitoringData, setMonitoringData] = useState([]);
  const [disponibility, setDisponibility] = useState([]);
  const [openToggleAvailability, setOpenToggleAvailability] = useState(false);

  const [availabilitySelected, setAvailabilitySelected] = useState({
    status: "fit",
    date: moment(),
    patient: "",
  });
  const fetchData = async () => {
    try {
      setLoading(true);
      if (patients) {
        const res = await getPatientDisponibility({
          id_patients: patients.map(({ id }) => id),
          start_date: filterStartDate,
          end_date: filterEndDate,
        });
        setDisponibility(res);
      }

      if (patients) {
        let listMontoring = [];
        const resMonitoringPatients = await getMonitoringDataPatients({
          filter_start_date: filterStartDate,
          filter_end_date: filterEndDate,
          id_patients: patients.map(({ id }) => id),
        });
        listMontoring = resMonitoringPatients.map(({ id_patient, data }) => ({
          id_patient: id_patient,
          data: data,
        }));

        setMonitoringData(listMontoring);
        const elMonitoring = document.getElementById("scrollingDivMonitoring");
        if (elMonitoring) elMonitoring.scrollLeft = elMonitoring.scrollWidth;
        const elAvailability = document.getElementById(
          "scrollingDivAvailability"
        );
        if (elAvailability)
          elAvailability.scrollLeft = elAvailability.scrollWidth;
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterStartDate, filterEndDate, patients]);

  const renderActivityMonitoring = () => {
    return monitoringData[0]?.data?.monitoring?.calendar_status?.map(
      (calendarStatusItem, index) => {
        return (
          <Grid
            item
            xs
            container
            direction="column"
            style={{ height: "100%", flexBasis: 0 }}
          >
            <Grid
              id={calendarStatusItem.date}
              item
              style={{
                minHeight: 40,
                minWidth: 80,
                border: "1px solid #31caad",
                padding: 4,
              }}
            >
              <SpText textAlign={"center"} variant="h4ComponentLabel">
                {calendarStatusItem.date}
              </SpText>
            </Grid>

            {patients.map(({ id }) => {
              let status = "";
              const monitoringPatient = monitoringData.find(
                ({ id_patient }) => id_patient === id
              );
              if (monitoringPatient)
                status =
                  monitoringPatient?.data?.monitoring?.calendar_status?.find(
                    ({ date }) => date === calendarStatusItem.date
                  );

              return (
                <Grid
                  item
                  container
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    minHeight: 40,
                    minWidth: 80,
                    border: "1px solid #31caad",
                    padding: 3,
                    backgroundColor: getColorFromPatientStatus({
                      status: status?.key,
                    }),
                  }}
                ></Grid>
              );
            })}
          </Grid>
        );
      }
    );
  };

  const renderDisponibility = () => {
    return monitoringData[0]?.data?.monitoring?.calendar_status?.map(
      (calendarStatusItem, index) => {
        return (
          <Grid
            item
            xs
            container
            direction="column"
            style={{ height: "100%", flexBasis: 0 }}
          >
            <Grid
              id={calendarStatusItem.date}
              item
              style={{
                minHeight: 40,
                minWidth: 80,
                border: "1px solid #31caad",
                padding: 4,
              }}
            >
              <SpText textAlign={"center"} variant="h4ComponentLabel">
                {calendarStatusItem.date}
              </SpText>
            </Grid>

            {patients.map(({ id, givenName, familyName }) => {
              const dispFound = disponibility.find(({ id_patient, date }) => {
                const momentDate1 = moment(date, "YYYY-MM-DD");
                const momentDate2 = moment(
                  calendarStatusItem.date,
                  "DD/MM/YYYY"
                );
                return (
                  id_patient == id && momentDate1.isSame(momentDate2, "day")
                );
              });
              const disponibilitySelected = dispFound
                ? dispFound?.patient_disponibility
                : "fit";
              return (
                <Grid
                  item
                  container
                  onClick={() => {
                    setAvailabilitySelected({
                      status: disponibilitySelected,
                      date: moment(calendarStatusItem.date, "DD/MM/YYYY"),
                      patient: `${givenName} ${familyName}`,
                      id_patient: id,
                    });
                    setOpenToggleAvailability(true);
                  }}
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    minHeight: 40,
                    minWidth: 80,
                    border: "1px solid #31caad",
                    padding: 3,
                    backgroundColor:
                      disponibilitySelected === "fit" ? "green" : "red",
                  }}
                >
                  <SpIconButton
                    variant="lightBlue"
                    style={{
                      marginLeft: 0,
                      cursor: "default",
                      height: 30,
                      width: 80,
                      marginBottom: "0px",
                    }}
                  ></SpIconButton>
                </Grid>
              );
            })}
          </Grid>
        );
      }
    );
  };

  return (
    <Grid container direction="column">
      <div style={{ width: "100%" }}>
        <SelectPeriod
          setFilterStartDate={setFilterStartDate}
          filterStartDate={filterStartDate}
          setFilterEndDate={setFilterEndDate}
          filterEndDate={filterEndDate}
        />
      </div>

      {monitoringData && (
        <div style={{ width: "100%" }}>
          {/* Group availability*/}
          <Grid
            item
            xs={12}
            direction="row"
            container
            style={{ paddingTop: "2em" }}
          >
            <Grid xs={6}>
              <SpText variant={"h1PageTitle"}>
                {labels.patient.monitoring.table.groupAvailability}
              </SpText>
            </Grid>
            <LegendAvailability />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ width: "100%", marginBottom: 5 }}
          >
            <Grid item xs={2} container direction="column">
              <Grid
                item
                xs
                style={{
                  maxHeight: 40,
                  border: "1px solid #31caad",
                  padding: 4,
                }}
              />

              {patients?.map(({ givenName, familyName }) => (
                <Grid
                  item
                  xs
                  style={{
                    maxHeight: 40,
                    border: "1px solid #31caad",
                    padding: 4,
                  }}
                >
                  <SpText variant="h4ComponentLabel">{`${titleCase(
                    givenName
                  )} ${titleCase(familyName)}`}</SpText>
                </Grid>
              ))}
            </Grid>

            <Grid
              id={"scrollingDivAvailability"}
              ref={scrollingDivAvailability}
              item
              xs={10}
              container
              direction="row"
              wrap={"nowrap"}
              className={classes.root}
              style={{
                height: "100%",
                overflowX: "scroll",
                maxWidth: 1200,
              }}
            >
              {renderDisponibility()}
            </Grid>
          </Grid>
          {/* Group activity */}
          <Grid
            item
            xs={12}
            direction="row"
            container
            style={{ paddingTop: "2em" }}
          >
            <Grid item xs={6}>
              <SpText variant={"h1PageTitle"}>
                {labels.patient.monitoring.table.groupActivities}
              </SpText>
            </Grid>
            <LegendStatus />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ width: "100%", marginBottom: 5 }}
          >
            <Grid item xs={2} container direction="column">
              <Grid
                item
                xs
                style={{
                  maxHeight: 40,
                  border: "1px solid #31caad",
                  padding: 4,
                }}
              />

              {patients?.map(({ givenName, familyName }) => (
                <Grid
                  item
                  xs
                  style={{
                    maxHeight: 40,
                    border: "1px solid #31caad",
                    padding: 4,
                  }}
                >
                  <SpText variant="h4ComponentLabel">{`${titleCase(
                    givenName
                  )} ${titleCase(familyName)}`}</SpText>
                </Grid>
              ))}
            </Grid>
            <Grid
              id={"scrollingDivMonitoring"}
              ref={scrollingDivMonitoring}
              item
              xs={10}
              container
              direction="row"
              wrap={"nowrap"}
              className={classes.root}
              style={{
                height: "100%",
                overflowX: "scroll",
                maxWidth: 1200,
              }}
            >
              {renderActivityMonitoring()}
            </Grid>
          </Grid>
        </div>
      )}

      <ChangeAvailabilityDialog
        openToggleAvailability={openToggleAvailability}
        setOpenToggleAvailability={setOpenToggleAvailability}
        availabilitySelected={availabilitySelected}
        updateEvent={async () => {
          await fetchDataSituationTable();
        }}
      />
    </Grid>
  );
};
