import React, { useEffect, useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import CloseIcon from "@material-ui/icons/Close";

import SpLoader from "../../../components/atoms/SpLoader";
import { FilterAutocomplete } from "../../patients/patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import {
  TextField,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import moment from "moment";
import {
  getProfessionalSchedule,
  updateProfessionalSchedule,
} from "../../../models/actions/Organization";
const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const BookingRequests = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null);
  const [schedule, setSchedule] = useState({});

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    largeAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));

  const classes = useStyles();

  const handleSlotChange = (day, slotIndex, field, value) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].map((slot, index) =>
        index === slotIndex ? { ...slot, [field]: value } : slot
      ),
    }));
  };

  const addSlot = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: [...(prevSchedule[day] || []), { startTime: null, endTime: null }],
    }));
  };

  const removeSlot = (day, slotIndex) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].filter((_, index) => index !== slotIndex),
    }));
  };

  const checkValue = () => {
    let ret = true;
    if (Object.keys(schedule).length == 0) ret = false;
    Object.keys(schedule).map((day) => {
      if (schedule[day].length == 0) ret = false;
    });
    if (!endDate || moment(endDate).isBefore(startDate)) ret = false;
    return ret;
  };

  const fetchData = async () => {
    const ret = await getProfessionalSchedule({});
    setStartDate(ret.startDate);
    setEndDate(ret.endDate);
    setSelectedDays(
      labels.week.filter(({ key }) => Object.keys(ret.schedule).includes(key))
    );
    setSchedule(ret.schedule);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"Schedule Manager"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>

      <div style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SpTextInput
              name="start_date"
              label={labels.patient.monitoring.table.startDate}
              type="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              maxValue="2100-12-31"
              variant="text"
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <SpTextInput
              name="end_date"
              label={labels.patient.monitoring.table.endDate}
              type="date"
              maxValue="2100-12-31"
              variant="text"
              style={{ width: "100%" }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FilterAutocomplete
              placeholder={labels.patient.controlQuestions.daysOfWeek}
              value={selectedDays}
              onChange={(_, newValue) => {
                setSelectedDays(newValue);
              }}
              renderOptions={selectedDays}
              options={labels.week}
            />
          </Grid>
        </Grid>

        {selectedDays.map(({ key, name }) => (
          <Card key={key} style={{ marginTop: 20 }}>
            <CardContent className={classes.content}>
              <Typography variant="h6">{name}</Typography>

              {(schedule[key] || []).map((slot, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs={4}>
                    <SpTextInput
                      style={{ width: "100%", paddingTop: "0.5em" }}
                      type="time"
                      name="start_time"
                      min="05:00"
                      max="22:00"
                      defaultValue={moment().format("HH:mm")}
                      label={
                        labels.patient.viewPlan.addActivity.inputForm.start_time
                      }
                      value={slot.startTime}
                      onChange={(e) =>
                        handleSlotChange(
                          key,
                          index,
                          "startTime",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SpTextInput
                      style={{ width: "100%", paddingTop: "0.5em" }}
                      type="time"
                      name="end_time"
                      min="05:00"
                      max="22:00"
                      defaultValue={moment().format("HH:mm")}
                      label={
                        labels.patient.viewPlan.addActivity.inputForm.end_time
                      }
                      value={slot.endTime}
                      onChange={(e) =>
                        handleSlotChange(key, index, "endTime", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "2em" }}>
                    <IconButton onClick={() => removeSlot(key, index)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Button
                onClick={() => addSlot(key)}
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ marginTop: 10 }}
              >
                {labels.analytics.add}
              </Button>
            </CardContent>
          </Card>
        ))}

        <SpButton
          type="submit"
          style={{ height: 20 }}
          buttonType={"accept"}
          variant="none"
          onClick={async () => {
            setLoading(true);
            if (checkValue()) {
              const ret = await updateProfessionalSchedule({
                data: {
                  schedule,
                  startDate,
                  endDate,
                },
              });
              if (ret?.message) {
                props.snackbarShowMessage(ret.message);
              }
              if (ret?.error) {
                props.snackbarShowErrorMessage(ret.error);
              }
            } else {
              props.snackbarShowErrorMessage(
                labels.patient.controlQuestions.insertAllData
              );
            }
            setLoading(false);
          }}
          text={labels.groups.groupDetail.actions.save}
        />
      </div>
    </Grid>
  );
};
export default withSnackbar(BookingRequests);
