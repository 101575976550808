import { Grid } from "@material-ui/core";
import { labels } from "../../../shared/translations";
import SpText from "../../../../components/atoms/SpText";
import React, { useEffect, useState } from "react";

import SpDataGrid from "../../../../components/atoms/SpDataGrid";
import { theme } from "../../../../components/theme";
import moment from "moment";
import { getStandardDeviation, titleCase } from "../../../../utils/common";
const PATIENT_KEY = "patient";
const MAX_DECIMALS = 2;

const TableRow = ({ data, maxValues }) => {
  let fillPercentage = 100;
  if (maxValues) {
    fillPercentage = Math.floor((data.value / maxValues[data.field]) * 100);
    fillPercentage = isNaN(fillPercentage) ? 0 : fillPercentage;
  }
  //A simple antialiasing trick
  const antialisingIncrease = fillPercentage
    ? Math.min(2, 0.1 * (Object.keys(maxValues ?? {}).length + 1))
    : 0;

  //Create data row
  const value = data.value;
  return (
    <div
      style={{
        border: `1px solid ${theme.colors.primary.lightBlue}`,
        height: 32,
        minWidth: "100%",
      }}
    >
      <div
        style={{
          background: `linear-gradient(70deg, ${
            theme.colors.primary.lightBlue
          } ${fillPercentage}%, ${theme.colors.primary.black} ${
            fillPercentage + antialisingIncrease
          }%)`,
          height: 30,
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: theme.colors.primary.white,
            fontWeight: 600,
            fontSize: "0.8rem",
            display: "flex",
          }}
        >
          {isNaN(value) ? value : parseFloat(value).toFixed(MAX_DECIMALS)}
        </span>
      </div>
    </div>
  );
};

const createColumnHeaders = (data) => {
  //Compute max values for each column
  const maxValues = {};
  Object.values(data).forEach((values) => {
    Object.entries(values).forEach(([dataKey, dataValue]) => {
      if (!maxValues[dataKey] || maxValues[dataKey] < dataValue)
        maxValues[dataKey] = dataValue;
    });
  });

  //Prepare headers
  const headers = [
    ...Object.values(data).reduce((accum, vals) => {
      Object.keys(vals).forEach((el) => {
        accum.add(el);
      });
      return accum;
    }, new Set()),
  ];

  const newData = [];
  newData.push({
    id: PATIENT_KEY,
    field: PATIENT_KEY,
    headerName: labels.groups.groupReports.firstTable.header.patient,
    editable: false,
    minWidth: 250,
    maxWidth: 350,
    height: 500,
    fixed: true,
    fixedPosition: "left",
    renderCell: (params) => {
      return <SpText variant="h4ComponentLabel">{params?.id}</SpText>;
    },
  });

  newData.push(
    ...headers.map((header) => ({
      id: header,
      field: header,
      headerName: header,
      editable: false,
      minWidth: 100,
      maxWidth: 250,
      flex: 1,
      renderCell: (params) => <TableRow data={params} maxValues={maxValues} />,
    }))
  );
  return newData;
};

const createRowsData = (data) => {
  //Return row data
  const retObject = Object.entries(data).map(([patientKey, values]) => ({
    id: patientKey,
    [PATIENT_KEY]: patientKey,
    ...values,
  }));

  return retObject;
};

const StatisticsTable = ({ data }) => {
  return (
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <SpDataGrid
        headers={createColumnHeaders(data)}
        rows={createRowsData(data)}
      />
    </Grid>
  );
};

const ReportStatistics = ({ data, currStudyParameters }) => {
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    let objetToData = {};
    Object.keys(data).map((athlete) => {
      let sum = 0;
      let values = [];
      const lastDate = Object.keys(data[athlete]).sort(function (a, b) {
        return new moment(b) - moment(a);
      })[0];
      if (athlete.includes("TEMPORAL")) {
        Object.keys(data[athlete]).map((date) => {
          sum += data[athlete][date];
          values.push(data[athlete][date]);
        });

        objetToData[`${athlete.split("-")[0]} - ${athlete.split("-")[2]}`] = {
          [`Latest ${moment(lastDate).format("DD/MM/YYYY")}`]:
            data[athlete][lastDate],
          Avarage: (sum / Object.keys(data[athlete]).length).toFixed(2),
          [`Standard deviation`]: getStandardDeviation(values),
        };
      } else {
        objetToData[`${athlete.split("-")[0]} - ${athlete.split("-")[2]}`] = {
          ...objetToData[`${athlete.split("-")[0]} - ${athlete.split("-")[2]}`],
          [`${titleCase(athlete.split("-")[1].split("-")[0])}`]:
            data[athlete][lastDate],
        };
      }
    });
    setDataTable(objetToData);
  }, [data]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ paddingTop: "25px", paddingRight: "25px" }}
    >
      <StatisticsTable data={dataTable} />
    </Grid>
  );
};

export default ReportStatistics;
