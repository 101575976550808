import { Chip, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import moment from "moment";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpButton from "../../components/atoms/SpButton";
import {
  createOrganizationAuth0,
  createUserAuth0,
  getAuth0Roles,
  getTokenOrganization,
} from "../../models/actions/Common";
import SpAutocomplete from "../../components/atoms/SpAutocomplete";
import { theme } from "../../components/theme";
import { labels } from "../shared/translations";
import { getAllProfessionals } from "../../models/actions/Professionals";
import {
  createGroupByCollection,
  getTeamCollection,
} from "../../models/actions/Collection";
import {
  duplicateTemplateAllProfessional,
  getAssessmentTemplates,
} from "../../models/actions/Assessment";

export const CreateOrganization = ({
  setLoading,
  snackbarShowMessage,
  snackbarShowErrorMessage,
  setUpdateOrganization,
}) => {
  const nameRef = useRef();
  const nMaxPatientsRef = useRef();
  const expiryDateRef = useRef();

  const createOrganizationFunction = async () => {
    if (
      nameRef.current.value &&
      nMaxPatientsRef.current.value &&
      expiryDateRef.current.value
    ) {
      const nameFormatted = nameRef.current.value
        .toLowerCase()
        .replace(" ", "_");

      try {
        setLoading(true);
        const result = await createOrganizationAuth0({
          name: nameFormatted,
          expiry_date: `${moment(expiryDateRef.current.value).format(
            "YYYY-MM-DD"
          )}T23:59:59+01:00`,
          n_max_patients: nMaxPatientsRef.current.value,
        });
        setUpdateOrganization(true);
        if (result.message) {
          nameRef.current.value = "";
          nMaxPatientsRef.current.value = "";
          expiryDateRef.current.value = "";
          snackbarShowMessage(result.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        snackbarShowErrorMessage(
          "Errore durante la creazione dell'organizzazione"
        );
      }
    } else {
      snackbarShowErrorMessage("Inserisci tutti i campi prima di continuare");
    }
  };

  return (
    <>
      <SpText variant="h2PageSubtitleColored">
        {labels.manageUsers.newOrganizationTitle}
      </SpText>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="name"
            displayLabel={true}
            label={labels.manageUsers.name}
            style={{
              width: "100%",
            }}
            inputRef={nameRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="nMaxPatients"
            type="number"
            displayLabel={true}
            label={labels.manageUsers.naMaxpPatients}
            style={{
              width: "100%",
            }}
            inputRef={nMaxPatientsRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="expiryDate"
            type="date"
            displayLabel={true}
            label={labels.manageUsers.expiryDate}
            style={{
              width: "100%",
            }}
            inputRef={expiryDateRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            buttonType={"accept"}
            style={{ marginTop: "1em" }}
            onClick={createOrganizationFunction}
            text={labels.manageUsers.addNewOrganizzationButton}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CreateGroup = ({
  setLoading,
  snackbarShowMessage,
  snackbarShowErrorMessage,
  setUpdateOrganization,
  updateOrganization,
  ...props
}) => {
  const nameRef = useRef();

  const [professionals, setProfessionals] = useState([]);
  const [professionalsSelected, setProfessionalsSelected] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);

  const getProfessionalFunction = async () => {
    const temp = await getAllProfessionals({});
    setProfessionals(temp);
  };

  const getTeamsCollection = async () => {
    const temp = await getTeamCollection({});
    setTeams(temp);
  };

  useEffect(() => {
    getProfessionalFunction();
    getTeamsCollection();
  }, []);

  const createGroup = async () => {
    setLoading(true);
    if (
      teamSelected &&
      professionalsSelected.length > 0 &&
      nameRef.current.value !== ""
    ) {
      try {
        const res = await createGroupByCollection({
          group_name: nameRef.current.value,
          professionals: professionalsSelected.map(({ id }) => id),
          id_team_collection: teamSelected.id,
        });
        if (res.message) {
          setTeamSelected([]);
          setProfessionalsSelected([]);
          nameRef.current.value = "";
          snackbarShowMessage(res.message);
        } else {
          snackbarShowErrorMessage("Errore durante la creazione del gruppo");
        }
        setLoading(false);
      } catch (error) {
        setLoading(true);
        snackbarShowErrorMessage("Errore durante la creazione del gruppo");
      }
    } else {
      setLoading(false);
      snackbarShowErrorMessage("Inserisci tutte le informazioni");
    }
  };

  return (
    <Grid item container direction="row" xs={12} style={{ paddingTop: "2em" }}>
      <SpText variant="h2PageSubtitleColored">
        {labels.manageUsers.createGroupTilte}
      </SpText>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            style={{ paddingTop: "0.6em", width: "100%" }}
            id="groupName"
            displayLabel={true}
            label={labels.manageUsers.name}
            inputRef={nameRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpAutocomplete
            isRequired={true}
            id="professionalsGroup"
            multiple
            label={labels.manageUsers.professionals}
            formControlWidth={"100%"}
            value={professionalsSelected}
            onChange={(_, value) => setProfessionalsSelected(value)}
            options={professionals}
            getOptionLabel={(param) => param.email}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={
                    professionals?.find((param) => param.id === option.id)
                      ?.email
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpAutocomplete
            isRequired={true}
            id="teamGroup"
            label={labels.manageUsers.teams}
            formControlWidth={"100%"}
            value={teamSelected}
            onChange={(_, value) => setTeamSelected(value)}
            options={teams}
            getOptionLabel={(param) => param.team_name}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={
                    teams?.find((param) => param.id === option.id)?.team_name
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id="createGroupButton"
            buttonType={"accept"}
            style={{ marginTop: "2em" }}
            onClick={createGroup}
            text={labels.manageUsers.createGroupTilte}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CreateUser = ({
  setLoading,
  snackbarShowMessage,
  snackbarShowErrorMessage,
  setUpdateOrganization,
  updateOrganization,
  ...props
}) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState();
  const [roles, setRoles] = useState([]);
  const [rolesSelected, setRolesSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const [typeUserSelected, setTypeUserSelected] = useState(null);
  const [typeUser, setTypeUser] = useState([
    { id: 3, type: "Pro" },
    { id: 2, type: "Advanced" },
    { id: 1, type: "Basic" },
  ]);
  const nameRef = useRef();
  const emailRef = useRef();
  const passRef = useRef();
  const surnameRef = useRef();

  const getUserOrganizations = async () => {
    try {
      const organizations = await getTokenOrganization({});
      setOrganizations(organizations);
    } catch (error) {
      snackbarShowErrorMessage(
        "Errore durante il recupero delle organizzazioni"
      );
    }
  };

  useEffect(() => {
    if (updateOrganization) {
      getUserOrganizations();
      setUpdateOrganization(false);
    }
  }, [updateOrganization]);

  useEffect(() => {
    setLoading(true);
    // Recupera le informazioni sull'utente e le organizzazioni a cui appartiene

    const getRoles = async () => {
      try {
        const response = await getAuth0Roles({});
        setRoles(response);
      } catch (error) {
        snackbarShowErrorMessage("Errore durante il recupero dei ruoli");
      }
    };

    const getAllProgessionals = async () => {
      const tempUsers = await getAllProfessionals();
      setUsers(tempUsers);
    };
    getAllProgessionals();
    getRoles();
    getUserOrganizations();
    setLoading(false);
  }, []);

  const createUser = async () => {
    setLoading(true);
    const nameTemp = nameRef.current.value;
    const surnameTemp = surnameRef.current.value;
    const emailTemp = emailRef.current.value;
    const passTemp = passRef.current.value;
    if (
      expiryDate &&
      moment(expiryDate) > moment() &&
      nameTemp &&
      surnameTemp &&
      emailTemp &&
      passTemp &&
      rolesSelected &&
      organizationSelected
    ) {
      const userMetadata = {
        expiry_date: `${moment(expiryDate).format(
          "YYYY-MM-DD"
        )}T23:59:59+01:00`,
        name: nameTemp,
        surname: surnameTemp,
        club: organizationSelected.name,
        subscription_type: typeUserSelected.type,
      };
      try {
        const result = await createUserAuth0({
          email: emailTemp,
          password: passTemp,
          metadata: userMetadata,
          roles: rolesSelected.map(({ id }) => id),
          organization: organizationSelected.id,
          type: typeUserSelected.type,
        });
        if (result.message) {
          snackbarShowMessage(result.message);
          setUsers([
            ...users,
            {
              name: nameTemp,
              surname: surnameTemp,
              club: organizationSelected.name,
              email: emailTemp,
              expiry_date: moment(expiryDate).format("YYYY-MM-DD"),
              roles: rolesSelected.map(({ name }) => name),
            },
          ]);
          nameRef.current.value = "";
          surnameRef.current.value = "";
          emailRef.current.value = "";
          passRef.current.value = "";
          setExpiryDate();
          setRolesSelected([]);
          setOrganizationSelected();
        } else {
          snackbarShowErrorMessage(result.error);
        }
      } catch (error) {
        snackbarShowErrorMessage("Error during user creation");
      }
    } else {
      snackbarShowErrorMessage("Insert all fields");
    }
    setLoading(false);
  };

  return (
    <Grid item container direction="row" xs={12} style={{ paddingTop: "2em" }}>
      <SpText variant="h2PageSubtitleColored">
        {labels.manageUsers.addUser}
      </SpText>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="name"
            displayLabel={true}
            label={labels.manageUsers.name}
            style={{
              width: "100%",
            }}
            inputRef={nameRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="surname"
            displayLabel={true}
            label={labels.manageUsers.surname}
            style={{
              width: "100%",
            }}
            inputRef={surnameRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="email"
            displayLabel={true}
            label={labels.manageUsers.email}
            style={{
              width: "100%",
            }}
            inputRef={emailRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            type="password"
            id="password"
            label={labels.manageUsers.password}
            displayLabel={true}
            style={{
              width: "100%",
            }}
            inputRef={passRef}
          />
        </Grid>
        <Grid item xs={2}>
          <SpTextInput
            isRequired={true}
            id="expiryDate"
            type="date"
            displayLabel={true}
            label={labels.manageUsers.expiryDate}
            style={{
              width: "100%",
            }}
            onChange={(e) => {
              setExpiryDate(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2}>
          <SpAutocomplete
            isRequired={true}
            id="group"
            label={labels.manageUsers.group}
            formControlWidth={"100%"}
            value={organizationSelected}
            onChange={(_, value) => setOrganizationSelected(value)}
            options={organizations}
            getOptionLabel={(param) => param.name}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={
                    organizations?.find((param) => param.id === option.id)?.name
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpAutocomplete
            isRequired={true}
            multiple
            label={labels.manageUsers.roles}
            formControlWidth={"100%"}
            value={rolesSelected}
            onChange={(_, value) => setRolesSelected(value)}
            options={roles}
            getOptionLabel={(param) => param.name}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={roles?.find((param) => param.id === option.id)?.name}
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpAutocomplete
            isRequired={true}
            label={labels.manageUsers.userType}
            formControlWidth={"100%"}
            value={typeUserSelected}
            onChange={(_, value) => setTypeUserSelected(value)}
            options={typeUser}
            getOptionLabel={(param) => param.type}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={
                    typeUser?.find((param) => param.id === option.id)?.type
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            buttonType={"accept"}
            style={{ marginTop: "2em" }}
            onClick={createUser}
            text={labels.manageUsers.addUserButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DuplicateTemplate = ({
  setLoading,
  snackbarShowMessage,
  snackbarShowErrorMessage,
  ...props
}) => {
  const [template, setTemplate] = useState([]);
  const [templateSelected, setTemplateSelected] = useState([]);

  const fetchData = async () => {
    const templateTemp = await getAssessmentTemplates({ is_handbook: 0 });
    setTemplate(templateTemp);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid item container direction="row" xs={12} style={{ paddingTop: "2em" }}>
      <SpText variant="h2PageSubtitleColored">
        {labels.manageUsers.duplicateTemplate}
      </SpText>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={8}>
          <SpAutocomplete
            isRequired={true}
            id="professionalsGroup"
            multiple
            label={"Template"}
            formControlWidth={"100%"}
            value={templateSelected}
            onChange={(_, value) => setTemplateSelected(value)}
            options={template}
            getOptionLabel={(param) => param.name}
            getOptionSelected={(option, value) => option === value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={
                    template?.find((param) => param.id === option.id)?.name
                  }
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id="createGroupButton"
            buttonType={"accept"}
            style={{ marginTop: "2em" }}
            onClick={async () => {
              setLoading(true);
              const templateTemp = templateSelected.map(({ id, name }) => ({
                id_template: id,
                new_name: name,
              }));
              const ret = await duplicateTemplateAllProfessional({
                templates: templateTemp,
              });
              if (ret.message) {
                snackbarShowMessage(ret.message);
                setTemplateSelected([]);
              } else {
                snackbarShowErrorMessage("Error during sharing template");
              }

              setLoading(false);
            }}
            text={labels.manageUsers.duplicateTemplate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
