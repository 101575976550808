import Grid from "@material-ui/core/Grid";
import React from "react";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../../shared/translations";
import SpCheckbox from "../../../../components/atoms/SpCheckbox";
import SpText from "../../../../components/atoms/SpText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";

export const WorkloadChecks = ({ filters, setFilters }) => (
  <Grid container xs={12} direction="row">
    {/* MONOTONY */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.MONOTONY}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkMonotony}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkMonotony: value },
          })
        }
      />
      {filters.workloadStudy.checkMonotony && (
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.period}
          // labelPlacementLeft={true}
          value={filters.workloadStudy.monotonyPeriod}
          onChange={(_, value) => {
            setFilters({
              ...filters,
              workloadStudy: {
                ...filters.workloadStudy,
                monotonyPeriod: value,
              },
            });
          }}
          type="number"
        />
      )}
    </Grid>
    {/* STRAIN */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.STRAIN}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkStrain}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkStrain: value },
          })
        }
      />

      {filters.workloadStudy.checkStrain && (
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.period}
          // labelPlacementLeft={true}
          value={filters.workloadStudy.strainPeriod}
          onChange={(_, value) => {
            setFilters({
              ...filters,
              workloadStudy: { ...filters.workloadStudy, strainPeriod: value },
            });
          }}
          type="number"
        />
      )}
    </Grid>

    {/* ACWRRA */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.ACWRRA}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkACWRRA}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkACWRRA: value },
          })
        }
      />

      {filters.workloadStudy.checkACWRRA && (
        <>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.acute_load
            }
            // labelPlacementLeft={true}
            value={filters.workloadStudy.ACWRRAAcute}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: { ...filters.workloadStudy, ACWRRAAcute: value },
              });
            }}
            type="number"
          />
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.chronic_load
            }
            // labelPlacementLeft={true}
            value={filters.workloadStudy.ACWRRAChronic}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  ACWRRAChronic: value,
                },
              });
            }}
            type="number"
          />
        </>
      )}
    </Grid>

    {/* ACWREWMA */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.ACWREWMA}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkACWREWMA}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkACWREWMA: value },
          })
        }
      />

      {filters.workloadStudy.checkACWREWMA && (
        <>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.acute_load
            }
            // labelPlacementLeft={true}
            value={filters.workloadStudy.ACWREWMAAcute}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  ACWREWMAAcute: value,
                },
              });
            }}
            type="number"
          />
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.chronic_load
            }
            // labelPlacementLeft={true}
            value={filters.workloadStudy.ACWREWMAChronic}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  ACWREWMAChronic: value,
                },
              });
            }}
            type="number"
          />
        </>
      )}
    </Grid>

    {/* COMPAREPERC */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.COMPAREASS}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkCOMPAREASS}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkCOMPAREASS: value },
          })
        }
      />
    </Grid>

    {/* COMPAREASS */}
    <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.COMPAREPERC}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkCOMPAREPERC}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: {
              ...filters.workloadStudy,
              checkCOMPAREPERC: value,
            },
          })
        }
      />
    </Grid>

    {/* CONFIDENCE */}
    {/* <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
      <SpCheckbox
        label={labels.patient.graphReport.section.feedback.studies.CONFIDENCE}
        customFromLabelStyle={{ marginBottom: 0 }}
        rightlabel={true}
        formControlStyle={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        checked={filters.workloadStudy.checkCONFIDENCE}
        onChange={(_, value) =>
          setFilters({
            ...filters,
            workloadStudy: { ...filters.workloadStudy, checkCONFIDENCE: value },
          })
        }
      />
    </Grid> */}

    {/* Z-SCORE */}
    <Grid item xs={12} style={{ padding: "8px" }} contaier direction="row">
      <Grid item xs={12} contaier direction="row">
        <SpCheckbox
          label={labels.patient.graphReport.section.feedback.studies.ZSCORE}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.workloadStudy.checkZscore}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              workloadStudy: { ...filters.workloadStudy, checkZscore: value },
            })
          }
        />
      </Grid>
    </Grid>

    {filters.workloadStudy.checkZscore && (
      <>
        <Grid style={{ padding: "8px" }} item xs={3}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_REFERENCE
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkPeriod}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkPeriod: value,
                },
              })
            }
          />
          {filters.workloadStudy.checkPeriod && (
            <SpTextInput
              label={labels.patient.graphReport.section.feedback.filters.period}
              value={filters.workloadStudy.zScorePeriod}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  workloadStudy: {
                    ...filters.workloadStudy,
                    zScorePeriod: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>
        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies.ZSCORE_VALUES
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkPreviousValues}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkPreviousValues: value,
                },
              })
            }
          />
          {filters.workloadStudy.checkPreviousValues && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.workloadStudy.zScorePreviousValues}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  workloadStudy: {
                    ...filters.workloadStudy,
                    zScorePreviousValues: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies.ZSCORE_FIXED
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkFixedPeriod}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkFixedPeriod: value,
                },
              });
            }}
          />
          {filters.workloadStudy.checkFixedPeriod && (
            <>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters
                      .start_date
                  }
                  value={filters.workloadStudy.startDateFixedZscore}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) => {
                    setFilters({
                      ...filters,
                      workloadStudy: {
                        ...filters.workloadStudy,
                        startDateFixedZscore: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters.end_date
                  }
                  value={filters.workloadStudy.endDateFixedZscore}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) =>
                    setFilters({
                      ...filters,
                      workloadStudy: {
                        ...filters.workloadStudy,
                        endDateFixedZscore: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    })
                  }
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_REFERENCE
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkPreviousPeriod}
            onChange={(_, value) => {
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkPreviousPeriod: value,
                },
              });
            }}
          />
          {filters.workloadStudy.checkPreviousPeriod && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.workloadStudy.previousPeriodValue}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  workloadStudy: {
                    ...filters.workloadStudy,
                    previousPeriodValue: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px", paddingLeft: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_VALUES
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkPreviousSubject}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkPreviousSubject: value,
                },
              })
            }
          />
          {filters.workloadStudy.checkPreviousSubject && (
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters
                  .previous_values
              }
              value={filters.workloadStudy.previousSubjectValue}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({
                  ...filters,
                  workloadStudy: {
                    ...filters.workloadStudy,
                    previousSubjectValue: value,
                  },
                });
              }}
              type="number"
            />
          )}
        </Grid>

        <Grid item xs={3} style={{ pading: "8px" }}>
          <SpCheckbox
            label={
              labels.patient.graphReport.section.feedback.studies
                .ZSCORE_SUBJECT_FIXED
            }
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.workloadStudy.checkFixedSubject}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                workloadStudy: {
                  ...filters.workloadStudy,
                  checkFixedSubject: value,
                },
              })
            }
          />
          {filters.workloadStudy.checkFixedSubject && (
            <>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters
                      .start_date
                  }
                  value={filters.workloadStudy.startDateFixedSubject}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) => {
                    setFilters({
                      ...filters,
                      workloadStudy: {
                        ...filters.workloadStudy,
                        startDateFixedSubject: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ pading: "8px" }}>
                <SpTextInput
                  label={
                    labels.patient.graphReport.section.feedback.filters.end_date
                  }
                  value={filters.workloadStudy.endDateFixedSubject}
                  style={{ width: "100%" }}
                  type={"date"}
                  onChange={(evnt) =>
                    setFilters({
                      ...filters,
                      workloadStudy: {
                        ...filters.workloadStudy,
                        endDateFixedSubject: moment(evnt.target.value).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    })
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    )}
  </Grid>
);
