import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import moment from "moment";
import { dateFormat } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";
import { getMomentIdFromKey } from "../patientsReports/ReportHelperFns";
import ToggleButton from "@material-ui/lab/ToggleButton";
import BarChartOutlinedIcon from "@material-ui/icons/BarChart";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import { theme } from "../../../components/theme";
import SpTooltip from "../../../components/atoms/SpTooltip";
const RADAR_KEY = "RADAR";
const HISTOGRAM_KEY = "HISTOGRAM";

const BAR_KEY = "bar";

const axisFormat = "DD-MM";

const colorArray = [
  "#30a2ff",
  "#de32a1",
  "#075cfe",
  "#8B9556",
  "#ff5024",
  "#8941ff",
  "#31cccf",
  "#070cfe",
  "#813556",
  "#aa0014",
  "#894b11",
  "#31cc12",
  "#aabb54",
  "#1041ff",
  "#31cccf",
  "#1dffae",
  "#56ffae",
  "#56ffee",
  "#232377",
  "#78ff",
  "#552377",
];

const ReportGraphStatisticAnalytic = ({
  dateRange,
  graphDateView,
  graphType,
  studies,
  currStudyParameters,
  defaultZero,
  data,
  isZscoreChar,
}) => {
  const [graphDataLinear, setGraphDataLinear] = useState([]);
  const [graphDataRadar, setGraphDataRadar] = useState([]);

  const [legendData, setLegendData] = useState([]); //[{ legend: legend, yAxisId: axisY }]
  const [yAxisLabels, setYAxisLabels] = useState([]); //["axisYname"]
  const [showLegend, setShowLegend] = useState(false); //["axisYname"]

  const control = {
    value: showLegend,
    onChange: (event) => {
      setShowLegend(!showLegend);
    },
    exclusive: true,
  };

  const adaptLinearGraph = () => {
    //Source format { study_key: { study_curve_name: { subject_name: { parameter_id: { "YYYY-MM-DD": datapoint, ... }, ... }, ... }, ... }, ... }
    //Recharts format [{ xAxis: "YYYY-MM-DD", study_curve_name_subject_name_parameter_merged: datapoint }}]

    //Flatten study_key, subject_name and parameter_id
    const legendData = [];
    const flattenedData = {};
    const measureUnits = new Set();
    //Iterate studies
    Object.entries(data).forEach(([studyKey, studyData]) => {
      const currStudy = studies.find((study) => study.key === studyKey);
      //Iterate studies curves
      Object.entries(studyData).forEach(([curveName, curveData]) => {
        //Iterate subjects
        Object.entries(curveData).forEach(([subjectName, subjectData]) => {
          //Iterate parameters
          Object.entries(subjectData).forEach(([key, val]) => {
            //Parameters names could contain a dash part which must be ignored
            //to obtain correct param key (and translation), so we filter out
            //the part after -, check the key, and put it back later
            //Example: "COMPAREASS - Total", COMPAREASS is the key to be extracted
            const dashIndex = key.indexOf("-");
            let keyValue = key;
            if (dashIndex !== -1) keyValue = key.substring(0, dashIndex).trim();
            //Search for correct name
            const currParam = currStudyParameters.find(
              (param) => param.id === parseInt(key) || param.key === keyValue
            );
            let currName = psTranslate(currParam?.name ?? key);
            if (dashIndex !== -1) currName += ` ${key.substring(dashIndex)}`;
            let units = currStudy.um ?? currParam?.um;
            if (!units) units = "units";

            //Flatten data
            const currStudyName =
              labels.patient.graphReport.section.feedback.studies[
                currStudy.key
              ];
            const flattenedKey = `${subjectName} - ${currName} (${units}) - ${currStudyName}${
              curveName ? ` (${curveName})` : ""
            }`;
            flattenedData[flattenedKey] = val;
            //Prepare legend
            legendData.push({
              legend: flattenedKey,
              yAxisId: units,
              type: curveName === HISTOGRAM_KEY ? BAR_KEY : null,
            });
            measureUnits.add(units);
          });
        });
      });
    });

    //Prevent more than 2 measure units
    if (measureUnits.size >= 3) return [];
    setLegendData(legendData);
    setYAxisLabels([...measureUnits].sort((a, b) => b.localeCompare(a)));

    //Convert flattened data into recharts format
    const graphData = [];
    for (const day of dateRange.reverseBy(
      getMomentIdFromKey(graphDateView?.key)
    )) {
      const dayKey = day.format(dateFormat);
      const obj = { xAxis: day.format(axisFormat) };
      Object.entries(flattenedData).forEach(([key, val]) => {
        if (val[dayKey] != null) obj[key] = val[dayKey];
      });
      graphData.push(obj);
    }
    return graphData.reverse();
  };

  const adaptRadarGraph = () => {
    //Source format { study_key: { study_curve_name: { subject_name: { parameter_id: datapoint, ... }, ... }, ... }, ... }
    //Recharts format [{ xAxis: "paramName", study_curve_name_subject_name_parameter_merged: datapoint }}]
    //Flatten study_key and subject_name
    const legendData = [];
    const flattenedData = {};
    //Iterate studies
    Object.entries(data).forEach(([studyKey, studyData]) => {
      const currStudy = studies.find((param) => param.key === studyKey);
      //Iterate studies curves
      Object.entries(studyData).forEach(([curveName, curveData]) => {
        //Iterate subjects
        Object.entries(curveData).forEach(([subjectName, subjectData]) => {
          const flattenedKey = `${subjectName} - ${psTranslate(
            currStudy.name
          )}${curveName ? ` (${curveName})` : ""}`;

          if (
            !isZscoreChar ||
            (isZscoreChar && flattenedKey.includes("Z-Score"))
          )
            //Prepare legend
            legendData.push({
              legend: flattenedKey,
            });

          //Iterate parameters
          Object.entries(subjectData).forEach(([key, val]) => {
            const currParam = currStudyParameters.find(
              (param) => param.id == key
            );
            const currName = currParam?.name ?? key;

            //Flatten data
            if (!flattenedData[currName]) flattenedData[currName] = {};
            flattenedData[currName][flattenedKey] = val;
          });
        });
      });
    });
    setLegendData(legendData);

    //Convert flattened data into recharts format
    const graphData = Object.entries(flattenedData).map(([key, elem]) => ({
      xAxis: key,
      ...elem,
    }));
    let graphUpdate = [];

    for (let param of graphData) {
      const keysParam = Object.keys(param);
      let item = {};
      item["xAxis"] = param["xAxis"];
      for (let keyParam of keysParam) {
        if (
          (keyParam !== "xAxis" && !isZscoreChar) ||
          (keyParam !== "xAxis" && isZscoreChar && keyParam.includes("Z-Score"))
        ) {
          const lastDate = Object.keys(param[keyParam]).sort(function (a, b) {
            return new moment(b) - moment(a);
          })[0];
          item[keyParam] = param[keyParam][lastDate];
        }
      }
      graphUpdate.push(item);
    }
    return graphUpdate;
  };

  useEffect(() => {
    if (graphType?.key === RADAR_KEY || isZscoreChar) {
      const graphData = adaptRadarGraph();
      setGraphDataLinear([]);
      setGraphDataRadar(graphData);
    } else {
      const graphData = adaptLinearGraph();
      setGraphDataRadar([]);
      setGraphDataLinear(graphData);
    }
  }, [data, graphType, graphDateView]);

  const CustomizedDot = ({ cx, cy, stroke, value, r, fill }) => {
    if (!value || (defaultZero && value === 0)) return null;
    else
      return (
        <circle
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={3}
          fill={fill}
        />
      );
  };

  return (
    <Grid item xs={12} style={{ paddingTop: "25px", paddingRight: "25px" }}>
      <ResponsiveContainer width="100%" height={300} isZoomEnabled={false}>
        {graphType?.key === RADAR_KEY || isZscoreChar ? (
          <RadarChart data={graphDataRadar} key={"RADAR"} isZoomEnabled={false}>
            <PolarGrid />
            <PolarAngleAxis dataKey="xAxis" stroke="white" />
            <PolarRadiusAxis
              angle={0}
              tickFormatter={(value) => parseFloat(value).toFixed(2)}
            />
            <SpTooltip
              contentStyle={{ backgroundColor: "#FFFFFFDF" }}
              wrapperStyle={{ zIndex: 99 }}
              formatter={(value, name) => [
                parseFloat(value).toFixed(2).replace("-", "\u2014"),
                name,
              ]}
            />
            {legendData.map(({ legend }, idx) => (
              <Radar
                isZoomEnabled={false}
                key={legend}
                name={legend}
                dataKey={legend}
                stroke={colorArray[idx % colorArray.length]}
                fillOpacity={0.6}
                isAnimationActive={false}
              />
            ))}
            {showLegend && <Legend />}
          </RadarChart>
        ) : (
          <ComposedChart
            isZoomEnabled={false}
            style={{ color: "white !important" }}
            data={graphDataLinear}
            key={"LINEAR"}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="xAxis" stroke="gray" />
            {yAxisLabels.length > 0 && (
              <YAxis
                yAxisId={yAxisLabels[0]}
                stroke="gray"
                width={50}
                label={{
                  value: yAxisLabels[0],
                  position: "insideLeft",
                  stroke: "gray",
                  angle: yAxisLabels[0].length <= 1 ? 0 : -90,
                }}
                type="number"
              />
            )}
            {yAxisLabels.length > 1 && (
              <YAxis
                yAxisId={yAxisLabels[1]}
                stroke="gray"
                width={50}
                label={{
                  value: yAxisLabels[1],
                  position: "insideRight",
                  stroke: "gray",
                  angle: yAxisLabels[1].length <= 1 ? 0 : 90,
                }}
                orientation="right"
                type="number"
              />
            )}
            <SpTooltip
              contentStyle={{ backgroundColor: "#FFFFFFDF" }}
              wrapperStyle={{ zIndex: 99 }}
              formatter={(value, name) => [
                parseFloat(value).toFixed(2).replace("-", "\u2014"),
                name,
              ]}
            />
            {showLegend && <Legend />}
            {legendData.map(({ legend, yAxisId, type }, idx) => {
              if (graphType?.key !== HISTOGRAM_KEY) {
                {
                  /* if (legend.includes("Timeline")) { */
                }
                return (
                  <Line
                    isZoomEnabled={false}
                    key={`${legend}_bar`}
                    type="monotone"
                    yAxisId={yAxisId}
                    strokeWidth={3}
                    dataKey={legend}
                    stroke={colorArray[idx % colorArray.length]}
                    activeDot={{ r: 8 }}
                    dot={<CustomizedDot />}
                    isAnimationActive={false}
                    connectNulls={true}
                  />
                );
                {
                  /* } else {
                  return (
                    <Area
                      key={`${legend}_bar`}
                      type="monotone"
                      yAxisId={yAxisId}
                      strokeWidth={3}
                      dataKey={legend}
                      stroke={colorArray[idx % colorArray.length]}
                      activeDot={{ r: 8 }}
                      dot={<CustomizedDot />}
                      isAnimationActive={false}
                      connectNulls={true}
                      fill={colorArray[idx % colorArray.length]}
                      fillOpacity="0.3"
                    />
                  );
                } */
                }
              } else {
                return (
                  <Bar
                    isZoomEnabled={false}
                    key={`${legend}_line`}
                    type="monotone"
                    yAxisId={yAxisId}
                    dataKey={legend}
                    barSize={20}
                    fill={colorArray[idx % colorArray.length]}
                  />
                );
              }
            })}
          </ComposedChart>
        )}
      </ResponsiveContainer>
      <ToggleButtonGroup
        size="small"
        {...control}
        style={{ paddingRight: "2em" }}
      >
        <ToggleButton
          value={showLegend}
          key={"showLegend"}
          style={{ borderColor: theme.colors.primary.lightBlue }}
        >
          {
            <ArtTrackIcon
              style={{
                color: showLegend ? theme.colors.primary.lightBlue : "grey",
              }}
            />
          }
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

export default ReportGraphStatisticAnalytic;
