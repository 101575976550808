import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import SpText from "../../components/atoms/SpText";
import CloseIcon from "@material-ui/icons/Close";
import SpIconButton from "./SpIconButton";
import { styled } from "../styled";
import { theme } from "../theme";
import { Avatar, Grid } from "@material-ui/core";

const StyledGridContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "16px",
  alignItems: "center",
  marginTop: "20px",
});

const CenteredTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
const AvatarStyle = styled("div")({
  width: "6em",
  height: "6em",
  borderRadius: "50%",
});

const AvatarImageStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain" /* Puoi cambiare in "contain" se preferisci */,
  objectPosition: "center" /* Centra l'immagine */,
});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  padding: "4%",
  justifyContent: "space-between",
});
const useStyles = makeStyles({
  paper: {
    background: "#333333",
    border: `1px solid ${theme.colors.primary.lightBlue}`,
  },

  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const riskScoreThreshold = {
  low: 20, // low
  moderate: 60, // moderate
  hight: 80, // hight
};

const baseColor = "red";

// Function to get the color based on the numerical value
const getColorInj = (value) => {
  if (value > riskScoreThreshold.hight) {
    return "green";
  } else if (value > riskScoreThreshold.moderate) {
    return "orange";
  } else if (value > riskScoreThreshold.low) {
    return "tomato";
  } else {
    return baseColor;
  }
};

// Function to get the color based on the numerical value
const getColorRead = (value) => {
  if (value > 80) {
    return "green";
  } else if (value > 60) {
    return "lightGreen";
  } else if (value > 40) {
    return "orange";
  } else if (value > 20) {
    return "tomato";
  } else {
    return baseColor;
  }
};

const SpDialog = ({
  children,
  open,
  setOpen,
  onCloseFunctions,
  title,
  image,
  variant,
  val,
  valN,
  show_vals = false,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (onCloseFunctions) {
      onCloseFunctions();
    }
  };

  if (show_vals === false) {
    return (
      <Dialog
        classes={classes}
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
      >
        <StyledRow>
          <Grid item container direction="row">
            {image && (
              <AvatarStyle>
                <AvatarImageStyle src={image ? image : ""} />
              </AvatarStyle>
            )}
            <SpText id={"dialogTitle"} variant="h1PageTitleDialog">
              {title}
            </SpText>
          </Grid>
          <SpIconButton
            alignSelf="flex-end"
            color={theme.colors.primary.lightBlue}
            variant={variant ? variant : "lightBlue"}
            onClick={handleClose}
            small
          >
            <CloseIcon id="closeIcon" />
          </SpIconButton>
        </StyledRow>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  } else {
    const ColoredSpTextInj = styled(SpText)({
      color: getColorInj(100 - valN),
      fontSize: "2.5em",
    });

    const ColoredSpTextRead = styled(SpText)({
      color: getColorRead(100 - valN),
      fontSize: "2.5em",
    });

    const SpTextRed = styled(SpText)({
      fontSize: "1.3em",
    });

    return (
      <Dialog
        classes={classes}
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
      >
        {/*<StyledRow>
          <SpIconButton
            alignSelf="flex-end"
            color={theme.colors.primary.lightBlue}
            variant={variant ? variant : "lightBlue"}
            onClick={handleClose}
            small
          >
            <CloseIcon id="closeIcon" />
          </SpIconButton>
        </StyledRow>*/}
        <StyledGridContainer>
          <CenteredTextContainer>
            <SpTextRed id="dialogTitle2" variant="h1PageTitleDialog">
              {"Readiness"}
            </SpTextRed>
            <ColoredSpTextRead
              id="dialogVal2"
              variant="h1PageTitleDialog"
              style={{ color: getColorRead(100 - valN) }}
            >
              {100 - valN + "%"}
            </ColoredSpTextRead>
          </CenteredTextContainer>
          <CenteredTextContainer>
            <SpTextRed id="dialogTitle1" variant="h1PageTitleDialog">
              {title}
            </SpTextRed>
            <ColoredSpTextInj id="dialogVal1" variant="h1PageTitleDialog">
              {val}
            </ColoredSpTextInj>
          </CenteredTextContainer>
        </StyledGridContainer>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  }
};

export default SpDialog;
