import Grid from "@material-ui/core/Grid";
import React from "react";
import { labels, psTranslate } from "../../../../shared/translations";
// material ui
// models
// components
import { theme } from "../../../../../components/theme";
// atoms
import SpDialog from "../../../../../components/atoms/SpDialog";
import SpText from "../../../../../components/atoms/SpText";
// utils
import { titleCase } from "../../../../../utils/common";
// mocks

export const DialogDetails = ({ selectedRow, dialog, setDialog }) => {
  const LabelValue = (props) =>
    props.value && (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    );
  return (
    <>
      <SpDialog
        style={{ padding: "1%" }}
        open={dialog}
        setOpen={setDialog}
        title={
          labels.mylab.tempDetail.riskFactor.title +
          ": " +
          psTranslate(selectedRow?.name_rf)
        }
      >
        {/* info section */}
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Grid direction="row" style={{ marginBottom: "5px" }}>
              <SpText variant="h2">{"Info"}</SpText>

              <Grid
                container
                xs={12}
                direction="row"
                spacing={2}
                style={{
                  padding: "2%",
                }}
              >
                {/* structure / dysfunction row */}
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    {/* structure */}
                    <LabelValue
                      label={labels.mylab.generalRiskFactor.dialog.structure}
                      value={titleCase(
                        psTranslate(
                          selectedRow?.structure_dysfunction?.structure?.name
                            ? selectedRow?.structure_dysfunction?.structure
                                ?.name
                            : selectedRow?.structure?.name
                        )
                      )}
                    />
                  </Grid>
                  {/* dysfunction (category) */}
                  <Grid item xs={3}>
                    <LabelValue
                      label={labels.mylab.generalRiskFactor.dialog.dysfunction}
                      value={titleCase(
                        psTranslate(
                          selectedRow?.structure_dysfunction?.dysfunction
                            ?.dysfunction_category?.name
                        )
                      )}
                    />
                  </Grid>
                  {/* dysfunction type */}
                  <Grid item xs={3}>
                    <LabelValue
                      label={
                        labels.mylab.generalRiskFactor.dialog.dysfunctionType
                      }
                      value={titleCase(
                        psTranslate(
                          selectedRow?.structure_dysfunction?.dysfunction?.name
                        )
                      )}
                    />
                  </Grid>
                </Grid>

                {/* info row */}
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    <LabelValue
                      label={
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .intrinsic
                      }
                      value={titleCase(
                        psTranslate(
                          selectedRow?.is_intrinsic === 1
                            ? labels.mylab.generalRiskFactor.addComp
                                .inputSection.isIntrinsic.yes
                            : labels.mylab.generalRiskFactor.addComp
                                .inputSection.isIntrinsic.no
                        )
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LabelValue
                      label={
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .modifiable
                      }
                      value={titleCase(
                        psTranslate(
                          selectedRow?.is_modifiable === 1
                            ? labels.mylab.generalRiskFactor.addComp
                                .inputSection.isModifiable.yes
                            : labels.mylab.generalRiskFactor.addComp
                                .inputSection.isModifiable.no
                        )
                      )}
                    />
                  </Grid>
                </Grid>

                {/* extra info row */}
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    <LabelValue
                      label={
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .notes
                      }
                      value={psTranslate(selectedRow?.note)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelValue
                      label={
                        labels.mylab.generalRiskFactor.addComp.inputSection
                          .references
                      }
                      value={psTranslate(selectedRow?.reference)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {selectedRow?.impacted_zones.length > 0 ? (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Grid direction="row" style={{ marginBottom: "5px" }}>
                <SpText variant="h3">
                  {labels.mylab.generalRiskFactor.dialog.zones}
                </SpText>
                {selectedRow?.impacted_zones?.map((zone) => (
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    spacing={2}
                    style={{
                      padding: "2%",
                    }}
                  >
                    {/* impacted RAS zone */}
                    <Grid item container xs={12}>
                      <Grid item xs={3}>
                        <LabelValue
                          label={
                            labels.mylab.generalRiskFactor.dialog.regionImpacted
                          }
                          value={titleCase(psTranslate(zone?.region?.name))}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelValue
                          label={
                            labels.mylab.generalRiskFactor.dialog.areaImpacted
                          }
                          value={titleCase(psTranslate(zone?.area?.name))}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelValue
                          label={
                            labels.mylab.generalRiskFactor.dialog
                              .structureImpacted
                          }
                          value={titleCase(psTranslate(zone?.structure?.name))}
                        />
                      </Grid>
                    </Grid>
                    {/* info about impacted RAS */}
                    <Grid item container xs={12}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <LabelValue
                          label={
                            labels.mylab.generalRiskFactor.addComp.inputSection
                              .inputWeight
                          }
                          value={`${titleCase(psTranslate(zone?.weight))}`}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelValue
                          label={
                            labels.mylab.generalRiskFactor.addComp.inputSection
                              .inputImpact
                          }
                          value={`${titleCase(
                            psTranslate(zone?.percentage)
                          )} %`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {/* rules row */}
        {selectedRow?.rules.length > 0 ? (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Grid direction="column" style={{ marginBottom: "5px" }}>
                <SpText variant="h3">
                  {labels.mylab.generalRiskFactor.dialog.rules}
                </SpText>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  spacing={2}
                  style={{
                    padding: "2%",
                  }}
                >
                  {selectedRow?.rules.map((role) => (
                    <Grid item container xs={12}>
                      <SpText variant="h4ComponentLabel">
                        <span style={{ color: theme.colors.primary.lightBlue }}>
                          -
                        </span>{" "}
                        {role.type ? `[${role.type.toUpperCase()}] : ` : ``}
                        {role.logical.key !== "betw"
                          ? `${psTranslate(role?.param?.name)} | ${psTranslate(
                              role?.subparam?.name
                            )} ${psTranslate(
                              role?.logical?.label
                            )} ${psTranslate(role.threshold)} ${
                              role?.subparam?.um
                                ? " (" + role.subparam.um + ")"
                                : ""
                            }`
                          : `${psTranslate(role.param.name)} | ${psTranslate(
                              role.subparam.name
                            )} ${psTranslate(role.logical.label)} ${psTranslate(
                              role.threshold
                            )} .. ${psTranslate(role.thresholdRef2)} ${
                              role?.subparam?.um
                                ? " (" + role.subparam.um + ")"
                                : ""
                            }`}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </SpDialog>
    </>
  );
};
