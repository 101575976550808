import React from "react";
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";

// Treemap plot
export const TreeMapChart = ({ data }) => {
  // Initialize max value with negative infinity
  let maxValue = 0;

  // Iterate over the array to find the maximum value
  data.forEach((item) => {
    if (item.value > maxValue) {
      maxValue = item.value;
    }
  });

  return (
    <ResponsiveContainer width={"95%"} height={200}>
      <Treemap
        width={"80%"}
        height={"90%"}
        data={data}
        dataKey="value"
        ratio={4 / 4}
        stroke="#ffffff"
        fill="#3d988e20"
        content={<CustomizedLabel maxValue={maxValue} />}
      >
        <Tooltip
          formatter={(value, name, props) => [
            `${props.payload.name}: ${value}`,
            "",
          ]}
          contentStyle={{ fontSize: "15px" }}
        />
      </Treemap>
    </ResponsiveContainer>
  );
};
// CustomizedLabel component to render label inside rectangle
const CustomizedLabel = ({
  depth,
  x,
  y,
  width,
  height,
  name,
  value,
  maxValue,
}) => {
  const fontSize = Math.min(width, height) / 8; // Adjust font size based on rectangle size
  if (depth === 1) {
    if (value > 0) {
      const textX = x + width / 2;
      const textY = y + height / 2;
      let displayName = "";
      if (width * 4 > height) {
        displayName = name.split(" ");
      } else {
        displayName = [name];
      }
      const alpha = (value / maxValue).toFixed(2) * 1.5; // Calculate alpha value based on the ratio of value to maxValue
      const fillColor = `rgba(152, 59, 69, ${alpha})`; // Set fill color with calculated alpha

      return (
        <>
          <rect x={x} y={y} width={width} height={height} fill={fillColor} />
          {displayName.map((part, index) => (
            <text
              key={index}
              x={textX}
              y={textY + (index - (displayName.length - 1) / 2) * fontSize}
              textAnchor="middle"
              dominantBaseline="central"
              fontSize={fontSize}
              style={{
                fontFamily: "Ariel",
                fill: "#FFFFFF",
                fontWeight: "light",
                stroke: "None",
                strokeWidth: 0,
              }}
            >
              {part}
            </text>
          ))}
        </>
      );
    } else {
      return (
        <>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill={`rgba(152, 59, 69, 0)`}
          />
        </>
      );
    }
  } else {
    return (
      <>
        <rect x={x} y={y} width={width} height={height} />
      </>
    );
  }
};
