import { call } from "./utils";

export const addMedicalNotes = async (data) =>
  await call({ url: "/medicalnotes/add", data: { data } });

export const getMedicalNotes = async (data) =>
  await call({ url: "/medicalnotes/get", data: { data } });

export const removeMedicalNotes = async (data) =>
  await call({ url: "/medicalnotes/remove", data: { data } });

export const getAllergies = async (data) =>
  await call({ url: "/medicalnotes/get/allergies", data: { data } });
