import React from "react";
import { Grid } from "@material-ui/core";
import { ThresholdCard } from "./cards_ava.jsx";
import { trainingLabel, partitaLabel } from "./exposure.jsx";

// Cards plot
export const Cards_ = ( { dataChartTraining } ) => {

  let allenamentoValue, allenamentoPer, partitaValue, partitaPer, parTrainValue, parTrainPer;

  allenamentoValue = dataChartTraining[trainingLabel];
  allenamentoPer = parseFloat(dataChartTraining[trainingLabel + '_ava']).toFixed(1);
  partitaValue = dataChartTraining[partitaLabel];
  partitaPer = parseFloat(dataChartTraining[partitaLabel + '_ava']).toFixed(1);
  parTrainValue = dataChartTraining[trainingLabel + ' + ' + partitaLabel];
  parTrainPer = parseFloat(dataChartTraining[trainingLabel + ' + ' + partitaLabel + '_ava']).toFixed(1);

  return (
    <>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={4}>
          <ThresholdCard title={trainingLabel}
            description={''}
            value={allenamentoValue}
            perc={allenamentoPer}
            threshold1={40}
            threshold2={60}
            threshold3={90}
            ava={true} />
        </Grid>
        <Grid item xs={4}>
          <ThresholdCard title={partitaLabel}
            description={''}
            value={partitaValue}
            perc={partitaPer}
            threshold1={40}
            threshold2={60}
            threshold3={90}
            ava={true} />
        </Grid>
        <Grid item xs={4}>
          <ThresholdCard title={trainingLabel + ' + ' + partitaLabel}
            description={''}
            value={parTrainValue}
            perc={parTrainPer}
            threshold1={40}
            threshold2={60}
            threshold3={90}
            ava={true} />
        </Grid>
      </Grid>
    </>
  );
};
