import React from "react";
import Divider from "@material-ui/core/Divider";
import { styled } from "../../styled";
import useSteps from "./useSteps";
import SpText from "./../../atoms/SpText";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  indexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: "4.5px",
    paddingRight: 15,
  },
  indexItemInnerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4.5px",
    alignItems: "center",
  },
  slidesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "58%",
  },
  slide: {
    padding: 15,
    minHeight: 400,
    background: "transparent",
    borderRadius: 4,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  slideContent: {
    flex: 1,
  },
  controlsContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  stepTitle: {
    color: "#FFF",
    fontSize: "0.875rem",
    fontWeight: "700",
    textAlign: "right",
  },
  stepDot: {
    width: 12,
    height: 12,
    textAlign: "center",
    margin: 5,
    borderRadius: "50%",
    backgroundColor: "#FFF",
  },
  stepDone: {
    width: 12,
    height: 12,
    textAlign: "center",
    margin: 5,
    borderRadius: "50%",
    backgroundColor: "gray",
  },
  stepFuture: {
    width: 12,
    height: 12,
    textAlign: "center",
    margin: 5,
    borderRadius: "50%",
    backgroundColor: "gray",
  },
  stepLink: {
    height: 45,
    width: 1,
    backgroundColor: "gray",
  },
};

const Container = ({ children }) => (
  <div style={styles.container}>{children}</div>
);

const IndexContainer = ({ children }) => (
  <div style={styles.indexContainer}>{children}</div>
);

const ItemDone = () => (
  <>
    <div style={{ ...styles.stepDone }} />
    <div
      style={{
        ...styles.stepLink,
        backgroundColor: "#84819A",
      }}
    />
  </>
);

const ItemCurrent = ({ isLast, onClick }) => (
  <>
    <div style={styles.stepDot} onClick={onClick} />
    {!isLast && (
      <div
        style={{
          ...styles.stepLink,
        }}
      />
    )}
  </>
);

const ItemFuture = ({ isLast }) => (
  <>
    <div style={{ ...styles.stepFuture }} />
    {!isLast && <div style={styles.stepLink} />}
  </>
);

const StyledIndexItem = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "0.5rem",
  cursor: "pointer !important",
});

const IndexItem = ({ title, stepIndex, currentStep, isLast, onClick, id }) => (
  <StyledIndexItem onClick={onClick}>
    {stepIndex === currentStep ? (
      <SpText
        id={id}
        style={{
          fontWeight: "900",
          fontSize: "1.5rem",
          textTransform: "uppercase",
          lineHeight: "1.3",
        }}
        color={"#FFF"}
      >
        {title}
      </SpText>
    ) : (
      <SpText id={id} style={{ fontSize: "0.875rem", fontWeight: "700" }}>
        {title}
      </SpText>
    )}
    <div style={styles.indexItemInnerContainer}>
      {stepIndex < currentStep ? (
        <ItemDone />
      ) : stepIndex === currentStep ? (
        <ItemCurrent isLast={isLast} />
      ) : (
        <ItemFuture isLast={isLast} />
      )}
    </div>
  </StyledIndexItem>
);

const SlidesContainer = ({ children }) => (
  <div style={styles.slidesContainer}>{children}</div>
);

export const StepTitle = ({ children }) => (
  <SpText variant="h4">{children}</SpText>
);

export const StepDivider = styled(Divider)({
  marginTop: 25,
  marginBottom: 20,
});

const Slide = ({ idx, children, index, controlsContainer, go }) => {
  return idx === index ? (
    <div style={styles.slide}>
      <div style={styles.slideContent}>
        {React.cloneElement(children, { go: go })}
      </div>
      {controlsContainer}
    </div>
  ) : null;
};

export const SpStepper = ({
  initialStep,
  titles,
  stepperControls,
  onComplete,
  children,
  containerWidth,
}) => {
  const config = { initialStep: initialStep ?? 0, steps: children.length };
  const { index, isLastStep, navigation } = useSteps(config);
  const { previous, next, go } = navigation;
  const currentStepperControls = stepperControls({
    previous,
    next,
    index,
    isLastStep,
  });

  if (onComplete && isLastStep) onComplete();

  const ControlsContainer = () => (
    <div style={styles.controlsContainer}>{currentStepperControls}</div>
  );

  const renderChild = (child, idx, go) => (
    <Slide
      key={idx}
      go={go}
      idx={idx}
      index={index}
      id={"test"}
      controlsContainer={<ControlsContainer />}
    >
      {child}
    </Slide>
  );

  return (
    <Container>
      <div id="test">
        <IndexContainer>
          {titles.map((title, idx) => (
            <IndexItem
              key={title.props.children}
              title={title}
              stepIndex={idx}
              currentStep={index}
              isLast={idx === titles.length - 1}
              onClick={() => {
                go(idx);
              }}
              id={`step-${idx}`}
            />
          ))}
        </IndexContainer>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: containerWidth ? containerWidth : null,
        }}
      >
        <SlidesContainer>
          {children
            .filter((c) => c)
            .map((child, idx) => renderChild(child, idx, go))}
        </SlidesContainer>
      </div>
    </Container>
  );
};
