export const mapTestToTable = (inputObject) => {
  const resultArray = [];
  if (inputObject && Object.keys(inputObject) > 0) {
    const dates = Object.keys(inputObject[Object.keys(inputObject)[0]]);
    for (const date of dates) {
      const resultItem = { Date: date };
      for (const testName in inputObject) {
        resultItem[testName] = inputObject[testName][date];
      }
      resultArray.push(resultItem);
    }
  }
  return resultArray;
};
