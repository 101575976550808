import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import SpButton from "../../components/atoms/SpButton";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpIconButton from "../../components/atoms/SpIconButton";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { isMobile } from "react-device-detect";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Grid } from "@material-ui/core";
import { getPresentationsByPatProf } from "../../models/actions/Presentation";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getUserPermission } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "../../components/theme";
import SpTooltip from "../../components/atoms/SpTooltip";
import SpDialog from "../../components/atoms/SpDialog";
import PatientsPresentationAdd from "./PatientsPresentationAdd";
import { createPresentation } from "../../models/actions/Presentation";
import { useLocation } from "react-router-dom";

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  marginBottom: "5%",
});

const PatientsPresentations = (props) => {
  const [displayPresentations, setDisplayPresentation] = useState([]);
  const [newInjuryPermission, setNewInjuryPermission] = useState();
  const [openNewInjury, setOpenNewInjury] = useState();

  const { getAccessTokenSilently } = useAuth0();

  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();
  const location = useLocation();

  //Fetching presentation data
  const fetchData = async () => {
    try {
      setLoading(true);

      const injuryPermission = await getUserPermission(
        "new-injury",
        getAccessTokenSilently
      );
      setNewInjuryPermission(injuryPermission);

      const response = await getPresentationsByPatProf({ id_patient: patId });
      setDisplayPresentation(response.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    props.props?.prevStep(false, false);
    fetchData();
  }, []);

  const headCellsPresentation = [
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableReason,
      isAction: false,
    },
    {
      id: "opened/closed",
      numeric: false,
      disablePadding: false,
      label: `${labels.patient.presentation.open}/${labels.patient.presentation.closed}`,
      isAction: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableStatus,
      isAction: false,
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableStart,
      isAction: false,
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableEnd,
      isAction: false,
    },
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.buyLicenseDialog.duration,
      isAction: false,
    },
  ];

  //rendering the profile editing form
  const PresentationRow = ({ row, index }) => {
    let bodyPartName = "";
    if (row?.structure?.name) {
      bodyPartName = `${psTranslate(row?.structure?.name)}`;
    } else {
      if (row?.area?.name) {
        bodyPartName = `${psTranslate(row?.area?.name)}`;
      } else {
        if (row?.region?.name) {
          bodyPartName = `${psTranslate(row?.region?.name)}`;
        }
      }
    }
    const getColorInjury = () => {
      if (row.end_date) {
        return "green";
      } else {
        if (row.time_loss == "1") return "red";
      }
      return "orange";
    };
    const getTooltipLabels = () => {
      if (!row.end_date) {
        return labels.patient.presentation.open;
      } else {
        if (row.time_loss == "1") return labels.patient.presentation.timeLoss;
      }
      return labels.patient.presentation.noTimeLoss;
    };

    return (
      <SpTableRow tabIndex={-1}>
        <SpTableCell>
          <SpIconAndTextButton
            id={`rowInjury${index}`}
            isIconRight={true}
            textVariant="tableText"
            text={
              row?.pathology?.name
                ? psTranslate(row?.pathology?.name) +
                  " " +
                  bodyPartName?.toLowerCase()
                : psTranslate(row?.type) + " " + bodyPartName?.toLowerCase()
            }
            onClick={() => {
              props.props?.prevStep(
                "/patients/edit/97/monitoring/injury",
                labels.groups.federationAdd.injuryList.title
              );
              history.push(`/patients/edit/${patId}/presentation/${row.id}`);
            }}
          >
            <ArrowForwardIosIcon
              style={{
                width: 10,
                color: theme.colors.primary.lightBlue,
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.end_date
              ? labels.patient.presentation.closed
              : labels.patient.presentation.open}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {`${row.time_loss == "1" ? "Time Loss" : "No Time Loss"}`}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.start_date ? moment(row.start_date).format("DD/MM/yyyy") : ""}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.end_date && moment(row.end_date).format("DD/MM/yyyy")}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText
            variant="tableText"
            style={{
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
            }}
          >
            {row?.end_date
              ? parseInt(
                  moment
                    .duration(moment(row.end_date).diff(moment(row.start_date)))
                    .asDays() - 1
                )
              : row?.estimated_end_date
              ? parseInt(
                  moment
                    .duration(
                      moment(row.estimated_end_date).diff(
                        moment(row.start_date)
                      )
                    )
                    .asDays() - 1
                )
              : parseInt(
                  moment
                    .duration(moment(row).diff(moment(row.start_date)))
                    .asDays() - 1
                )}
          </SpText>
        </SpTableCell>
      </SpTableRow>
    );
  };

  const onSave = async (newData) => {
    setLoading(true);
    setOpenNewInjury(false);
    const response = await createPresentation(newData);
    props.snackbarShowMessage(response.message);

    setLoading(false);
    await fetchData();
  };

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container spacing={2} direction="row">
        <Grid item xs={12}>
          <Grid item container xs={12}>
            <Grid item xs={8}></Grid>
            {newInjuryPermission && (
              <Grid
                item
                xs={4}
                justify="flex-end"
                style={{ alignSelf: "center" }}
              >
                <SpButton
                  id="newInjuryButton"
                  onClick={() => setOpenNewInjury(true)}
                  style={{ width: "100%" }}
                  buttonType="accept"
                  text={labels.patient.presentation.newPresentation.toUpperCase()}
                />
                <SpDialog
                  style={{ padding: "1%" }}
                  open={openNewInjury}
                  setOpen={setOpenNewInjury}
                  title={labels.patient.presentation.newPresentation.toUpperCase()}
                >
                  <PatientsPresentationAdd
                    setLoading={setLoading}
                    onSave={onSave}
                  />
                </SpDialog>
              </Grid>
            )}
          </Grid>

          {displayPresentations && displayPresentations?.length === 0 && (
            <Grid container item xs={12} style={{ marginTop: "1%" }}>
              <SpText variant="h1PageSubtitle">
                {labels.patient.presentation.no_presentations}
              </SpText>
            </Grid>
          )}
          {displayPresentations && displayPresentations?.length > 0 && (
            <StyledTableColumn>
              <SpTable
                headCells={headCellsPresentation}
                rows={displayPresentations}
                pagination={true}
                rowKey="id"
                padding={false}
                notCheckable={false}
                tableContainerMaxHeight={
                  isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                }
              >
                <PresentationRow />
              </SpTable>
            </StyledTableColumn>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsPresentations);
