/* eslint-disable import/prefer-default-export */
import { getUserPermission } from "../../utils/common";

// eslint-disable-next-line no-unused-vars
export const isRoutePermitted = async (
  componentName,
  locationPathname,
  getAccessTokenSilently
) => {
  // necessario per la default route altrimenti si crea un infinite loop
  if (locationPathname === "/") return true;

  // in questo caso devo vedere la location e non il componentName
  // perché è un sotto menu dello stesso componente
  if (locationPathname.includes("/groups")) {
    const tempPermission = await getUserPermission(
      "manage:groups",
      getAccessTokenSilently
    );
    return tempPermission;
  }
  if (locationPathname.includes("/mylab")) {
    const tempPermission = await getUserPermission(
      "manage:mylab",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (componentName === "PatientsReports") {
    const tempPermission = await getUserPermission(
      "manage:patient-analytics",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (locationPathname.includes("/alerts")) {
    const tempPermission = await getUserPermission(
      "manage:alert",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (locationPathname.includes("/genericRiskFactor")) {
    const tempPermission = await getUserPermission(
      "manage:general-risk-factor",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (componentName === "GroupAvailability") {
    const tempPermission = await getUserPermission(
      "injury-surveillance",
      getAccessTokenSilently
    );
    return tempPermission;
  }
  if (componentName === "PatientsPresentationAdd") {
    const tempPermission = await getUserPermission(
      "new-injury",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (componentName === "ManageUsers") {
    const tempPermission = await getUserPermission(
      "manage:users",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (componentName === "PatientsStatistics") {
    const tempPermission = await getUserPermission(
      "manage:patient-statistics",
      getAccessTokenSilently
    );
    return tempPermission;
  }
  if (
    componentName === "PatientsChart" ||
    locationPathname.includes("/control-room")
  ) {
    const tempPermission = await getUserPermission(
      "manage:chart",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (componentName === "Groups") {
    const tempPermission = await getUserPermission(
      "manage:groups",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  if (
    componentName === "MyLabGpsDevicesAccount" ||
    componentName === "MyLabGpsDevicesPatient"
  ) {
    const tempPermission = await getUserPermission(
      "manage:gps-device",
      getAccessTokenSilently
    );
    return tempPermission;
  }

  return true;
};
