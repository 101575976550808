import Grid from "@material-ui/core/Grid";
import React from "react";
import SpText from "../../../../components/atoms/SpText";
import {
  getActivityResponseParameterFeedbacksGroups,
  getMeasurementsParametersAnswers,
  getMeasurementsParametersAnswersGroups,
} from "../../../../models/actions/Patients";

import SpTextInput from "../../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../../shared/translations";
import moment from "moment";

import SpAutocomplete from "../../../../components/atoms/SpAutocomplete";
import { Chip } from "@material-ui/core";
import { getActivityResponseParameterFeedbacks } from "../../../../models/actions/Patients";
import {
  averageInRange,
  computeConfidenceInterval,
} from "../../patientsReports/ReportHelperFns";
import { computeInRange } from "../../patientsReports/ReportHelperFns";
import {
  calculateFinalScore,
  getAllPromsSurveyAnswers,
  getAllPromsSurveyAnswersGroups,
} from "../../../../models/actions/Proms";
import {
  dateFormat,
  dateFormatEndOfDay,
  downloadExcel,
} from "../../../../utils/common";
import {
  linearFixedPeriod,
  linearReferencePeriod,
  linearValuesNumber,
  updateZScoreData,
} from "../../patientsReports/patientsReportFeedback/reportStudies/studyZScore/ZScoreHelpers";
import { updateStatisticAnalyticProfessional } from "../../../../models/actions/Professionals";
import { theme } from "../../../../components/theme";
export const HeaderButton = ({
  setFilters,
  filters,
  buttonName,
  buttonLabel,
}) => {
  return (
    <Grid
      item
      xs={2}
      onClick={() => {
        setFilters({ ...filters, selected: buttonName });
      }}
      style={
        filters.selected === buttonName
          ? {
              border: `2px solid ${theme.colors.primary.lightBlue}`,
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingTop: "8px",
            }
          : { paddingLeft: "8px", paddingRight: "8px", paddingTop: "8px" }
      }
    >
      <SpText variant="h4ComponentLabelCenter">{buttonLabel}</SpText>
    </Grid>
  );
};

export const DateSelection = ({ setFilters, dateLabel, label, filters }) => {
  return (
    <SpTextInput
      onChange={(event) => {
        setFilters({ ...filters, [dateLabel]: event.target.value });
      }}
      style={{ width: "100%" }}
      variant="column"
      type="date"
      label={label.toUpperCase()}
      value={filters[dateLabel]}
    />
  );
};

export const processGroupData = (groupData, processedData) => {
  //Group patient values per group
  processedData.forEach(({ name, data }) => {
    if (!groupData[name]) groupData[name] = {};
    Object.values(data).forEach((lines) => {
      Object.entries(lines).forEach(([line, days]) => {
        if (!groupData[name][line]) groupData[name][line] = {};
        Object.entries(days).forEach(([day, value]) => {
          if (!groupData[name][line][day]) groupData[name][line][day] = [];
          groupData[name][line][day].push(value);
        });
      });
    });
  });
  //Average all
  averageGraphData(groupData);
};

export const FilterAutocomplete = ({
  placeholder,
  value,
  onChange,
  renderOptions,
  options,
  getOptionDisabled,
  multiple = true,
}) => {
  return (
    <>
      <SpText
        style={{
          color: theme.colors.primary.lightBlue,
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        {placeholder}
      </SpText>
      <SpAutocomplete
        multiple={multiple}
        placeholder={placeholder}
        label={placeholder}
        style={{ width: "100%" }}
        formControlWidth={"100%"}
        labelPaddingTop={"0"}
        value={value}
        onChange={onChange}
        displayLabel={false}
        renderTags={(value, getTagProps) =>
          renderOptions.map((option, index) => (
            <Chip
              key={`${option.key}`}
              style={{
                backgroundColor: theme.colors.primary.lightBlue,
                color: "white",
              }}
              label={psTranslate(`${option.name}`)}
              size="medium"
              {...getTagProps({ index })}
            />
          ))
        }
        getOptionDisabled={getOptionDisabled}
        options={options}
        getOptionLabel={(option) => psTranslate(`${option.name}`)}
        getOptionSelected={(option, value) => option.id === value?.id}
      />
    </>
  );
};

export const getResults = async ({ filters, patId, patients = [], groups }) => {
  const ret = await getActivityResponseParameterFeedbacks({
    id_activity_types: filters.activity.selectedActivites.map(({ id }) => id),
    id_patients: [patId, ...patients.map(({ id }) => id)],
    parameters: filters.activity.selectedStudyParams.map(({ id }) => id),
    startDate: filters.startDate,
    endDate: filters.endDate,
  });
  const retGroup = await getActivityResponseParameterFeedbacksGroups({
    id_activity_types: filters.activity.selectedActivites.map(({ id }) => id),
    id_groups: [groups.map(({ id }) => id)],
    parameters: filters.activity.selectedStudyParams.map(({ id }) => id),
    startDate: filters.startDate,
    endDate: filters.endDate,
  });

  return { ...ret, ...retGroup };
};

export const getDataChartByFilters = ({
  filters,
  result,
  type,
  study,
  mainSubjectKey,
}) => {
  let dataChart = { TEMPORAL: { Line: result } };

  if (study.checkMonotony) {
    const monotony = getMonotonyStudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      daysBefore: study.monotonyPeriod,
    });
    dataChart = { ...dataChart, MONOTONY: { "": monotony } };
  }

  if (study.checkStrain) {
    const strain = getStrainStudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      daysBefore: study.strainPeriod,
    });
    dataChart = { ...dataChart, STRAIN: { "": strain } };
  }

  if (study.checkACWRRA) {
    const acwrra = getACWRRAStudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      daysBeforeAcute: study.ACWRRAAcute,
      daysBeforeCronic: study.ACWRRAChronic,
    });
    dataChart = { ...dataChart, ACWRRA: { "": acwrra } };
  }

  if (study.checkACWREWMA) {
    const acwrewma = getACWREWMA({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      daysBeforeAcute: study.ACWREWMAAcute,
      daysBeforeCronic: study.ACWREWMAChronic,
    });
    dataChart = { ...dataChart, ACWREWMA: { "": acwrewma } };
  }
  if (study.checkCOMPAREPERC) {
    const comparper = getComparPercStudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      type: type,
    });
    dataChart = { ...dataChart, COMPAREPERC: { "": comparper } };
  }
  if (study.checkCOMPAREASS) {
    const comparper = getComparAsstudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
      type: type,
    });
    dataChart = { ...dataChart, COMPAREASS: { "": comparper } };
  }
  if (study.checkCONFIDENCE) {
    const confidence = getConfidenceStudy({
      graphDateView: result,
      dateRange: moment().range(filters.startDate, filters.endDate),
    });
    dataChart = { ...dataChart, CONFIDENCE: confidence };
  }
  if (study.checkCONFIDENCE) {
    const symmetry = getSymmetryData({
      dateRange: moment().range(filters.startDate, filters.endDate),
      graphDateView: result,
    });
    dataChart = { ...dataChart, SYMMETRY: symmetry };
  }
  if (study.checkPeriod) {
    const zScorePeriod = getZScorePeriod({
      graphDateView: result,
      daysBefore: study.zScorePeriod,
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...zScorePeriod };
  }

  if (study.checkPreviousValues) {
    const zPreviousValueZscore = getZscorePrevious({
      graphDateView: result,
      daysBefore: study.zScorePreviousValues,
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...zPreviousValueZscore };
  }

  if (study.checkFixedPeriod) {
    const fixedPeriodZscore = getZscoreFixedPeriod({
      graphDateView: result,
      period: moment().range(
        study.startDateFixedZscore,
        study.endDateFixedZscore
      ),
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...fixedPeriodZscore };
  }

  if (study.checkPreviousPeriod) {
    const zPreviousPeriodZscore = getZscorePreviousPeriod({
      graphDateView: result,
      daysBefore: study.previousPeriodValue,
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...zPreviousPeriodZscore };
  }

  if (study.checkPreviousSubject) {
    const zPreviousPeriodZscore = getZscorePreviousSubject({
      graphDateView: result,
      daysBefore: study.previousSubjectValue,
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...zPreviousPeriodZscore };
  }

  if (study.checkFixedSubject) {
    const zPreviousPeriodZscore = getZscoreFixedSubject({
      graphDateView: result,
      period: moment().range(
        study.startDateFixedSubject,
        study.endDateFixedSubject
      ),
      dateRange: moment().range(filters.startDate, filters.endDate),
      mainSubjectKey,
      defaultZero: type === "workload" ? true : false,
    });
    dataChart = { ...dataChart, ...zPreviousPeriodZscore };
  }

  return dataChart;
};

const monotonyOverPrevDays = ({
  by,
  data,
  dateRange,
  daysBefore = 7,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters
    accum[subjectName] = Object.entries(subjectData).reduce(
      (accum, [studyParamKey, studyData]) => {
        accum[studyParamKey] = computeInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBefore, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) => {
            const average = values.reduce((a, b) => a + b, 0) / values.length;
            const stddev = Math.sqrt(
              values.reduce((acc, val) => acc + Math.pow(val - average, 2), 0) /
                values.length
            );
            return stddev !== 0 ? average / stddev : 0;
          }
        );
        return accum;
      },
      {}
    );
    return accum;
  }, {});
};

const accraOverPrevDays = ({
  by,
  data,
  dateRange,
  daysBeforeAcute,
  daysBeforeCronic,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    const resultData = {};
    //Iterate over study parameters
    Object.entries(subjectData).forEach(([studyParamKey, studyData]) => {
      //Acute avgs
      const acuteAvgs = averageInRange(
        dateRange,
        by,
        (range) =>
          moment
            .rangeFromInterval("day", -daysBeforeAcute, range.end)
            .reverseBy("day", { excludeStart: true }),
        (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
      );

      //Cronic avgs
      const cronicAvgs = averageInRange(
        dateRange,
        by,
        (range) =>
          moment
            .rangeFromInterval("day", -daysBeforeCronic, range.end)
            .reverseBy("day", { excludeStart: true }),
        (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
      );

      //RA result
      resultData[studyParamKey] = Object.entries(cronicAvgs).reduce(
        (accum, [key, cronicAvg]) => {
          if (acuteAvgs[key] != null && cronicAvg != null)
            accum[key] = cronicAvg != 0 ? acuteAvgs[key] / cronicAvg : 0;
          return accum;
        },
        {}
      );
    });
    accum[subjectName] = resultData;
    return accum;
  }, {});
};

const confidenceData = ({ dateRange, data }) => {
  const MIN_KEY = "Min";
  const MAX_KEY = "Max";
  const dayStrStart = dateRange.start.format(dateFormat);
  const dayStrEnd = dateRange.end.format(dateFormat);
  const resultData = {
    [MIN_KEY]: {},
    [MAX_KEY]: {},
  };
  //Iterate over subjects
  Object.entries(data).forEach(([subjectName, subjectData]) => {
    resultData[MIN_KEY][subjectName] = {};
    resultData[MAX_KEY][subjectName] = {};

    //Iterate over study parameters
    Object.entries(subjectData).forEach(([studyParamKey, studyData]) => {
      resultData[MIN_KEY][subjectName][studyParamKey] = {};
      resultData[MAX_KEY][subjectName][studyParamKey] = {};
      const studyValues = Object.values(studyData);
      const [confidenceMin, confidenceMax] =
        computeConfidenceInterval(studyValues);
      resultData[MIN_KEY][subjectName][studyParamKey][dayStrStart] =
        confidenceMin;
      resultData[MIN_KEY][subjectName][studyParamKey][dayStrEnd] =
        confidenceMin;
      resultData[MAX_KEY][subjectName][studyParamKey][dayStrStart] =
        confidenceMax;
      resultData[MAX_KEY][subjectName][studyParamKey][dayStrEnd] =
        confidenceMax;
    });
  });
  return resultData;
};

const symmetryAverageData = ({ by, data, dateRange, defaultZero = true }) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters (2)
    let paramKey = null;
    const avgParams = Object.entries(subjectData).map(
      ([studyParamKey, studyData]) => {
        paramKey = paramKey ?? studyParamKey;
        return averageInRange(
          dateRange,
          by,
          (range) => range.by("day", { excludeEnd: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          true
        );
      }
    );

    //Diff 2 params
    if (avgParams.length === 2) {
      const param0 = avgParams[0];
      const param1 = avgParams[1];

      accum[subjectName] = {};
      accum[subjectName][paramKey] = Object.entries(param0).reduce(
        (accum, [dayStr, value0]) => {
          const value1 = param1[dayStr];
          if (value0 != null && value1 != null)
            accum[dayStr] =
              (Math.min(value0, value1) / Math.max(value0, value1)) * 100;
          return accum;
        },
        {}
      );
    }

    return accum;
  }, {});
};

const comparationPercAverageData = ({
  by,
  data,
  dateRange,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters
    accum[subjectName] = Object.entries(subjectData).reduce(
      (accum, [studyParamKey, studyData]) => {
        let avg = 0;
        accum[studyParamKey] = computeInRange(
          dateRange,
          by,
          (range) => range.by("day", { excludeEnd: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) => {
            const prevAvg = avg;
            avg = values.reduce((a, b) => a + b, 0) / values.length;
            if (prevAvg != 0) return ((avg - prevAvg) / prevAvg) * 100;
            else return defaultZero ? 0 : null;
          },
          true,
          true //Forward compute
        );
        return accum;
      },
      {}
    );
    return accum;
  }, {});
};

const acrewmaOverPrevDays = ({
  by,
  data,
  dateRange,
  daysBeforeAcute,
  daysBeforeCronic,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters
    accum[subjectName] = Object.entries(subjectData).reduce(
      (accum, [studyParamKey, studyData]) => {
        //Acute EWMA
        let a = 2 / (daysBeforeAcute + 1);
        const acuteValues = computeInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBeforeAcute, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) =>
            values.reverse().reduce((ewma, val) => val * a + ewma * (1 - a), 0)
        );
        //Cronic EWMA
        a = 2 / (daysBeforeCronic + 1);
        const cronicValues = computeInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBeforeCronic, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) =>
            values.reverse().reduce((ewma, val) => val * a + ewma * (1 - a), 0)
        );

        //EWMA result
        accum[studyParamKey] = Object.entries(cronicValues).reduce(
          (accum, [key, cronicValue]) => {
            if (acuteValues[key] != null && cronicValue != null)
              accum[key] =
                cronicValue != 0 ? acuteValues[key] / cronicValue : 0;
            return accum;
          },
          {}
        );

        return accum;
      },
      {}
    );
    return accum;
  }, {});
};

export const comparationAssAverageData = ({
  by,
  data,
  dateRange,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters
    accum[subjectName] = Object.entries(subjectData).reduce(
      (accum, [studyParamKey, studyData]) => {
        let avg = 0;
        accum[studyParamKey] = computeInRange(
          dateRange,
          by,
          (range) => range.by("day", { excludeEnd: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) => {
            const prevAvg = avg;
            avg = values.reduce((a, b) => a + b, 0) / values.length;
            return prevAvg !== 0 ? avg - prevAvg : defaultZero ? 0 : null;
          },
          true,
          true //Forward compute
        );
        return accum;
      },
      {}
    );
    return accum;
  }, {});
};

export const getStrainStudy = ({ graphDateView, dateRange, daysBefore }) => {
  return strainOverPrevDays({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    daysBefore: daysBefore,
  });
};

export const getComparPercStudy = ({ graphDateView, dateRange, type }) => {
  let defaultZero;
  switch (type) {
    case "workload":
      defaultZero = true;
      break;
    case "proms":
      defaultZero = false;
      break;

    default:
      defaultZero = true;
      break;
  }
  return comparationPercAverageData({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    defaultZero: defaultZero,
  });
};

export const getComparAsstudy = ({ graphDateView, dateRange, type }) => {
  let defaultZero;
  switch (type) {
    case "workload":
      defaultZero = true;
      break;
    case "proms":
      defaultZero = false;
      break;

    default:
      defaultZero = true;
      break;
  }
  return comparationAssAverageData({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    defaultZero: defaultZero,
  });
};

export const getACWRRAStudy = ({
  graphDateView,
  dateRange,
  daysBeforeAcute,
  daysBeforeCronic,
}) => {
  return accraOverPrevDays({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    daysBeforeAcute: daysBeforeAcute,
    daysBeforeCronic: daysBeforeCronic,
  });
};

export const getZscoreFixedPeriod = ({
  graphDateView,
  period,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Fixed period"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        period,
        "day",
        false,
        mainSubjectKey,
        linearFixedPeriod
      ),
    },
  };
  return newData;
};
export const getZscoreFixedSubject = ({
  graphDateView,
  period,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Fixed value"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        period,
        "day",
        true,
        mainSubjectKey,
        linearFixedPeriod
      ),
    },
  };
  return newData;
};

export const getZscorePrevious = ({
  graphDateView,
  daysBefore,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Values number"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        daysBefore,
        "day",
        false,
        mainSubjectKey,
        linearValuesNumber
      ),
    },
  };
  return newData;
};
export const getZscorePreviousSubject = ({
  graphDateView,
  daysBefore,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Subject previous values"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        daysBefore,
        "day",
        true,
        mainSubjectKey,
        linearValuesNumber
      ),
    },
  };
  return newData;
};
export const getZscorePreviousPeriod = ({
  graphDateView,
  daysBefore,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Subject reference period"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        daysBefore,
        "day",
        true,
        mainSubjectKey,
        linearReferencePeriod
      ),
    },
  };
  return newData;
};

export const getZScorePeriod = ({
  graphDateView,
  daysBefore,
  dateRange,
  mainSubjectKey,
  defaultZero,
}) => {
  let newData;
  newData = {
    ["ZSCORE"]: {
      ["Reference Period"]: updateZScoreData(
        graphDateView,
        defaultZero,
        dateRange,
        daysBefore,
        "day",
        false,
        mainSubjectKey,
        linearReferencePeriod
      ),
    },
  };
  return newData;
};

export const getConfidenceStudy = ({ graphDateView, dateRange }) => {
  return confidenceData({ dateRange: dateRange, data: graphDateView });
};

export const getSymmetryData = ({ dateRange, graphDateView }) => {
  return symmetryAverageData({
    by: "day",
    dateRange: dateRange,
    data: graphDateView,
  });
};

export const getACWREWMA = ({
  graphDateView,
  dateRange,
  daysBeforeAcute,
  daysBeforeCronic,
}) => {
  return acrewmaOverPrevDays({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    daysBeforeAcute: daysBeforeAcute,
    daysBeforeCronic: daysBeforeCronic,
  });
};
const strainOverPrevDays = ({
  by,
  data,
  dateRange,
  daysBefore = 7,
  defaultZero = true,
}) => {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Iterate over study parameters
    accum[subjectName] = Object.entries(subjectData).reduce(
      (accum, [studyParamKey, studyData]) => {
        accum[studyParamKey] = computeInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBefore, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
          (values) => {
            const sum = values.reduce((a, b) => a + b, 0);
            const average = sum / values.length;
            const stddev = Math.sqrt(
              values.reduce((acc, val) => acc + Math.pow(val - average, 2), 0) /
                values.length
            );
            const monotony = stddev !== 0 ? average / stddev : 0;
            return monotony * sum;
          }
        );
        return accum;
      },
      {}
    );
    return accum;
  }, {});
};

export const getMonotonyStudy = ({ graphDateView, dateRange, daysBefore }) => {
  return monotonyOverPrevDays({
    by: "day",
    data: graphDateView,
    dateRange: dateRange,
    daysBefore: daysBefore,
  });
};

export const averageGraphData = (allData) => {
  Object.values(allData).forEach((lines) => {
    Object.entries(lines).forEach(([line, days]) => {
      Object.entries(days).forEach(([day, arr]) => {
        lines[line][day] = arr.reduce((tot, a) => tot + a, 0) / arr.length;
      });
    });
  });
};

export const processPatientData = (
  newPromsGraphData,
  patientData,
  newMcidList
) => {
  if (patientData.data) {
    patientData.data.forEach((promInstance) => {
      const answer = promInstance.proms_answers[0];
      if (answer) {
        //Obtain date
        const date = moment(promInstance.date)
          .startOf("day")
          .format(dateFormat);

        //Generate graph data
        const finalScores = calculateFinalScore(
          promInstance.prom,
          promInstance.proms_answers
        );
        newMcidList.add(promInstance.prom.id);

        //Save scores
        const name = promInstance.prom.key;
        finalScores.forEach(({ label, value }) => {
          if (!isNaN(value)) {
            const lineKey = `${name} - ${label}`;
            if (!newPromsGraphData[patientData.name])
              newPromsGraphData[patientData.name] = {};
            if (!newPromsGraphData[patientData.name][lineKey])
              newPromsGraphData[patientData.name][lineKey] = {};
            if (!newPromsGraphData[patientData.name][lineKey][date])
              newPromsGraphData[patientData.name][lineKey][date] = [];
            newPromsGraphData[patientData.name][lineKey][date].push(
              parseFloat(value)
            );
          }
        });
      }
    });
  }
};

export const updateDataPROMSPatients = async ({ filters, patId }) => {
  const patientResults = await getAllPromsSurveyAnswers({
    id_patients: [
      patId,
      ...filters.comparison.selectedUnlinkedPatients.map(({ id }) => id),
      ...filters.comparison.selectedLinkedPatients.map(({ id }) => id),
    ],
    starting_date: filters.startDate,
    ending_date: filters.endDate,
    proms: filters.activity.selectedStudyParamsPROMS.map(({ id }) => id),
  });

  const newMcidList = new Set();
  const newPromsGraphData = {};
  patientResults.forEach((p) =>
    processPatientData(newPromsGraphData, p, newMcidList)
  );
  averageGraphData(newPromsGraphData);
  return newPromsGraphData;
};
export const updateDataPROMSGroup = async ({ filters }) => {
  const groupResults = await getAllPromsSurveyAnswersGroups({
    id_groups: [filters.comparison.selectedGroupsPatients.map(({ id }) => id)],
    starting_date: filters.startDate,
    ending_date: filters.endDate,
    proms: filters.activity.selectedStudyParamsPROMS.map(({ id }) => id),
  });
  //Process data
  const newMcidListGroup = new Set();
  const groupData = {};
  const processedData = groupResults.map((group) => {
    //Average single patients
    const groupProcessedData = {};
    group.patients.forEach((p) =>
      processPatientData(groupProcessedData, p, newMcidListGroup)
    );
    averageGraphData(groupProcessedData);
    return {
      name: group.name,
      data: groupProcessedData,
    };
  });
  processGroupData(groupData, processedData);
  return groupData;
};

export const updateDataPROMSFunction = async ({ filters, patId }) => {
  const newPromsGraphData = await updateDataPROMSPatients({ filters, patId });
  const groupData = await updateDataPROMSGroup({ filters });
  return { groupData, newPromsGraphData };
};

export const updateDataMeasurementFunction = async ({ filters, patId }) => {
  const newMeasurementGraphData = await getMeasurementsParametersAnswers({
    id_patients: [
      patId,
      ...filters.comparison.selectedUnlinkedPatients.map(({ id }) => id),
      ...filters.comparison.selectedLinkedPatients.map(({ id }) => id),
    ],
    parameters: filters.activity.selectedMeasurements.map(({ id }) => id),
    startDate: moment(filters.startDate).format(dateFormat),
    endDate: moment(filters.endDate).format(dateFormatEndOfDay),
  });

  const groupData = await getMeasurementsParametersAnswersGroups({
    id_groups: [filters.comparison.selectedGroupsPatients.map(({ id }) => id)],
    parameters: filters.activity.selectedMeasurements.map(({ id }) => id),
    startDate: moment(filters.startDate).format(dateFormat),
    endDate: moment(filters.endDate).format(dateFormatEndOfDay),
  });
  return { groupData, newMeasurementGraphData };
};

export const getTableDataFromList = (data) => {
  let resultMapped = {};
  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((lineKey) => {
      Object.keys(data[key][lineKey]).forEach((patientName) => {
        if (!resultMapped[patientName]) resultMapped[patientName] = {};
        resultMapped[patientName][key] = data[key][lineKey][patientName];
      });
    });
  });

  let objectToPass = {};
  Object.keys(resultMapped).forEach((athlete) => {
    Object.keys(resultMapped[athlete]).forEach((study) => {
      Object.keys(resultMapped[athlete][study]).map((days) => {
        Object.keys(resultMapped[athlete][study][days]).map((day) => {
          objectToPass = {
            ...objectToPass,
            [`${athlete} - ${study} - ${days.split("-")[1]}`]: {
              ...resultMapped[athlete][study][days],
            },
          };
        });
      });
    });
  });
  return objectToPass;
};

export const getTableDataWorkload = (data, activities) => {
  let resultMapped = {};
  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((lineKey) => {
      Object.keys(data[key][lineKey]).forEach((patientName) => {
        if (!resultMapped[patientName]) resultMapped[patientName] = {};
        resultMapped[patientName][key] = data[key][lineKey][patientName];
      });
    });
  });

  let objectToPass = {};
  Object.keys(resultMapped).forEach((athlete) => {
    Object.keys(resultMapped[athlete]).forEach((study) => {
      Object.keys(resultMapped[athlete][study]).map((days) => {
        objectToPass = {
          ...objectToPass,
          [`${athlete} - ${study} - ${
            activities.activity.selectedStudyParams.find(({ id }) => id == days)
              ?.name
          }`]: {
            ...resultMapped[athlete][study][days],
          },
        };
      });
    });
  });
  return objectToPass;
};
export const getTableDataTest = (data, measurementSelected) => {
  let resultMapped = {};
  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((lineKey) => {
      Object.keys(data[key][lineKey]).forEach((patientName) => {
        if (!resultMapped[patientName]) resultMapped[patientName] = {};
        resultMapped[patientName][key] = data[key][lineKey][patientName];
      });
    });
  });

  let objectToPass = {};
  Object.keys(resultMapped).forEach((athlete) => {
    Object.keys(resultMapped[athlete]).forEach((study) => {
      Object.keys(resultMapped[athlete][study]).map((days) => {
        const tempMeasurement = measurementSelected.find(
          ({ id }) => id == days
        );
        Object.keys(resultMapped[athlete][study][days]).map((day) => {
          objectToPass = {
            ...objectToPass,
            [`${athlete} - ${study} - ${tempMeasurement.element_name} (${tempMeasurement.column_name})`]:
              {
                ...resultMapped[athlete][study][days],
              },
          };
        });
      });
    });
  });
  return objectToPass;
};

export const testTemplateHelper = async ({
  patients,
  assessmentsCounts,
  selectedAssessmentsTemplates,
  measurementsMapping,
  patId,
  filters,
  study,
  type,
  mainSubjectKey,
}) => {
  let temp = [];
  let result = {};
  await Promise.all(
    Object.keys(assessmentsCounts).map(async (patient) => {
      // filters.activity.
      await Promise.all(
        selectedAssessmentsTemplates.map(async ({ name }) => {
          result[name] = {};
          result[name]["TEMPORAL"] = {};
          result[name]["TEMPORAL"]["Line"] = {};
          const selected = assessmentsCounts[patient][name];
          temp.push({
            patient: patient,
            ids_monitoring_assessment: selected
              ? selected["ids_monitoring_assessment"]
              : [],
          });
          if (selected) {
            const id = patients.map(({ name }) => name).includes(patient)
              ? patients.find(({ name }) => name === patient).id
              : patId;

            const res = await getMeasurementsParametersAnswers({
              id_patients: [id],
              ids_monitoring_assessment: selected["ids_monitoring_assessment"],
              startDate: `${moment(moment().diff(1, "year")).format(
                "YYYY-MM-DD"
              )}`,
              endDate: `${moment(moment().add(1, "day")).format("YYYY-MM-DD")}`,
            });
            const newData = Object.entries(res).reduce(
              (accum, [patientKey, patientData]) => {
                accum[patientKey] = Object.entries(patientData).reduce(
                  (accum, [measurementId, measurementData]) => {
                    const currMeasurementName =
                      measurementsMapping[measurementId];
                    accum[currMeasurementName] = measurementData;
                    return accum;
                  },
                  {}
                );
                return accum;
              },
              {}
            );
            result = {
              ...result,
              [name]: {
                TEMPORAL: {
                  Line: { ...result[name]["TEMPORAL"]["Line"], ...newData },
                },
              },
            };
          }
        })
      );
    })
  );
  let tempMap = {};
  Object.keys(result).map((key) => {
    tempMap[key] = {
      ...getDataChartByFilters({
        study,
        filters,
        result: result[key]["TEMPORAL"]["Line"],
        type,
        mainSubjectKey,
      }),
    };
  });

  filters.comparison.selectedGroupsPatients.forEach(
    ({ id, name: groupName }) => {
      selectedAssessmentsTemplates.forEach(({ name }) => {
        const patientsData = tempMap[name]["TEMPORAL"]["Line"];

        const output = {
          [groupName]: {},
        };

        // Itera sull'input
        for (const person in patientsData) {
          const exams = patientsData[person];
          // Itera sugli esami per ogni persona
          for (const exam in exams) {
            const dates = exams[exam];
            // Inizializza un oggetto per tenere traccia dei valori per ogni parametro
            if (!output[groupName][exam]) {
              output[groupName][exam] = {};
            }
            // Itera sulle date per ogni esame
            for (const date in dates) {
              const value = dates[date];
              // Aggiungi il valore al parametro corrispondente
              if (!output[groupName][exam][date]) {
                output[groupName][exam][date] = [];
              }
              output[groupName][exam][date].push(value);
            }
          }
        }

        // Calcola la media per ogni parametro
        for (const exam in output[groupName]) {
          for (const date in output[groupName][exam]) {
            const values = output[groupName][exam][date];
            const sum = values.reduce((acc, val) => acc + val, 0);
            const average = sum / values.length;
            output[groupName][exam][date] = average;
          }
        }
        tempMap[name]["TEMPORAL"]["Line"][groupName] = { ...output[groupName] };
      });
    }
  );
  return tempMap;
};

export const updatePreferences = async ({ id_patient, id_group, data }) => {
  return await updateStatisticAnalyticProfessional({
    id_patient,
    id_group,
    data: {
      startDate: data.startDate,
      activity: {
        selectedActivites: data.activity.selectedActivites,
        selectedDateView: data.activity.selectedDateView,
        dateView: data.activity.dateView,
        selectedMeasurements: data.activity.selectedMeasurements,
        selectedStudyParams: data.activity.selectedStudyParams,
        selectedStudyParamsPROMS: data.activity.selectedStudyParamsPROMS,
        selectedAssessmentsTemplates:
          data.activity.selectedAssessmentsTemplates,
      },
      comparison: {
        selectedUnlinkedPatients: data.comparison.selectedUnlinkedPatients,
        selectedLinkedPatients: data.comparison.selectedLinkedPatients,
        selectedGroupsPatients: data.comparison.selectedGroupsPatients,
      },
      workloadStudy: data.workloadStudy,
      promsStudy: data.promsStudy,
      measurementsStudy: data.measurementsStudy,
    },
  });
};

export const checkZScore = ({ study }) => {
  let result = { checkPeriod: true, zScorePeriod: 7 };
  if (study.checkPeriod)
    result = {
      checkPeriod: true,
      zScorePeriod: study.zScorePeriod,
    };
  if (study.checkPreviousValues)
    result = {
      checkPreviousValues: true,
      zScorePreviousValues: study.zScorePreviousValues,
    };
  if (study.checkFixedPeriod)
    result = {
      checkFixedPeriod: true,
      startDateFixedZscore: study.startDateFixedZscore,
      endDateFixedZscore: study.endDateFixedZscore,
    };
  if (study.checkPreviousPeriod)
    result = {
      checkPreviousPeriod: true,
      previousPeriodValue: study.previousPeriodValue,
    };

  if (study.checkPreviousSubject)
    result = {
      checkPreviousSubject: true,
      previousSubjectValue: study.previousSubjectValue,
    };

  if (study.checkFixedSubject)
    result = {
      checkFixedSubject: true,
      startDateFixedSubject: study.startDateFixedSubject,
      endDateFixedSubject: study.endDateFixedSubject,
    };
  return result;
};

export const studies = [
  {
    id: 1,
    key: "TEMPORAL",
    name: "Timeline",
    description: "",
    um: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 2,
    key: "MONOTONY",
    name: "Monotony",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 3,
    key: "STRAIN",
    name: "Strain",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 4,
    key: "ACWRRA",
    name: "ACWR RA",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 5,
    key: "ACWREWMA",
    name: "ACWR EWMA",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 6,
    key: "COMPAREPERC",
    name: "Compare %",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 7,
    key: "COMPAREASS",
    name: "Compare abs",
    description: "",
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 8,
    key: "ZSCORE",
    name: "Z-Score",
    description: null,
    um: "",
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 13,
    key: "CONFIDENCE",
    name: "Confidence interval",
    description: null,
    um: null,
    createdAt: null,
    updatedAt: null,
  },
];

export const divideByUnits = ({ dataChart, selectedStudyParams }) => {
  let retObject = {};
  if (dataChart) {
    Object.entries(dataChart).forEach(([studyKey, studyData]) => {
      const currStudy = studies.find((study) => study.key === studyKey);
      //Iterate studies curves
      if (studyKey !== "ZSCORE")
        Object.entries(studyData).forEach(([curveName, curveData]) => {
          //Iterate subjects
          Object.entries(curveData).forEach(([subjectName, subjectData]) => {
            //Iterate parameters
            Object.entries(subjectData).forEach(([key, val]) => {
              const dashIndex = key.indexOf("-");

              let keyValue = key;
              if (dashIndex !== -1)
                keyValue = key.substring(0, dashIndex).trim();

              const currParam = selectedStudyParams.find(
                (param) => param.id === parseInt(key) || param.key === keyValue
              );
              let units = currStudy.um ?? currParam?.um;
              if (!units) units = "units";

              if (!retObject[units]) retObject[units] = {};
              if (!retObject[units][studyKey]) retObject[units][studyKey] = {};
              if (!retObject[units][studyKey][curveName])
                retObject[units][studyKey][curveName] = {};
              if (!retObject[units][studyKey][curveName][subjectName])
                retObject[units][studyKey][curveName][subjectName] = {};
              if (!retObject[units][studyKey][curveName][subjectName][key])
                retObject[units][studyKey][curveName][subjectName][key] = {};

              retObject[units] = {
                ...retObject[units],
                [studyKey]: {
                  ...retObject[units][studyKey],
                  [curveName]: {
                    ...retObject[units][studyKey][curveName],
                    [subjectName]: {
                      ...retObject[units][studyKey][curveName][subjectName],
                      [key]: val,
                    },
                  },
                },
              };
            });
          });
        });
    });
  }
  let ret = {};
  Object.keys(retObject).map((key) => {
    let feature = "";
    if (key === "units") {
      feature = key;
    } else {
      const um = key.split("-");
      um.forEach((item) => {
        const params = selectedStudyParams
          .filter(({ um }) => um === item)
          .map(({ name }) => name)
          .join(" - ");
        if (feature === "") {
          feature = params;
        } else {
          feature = `${feature} - ${params}`;
        }
      });
    }
    ret[feature] = retObject[key];
  });
  return ret;
};

export const createExcel = ({ data, filters }) => {
  let excelSheets = {};
  excelSheets = {
    ...createSheetFromData({
      data: data.dataTableRace,
      label: "WorkloadCompetition",
    }),
    ...createSheetFromData({
      data: data.dataTable,
      label: "Workload",
    }),
    ...createSheetFromData({
      data: data.dataTableMeasurement,
      label: labels.patient.graphReport.section.assessment.title,
    }),
  };

  data?.promsData.forEach((proms, index) => {
    let labelPROMSelected = "";
    try {
      const patients = Object.keys(proms["TEMPORAL"]["Line"]);
      if (patients.length > 0)
        labelPROMSelected = filters.activity.selectedStudyParamsPROMS.find(
          ({ key }) =>
            key ===
            Object.keys(proms["TEMPORAL"]["Line"][patients[0]])[0].split(" ")[0]
        );
    } catch (error) {
      labelPROMSelected = "";
    }

    excelSheets = {
      ...excelSheets,
      ...createSheetFromData({
        data: data.dataTablePROMS[index],
        label: psTranslate(labelPROMSelected?.name).split(" ").join(""),
      }),
    };
  });

  Object.keys(data.testList).forEach((item, index) => {
    excelSheets = {
      ...excelSheets,
      ...createSheetFromData({
        data: data.testTableList[index],
        label: psTranslate(item).split(" ").join(""),
      }),
    };
  });
  downloadExcel(excelSheets, "StatisticAnalytic");
  return excelSheets;
};

export const createSheetFromData = ({ data, label }) => {
  let excetData = [];
  Object.keys(data).forEach((key) => {
    excetData.push({ [label]: key, ...data[key] });
  });
  return { [label]: excetData };
};
