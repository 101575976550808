import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import {
  getSinsrByPresentation,
  updateSinsrPresentation,
} from "../../../models/actions/Presentation";
import { rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "45%",
  flexDirection: "column",
});

const PatientsPresentationDetailSins = (props) => {
  const [sinsrs, setSinsrs] = useState([]);

  const { presentationId, patId } = useParams();
  const { endDate } = props;

  const { control, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      const result = await getSinsrByPresentation({
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (result) {
        const sinsrIds = result.map((sinsr) => sinsr.id_answer);
        setValue("sins_answers", sinsrIds);
      }
      setSinsrs([...result]);
    } catch (error) {
      rollbar.error("PatientsPresentationDetailSins - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => fetchData(), []);

  const updateSins = async (data) => {
    try {
      const response = await updateSinsrPresentation({
        ...data,
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (response.error) {
        props.snackbarShowErrorMessage(response.error);
      } else {
        props.snackbarShowMessage(response.message);
      }
    } catch (error) {
      rollbar.error("PatientsPresentationDetailSins - updateSins", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <StyledBodyColumn>
        {!endDate && (
          <Grid item xs={4}>
            <SpButton
              style={{ width: "100%" }}
              variant="none"
              onClick={() => handleSubmit(updateSins)()}
              buttonType="accept"
              text={labels.patient.presentation.add.save}
            />
          </Grid>
        )}
        <StyledRow>
          <form onSubmit={handleSubmit(updateSins)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SpText style={{ marginTop: "3%" }} variant="h4ComponentLabel">
                  {labels.patient.presentation.add.stepper.step3.severityTitle}
                </SpText>
                <StyledRow>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpSelect
                        key={sinsrs[0]?.id_answer}
                        allowDeselect={true}
                        disabled={endDate}
                        value={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {labels.patient.presentation.add.stepper.step3.severityRows.map(
                          (p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {psTranslate(p.name)}
                            </SpSelectMenuItem>
                          )
                        )}
                      </SpSelect>
                    )}
                    defaultValue={sinsrs[0]?.id_answer}
                    name={`sins_answers[${0}]`}
                    control={control}
                  />
                </StyledRow>
              </Grid>
              <Grid item xs={6}>
                <SpText style={{ marginTop: "3%" }} variant="h4ComponentLabel">
                  {
                    labels.patient.presentation.add.stepper.step3
                      .irritabilityTitle
                  }
                </SpText>
                <StyledRow>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpSelect
                        key={sinsrs[1]?.id_answer}
                        value={props.value}
                        disabled={endDate}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {labels.patient.presentation.add.stepper.step3.irritabilityRows.map(
                          (p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {psTranslate(p.name)}
                            </SpSelectMenuItem>
                          )
                        )}
                      </SpSelect>
                    )}
                    defaultValue={sinsrs[1]?.id_answer}
                    name={`sins_answers[${1}]`}
                    control={control}
                  />
                </StyledRow>
              </Grid>
              <Grid item xs={6}>
                <SpText style={{ marginTop: "3%" }} variant="h4ComponentLabel">
                  {labels.patient.presentation.add.stepper.step3.originTitle}
                </SpText>
                <StyledRow>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpSelect
                        key={sinsrs[2]?.id_answer}
                        value={props.value}
                        disabled={endDate}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {labels.patient.presentation.add.stepper.step3.natureRows.map(
                          (p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {psTranslate(p.name)}
                            </SpSelectMenuItem>
                          )
                        )}
                      </SpSelect>
                    )}
                    defaultValue={sinsrs[2]?.id_answer}
                    name={`sins_answers[${2}]`}
                    control={control}
                  />
                </StyledRow>
              </Grid>
              <Grid item xs={6}>
                <SpText style={{ marginTop: "3%" }} variant="h4ComponentLabel">
                  {labels.patient.presentation.add.stepper.step3.statusTitle}
                </SpText>
                <StyledRow>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpSelect
                        key={sinsrs[3]?.id_answer}
                        disabled={endDate}
                        value={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {labels.patient.presentation.add.stepper.step3.levelRows.map(
                          (p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {psTranslate(p.name)}
                            </SpSelectMenuItem>
                          )
                        )}
                      </SpSelect>
                    )}
                    defaultValue={sinsrs[3]?.id_answer}
                    name={`sins_answers[${3}]`}
                    control={control}
                  />
                </StyledRow>
              </Grid>
            </Grid>
          </form>
        </StyledRow>
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetailSins);
