import React from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
// material ui
import Grid from "@material-ui/core/Grid";
// models
// components
import { styled } from "../../../../../../components/styled";
import { theme } from "../../../../../../components/theme";
// atoms
import { SpAutocomplete } from "../../../../../../components/atoms/SpAutocomplete";
import SpText from "../../../../../../components/atoms/SpText";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import { TestSection } from "./testSection";
import { PromsSection } from "./promsSection";
// utils
// mocks

const StyledGridContainerA = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "1%",
});

const StyledGridContainerB = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
});

export const RuleComposition = ({
  ruleInputs,
  setRuleInputs,
  setThresholdRef,
  thresholdRef,
  thresholdRef2,
  setThresholdRef2,
  logicals,
  rulesSelectable,
  ruleSelector,
  setRuleSelector,
  filteredElements,
  allRules,
}) => {
  const DefaultSection = () => (
    <>
      {/* parametro */}
      <Grid item xs={12}>
        <SpAutocomplete
          id={"selectParameter"}
          formControlWidth={"100%"}
          value={
            allRules[ruleSelector.selected]?.parameters.find(
              (el) => el === ruleInputs?.parameter
            ) ?? ""
          }
          disableListWrap
          label={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .parameter
          }
          selectPlaceholder={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .selParam
          }
          onChange={(e, newValue) => {
            setRuleInputs({
              ...ruleInputs,
              parameter: newValue,
            });
          }}
          options={allRules[ruleSelector.selected]?.parameters}
          getOptionLabel={({ name }) => psTranslate(name)}
          getOptionSelected={({ id: id_opt }, { id: id_value }) =>
            id_opt === id_value
          }
        />
      </Grid>
      {/* sottoparametro */}
      <Grid item xs={12}>
        <SpAutocomplete
          id={"selectSubParameter"}
          formControlWidth={"100%"}
          value={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
              ({ key }) => key === ruleInputs?.subparameter
            ) ?? ""
          }
          disableListWrap
          label={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .subparameter
          }
          selectPlaceholder={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .selSubparam
          }
          onChange={(e, newValue) => {
            setRuleInputs({
              ...ruleInputs,
              subparameter: newValue.key,
            });
          }}
          options={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .study
          }
          getOptionLabel={({ label }) => psTranslate(label)}
          getOptionSelected={({ key: key_opt }, { key: key_value }) =>
            key_opt === key_value
          }
        />
      </Grid>
    </>
  );

  const getSection = () => {
    switch (ruleSelector.selected) {
      case "test":
        return (
          <TestSection
            ruleInputs={ruleInputs}
            setRuleInputs={setRuleInputs}
            ruleSelector={ruleSelector}
            allRules={allRules}
          />
        );
      case "prom":
        return (
          <PromsSection
            ruleInputs={ruleInputs}
            setRuleInputs={setRuleInputs}
            ruleSelector={ruleSelector}
            allRules={allRules}
          />
        );

      default:
        return <DefaultSection />;
    }
  };

  return (
    <Grid container item xs={5}>
      <StyledGridContainerB key="">
        <StyledGridContainerA>
          <Grid container xs={12}>
            {/* RULE TYPE */}
            {labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.paramType.map(
              ({ label, key }, index) => {
                return (
                  <Grid
                    item
                    id={"selectRuleWorkload"}
                    xs={3}
                    onClick={(e) => {
                      setRuleSelector({ selected: key });
                      setRuleInputs({
                        anatomicalParts: null,
                        parameter: null,
                        subparameter: null,
                        logical: null,
                      });
                    }}
                    style={
                      ruleSelector.selected === key
                        ? {
                            borderTop: "2px solid #31ccad",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            paddingTop: "8px",
                          }
                        : {
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            paddingTop: "8px",
                          }
                    }
                  >
                    <SpText variant="h4ComponentLabelCenter">{label}</SpText>
                  </Grid>
                );
              }
            )}
            {/* RULE COMPOSING */}
            {getSection()}

            {/* predicato logico */}
            <Grid item xs={12}>
              <SpAutocomplete
                id={"selectLogicalPredicate"}
                formControlWidth={"100%"}
                value={logicals.find((el) => el === ruleInputs.logical) ?? ""}
                disableListWrap
                label={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.logical
                }
                selectPlaceholder={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.selLogical
                }
                onChange={(e, newValue) => {
                  setRuleInputs({
                    ...ruleInputs,
                    logical: newValue,
                  });
                }}
                options={logicals}
                getOptionLabel={({ label }) => psTranslate(label)}
                getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                  id_opt === id_value
                }
              />
            </Grid>
            {/* soglie */}
            <Grid item xs={5} style={{ paddingTop: "5px" }}>
              <SpTextInput
                type="number"
                value={thresholdRef}
                onChange={(e) => {
                  setThresholdRef(e.target.value);
                }}
                id={"inputThresold"}
                InputProps={{
                  startAdornment: (
                    <SpText
                      style={{
                        marginRight: "0.4em",
                        marginLeft: "0.4em",
                      }}
                    >
                      {ruleInputs?.subparameter?.um
                        ? ruleInputs?.subparameter?.um
                        : ""}
                    </SpText>
                  ),
                }}
                label={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.threshold
                }
                placeholder={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.selThresh
                }
                formControlStyle={{ width: "100%", marginTop: "1%" }}
              />
            </Grid>
            <Grid item xs={5}>
              <SpAutocomplete
                id={"inputThresold"}
                formControlWidth={"100%"}
                value={
                  labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
                    ({ key }) => key === thresholdRef
                  ) ?? ""
                }
                disableListWrap
                label={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.subparameter
                }
                selectPlaceholder={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.selSubparam
                }
                onChange={(e, newValue) => {
                  setThresholdRef(newValue.key);
                }}
                options={
                  labels.mylab.generalRiskFactor.addComp.ruleSection
                    .ruleComposition.study
                }
                getOptionLabel={({ label }) => psTranslate(label)}
                getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                  key_opt === key_value
                }
              />
            </Grid>
            <Grid item xs={1}></Grid>
            {ruleInputs.logical?.key === "betw" && (
              <>
                <Grid item xs={5} style={{ paddingTop: "5px" }}>
                  <SpTextInput
                    onChange={(e) => {
                      setThresholdRef2(e.target.value);
                    }}
                    type="number"
                    id={"inputThresold2"}
                    InputProps={{
                      startAdornment: (
                        <SpText
                          style={{
                            marginRight: "0.4em",
                            marginLeft: "0.4em",
                          }}
                        >
                          {ruleInputs?.subparameter?.um
                            ? ruleInputs?.subparameter?.um
                            : ""}
                        </SpText>
                      ),
                    }}
                    label={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.threshold
                    }
                    placeholder={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.selThresh
                    }
                    formControlStyle={{ width: "100%", marginTop: "1%" }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <SpAutocomplete
                    id={"inputThresold2"}
                    formControlWidth={"100%"}
                    value={
                      labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
                        ({ key }) => key === thresholdRef2
                      ) ?? ""
                    }
                    disableListWrap
                    label={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.subparameter
                    }
                    selectPlaceholder={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.selSubparam
                    }
                    onChange={(e, newValue) => {
                      setThresholdRef2(newValue.key);
                    }}
                    options={
                      labels.mylab.generalRiskFactor.addComp.ruleSection
                        .ruleComposition.study
                    }
                    getOptionLabel={({ label }) => psTranslate(label)}
                    getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                      key_opt === key_value
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </StyledGridContainerA>
      </StyledGridContainerB>
    </Grid>
  );
};
