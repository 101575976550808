export const patients = [
  {
    id: 97,
    familyName: "Nuovo",
    givenName: "Prova",
    email: "prova@prova.it",
    password: null,
    birth_date: "1995-09-17T22:00:00.000Z",
    birth_location: null,
    gender: "M",
    family_doctor: "dott luzi",
    address: "via delle vie 1",
    city: null,
    cap: null,
    province: null,
    nation: null,
    privacy: null,
    emergency_name: "Simone",
    emergency_telephone: "3208427538",
    app_activation: 0,
    phone: "3291743333",
    prevalent_hand: "R",
    prevalent_leg: "R",
    job: "spazzino",
    weight: "53",
    height: "167",
    BMI: "19.00",
    token: null,
    device_os: null,
    device_token: null,
    coupon_code: "VK4ZXE6L",
    silent_token: null,
    reset_password_code: null,
    is_already_enabled: false,
    training_status: "Altro",
    short_name: "nic",
    middle_name: "secondo",
    birth_area: "italia",
    passport_area: "italia",
    main_role: "terzino",
    national_team: "italia",
    shirt_number: "21",
    createdAt: "2022-08-04T11:15:32.000Z",
    updatedAt: "2023-02-14T16:47:35.000Z",
    patient_group: {
      id: 87,
      id_patient: 97,
      id_group: 46,
      createdAt: "2023-04-18T13:32:57.000Z",
      updatedAt: "2023-04-18T13:32:57.000Z",
    },
    groupPatient: true,
    injuries: [
      {
        id: 730,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-02-08T00:00:00.000Z",
        end_date: "2023-02-18T14:35:33.000Z",
        estimated_end_date: "2023-02-27T00:00:00.000Z",
        satisfaction: 1,
        id_exit_criteria: null,
        id_interview: 7,
        id_region: 9,
        id_area: 64,
        id_structure: 229,
        id_pathology: 239,
        type: "Infortunio",
        note: "",
        activity_during_injury: 1,
        mechanism: 2,
        reinjury: 2,
        createdAt: "2023-02-08T11:18:16.000Z",
        updatedAt: "2023-04-18T14:35:33.000Z",
        pathology: {
          id: 239,
          name: "Lesione del legamento glenoomerale",
          description: null,
          osiics_code: "SDG",
          id_tissue_type: 2,
          id_pathology_type: 2,
          id_medical_system_type: null,
          id_etiology: null,
          id_dysfunction: 289,
          createdAt: "2021-06-07T17:02:00.000Z",
          updatedAt: "2021-06-07T17:02:00.000Z",
        },
        region: {
          id: 9,
          key: "spallasx",
          name: "Regione Della Spalla Sinistra",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 64,
          key: "spallasx_anterolaterale",
          name: "Area Deltoidea Della Spalla Sinistra",
          latin_name: "null",
          english_name: "null",
          id_region: 9,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: {
          id: 229,
          name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
          id_area: 64,
          id_tissue_type: 2,
          latin_name: null,
          english_name: null,
          createdAt: "2021-09-19T13:21:00.000Z",
          updatedAt: "2021-09-19 13:21.00",
        },
      },
      {
        id: 894,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-02-01T00:00:00.000Z",
        end_date: "2023-04-26T15:36:17.000Z",
        estimated_end_date: null,
        satisfaction: 3,
        id_exit_criteria: null,
        id_interview: 7,
        id_region: 8,
        id_area: 62,
        id_structure: null,
        id_pathology: null,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-26T15:36:08.000Z",
        updatedAt: "2023-04-26T15:36:17.000Z",
        pathology: null,
        region: {
          id: 8,
          key: "spalladx",
          name: "Regione Della Spalla Destra",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 62,
          key: "spalladx_anterolaterale",
          name: "Area Deltoidea Della Spalla Destra",
          latin_name: "null",
          english_name: "null",
          id_region: 8,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: null,
      },
      {
        id: 895,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-02-23T00:00:00.000Z",
        end_date: "2023-04-26T15:38:55.000Z",
        estimated_end_date: null,
        satisfaction: 4,
        id_exit_criteria: null,
        id_interview: 10,
        id_region: 15,
        id_area: 83,
        id_structure: null,
        id_pathology: null,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-26T15:38:45.000Z",
        updatedAt: "2023-04-26T15:38:55.000Z",
        pathology: null,
        region: {
          id: 15,
          key: "avambracciosx",
          name: "Regione Dell'Avambraccio Sinistro",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 83,
          key: "avambracciosx_laterale",
          name: "Area Laterale Dell'Avambraccio Sinistro",
          latin_name: "null",
          english_name: "null",
          id_region: 15,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: null,
      },
      {
        id: 903,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-04-12T00:00:00.000Z",
        end_date: "2023-04-28T09:09:04.000Z",
        estimated_end_date: null,
        satisfaction: 4,
        id_exit_criteria: null,
        id_interview: 13,
        id_region: 20,
        id_area: 103,
        id_structure: 34,
        id_pathology: null,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-28T09:08:49.000Z",
        updatedAt: "2023-04-28T09:09:04.000Z",
        pathology: null,
        region: {
          id: 20,
          key: "ancadx",
          name: "Regione Dell'Anca Destra",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 103,
          key: "ancadx_anteromediale",
          name: "Area Anteromediale Dell'Anca Destra",
          latin_name: "null",
          english_name: "null",
          id_region: 20,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: {
          id: 34,
          name: "MUSCOLO ADDUTTORE BREVE DESTRO",
          id_area: 103,
          id_tissue_type: 9,
          latin_name: null,
          english_name: null,
          createdAt: "2021-09-19T13:21:00.000Z",
          updatedAt: "2021-09-19 13:21.00",
        },
      },
      {
        id: 905,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-04-28T00:00:00.000Z",
        end_date: "2023-04-28T15:21:21.000Z",
        estimated_end_date: null,
        satisfaction: 3,
        id_exit_criteria: null,
        id_interview: 3,
        id_region: 2,
        id_area: 26,
        id_structure: 448,
        id_pathology: 155,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-28T15:20:55.000Z",
        updatedAt: "2023-04-28T15:21:21.000Z",
        pathology: {
          id: 155,
          name: "Stiramento muscolare al collo",
          description: null,
          osiics_code: "NM1",
          id_tissue_type: 9,
          id_pathology_type: 11,
          id_medical_system_type: null,
          id_etiology: null,
          id_dysfunction: 295,
          createdAt: "2021-06-07T17:02:00.000Z",
          updatedAt: "2021-06-07T17:02:00.000Z",
        },
        region: {
          id: 2,
          key: "collo",
          name: "Regione Del Collo",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 26,
          key: "collo_anterioredx",
          name: "Area Laterale Del Collo Destra",
          latin_name: "null",
          english_name: "null",
          id_region: 2,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: {
          id: 448,
          name: "MUSCOLO LUNGO DEL COLLO DESTRO",
          id_area: 26,
          id_tissue_type: 9,
          latin_name: null,
          english_name: null,
          createdAt: "2021-09-19T13:21:00.000Z",
          updatedAt: "2021-09-19 13:21.00",
        },
      },
      {
        id: 906,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-04-01T00:00:00.000Z",
        end_date: "2023-04-28T15:25:02.000Z",
        estimated_end_date: null,
        satisfaction: 1,
        id_exit_criteria: null,
        id_interview: 3,
        id_region: 2,
        id_area: 27,
        id_structure: 469,
        id_pathology: 155,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-28T15:24:49.000Z",
        updatedAt: "2023-04-28T15:25:02.000Z",
        pathology: {
          id: 155,
          name: "Stiramento muscolare al collo",
          description: null,
          osiics_code: "NM1",
          id_tissue_type: 9,
          id_pathology_type: 11,
          id_medical_system_type: null,
          id_etiology: null,
          id_dysfunction: 295,
          createdAt: "2021-06-07T17:02:00.000Z",
          updatedAt: "2021-06-07T17:02:00.000Z",
        },
        region: {
          id: 2,
          key: "collo",
          name: "Regione Del Collo",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 27,
          key: "collo_anterioresx",
          name: "Area Laterale Del Collo Sinistra",
          latin_name: "null",
          english_name: "null",
          id_region: 2,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: {
          id: 469,
          name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
          id_area: 27,
          id_tissue_type: 9,
          latin_name: null,
          english_name: null,
          createdAt: "2021-09-19T13:21:00.000Z",
          updatedAt: "2021-09-19 13:21.00",
        },
      },
      {
        id: 907,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-04-21T00:00:00.000Z",
        end_date: "2023-04-28T15:26:18.000Z",
        estimated_end_date: null,
        satisfaction: 3,
        id_exit_criteria: null,
        id_interview: 8,
        id_region: 11,
        id_area: 68,
        id_structure: 2017,
        id_pathology: 294,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-04-28T15:26:11.000Z",
        updatedAt: "2023-04-28T15:26:18.000Z",
        pathology: {
          id: 294,
          name: "DOMS al braccio",
          description: null,
          osiics_code: "UMD",
          id_tissue_type: 9,
          id_pathology_type: 11,
          id_medical_system_type: null,
          id_etiology: null,
          id_dysfunction: 295,
          createdAt: "2021-06-07T17:02:00.000Z",
          updatedAt: "2021-06-07T17:02:00.000Z",
        },
        region: {
          id: 11,
          key: "bracciosx",
          name: "Regione Del Braccio Sinistro",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: {
          id: 68,
          key: "bracciosx_anteriore",
          name: "Area Anteriore Del Braccio Sinistro",
          latin_name: "null",
          english_name: "null",
          id_region: 11,
          createdAt: "2021-06-29T21:58:30.000Z",
          updatedAt: "2021-06-29T21:58:30.000Z",
        },
        structure: {
          id: 2017,
          name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
          id_area: 68,
          id_tissue_type: 9,
          latin_name: null,
          english_name: null,
          createdAt: "2021-09-19T13:21:00.000Z",
          updatedAt: "2021-09-19 13:21.00",
        },
      },
      {
        id: 919,
        id_patient: 97,
        id_professional: 117,
        start_date: "2023-05-04T00:00:00.000Z",
        end_date: "2023-05-11T10:13:31.000Z",
        estimated_end_date: null,
        satisfaction: 2,
        id_exit_criteria: null,
        id_interview: 10,
        id_region: 14,
        id_area: null,
        id_structure: null,
        id_pathology: null,
        type: "Infortunio",
        note: null,
        activity_during_injury: null,
        mechanism: null,
        reinjury: null,
        createdAt: "2023-05-11T10:11:45.000Z",
        updatedAt: "2023-05-11T10:13:31.000Z",
        pathology: null,
        region: {
          id: 14,
          key: "avambracciodx",
          name: "Regione Dell'Avambraccio Destro",
          latin_name: "null",
          english_name: "null",
          createdAt: null,
          updatedAt: null,
        },
        area: null,
        structure: null,
      },
    ],
  },
];

export const groups = [
  {
    idGruppo: 44,
    nameGruppo: "Federazione",
    training: [
      {
        id: 868,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 868,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-09-22",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 64,
        createdAt: "2022-09-22T10:00:36.000Z",
        updatedAt: "2022-09-22T10:00:36.000Z",
        activity_datum: {
          id: 868,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-09-22T10:00:36.000Z",
          updatedAt: "2022-09-22T15:32:32.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 377,
            id_activity: 868,
            notes: null,
            createdAt: "2022-09-22T10:00:36.000Z",
            updatedAt: "2022-09-22T10:00:36.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 615,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 377,
                date: null,
                createdAt: "2022-09-22T10:00:36.000Z",
                updatedAt: "2022-09-22T10:00:36.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 64,
          id_group: 44,
          createdAt: "2022-09-22T10:00:36.000Z",
          updatedAt: "2022-09-22T10:00:36.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 615,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 377,
                date: null,
                createdAt: "2022-09-22T10:00:36.000Z",
                updatedAt: "2022-09-22T10:00:36.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 883,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 883,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-10-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 68,
        createdAt: "2022-10-20T10:32:19.000Z",
        updatedAt: "2022-10-20T10:32:19.000Z",
        activity_datum: {
          id: 883,
          id_activity_type: 4,
          data: {
            training: [
              {
                id: 0,
              },
            ],
            training_motivation: "",
          },
          createdAt: "2022-10-20T10:32:19.000Z",
          updatedAt: "2022-10-21T14:16:50.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 391,
            id_activity: 883,
            notes: null,
            createdAt: "2022-10-20T10:32:19.000Z",
            updatedAt: "2022-10-20T10:32:19.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 673,
                id_parameter: 25,
                answer: "10",
                id_activity_feedback: 391,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 25,
                  id_category_load: 2,
                  name: "% sessione > 85% HR",
                  description: null,
                  um: "%",
                  key: "%sess>85%",
                  createdAt: "2021-10-06T11:20:35.000Z",
                  updatedAt: "2021-10-06T11:20:36.000Z",
                },
              },
              {
                id: 674,
                id_parameter: 15,
                answer: "10",
                id_activity_feedback: 391,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 15,
                  id_category_load: 2,
                  name: "1-RM",
                  description: null,
                  um: "Kg",
                  key: "1rm",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 68,
          id_group: 44,
          createdAt: "2022-10-20T10:32:19.000Z",
          updatedAt: "2022-10-20T10:32:19.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 673,
                id_parameter: 25,
                answer: "10",
                id_activity_feedback: 391,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 25,
                  id_category_load: 2,
                  name: "% sessione > 85% HR",
                  description: null,
                  um: "%",
                  key: "%sess>85%",
                  createdAt: "2021-10-06T11:20:35.000Z",
                  updatedAt: "2021-10-06T11:20:36.000Z",
                },
              },
              {
                id: 674,
                id_parameter: 15,
                answer: "10",
                id_activity_feedback: 391,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 15,
                  id_category_load: 2,
                  name: "1-RM",
                  description: null,
                  um: "Kg",
                  key: "1rm",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 671,
                id_parameter: 25,
                answer: "10",
                id_activity_feedback: 392,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 25,
                  id_category_load: 2,
                  name: "% sessione > 85% HR",
                  description: null,
                  um: "%",
                  key: "%sess>85%",
                  createdAt: "2021-10-06T11:20:35.000Z",
                  updatedAt: "2021-10-06T11:20:36.000Z",
                },
              },
              {
                id: 672,
                id_parameter: 15,
                answer: "10",
                id_activity_feedback: 392,
                date: "2022-10-05",
                createdAt: "2022-10-21T14:16:51.000Z",
                updatedAt: "2022-10-21T14:17:48.000Z",
                feedback_parameter: {
                  id: 15,
                  id_category_load: 2,
                  name: "1-RM",
                  description: null,
                  um: "Kg",
                  key: "1rm",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 920,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 920,
        id_reference: null,
        end_date: null,
        start_time: "06:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-10-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 78,
        createdAt: "2022-10-26T14:03:32.000Z",
        updatedAt: "2022-10-26T20:22:25.000Z",
        activity_datum: {
          id: 920,
          id_activity_type: 4,
          data: {
            training: [
              {
                id: 0,
                activity: "Corsa",
                duration: "15",
                description: "max speed",
              },
            ],
            training_motivation: "",
          },
          createdAt: "2022-10-26T14:03:32.000Z",
          updatedAt: "2022-10-26T20:22:25.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 422,
            id_activity: 920,
            notes: null,
            createdAt: "2022-10-26T14:03:32.000Z",
            updatedAt: "2022-10-26T14:03:32.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 715,
                id_parameter: 14,
                answer: null,
                id_activity_feedback: 422,
                date: null,
                createdAt: "2022-10-26T14:03:32.000Z",
                updatedAt: "2022-10-26T14:03:32.000Z",
                feedback_parameter: {
                  id: 14,
                  id_category_load: 2,
                  name: "Velocità massima",
                  description: null,
                  um: "km/h",
                  key: "velmax",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 78,
          id_group: 44,
          createdAt: "2022-10-26T14:03:32.000Z",
          updatedAt: "2022-10-26T14:03:32.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 715,
                id_parameter: 14,
                answer: null,
                id_activity_feedback: 422,
                date: null,
                createdAt: "2022-10-26T14:03:32.000Z",
                updatedAt: "2022-10-26T14:03:32.000Z",
                feedback_parameter: {
                  id: 14,
                  id_category_load: 2,
                  name: "Velocità massima",
                  description: null,
                  um: "km/h",
                  key: "velmax",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 716,
                id_parameter: 14,
                answer: null,
                id_activity_feedback: 423,
                date: null,
                createdAt: "2022-10-26T14:03:32.000Z",
                updatedAt: "2022-10-26T14:03:32.000Z",
                feedback_parameter: {
                  id: 14,
                  id_category_load: 2,
                  name: "Velocità massima",
                  description: null,
                  um: "km/h",
                  key: "velmax",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 938,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 938,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-11-02",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 79,
        createdAt: "2022-11-02T11:29:38.000Z",
        updatedAt: "2022-11-02T11:29:38.000Z",
        activity_datum: {
          id: 938,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-11-02T11:29:38.000Z",
          updatedAt: "2022-11-02T11:29:38.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 440,
            id_activity: 938,
            notes: null,
            createdAt: "2022-11-02T11:29:38.000Z",
            updatedAt: "2022-11-02T11:29:38.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 717,
                id_parameter: 23,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 718,
                id_parameter: 24,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 24,
                  id_category_load: 2,
                  name: "Numero di sprint",
                  description: null,
                  um: "num",
                  key: "nsprint",
                  createdAt: "2021-10-06T11:19:54.000Z",
                  updatedAt: "2021-10-06T11:19:55.000Z",
                },
              },
              {
                id: 719,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 720,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 721,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 79,
          id_group: 44,
          createdAt: "2022-11-02T11:29:38.000Z",
          updatedAt: "2022-11-02T11:29:38.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 717,
                id_parameter: 23,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 718,
                id_parameter: 24,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 24,
                  id_category_load: 2,
                  name: "Numero di sprint",
                  description: null,
                  um: "num",
                  key: "nsprint",
                  createdAt: "2021-10-06T11:19:54.000Z",
                  updatedAt: "2021-10-06T11:19:55.000Z",
                },
              },
              {
                id: 719,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 720,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 721,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 440,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 722,
                id_parameter: 23,
                answer: null,
                id_activity_feedback: 441,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 723,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 441,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 724,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 441,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 725,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 441,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 726,
                id_parameter: 24,
                answer: null,
                id_activity_feedback: 441,
                date: null,
                createdAt: "2022-11-02T11:29:38.000Z",
                updatedAt: "2022-11-02T11:29:38.000Z",
                feedback_parameter: {
                  id: 24,
                  id_category_load: 2,
                  name: "Numero di sprint",
                  description: null,
                  um: "num",
                  key: "nsprint",
                  createdAt: "2021-10-06T11:19:54.000Z",
                  updatedAt: "2021-10-06T11:19:55.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 940,
        id_monitoring: 179,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 940,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-11-02",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 80,
        createdAt: "2022-11-02T11:45:04.000Z",
        updatedAt: "2022-11-02T11:45:04.000Z",
        activity_datum: {
          id: 940,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-11-02T11:45:04.000Z",
          updatedAt: "2022-11-02T11:45:04.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 442,
            id_activity: 940,
            notes: null,
            createdAt: "2022-11-02T11:45:04.000Z",
            updatedAt: "2022-11-02T11:45:04.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 727,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 442,
                date: null,
                createdAt: "2022-11-02T11:45:04.000Z",
                updatedAt: "2022-11-02T11:45:04.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 179,
          id_patient: 101,
          id_professional: 6,
          starting_date: "2022-10-24 11:00:48",
          ending_date: "2023-03-31 08:54:10",
          createdAt: "2022-10-24T11:00:48.000Z",
          updatedAt: "2023-03-31T08:54:10.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 80,
          id_group: 44,
          createdAt: "2022-11-02T11:45:04.000Z",
          updatedAt: "2022-11-02T11:45:04.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 727,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 442,
                date: null,
                createdAt: "2022-11-02T11:45:04.000Z",
                updatedAt: "2022-11-02T11:45:04.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 728,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 443,
                date: null,
                createdAt: "2022-11-02T11:45:05.000Z",
                updatedAt: "2022-11-02T11:45:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1012,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1012,
        id_reference: null,
        end_date: null,
        start_time: "20:00:00",
        end_time: "21:00:00",
        event_note: "",
        start_date: "2022-11-22",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 119,
        createdAt: "2022-11-22T18:22:36.000Z",
        updatedAt: "2022-11-22T18:27:54.000Z",
        activity_datum: {
          id: 1012,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-11-22T18:22:36.000Z",
          updatedAt: "2022-11-22T18:27:52.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 512,
            id_activity: 1012,
            notes: null,
            createdAt: "2022-11-22T18:22:36.000Z",
            updatedAt: "2022-11-22T18:22:36.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 1801,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 512,
                date: null,
                createdAt: "2022-11-22T18:22:36.000Z",
                updatedAt: "2022-11-22T18:22:36.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 119,
          id_group: 44,
          createdAt: "2022-11-22T18:22:36.000Z",
          updatedAt: "2022-11-22T18:22:36.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 1801,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 512,
                date: null,
                createdAt: "2022-11-22T18:22:36.000Z",
                updatedAt: "2022-11-22T18:22:36.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1016,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1016,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-11-23",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 123,
        createdAt: "2022-11-23T10:17:40.000Z",
        updatedAt: "2022-11-23T10:19:41.000Z",
        activity_datum: {
          id: 1016,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-11-23T10:17:39.000Z",
          updatedAt: "2022-11-24T16:49:53.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 516,
            id_activity: 1016,
            notes: null,
            createdAt: "2022-11-23T10:17:40.000Z",
            updatedAt: "2022-11-23T10:17:40.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 1805,
                id_parameter: 26,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-23T10:17:40.000Z",
                updatedAt: "2022-11-23T10:17:40.000Z",
                feedback_parameter: {
                  id: 26,
                  id_category_load: 2,
                  name: "Calorie",
                  description: null,
                  um: "cal",
                  key: "calorie",
                  createdAt: "2021-10-06T11:21:04.000Z",
                  updatedAt: "2021-10-06T11:21:04.000Z",
                },
              },
              {
                id: 1862,
                id_parameter: 19,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-24T16:49:21.000Z",
                updatedAt: "2022-11-24T16:49:21.000Z",
                feedback_parameter: {
                  id: 19,
                  id_category_load: 2,
                  name: "Cadenza al minuto",
                  description: null,
                  um: "num",
                  key: "cadenzaminuto",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1863,
                id_parameter: 15,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-24T16:49:53.000Z",
                updatedAt: "2022-11-24T16:49:53.000Z",
                feedback_parameter: {
                  id: 15,
                  id_category_load: 2,
                  name: "1-RM",
                  description: null,
                  um: "Kg",
                  key: "1rm",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 123,
          id_group: 44,
          createdAt: "2022-11-23T10:17:39.000Z",
          updatedAt: "2022-11-23T10:17:39.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 1805,
                id_parameter: 26,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-23T10:17:40.000Z",
                updatedAt: "2022-11-23T10:17:40.000Z",
                feedback_parameter: {
                  id: 26,
                  id_category_load: 2,
                  name: "Calorie",
                  description: null,
                  um: "cal",
                  key: "calorie",
                  createdAt: "2021-10-06T11:21:04.000Z",
                  updatedAt: "2021-10-06T11:21:04.000Z",
                },
              },
              {
                id: 1862,
                id_parameter: 19,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-24T16:49:21.000Z",
                updatedAt: "2022-11-24T16:49:21.000Z",
                feedback_parameter: {
                  id: 19,
                  id_category_load: 2,
                  name: "Cadenza al minuto",
                  description: null,
                  um: "num",
                  key: "cadenzaminuto",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1863,
                id_parameter: 15,
                answer: null,
                id_activity_feedback: 516,
                date: null,
                createdAt: "2022-11-24T16:49:53.000Z",
                updatedAt: "2022-11-24T16:49:53.000Z",
                feedback_parameter: {
                  id: 15,
                  id_category_load: 2,
                  name: "1-RM",
                  description: null,
                  um: "Kg",
                  key: "1rm",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1055,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1055,
        id_reference: null,
        end_date: null,
        start_time: "15:38:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2022-12-14",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 135,
        createdAt: "2022-12-15T08:11:49.000Z",
        updatedAt: "2022-12-15T08:11:49.000Z",
        activity_datum: {
          id: 1055,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2022-12-15T08:11:49.000Z",
          updatedAt: "2022-12-15T08:11:49.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 554,
            id_activity: 1055,
            notes: null,
            createdAt: "2022-12-15T08:11:49.000Z",
            updatedAt: "2022-12-15T08:11:49.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 1925,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 554,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-15T08:12:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1926,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 554,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-15T08:12:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 135,
          id_group: 44,
          createdAt: "2022-12-15T08:11:49.000Z",
          updatedAt: "2022-12-15T08:11:49.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 1925,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 554,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-15T08:12:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1926,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 554,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-15T08:12:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 1927,
                id_parameter: 11,
                answer: "1",
                id_activity_feedback: 555,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-16T08:58:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1928,
                id_parameter: 12,
                answer: "6690.28",
                id_activity_feedback: 555,
                date: "2022-12-14",
                createdAt: "2022-12-15T08:11:49.000Z",
                updatedAt: "2022-12-15T08:12:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1193,
        id_monitoring: 3091,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1192,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-01-04",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 189,
        createdAt: "2023-01-04T14:57:27.000Z",
        updatedAt: "2023-01-04T14:57:27.000Z",
        activity_datum: {
          id: 1192,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-01-04T14:57:27.000Z",
          updatedAt: "2023-01-04T14:57:27.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 693,
            id_activity: 1193,
            notes: null,
            createdAt: "2023-01-04T14:57:27.000Z",
            updatedAt: "2023-01-04T14:57:27.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2354,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2356,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2358,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2359,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3091,
          id_patient: 3476,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:54",
          ending_date: null,
          createdAt: "2022-12-15T08:07:54.000Z",
          updatedAt: "2022-12-15T08:07:54.000Z",
          patient: {
            id: 3476,
            familyName: "PELLEGRI",
            givenName: "PIETRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "FE4USOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:54.000Z",
            updatedAt: "2022-12-19T17:33:23.000Z",
          },
        },
        activity_group: {
          id: 189,
          id_group: 44,
          createdAt: "2023-01-04T14:57:27.000Z",
          updatedAt: "2023-01-04T14:57:27.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2354,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2356,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2358,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2359,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 693,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2355,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 694,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2357,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 694,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2360,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 694,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2361,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 694,
                date: null,
                createdAt: "2023-01-04T14:57:28.000Z",
                updatedAt: "2023-01-04T14:57:28.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
        ],
      },
      {
        id: 1195,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1194,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-01-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 190,
        createdAt: "2023-01-04T14:58:01.000Z",
        updatedAt: "2023-01-04T14:58:01.000Z",
        activity_datum: {
          id: 1194,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-01-04T14:58:01.000Z",
          updatedAt: "2023-01-04T14:58:01.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 695,
            id_activity: 1195,
            notes: null,
            createdAt: "2023-01-04T14:58:01.000Z",
            updatedAt: "2023-01-04T14:58:01.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2362,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2363,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2364,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2367,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 190,
          id_group: 44,
          createdAt: "2023-01-04T14:58:01.000Z",
          updatedAt: "2023-01-04T14:58:01.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2362,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2363,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2364,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2367,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 695,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2365,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 696,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2366,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 696,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2368,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 696,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2369,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 696,
                date: null,
                createdAt: "2023-01-04T14:58:01.000Z",
                updatedAt: "2023-01-04T14:58:01.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1197,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1196,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-01-04",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 191,
        createdAt: "2023-01-04T14:59:21.000Z",
        updatedAt: "2023-01-04T14:59:21.000Z",
        activity_datum: {
          id: 1196,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-01-04T14:59:21.000Z",
          updatedAt: "2023-01-04T14:59:21.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 697,
            id_activity: 1197,
            notes: null,
            createdAt: "2023-01-04T14:59:21.000Z",
            updatedAt: "2023-01-04T14:59:21.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2370,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2371,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2372,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2373,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 191,
          id_group: 44,
          createdAt: "2023-01-04T14:59:21.000Z",
          updatedAt: "2023-01-04T14:59:21.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2370,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2371,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2372,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2373,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 697,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2374,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 698,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2375,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 698,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2376,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 698,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2377,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 698,
                date: null,
                createdAt: "2023-01-04T14:59:21.000Z",
                updatedAt: "2023-01-04T14:59:21.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1199,
        id_monitoring: 3091,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1198,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-01-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 192,
        createdAt: "2023-01-04T15:04:54.000Z",
        updatedAt: "2023-01-04T15:04:54.000Z",
        activity_datum: {
          id: 1198,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-01-04T15:04:54.000Z",
          updatedAt: "2023-01-04T15:04:54.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 699,
            id_activity: 1199,
            notes: null,
            createdAt: "2023-01-04T15:04:54.000Z",
            updatedAt: "2023-01-04T15:04:54.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2378,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2379,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2380,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2381,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3091,
          id_patient: 3476,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:54",
          ending_date: null,
          createdAt: "2022-12-15T08:07:54.000Z",
          updatedAt: "2022-12-15T08:07:54.000Z",
          patient: {
            id: 3476,
            familyName: "PELLEGRI",
            givenName: "PIETRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "FE4USOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:54.000Z",
            updatedAt: "2022-12-19T17:33:23.000Z",
          },
        },
        activity_group: {
          id: 192,
          id_group: 44,
          createdAt: "2023-01-04T15:04:54.000Z",
          updatedAt: "2023-01-04T15:04:54.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2378,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2379,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2380,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2381,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 699,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2382,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 700,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2383,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 700,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2384,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 700,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2385,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 700,
                date: null,
                createdAt: "2023-01-04T15:04:54.000Z",
                updatedAt: "2023-01-04T15:04:54.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1270,
        id_monitoring: 3091,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1269,
        id_reference: null,
        end_date: null,
        start_time: "18:16:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-02-27",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 200,
        createdAt: "2023-02-28T10:11:02.000Z",
        updatedAt: "2023-02-28T10:11:02.000Z",
        activity_datum: {
          id: 1269,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-02-28T10:11:02.000Z",
          updatedAt: "2023-02-28T10:11:02.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 735,
            id_activity: 1270,
            notes: null,
            createdAt: "2023-02-28T10:11:02.000Z",
            updatedAt: "2023-02-28T10:11:02.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2424,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2425,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2426,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2427,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3091,
          id_patient: 3476,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:54",
          ending_date: null,
          createdAt: "2022-12-15T08:07:54.000Z",
          updatedAt: "2022-12-15T08:07:54.000Z",
          patient: {
            id: 3476,
            familyName: "PELLEGRI",
            givenName: "PIETRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "FE4USOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:54.000Z",
            updatedAt: "2022-12-19T17:33:23.000Z",
          },
        },
        activity_group: {
          id: 200,
          id_group: 44,
          createdAt: "2023-02-28T10:11:02.000Z",
          updatedAt: "2023-02-28T10:11:02.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2424,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2425,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2426,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2427,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 735,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:02.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2428,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 737,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2430,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 737,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2432,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 737,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2433,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 737,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3448,
            patientName: "MICHEL ADOPO",
            patientFeedbacks: [
              {
                id: 2429,
                id_parameter: 12,
                answer: "2404.73",
                id_activity_feedback: 736,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2431,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 736,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2434,
                id_parameter: 11,
                answer: "30",
                id_activity_feedback: 736,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2435,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 736,
                date: "2023-02-27",
                createdAt: "2023-02-28T10:11:03.000Z",
                updatedAt: "2023-02-28T10:11:18.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1296,
        id_monitoring: 3063,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1295,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-12",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 206,
        createdAt: "2023-04-06T15:49:12.000Z",
        updatedAt: "2023-04-12T08:05:47.000Z",
        activity_datum: {
          id: 1295,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-06T15:49:12.000Z",
          updatedAt: "2023-04-12T08:05:48.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 762,
            id_activity: 1296,
            notes: null,
            createdAt: "2023-04-06T15:49:12.000Z",
            updatedAt: "2023-04-06T15:49:12.000Z",
            activity_resp_parameter_feedbacks: [],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3063,
          id_patient: 3448,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:43",
          ending_date: null,
          createdAt: "2022-12-15T08:07:43.000Z",
          updatedAt: "2022-12-15T08:07:43.000Z",
          patient: {
            id: 3448,
            familyName: "ADOPO",
            givenName: "MICHEL",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "CRVTSOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "illnessButAvailable",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:43.000Z",
            updatedAt: "2023-03-02T10:36:18.000Z",
          },
        },
        activity_group: {
          id: 206,
          id_group: 44,
          createdAt: "2023-04-06T15:49:11.000Z",
          updatedAt: "2023-04-06T15:49:11.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 3448,
            patientName: "MICHEL ADOPO",
            patientFeedbacks: [],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [],
          },
          {
            id: 3452,
            patientName: "ANDREI ANTONIO ANTON",
            patientFeedbacks: [],
          },
        ],
      },
    ],
    match: [
      {
        id: 901,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 901,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "Note",
        start_date: "2022-10-13",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 73,
        createdAt: "2022-10-24T16:36:04.000Z",
        updatedAt: "2022-10-24T16:36:04.000Z",
        activity_datum: {
          id: 901,
          id_activity_type: 7,
          data: {
            race_minutes: "120",
            race_motivation: "",
          },
          createdAt: "2022-10-24T16:36:04.000Z",
          updatedAt: "2022-10-24T16:36:04.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 403,
            id_activity: 901,
            notes: null,
            createdAt: "2022-10-24T16:36:04.000Z",
            updatedAt: "2022-10-24T16:36:04.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 683,
                id_parameter: 26,
                answer: null,
                id_activity_feedback: 403,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 26,
                  id_category_load: 2,
                  name: "Calorie",
                  description: null,
                  um: "cal",
                  key: "calorie",
                  createdAt: "2021-10-06T11:21:04.000Z",
                  updatedAt: "2021-10-06T11:21:04.000Z",
                },
              },
              {
                id: 684,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 403,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 73,
          id_group: 44,
          createdAt: "2022-10-24T16:36:04.000Z",
          updatedAt: "2022-10-24T16:36:04.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 683,
                id_parameter: 26,
                answer: null,
                id_activity_feedback: 403,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 26,
                  id_category_load: 2,
                  name: "Calorie",
                  description: null,
                  um: "cal",
                  key: "calorie",
                  createdAt: "2021-10-06T11:21:04.000Z",
                  updatedAt: "2021-10-06T11:21:04.000Z",
                },
              },
              {
                id: 684,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 403,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 685,
                id_parameter: 26,
                answer: null,
                id_activity_feedback: 404,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 26,
                  id_category_load: 2,
                  name: "Calorie",
                  description: null,
                  um: "cal",
                  key: "calorie",
                  createdAt: "2021-10-06T11:21:04.000Z",
                  updatedAt: "2021-10-06T11:21:04.000Z",
                },
              },
              {
                id: 686,
                id_parameter: 22,
                answer: null,
                id_activity_feedback: 404,
                date: null,
                createdAt: "2022-10-24T16:36:05.000Z",
                updatedAt: "2022-10-24T16:36:05.000Z",
                feedback_parameter: {
                  id: 22,
                  id_category_load: 2,
                  name: "Distanza in Sprint",
                  description: null,
                  um: "metri",
                  key: "distsprint",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 998,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 996,
        id_reference: null,
        end_date: null,
        start_time: "15:00:00",
        end_time: "16:40:00",
        event_note: "",
        start_date: "2022-11-13",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 114,
        createdAt: "2022-11-16T08:46:09.000Z",
        updatedAt: "2022-11-16T08:46:09.000Z",
        activity_datum: {
          id: 996,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2022-11-16T08:46:09.000Z",
          updatedAt: "2022-11-16T08:46:09.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 497,
            id_activity: 998,
            notes: null,
            createdAt: "2022-11-16T08:46:10.000Z",
            updatedAt: "2022-11-16T08:46:10.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 1769,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 497,
                date: "2022-11-13",
                createdAt: "2022-11-16T08:46:10.000Z",
                updatedAt: "2022-11-16T08:46:21.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1770,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 497,
                date: "2022-11-13",
                createdAt: "2022-11-16T08:46:10.000Z",
                updatedAt: "2022-11-16T08:46:21.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 114,
          id_group: 44,
          createdAt: "2022-11-16T08:46:09.000Z",
          updatedAt: "2022-11-16T08:46:09.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 1769,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 497,
                date: "2022-11-13",
                createdAt: "2022-11-16T08:46:10.000Z",
                updatedAt: "2022-11-16T08:46:21.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 1770,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 497,
                date: "2022-11-13",
                createdAt: "2022-11-16T08:46:10.000Z",
                updatedAt: "2022-11-16T08:46:21.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1299,
        id_monitoring: 3091,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1298,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-03-31",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 207,
        createdAt: "2023-04-07T08:31:15.000Z",
        updatedAt: "2023-04-07T08:31:21.000Z",
        activity_datum: {
          id: 1298,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-04-07T08:31:15.000Z",
          updatedAt: "2023-04-07T08:31:20.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 766,
            id_activity: 1299,
            notes: null,
            createdAt: "2023-04-07T08:31:15.000Z",
            updatedAt: "2023-04-07T08:31:15.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2518,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2519,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2520,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2521,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3091,
          id_patient: 3476,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:54",
          ending_date: null,
          createdAt: "2022-12-15T08:07:54.000Z",
          updatedAt: "2022-12-15T08:07:54.000Z",
          patient: {
            id: 3476,
            familyName: "PELLEGRI",
            givenName: "PIETRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "FE4USOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:54.000Z",
            updatedAt: "2022-12-19T17:33:23.000Z",
          },
        },
        activity_group: {
          id: 207,
          id_group: 44,
          createdAt: "2023-04-07T08:31:14.000Z",
          updatedAt: "2023-04-07T08:31:14.000Z",
        },
        groupName: "Federazione",
        patients: [
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2518,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2519,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2520,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2521,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 766,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3448,
            patientName: "MICHEL ADOPO",
            patientFeedbacks: [
              {
                id: 2512,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 764,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2515,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 764,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2516,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 764,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2517,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 764,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3452,
            patientName: "ANDREI ANTONIO ANTON",
            patientFeedbacks: [
              {
                id: 2510,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 765,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2511,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 765,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2513,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 765,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2514,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 765,
                date: null,
                createdAt: "2023-04-07T08:31:15.000Z",
                updatedAt: "2023-04-07T08:31:15.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    patients: [
      {
        id: 3476,
        familyName: "PELLEGRI",
        givenName: "PIETRO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "FE4USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:54.000Z",
        updatedAt: "2022-12-19T17:33:23.000Z",
        patient_group: {
          id: 71,
          id_patient: 3476,
          id_group: 44,
          createdAt: "2022-12-15T08:10:11.000Z",
          updatedAt: "2022-12-15T08:10:11.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3448,
        familyName: "ADOPO",
        givenName: "MICHEL",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "CRVTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: "illnessButAvailable",
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:43.000Z",
        updatedAt: "2023-03-02T10:36:18.000Z",
        patient_group: {
          id: 72,
          id_patient: 3448,
          id_group: 44,
          createdAt: "2023-01-05T09:40:27.000Z",
          updatedAt: "2023-01-05T09:40:27.000Z",
        },
        groupPatient: false,
        injuries: [
          {
            id: 790,
            id_patient: 3448,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: "2023-03-02T08:32:55.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 14,
            id_region: 21,
            id_area: 107,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:32:33.000Z",
            updatedAt: "2023-03-02T08:32:55.000Z",
            pathology: null,
            region: {
              id: 21,
              key: "ancasx",
              name: "Regione Dell'Anca Sinistra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 107,
              key: "ancasx_posteriore",
              name: "Area Glutea Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 21,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
        ],
      },
      {
        id: 3452,
        familyName: "ANTON",
        givenName: "ANDREI ANTONIO",
        email: "",
        password: null,
        birth_date: "2003-08-23T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "V3XTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:45.000Z",
        updatedAt: "2022-12-15T08:07:45.000Z",
        patient_group: {
          id: 77,
          id_patient: 3452,
          id_group: 44,
          createdAt: "2023-03-02T10:11:42.000Z",
          updatedAt: "2023-03-02T10:11:42.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
    ],
  },
  {
    idGruppo: 46,
    nameGruppo: "Primavera",
    training: [
      {
        id: 1275,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1274,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-03-08",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 201,
        createdAt: "2023-03-08T15:36:41.000Z",
        updatedAt: "2023-03-08T15:36:59.000Z",
        activity_datum: {
          id: 1274,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-03-08T15:36:41.000Z",
          updatedAt: "2023-03-08T15:36:58.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 739,
            id_activity: 1275,
            notes: null,
            createdAt: "2023-03-08T15:36:42.000Z",
            updatedAt: "2023-03-08T15:36:42.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2438,
                id_parameter: 12,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2439,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2440,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2441,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2446,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 201,
          id_group: 46,
          createdAt: "2023-03-08T15:36:41.000Z",
          updatedAt: "2023-03-08T15:36:41.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2438,
                id_parameter: 12,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2439,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2440,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2441,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2446,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2442,
                id_parameter: 12,
                answer: "101",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2443,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2444,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2445,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2447,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1293,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1292,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-03-31",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 204,
        createdAt: "2023-04-04T10:47:56.000Z",
        updatedAt: "2023-04-04T10:48:05.000Z",
        activity_datum: {
          id: 1292,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-04T10:47:56.000Z",
          updatedAt: "2023-04-04T10:48:04.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 758,
            id_activity: 1293,
            notes: null,
            createdAt: "2023-04-04T10:47:56.000Z",
            updatedAt: "2023-04-04T10:47:56.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2486,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2487,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2488,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2489,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 204,
          id_group: 46,
          createdAt: "2023-04-04T10:47:56.000Z",
          updatedAt: "2023-04-04T10:47:56.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2486,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2487,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2488,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2489,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1294,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1293,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-06",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 205,
        createdAt: "2023-04-06T08:33:53.000Z",
        updatedAt: "2023-04-18T14:59:39.000Z",
        activity_datum: {
          id: 1293,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-06T08:33:53.000Z",
          updatedAt: "2023-04-18T14:59:38.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 759,
            id_activity: 1294,
            notes: null,
            createdAt: "2023-04-06T08:33:53.000Z",
            updatedAt: "2023-04-06T08:33:53.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2490,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2491,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2492,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2493,
                id_parameter: 11,
                answer: "59",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 205,
          id_group: 46,
          createdAt: "2023-04-06T08:33:53.000Z",
          updatedAt: "2023-04-06T08:33:53.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2490,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2491,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2492,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2493,
                id_parameter: 11,
                answer: "59",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2494,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2495,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2496,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2497,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1303,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1302,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-06",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 208,
        createdAt: "2023-04-18T14:48:40.000Z",
        updatedAt: "2023-04-18T15:00:02.000Z",
        activity_datum: {
          id: 1302,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-18T14:48:40.000Z",
          updatedAt: "2023-04-18T15:00:01.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 768,
            id_activity: 1303,
            notes: null,
            createdAt: "2023-04-18T14:48:40.000Z",
            updatedAt: "2023-04-18T14:48:40.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2526,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2527,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2528,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2529,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 208,
          id_group: 46,
          createdAt: "2023-04-18T14:48:39.000Z",
          updatedAt: "2023-04-18T14:48:39.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2526,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2527,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2528,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2529,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2530,
                id_parameter: 11,
                answer: "57",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2531,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2532,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2533,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1429,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1428,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-28",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 216,
        createdAt: "2023-04-28T07:08:05.000Z",
        updatedAt: "2023-04-28T16:35:36.000Z",
        activity_datum: {
          id: 1428,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-28T07:08:05.000Z",
          updatedAt: "2023-04-28T16:35:34.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 894,
            id_activity: 1429,
            notes: null,
            createdAt: "2023-04-28T07:08:05.000Z",
            updatedAt: "2023-04-28T07:08:05.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2984,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2985,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2986,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2987,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 216,
          id_group: 46,
          createdAt: "2023-04-28T07:08:05.000Z",
          updatedAt: "2023-04-28T07:08:05.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2984,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2985,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2986,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2987,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2988,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2989,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2990,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2991,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1431,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1430,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-02",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 217,
        createdAt: "2023-05-08T13:17:07.000Z",
        updatedAt: "2023-05-08T13:17:14.000Z",
        activity_datum: {
          id: 1430,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-05-08T13:17:07.000Z",
          updatedAt: "2023-05-08T13:17:13.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 896,
            id_activity: 1431,
            notes: null,
            createdAt: "2023-05-08T13:17:07.000Z",
            updatedAt: "2023-05-08T13:17:07.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2992,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2993,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2994,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2995,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 217,
          id_group: 46,
          createdAt: "2023-05-08T13:17:07.000Z",
          updatedAt: "2023-05-08T13:17:07.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2992,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2993,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2994,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2995,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2996,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2997,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2998,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2999,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1437,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1436,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 220,
        createdAt: "2023-05-11T10:10:19.000Z",
        updatedAt: "2023-05-11T10:10:50.000Z",
        activity_datum: {
          id: 1436,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-05-11T10:10:19.000Z",
          updatedAt: "2023-05-11T10:10:49.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 902,
            id_activity: 1437,
            notes: null,
            createdAt: "2023-05-11T10:10:19.000Z",
            updatedAt: "2023-05-11T10:10:19.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3016,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3017,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3018,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3019,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 220,
          id_group: 46,
          createdAt: "2023-05-11T10:10:19.000Z",
          updatedAt: "2023-05-11T10:10:19.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3016,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3017,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3018,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3019,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3020,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3021,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3022,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3023,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    match: [
      {
        id: 1291,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1290,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-01",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 203,
        createdAt: "2023-04-04T10:36:45.000Z",
        updatedAt: "2023-04-04T10:47:39.000Z",
        activity_datum: {
          id: 1290,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-04-04T10:36:45.000Z",
          updatedAt: "2023-04-04T10:47:38.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 756,
            id_activity: 1291,
            notes: null,
            createdAt: "2023-04-04T10:36:45.000Z",
            updatedAt: "2023-04-04T10:36:45.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2478,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2479,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2480,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2481,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 203,
          id_group: 46,
          createdAt: "2023-04-04T10:36:44.000Z",
          updatedAt: "2023-04-04T10:36:44.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2478,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2479,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2480,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2481,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2482,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2483,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2484,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2485,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1433,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1432,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-09",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 218,
        createdAt: "2023-05-09T07:30:48.000Z",
        updatedAt: "2023-05-09T07:30:53.000Z",
        activity_datum: {
          id: 1432,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-09T07:30:48.000Z",
          updatedAt: "2023-05-09T07:30:52.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 898,
            id_activity: 1433,
            notes: null,
            createdAt: "2023-05-09T07:30:48.000Z",
            updatedAt: "2023-05-09T07:30:48.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3000,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3001,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3002,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3003,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 218,
          id_group: 46,
          createdAt: "2023-05-09T07:30:48.000Z",
          updatedAt: "2023-05-09T07:30:48.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3000,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3001,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3002,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3003,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3004,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3005,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3006,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3007,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1435,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1434,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-08",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 219,
        createdAt: "2023-05-09T07:32:56.000Z",
        updatedAt: "2023-05-09T07:33:02.000Z",
        activity_datum: {
          id: 1434,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-09T07:32:56.000Z",
          updatedAt: "2023-05-09T07:33:00.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 900,
            id_activity: 1435,
            notes: null,
            createdAt: "2023-05-09T07:32:56.000Z",
            updatedAt: "2023-05-09T07:32:56.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3008,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3009,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3010,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3011,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 219,
          id_group: 46,
          createdAt: "2023-05-09T07:32:56.000Z",
          updatedAt: "2023-05-09T07:32:56.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3008,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3009,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3010,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3011,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3012,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3013,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3014,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3015,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1439,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1438,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-03",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 221,
        createdAt: "2023-05-12T07:02:45.000Z",
        updatedAt: "2023-05-12T07:03:49.000Z",
        activity_datum: {
          id: 1438,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-12T07:02:45.000Z",
          updatedAt: "2023-05-12T07:03:48.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 904,
            id_activity: 1439,
            notes: null,
            createdAt: "2023-05-12T07:02:45.000Z",
            updatedAt: "2023-05-12T07:02:45.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3032,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3033,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3034,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3035,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 221,
          id_group: 46,
          createdAt: "2023-05-12T07:02:45.000Z",
          updatedAt: "2023-05-12T07:02:45.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3032,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3033,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3034,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3035,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3036,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3037,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3038,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3039,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    patients: [
      {
        id: 101,
        familyName: "Leonardi",
        givenName: "Simone",
        email: "leonardisimonemaria@gmail.com",
        password:
          "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: "",
        address: "",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: true,
        emergency_name: "",
        emergency_telephone: "",
        app_activation: 1,
        phone: "",
        prevalent_hand: null,
        prevalent_leg: null,
        job: "",
        weight: "",
        height: "",
        BMI: null,
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
        device_os: "ios",
        device_token:
          "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
        coupon_code: "8CSGCL8L",
        silent_token: "",
        reset_password_code: "LF6KCREI",
        is_already_enabled: true,
        training_status: "",
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2023-03-14T11:24:48.000Z",
        patient_group: {
          id: 76,
          id_patient: 101,
          id_group: 46,
          createdAt: "2023-02-20T09:19:21.000Z",
          updatedAt: "2023-02-20T09:19:21.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 642,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-01-10T00:00:00.000Z",
            end_date: "2023-02-27T14:42:43.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 31,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 1,
            reinjury: null,
            createdAt: "2023-01-10T17:22:30.000Z",
            updatedAt: "2023-02-27T14:42:43.000Z",
            pathology: {
              id: 31,
              name: "Naso faringite",
              description: null,
              osiics_code: "MPZT",
              id_tissue_type: null,
              id_pathology_type: null,
              id_medical_system_type: 5,
              id_etiology: 8,
              id_dysfunction: null,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 777,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-02-27T00:00:00.000Z",
            end_date: "2023-02-27T14:44:11.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 83,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-02-27T14:43:39.000Z",
            updatedAt: "2023-02-27T14:44:11.000Z",
            pathology: {
              id: 83,
              name: "Ferita al naso che non necessita di sutura",
              description: null,
              osiics_code: "HKN",
              id_tissue_type: 3,
              id_pathology_type: 3,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 472,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 789,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: "2023-03-02T08:26:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 15,
            id_region: 22,
            id_area: 108,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:25:48.000Z",
            updatedAt: "2023-03-02T08:26:07.000Z",
            pathology: null,
            region: {
              id: 22,
              key: "cosciadx",
              name: "Regione Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 108,
              key: "cosciadx_anteriore",
              name: "Area Anteriore Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 22,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 908,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T15:28:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 12,
            id_region: 17,
            id_area: 90,
            id_structure: 1600,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:02.000Z",
            updatedAt: "2023-04-28T15:28:07.000Z",
            pathology: null,
            region: {
              id: 17,
              key: "manodx",
              name: "Regione Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 90,
              key: "manodx_dorsale",
              name: "Area Dorsale Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 17,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1600,
              name: "ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA",
              id_area: 90,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 909,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-26T00:00:00.000Z",
            end_date: "2023-04-28T15:28:59.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: null,
            id_region: 7,
            id_area: 57,
            id_structure: 1088,
            id_pathology: 635,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:53.000Z",
            updatedAt: "2023-04-28T15:28:59.000Z",
            pathology: {
              id: 635,
              name: "Stiramento muscolare al tronco",
              description: null,
              osiics_code: "OMM",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 7,
              key: "addominale",
              name: "Regione Addominale",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 57,
              key: "addominale_fiancodx",
              name: "Area Del Fianco Destro",
              latin_name: "null",
              english_name: "null",
              id_region: 7,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1088,
              name: "MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO",
              id_area: 57,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
        ],
      },
      {
        id: 97,
        familyName: "Nuovo",
        givenName: "Prova",
        email: "prova@prova.it",
        password: null,
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "M",
        family_doctor: "dott luzi",
        address: "via delle vie 1",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: "Simone",
        emergency_telephone: "3208427538",
        app_activation: 0,
        phone: "3291743333",
        prevalent_hand: "R",
        prevalent_leg: "R",
        job: "spazzino",
        weight: "53",
        height: "167",
        BMI: "19.00",
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "VK4ZXE6L",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: "Altro",
        short_name: "nic",
        middle_name: "secondo",
        birth_area: "italia",
        passport_area: "italia",
        main_role: "terzino",
        national_team: "italia",
        shirt_number: "21",
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2023-02-14T16:47:35.000Z",
        patient_group: {
          id: 87,
          id_patient: 97,
          id_group: 46,
          createdAt: "2023-04-18T13:32:57.000Z",
          updatedAt: "2023-04-18T13:32:57.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 730,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-08T00:00:00.000Z",
            end_date: "2023-02-18T14:35:33.000Z",
            estimated_end_date: "2023-02-27T00:00:00.000Z",
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 9,
            id_area: 64,
            id_structure: 229,
            id_pathology: 239,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 2,
            reinjury: 2,
            createdAt: "2023-02-08T11:18:16.000Z",
            updatedAt: "2023-04-18T14:35:33.000Z",
            pathology: {
              id: 239,
              name: "Lesione del legamento glenoomerale",
              description: null,
              osiics_code: "SDG",
              id_tissue_type: 2,
              id_pathology_type: 2,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 289,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 9,
              key: "spallasx",
              name: "Regione Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 64,
              key: "spallasx_anterolaterale",
              name: "Area Deltoidea Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 9,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 229,
              name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
              id_area: 64,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 894,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-01T00:00:00.000Z",
            end_date: "2023-04-26T15:36:17.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 8,
            id_area: 62,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:36:08.000Z",
            updatedAt: "2023-04-26T15:36:17.000Z",
            pathology: null,
            region: {
              id: 8,
              key: "spalladx",
              name: "Regione Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 62,
              key: "spalladx_anterolaterale",
              name: "Area Deltoidea Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 8,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 895,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-23T00:00:00.000Z",
            end_date: "2023-04-26T15:38:55.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 15,
            id_area: 83,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:38:45.000Z",
            updatedAt: "2023-04-26T15:38:55.000Z",
            pathology: null,
            region: {
              id: 15,
              key: "avambracciosx",
              name: "Regione Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 83,
              key: "avambracciosx_laterale",
              name: "Area Laterale Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 15,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 903,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T09:09:04.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 13,
            id_region: 20,
            id_area: 103,
            id_structure: 34,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T09:08:49.000Z",
            updatedAt: "2023-04-28T09:09:04.000Z",
            pathology: null,
            region: {
              id: 20,
              key: "ancadx",
              name: "Regione Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 103,
              key: "ancadx_anteromediale",
              name: "Area Anteromediale Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 20,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 34,
              name: "MUSCOLO ADDUTTORE BREVE DESTRO",
              id_area: 103,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 905,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-28T00:00:00.000Z",
            end_date: "2023-04-28T15:21:21.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 26,
            id_structure: 448,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:20:55.000Z",
            updatedAt: "2023-04-28T15:21:21.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 26,
              key: "collo_anterioredx",
              name: "Area Laterale Del Collo Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 448,
              name: "MUSCOLO LUNGO DEL COLLO DESTRO",
              id_area: 26,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 906,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-01T00:00:00.000Z",
            end_date: "2023-04-28T15:25:02.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 27,
            id_structure: 469,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:24:49.000Z",
            updatedAt: "2023-04-28T15:25:02.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 27,
              key: "collo_anterioresx",
              name: "Area Laterale Del Collo Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 469,
              name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
              id_area: 27,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 907,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-21T00:00:00.000Z",
            end_date: "2023-04-28T15:26:18.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 8,
            id_region: 11,
            id_area: 68,
            id_structure: 2017,
            id_pathology: 294,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:26:11.000Z",
            updatedAt: "2023-04-28T15:26:18.000Z",
            pathology: {
              id: 294,
              name: "DOMS al braccio",
              description: null,
              osiics_code: "UMD",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 11,
              key: "bracciosx",
              name: "Regione Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 68,
              key: "bracciosx_anteriore",
              name: "Area Anteriore Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 11,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 2017,
              name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
              id_area: 68,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 919,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-05-04T00:00:00.000Z",
            end_date: "2023-05-11T10:13:31.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 14,
            id_area: null,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-05-11T10:11:45.000Z",
            updatedAt: "2023-05-11T10:13:31.000Z",
            pathology: null,
            region: {
              id: 14,
              key: "avambracciodx",
              name: "Regione Dell'Avambraccio Destro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: null,
            structure: null,
          },
        ],
      },
    ],
  },
  {
    idGruppo: 47,
    nameGruppo: "Torino",
    training: [
      {
        id: 1369,
        id_monitoring: 3070,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1368,
        id_reference: null,
        end_date: null,
        start_time: "11:55:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-25",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 213,
        createdAt: "2023-04-26T09:53:23.000Z",
        updatedAt: "2023-04-26T09:53:23.000Z",
        activity_datum: {
          id: 1368,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-26T09:53:23.000Z",
          updatedAt: "2023-04-26T09:53:23.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 834,
            id_activity: 1369,
            notes: null,
            createdAt: "2023-04-26T09:53:23.000Z",
            updatedAt: "2023-04-26T09:53:23.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2789,
                id_parameter: 12,
                answer: "2993.12",
                id_activity_feedback: 834,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2793,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 834,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3070,
          id_patient: 3455,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:46",
          ending_date: null,
          createdAt: "2022-12-15T08:07:46.000Z",
          updatedAt: "2022-12-15T08:07:46.000Z",
          patient: {
            id: 3455,
            familyName: "BUONGIORNO",
            givenName: "ALESSANDRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "X0YTSOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:46.000Z",
            updatedAt: "2022-12-15T08:07:46.000Z",
          },
        },
        activity_group: {
          id: 213,
          id_group: 47,
          createdAt: "2023-04-26T09:53:23.000Z",
          updatedAt: "2023-04-26T09:53:23.000Z",
        },
        groupName: "Torino",
        patients: [
          {
            id: 3455,
            patientName: "ALESSANDRO BUONGIORNO",
            patientFeedbacks: [
              {
                id: 2789,
                id_parameter: 12,
                answer: "2993.12",
                id_activity_feedback: 834,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2793,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 834,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3460,
            patientName: "KOFFI DJIDJI",
            patientFeedbacks: [
              {
                id: 2790,
                id_parameter: 12,
                answer: "2612.49",
                id_activity_feedback: 835,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2795,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 835,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4112,
            patientName: "ANDREW GRAVILLON",
            patientFeedbacks: [
              {
                id: 2791,
                id_parameter: 12,
                answer: "2510.28",
                id_activity_feedback: 836,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2797,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 836,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4113,
            patientName: "IVAN ILIC",
            patientFeedbacks: [
              {
                id: 2792,
                id_parameter: 12,
                answer: "2617.45",
                id_activity_feedback: 837,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2796,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 837,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3467,
            patientName: "YANN KARAMOH",
            patientFeedbacks: [
              {
                id: 2794,
                id_parameter: 12,
                answer: "2026.71",
                id_activity_feedback: 838,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2798,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 838,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3471,
            patientName: "KAROL LINETTY",
            patientFeedbacks: [
              {
                id: 2801,
                id_parameter: 12,
                answer: "3211.28",
                id_activity_feedback: 840,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2805,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 840,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3448,
            patientName: "MICHEL ADOPO",
            patientFeedbacks: [
              {
                id: 2809,
                id_parameter: 12,
                answer: "2776.15",
                id_activity_feedback: 842,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2812,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 842,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2799,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 839,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2800,
                id_parameter: 12,
                answer: "2987.05",
                id_activity_feedback: 839,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3453,
            patientName: "BRIAN BAYEYE",
            patientFeedbacks: [
              {
                id: 2811,
                id_parameter: 12,
                answer: "2765.88",
                id_activity_feedback: 843,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2817,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 843,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3474,
            patientName: "ALEKSEJ MIRANCHUK",
            patientFeedbacks: [
              {
                id: 2802,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 841,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2803,
                id_parameter: 12,
                answer: "2854.57",
                id_activity_feedback: 841,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3463,
            patientName: "GVIDAS GINEITIS",
            patientFeedbacks: [
              {
                id: 2813,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 848,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2826,
                id_parameter: 12,
                answer: "3009.02",
                id_activity_feedback: 848,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3478,
            patientName: "SAMUELE RICCI",
            patientFeedbacks: [
              {
                id: 2818,
                id_parameter: 12,
                answer: "2930.32",
                id_activity_feedback: 844,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2819,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 844,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3477,
            patientName: "NEMANJA RADONJIC",
            patientFeedbacks: [
              {
                id: 2808,
                id_parameter: 12,
                answer: "2816.54",
                id_activity_feedback: 847,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2810,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 847,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3481,
            patientName: "ANTONIO SANABRIA",
            patientFeedbacks: [
              {
                id: 2806,
                id_parameter: 11,
                answer: "40",
                id_activity_feedback: 846,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2807,
                id_parameter: 12,
                answer: "2241.35",
                id_activity_feedback: 846,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3470,
            patientName: "VALENTINO LAZARO",
            patientFeedbacks: [
              {
                id: 2814,
                id_parameter: 12,
                answer: "2802.93",
                id_activity_feedback: 849,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2815,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 849,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3479,
            patientName: "RICCARDO RODRIGUEZ",
            patientFeedbacks: [
              {
                id: 2804,
                id_parameter: 12,
                answer: "2980.13",
                id_activity_feedback: 845,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2824,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 845,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3485,
            patientName: "STEPHAN SINGO",
            patientFeedbacks: [
              {
                id: 2816,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 850,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2825,
                id_parameter: 12,
                answer: "2900.73",
                id_activity_feedback: 850,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3482,
            patientName: "PERR SCHUURS",
            patientFeedbacks: [
              {
                id: 2820,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 851,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2827,
                id_parameter: 12,
                answer: "2879.93",
                id_activity_feedback: 851,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3488,
            patientName: "MERGIM VOJVODA",
            patientFeedbacks: [
              {
                id: 2822,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 853,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2829,
                id_parameter: 12,
                answer: "2771.38",
                id_activity_feedback: 853,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3483,
            patientName: "DEMBA SECK",
            patientFeedbacks: [
              {
                id: 2821,
                id_parameter: 11,
                answer: "43",
                id_activity_feedback: 852,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2828,
                id_parameter: 12,
                answer: "2881.26",
                id_activity_feedback: 852,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3487,
            patientName: "NIKOLA VLASIC",
            patientFeedbacks: [
              {
                id: 2823,
                id_parameter: 11,
                answer: "46",
                id_activity_feedback: 854,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2830,
                id_parameter: 12,
                answer: "3184.07",
                id_activity_feedback: 854,
                date: "2023-04-25",
                createdAt: "2023-04-26T09:53:23.000Z",
                updatedAt: "2023-04-26T09:54:41.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    match: [
      {
        id: 1390,
        id_monitoring: 3070,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1389,
        id_reference: null,
        end_date: null,
        start_time: "18:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-22",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: null,
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 214,
        createdAt: "2023-04-26T09:59:50.000Z",
        updatedAt: "2023-04-26T09:59:50.000Z",
        activity_datum: {
          id: 1389,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-04-26T09:59:50.000Z",
          updatedAt: "2023-04-26T09:59:50.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 857,
            id_activity: 1390,
            notes: null,
            createdAt: "2023-04-26T09:59:50.000Z",
            updatedAt: "2023-04-26T09:59:50.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2840,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2846,
                id_parameter: 12,
                answer: "11221.35",
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2847,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2850,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 3070,
          id_patient: 3455,
          id_professional: 117,
          starting_date: "2022-12-15 08:07:46",
          ending_date: null,
          createdAt: "2022-12-15T08:07:46.000Z",
          updatedAt: "2022-12-15T08:07:46.000Z",
          patient: {
            id: 3455,
            familyName: "BUONGIORNO",
            givenName: "ALESSANDRO",
            email: "",
            password: null,
            birth_date: null,
            birth_location: null,
            gender: "X",
            family_doctor: null,
            address: null,
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: null,
            emergency_telephone: null,
            app_activation: 0,
            phone: null,
            prevalent_hand: null,
            prevalent_leg: null,
            job: null,
            weight: null,
            height: null,
            BMI: null,
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "X0YTSOBL",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: null,
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-12-15T08:07:46.000Z",
            updatedAt: "2022-12-15T08:07:46.000Z",
          },
        },
        activity_group: {
          id: 214,
          id_group: 47,
          createdAt: "2023-04-26T09:59:49.000Z",
          updatedAt: "2023-04-26T09:59:49.000Z",
        },
        groupName: "Torino",
        patients: [
          {
            id: 3455,
            patientName: "ALESSANDRO BUONGIORNO",
            patientFeedbacks: [
              {
                id: 2840,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2846,
                id_parameter: 12,
                answer: "11221.35",
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2847,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2850,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 857,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3449,
            patientName: "OLA AINA",
            patientFeedbacks: [
              {
                id: 2838,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 855,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2842,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 855,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2843,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 855,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2844,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 855,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3451,
            patientName: "JACOPO ANTOLINI",
            patientFeedbacks: [
              {
                id: 2870,
                id_parameter: 12,
                answer: "10638.33",
                id_activity_feedback: 863,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2871,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 863,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2873,
                id_parameter: 11,
                answer: "112",
                id_activity_feedback: 863,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2874,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 863,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 3457,
            patientName: "AARON CIAMMAGLICHELLA",
            patientFeedbacks: [
              {
                id: 2858,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 860,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2859,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 860,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2860,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 860,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2865,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 860,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3448,
            patientName: "MICHEL ADOPO",
            patientFeedbacks: [
              {
                id: 2882,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 867,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2884,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 867,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2885,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 867,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2886,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 867,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4113,
            patientName: "IVAN ILIC",
            patientFeedbacks: [
              {
                id: 2861,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 862,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2863,
                id_parameter: 12,
                answer: "12522.64",
                id_activity_feedback: 862,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2864,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 862,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2866,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 862,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4111,
            patientName: "FRANCESCO DELL'AQUILA",
            patientFeedbacks: [
              {
                id: 2854,
                id_parameter: 12,
                answer: "11295.02",
                id_activity_feedback: 865,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2855,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 865,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2857,
                id_parameter: 11,
                answer: "112",
                id_activity_feedback: 865,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2862,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 865,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3453,
            patientName: "BRIAN BAYEYE",
            patientFeedbacks: [
              {
                id: 2867,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 861,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2868,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 861,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2869,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 861,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2872,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 861,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3456,
            patientName: "LUIGI CACCAVO",
            patientFeedbacks: [
              {
                id: 2841,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 859,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2845,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 859,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2849,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 859,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2853,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 859,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3460,
            patientName: "KOFFI DJIDJI",
            patientFeedbacks: [
              {
                id: 2831,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 858,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2832,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 858,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2834,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 858,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2835,
                id_parameter: 12,
                answer: "10032.18",
                id_activity_feedback: 858,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3461,
            patientName: "SIMONE EDERA",
            patientFeedbacks: [
              {
                id: 2910,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 871,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2916,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 871,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2917,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 871,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2918,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 871,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3459,
            patientName: "ALI DEMBELE",
            patientFeedbacks: [
              {
                id: 2887,
                id_parameter: 12,
                answer: "10900.19",
                id_activity_feedback: 869,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2888,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 869,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2889,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 869,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2890,
                id_parameter: 11,
                answer: "112",
                id_activity_feedback: 869,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3470,
            patientName: "VALENTINO LAZARO",
            patientFeedbacks: [
              {
                id: 2848,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 864,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2851,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 864,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2852,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 864,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2856,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 864,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3472,
            patientName: "SASA LUKIC",
            patientFeedbacks: [
              {
                id: 2833,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 856,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2836,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 856,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2837,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 856,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2839,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 856,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 3476,
            patientName: "PIETRO PELLEGRI",
            patientFeedbacks: [
              {
                id: 2927,
                id_parameter: 12,
                answer: "8016.54",
                id_activity_feedback: 875,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2928,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 875,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2929,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 875,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2931,
                id_parameter: 11,
                answer: "83",
                id_activity_feedback: 875,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3467,
            patientName: "YANN KARAMOH",
            patientFeedbacks: [
              {
                id: 2909,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 878,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2912,
                id_parameter: 11,
                answer: "27",
                id_activity_feedback: 878,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2913,
                id_parameter: 12,
                answer: "3504.44",
                id_activity_feedback: 878,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2915,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 878,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 3462,
            patientName: "MATTHEW GARBETT",
            patientFeedbacks: [
              {
                id: 2930,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 880,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2932,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 880,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2933,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 880,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2935,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 880,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3477,
            patientName: "NEMANJA RADONJIC",
            patientFeedbacks: [
              {
                id: 2907,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 870,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2908,
                id_parameter: 12,
                answer: "8148.55",
                id_activity_feedback: 870,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2911,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 870,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2914,
                id_parameter: 11,
                answer: "69",
                id_activity_feedback: 870,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3475,
            patientName: "ANGE N'GUESSAN",
            patientFeedbacks: [
              {
                id: 2876,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 866,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2877,
                id_parameter: 12,
                answer: "9486.35",
                id_activity_feedback: 866,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2881,
                id_parameter: 11,
                answer: "112",
                id_activity_feedback: 866,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2883,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 866,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4112,
            patientName: "ANDREW GRAVILLON",
            patientFeedbacks: [
              {
                id: 2939,
                id_parameter: 12,
                answer: "2428.78",
                id_activity_feedback: 881,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2940,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 881,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2943,
                id_parameter: 11,
                answer: "19",
                id_activity_feedback: 881,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2947,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 881,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3471,
            patientName: "KAROL LINETTY",
            patientFeedbacks: [
              {
                id: 2920,
                id_parameter: 12,
                answer: "11799.35",
                id_activity_feedback: 877,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2924,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 877,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2925,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 877,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2926,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 877,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3465,
            patientName: "EMIRHAN ILKHAN",
            patientFeedbacks: [
              {
                id: 2899,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 876,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2900,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 876,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2901,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 876,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2904,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 876,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3463,
            patientName: "GVIDAS GINEITIS",
            patientFeedbacks: [
              {
                id: 2934,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 882,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2936,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 882,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2937,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 882,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2941,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 882,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 3478,
            patientName: "SAMUELE RICCI",
            patientFeedbacks: [
              {
                id: 2875,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 868,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2878,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 868,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2879,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 868,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2880,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 868,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3482,
            patientName: "PERR SCHUURS",
            patientFeedbacks: [
              {
                id: 2891,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 873,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2892,
                id_parameter: 12,
                answer: "10568.37",
                id_activity_feedback: 873,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2893,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 873,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2894,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 873,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3474,
            patientName: "ALEKSEJ MIRANCHUK",
            patientFeedbacks: [
              {
                id: 2967,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 889,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2969,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 889,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2970,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 889,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2971,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 889,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3487,
            patientName: "NIKOLA VLASIC",
            patientFeedbacks: [
              {
                id: 2919,
                id_parameter: 12,
                answer: "11951.74",
                id_activity_feedback: 874,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2921,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 874,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2922,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 874,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2923,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 874,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3483,
            patientName: "DEMBA SECK",
            patientFeedbacks: [
              {
                id: 2902,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 879,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2903,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 879,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2905,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 879,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2906,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 879,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4114,
            patientName: "ZANOS SAVVA",
            patientFeedbacks: [
              {
                id: 2974,
                id_parameter: 12,
                answer: "10435.78",
                id_activity_feedback: 890,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2976,
                id_parameter: 11,
                answer: "112",
                id_activity_feedback: 890,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2977,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 890,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2978,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 890,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3485,
            patientName: "STEPHAN SINGO",
            patientFeedbacks: [
              {
                id: 2895,
                id_parameter: 12,
                answer: "8512.95",
                id_activity_feedback: 872,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2896,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 872,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2897,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 872,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2898,
                id_parameter: 11,
                answer: "77",
                id_activity_feedback: 872,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3488,
            patientName: "MERGIM VOJVODA",
            patientFeedbacks: [
              {
                id: 2938,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 885,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2942,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 885,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2945,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 885,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2946,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 885,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3490,
            patientName: "DUODA WEIDMANN",
            patientFeedbacks: [
              {
                id: 2944,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 884,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2948,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 884,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2949,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 884,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2950,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 884,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 4115,
            patientName: "RONALDO VIEIRA",
            patientFeedbacks: [
              {
                id: 2962,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 887,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2964,
                id_parameter: 11,
                answer: "59",
                id_activity_feedback: 887,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2965,
                id_parameter: 12,
                answer: "5097.98",
                id_activity_feedback: 887,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2966,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 887,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3481,
            patientName: "ANTONIO SANABRIA",
            patientFeedbacks: [
              {
                id: 2953,
                id_parameter: 12,
                answer: "10301.84",
                id_activity_feedback: 888,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2956,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 888,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2957,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 888,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2958,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 888,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3480,
            patientName: "MARCEL RUSZEL",
            patientFeedbacks: [
              {
                id: 2968,
                id_parameter: 12,
                answer: "7327.37",
                id_activity_feedback: 891,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2972,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 891,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2973,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 891,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2975,
                id_parameter: 11,
                answer: "66",
                id_activity_feedback: 891,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3479,
            patientName: "RICCARDO RODRIGUEZ",
            patientFeedbacks: [
              {
                id: 2959,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 883,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2960,
                id_parameter: 12,
                answer: "10779.71",
                id_activity_feedback: 883,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2961,
                id_parameter: 11,
                answer: "96",
                id_activity_feedback: 883,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2963,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 883,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3492,
            patientName: "DAVID ZIMA",
            patientFeedbacks: [
              {
                id: 2951,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 886,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2952,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 886,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2954,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 886,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2955,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 886,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 3489,
            patientName: "SEBASTIAN WADE",
            patientFeedbacks: [
              {
                id: 2979,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 892,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2980,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 892,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2981,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 892,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2982,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 892,
                date: "2023-04-22",
                createdAt: "2023-04-26T09:59:50.000Z",
                updatedAt: "2023-04-26T10:01:11.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    patients: [
      {
        id: 3448,
        familyName: "ADOPO",
        givenName: "MICHEL",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "CRVTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: "illnessButAvailable",
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:43.000Z",
        updatedAt: "2023-03-02T10:36:18.000Z",
        patient_group: {
          id: 88,
          id_patient: 3448,
          id_group: 47,
          createdAt: "2023-04-26T08:56:29.000Z",
          updatedAt: "2023-04-26T08:56:29.000Z",
        },
        injuries: [
          {
            id: 790,
            id_patient: 3448,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: "2023-03-02T08:32:55.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 14,
            id_region: 21,
            id_area: 107,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:32:33.000Z",
            updatedAt: "2023-03-02T08:32:55.000Z",
            pathology: null,
            region: {
              id: 21,
              key: "ancasx",
              name: "Regione Dell'Anca Sinistra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 107,
              key: "ancasx_posteriore",
              name: "Area Glutea Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 21,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 791,
            id_patient: 3448,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: null,
            estimated_end_date: "2023-03-04T00:00:00.000Z",
            satisfaction: null,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 8,
            id_area: 62,
            id_structure: 2355,
            id_pathology: null,
            type: "Infortunio",
            note: "",
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:52:06.000Z",
            updatedAt: "2023-03-02T09:24:45.000Z",
            pathology: null,
            region: {
              id: 8,
              key: "spalladx",
              name: "Regione Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 62,
              key: "spalladx_anterolaterale",
              name: "Area Deltoidea Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 8,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 2355,
              name: "(BORSA ACROMIALE SUBCUTANEA DESTRA)",
              id_area: 62,
              id_tissue_type: 10,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
        ],
      },
      {
        id: 3449,
        familyName: "AINA",
        givenName: "OLA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "U6WTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:43.000Z",
        updatedAt: "2022-12-15T08:07:43.000Z",
        patient_group: {
          id: 89,
          id_patient: 3449,
          id_group: 47,
          createdAt: "2023-04-26T08:56:40.000Z",
          updatedAt: "2023-04-26T08:56:40.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3451,
        familyName: "ANTOLINI",
        givenName: "JACOPO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "TSWTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:44.000Z",
        updatedAt: "2022-12-15T08:07:44.000Z",
        patient_group: {
          id: 90,
          id_patient: 3451,
          id_group: 47,
          createdAt: "2023-04-26T08:56:56.000Z",
          updatedAt: "2023-04-26T08:56:56.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3453,
        familyName: "BAYEYE",
        givenName: "BRIAN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "0FXTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:45.000Z",
        updatedAt: "2022-12-15T08:07:45.000Z",
        patient_group: {
          id: 91,
          id_patient: 3453,
          id_group: 47,
          createdAt: "2023-04-26T08:57:07.000Z",
          updatedAt: "2023-04-26T08:57:07.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3455,
        familyName: "BUONGIORNO",
        givenName: "ALESSANDRO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "X0YTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:46.000Z",
        updatedAt: "2022-12-15T08:07:46.000Z",
        patient_group: {
          id: 92,
          id_patient: 3455,
          id_group: 47,
          createdAt: "2023-04-26T08:57:16.000Z",
          updatedAt: "2023-04-26T08:57:16.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3456,
        familyName: "CACCAVO",
        givenName: "LUIGI",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "YBYTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:46.000Z",
        updatedAt: "2022-12-15T08:07:46.000Z",
        patient_group: {
          id: 93,
          id_patient: 3456,
          id_group: 47,
          createdAt: "2023-04-26T08:57:27.000Z",
          updatedAt: "2023-04-26T08:57:27.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3457,
        familyName: "CIAMMAGLICHELLA",
        givenName: "AARON",
        email: "",
        password: null,
        birth_date: "2005-01-26T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "4NYTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:47.000Z",
        updatedAt: "2022-12-15T08:07:47.000Z",
        patient_group: {
          id: 94,
          id_patient: 3457,
          id_group: 47,
          createdAt: "2023-04-26T08:57:37.000Z",
          updatedAt: "2023-04-26T08:57:37.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 4111,
        familyName: "DELL'AQUILA",
        givenName: "FRANCESCO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "GKD3WUGL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2023-04-24T13:44:13.000Z",
        updatedAt: "2023-04-24T13:44:13.000Z",
        patient_group: {
          id: 95,
          id_patient: 4111,
          id_group: 47,
          createdAt: "2023-04-26T08:57:48.000Z",
          updatedAt: "2023-04-26T08:57:48.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3459,
        familyName: "DEMBELE",
        givenName: "ALI",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "X8ZTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:47.000Z",
        updatedAt: "2022-12-15T08:07:47.000Z",
        patient_group: {
          id: 96,
          id_patient: 3459,
          id_group: 47,
          createdAt: "2023-04-26T08:57:58.000Z",
          updatedAt: "2023-04-26T08:57:58.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3460,
        familyName: "DJIDJI",
        givenName: "KOFFI",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "WJZTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:48.000Z",
        updatedAt: "2022-12-15T08:07:48.000Z",
        patient_group: {
          id: 97,
          id_patient: 3460,
          id_group: 47,
          createdAt: "2023-04-26T08:58:06.000Z",
          updatedAt: "2023-04-26T08:58:06.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3461,
        familyName: "EDERA",
        givenName: "SIMONE",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "OUZTSOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:48.000Z",
        updatedAt: "2022-12-15T08:07:48.000Z",
        patient_group: {
          id: 98,
          id_patient: 3461,
          id_group: 47,
          createdAt: "2023-04-26T08:58:20.000Z",
          updatedAt: "2023-04-26T08:58:20.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3462,
        familyName: "GARBETT",
        givenName: "MATTHEW",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "M50USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:49.000Z",
        updatedAt: "2022-12-15T08:07:49.000Z",
        patient_group: {
          id: 99,
          id_patient: 3462,
          id_group: 47,
          createdAt: "2023-04-26T08:58:35.000Z",
          updatedAt: "2023-04-26T08:58:35.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3463,
        familyName: "GINEITIS",
        givenName: "GVIDAS",
        email: "",
        password: null,
        birth_date: "2000-04-15T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "FG0USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:49.000Z",
        updatedAt: "2022-12-15T08:07:49.000Z",
        patient_group: {
          id: 100,
          id_patient: 3463,
          id_group: 47,
          createdAt: "2023-04-26T08:58:46.000Z",
          updatedAt: "2023-04-26T08:58:46.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 4112,
        familyName: "GRAVILLON",
        givenName: "ANDREW",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "HCE3WUGL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2023-04-24T13:44:14.000Z",
        updatedAt: "2023-04-24T13:44:14.000Z",
        patient_group: {
          id: 101,
          id_patient: 4112,
          id_group: 47,
          createdAt: "2023-04-26T08:58:55.000Z",
          updatedAt: "2023-04-26T08:58:55.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 4113,
        familyName: "ILIC",
        givenName: "IVAN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "FOE3WUGL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2023-04-24T13:44:15.000Z",
        updatedAt: "2023-04-24T13:44:15.000Z",
        patient_group: {
          id: 102,
          id_patient: 4113,
          id_group: 47,
          createdAt: "2023-04-26T08:59:06.000Z",
          updatedAt: "2023-04-26T08:59:06.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3465,
        familyName: "ILKHAN",
        givenName: "EMIRHAN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "421USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:50.000Z",
        updatedAt: "2022-12-15T08:07:50.000Z",
        patient_group: {
          id: 103,
          id_patient: 3465,
          id_group: 47,
          createdAt: "2023-04-26T08:59:19.000Z",
          updatedAt: "2023-04-26T08:59:19.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3467,
        familyName: "KARAMOH",
        givenName: "YANN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "QN1USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:51.000Z",
        updatedAt: "2022-12-15T08:07:51.000Z",
        patient_group: {
          id: 104,
          id_patient: 3467,
          id_group: 47,
          createdAt: "2023-04-26T08:59:31.000Z",
          updatedAt: "2023-04-26T08:59:31.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3470,
        familyName: "LAZARO",
        givenName: "VALENTINO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "UK2USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:52.000Z",
        updatedAt: "2022-12-15T08:07:52.000Z",
        patient_group: {
          id: 105,
          id_patient: 3470,
          id_group: 47,
          createdAt: "2023-04-26T08:59:41.000Z",
          updatedAt: "2023-04-26T08:59:41.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3471,
        familyName: "LINETTY",
        givenName: "KAROL",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "IV2USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:52.000Z",
        updatedAt: "2022-12-15T08:07:52.000Z",
        patient_group: {
          id: 106,
          id_patient: 3471,
          id_group: 47,
          createdAt: "2023-04-26T08:59:50.000Z",
          updatedAt: "2023-04-26T08:59:50.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3472,
        familyName: "LUKIC",
        givenName: "SASA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "G63USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:52.000Z",
        updatedAt: "2022-12-15T08:07:52.000Z",
        patient_group: {
          id: 107,
          id_patient: 3472,
          id_group: 47,
          createdAt: "2023-04-26T08:59:58.000Z",
          updatedAt: "2023-04-26T08:59:58.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3474,
        familyName: "MIRANCHUK",
        givenName: "ALEKSEJ",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "GS3USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:53.000Z",
        updatedAt: "2022-12-15T08:07:53.000Z",
        patient_group: {
          id: 108,
          id_patient: 3474,
          id_group: 47,
          createdAt: "2023-04-26T09:00:12.000Z",
          updatedAt: "2023-04-26T09:00:12.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3475,
        familyName: "N'GUESSAN",
        givenName: "ANGE",
        email: "",
        password: null,
        birth_date: "2003-09-01T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "G34USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:54.000Z",
        updatedAt: "2022-12-15T08:07:54.000Z",
        patient_group: {
          id: 109,
          id_patient: 3475,
          id_group: 47,
          createdAt: "2023-04-26T09:00:21.000Z",
          updatedAt: "2023-04-26T09:00:21.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3476,
        familyName: "PELLEGRI",
        givenName: "PIETRO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "FE4USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:54.000Z",
        updatedAt: "2022-12-19T17:33:23.000Z",
        patient_group: {
          id: 110,
          id_patient: 3476,
          id_group: 47,
          createdAt: "2023-04-26T09:00:36.000Z",
          updatedAt: "2023-04-26T09:00:36.000Z",
        },
        injuries: [],
      },
      {
        id: 3477,
        familyName: "RADONJIC",
        givenName: "NEMANJA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "AP4USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:54.000Z",
        updatedAt: "2022-12-15T08:07:54.000Z",
        patient_group: {
          id: 111,
          id_patient: 3477,
          id_group: 47,
          createdAt: "2023-04-26T09:00:47.000Z",
          updatedAt: "2023-04-26T09:00:47.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3478,
        familyName: "RICCI",
        givenName: "SAMUELE",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "905USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:55.000Z",
        updatedAt: "2022-12-15T08:07:55.000Z",
        patient_group: {
          id: 112,
          id_patient: 3478,
          id_group: 47,
          createdAt: "2023-04-26T09:00:56.000Z",
          updatedAt: "2023-04-26T09:00:56.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3479,
        familyName: "RODRIGUEZ",
        givenName: "RICCARDO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "2B5USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:55.000Z",
        updatedAt: "2022-12-15T08:07:55.000Z",
        patient_group: {
          id: 113,
          id_patient: 3479,
          id_group: 47,
          createdAt: "2023-04-26T09:01:09.000Z",
          updatedAt: "2023-04-26T09:01:09.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3480,
        familyName: "RUSZEL",
        givenName: "MARCEL",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "TL5USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:56.000Z",
        updatedAt: "2022-12-15T08:07:56.000Z",
        patient_group: {
          id: 114,
          id_patient: 3480,
          id_group: 47,
          createdAt: "2023-04-26T09:01:18.000Z",
          updatedAt: "2023-04-26T09:01:18.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3481,
        familyName: "SANABRIA",
        givenName: "ANTONIO",
        email: "",
        password: null,
        birth_date: "1996-03-04T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "OW5USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:56.000Z",
        updatedAt: "2022-12-15T08:07:56.000Z",
        patient_group: {
          id: 115,
          id_patient: 3481,
          id_group: 47,
          createdAt: "2023-04-26T09:01:29.000Z",
          updatedAt: "2023-04-26T09:01:29.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 4114,
        familyName: "SAVVA",
        givenName: "ZANOS",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "I9G3WUGL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2023-04-24T13:44:17.000Z",
        updatedAt: "2023-04-24T13:44:17.000Z",
        patient_group: {
          id: 116,
          id_patient: 4114,
          id_group: 47,
          createdAt: "2023-04-26T09:01:41.000Z",
          updatedAt: "2023-04-26T09:01:41.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3482,
        familyName: "SCHUURS",
        givenName: "PERR",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "O76USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:56.000Z",
        updatedAt: "2022-12-15T08:07:56.000Z",
        patient_group: {
          id: 117,
          id_patient: 3482,
          id_group: 47,
          createdAt: "2023-04-26T09:01:53.000Z",
          updatedAt: "2023-04-26T09:01:53.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3483,
        familyName: "SECK",
        givenName: "DEMBA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "KI6USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:57.000Z",
        updatedAt: "2022-12-15T08:07:57.000Z",
        patient_group: {
          id: 118,
          id_patient: 3483,
          id_group: 47,
          createdAt: "2023-04-26T09:02:01.000Z",
          updatedAt: "2023-04-26T09:02:01.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3485,
        familyName: "SINGO",
        givenName: "STEPHAN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "K47USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:58.000Z",
        updatedAt: "2022-12-15T08:07:58.000Z",
        patient_group: {
          id: 119,
          id_patient: 3485,
          id_group: 47,
          createdAt: "2023-04-26T09:02:11.000Z",
          updatedAt: "2023-04-26T09:02:11.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 4115,
        familyName: "VIEIRA",
        givenName: "RONALDO",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "JWG3WUGL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2023-04-24T13:44:18.000Z",
        updatedAt: "2023-04-24T13:44:18.000Z",
        patient_group: {
          id: 120,
          id_patient: 4115,
          id_group: 47,
          createdAt: "2023-04-26T09:02:24.000Z",
          updatedAt: "2023-04-26T09:02:24.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3487,
        familyName: "VLASIC",
        givenName: "NIKOLA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "LQ7USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:58.000Z",
        updatedAt: "2022-12-15T08:07:58.000Z",
        patient_group: {
          id: 121,
          id_patient: 3487,
          id_group: 47,
          createdAt: "2023-04-26T09:02:34.000Z",
          updatedAt: "2023-04-26T09:02:34.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3488,
        familyName: "VOJVODA",
        givenName: "MERGIM",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "J18USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:59.000Z",
        updatedAt: "2022-12-15T08:07:59.000Z",
        patient_group: {
          id: 122,
          id_patient: 3488,
          id_group: 47,
          createdAt: "2023-04-26T09:02:44.000Z",
          updatedAt: "2023-04-26T09:02:44.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3489,
        familyName: "WADE",
        givenName: "SEBASTIAN",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "JC8USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:07:59.000Z",
        updatedAt: "2022-12-15T08:07:59.000Z",
        patient_group: {
          id: 123,
          id_patient: 3489,
          id_group: 47,
          createdAt: "2023-04-26T09:02:54.000Z",
          updatedAt: "2023-04-26T09:02:54.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3490,
        familyName: "WEIDMANN",
        givenName: "DUODA",
        email: "",
        password: null,
        birth_date: null,
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "WT8USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:08:00.000Z",
        updatedAt: "2022-12-15T08:08:00.000Z",
        patient_group: {
          id: 124,
          id_patient: 3490,
          id_group: 47,
          createdAt: "2023-04-26T09:03:03.000Z",
          updatedAt: "2023-04-26T09:03:03.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
      {
        id: 3492,
        familyName: "ZIMA",
        givenName: "DAVID",
        email: "",
        password: null,
        birth_date: "2000-11-08T00:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: null,
        address: null,
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: null,
        emergency_telephone: null,
        app_activation: 0,
        phone: null,
        prevalent_hand: null,
        prevalent_leg: null,
        job: null,
        weight: null,
        height: null,
        BMI: null,
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "OF9USOBL",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: null,
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-12-15T08:08:01.000Z",
        updatedAt: "2022-12-15T08:08:01.000Z",
        patient_group: {
          id: 125,
          id_patient: 3492,
          id_group: 47,
          createdAt: "2023-04-26T09:03:12.000Z",
          updatedAt: "2023-04-26T09:03:12.000Z",
        },
        groupPatient: false,
        injuries: [],
      },
    ],
  },
];

export const resultPatientMockTraining = {
  dataChart: [
    {
      groupDate: "Prova Nuovo",
      "Match absence (%)": "83.3",
      "Training attendance (%)": "16.7",
      totalDay: 6,
      totalNotAvailable: 5,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Training attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Training attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultPatientMockMatch = {
  dataChart: [
    {
      groupDate: "Prova Nuovo",
      "Match absence (%)": "75.0",
      "Match attendance (%)": "25.0",
      totalDay: 4,
      totalNotAvailable: 3,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const groupSelected = [
  {
    id: 46,
    name: "Primavera",
    patients: [
      {
        id: 101,
        familyName: "Leonardi",
        givenName: "Simone",
        email: "leonardisimonemaria@gmail.com",
        password:
          "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: "",
        address: "",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: true,
        emergency_name: "",
        emergency_telephone: "",
        app_activation: 1,
        phone: "",
        prevalent_hand: null,
        prevalent_leg: null,
        job: "",
        weight: "",
        height: "",
        BMI: null,
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
        device_os: "ios",
        device_token:
          "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
        coupon_code: "8CSGCL8L",
        silent_token: "",
        reset_password_code: "LF6KCREI",
        is_already_enabled: true,
        training_status: "",
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2023-03-14T11:24:48.000Z",
        patient_group: {
          id: 76,
          id_patient: 101,
          id_group: 46,
          createdAt: "2023-02-20T09:19:21.000Z",
          updatedAt: "2023-02-20T09:19:21.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 642,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-01-10T00:00:00.000Z",
            end_date: "2023-02-27T14:42:43.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 31,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 1,
            reinjury: null,
            createdAt: "2023-01-10T17:22:30.000Z",
            updatedAt: "2023-02-27T14:42:43.000Z",
            pathology: {
              id: 31,
              name: "Naso faringite",
              description: null,
              osiics_code: "MPZT",
              id_tissue_type: null,
              id_pathology_type: null,
              id_medical_system_type: 5,
              id_etiology: 8,
              id_dysfunction: null,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 777,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-02-27T00:00:00.000Z",
            end_date: "2023-02-27T14:44:11.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 83,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-02-27T14:43:39.000Z",
            updatedAt: "2023-02-27T14:44:11.000Z",
            pathology: {
              id: 83,
              name: "Ferita al naso che non necessita di sutura",
              description: null,
              osiics_code: "HKN",
              id_tissue_type: 3,
              id_pathology_type: 3,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 472,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 789,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: "2023-03-02T08:26:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 15,
            id_region: 22,
            id_area: 108,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:25:48.000Z",
            updatedAt: "2023-03-02T08:26:07.000Z",
            pathology: null,
            region: {
              id: 22,
              key: "cosciadx",
              name: "Regione Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 108,
              key: "cosciadx_anteriore",
              name: "Area Anteriore Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 22,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 908,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T15:28:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 12,
            id_region: 17,
            id_area: 90,
            id_structure: 1600,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:02.000Z",
            updatedAt: "2023-04-28T15:28:07.000Z",
            pathology: null,
            region: {
              id: 17,
              key: "manodx",
              name: "Regione Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 90,
              key: "manodx_dorsale",
              name: "Area Dorsale Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 17,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1600,
              name: "ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA",
              id_area: 90,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 909,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-26T00:00:00.000Z",
            end_date: "2023-04-28T15:28:59.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: null,
            id_region: 7,
            id_area: 57,
            id_structure: 1088,
            id_pathology: 635,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:53.000Z",
            updatedAt: "2023-04-28T15:28:59.000Z",
            pathology: {
              id: 635,
              name: "Stiramento muscolare al tronco",
              description: null,
              osiics_code: "OMM",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 7,
              key: "addominale",
              name: "Regione Addominale",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 57,
              key: "addominale_fiancodx",
              name: "Area Del Fianco Destro",
              latin_name: "null",
              english_name: "null",
              id_region: 7,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1088,
              name: "MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO",
              id_area: 57,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
        ],
      },
      {
        id: 97,
        familyName: "Nuovo",
        givenName: "Prova",
        email: "prova@prova.it",
        password: null,
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "M",
        family_doctor: "dott luzi",
        address: "via delle vie 1",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: "Simone",
        emergency_telephone: "3208427538",
        app_activation: 0,
        phone: "3291743333",
        prevalent_hand: "R",
        prevalent_leg: "R",
        job: "spazzino",
        weight: "53",
        height: "167",
        BMI: "19.00",
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "VK4ZXE6L",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: "Altro",
        short_name: "nic",
        middle_name: "secondo",
        birth_area: "italia",
        passport_area: "italia",
        main_role: "terzino",
        national_team: "italia",
        shirt_number: "21",
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2023-02-14T16:47:35.000Z",
        patient_group: {
          id: 87,
          id_patient: 97,
          id_group: 46,
          createdAt: "2023-04-18T13:32:57.000Z",
          updatedAt: "2023-04-18T13:32:57.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 730,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-08T00:00:00.000Z",
            end_date: "2023-02-18T14:35:33.000Z",
            estimated_end_date: "2023-02-27T00:00:00.000Z",
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 9,
            id_area: 64,
            id_structure: 229,
            id_pathology: 239,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 2,
            reinjury: 2,
            createdAt: "2023-02-08T11:18:16.000Z",
            updatedAt: "2023-04-18T14:35:33.000Z",
            pathology: {
              id: 239,
              name: "Lesione del legamento glenoomerale",
              description: null,
              osiics_code: "SDG",
              id_tissue_type: 2,
              id_pathology_type: 2,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 289,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 9,
              key: "spallasx",
              name: "Regione Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 64,
              key: "spallasx_anterolaterale",
              name: "Area Deltoidea Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 9,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 229,
              name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
              id_area: 64,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 894,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-01T00:00:00.000Z",
            end_date: "2023-04-26T15:36:17.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 8,
            id_area: 62,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:36:08.000Z",
            updatedAt: "2023-04-26T15:36:17.000Z",
            pathology: null,
            region: {
              id: 8,
              key: "spalladx",
              name: "Regione Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 62,
              key: "spalladx_anterolaterale",
              name: "Area Deltoidea Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 8,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 895,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-23T00:00:00.000Z",
            end_date: "2023-04-26T15:38:55.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 15,
            id_area: 83,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:38:45.000Z",
            updatedAt: "2023-04-26T15:38:55.000Z",
            pathology: null,
            region: {
              id: 15,
              key: "avambracciosx",
              name: "Regione Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 83,
              key: "avambracciosx_laterale",
              name: "Area Laterale Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 15,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 903,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T09:09:04.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 13,
            id_region: 20,
            id_area: 103,
            id_structure: 34,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T09:08:49.000Z",
            updatedAt: "2023-04-28T09:09:04.000Z",
            pathology: null,
            region: {
              id: 20,
              key: "ancadx",
              name: "Regione Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 103,
              key: "ancadx_anteromediale",
              name: "Area Anteromediale Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 20,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 34,
              name: "MUSCOLO ADDUTTORE BREVE DESTRO",
              id_area: 103,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 905,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-28T00:00:00.000Z",
            end_date: "2023-04-28T15:21:21.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 26,
            id_structure: 448,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:20:55.000Z",
            updatedAt: "2023-04-28T15:21:21.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 26,
              key: "collo_anterioredx",
              name: "Area Laterale Del Collo Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 448,
              name: "MUSCOLO LUNGO DEL COLLO DESTRO",
              id_area: 26,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 906,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-01T00:00:00.000Z",
            end_date: "2023-04-28T15:25:02.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 27,
            id_structure: 469,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:24:49.000Z",
            updatedAt: "2023-04-28T15:25:02.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 27,
              key: "collo_anterioresx",
              name: "Area Laterale Del Collo Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 469,
              name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
              id_area: 27,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 907,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-21T00:00:00.000Z",
            end_date: "2023-04-28T15:26:18.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 8,
            id_region: 11,
            id_area: 68,
            id_structure: 2017,
            id_pathology: 294,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:26:11.000Z",
            updatedAt: "2023-04-28T15:26:18.000Z",
            pathology: {
              id: 294,
              name: "DOMS al braccio",
              description: null,
              osiics_code: "UMD",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 11,
              key: "bracciosx",
              name: "Regione Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 68,
              key: "bracciosx_anteriore",
              name: "Area Anteriore Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 11,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 2017,
              name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
              id_area: 68,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 919,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-05-04T00:00:00.000Z",
            end_date: "2023-05-11T10:13:31.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 14,
            id_area: null,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-05-11T10:11:45.000Z",
            updatedAt: "2023-05-11T10:13:31.000Z",
            pathology: null,
            region: {
              id: 14,
              key: "avambracciodx",
              name: "Regione Dell'Avambraccio Destro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: null,
            structure: null,
          },
        ],
      },
    ],
  },
];

export const resultGroupTraining = {
  dataChart: [
    {
      groupDate: "Primavera",
      "Match absence (%)": "50.0",
      "Training attendance (%)": "50.0",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Training attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Training attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultGroupMatch = {
  dataChart: [
    {
      groupDate: "Primavera",
      "Match absence (%)": "37.5",
      "Match attendance (%)": "62.5",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const patient = {
  id: 97,
  familyName: "Nuovo",
  givenName: "Prova",
  email: "prova@prova.it",
  password: null,
  birth_date: "1995-09-17T22:00:00.000Z",
  birth_location: null,
  gender: "M",
  family_doctor: "dott luzi",
  address: "via delle vie 1",
  city: null,
  cap: null,
  province: null,
  nation: null,
  privacy: null,
  emergency_name: "Simone",
  emergency_telephone: "3208427538",
  app_activation: 0,
  phone: "3291743333",
  prevalent_hand: "R",
  prevalent_leg: "R",
  job: "spazzino",
  weight: "53",
  height: "167",
  BMI: "19.00",
  token: null,
  device_os: null,
  device_token: null,
  coupon_code: "VK4ZXE6L",
  silent_token: null,
  reset_password_code: null,
  is_already_enabled: false,
  training_status: "Altro",
  short_name: "nic",
  middle_name: "secondo",
  birth_area: "italia",
  passport_area: "italia",
  main_role: "terzino",
  national_team: "italia",
  shirt_number: "21",
  createdAt: "2022-08-04T11:15:32.000Z",
  updatedAt: "2023-02-14T16:47:35.000Z",
  patient_group: {
    id: 87,
    id_patient: 97,
    id_group: 46,
    createdAt: "2023-04-18T13:32:57.000Z",
    updatedAt: "2023-04-18T13:32:57.000Z",
  },
  groupPatient: true,
  injuries: [
    {
      id: 730,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-02-08T00:00:00.000Z",
      end_date: "2023-02-18T14:35:33.000Z",
      estimated_end_date: "2023-02-27T00:00:00.000Z",
      satisfaction: 1,
      id_exit_criteria: null,
      id_interview: 7,
      id_region: 9,
      id_area: 64,
      id_structure: 229,
      id_pathology: 239,
      type: "Infortunio",
      note: "",
      activity_during_injury: 1,
      mechanism: 2,
      reinjury: 2,
      createdAt: "2023-02-08T11:18:16.000Z",
      updatedAt: "2023-04-18T14:35:33.000Z",
      pathology: {
        id: 239,
        name: "Lesione del legamento glenoomerale",
        description: null,
        osiics_code: "SDG",
        id_tissue_type: 2,
        id_pathology_type: 2,
        id_medical_system_type: null,
        id_etiology: null,
        id_dysfunction: 289,
        createdAt: "2021-06-07T17:02:00.000Z",
        updatedAt: "2021-06-07T17:02:00.000Z",
      },
      region: {
        id: 9,
        key: "spallasx",
        name: "Regione Della Spalla Sinistra",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 64,
        key: "spallasx_anterolaterale",
        name: "Area Deltoidea Della Spalla Sinistra",
        latin_name: "null",
        english_name: "null",
        id_region: 9,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: {
        id: 229,
        name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
        id_area: 64,
        id_tissue_type: 2,
        latin_name: null,
        english_name: null,
        createdAt: "2021-09-19T13:21:00.000Z",
        updatedAt: "2021-09-19 13:21.00",
      },
    },
    {
      id: 894,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-02-01T00:00:00.000Z",
      end_date: "2023-04-26T15:36:17.000Z",
      estimated_end_date: null,
      satisfaction: 3,
      id_exit_criteria: null,
      id_interview: 7,
      id_region: 8,
      id_area: 62,
      id_structure: null,
      id_pathology: null,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-26T15:36:08.000Z",
      updatedAt: "2023-04-26T15:36:17.000Z",
      pathology: null,
      region: {
        id: 8,
        key: "spalladx",
        name: "Regione Della Spalla Destra",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 62,
        key: "spalladx_anterolaterale",
        name: "Area Deltoidea Della Spalla Destra",
        latin_name: "null",
        english_name: "null",
        id_region: 8,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: null,
    },
    {
      id: 895,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-02-23T00:00:00.000Z",
      end_date: "2023-04-26T15:38:55.000Z",
      estimated_end_date: null,
      satisfaction: 4,
      id_exit_criteria: null,
      id_interview: 10,
      id_region: 15,
      id_area: 83,
      id_structure: null,
      id_pathology: null,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-26T15:38:45.000Z",
      updatedAt: "2023-04-26T15:38:55.000Z",
      pathology: null,
      region: {
        id: 15,
        key: "avambracciosx",
        name: "Regione Dell'Avambraccio Sinistro",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 83,
        key: "avambracciosx_laterale",
        name: "Area Laterale Dell'Avambraccio Sinistro",
        latin_name: "null",
        english_name: "null",
        id_region: 15,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: null,
    },
    {
      id: 903,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-04-12T00:00:00.000Z",
      end_date: "2023-04-28T09:09:04.000Z",
      estimated_end_date: null,
      satisfaction: 4,
      id_exit_criteria: null,
      id_interview: 13,
      id_region: 20,
      id_area: 103,
      id_structure: 34,
      id_pathology: null,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-28T09:08:49.000Z",
      updatedAt: "2023-04-28T09:09:04.000Z",
      pathology: null,
      region: {
        id: 20,
        key: "ancadx",
        name: "Regione Dell'Anca Destra",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 103,
        key: "ancadx_anteromediale",
        name: "Area Anteromediale Dell'Anca Destra",
        latin_name: "null",
        english_name: "null",
        id_region: 20,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: {
        id: 34,
        name: "MUSCOLO ADDUTTORE BREVE DESTRO",
        id_area: 103,
        id_tissue_type: 9,
        latin_name: null,
        english_name: null,
        createdAt: "2021-09-19T13:21:00.000Z",
        updatedAt: "2021-09-19 13:21.00",
      },
    },
    {
      id: 905,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-04-28T00:00:00.000Z",
      end_date: "2023-04-28T15:21:21.000Z",
      estimated_end_date: null,
      satisfaction: 3,
      id_exit_criteria: null,
      id_interview: 3,
      id_region: 2,
      id_area: 26,
      id_structure: 448,
      id_pathology: 155,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-28T15:20:55.000Z",
      updatedAt: "2023-04-28T15:21:21.000Z",
      pathology: {
        id: 155,
        name: "Stiramento muscolare al collo",
        description: null,
        osiics_code: "NM1",
        id_tissue_type: 9,
        id_pathology_type: 11,
        id_medical_system_type: null,
        id_etiology: null,
        id_dysfunction: 295,
        createdAt: "2021-06-07T17:02:00.000Z",
        updatedAt: "2021-06-07T17:02:00.000Z",
      },
      region: {
        id: 2,
        key: "collo",
        name: "Regione Del Collo",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 26,
        key: "collo_anterioredx",
        name: "Area Laterale Del Collo Destra",
        latin_name: "null",
        english_name: "null",
        id_region: 2,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: {
        id: 448,
        name: "MUSCOLO LUNGO DEL COLLO DESTRO",
        id_area: 26,
        id_tissue_type: 9,
        latin_name: null,
        english_name: null,
        createdAt: "2021-09-19T13:21:00.000Z",
        updatedAt: "2021-09-19 13:21.00",
      },
    },
    {
      id: 906,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-04-01T00:00:00.000Z",
      end_date: "2023-04-28T15:25:02.000Z",
      estimated_end_date: null,
      satisfaction: 1,
      id_exit_criteria: null,
      id_interview: 3,
      id_region: 2,
      id_area: 27,
      id_structure: 469,
      id_pathology: 155,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-28T15:24:49.000Z",
      updatedAt: "2023-04-28T15:25:02.000Z",
      pathology: {
        id: 155,
        name: "Stiramento muscolare al collo",
        description: null,
        osiics_code: "NM1",
        id_tissue_type: 9,
        id_pathology_type: 11,
        id_medical_system_type: null,
        id_etiology: null,
        id_dysfunction: 295,
        createdAt: "2021-06-07T17:02:00.000Z",
        updatedAt: "2021-06-07T17:02:00.000Z",
      },
      region: {
        id: 2,
        key: "collo",
        name: "Regione Del Collo",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 27,
        key: "collo_anterioresx",
        name: "Area Laterale Del Collo Sinistra",
        latin_name: "null",
        english_name: "null",
        id_region: 2,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: {
        id: 469,
        name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
        id_area: 27,
        id_tissue_type: 9,
        latin_name: null,
        english_name: null,
        createdAt: "2021-09-19T13:21:00.000Z",
        updatedAt: "2021-09-19 13:21.00",
      },
    },
    {
      id: 907,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-04-21T00:00:00.000Z",
      end_date: "2023-04-28T15:26:18.000Z",
      estimated_end_date: null,
      satisfaction: 3,
      id_exit_criteria: null,
      id_interview: 8,
      id_region: 11,
      id_area: 68,
      id_structure: 2017,
      id_pathology: 294,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-04-28T15:26:11.000Z",
      updatedAt: "2023-04-28T15:26:18.000Z",
      pathology: {
        id: 294,
        name: "DOMS al braccio",
        description: null,
        osiics_code: "UMD",
        id_tissue_type: 9,
        id_pathology_type: 11,
        id_medical_system_type: null,
        id_etiology: null,
        id_dysfunction: 295,
        createdAt: "2021-06-07T17:02:00.000Z",
        updatedAt: "2021-06-07T17:02:00.000Z",
      },
      region: {
        id: 11,
        key: "bracciosx",
        name: "Regione Del Braccio Sinistro",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: {
        id: 68,
        key: "bracciosx_anteriore",
        name: "Area Anteriore Del Braccio Sinistro",
        latin_name: "null",
        english_name: "null",
        id_region: 11,
        createdAt: "2021-06-29T21:58:30.000Z",
        updatedAt: "2021-06-29T21:58:30.000Z",
      },
      structure: {
        id: 2017,
        name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
        id_area: 68,
        id_tissue_type: 9,
        latin_name: null,
        english_name: null,
        createdAt: "2021-09-19T13:21:00.000Z",
        updatedAt: "2021-09-19 13:21.00",
      },
    },
    {
      id: 919,
      id_patient: 97,
      id_professional: 117,
      start_date: "2023-05-04T00:00:00.000Z",
      end_date: "2023-05-11T10:13:31.000Z",
      estimated_end_date: null,
      satisfaction: 2,
      id_exit_criteria: null,
      id_interview: 10,
      id_region: 14,
      id_area: null,
      id_structure: null,
      id_pathology: null,
      type: "Infortunio",
      note: null,
      activity_during_injury: null,
      mechanism: null,
      reinjury: null,
      createdAt: "2023-05-11T10:11:45.000Z",
      updatedAt: "2023-05-11T10:13:31.000Z",
      pathology: null,
      region: {
        id: 14,
        key: "avambracciodx",
        name: "Regione Dell'Avambraccio Destro",
        latin_name: "null",
        english_name: "null",
        createdAt: null,
        updatedAt: null,
      },
      area: null,
      structure: null,
    },
  ],
};

export const dateRange = {
  start: "2022-06-01T22:00:00.000Z",
  end: "2023-05-14T22:00:00.000Z",
};

export const groupAttendance = {
  idGruppo: 46,
  nameGruppo: "Primavera",
  training: [
    {
      id: 1275,
      id_monitoring: 150,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1274,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-03-08",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 201,
      createdAt: "2023-03-08T15:36:41.000Z",
      updatedAt: "2023-03-08T15:36:59.000Z",
      activity_datum: {
        id: 1274,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-03-08T15:36:41.000Z",
        updatedAt: "2023-03-08T15:36:58.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 739,
          id_activity: 1275,
          notes: null,
          createdAt: "2023-03-08T15:36:42.000Z",
          updatedAt: "2023-03-08T15:36:42.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2438,
              id_parameter: 12,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2439,
              id_parameter: 21,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2440,
              id_parameter: 11,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2441,
              id_parameter: 52,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2446,
              id_parameter: 23,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 23,
                id_category_load: 2,
                name: "Distanza ad alta velocità",
                description: null,
                um: "metri",
                key: "distaltavel",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 150,
        id_patient: 97,
        id_professional: 117,
        starting_date: "2022-08-04 11:15:32",
        ending_date: null,
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2022-08-04T11:15:32.000Z",
        patient: {
          id: 97,
          familyName: "Nuovo",
          givenName: "Prova",
          email: "prova@prova.it",
          password: null,
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "M",
          family_doctor: "dott luzi",
          address: "via delle vie 1",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: null,
          emergency_name: "Simone",
          emergency_telephone: "3208427538",
          app_activation: 0,
          phone: "3291743333",
          prevalent_hand: "R",
          prevalent_leg: "R",
          job: "spazzino",
          weight: "53",
          height: "167",
          BMI: "19.00",
          token: null,
          device_os: null,
          device_token: null,
          coupon_code: "VK4ZXE6L",
          silent_token: null,
          reset_password_code: null,
          is_already_enabled: false,
          training_status: "injured",
          short_name: "nic",
          middle_name: "secondo",
          birth_area: "italia",
          passport_area: "italia",
          main_role: "terzino",
          national_team: "italia",
          shirt_number: "21",
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2023-05-23T14:53:21.000Z",
        },
      },
      activity_group: {
        id: 201,
        id_group: 46,
        createdAt: "2023-03-08T15:36:41.000Z",
        updatedAt: "2023-03-08T15:36:41.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2438,
              id_parameter: 12,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2439,
              id_parameter: 21,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2440,
              id_parameter: 11,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2441,
              id_parameter: 52,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2446,
              id_parameter: 23,
              answer: "10",
              id_activity_feedback: 739,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 23,
                id_category_load: 2,
                name: "Distanza ad alta velocità",
                description: null,
                um: "metri",
                key: "distaltavel",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2442,
              id_parameter: 12,
              answer: "101",
              id_activity_feedback: 740,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2443,
              id_parameter: 52,
              answer: "10",
              id_activity_feedback: 740,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2444,
              id_parameter: 11,
              answer: "10",
              id_activity_feedback: 740,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2445,
              id_parameter: 21,
              answer: "10",
              id_activity_feedback: 740,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2447,
              id_parameter: 23,
              answer: "10",
              id_activity_feedback: 740,
              date: "2023-03-08",
              createdAt: "2023-03-08T15:36:42.000Z",
              updatedAt: "2023-03-08T15:36:56.000Z",
              feedback_parameter: {
                id: 23,
                id_category_load: 2,
                name: "Distanza ad alta velocità",
                description: null,
                um: "metri",
                key: "distaltavel",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1293,
      id_monitoring: 150,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1292,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-03-31",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 204,
      createdAt: "2023-04-04T10:47:56.000Z",
      updatedAt: "2023-04-04T10:48:05.000Z",
      activity_datum: {
        id: 1292,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-04-04T10:47:56.000Z",
        updatedAt: "2023-04-04T10:48:04.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 758,
          id_activity: 1293,
          notes: null,
          createdAt: "2023-04-04T10:47:56.000Z",
          updatedAt: "2023-04-04T10:47:56.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2486,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2487,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2488,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2489,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 150,
        id_patient: 97,
        id_professional: 117,
        starting_date: "2022-08-04 11:15:32",
        ending_date: null,
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2022-08-04T11:15:32.000Z",
        patient: {
          id: 97,
          familyName: "Nuovo",
          givenName: "Prova",
          email: "prova@prova.it",
          password: null,
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "M",
          family_doctor: "dott luzi",
          address: "via delle vie 1",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: null,
          emergency_name: "Simone",
          emergency_telephone: "3208427538",
          app_activation: 0,
          phone: "3291743333",
          prevalent_hand: "R",
          prevalent_leg: "R",
          job: "spazzino",
          weight: "53",
          height: "167",
          BMI: "19.00",
          token: null,
          device_os: null,
          device_token: null,
          coupon_code: "VK4ZXE6L",
          silent_token: null,
          reset_password_code: null,
          is_already_enabled: false,
          training_status: "injured",
          short_name: "nic",
          middle_name: "secondo",
          birth_area: "italia",
          passport_area: "italia",
          main_role: "terzino",
          national_team: "italia",
          shirt_number: "21",
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2023-05-23T14:53:21.000Z",
        },
      },
      activity_group: {
        id: 204,
        id_group: 46,
        createdAt: "2023-04-04T10:47:56.000Z",
        updatedAt: "2023-04-04T10:47:56.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2486,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2487,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2488,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2489,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 758,
              date: null,
              createdAt: "2023-04-04T10:47:57.000Z",
              updatedAt: "2023-04-04T10:47:57.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1294,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1293,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-04-06",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 205,
      createdAt: "2023-04-06T08:33:53.000Z",
      updatedAt: "2023-04-18T14:59:39.000Z",
      activity_datum: {
        id: 1293,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-04-06T08:33:53.000Z",
        updatedAt: "2023-04-18T14:59:38.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 759,
          id_activity: 1294,
          notes: null,
          createdAt: "2023-04-06T08:33:53.000Z",
          updatedAt: "2023-04-06T08:33:53.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2490,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2491,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2492,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2493,
              id_parameter: 11,
              answer: "59",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 205,
        id_group: 46,
        createdAt: "2023-04-06T08:33:53.000Z",
        updatedAt: "2023-04-06T08:33:53.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2490,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2491,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2492,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2493,
              id_parameter: 11,
              answer: "59",
              id_activity_feedback: 759,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2494,
              id_parameter: 11,
              answer: "60",
              id_activity_feedback: 760,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2495,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 760,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2496,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 760,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2497,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 760,
              date: "2023-04-06",
              createdAt: "2023-04-06T08:33:53.000Z",
              updatedAt: "2023-04-18T14:59:35.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1303,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1302,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-04-06",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 208,
      createdAt: "2023-04-18T14:48:40.000Z",
      updatedAt: "2023-04-18T15:00:02.000Z",
      activity_datum: {
        id: 1302,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-04-18T14:48:40.000Z",
        updatedAt: "2023-04-18T15:00:01.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 768,
          id_activity: 1303,
          notes: null,
          createdAt: "2023-04-18T14:48:40.000Z",
          updatedAt: "2023-04-18T14:48:40.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2526,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2527,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2528,
              id_parameter: 11,
              answer: "60",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2529,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 208,
        id_group: 46,
        createdAt: "2023-04-18T14:48:39.000Z",
        updatedAt: "2023-04-18T14:48:39.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2526,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2527,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2528,
              id_parameter: 11,
              answer: "60",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2529,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 768,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2530,
              id_parameter: 11,
              answer: "57",
              id_activity_feedback: 769,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2531,
              id_parameter: 21,
              answer: "",
              id_activity_feedback: 769,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2532,
              id_parameter: 52,
              answer: "",
              id_activity_feedback: 769,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2533,
              id_parameter: 12,
              answer: "",
              id_activity_feedback: 769,
              date: "2023-04-06",
              createdAt: "2023-04-18T14:48:40.000Z",
              updatedAt: "2023-04-18T14:59:58.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1429,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1428,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-04-28",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 216,
      createdAt: "2023-04-28T07:08:05.000Z",
      updatedAt: "2023-04-28T16:35:36.000Z",
      activity_datum: {
        id: 1428,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-04-28T07:08:05.000Z",
        updatedAt: "2023-04-28T16:35:34.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 894,
          id_activity: 1429,
          notes: null,
          createdAt: "2023-04-28T07:08:05.000Z",
          updatedAt: "2023-04-28T07:08:05.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2984,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2985,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2986,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2987,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 216,
        id_group: 46,
        createdAt: "2023-04-28T07:08:05.000Z",
        updatedAt: "2023-04-28T07:08:05.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2984,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2985,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2986,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2987,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 894,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2988,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 895,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2989,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 895,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2990,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 895,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2991,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 895,
              date: null,
              createdAt: "2023-04-28T07:08:05.000Z",
              updatedAt: "2023-04-28T07:08:05.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1431,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1430,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-02",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 217,
      createdAt: "2023-05-08T13:17:07.000Z",
      updatedAt: "2023-05-08T13:17:14.000Z",
      activity_datum: {
        id: 1430,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-05-08T13:17:07.000Z",
        updatedAt: "2023-05-08T13:17:13.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 896,
          id_activity: 1431,
          notes: null,
          createdAt: "2023-05-08T13:17:07.000Z",
          updatedAt: "2023-05-08T13:17:07.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2992,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2993,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2994,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2995,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 217,
        id_group: 46,
        createdAt: "2023-05-08T13:17:07.000Z",
        updatedAt: "2023-05-08T13:17:07.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2992,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2993,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2994,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2995,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 896,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2996,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 897,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2997,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 897,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2998,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 897,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2999,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 897,
              date: null,
              createdAt: "2023-05-08T13:17:07.000Z",
              updatedAt: "2023-05-08T13:17:07.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1437,
      id_monitoring: 150,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1436,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-05",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 220,
      createdAt: "2023-05-11T10:10:19.000Z",
      updatedAt: "2023-05-11T10:10:50.000Z",
      activity_datum: {
        id: 1436,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-05-11T10:10:19.000Z",
        updatedAt: "2023-05-11T10:10:49.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 902,
          id_activity: 1437,
          notes: null,
          createdAt: "2023-05-11T10:10:19.000Z",
          updatedAt: "2023-05-11T10:10:19.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 3016,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3017,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3018,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3019,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 150,
        id_patient: 97,
        id_professional: 117,
        starting_date: "2022-08-04 11:15:32",
        ending_date: null,
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2022-08-04T11:15:32.000Z",
        patient: {
          id: 97,
          familyName: "Nuovo",
          givenName: "Prova",
          email: "prova@prova.it",
          password: null,
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "M",
          family_doctor: "dott luzi",
          address: "via delle vie 1",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: null,
          emergency_name: "Simone",
          emergency_telephone: "3208427538",
          app_activation: 0,
          phone: "3291743333",
          prevalent_hand: "R",
          prevalent_leg: "R",
          job: "spazzino",
          weight: "53",
          height: "167",
          BMI: "19.00",
          token: null,
          device_os: null,
          device_token: null,
          coupon_code: "VK4ZXE6L",
          silent_token: null,
          reset_password_code: null,
          is_already_enabled: false,
          training_status: "injured",
          short_name: "nic",
          middle_name: "secondo",
          birth_area: "italia",
          passport_area: "italia",
          main_role: "terzino",
          national_team: "italia",
          shirt_number: "21",
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2023-05-23T14:53:21.000Z",
        },
      },
      activity_group: {
        id: 220,
        id_group: 46,
        createdAt: "2023-05-11T10:10:19.000Z",
        updatedAt: "2023-05-11T10:10:19.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 3016,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3017,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3018,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3019,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 902,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 3020,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 903,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3021,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 903,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3022,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 903,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3023,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 903,
              date: null,
              createdAt: "2023-05-11T10:10:19.000Z",
              updatedAt: "2023-05-11T10:10:19.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1487,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1486,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-22",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 224,
      createdAt: "2023-05-23T13:47:53.000Z",
      updatedAt: "2023-05-23T13:47:59.000Z",
      activity_datum: {
        id: 1486,
        id_activity_type: 4,
        data: {
          training: [],
          training_motivation: "",
        },
        createdAt: "2023-05-23T13:47:53.000Z",
        updatedAt: "2023-05-23T13:47:58.000Z",
        activity_type: {
          id: 4,
          name: "Allenamento",
          key: "training",
          id_file: null,
          description: null,
          color: "#075cfe",
          createdAt: "2021-08-12T09:54:14.000Z",
          updatedAt: "2021-08-12T09:54:14.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 953,
          id_activity: 1487,
          notes: null,
          createdAt: "2023-05-23T13:47:53.000Z",
          updatedAt: "2023-05-23T13:47:53.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 3203,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3204,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3205,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3206,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 224,
        id_group: 46,
        createdAt: "2023-05-23T13:47:53.000Z",
        updatedAt: "2023-05-23T13:47:53.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 3203,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3204,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3205,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3206,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 953,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 3207,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 952,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3208,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 952,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3209,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 952,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3210,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 952,
              date: null,
              createdAt: "2023-05-23T13:47:53.000Z",
              updatedAt: "2023-05-23T13:47:53.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
  ],
  match: [
    {
      id: 1291,
      id_monitoring: 150,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1290,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-04-01",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 203,
      createdAt: "2023-04-04T10:36:45.000Z",
      updatedAt: "2023-04-04T10:47:39.000Z",
      activity_datum: {
        id: 1290,
        id_activity_type: 7,
        data: {
          race_minutes: "",
          race_motivation: "",
        },
        createdAt: "2023-04-04T10:36:45.000Z",
        updatedAt: "2023-04-04T10:47:38.000Z",
        activity_type: {
          id: 7,
          name: "Gara",
          key: "race",
          id_file: null,
          description: null,
          color: "#8941ff",
          createdAt: "2021-08-12T22:29:11.000Z",
          updatedAt: "2021-08-12T22:29:11.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 756,
          id_activity: 1291,
          notes: null,
          createdAt: "2023-04-04T10:36:45.000Z",
          updatedAt: "2023-04-04T10:36:45.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 2478,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2479,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2480,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2481,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 150,
        id_patient: 97,
        id_professional: 117,
        starting_date: "2022-08-04 11:15:32",
        ending_date: null,
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2022-08-04T11:15:32.000Z",
        patient: {
          id: 97,
          familyName: "Nuovo",
          givenName: "Prova",
          email: "prova@prova.it",
          password: null,
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "M",
          family_doctor: "dott luzi",
          address: "via delle vie 1",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: null,
          emergency_name: "Simone",
          emergency_telephone: "3208427538",
          app_activation: 0,
          phone: "3291743333",
          prevalent_hand: "R",
          prevalent_leg: "R",
          job: "spazzino",
          weight: "53",
          height: "167",
          BMI: "19.00",
          token: null,
          device_os: null,
          device_token: null,
          coupon_code: "VK4ZXE6L",
          silent_token: null,
          reset_password_code: null,
          is_already_enabled: false,
          training_status: "injured",
          short_name: "nic",
          middle_name: "secondo",
          birth_area: "italia",
          passport_area: "italia",
          main_role: "terzino",
          national_team: "italia",
          shirt_number: "21",
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2023-05-23T14:53:21.000Z",
        },
      },
      activity_group: {
        id: 203,
        id_group: 46,
        createdAt: "2023-04-04T10:36:44.000Z",
        updatedAt: "2023-04-04T10:36:44.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 2478,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2479,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2480,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2481,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 756,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
          ],
        },
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 2482,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 757,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 2483,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 757,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2484,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 757,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 2485,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 757,
              date: null,
              createdAt: "2023-04-04T10:36:45.000Z",
              updatedAt: "2023-04-04T10:36:45.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1433,
      id_monitoring: 150,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1432,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-09",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 218,
      createdAt: "2023-05-09T07:30:48.000Z",
      updatedAt: "2023-05-09T07:30:53.000Z",
      activity_datum: {
        id: 1432,
        id_activity_type: 7,
        data: {
          race_minutes: "",
          race_motivation: "",
        },
        createdAt: "2023-05-09T07:30:48.000Z",
        updatedAt: "2023-05-09T07:30:52.000Z",
        activity_type: {
          id: 7,
          name: "Gara",
          key: "race",
          id_file: null,
          description: null,
          color: "#8941ff",
          createdAt: "2021-08-12T22:29:11.000Z",
          updatedAt: "2021-08-12T22:29:11.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 898,
          id_activity: 1433,
          notes: null,
          createdAt: "2023-05-09T07:30:48.000Z",
          updatedAt: "2023-05-09T07:30:48.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 3000,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3001,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3002,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3003,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 150,
        id_patient: 97,
        id_professional: 117,
        starting_date: "2022-08-04 11:15:32",
        ending_date: null,
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2022-08-04T11:15:32.000Z",
        patient: {
          id: 97,
          familyName: "Nuovo",
          givenName: "Prova",
          email: "prova@prova.it",
          password: null,
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "M",
          family_doctor: "dott luzi",
          address: "via delle vie 1",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: null,
          emergency_name: "Simone",
          emergency_telephone: "3208427538",
          app_activation: 0,
          phone: "3291743333",
          prevalent_hand: "R",
          prevalent_leg: "R",
          job: "spazzino",
          weight: "53",
          height: "167",
          BMI: "19.00",
          token: null,
          device_os: null,
          device_token: null,
          coupon_code: "VK4ZXE6L",
          silent_token: null,
          reset_password_code: null,
          is_already_enabled: false,
          training_status: "injured",
          short_name: "nic",
          middle_name: "secondo",
          birth_area: "italia",
          passport_area: "italia",
          main_role: "terzino",
          national_team: "italia",
          shirt_number: "21",
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2023-05-23T14:53:21.000Z",
        },
      },
      activity_group: {
        id: 218,
        id_group: 46,
        createdAt: "2023-05-09T07:30:48.000Z",
        updatedAt: "2023-05-09T07:30:48.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 3000,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3001,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3002,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3003,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 898,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 3004,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 899,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3005,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 899,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3006,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 899,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3007,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 899,
              date: null,
              createdAt: "2023-05-09T07:30:48.000Z",
              updatedAt: "2023-05-09T07:30:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1435,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1434,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-08",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 219,
      createdAt: "2023-05-09T07:32:56.000Z",
      updatedAt: "2023-05-09T07:33:02.000Z",
      activity_datum: {
        id: 1434,
        id_activity_type: 7,
        data: {
          race_minutes: "",
          race_motivation: "",
        },
        createdAt: "2023-05-09T07:32:56.000Z",
        updatedAt: "2023-05-09T07:33:00.000Z",
        activity_type: {
          id: 7,
          name: "Gara",
          key: "race",
          id_file: null,
          description: null,
          color: "#8941ff",
          createdAt: "2021-08-12T22:29:11.000Z",
          updatedAt: "2021-08-12T22:29:11.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 900,
          id_activity: 1435,
          notes: null,
          createdAt: "2023-05-09T07:32:56.000Z",
          updatedAt: "2023-05-09T07:32:56.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 3008,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3009,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3010,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3011,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 219,
        id_group: 46,
        createdAt: "2023-05-09T07:32:56.000Z",
        updatedAt: "2023-05-09T07:32:56.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 3008,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3009,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3010,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3011,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 900,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 3012,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 901,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3013,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 901,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3014,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 901,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3015,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 901,
              date: null,
              createdAt: "2023-05-09T07:32:56.000Z",
              updatedAt: "2023-05-09T07:32:56.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
    {
      id: 1439,
      id_monitoring: 154,
      id_presentation: null,
      added_notes: null,
      type: null,
      id_activity_data: 1438,
      id_reference: null,
      end_date: null,
      start_time: "05:00:00",
      end_time: "22:00:00",
      event_note: "",
      start_date: "2023-05-03",
      satisfaction_date: null,
      satisfaction: null,
      id_template: null,
      fl_template: null,
      archiving_status: "completed_and_feedback_sent",
      is_from_agenda: 0,
      is_from_group_plan: true,
      id_activity_group: 221,
      createdAt: "2023-05-12T07:02:45.000Z",
      updatedAt: "2023-05-12T07:03:49.000Z",
      activity_datum: {
        id: 1438,
        id_activity_type: 7,
        data: {
          race_minutes: "",
          race_motivation: "",
        },
        createdAt: "2023-05-12T07:02:45.000Z",
        updatedAt: "2023-05-12T07:03:48.000Z",
        activity_type: {
          id: 7,
          name: "Gara",
          key: "race",
          id_file: null,
          description: null,
          color: "#8941ff",
          createdAt: "2021-08-12T22:29:11.000Z",
          updatedAt: "2021-08-12T22:29:11.000Z",
        },
      },
      activity_feedbacks: [
        {
          id: 904,
          id_activity: 1439,
          notes: null,
          createdAt: "2023-05-12T07:02:45.000Z",
          updatedAt: "2023-05-12T07:02:45.000Z",
          activity_resp_parameter_feedbacks: [
            {
              id: 3032,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3033,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3034,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3035,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
      presentation: null,
      monitoring: {
        id: 154,
        id_patient: 101,
        id_professional: 117,
        starting_date: "2022-09-28 08:07:13",
        ending_date: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2022-11-08T15:36:55.000Z",
        patient: {
          id: 101,
          familyName: "Leonardi",
          givenName: "Simone",
          email: "leonardisimonemaria@gmail.com",
          password:
            "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
          birth_date: "1995-09-17T22:00:00.000Z",
          birth_location: null,
          gender: "X",
          family_doctor: "",
          address: "",
          city: null,
          cap: null,
          province: null,
          nation: null,
          privacy: true,
          emergency_name: "",
          emergency_telephone: "",
          app_activation: 1,
          phone: "",
          prevalent_hand: null,
          prevalent_leg: null,
          job: "",
          weight: "",
          height: "",
          BMI: null,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
          device_os: "ios",
          device_token:
            "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
          coupon_code: "8CSGCL8L",
          silent_token: "",
          reset_password_code: "LF6KCREI",
          is_already_enabled: true,
          training_status: "",
          short_name: null,
          middle_name: null,
          birth_area: null,
          passport_area: null,
          main_role: null,
          national_team: null,
          shirt_number: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2023-03-14T11:24:48.000Z",
        },
      },
      activity_group: {
        id: 221,
        id_group: 46,
        createdAt: "2023-05-12T07:02:45.000Z",
        updatedAt: "2023-05-12T07:02:45.000Z",
      },
      groupName: "Primavera",
      patients: [
        {
          id: 101,
          patientName: "Simone Leonardi",
          patientFeedbacks: [
            {
              id: 3032,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3033,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3034,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3035,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 904,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
        {
          id: 97,
          patientName: "Prova Nuovo",
          patientFeedbacks: [
            {
              id: 3036,
              id_parameter: 11,
              answer: null,
              id_activity_feedback: 905,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 11,
                id_category_load: 2,
                name: "Durata",
                description: null,
                um: "min",
                key: "durata",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3037,
              id_parameter: 12,
              answer: null,
              id_activity_feedback: 905,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 12,
                id_category_load: 2,
                name: "Distanza totale",
                description: null,
                um: "metri",
                key: "disttotale",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
            {
              id: 3038,
              id_parameter: 52,
              answer: null,
              id_activity_feedback: 905,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 52,
                id_category_load: null,
                name: "Mechanical Work",
                description: null,
                um: null,
                key: "mechwork",
                createdAt: null,
                updatedAt: null,
              },
            },
            {
              id: 3039,
              id_parameter: 21,
              answer: null,
              id_activity_feedback: 905,
              date: null,
              createdAt: "2023-05-12T07:03:48.000Z",
              updatedAt: "2023-05-12T07:03:48.000Z",
              feedback_parameter: {
                id: 21,
                id_category_load: 2,
                name: "Numero decelerazioni",
                description: null,
                um: "num",
                key: "ndec",
                createdAt: "2030-08-21T13:54:00.000Z",
                updatedAt: "2030-08-21T13:54:00.000Z",
              },
            },
          ],
        },
      ],
    },
  ],
  patients: [
    {
      givenName: "Simone",
      familyName: "Leonardi",
      email: "leonardisimonemaria@gmail.com",
      id: 101,
      patient_group: {
        id: 76,
        id_patient: 101,
        id_group: 46,
        createdAt: "2023-02-20T09:19:21.000Z",
        updatedAt: "2023-02-20T09:19:21.000Z",
      },
      presentations: [],
      injuries: [
        {
          id: 642,
          id_patient: 101,
          id_professional: 117,
          start_date: "2023-01-10T00:00:00.000Z",
          end_date: "2023-02-27T14:42:43.000Z",
          estimated_end_date: null,
          satisfaction: 1,
          id_exit_criteria: null,
          id_interview: 2,
          id_region: 1,
          id_area: 18,
          id_structure: 5630,
          id_pathology: 31,
          type: "Infortunio",
          note: "",
          activity_during_injury: 1,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: 1,
          reinjury: null,
          createdAt: "2023-01-10T17:22:30.000Z",
          updatedAt: "2023-02-27T14:42:43.000Z",
          pathology: {
            id: 31,
            name: "Naso faringite",
            description: null,
            osiics_code: "MPZT",
            id_tissue_type: null,
            id_pathology_type: null,
            id_medical_system_type: 5,
            id_etiology: 8,
            id_dysfunction: null,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 1,
            key: "testa",
            name: "Regione Della Testa",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 18,
            key: "testa_naso",
            name: "Area Nasale",
            latin_name: "null",
            english_name: "null",
            id_region: 1,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 5630,
            name: "NASO",
            id_area: 18,
            id_tissue_type: null,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 777,
          id_patient: 101,
          id_professional: 117,
          start_date: "2023-02-27T00:00:00.000Z",
          end_date: "2023-02-27T14:44:11.000Z",
          estimated_end_date: null,
          satisfaction: 2,
          id_exit_criteria: null,
          id_interview: 2,
          id_region: 1,
          id_area: 18,
          id_structure: 5630,
          id_pathology: 83,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-02-27T14:43:39.000Z",
          updatedAt: "2023-02-27T14:44:11.000Z",
          pathology: {
            id: 83,
            name: "Ferita al naso che non necessita di sutura",
            description: null,
            osiics_code: "HKN",
            id_tissue_type: 3,
            id_pathology_type: 3,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 472,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 1,
            key: "testa",
            name: "Regione Della Testa",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 18,
            key: "testa_naso",
            name: "Area Nasale",
            latin_name: "null",
            english_name: "null",
            id_region: 1,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 5630,
            name: "NASO",
            id_area: 18,
            id_tissue_type: null,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 789,
          id_patient: 101,
          id_professional: 117,
          start_date: "2023-03-02T00:00:00.000Z",
          end_date: "2023-03-02T08:26:07.000Z",
          estimated_end_date: null,
          satisfaction: 0,
          id_exit_criteria: null,
          id_interview: 15,
          id_region: 22,
          id_area: 108,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-03-02T08:25:48.000Z",
          updatedAt: "2023-03-02T08:26:07.000Z",
          pathology: null,
          region: {
            id: 22,
            key: "cosciadx",
            name: "Regione Della Coscia Destra",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 108,
            key: "cosciadx_anteriore",
            name: "Area Anteriore Della Coscia Destra",
            latin_name: "null",
            english_name: "null",
            id_region: 22,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: null,
        },
        {
          id: 908,
          id_patient: 101,
          id_professional: 117,
          start_date: "2023-04-12T00:00:00.000Z",
          end_date: "2023-04-28T15:28:07.000Z",
          estimated_end_date: null,
          satisfaction: 0,
          id_exit_criteria: null,
          id_interview: 12,
          id_region: 17,
          id_area: 90,
          id_structure: 1600,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T15:28:02.000Z",
          updatedAt: "2023-04-28T15:28:07.000Z",
          pathology: null,
          region: {
            id: 17,
            key: "manodx",
            name: "Regione Della Mano Destra",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 90,
            key: "manodx_dorsale",
            name: "Area Dorsale Della Mano Destra",
            latin_name: "null",
            english_name: "null",
            id_region: 17,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 1600,
            name: "ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA",
            id_area: 90,
            id_tissue_type: 2,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 909,
          id_patient: 101,
          id_professional: 117,
          start_date: "2023-04-26T00:00:00.000Z",
          end_date: "2023-04-28T15:28:59.000Z",
          estimated_end_date: null,
          satisfaction: 1,
          id_exit_criteria: null,
          id_interview: null,
          id_region: 7,
          id_area: 57,
          id_structure: 1088,
          id_pathology: 635,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T15:28:53.000Z",
          updatedAt: "2023-04-28T15:28:59.000Z",
          pathology: {
            id: 635,
            name: "Stiramento muscolare al tronco",
            description: null,
            osiics_code: "OMM",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 7,
            key: "addominale",
            name: "Regione Addominale",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 57,
            key: "addominale_fiancodx",
            name: "Area Del Fianco Destro",
            latin_name: "null",
            english_name: "null",
            id_region: 7,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 1088,
            name: "MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO",
            id_area: 57,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
      ],
    },
    {
      givenName: "Prova",
      familyName: "Nuovo",
      email: "prova@prova.it",
      id: 97,
      patient_group: {
        id: 87,
        id_patient: 97,
        id_group: 46,
        createdAt: "2023-04-18T13:32:57.000Z",
        updatedAt: "2023-04-18T13:32:57.000Z",
      },
      presentations: [],
      injuries: [
        {
          id: 730,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-02-08T00:00:00.000Z",
          end_date: "2023-02-18T14:35:33.000Z",
          estimated_end_date: "2023-02-27T00:00:00.000Z",
          satisfaction: 1,
          id_exit_criteria: null,
          id_interview: 7,
          id_region: 9,
          id_area: 64,
          id_structure: 229,
          id_pathology: 239,
          type: "Infortunio",
          note: "",
          activity_during_injury: 1,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: 2,
          reinjury: 2,
          createdAt: "2023-02-08T11:18:16.000Z",
          updatedAt: "2023-04-18T14:35:33.000Z",
          pathology: {
            id: 239,
            name: "Lesione del legamento glenoomerale",
            description: null,
            osiics_code: "SDG",
            id_tissue_type: 2,
            id_pathology_type: 2,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 289,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 9,
            key: "spallasx",
            name: "Regione Della Spalla Sinistra",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 64,
            key: "spallasx_anterolaterale",
            name: "Area Deltoidea Della Spalla Sinistra",
            latin_name: "null",
            english_name: "null",
            id_region: 9,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 229,
            name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
            id_area: 64,
            id_tissue_type: 2,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 894,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-02-01T00:00:00.000Z",
          end_date: "2023-04-26T15:36:17.000Z",
          estimated_end_date: null,
          satisfaction: 3,
          id_exit_criteria: null,
          id_interview: 7,
          id_region: 8,
          id_area: 62,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-26T15:36:08.000Z",
          updatedAt: "2023-04-26T15:36:17.000Z",
          pathology: null,
          region: {
            id: 8,
            key: "spalladx",
            name: "Regione Della Spalla Destra",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 62,
            key: "spalladx_anterolaterale",
            name: "Area Deltoidea Della Spalla Destra",
            latin_name: "null",
            english_name: "null",
            id_region: 8,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: null,
        },
        {
          id: 895,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-02-23T00:00:00.000Z",
          end_date: "2023-04-26T15:38:55.000Z",
          estimated_end_date: null,
          satisfaction: 4,
          id_exit_criteria: null,
          id_interview: 10,
          id_region: 15,
          id_area: 83,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-26T15:38:45.000Z",
          updatedAt: "2023-04-26T15:38:55.000Z",
          pathology: null,
          region: {
            id: 15,
            key: "avambracciosx",
            name: "Regione Dell'Avambraccio Sinistro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 83,
            key: "avambracciosx_laterale",
            name: "Area Laterale Dell'Avambraccio Sinistro",
            latin_name: "null",
            english_name: "null",
            id_region: 15,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: null,
        },
        {
          id: 903,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-04-12T00:00:00.000Z",
          end_date: "2023-04-28T09:09:04.000Z",
          estimated_end_date: null,
          satisfaction: 4,
          id_exit_criteria: null,
          id_interview: 13,
          id_region: 20,
          id_area: 103,
          id_structure: 34,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T09:08:49.000Z",
          updatedAt: "2023-04-28T09:09:04.000Z",
          pathology: null,
          region: {
            id: 20,
            key: "ancadx",
            name: "Regione Dell'Anca Destra",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 103,
            key: "ancadx_anteromediale",
            name: "Area Anteromediale Dell'Anca Destra",
            latin_name: "null",
            english_name: "null",
            id_region: 20,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 34,
            name: "MUSCOLO ADDUTTORE BREVE DESTRO",
            id_area: 103,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 905,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-04-28T00:00:00.000Z",
          end_date: "2023-04-28T15:21:21.000Z",
          estimated_end_date: null,
          satisfaction: 3,
          id_exit_criteria: null,
          id_interview: 3,
          id_region: 2,
          id_area: 26,
          id_structure: 448,
          id_pathology: 155,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T15:20:55.000Z",
          updatedAt: "2023-04-28T15:21:21.000Z",
          pathology: {
            id: 155,
            name: "Stiramento muscolare al collo",
            description: null,
            osiics_code: "NM1",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 2,
            key: "collo",
            name: "Regione Del Collo",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 26,
            key: "collo_anterioredx",
            name: "Area Laterale Del Collo Destra",
            latin_name: "null",
            english_name: "null",
            id_region: 2,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 448,
            name: "MUSCOLO LUNGO DEL COLLO DESTRO",
            id_area: 26,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 906,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-04-01T00:00:00.000Z",
          end_date: "2023-04-28T15:25:02.000Z",
          estimated_end_date: null,
          satisfaction: 1,
          id_exit_criteria: null,
          id_interview: 3,
          id_region: 2,
          id_area: 27,
          id_structure: 469,
          id_pathology: 155,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T15:24:49.000Z",
          updatedAt: "2023-04-28T15:25:02.000Z",
          pathology: {
            id: 155,
            name: "Stiramento muscolare al collo",
            description: null,
            osiics_code: "NM1",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 2,
            key: "collo",
            name: "Regione Del Collo",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 27,
            key: "collo_anterioresx",
            name: "Area Laterale Del Collo Sinistra",
            latin_name: "null",
            english_name: "null",
            id_region: 2,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 469,
            name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
            id_area: 27,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 907,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-04-21T00:00:00.000Z",
          end_date: "2023-04-28T15:26:18.000Z",
          estimated_end_date: null,
          satisfaction: 3,
          id_exit_criteria: null,
          id_interview: 8,
          id_region: 11,
          id_area: 68,
          id_structure: 2017,
          id_pathology: 294,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-04-28T15:26:11.000Z",
          updatedAt: "2023-04-28T15:26:18.000Z",
          pathology: {
            id: 294,
            name: "DOMS al braccio",
            description: null,
            osiics_code: "UMD",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 11,
            key: "bracciosx",
            name: "Regione Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 68,
            key: "bracciosx_anteriore",
            name: "Area Anteriore Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            id_region: 11,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 2017,
            name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
            id_area: 68,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 919,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-04T00:00:00.000Z",
          end_date: "2023-05-11T10:13:31.000Z",
          estimated_end_date: null,
          satisfaction: 2,
          id_exit_criteria: null,
          id_interview: 10,
          id_region: 14,
          id_area: null,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-05-11T10:11:45.000Z",
          updatedAt: "2023-05-11T10:13:31.000Z",
          pathology: null,
          region: {
            id: 14,
            key: "avambracciodx",
            name: "Regione Dell'Avambraccio Destro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: null,
          structure: null,
        },
        {
          id: 934,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-15T00:00:00.000Z",
          end_date: "2023-05-17T09:44:47.000Z",
          estimated_end_date: null,
          satisfaction: 3,
          id_exit_criteria: null,
          id_interview: 1,
          id_region: 1,
          id_area: 6,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: "",
          activity_during_injury: null,
          specific_action: 7,
          onset: 1,
          time_loss: 2,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-05-15T16:41:46.000Z",
          updatedAt: "2023-05-17T09:44:47.000Z",
          pathology: null,
          region: {
            id: 1,
            key: "testa",
            name: "Regione Della Testa",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 6,
            key: "testa_temporoparietalesx",
            name: "Area Temporoparietale Sinistra",
            latin_name: "null",
            english_name: "null",
            id_region: 1,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: null,
        },
        {
          id: 939,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-17T00:00:00.000Z",
          end_date: "2023-05-17T10:01:27.000Z",
          estimated_end_date: "2023-08-16T00:00:00.000Z",
          satisfaction: 2,
          id_exit_criteria: null,
          id_interview: 8,
          id_region: 11,
          id_area: 68,
          id_structure: 2017,
          id_pathology: 291,
          type: "Infortunio",
          note: "Note",
          activity_during_injury: 3,
          specific_action: 8,
          onset: 3,
          time_loss: 1,
          activity: null,
          mechanism: 3,
          reinjury: 2,
          createdAt: "2023-05-17T09:56:53.000Z",
          updatedAt: "2023-05-17T10:01:27.000Z",
          pathology: {
            id: 291,
            name: "Lesione muscolare del bicipite brachiale",
            description: null,
            osiics_code: "UM1",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 11,
            key: "bracciosx",
            name: "Regione Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 68,
            key: "bracciosx_anteriore",
            name: "Area Anteriore Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            id_region: 11,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 2017,
            name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
            id_area: 68,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 940,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-17T00:00:00.000Z",
          end_date: "2023-05-17T15:10:30.000Z",
          estimated_end_date: null,
          satisfaction: 3,
          id_exit_criteria: null,
          id_interview: null,
          id_region: 7,
          id_area: 60,
          id_structure: 1402,
          id_pathology: 625,
          type: "Infortunio",
          note: "Nuove note",
          activity_during_injury: 2,
          specific_action: 6,
          onset: 3,
          time_loss: 2,
          activity: 1444,
          mechanism: 1,
          reinjury: 2,
          createdAt: "2023-05-17T12:49:26.000Z",
          updatedAt: "2023-05-17T15:10:30.000Z",
          pathology: {
            id: 625,
            name: "Lesione muscolare/spasmo/punto trigger del tronco",
            description: null,
            osiics_code: "OMT",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 7,
            key: "addominale",
            name: "Regione Addominale",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 60,
            key: "addominale_ipogastrica",
            name: "Area Ipogastrica",
            latin_name: "null",
            english_name: "null",
            id_region: 7,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 1402,
            name: "MUSCOLO PIRAMIDALE DELL'ADDOME DESTRO",
            id_area: 60,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 942,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-17T00:00:00.000Z",
          end_date: "2023-05-19T07:42:23.000Z",
          estimated_end_date: null,
          satisfaction: 2,
          id_exit_criteria: null,
          id_interview: 8,
          id_region: 11,
          id_area: 69,
          id_structure: 1176,
          id_pathology: 292,
          type: "Infortunio",
          note: "",
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-05-17T16:29:03.000Z",
          updatedAt: "2023-05-19T07:42:23.000Z",
          pathology: {
            id: 292,
            name: "Lesione muscolare del tricipite brachiale",
            description: null,
            osiics_code: "UMT",
            id_tissue_type: 9,
            id_pathology_type: 11,
            id_medical_system_type: null,
            id_etiology: null,
            id_dysfunction: 295,
            createdAt: "2021-06-07T17:02:00.000Z",
            updatedAt: "2021-06-07T17:02:00.000Z",
          },
          region: {
            id: 11,
            key: "bracciosx",
            name: "Regione Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 69,
            key: "bracciosx_posteriore",
            name: "Area Posteriore Del Braccio Sinistro",
            latin_name: "null",
            english_name: "null",
            id_region: 11,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 1176,
            name: "MUSCOLO TRICIPITE BRACHIALE SINISTRO",
            id_area: 69,
            id_tissue_type: 9,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 968,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-19T00:00:00.000Z",
          end_date: "2023-05-23T07:41:33.000Z",
          estimated_end_date: null,
          satisfaction: 1,
          id_exit_criteria: null,
          id_interview: 10,
          id_region: 15,
          id_area: 83,
          id_structure: 1453,
          id_pathology: null,
          type: "Infortunio",
          note: "",
          activity_during_injury: 2,
          specific_action: 7,
          onset: 2,
          time_loss: 1,
          activity: null,
          mechanism: 4,
          reinjury: 3,
          createdAt: "2023-05-19T10:30:41.000Z",
          updatedAt: "2023-05-23T07:41:33.000Z",
          pathology: null,
          region: {
            id: 15,
            key: "avambracciosx",
            name: "Regione Dell'Avambraccio Sinistro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 83,
            key: "avambracciosx_laterale",
            name: "Area Laterale Dell'Avambraccio Sinistro",
            latin_name: "null",
            english_name: "null",
            id_region: 15,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: {
            id: 1453,
            name: "CUTE DELL'AREA LATERALE DELL'AVAMBRACCIO SINISTRO",
            id_area: 83,
            id_tissue_type: 3,
            latin_name: null,
            english_name: null,
            createdAt: "2021-09-19T13:21:00.000Z",
            updatedAt: "2021-09-19 13:21.00",
          },
        },
        {
          id: 969,
          id_patient: 97,
          id_professional: 117,
          start_date: "2023-05-21T00:00:00.000Z",
          end_date: "2023-05-23T13:46:01.000Z",
          estimated_end_date: null,
          satisfaction: 2,
          id_exit_criteria: null,
          id_interview: 10,
          id_region: 14,
          id_area: 78,
          id_structure: null,
          id_pathology: null,
          type: "Infortunio",
          note: null,
          activity_during_injury: null,
          specific_action: null,
          onset: null,
          time_loss: null,
          activity: null,
          mechanism: null,
          reinjury: null,
          createdAt: "2023-05-23T13:45:53.000Z",
          updatedAt: "2023-05-23T13:46:01.000Z",
          pathology: null,
          region: {
            id: 14,
            key: "avambracciodx",
            name: "Regione Dell'Avambraccio Destro",
            latin_name: "null",
            english_name: "null",
            createdAt: null,
            updatedAt: null,
          },
          area: {
            id: 78,
            key: "avambracciodx_anteriore",
            name: "Area Anteriore Dell'Avambraccio Destro",
            latin_name: "null",
            english_name: "null",
            id_region: 14,
            createdAt: "2021-06-29T21:58:30.000Z",
            updatedAt: "2021-06-29T21:58:30.000Z",
          },
          structure: null,
        },
      ],
    },
  ],
};
export const groupAttendanceByMonthResultMatch = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "April",
      "Match absence (%)": 50,
      "Match attendance (%)": 50,
      totalDay: 1,
      totalNotAvailable: 0.5,
    },
    {
      groupDate: "May",
      "Match absence (%)": 66.7,
      "Match attendance (%)": 33.4,
      totalDay: 3,
      totalNotAvailable: 1,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Match attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};
export const groupAttendanceByMonthResultTraining = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": 50,
      "Training attendance (%)": 50,
      totalDay: 2,
      totalNotAvailable: 1,
    },
    {
      groupDate: "April",
      "Match absence (%)": 25,
      "Training attendance (%)": 75,
      totalDay: 2,
      totalNotAvailable: 1.5,
    },
    {
      groupDate: "May",
      "Match absence (%)": 66.7,
      "Training attendance (%)": 33.4,
      totalDay: 3,
      totalNotAvailable: 1,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Training attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Training attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};
export const patientAttendanceByMonthResultTraining = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": "100.0",
      "Training attendance (%)": "0.0",
      totalDay: 2,
      totalNotAvailable: 2,
    },
    {
      groupDate: "April",
      "Match absence (%)": "100.0",
      "Training attendance (%)": "0.0",
      totalDay: 2,
      totalNotAvailable: 2,
    },
    {
      groupDate: "May",
      "Match absence (%)": "50.0",
      "Training attendance (%)": "50.0",
      totalDay: 2,
      totalNotAvailable: 1,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Training attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Training attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const patientAttendanceByMonthResultMatch = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "April",
      "Match absence (%)": "100.0",
      "Match attendance (%)": "0.0",
      totalDay: 1,
      totalNotAvailable: 1,
    },
    {
      groupDate: "May",
      "Match absence (%)": "66.7",
      "Match attendance (%)": "33.3",
      totalDay: 3,
      totalNotAvailable: 2,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Match attendance (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match attendance (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultPatientsActionMissingTraining = {
  dataChart: [
    {
      groupDate: "Prova Nuovo",
      "Match absence (%)": "83.3",
      "Training attendance (%)": "16.7",
      totalDay: 6,
      totalNotAvailable: 5,
      "Number of training sessions missed per month": "0.5",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Number of training sessions missed per month",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Number of training sessions missed per month",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultPatientsActionMissingMatch = {
  dataChart: [
    {
      groupDate: "Prova Nuovo",
      "Match absence (%)": "75.0",
      "Match attendance (%)": "25.0",
      totalDay: 4,
      totalNotAvailable: 3,
      "Number of match sessions missed per month": "0.3",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Number of match sessions missed per month",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Number of match sessions missed per month",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultGroupsActionMissingTraining = {
  dataChart: [
    {
      groupDate: "Primavera",
      "Number of training sessions missed per month": "0.3",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Number of training sessions missed per month",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Number of training sessions missed per month",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const resultGroupsActionMatch = {
  dataChart: [
    {
      groupDate: "Primavera",
      "Number of match sessions missed per month": "0.1",
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 41.666666666666664,
        dataKey: "Number of match sessions missed per month",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Number of match sessions missed per month",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const groupsSelected = [
  {
    idGruppo: 46,
    nameGruppo: "Primavera",
    training: [
      {
        id: 1275,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1274,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-03-08",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 201,
        createdAt: "2023-03-08T15:36:41.000Z",
        updatedAt: "2023-03-08T15:36:59.000Z",
        activity_datum: {
          id: 1274,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-03-08T15:36:41.000Z",
          updatedAt: "2023-03-08T15:36:58.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 739,
            id_activity: 1275,
            notes: null,
            createdAt: "2023-03-08T15:36:42.000Z",
            updatedAt: "2023-03-08T15:36:42.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2438,
                id_parameter: 12,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2439,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2440,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2441,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2446,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 201,
          id_group: 46,
          createdAt: "2023-03-08T15:36:41.000Z",
          updatedAt: "2023-03-08T15:36:41.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2438,
                id_parameter: 12,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2439,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2440,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2441,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2446,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 739,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2442,
                id_parameter: 12,
                answer: "101",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2443,
                id_parameter: 52,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2444,
                id_parameter: 11,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2445,
                id_parameter: 21,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2447,
                id_parameter: 23,
                answer: "10",
                id_activity_feedback: 740,
                date: "2023-03-08",
                createdAt: "2023-03-08T15:36:42.000Z",
                updatedAt: "2023-03-08T15:36:56.000Z",
                feedback_parameter: {
                  id: 23,
                  id_category_load: 2,
                  name: "Distanza ad alta velocità",
                  description: null,
                  um: "metri",
                  key: "distaltavel",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1293,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1292,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-03-31",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 204,
        createdAt: "2023-04-04T10:47:56.000Z",
        updatedAt: "2023-04-04T10:48:05.000Z",
        activity_datum: {
          id: 1292,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-04T10:47:56.000Z",
          updatedAt: "2023-04-04T10:48:04.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 758,
            id_activity: 1293,
            notes: null,
            createdAt: "2023-04-04T10:47:56.000Z",
            updatedAt: "2023-04-04T10:47:56.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2486,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2487,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2488,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2489,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 204,
          id_group: 46,
          createdAt: "2023-04-04T10:47:56.000Z",
          updatedAt: "2023-04-04T10:47:56.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2486,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2487,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2488,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2489,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 758,
                date: null,
                createdAt: "2023-04-04T10:47:57.000Z",
                updatedAt: "2023-04-04T10:47:57.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1294,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1293,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-06",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 205,
        createdAt: "2023-04-06T08:33:53.000Z",
        updatedAt: "2023-04-18T14:59:39.000Z",
        activity_datum: {
          id: 1293,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-06T08:33:53.000Z",
          updatedAt: "2023-04-18T14:59:38.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 759,
            id_activity: 1294,
            notes: null,
            createdAt: "2023-04-06T08:33:53.000Z",
            updatedAt: "2023-04-06T08:33:53.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2490,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2491,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2492,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2493,
                id_parameter: 11,
                answer: "59",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 205,
          id_group: 46,
          createdAt: "2023-04-06T08:33:53.000Z",
          updatedAt: "2023-04-06T08:33:53.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2490,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2491,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2492,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2493,
                id_parameter: 11,
                answer: "59",
                id_activity_feedback: 759,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2494,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2495,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2496,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2497,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 760,
                date: "2023-04-06",
                createdAt: "2023-04-06T08:33:53.000Z",
                updatedAt: "2023-04-18T14:59:35.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1303,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1302,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-06",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 208,
        createdAt: "2023-04-18T14:48:40.000Z",
        updatedAt: "2023-04-18T15:00:02.000Z",
        activity_datum: {
          id: 1302,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-18T14:48:40.000Z",
          updatedAt: "2023-04-18T15:00:01.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 768,
            id_activity: 1303,
            notes: null,
            createdAt: "2023-04-18T14:48:40.000Z",
            updatedAt: "2023-04-18T14:48:40.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2526,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2527,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2528,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2529,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 208,
          id_group: 46,
          createdAt: "2023-04-18T14:48:39.000Z",
          updatedAt: "2023-04-18T14:48:39.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2526,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2527,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2528,
                id_parameter: 11,
                answer: "60",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2529,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 768,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2530,
                id_parameter: 11,
                answer: "57",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2531,
                id_parameter: 21,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2532,
                id_parameter: 52,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2533,
                id_parameter: 12,
                answer: "",
                id_activity_feedback: 769,
                date: "2023-04-06",
                createdAt: "2023-04-18T14:48:40.000Z",
                updatedAt: "2023-04-18T14:59:58.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1429,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1428,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-28",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 216,
        createdAt: "2023-04-28T07:08:05.000Z",
        updatedAt: "2023-04-28T16:35:36.000Z",
        activity_datum: {
          id: 1428,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-04-28T07:08:05.000Z",
          updatedAt: "2023-04-28T16:35:34.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 894,
            id_activity: 1429,
            notes: null,
            createdAt: "2023-04-28T07:08:05.000Z",
            updatedAt: "2023-04-28T07:08:05.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2984,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2985,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2986,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2987,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 216,
          id_group: 46,
          createdAt: "2023-04-28T07:08:05.000Z",
          updatedAt: "2023-04-28T07:08:05.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2984,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2985,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2986,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2987,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 894,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2988,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2989,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2990,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2991,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 895,
                date: null,
                createdAt: "2023-04-28T07:08:05.000Z",
                updatedAt: "2023-04-28T07:08:05.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1431,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1430,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-02",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 217,
        createdAt: "2023-05-08T13:17:07.000Z",
        updatedAt: "2023-05-08T13:17:14.000Z",
        activity_datum: {
          id: 1430,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-05-08T13:17:07.000Z",
          updatedAt: "2023-05-08T13:17:13.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 896,
            id_activity: 1431,
            notes: null,
            createdAt: "2023-05-08T13:17:07.000Z",
            updatedAt: "2023-05-08T13:17:07.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2992,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2993,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2994,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2995,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 217,
          id_group: 46,
          createdAt: "2023-05-08T13:17:07.000Z",
          updatedAt: "2023-05-08T13:17:07.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2992,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2993,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2994,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2995,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 896,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2996,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2997,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2998,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2999,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 897,
                date: null,
                createdAt: "2023-05-08T13:17:07.000Z",
                updatedAt: "2023-05-08T13:17:07.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1437,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1436,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-05",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 220,
        createdAt: "2023-05-11T10:10:19.000Z",
        updatedAt: "2023-05-11T10:10:50.000Z",
        activity_datum: {
          id: 1436,
          id_activity_type: 4,
          data: {
            training: [],
            training_motivation: "",
          },
          createdAt: "2023-05-11T10:10:19.000Z",
          updatedAt: "2023-05-11T10:10:49.000Z",
          activity_type: {
            id: 4,
            name: "Allenamento",
            key: "training",
            id_file: null,
            description: null,
            color: "#075cfe",
            createdAt: "2021-08-12T09:54:14.000Z",
            updatedAt: "2021-08-12T09:54:14.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 902,
            id_activity: 1437,
            notes: null,
            createdAt: "2023-05-11T10:10:19.000Z",
            updatedAt: "2023-05-11T10:10:19.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3016,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3017,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3018,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3019,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 220,
          id_group: 46,
          createdAt: "2023-05-11T10:10:19.000Z",
          updatedAt: "2023-05-11T10:10:19.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3016,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3017,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3018,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3019,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 902,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3020,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3021,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3022,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3023,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 903,
                date: null,
                createdAt: "2023-05-11T10:10:19.000Z",
                updatedAt: "2023-05-11T10:10:19.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    match: [
      {
        id: 1291,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1290,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-04-01",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 203,
        createdAt: "2023-04-04T10:36:45.000Z",
        updatedAt: "2023-04-04T10:47:39.000Z",
        activity_datum: {
          id: 1290,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-04-04T10:36:45.000Z",
          updatedAt: "2023-04-04T10:47:38.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 756,
            id_activity: 1291,
            notes: null,
            createdAt: "2023-04-04T10:36:45.000Z",
            updatedAt: "2023-04-04T10:36:45.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 2478,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2479,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2480,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2481,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 203,
          id_group: 46,
          createdAt: "2023-04-04T10:36:44.000Z",
          updatedAt: "2023-04-04T10:36:44.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 2478,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2479,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2480,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2481,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 756,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 2482,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 2483,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2484,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 2485,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 757,
                date: null,
                createdAt: "2023-04-04T10:36:45.000Z",
                updatedAt: "2023-04-04T10:36:45.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1433,
        id_monitoring: 150,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1432,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-09",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 218,
        createdAt: "2023-05-09T07:30:48.000Z",
        updatedAt: "2023-05-09T07:30:53.000Z",
        activity_datum: {
          id: 1432,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-09T07:30:48.000Z",
          updatedAt: "2023-05-09T07:30:52.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 898,
            id_activity: 1433,
            notes: null,
            createdAt: "2023-05-09T07:30:48.000Z",
            updatedAt: "2023-05-09T07:30:48.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3000,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3001,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3002,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3003,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 150,
          id_patient: 97,
          id_professional: 117,
          starting_date: "2022-08-04 11:15:32",
          ending_date: null,
          createdAt: "2022-08-04T11:15:32.000Z",
          updatedAt: "2022-08-04T11:15:32.000Z",
          patient: {
            id: 97,
            familyName: "Nuovo",
            givenName: "Prova",
            email: "prova@prova.it",
            password: null,
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "M",
            family_doctor: "dott luzi",
            address: "via delle vie 1",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: null,
            emergency_name: "Simone",
            emergency_telephone: "3208427538",
            app_activation: 0,
            phone: "3291743333",
            prevalent_hand: "R",
            prevalent_leg: "R",
            job: "spazzino",
            weight: "53",
            height: "167",
            BMI: "19.00",
            token: null,
            device_os: null,
            device_token: null,
            coupon_code: "VK4ZXE6L",
            silent_token: null,
            reset_password_code: null,
            is_already_enabled: false,
            training_status: "Altro",
            short_name: "nic",
            middle_name: "secondo",
            birth_area: "italia",
            passport_area: "italia",
            main_role: "terzino",
            national_team: "italia",
            shirt_number: "21",
            createdAt: "2022-08-04T11:15:32.000Z",
            updatedAt: "2023-02-14T16:47:35.000Z",
          },
        },
        activity_group: {
          id: 218,
          id_group: 46,
          createdAt: "2023-05-09T07:30:48.000Z",
          updatedAt: "2023-05-09T07:30:48.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3000,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3001,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3002,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3003,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 898,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3004,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3005,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3006,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3007,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 899,
                date: null,
                createdAt: "2023-05-09T07:30:48.000Z",
                updatedAt: "2023-05-09T07:30:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1435,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1434,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-08",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 219,
        createdAt: "2023-05-09T07:32:56.000Z",
        updatedAt: "2023-05-09T07:33:02.000Z",
        activity_datum: {
          id: 1434,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-09T07:32:56.000Z",
          updatedAt: "2023-05-09T07:33:00.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 900,
            id_activity: 1435,
            notes: null,
            createdAt: "2023-05-09T07:32:56.000Z",
            updatedAt: "2023-05-09T07:32:56.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3008,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3009,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3010,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3011,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 219,
          id_group: 46,
          createdAt: "2023-05-09T07:32:56.000Z",
          updatedAt: "2023-05-09T07:32:56.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3008,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3009,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3010,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3011,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 900,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3012,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3013,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3014,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3015,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 901,
                date: null,
                createdAt: "2023-05-09T07:32:56.000Z",
                updatedAt: "2023-05-09T07:32:56.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
      {
        id: 1439,
        id_monitoring: 154,
        id_presentation: null,
        added_notes: null,
        type: null,
        id_activity_data: 1438,
        id_reference: null,
        end_date: null,
        start_time: "05:00:00",
        end_time: "22:00:00",
        event_note: "",
        start_date: "2023-05-03",
        satisfaction_date: null,
        satisfaction: null,
        id_template: null,
        fl_template: null,
        archiving_status: "completed_and_feedback_sent",
        is_from_agenda: 0,
        is_from_group_plan: true,
        id_activity_group: 221,
        createdAt: "2023-05-12T07:02:45.000Z",
        updatedAt: "2023-05-12T07:03:49.000Z",
        activity_datum: {
          id: 1438,
          id_activity_type: 7,
          data: {
            race_minutes: "",
            race_motivation: "",
          },
          createdAt: "2023-05-12T07:02:45.000Z",
          updatedAt: "2023-05-12T07:03:48.000Z",
          activity_type: {
            id: 7,
            name: "Gara",
            key: "race",
            id_file: null,
            description: null,
            color: "#8941ff",
            createdAt: "2021-08-12T22:29:11.000Z",
            updatedAt: "2021-08-12T22:29:11.000Z",
          },
        },
        activity_feedbacks: [
          {
            id: 904,
            id_activity: 1439,
            notes: null,
            createdAt: "2023-05-12T07:02:45.000Z",
            updatedAt: "2023-05-12T07:02:45.000Z",
            activity_resp_parameter_feedbacks: [
              {
                id: 3032,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3033,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3034,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3035,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
        presentation: null,
        monitoring: {
          id: 154,
          id_patient: 101,
          id_professional: 117,
          starting_date: "2022-09-28 08:07:13",
          ending_date: null,
          createdAt: "2022-09-28T08:07:13.000Z",
          updatedAt: "2022-11-08T15:36:55.000Z",
          patient: {
            id: 101,
            familyName: "Leonardi",
            givenName: "Simone",
            email: "leonardisimonemaria@gmail.com",
            password:
              "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
            birth_date: "1995-09-17T22:00:00.000Z",
            birth_location: null,
            gender: "X",
            family_doctor: "",
            address: "",
            city: null,
            cap: null,
            province: null,
            nation: null,
            privacy: true,
            emergency_name: "",
            emergency_telephone: "",
            app_activation: 1,
            phone: "",
            prevalent_hand: null,
            prevalent_leg: null,
            job: "",
            weight: "",
            height: "",
            BMI: null,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
            device_os: "ios",
            device_token:
              "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
            coupon_code: "8CSGCL8L",
            silent_token: "",
            reset_password_code: "LF6KCREI",
            is_already_enabled: true,
            training_status: "",
            short_name: null,
            middle_name: null,
            birth_area: null,
            passport_area: null,
            main_role: null,
            national_team: null,
            shirt_number: null,
            createdAt: "2022-09-28T08:07:13.000Z",
            updatedAt: "2023-03-14T11:24:48.000Z",
          },
        },
        activity_group: {
          id: 221,
          id_group: 46,
          createdAt: "2023-05-12T07:02:45.000Z",
          updatedAt: "2023-05-12T07:02:45.000Z",
        },
        groupName: "Primavera",
        patients: [
          {
            id: 101,
            patientName: "Simone Leonardi",
            patientFeedbacks: [
              {
                id: 3032,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3033,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3034,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3035,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 904,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
          {
            id: 97,
            patientName: "Prova Nuovo",
            patientFeedbacks: [
              {
                id: 3036,
                id_parameter: 11,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 11,
                  id_category_load: 2,
                  name: "Durata",
                  description: null,
                  um: "min",
                  key: "durata",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3037,
                id_parameter: 12,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 12,
                  id_category_load: 2,
                  name: "Distanza totale",
                  description: null,
                  um: "metri",
                  key: "disttotale",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
              {
                id: 3038,
                id_parameter: 52,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 52,
                  id_category_load: null,
                  name: "Mechanical Work",
                  description: null,
                  um: null,
                  key: "mechwork",
                  createdAt: null,
                  updatedAt: null,
                },
              },
              {
                id: 3039,
                id_parameter: 21,
                answer: null,
                id_activity_feedback: 905,
                date: null,
                createdAt: "2023-05-12T07:03:48.000Z",
                updatedAt: "2023-05-12T07:03:48.000Z",
                feedback_parameter: {
                  id: 21,
                  id_category_load: 2,
                  name: "Numero decelerazioni",
                  description: null,
                  um: "num",
                  key: "ndec",
                  createdAt: "2030-08-21T13:54:00.000Z",
                  updatedAt: "2030-08-21T13:54:00.000Z",
                },
              },
            ],
          },
        ],
      },
    ],
    patients: [
      {
        id: 101,
        familyName: "Leonardi",
        givenName: "Simone",
        email: "leonardisimonemaria@gmail.com",
        password:
          "$2a$12$IqBtg9rMhhuHnwRptDbX4OHw3ELLb4rK0hc8k873m5WJp8rsf/CNi",
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "X",
        family_doctor: "",
        address: "",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: true,
        emergency_name: "",
        emergency_telephone: "",
        app_activation: 1,
        phone: "",
        prevalent_hand: null,
        prevalent_leg: null,
        job: "",
        weight: "",
        height: "",
        BMI: null,
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJleHAiOjE2Nzg4NjkxOTksImlhdCI6MTY3ODY5NjM5OX0.zJi4Ynl67e9JyZhWMaA_PMGgoAZlAaO2qE4vZiwPkrw",
        device_os: "ios",
        device_token:
          "epzjnUpVD0lFjhItfmyV-j:APA91bGnD1VAkBGqM2fgWKb_5_RaEP3cFCTSL1CsRhVQcdmfwcTCGzVxM7gC0CgmMVM3Jn0iP5J7hG9FXa312Ltl9X7VqhwETbrEuerN_AVkr8iRIqwfI7H1tPWdgkP4-fPT_7XDMxMk",
        coupon_code: "8CSGCL8L",
        silent_token: "",
        reset_password_code: "LF6KCREI",
        is_already_enabled: true,
        training_status: "",
        short_name: null,
        middle_name: null,
        birth_area: null,
        passport_area: null,
        main_role: null,
        national_team: null,
        shirt_number: null,
        createdAt: "2022-09-28T08:07:13.000Z",
        updatedAt: "2023-03-14T11:24:48.000Z",
        patient_group: {
          id: 76,
          id_patient: 101,
          id_group: 46,
          createdAt: "2023-02-20T09:19:21.000Z",
          updatedAt: "2023-02-20T09:19:21.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 642,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-01-10T00:00:00.000Z",
            end_date: "2023-02-27T14:42:43.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 31,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 1,
            reinjury: null,
            createdAt: "2023-01-10T17:22:30.000Z",
            updatedAt: "2023-02-27T14:42:43.000Z",
            pathology: {
              id: 31,
              name: "Naso faringite",
              description: null,
              osiics_code: "MPZT",
              id_tissue_type: null,
              id_pathology_type: null,
              id_medical_system_type: 5,
              id_etiology: 8,
              id_dysfunction: null,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 777,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-02-27T00:00:00.000Z",
            end_date: "2023-02-27T14:44:11.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 2,
            id_region: 1,
            id_area: 18,
            id_structure: 5630,
            id_pathology: 83,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-02-27T14:43:39.000Z",
            updatedAt: "2023-02-27T14:44:11.000Z",
            pathology: {
              id: 83,
              name: "Ferita al naso che non necessita di sutura",
              description: null,
              osiics_code: "HKN",
              id_tissue_type: 3,
              id_pathology_type: 3,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 472,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 1,
              key: "testa",
              name: "Regione Della Testa",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 18,
              key: "testa_naso",
              name: "Area Nasale",
              latin_name: "null",
              english_name: "null",
              id_region: 1,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 5630,
              name: "NASO",
              id_area: 18,
              id_tissue_type: null,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 789,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-03-02T00:00:00.000Z",
            end_date: "2023-03-02T08:26:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 15,
            id_region: 22,
            id_area: 108,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-03-02T08:25:48.000Z",
            updatedAt: "2023-03-02T08:26:07.000Z",
            pathology: null,
            region: {
              id: 22,
              key: "cosciadx",
              name: "Regione Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 108,
              key: "cosciadx_anteriore",
              name: "Area Anteriore Della Coscia Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 22,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 908,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T15:28:07.000Z",
            estimated_end_date: null,
            satisfaction: 0,
            id_exit_criteria: null,
            id_interview: 12,
            id_region: 17,
            id_area: 90,
            id_structure: 1600,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:02.000Z",
            updatedAt: "2023-04-28T15:28:07.000Z",
            pathology: null,
            region: {
              id: 17,
              key: "manodx",
              name: "Regione Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 90,
              key: "manodx_dorsale",
              name: "Area Dorsale Della Mano Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 17,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1600,
              name: "ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA",
              id_area: 90,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 909,
            id_patient: 101,
            id_professional: 117,
            start_date: "2023-04-26T00:00:00.000Z",
            end_date: "2023-04-28T15:28:59.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: null,
            id_region: 7,
            id_area: 57,
            id_structure: 1088,
            id_pathology: 635,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:28:53.000Z",
            updatedAt: "2023-04-28T15:28:59.000Z",
            pathology: {
              id: 635,
              name: "Stiramento muscolare al tronco",
              description: null,
              osiics_code: "OMM",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 7,
              key: "addominale",
              name: "Regione Addominale",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 57,
              key: "addominale_fiancodx",
              name: "Area Del Fianco Destro",
              latin_name: "null",
              english_name: "null",
              id_region: 7,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 1088,
              name: "MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO",
              id_area: 57,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
        ],
      },
      {
        id: 97,
        familyName: "Nuovo",
        givenName: "Prova",
        email: "prova@prova.it",
        password: null,
        birth_date: "1995-09-17T22:00:00.000Z",
        birth_location: null,
        gender: "M",
        family_doctor: "dott luzi",
        address: "via delle vie 1",
        city: null,
        cap: null,
        province: null,
        nation: null,
        privacy: null,
        emergency_name: "Simone",
        emergency_telephone: "3208427538",
        app_activation: 0,
        phone: "3291743333",
        prevalent_hand: "R",
        prevalent_leg: "R",
        job: "spazzino",
        weight: "53",
        height: "167",
        BMI: "19.00",
        token: null,
        device_os: null,
        device_token: null,
        coupon_code: "VK4ZXE6L",
        silent_token: null,
        reset_password_code: null,
        is_already_enabled: false,
        training_status: "Altro",
        short_name: "nic",
        middle_name: "secondo",
        birth_area: "italia",
        passport_area: "italia",
        main_role: "terzino",
        national_team: "italia",
        shirt_number: "21",
        createdAt: "2022-08-04T11:15:32.000Z",
        updatedAt: "2023-02-14T16:47:35.000Z",
        patient_group: {
          id: 87,
          id_patient: 97,
          id_group: 46,
          createdAt: "2023-04-18T13:32:57.000Z",
          updatedAt: "2023-04-18T13:32:57.000Z",
        },
        groupPatient: true,
        injuries: [
          {
            id: 730,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-08T00:00:00.000Z",
            end_date: "2023-02-18T14:35:33.000Z",
            estimated_end_date: "2023-02-27T00:00:00.000Z",
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 9,
            id_area: 64,
            id_structure: 229,
            id_pathology: 239,
            type: "Infortunio",
            note: "",
            activity_during_injury: 1,
            mechanism: 2,
            reinjury: 2,
            createdAt: "2023-02-08T11:18:16.000Z",
            updatedAt: "2023-04-18T14:35:33.000Z",
            pathology: {
              id: 239,
              name: "Lesione del legamento glenoomerale",
              description: null,
              osiics_code: "SDG",
              id_tissue_type: 2,
              id_pathology_type: 2,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 289,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 9,
              key: "spallasx",
              name: "Regione Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 64,
              key: "spallasx_anterolaterale",
              name: "Area Deltoidea Della Spalla Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 9,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 229,
              name: "LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO",
              id_area: 64,
              id_tissue_type: 2,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 894,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-01T00:00:00.000Z",
            end_date: "2023-04-26T15:36:17.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 7,
            id_region: 8,
            id_area: 62,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:36:08.000Z",
            updatedAt: "2023-04-26T15:36:17.000Z",
            pathology: null,
            region: {
              id: 8,
              key: "spalladx",
              name: "Regione Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 62,
              key: "spalladx_anterolaterale",
              name: "Area Deltoidea Della Spalla Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 8,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 895,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-02-23T00:00:00.000Z",
            end_date: "2023-04-26T15:38:55.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 15,
            id_area: 83,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-26T15:38:45.000Z",
            updatedAt: "2023-04-26T15:38:55.000Z",
            pathology: null,
            region: {
              id: 15,
              key: "avambracciosx",
              name: "Regione Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 83,
              key: "avambracciosx_laterale",
              name: "Area Laterale Dell'Avambraccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 15,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: null,
          },
          {
            id: 903,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-12T00:00:00.000Z",
            end_date: "2023-04-28T09:09:04.000Z",
            estimated_end_date: null,
            satisfaction: 4,
            id_exit_criteria: null,
            id_interview: 13,
            id_region: 20,
            id_area: 103,
            id_structure: 34,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T09:08:49.000Z",
            updatedAt: "2023-04-28T09:09:04.000Z",
            pathology: null,
            region: {
              id: 20,
              key: "ancadx",
              name: "Regione Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 103,
              key: "ancadx_anteromediale",
              name: "Area Anteromediale Dell'Anca Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 20,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 34,
              name: "MUSCOLO ADDUTTORE BREVE DESTRO",
              id_area: 103,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 905,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-28T00:00:00.000Z",
            end_date: "2023-04-28T15:21:21.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 26,
            id_structure: 448,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:20:55.000Z",
            updatedAt: "2023-04-28T15:21:21.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 26,
              key: "collo_anterioredx",
              name: "Area Laterale Del Collo Destra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 448,
              name: "MUSCOLO LUNGO DEL COLLO DESTRO",
              id_area: 26,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 906,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-01T00:00:00.000Z",
            end_date: "2023-04-28T15:25:02.000Z",
            estimated_end_date: null,
            satisfaction: 1,
            id_exit_criteria: null,
            id_interview: 3,
            id_region: 2,
            id_area: 27,
            id_structure: 469,
            id_pathology: 155,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:24:49.000Z",
            updatedAt: "2023-04-28T15:25:02.000Z",
            pathology: {
              id: 155,
              name: "Stiramento muscolare al collo",
              description: null,
              osiics_code: "NM1",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 2,
              key: "collo",
              name: "Regione Del Collo",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 27,
              key: "collo_anterioresx",
              name: "Area Laterale Del Collo Sinistra",
              latin_name: "null",
              english_name: "null",
              id_region: 2,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 469,
              name: "MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO",
              id_area: 27,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 907,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-04-21T00:00:00.000Z",
            end_date: "2023-04-28T15:26:18.000Z",
            estimated_end_date: null,
            satisfaction: 3,
            id_exit_criteria: null,
            id_interview: 8,
            id_region: 11,
            id_area: 68,
            id_structure: 2017,
            id_pathology: 294,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-04-28T15:26:11.000Z",
            updatedAt: "2023-04-28T15:26:18.000Z",
            pathology: {
              id: 294,
              name: "DOMS al braccio",
              description: null,
              osiics_code: "UMD",
              id_tissue_type: 9,
              id_pathology_type: 11,
              id_medical_system_type: null,
              id_etiology: null,
              id_dysfunction: 295,
              createdAt: "2021-06-07T17:02:00.000Z",
              updatedAt: "2021-06-07T17:02:00.000Z",
            },
            region: {
              id: 11,
              key: "bracciosx",
              name: "Regione Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: {
              id: 68,
              key: "bracciosx_anteriore",
              name: "Area Anteriore Del Braccio Sinistro",
              latin_name: "null",
              english_name: "null",
              id_region: 11,
              createdAt: "2021-06-29T21:58:30.000Z",
              updatedAt: "2021-06-29T21:58:30.000Z",
            },
            structure: {
              id: 2017,
              name: "MUSCOLO BICIPITE BRACHIALE SINISTRO",
              id_area: 68,
              id_tissue_type: 9,
              latin_name: null,
              english_name: null,
              createdAt: "2021-09-19T13:21:00.000Z",
              updatedAt: "2021-09-19 13:21.00",
            },
          },
          {
            id: 919,
            id_patient: 97,
            id_professional: 117,
            start_date: "2023-05-04T00:00:00.000Z",
            end_date: "2023-05-11T10:13:31.000Z",
            estimated_end_date: null,
            satisfaction: 2,
            id_exit_criteria: null,
            id_interview: 10,
            id_region: 14,
            id_area: null,
            id_structure: null,
            id_pathology: null,
            type: "Infortunio",
            note: null,
            activity_during_injury: null,
            mechanism: null,
            reinjury: null,
            createdAt: "2023-05-11T10:11:45.000Z",
            updatedAt: "2023-05-11T10:13:31.000Z",
            pathology: null,
            region: {
              id: 14,
              key: "avambracciodx",
              name: "Regione Dell'Avambraccio Destro",
              latin_name: "null",
              english_name: "null",
              createdAt: null,
              updatedAt: null,
            },
            area: null,
            structure: null,
          },
        ],
      },
    ],
  },
];

export const patientsAbsenseByMonthResultTraining = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Training attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": "100.0",
      "Training attendance (%)": "0.0",
      totalDay: 2,
      totalNotAvailable: 2,
    },
    {
      groupDate: "April",
      "Match absence (%)": "100.0",
      "Training attendance (%)": "0.0",
      totalDay: 2,
      totalNotAvailable: 2,
    },
    {
      groupDate: "May",
      "Match absence (%)": "50.0",
      "Training attendance (%)": "50.0",
      totalDay: 2,
      totalNotAvailable: 1,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};

export const patientsAbsenseByMonthResultMatch = {
  dataChart: [
    {
      groupDate: "June",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "July",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "August",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "September",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "October",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "November",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "December",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "January",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "February",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "March",
      "Match absence (%)": 0,
      "Match attendance (%)": 0,
      totalDay: 0,
      totalNotAvailable: 0,
    },
    {
      groupDate: "April",
      "Match absence (%)": "100.0",
      "Match attendance (%)": "0.0",
      totalDay: 1,
      totalNotAvailable: 1,
    },
    {
      groupDate: "May",
      "Match absence (%)": "66.7",
      "Match attendance (%)": "33.3",
      totalDay: 3,
      totalNotAvailable: 2,
    },
  ],
  barChart: [
    {
      key: null,
      ref: null,
      props: {
        barSize: 25,
        dataKey: "Match absence (%)",
        fill: "#008000",
        stackId: "a",
        children: {
          key: null,
          ref: null,
          props: {
            dataKey: "Match absence (%)",
            position: "center",
          },
          _owner: null,
          _store: {},
        },
        xAxisId: 0,
        yAxisId: 0,
        legendType: "rect",
        minPointSize: 0,
        hide: false,
        data: [],
        layout: "vertical",
        isAnimationActive: true,
        animationBegin: 0,
        animationDuration: 400,
        animationEasing: "ease",
      },
      _owner: null,
      _store: {},
    },
  ],
};
