import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import { useHistory, useParams } from "react-router-dom";
import { showFeedbacksGrid } from "../../../utils/common";

const RaceInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,
  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  control,
  setValue,
  getValues,
  register,
  disabled = false,
  handleSubmit,
  updateActivityFeedback,
  handleSync,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
  snackbarShowErrorMessage,
  snackbarShowMessage,
  subactivities,
  isFromAgenda,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const { patId, groupId } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (templateData) {
      setValue("race_motivation", templateData.race_motivation);
      setValue("race_minutes", templateData.race_minutes);
    }
  }, [templateData]);

  return (
    <Grid
      item
      xs={12}
      container
      style={{ justifyContent: "flex-start" }}
      direction="column"
    >
      <SpTextInput
        name="race_motivation"
        inputRef={register}
        label={
          labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
        }
        disabled={disabled}
        isPrint={isPrint}
      />
      <SpTextInput
        type="number"
        name="race_minutes"
        inputRef={register}
        style={{ marginLeft: "3px" }}
        label={labels.patient.viewPlan.addActivity.inputForm.raceMinutes}
        disabled={disabled}
        isPrint={isPrint}
      />

      {
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          internalLoadOnly={true}
        />
      }

      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "race",
          showFeedback,
          setShowFeedback,
          isFromAgenda,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default RaceInputDetails;
