import { Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpText from "../../components/atoms/SpText";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import { getGroupsById } from "../../models/actions/Groups";
import PatientsReportsConfigurable from "../patients/patientsReports/PatientsReportsConfigurable";
import { labels } from "../shared/translations";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { rollbar } from "../../utils/common";
const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const GroupAnalytics = ({ ...props }) => {
  const [currentGroup, setCurrentGroup] = useState();

  const { groupId } = useParams();

  const history = useHistory();

  useEffect(async () => {
    try {
      const group = await getGroupsById({ id_group: groupId });
      setCurrentGroup(group);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupAnalytics - GroupAnalytics", error);
    }
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={0} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={0}
            direction="row"
            style={{ paddingLeft: "1%" }}
          >
            <PatientsReportsConfigurable />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupAnalytics;
