import { Avatar, Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getFirstTableGroupsData,
  getGroupsById,
} from "../../../models/actions/Groups";
import { rollbar } from "../../../utils/common";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getPatientsWithUncheckedAlerts } from "../../../models/actions/PatientAlerts";
import SpLoader from "../../../components/atoms/SpLoader";
import { ChangeAvailabilityDialog } from "./SituationTableHelper";
import { MonitoringTable } from "./MonitoringTable";

const DetailSituation = ({
  snackbarShowMessage,
  snackbarShowErrorMessage,
  ...props
}) => {
  const [currentGroup, setCurrentGroup] = useState([]);
  const [openToggleAvailability, setOpenToggleAvailability] = useState(false);
  const [availabilitySelected, setAvailabilitySelected] = useState({
    status: "fit",
    date: moment(),
    patient: "",
  });
  const [loading, setLoading] = useState(false);
  const { groupId } = useParams();
  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      //Obtain table data
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
      const firstTableData = await getFirstTableGroupsData({
        id_group: groupId,
      });
      const resultAlerts = await getPatientsWithUncheckedAlerts({
        ids: firstTableData.map((atl) => atl.id),
      });
    } catch (error) {
      setLoading(false);
      rollbar.error("SituationTable - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <MonitoringTable
          setValue={setValue}
          fetchDataSituationTable={fetchData}
          patients={currentGroup?.patients}
          setLoading={setLoading}
          snackbarShowMessage={snackbarShowMessage}
          snackbarShowErrorMessage={snackbarShowErrorMessage}
        />

        <ChangeAvailabilityDialog
          openToggleAvailability={openToggleAvailability}
          setOpenToggleAvailability={setOpenToggleAvailability}
          availabilitySelected={availabilitySelected}
          updateEvent={async () => {
            await fetchData();
          }}
        />
      </Grid>
    </>
  );
};

export default withSnackbar(DetailSituation);
