import React from "react";
import {
  barColors,
  barWithDimension,
  getMonthList,
  titleCase,
} from "../../../../utils/common";
import moment from "moment";
import { getLanguage, labels } from "../../../shared/translations";
import { createBarschartDataPoints } from "./GeneralInjuryFunction";

const trainingAttendance =
  labels.analytics.injuryReport.graphs.trainingAttendance;
const trainingAbsence = labels.analytics.injuryReport.graphs.trainingAbsence;
const matchAttendance = labels.analytics.injuryReport.graphs.matchAttendance;
const matchAbsence = labels.analytics.injuryReport.graphs.matchAbsence;
const monthTrainingAbsense =
  labels.analytics.injuryReport.graphs.monthTrainingAbsense;
const monthMatchAbsense =
  labels.analytics.injuryReport.graphs.monthMatchAbsense;

const language = getLanguage();
moment.locale(String(language));

export const resultPatient = (patients, groups, action) => {
  const barColorValueTemp = barColors();
  let result = [];
  let bars = [];

  const actionAttendance =
    action === "training" ? trainingAttendance : matchAttendance;
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;

  //   lista di date degli allenamenti del gruppo
  let actionDates = {};
  for (let group of groups) {
    actionDates[group.idGruppo] = [
      ...new Set(group[action].map(({ start_date }) => start_date)),
    ];
  }

  for (let patient of patients) {
    let item = {};
    item["groupDate"] = titleCase(patient.givenName + " " + patient.familyName);

    // lista delle date degli allenamenti del gruppo dell'atleta
    let groupDatesSelected = actionDates[patient?.patient_group.id_group];
    groupDatesSelected = [...new Set(groupDatesSelected)];
    if (groupDatesSelected) {
      let countNotAvailable = [];
      //   se la data è compresa tra il range di uno o più infortuni quella viene
      // inserita nell'array delle date non disponibili
      for (let date of groupDatesSelected) {
        const dateFormatted = moment(date).format("YYYY-MM-DD");
        for (let injury of patient.injuries) {
          if (
            moment(dateFormatted).isSame(
              moment(injury.end_date).format("YYYY-MM-DD")
            ) ||
            moment(dateFormatted).isSame(
              moment(injury.start_date).format("YYYY-MM-DD")
            ) ||
            (moment(dateFormatted).isBefore(
              moment(injury.end_date).format("YYYY-MM-DD")
            ) &&
              moment(dateFormatted).isAfter(
                moment(injury.start_date).format("YYYY-MM-DD")
              ))
          ) {
            countNotAvailable.push(dateFormatted);
          }
        }
      }
      countNotAvailable = [...new Set(countNotAvailable)];

      item[actionAbsence] = (
        (countNotAvailable.length * 100) /
        groupDatesSelected.length
      ).toFixed(1);
      item[actionAttendance] = (
        100 -
        (countNotAvailable.length * 100) / groupDatesSelected.length
      ).toFixed(1);
      item["totalDay"] = groupDatesSelected.length;
      item["totalNotAvailable"] = countNotAvailable.length;
    }
    result.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      actionAttendance,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  bars.push(
    createBarschartDataPoints(
      actionAbsence,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};

export const resultGroup = (groups, action, groupsSelected) => {
  const barColorValueTemp = barColors();

  let result = [];
  let bars = [];

  const actionAttendance =
    action === "training" ? trainingAttendance : matchAttendance;
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;

  //   lista di date degli allenamenti del gruppo
  let actionDates = {};
  for (let group of groups) {
    actionDates[group.idGruppo] = [
      ...new Set(group[action].map(({ start_date }) => start_date)),
    ];
  }
  for (let groupSelected of groupsSelected) {
    let item = {};
    item["groupDate"] = groupSelected.name;
    const resultPatients = resultPatient(
      groupSelected.patients,
      groups,
      action
    ).dataChart;

    const absence = (
      resultPatients.reduce(function (acc, oggetto) {
        return acc + parseFloat(oggetto[actionAbsence]);
      }, 0) / resultPatients.length
    ).toFixed(1);

    item[actionAbsence] = absence;
    item[actionAttendance] = (100 - parseFloat(absence)).toFixed(1);
    result.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      actionAttendance,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );

  bars.push(
    createBarschartDataPoints(
      actionAbsence,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};

export const groupAttendanceByMonth = (group, action, dateRange) => {
  const barColorValueTemp = barColors();

  let resultChar = [];
  let bars = [];

  const actionAttendance =
    action === "training" ? trainingAttendance : matchAttendance;
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;

  //   lista di date degli allenamenti del gruppo
  const actionDates = [
    ...new Set(group[action].map(({ start_date }) => start_date)),
  ];
  const monthList = getMonthList(
    moment(dateRange.start).format("YYYY-MM-DD"),
    moment(dateRange.end).format("YYYY-MM-DD")
  );
  let resultPatient = [];
  for (let day of monthList) {
    const month = parseInt(moment(day).format("MM"));
    const groupDatesSelectedMonth = []
    for (let date of actionDates) {
      const dateMonth = parseInt(moment(date).format("MM"));
      if (dateMonth === month) {
        groupDatesSelectedMonth.push(date)
      }  
    }  

    
    for (let patient of group.patients) {
      const injuries = patient.injuries;
      const patientInjuriesMonth = injuries

      let countNotAvailable = {};
      countNotAvailable[patient.id] = [];
      //   se la data è compresa tra il range di uno o più infortuni quella viene
      // inserita nell'array delle date non disponibili
      for (let date of groupDatesSelectedMonth) {
        const dateFormatted = moment(date).format("YYYY-MM-DD");
        for (let injuryMonth of patientInjuriesMonth) {
          let end_date;
          if (injuryMonth?.end_date===null) {
            end_date = moment().format("YYYY-MM-DD")
          } else {
            end_date = moment(injuryMonth?.end_date).format("YYYY-MM-DD");
          }
          let start_date;
          if (injuryMonth?.start_date===null) {
            start_date = moment().format("YYYY-MM-DD")
          } else {
            start_date = moment(injuryMonth?.start_date).format("YYYY-MM-DD");
          }
          
          if (
            moment(dateFormatted).isSame(
              moment(end_date)
            ) || 
            moment(dateFormatted).isSame(
              moment(start_date)
            ) ||
            (moment(dateFormatted).isBefore(
              moment(end_date)
            ) &&
              moment(dateFormatted).isAfter(
                moment(injuryMonth.start_date)
              )
            ) ||
            (moment(dateFormatted).isAfter(
              moment(start_date)
            ) &&
              moment(dateFormatted).isBefore(
                moment(end_date).format("YYYY-MM-DD")
              )
            )
          ) {
            countNotAvailable[patient.id].push(dateFormatted);
          }
          
        }
      }

      let item = {};
      item["key"] = moment()
        .month(month - 1)
        .format("MMMM");
      countNotAvailable[patient.id] = [
        ...new Set(countNotAvailable[patient.id]),
      ];
      item["value"] = {};
      item["value"]["groupDate"] = patient.id;

      item["value"][actionAbsence] =
        groupDatesSelectedMonth.length === 0
          ? 0
          : parseFloat(
              (100-(countNotAvailable[patient.id].length * 100) / groupDatesSelectedMonth.length
              ).toFixed(1)
            );

      item["value"][actionAttendance] =
        groupDatesSelectedMonth.length === 0
          ? 0
          : parseFloat(
              ((countNotAvailable[patient.id].length * 100) / groupDatesSelectedMonth.length).toFixed(1));
      item["value"]["totalDay"] = groupDatesSelectedMonth.length;
      item["value"]["totalNotAvailable"] = countNotAvailable[patient.id].length;
      item["value"]["ava"] = ((groupDatesSelectedMonth.length-countNotAvailable[patient.id].length)/groupDatesSelectedMonth.length)*100;
      resultPatient.push(item);
    }
  }

  for (let day of monthList) {
    const month = parseInt(moment(day).format("MM"));
    const labelMonth = moment()
      .month(month - 1)
      .format("MMMM");
    const resultMonth = resultPatient.filter(({ key }) => key === labelMonth);
    let sum = {
      [actionAbsence]: 0,
      [actionAttendance]: 0,
      ["totalDay"]: 0,
      ["totalNotAvailable"]: 0,
    };
    resultMonth.forEach(({ value }) => {
      sum[actionAbsence] += value[actionAbsence];
      sum[actionAttendance] += value[actionAttendance];
      sum["totalDay"] += value["totalDay"];
      sum["totalNotAvailable"] += value["totalNotAvailable"];
    });

    let item = {};
    item["groupDate"] = labelMonth;
    item[actionAbsence] = parseFloat(
      (sum[actionAbsence] / resultMonth.length).toFixed(1)
    );
    item[actionAttendance] = parseFloat(
      (sum[actionAttendance] / resultMonth.length).toFixed(1)
    );
    item["totalDay"] = parseFloat(
      (sum["totalDay"] / resultMonth.length).toFixed(1)
    );
    item["totalNotAvailable"] = parseFloat(
      (sum["totalNotAvailable"] / resultMonth.length).toFixed(1)
    );
    resultChar.push(item);
  }

  bars.push(
    createBarschartDataPoints(
      actionAttendance,
      barWithDimension(resultChar.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultChar, barChart: bars };
};

export const patientAttendanceByMonth = (
  patient,
  groups,
  action,
  dateRange
) => {
  const barColorValueTemp = barColors();

  let resultChar = [];
  let bars = [];

  const actionAttendance =
    action === "training" ? trainingAttendance : matchAttendance;
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;

  //   lista di date degli allenamenti del gruppo
  let actionDates = {};
  for (let group of groups) {
    actionDates[group.idGruppo] = [
      ...new Set(group[action].map(({ start_date }) => start_date)),
    ];
  }

  // lista delle date degli allenamenti del gruppo dell'atleta
  let groupDatesSelected = actionDates[patient?.patient_group.id_group];
  groupDatesSelected = [...new Set(groupDatesSelected)];
  if (groupDatesSelected) {
    const monthList = getMonthList(
      moment(dateRange.start).format("YYYY-MM-DD"),
      moment(dateRange.end).format("YYYY-MM-DD")
    );

    for (let day of monthList) {
      const month = parseInt(moment(day).format("MM"));
      const groupDatesSelectedMonth = []
      for (let date of groupDatesSelected) {
        const dateMonth = parseInt(moment(date).format("MM"));
        if (dateMonth === month) {
          groupDatesSelectedMonth.push(date)
        }  
      }  
      const patientInjuriesMonth = patient.injuries
      let countNotAvailable = [];
      //   se la data è compresa tra il range di uno o più infortuni quella viene
      // inserita nell'array delle date non disponibili
      for (let date of groupDatesSelectedMonth) {
        const dateMonth = parseInt(moment(date).format("MM"));
        if (dateMonth === month) {
          const dateFormatted = moment(date).format("YYYY-MM-DD");
          for (let injuryMonth of patientInjuriesMonth) {
            if (
              moment(dateFormatted).isSame(
                moment(injuryMonth.end_date).format("YYYY-MM-DD")
              ) ||
              moment(dateFormatted).isSame(
                moment(injuryMonth.start_date).format("YYYY-MM-DD")
              ) ||
              (moment(dateFormatted).isBefore(
                moment(injuryMonth.end_date).format("YYYY-MM-DD")
              ) &&
                moment(dateFormatted).isAfter(
                  moment(injuryMonth.start_date).format("YYYY-MM-DD")
                ))
            ) {
              countNotAvailable.push(dateFormatted);
            }
          }
        }
      }
      let item = {};

      countNotAvailable = [...new Set(countNotAvailable)];
      const momentMonth = moment().month(month - 1);
      item["groupDate"] = momentMonth.format("MMMM");

      item[actionAbsence] =
        groupDatesSelectedMonth.length === 0
          ? 0
          : (
              (countNotAvailable.length * 100) /
              groupDatesSelectedMonth.length
            ).toFixed(1);

      item[actionAttendance] =
        groupDatesSelectedMonth.length === 0
          ? 0
          : (
              100 -
              (countNotAvailable.length * 100) / groupDatesSelectedMonth.length
            ).toFixed(1);
      item["totalDay"] = groupDatesSelectedMonth.length;
      item["totalNotAvailable"] = countNotAvailable.length;
      resultChar.push(item);
    }
  }

  bars.push(
    createBarschartDataPoints(
      actionAttendance,
      barWithDimension(resultChar.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultChar, barChart: bars };
};

export const patientsActionMissing = (patients, groups, action, dateRange) => {
  const barColorValueTemp = barColors();
  let bars = [];
  const labelAction =
    action === "training" ? monthTrainingAbsense : monthMatchAbsense;
  const nMonths = moment(dateRange.end).diff(moment(dateRange.start), "months");
  const result = resultPatient(patients, groups, action).dataChart;

  result.forEach((item) => {
    item[labelAction] = (item["totalNotAvailable"] / nMonths).toFixed(1);
  });
  bars.push(
    createBarschartDataPoints(
      labelAction,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};

export const groupsActionMissing = (groups, action, dateRange) => {
  const barColorValueTemp = barColors();
  let bars = [];
  const labelAction =
    action === "training" ? monthTrainingAbsense : monthMatchAbsense;
  const nMonths = moment(dateRange.end).diff(moment(dateRange.start), "months");
  let result = [];
  let patientsInjuriesFiltered = [];
  for (let group of groups) {
    let tempPatients = JSON.parse(JSON.stringify(group.patients));
    for (let temppatient of tempPatients) {
      const tempInjuries = temppatient?.injuries?.filter((injury) => {
        let end_date;
        if (injury?.end_date===null) {
          end_date = moment().format("YYYY-MM-DD")
        } else {
          end_date = moment(injury?.end_date).format("YYYY-MM-DD");
        }
        let start_date;
        if (injury?.start_date===null) {
          start_date = moment().format("YYYY-MM-DD")
        } else {
          start_date = moment(injury?.end_date).format("YYYY-MM-DD");
        }
        return (
          moment(end_date).isSame(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) ||
          moment(end_date).isSame(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          moment(end_date).isBefore(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          moment(end_date).isAfter(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) ||
          moment(start_date).isSame(
            moment(dateRange?.start).format("YYYY-MM-DD")
          ) ||
          moment(start_date).isSame(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          moment(start_date).isBefore(
            moment(dateRange?.end).format("YYYY-MM-DD")
          ) ||
          moment(start_date).isAfter(
            moment(dateRange?.start).format("YYYY-MM-DD")
          )
        );
      });
      patientsInjuriesFiltered.push({
        ...temppatient,
        injuries: tempInjuries,
      });
    }
    const resultForEachpatient = resultPatient(
      patientsInjuriesFiltered,
      groups,
      action
    ).dataChart;
    resultForEachpatient.forEach((item) => {
      item[labelAction] = item["totalNotAvailable"] / nMonths;
    });
    const sumAbsence = (
      resultForEachpatient.reduce(function (acc, oggetto) {
        return acc + parseFloat(oggetto[labelAction]);
      }, 0) / group.patients.length
    ).toFixed(1);
    let item = {};
    item["groupDate"] = group.nameGruppo;
    item[labelAction] = sumAbsence;
    result.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      labelAction,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};

export const groupAbsenseByMonth = (group, action, dateRange) => {
  const barColorValueTemp = barColors();
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;
  const result = groupAttendanceByMonth(group, action, dateRange).dataChart;
  let bars = [];

  bars.push(
    createBarschartDataPoints(
      actionAbsence,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};

export const patientsAbsenseByMonth = (patient, groups, action, dateRange) => {
  const barColorValueTemp = barColors();
  const actionAbsence = action === "training" ? trainingAbsence : matchAbsence;
  const result = patientAttendanceByMonth(
    patient,
    groups,
    action,
    dateRange
  ).dataChart;
  let bars = [];

  bars.push(
    createBarschartDataPoints(
      actionAbsence,
      barWithDimension(result.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: result, barChart: bars };
};
