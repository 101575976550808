import Grid from "@material-ui/core/Grid";
import React from "react";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../../shared/translations";
import SpCheckbox from "../../../../components/atoms/SpCheckbox";
import SpText from "../../../../components/atoms/SpText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export const PromsChecks = ({ filters, setFilters }) => {
  return (
    <Grid container xs={12} direction="row">
      {/* COMPAREPERC */}
      <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
        <SpCheckbox
          label={labels.patient.graphReport.section.feedback.studies.COMPAREASS}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.promsStudy.checkCOMPAREASS}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              promsStudy: { ...filters.promsStudy, checkCOMPAREASS: value },
            })
          }
        />
      </Grid>

      {/* COMPAREASS */}
      <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
        <SpCheckbox
          label={
            labels.patient.graphReport.section.feedback.studies.COMPAREPERC
          }
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.promsStudy.checkCOMPAREPERC}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              promsStudy: {
                ...filters.promsStudy,
                checkCOMPAREPERC: value,
              },
            })
          }
        />
      </Grid>

      {/* CONFIDENCE */}
      {/* <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
        <SpCheckbox
          label={labels.patient.graphReport.section.feedback.studies.CONFIDENCE}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.promsStudy.checkCONFIDENCE}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              promsStudy: { ...filters.promsStudy, checkCONFIDENCE: value },
            })
          }
        />
      </Grid> */}

      {/* INDEXES */}
      <Grid item xs={3} style={{ padding: "8px" }} contaier direction="row">
        <SpCheckbox
          label={labels.patient.graphReport.section.feedback.studies.INDEXES}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={filters.promsStudy.checkINDEXES}
          onChange={(_, value) =>
            setFilters({
              ...filters,
              promsStudy: { ...filters.promsStudy, checkINDEXES: value },
            })
          }
        />
      </Grid>

      {/* Z-SCORE */}
      <Grid item xs={12} style={{ padding: "8px" }} contaier direction="row">
        <Grid item xs={12} contaier direction="row">
          <SpCheckbox
            label={labels.patient.graphReport.section.feedback.studies.ZSCORE}
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={filters.promsStudy.checkZscore}
            onChange={(_, value) =>
              setFilters({
                ...filters,
                promsStudy: { ...filters.promsStudy, checkZscore: value },
              })
            }
          />
        </Grid>
      </Grid>

      {filters.promsStudy.checkZscore && (
        <>
          <Grid style={{ padding: "8px" }} item xs={3}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies
                  .ZSCORE_REFERENCE
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkPeriod}
              onChange={(_, value) =>
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkPeriod: value,
                  },
                })
              }
            />
            {filters.promsStudy.checkPeriod && (
              <SpTextInput
                label={
                  labels.patient.graphReport.section.feedback.filters.period
                }
                value={filters.promsStudy.zScorePeriod}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilters({
                    ...filters,
                    promsStudy: {
                      ...filters.promsStudy,
                      zScorePeriod: value,
                    },
                  });
                }}
                type="number"
              />
            )}
          </Grid>
          <Grid item xs={3} style={{ pading: "8px" }}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies
                  .ZSCORE_VALUES
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkPreviousValues}
              onChange={(_, value) =>
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkPreviousValues: value,
                  },
                })
              }
            />
            {filters.promsStudy.checkPreviousValues && (
              <SpTextInput
                label={
                  labels.patient.graphReport.section.feedback.filters
                    .previous_values
                }
                value={filters.promsStudy.zScorePreviousValues}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilters({
                    ...filters,
                    promsStudy: {
                      ...filters.promsStudy,
                      zScorePreviousValues: value,
                    },
                  });
                }}
                type="number"
              />
            )}
          </Grid>

          <Grid item xs={3} style={{ pading: "8px" }}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies.ZSCORE_FIXED
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkFixedPeriod}
              onChange={(_, value) => {
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkFixedPeriod: value,
                  },
                });
              }}
            />
            {filters.promsStudy.checkFixedPeriod && (
              <>
                <Grid item xs={6} style={{ pading: "8px" }}>
                  <SpTextInput
                    label={
                      labels.patient.graphReport.section.feedback.filters
                        .start_date
                    }
                    value={filters.promsStudy.startDateFixedZscore}
                    style={{ width: "100%" }}
                    type={"date"}
                    onChange={(evnt) => {
                      setFilters({
                        ...filters,
                        promsStudy: {
                          ...filters.promsStudy,
                          startDateFixedZscore: moment(
                            evnt.target.value
                          ).format("YYYY-MM-DD"),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ pading: "8px" }}>
                  <SpTextInput
                    label={
                      labels.patient.graphReport.section.feedback.filters
                        .end_date
                    }
                    value={filters.promsStudy.endDateFixedZscore}
                    style={{ width: "100%" }}
                    type={"date"}
                    onChange={(evnt) =>
                      setFilters({
                        ...filters,
                        promsStudy: {
                          ...filters.promsStudy,
                          endDateFixedZscore: moment(evnt.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        },
                      })
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={3} style={{ pading: "8px" }}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies
                  .ZSCORE_SUBJECT_REFERENCE
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkPreviousPeriod}
              onChange={(_, value) => {
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkPreviousPeriod: value,
                  },
                });
              }}
            />
            {filters.promsStudy.checkPreviousPeriod && (
              <SpTextInput
                label={
                  labels.patient.graphReport.section.feedback.filters
                    .previous_values
                }
                value={filters.promsStudy.previousPeriodValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilters({
                    ...filters,
                    promsStudy: {
                      ...filters.promsStudy,
                      previousPeriodValue: value,
                    },
                  });
                }}
                type="number"
              />
            )}
          </Grid>

          <Grid item xs={3} style={{ pading: "8px", paddingLeft: "8px" }}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies
                  .ZSCORE_SUBJECT_VALUES
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkPreviousSubject}
              onChange={(_, value) =>
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkPreviousSubject: value,
                  },
                })
              }
            />
            {filters.promsStudy.checkPreviousSubject && (
              <SpTextInput
                label={
                  labels.patient.graphReport.section.feedback.filters
                    .previous_values
                }
                value={filters.promsStudy.previousSubjectValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilters({
                    ...filters,
                    promsStudy: {
                      ...filters.promsStudy,
                      previousSubjectValue: value,
                    },
                  });
                }}
                type="number"
              />
            )}
          </Grid>

          <Grid item xs={3} style={{ pading: "8px" }}>
            <SpCheckbox
              label={
                labels.patient.graphReport.section.feedback.studies
                  .ZSCORE_SUBJECT_FIXED
              }
              customFromLabelStyle={{ marginBottom: 0 }}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              checked={filters.promsStudy.checkFixedSubject}
              onChange={(_, value) =>
                setFilters({
                  ...filters,
                  promsStudy: {
                    ...filters.promsStudy,
                    checkFixedSubject: value,
                  },
                })
              }
            />
            {filters.promsStudy.checkFixedSubject && (
              <>
                <Grid item xs={6} style={{ pading: "8px" }}>
                  <SpTextInput
                    label={
                      labels.patient.graphReport.section.feedback.filters
                        .start_date
                    }
                    value={filters.promsStudy.startDateFixedSubject}
                    style={{ width: "100%" }}
                    type={"date"}
                    onChange={(evnt) => {
                      setFilters({
                        ...filters,
                        promsStudy: {
                          ...filters.promsStudy,
                          startDateFixedSubject: moment(
                            evnt.target.value
                          ).format("YYYY-MM-DD"),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ pading: "8px" }}>
                  <SpTextInput
                    label={
                      labels.patient.graphReport.section.feedback.filters
                        .end_date
                    }
                    value={filters.promsStudy.endDateFixedSubject}
                    style={{ width: "100%" }}
                    type={"date"}
                    onChange={(evnt) =>
                      setFilters({
                        ...filters,
                        promsStudy: {
                          ...filters.promsStudy,
                          endDateFixedSubject: moment(evnt.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        },
                      })
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
