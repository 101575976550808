import { Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import {
  getMeasurementsParameters,
  getMeasurementsParametersAnswers,
  getMeasurementsParametersAnswersGroups,
} from "../../../models/actions/Patients";
import { dateFormat } from "../../../utils/common";
import TemplatePatientsReports from "./TemplatePatientsReports";
import { psTranslate } from "../../shared/translations";

const EXCLUDED_STUDIES = [
  "MONOTONY",
  "STRAIN",
  "ACWRRA",
  "ACWREWMA",
  "INDEXES",
];

const dateFormatEndOfDay = `${dateFormat} 23:59:59`;

const PatientsReportsStep4Measurements = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  let { patId, groupId } = useParams();
  patId = patId ? parseInt(patId) : patId;
  groupId = groupId ? parseInt(groupId) : groupId;

  const getStudyParams = () => {
    return getMeasurementsParameters({
      id_patients: patId ? [patId] : null,
      id_groups: groupId ? [groupId] : null,
    });
  };

  const fixProxyParameters = (studyParams) => {
    studyParams.forEach(
      (s) =>
        (s.name = `${psTranslate(s.category_name)} - ${psTranslate(
          s.element_name
        )} (${psTranslate(s.column_name)})`)
    );
    return studyParams;
  };

  const getStudyParamsInRange = async () => {
    return null; //Always show all
  };

  const fetchPatientsData = async (
    _,
    fetchDateRange,
    currStudyParameterIds,
    patientIds
  ) => {
    return await getMeasurementsParametersAnswers({
      id_patients: patientIds,
      parameters: currStudyParameterIds,
      startDate: fetchDateRange.start.format(dateFormat),
      endDate: fetchDateRange.end.format(dateFormatEndOfDay),
    });
  };

  const fetchGroupData = async (
    _,
    fetchDateRange,
    currStudyParameterIds,
    groups
  ) => {
    return await getMeasurementsParametersAnswersGroups({
      id_groups: groups,
      parameters: currStudyParameterIds,
      startDate: fetchDateRange.start.format(dateFormat),
      endDate: fetchDateRange.end.format(dateFormatEndOfDay),
    });
  };

  return (
    <Grid container item xs={12}>
      <TemplatePatientsReports
        elements={null}
        excludedStudies={EXCLUDED_STUDIES}
        getStudyParams={getStudyParams}
        getStudyParamsInRange={getStudyParamsInRange}
        fixProxyParameters={fixProxyParameters}
        fixProxyParamsListAvailable={null}
        fixForProxyParams={null}
        fixForProxyParamsResults={null}
        fetchPatientsData={fetchPatientsData}
        fetchGroupData={fetchGroupData}
        enableCombinedParameters={false}
        defaultZero={false}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
      />
    </Grid>
  );
};

export default PatientsReportsStep4Measurements;
