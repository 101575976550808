import { Chip, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import moment from "moment";
import SpText from "../../components/atoms/SpText";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpButton from "../../components/atoms/SpButton";
import {
  createUserAuth0,
  getAuth0Roles,
  getTokenOrganization,
} from "../../models/actions/Common";
import SpAutocomplete from "../../components/atoms/SpAutocomplete";
import { theme } from "../../components/theme";
import { labels } from "../shared/translations";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { getAllProfessionals } from "../../models/actions/Professionals";
import SpSearch from "../../components/atoms/SpSearch";
import { getTeamCollection } from "../../models/actions/Collection";
import {
  CreateGroup,
  CreateOrganization,
  CreateUser,
  DuplicateTemplate,
} from "./ManageUsersFunction";

const ManageUsers = ({ setLoading, ...props }) => {
  // Stati per il form di creazione utente
  const [users, setUsers] = useState([]);
  const [updateOrganization, setUpdateOrganization] = useState(false);
  const [usersSearch, setUsersSearch] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Recupera le informazioni sull'utente e le organizzazioni a cui appartiene
    const getAllProgessionals = async () => {
      const tempUsers = await getAllProfessionals();
      setUsers(tempUsers);
    };
    getAllProgessionals();
    setLoading(false);
  }, []);

  const PresentationStatRow = ({ row, index }) => (
    <>
      <SpTableRow id={row.id} tabIndex={index}>
        <SpTableCell id={row.name}>
          <SpText variant={"tableText"}>{row.name}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant={"tableText"}>{row.surname}</SpText>
        </SpTableCell>
        <SpTableCell id={row.email}>
          <SpText variant={"tableText"}>{row.email}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant={"tableText"}>{row.club}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant={"tableText"}>
            {row.expiry_date && moment(row.expiry_date).format("DD/MM/YYYY")}
          </SpText>
        </SpTableCell>
      </SpTableRow>
    </>
  );

  const presentationHeadCells = [
    {
      width: "20%",
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.manageUsers.name,
      isAction: false,
    },
    {
      width: "20%",
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: labels.manageUsers.surname,
      isAction: false,
    },
    {
      width: "30%",
      id: "email",
      numeric: false,
      disablePadding: false,
      label: labels.manageUsers.email,
      isAction: false,
    },
    {
      width: "20%",
      id: "club",
      numeric: false,
      disablePadding: false,
      label: labels.manageUsers.group,
      isAction: false,
    },
    {
      width: "10%",
      id: "expiryDate",
      numeric: false,
      disablePadding: false,
      label: labels.manageUsers.expiryDate,
      isAction: false,
    },
  ];

  return (
    <>
      <CreateOrganization
        setUpdateOrganization={setUpdateOrganization}
        setLoading={setLoading}
        snackbarShowMessage={props.snackbarShowMessage}
        snackbarShowErrorMessage={props.snackbarShowErrorMessage}
      />
      <CreateUser
        setLoading={setLoading}
        updateOrganization={updateOrganization}
        setUpdateOrganization={setUpdateOrganization}
        snackbarShowMessage={props.snackbarShowMessage}
        snackbarShowErrorMessage={props.snackbarShowErrorMessage}
      />

      <CreateGroup
        setLoading={setLoading}
        updateOrganization={updateOrganization}
        setUpdateOrganization={setUpdateOrganization}
        snackbarShowMessage={props.snackbarShowMessage}
        snackbarShowErrorMessage={props.snackbarShowErrorMessage}
      />
      <DuplicateTemplate
        setLoading={setLoading}
        snackbarShowMessage={props.snackbarShowMessage}
        snackbarShowErrorMessage={props.snackbarShowErrorMessage}
      />

      <Grid item xs={12} style={{ width: "20%", paddingTop: "3em" }}>
        <SpText variant="h2PageSubtitleColored">
          {labels.manageUsers.searchUser}
        </SpText>
        <SpSearch
          onChange={(e) => {
            setUsersSearch(
              users.filter(({ email }) => email.includes(e.target.value))
            );
          }}
        />
      </Grid>

      <SpTable
        sortable={true}
        pagination={true}
        headCells={presentationHeadCells}
        rows={usersSearch.length > 0 ? usersSearch : users}
        rowKey="id"
        padding={false}
        notCheckable={false}
      >
        <PresentationStatRow />
      </SpTable>
    </>
  );
};
export default withSnackbar(ManageUsers);
