import CreateIcon from "@material-ui/icons/Create";
import { Grid } from "@material-ui/core";
import SpIconButton from "../../../components/atoms/SpIconButton";
import React from "react";
import { styled } from "../../../components/styled";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import { labels } from "../../shared/translations";
import { theme } from "../../../components/theme";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const StyledRowItem = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
});

export const getReferenceButton = ({
  showReferenceButton,
  setShowReferenceButton,
  register,
  getValues,
}) => {
  return (
    <>
      {!showReferenceButton && (
        <Grid item xs={6} style={{ padding: "8px" }}>
          <StyledRow>
            <StyledRowItem>
              <>
                <SpTextInput
                  label={labels.mylab.reference}
                  formControlStyle={{ width: "50%" }}
                  placeholder={labels.mylab.insertReference}
                  name="template_reference"
                  inputRef={register}
                />
                <SpButton
                  style={{
                    marginTop: "2%",
                    maxWidth: "100%",
                    width: "25%",
                  }}
                  text={labels.general.update}
                  buttonType="accept"
                  variant="none"
                  onClick={() => {
                    setShowReferenceButton(true);
                  }}
                />
              </>
            </StyledRowItem>
          </StyledRow>
        </Grid>
      )}
      {showReferenceButton && getValues("template_reference").length > 0 && (
        <Grid item xs={6} style={{ padding: "8px" }}>
          <StyledRow>
            <StyledRowItem>
              <SpIconButton
                style={{ width: "auto", height: "auto" }}
                alignSelf="auto"
                color={theme.colors.primary.lightBlue}
                variant="raised"
                onClick={() => {
                  setShowReferenceButton(false);
                }}
                small
              >
                <CreateIcon />
                <SpButton
                  style={{
                    marginTop: "8px",
                    maxWidth: "100%",
                    width: "25%",
                  }}
                  text={labels.mylab.reference}
                  buttonType="accept"
                  variant="none"
                  onClick={() => {
                    window.open(getValues("template_reference"), "_blank");
                  }}
                />
              </SpIconButton>
            </StyledRowItem>
          </StyledRow>
        </Grid>
      )}
    </>
  );
};
