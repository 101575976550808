import React, { forwardRef, useEffect } from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
// material ui
import Grid from "@material-ui/core/Grid";
// models
// components
import { styled } from "../../../../../../components/styled";
// atoms
import { SpAutocomplete } from "../../../../../../components/atoms/SpAutocomplete";
import SpCheckbox from "../../../../../../components/atoms/SpCheckbox";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import SpTextArea from "../../../../../../components/atoms/SpTextArea";
import SpText from "../../../../../../components/atoms/SpText";
//utils

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "0.5%",
});

export const GRFInfoSection = forwardRef(
  ({
    inputNameRef,
    setInputNameRef,
    inputValues,
    setInputValues,
    filterLocks,
    setFilterLocks,
    areaNotes,
    setAreaNotes,
    areaReferences,
    setAreaReferences,
  }) => {
    const ansSignificative = [
      {
        id: 1,
        key: "significative",
        value: true,
        name: labels.mylab.generalRiskFactor.addComp.inputSection
          .isSignificative.yes,
      },
      {
        id: 0,
        key: "non_significative",
        value: false,
        name: labels.mylab.generalRiskFactor.addComp.inputSection
          .isSignificative.no,
      },
    ];
    const ansIntrinsic = [
      {
        id: 1,
        key: "intrisic",
        value: true,
        name: labels.mylab.generalRiskFactor.addComp.inputSection.isIntrinsic
          .yes,
      },
      {
        id: 0,
        key: "non_intrisic",
        value: false,
        name: labels.mylab.generalRiskFactor.addComp.inputSection.isIntrinsic
          .no,
      },
    ];
    const ansModifiable = [
      {
        id: 1,
        key: "modifiable",
        value: true,
        name: labels.mylab.generalRiskFactor.addComp.inputSection.isModifiable
          .yes,
      },
      {
        id: 0,
        key: "non_modifiable",
        value: false,
        name: labels.mylab.generalRiskFactor.addComp.inputSection.isModifiable
          .no,
      },
    ];

    return (
      <StyledGridContainer key="">
        <Grid
          item
          container
          xs={12}
          style={{ flexDirection: "column", marginBottom: "1%" }}
        >
          <SpText variant="h1PageSubtitle">
            {labels.mylab.generalRiskFactor.addComp.titleInfoSection}
          </SpText>
        </Grid>
        {/* riga nome e sport */}
        <Grid
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
            direction: "row",
          }}
        >
          <Grid item xs={3}>
            <SpTextInput
              isRequired="true"
              id={"inputName"}
              value={inputNameRef}
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.inputName
              }
              placeholder={
                labels.mylab.generalRiskFactor.addComp.inputSection
                  .inputNamePlaceholder
              }
              formControlStyle={{ width: "100%" }}
              onChange={(e) => {
                setInputNameRef(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockName"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.nameToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  nameToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SpAutocomplete
              id={"selectSport"}
              formControlWidth={"100%"}
              value={
                labels.mylab.generalRiskFactor.addComp.inputSection.sportList.find(
                  ({ key }) => key == inputValues.sportSelected
                ) ?? ""
              }
              disableListWrap
              label={labels.mylab.generalRiskFactor.addComp.inputSection.sport}
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.inputSection
                  .sportPlaceholder
              }
              onChange={(e, { key }) => {
                setInputValues({
                  ...inputValues,
                  sportSelected: key,
                });
              }}
              options={
                labels.mylab.generalRiskFactor.addComp.inputSection.sportList
              }
              getOptionLabel={({ value }) => psTranslate(value)}
              getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                key_opt === key_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockSport"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.sportToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  sportToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SpAutocomplete
              id={"selectSex"}
              formControlWidth={"100%"}
              value={
                labels.mylab.generalRiskFactor.addComp.inputSection.sexValues.find(
                  ({ key }) => key == inputValues.sexSelected
                ) ?? ""
              }
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.sexLabel
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.inputSection.sexLabel
              }
              onChange={(e, { key }) => {
                setInputValues({
                  ...inputValues,
                  sexSelected: key,
                });
              }}
              options={
                labels.mylab.generalRiskFactor.addComp.inputSection.sexValues
              }
              getOptionLabel={({ value }) => value}
              getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                key_opt === key_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockSex"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.sexToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  sexToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>

        {/* riga significative, intrinsic, modifiable */}
        <Grid
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Grid item xs={3}>
            <SpAutocomplete
              isRequired={true}
              id={"selectIsIntrinsic"}
              formControlWidth={"100%"}
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.intrinsic
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.inputSection.selectAnswer
              }
              value={
                ansIntrinsic.find(
                  ({ value }) => value == inputValues.intrinsicSelected
                ) ?? ""
              }
              onChange={(_, { value }) => {
                setInputValues({
                  ...inputValues,
                  intrinsicSelected: value,
                });
              }}
              options={ansIntrinsic}
              getOptionLabel={({ name }) => psTranslate(name)}
              getOptionSelected={({ key: key_opt }, { key: key_value }) =>
                key_opt === key_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockIntrinsic"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.intrinsicToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  intrinsicToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SpAutocomplete
              isRequired={true}
              id={"selectIsModifiable"}
              formControlWidth={"100%"}
              disableListWrap
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.modifiable
              }
              selectPlaceholder={
                labels.mylab.generalRiskFactor.addComp.inputSection.selectAnswer
              }
              value={
                ansModifiable.find(
                  ({ value }) => value == inputValues.modifiableSelected
                ) ?? ""
              }
              onChange={(_, { value }) => {
                setInputValues({
                  ...inputValues,
                  modifiableSelected: value,
                });
              }}
              options={ansModifiable}
              getOptionLabel={({ name }) => psTranslate(name)}
              getOptionSelected={({ id: id_opt }, { id: id_value }) =>
                id_opt === id_value
              }
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockModifiable"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.lock}
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
              checked={filterLocks.modifiableToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  modifiableToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>

        {/* riga note e referenze */}
        <Grid
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "space-between",
          }}
        >
          <Grid
            item
            xs={6}
            style={{ width: "100%", marginTop: "10px", marginBottom: "0.1em" }}
          >
            <SpTextArea
              isRequired={false}
              id={"areaNotes"}
              label={labels.mylab.generalRiskFactor.addComp.inputSection.notes}
              style={{ width: "100%" }}
              value={areaNotes}
              onChange={(e) => {
                setAreaNotes(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "100%", marginTop: "10px" }}>
            <SpTextArea
              id={"areaReferences"}
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.references
              }
              style={{ width: "100%" }}
              value={areaReferences}
              onChange={(e) => {
                setAreaReferences(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </StyledGridContainer>
    );
  }
);
