import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";

import SpText from "../../components/atoms/SpText";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";

import {
  findPatientByPk,
  getUserProfileImage,
  setUserProfileImage,
} from "../../models/actions/Patients";
import { getAreasByRegion, getRegions } from "../../models/actions/Pathologies";
import { Button as MuiButton, Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { getResourceFromServer } from "../../models/actions/Professionals";
import { spacing } from "@material-ui/system";
import { uploadFile } from "../../models/actions/awsUtils";
import { getUserPermission, rollbar } from "../../utils/common";
import { useForm } from "react-hook-form";
import { SpStepper, StepTitle } from "../../components/bundles";
import PatientsRegistrySumup from "./patientsRegistry/PatientsRegistrySumup";
import PatientsRegistryInput from "./patientsRegistry/PatientsRegistryInput";
import PastHistoryInputBasic from "./patientPastHistorySteps/PastHistoryInputBasic";
import PastHistoryInputStep from "./patientPastHistorySteps/PastHistoryInputStep";
import PathologiesInputStep from "./patientPastHistorySteps/PathologiesInputStep";
import PatientsDetail from "./PatientsDetail";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PatientStatusInternalMenu = (props) => {
  const [currentPatient, setCurrentPatient] = useState();
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [profileImage, setProfileImg] = useState(null);
  const { patId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const [isPreviousInjury, setIsPreviousInjury] = useState(false);
  const [isConsensus, setIsConsensus] = useState(false);
  const [permissionPatientMenu, setPermissionPatientMenu] = useState(false);
  const [atletheStatus, setAtletheStatus] = useState(false);

  const [spMenuLabels, setspMenuLabels] = useState([]);
  const { previousInjuries } = useFlags();

  const [isShowingUpdateSymbol, setIsShowingUpdateSymbol] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { setLoading } = props;

  let patientId = null;
  if (props.id) {
    patientId = props.id;
  } else {
    patientId = patId;
  }

  const uploadProfileImage = async (file) => {
    try {
      //setting up the file to be uploaded
      if (file) {
        await uploadFile(file);

        const result = await setUserProfileImage({
          id_patient: patientId,
          fileName: file.name,
          fileSize: file.size,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
        });
        if (result) {
          props.snackbarShowMessage(result.message);
          fetchData();
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("PatientsDetail - uploadProfileImage", error);
    }
  };

  const onFileChange = (e) => {
    uploadProfileImage(e.target.files[0]);
    fetchData();
  };

  const handleClick = (event) => {
    if (profileImage) {
      event.preventDefault();
      setProfileImg(null);
    }
  };

  const fetchData = async () => {
    try {
      const response = await findPatientByPk({ userId: patientId });
      setCurrentPatient(response);
      const resultImageProfile = await getUserProfileImage({
        id_patient: patientId,
      });
      if (resultImageProfile) {
        const profileBase64 = await getResourceFromServer({
          fileName: resultImageProfile.name,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }

      const regionsResult = await getRegions();
      regionsResult.length > 0 && setRegions(regionsResult);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchAreasByRegion = async () => {
    try {
      if (selectedRegion) {
        const currentAreas = await getAreasByRegion({
          id_region: regions.find((elem) => elem.key === selectedRegion).id,
        });
        setAreas(currentAreas);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    let tmpLabels = labels.patient.patientRegistry.steps;
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );

    const tempPreviousInjury = await getUserPermission(
      "previous-injuries",
      getAccessTokenSilently
    );

    const tempConsensus = await getUserPermission(
      "medical-history",
      getAccessTokenSilently
    );

    const tempPermissionPatientMenu = await getUserPermission(
      "patient-menu",
      getAccessTokenSilently
    );

    const tempPermissionPatientStatus = await getUserPermission(
      "status_atlethe",
      getAccessTokenSilently
    );

    setIsConsensus(tempConsensus);
    setIsPatientLabel(patientLabelTemp);
    setIsPreviousInjury(tempPreviousInjury);
    setPermissionPatientMenu(tempPermissionPatientMenu);
    setAtletheStatus(tempPermissionPatientStatus);

    if (!tempPreviousInjury || !tempPermissionPatientMenu || !previousInjuries)
      tmpLabels = tmpLabels.filter((item) => item.key !== "previous_injury");

    if (!tempPermissionPatientStatus)
      tmpLabels = tmpLabels.filter((item) => item.key !== "status_atlethe");

    if (!tempConsensus || !tempPermissionPatientMenu)
      tmpLabels = tmpLabels.filter((item) => item.key !== "phe_consensus");
    if (!tempPermissionPatientMenu) {
      tmpLabels = tmpLabels.filter((item) => item.key !== "summary");
      tmpLabels = tmpLabels.filter((item) => item.key !== "anagraphic");
      tmpLabels = tmpLabels.filter((item) => item.key !== "medical_history");
    }

    tmpLabels = tmpLabels.map((item) => item.value);

    setspMenuLabels(tmpLabels.map((t) => <div>{t}</div>));
  }, [previousInjuries]);

  useEffect(() => {
    fetchAreasByRegion(selectedRegion);
  }, [selectedRegion]);

  const LabelValue = ({ label, value }) => (
    <Grid
      direction="column"
      style={{ marginBottom: "5px", alignContent: "center" }}
      container
    >
      <SpText variant="text" style={{ alignSelf: "center" }}>
        {label}
      </SpText>
      <SpText variant="h4ComponentLabel" style={{ alignSelf: "center" }}>
        {value}
      </SpText>
    </Grid>
  );
  const { register, errors, control, getValues } = useForm({
    defaultValues: {
      patientName: "",
      birthDate: "",
      phone: "",
      job: "",
    },
    shouldUnregister: false,
  });

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );
  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid
        item
        container
        direction="row"
        style={{ margin: "auto 0", padding: 0 }}
      >
        <Grid item xs={12} style={{ margin: "auto 0", padding: 0 }}>
          <PatientsEditSidebar
            props={props}
            patId={patId}
            history={history}
            currentPatient={currentPatient}
            sectionName={
              isPatientLabel
                ? labels.patient.sideBarPatEdit.dettPaz.toUpperCase()
                : labels.patient.sideBarPatEdit.dettAthlete.toUpperCase()
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          style={{ paddingTop: "1em" }}
        >
          <Grid item xs={12} style={{ paddingTop: "1em" }}>
            <SpStepper
              //initialStep={initialStep}
              titles={spMenuLabels}
              stepperControls={StepperControls(errors)}
            >
              {atletheStatus && <PatientsDetail setLoading={setLoading} />}
              <PatientsRegistrySumup
                register={register}
                control={control}
                setLoading={setLoading}
              />
              <PatientsRegistryInput
                register={register}
                control={control}
                setLoading={setLoading}
              />

              <PastHistoryInputBasic
                register={register}
                control={control}
                idx={"navHistory"}
              />

              {isConsensus && (
                <PastHistoryInputStep register={register} control={control} />
              )}
              {isPreviousInjury && previousInjuries && (
                <PathologiesInputStep
                  id={"pashHistoryButton"}
                  register={register}
                  control={control}
                  setLoading={setLoading}
                />
              )}
            </SpStepper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientStatusInternalMenu);
