import { Button as MuiButton, Grid, Chip, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";

import SpSearch from "../../components/atoms/SpSearch";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { getGroupsById } from "../../models/actions/Groups";
import { rollbar, titleCase } from "../../utils/common";
import { labels, psTranslate } from "../shared/translations";
import { getFederationInjuries } from "../../models/actions/Team";
import moment from "moment";
const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const FederationInjuries = (props) => {
  const [currentGroup, setCurrentGroup] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamsSearch, setTeamSearch] = useState([]);
  const { groupId } = useParams();
  const { setLoading } = props;

  const history = useHistory();

  const fetchData = async () => {
    try {
      setLoading(true);

      const federationInjuries = await getFederationInjuries({
        id_federation: groupId,
      });
      setTeams(federationInjuries);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Columns
  const headCellsProfessional = [
    {
      width: "20%",
      id: "teamName",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.injuryList.team,
    },
    {
      width: "10%",
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.injuryList.startDate,
    },
    {
      width: "40%",
      id: "localization",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.injuryList.localization,
    },
    {
      width: "15%",
      id: "details",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.injuryList.pathology,
    },
    {
      width: "15%",
      id: "details",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.injuryList.details,
    },
  ];

  const filterProf = (e) => {
    setTeamSearch(
      teams.filter(({ team }) => team.name.includes(e.target.value))
    );
  };

  const GroupAddProfessionalRow = ({ row, index }) => {
    const { team, region, area, structure, type, pathology, start_date } = row;

    return (
      <>
        <SpTableRow role="checkbox" key={name} tabIndex={index}>
          <SpTableCell>
            <SpText id={`teamName${index}`} variant="tableTextDetails">
              {team.name}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText id={`startDate${index}`} variant="tableTextDetails">
              {moment(start_date).format("DD/MM/yyyy")}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText id={`localization${index}`} variant="tableTextDetails">
              {titleCase(
                psTranslate(
                  structure ? structure.name : area ? area.name : region.name
                )
              )}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText id={`details${index}`} variant="tableTextDetails">
              {pathology
                ? `${titleCase(psTranslate(pathology?.name))} [${
                    pathology?.osiics_code
                  }]`
                : "/"}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText id={`details${index}`} variant="tableTextDetails">
              {titleCase(psTranslate(type))}
            </SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>
          <Grid item xs={12} container spacing={1} direction="row">
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.groups.federationAdd.injuryList.title}
              </SpText>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <StyledRow>
                  <SpSearch
                    onKeyDown={prevent}
                    onChange={(e) => filterProf(e)}
                  />
                </StyledRow>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SpTable
                style={{ width: "100%" }}
                headCells={headCellsProfessional}
                rows={teamsSearch.length > 0 ? teamsSearch : teams}
                rowKey="id"
              >
                <GroupAddProfessionalRow />
              </SpTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(FederationInjuries);
