import Grid from "@material-ui/core/Grid";
import React from "react";
import { labels } from "../../../shared/translations";
import {
  DateSelection,
  FilterAutocomplete,
} from "./PatientsStatisticsAnalyticsFiltersHelper";
const constPadding = { paddingLeft: "8px", paddingRight: "8px" };
export const ActivityFilters = ({ filters, setFilters }) => {
  return (
    <>
      <Grid container direction="row" xs={12} style={{ padding: "8px" }}>
        <Grid item xs={3}>
          {/* Data di inizio  */}
          <DateSelection
            setFilters={setFilters}
            dateLabel="startDate"
            label={labels.analytics.account.startDate}
            filters={filters}
          />
        </Grid>

        {/* Data di fine */}
        <Grid item xs={3} style={constPadding}>
          <DateSelection
            setFilters={setFilters}
            dateLabel="endDate"
            label={labels.analytics.account.endDate}
            filters={filters}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters.activities
            }
            value={filters.activity.selectedActivites}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                activity: {
                  ...filters.activity,
                  selectedActivites: newValue,
                },
              })
            }
            renderOptions={filters.activity.selectedActivites}
            options={filters.activity.activities}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} direction="row">
        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters
                .study_parameters + " workload"
            }
            value={filters.activity.selectedStudyParams}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                activity: {
                  ...filters.activity,
                  selectedStudyParams: newValue,
                },
              })
            }
            renderOptions={filters.activity.selectedStudyParams}
            options={filters.activity.studyParams}
          />
        </Grid>

        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters
                .study_parameters +
              ` ${labels.patient.graphReport.section.proms.title}`
            }
            value={filters.activity.selectedStudyParamsPROMS}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                activity: {
                  ...filters.activity,
                  selectedStudyParamsPROMS: newValue,
                },
              })
            }
            renderOptions={filters.activity.selectedStudyParamsPROMS}
            options={filters.activity.studyParamsPROMS}
          />
        </Grid>

        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={`${labels.patient.graphReport.section.feedback.filters.study_parameters} ${labels.patient.graphReport.section.assessment.title}`}
            value={filters.activity.selectedMeasurements}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                activity: {
                  ...filters.activity,
                  selectedMeasurements: newValue,
                },
              })
            }
            renderOptions={filters.activity.selectedMeasurements}
            options={filters.activity.measurements}
          />
        </Grid>

        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={`${labels.patient.graphReport.section.feedback.filters.test} template`}
            value={filters.activity.selectedAssessmentsTemplates}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                activity: {
                  ...filters.activity,
                  selectedAssessmentsTemplates: newValue,
                },
              })
            }
            renderOptions={filters.activity.selectedAssessmentsTemplates}
            options={filters.activity.assessmentsTemplates}
          />
        </Grid>
      </Grid>
    </>
  );
};
