/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";
import {
  getGroupPatients,
  getHeader,
  getRange,
} from "../patientsReports/patientsReportInjury/InjuryReportCommonFunction";
import { psTranslate, labels } from "../../shared/translations";
import { selectPatientsFilteredByMonth } from "../patientsReports/patientsReportInjury/ExposureFunction";
import {
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Treemap,
  Legend,
} from "recharts";
import { getPatientInjury } from "../patientsReports/patientsReportInjury/InjuryReportCommonFunction";
import { ThresholdCard } from "../../groups/disponibility/cards_ava.jsx";
import { getPatientDisponibility } from "../../../models/actions/Disponibility";
import { theme } from "../../../components/theme.js";

// set data range
const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";
const trainingLabel = labels.analytics.injuryReport.graphs.training;
const partitaLabel = labels.analytics.injuryReport.graphs.partita;
const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;

// count function
function countBy(data, key) {
  const counts = {};
  data.forEach((item) => {
    if (item[key] !== null) {
      const value = item[key];
      counts[value] = (counts[value] || 0) + 1;
    }
  });

  const result = Object.keys(counts).map((key) => ({
    name: key,
    value: counts[key],
  }));

  return result;
}

const PatientsReportsStep5Exposure = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [patients, setPatients] = useState([]);

  const [patientInj, setPatientInj] = useState([]);
  const [patientInjSel, setPatientInjSel] = useState([]);
  const [dataChartTraining, setDataChartTraining] = useState({});
  const [dataChartMonth, setDataChartMonth] = useState({});

  const { patId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      // for patients
      const response = await listGroupsByProfessional();
      const result = getGroupPatients(response, patId);
      setPatients(result.allPatients);

      try {
        const dispon = await getPatientDisponibility({
          id_patients: [
            result?.allPatients.find(
              ({ id }) => parseInt(id) === parseInt(patId)
            ),
          ].map(({ id }) => id),
          start_date: dateRange.start,
          end_date: dateRange.end,
        });

        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        const injuryD = await getPatientInjury(tempGroupPatient, dateRange);
        const injurydata = [];
        for (let p of injuryD[0].patient) {
          injurydata.push(p?.patients);
        }
        const groupResults = injurydata.find(
          ({ id }) => parseInt(id) === parseInt(patId)
        );

        let groupsDataInj = [];
        // create dataset for visualization
        let patName = groupResults.givenName + " " + groupResults.familyName;

        for (let injIdx = 0; injIdx < groupResults?.injuries.length; injIdx++) {
          let injVal = groupResults?.injuries[injIdx];
          injVal["playersName"] = patName;
          let duration;
          if (injVal["end_date"] !== null) {
            duration = parseFloat(
              moment(injVal["end_date"]).diff(
                moment(injVal["start_date"]),
                "days"
              )
            );
          } else {
            injVal["end_date"] = moment();
            duration = parseFloat(
              moment().diff(moment(injVal["start_date"]), "days")
            );
          }
          injVal["duration"] = duration;
          let severity = "None";
          if (duration === 0) {
            severity = "No time loss";
          } else if (duration <= 3) {
            severity = labels.injSur.minlow;
          } else if (duration > 3 && duration <= 7) {
            severity = labels.injSur.low;
          } else if (duration > 7 && duration <= 28) {
            severity = labels.injSur.moderate;
          } else {
            severity = labels.injSur.severe;
          }
          injVal["severity"] = severity;

          if (injVal["region"]) {
            injVal["region"] = psTranslate(injVal?.region?.name);
          }
          if (injVal["structure"]) {
            injVal["structure"] = psTranslate(injVal?.structure?.name);
          }
          if (injVal["pathology"]) {
            injVal["pathology"] = psTranslate(injVal?.pathology?.name);
          }
          if (injVal["mechanism"]) {
            injVal["mechanism"] = psTranslate(
              labels.patient.presentation.add.stepper.step0.detail.mechanism.choise.find(
                ({ key }) => key === injVal["mechanism"]
              )?.value
            );
          }
          if (injVal["specific_action"]) {
            injVal["specific_action"] = psTranslate(
              labels.patient.presentation.add.stepper.step0.detail.specificAction.choise.find(
                ({ key }) => key === injVal["specific_action"]
              )?.value
            );
          }

          groupsDataInj.push(injVal);
        }

        setPatientInj(groupsDataInj);
        setPatientInjSel(groupsDataInj);

        setGroups(tempGroupPatient);
        // get allenamenti per ogni gruppo
        await selectExposureActivity(
          tempGroupPatient,
          dateRange,
          result.allPatients,
          groupsDataInj,
          dispon
        );
      } catch {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error.message);
    }
  }, []);

  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param tempPatients: patients
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    tempPatients = patients,
    groupsDataInj,
    tempDisponibilityPatients
  ) => {
    let temp = [];
    setSelectedGroups(mapGroup);
    setLoading(true);

    let injData;
    if (groupsDataInj) {
      injData = groupsDataInj;
    } else {
      injData = patientInj;
    }

    let injSel = [];
    for (let injury of injData) {
      let end_date;
      if (injury?.end_date === null) {
        end_date = moment().format("YYYY-MM-DD");
      } else {
        end_date = moment(injury?.end_date).format("YYYY-MM-DD");
      }
      let start_date;
      if (injury?.start_date === null) {
        start_date = moment().format("YYYY-MM-DD");
      } else {
        start_date = moment(newDateRange?.start_date).format("YYYY-MM-DD");
      }

      if (
        moment(end_date).isSame(
          moment(newDateRange?.end).format("YYYY-MM-DD")
        ) ||
        (moment(end_date).isBefore(
          moment(newDateRange?.end).format("YYYY-MM-DD")
        ) &&
          (moment(end_date).isAfter(
            moment(newDateRange?.start).format("YYYY-MM-DD")
          ) ||
            moment(end_date).isSame(
              moment(newDateRange?.start).format("YYYY-MM-DD")
            ))) ||
        moment(start_date).isSame(
          moment(newDateRange?.start).format("YYYY-MM-DD")
        ) ||
        (moment(start_date).isAfter(
          moment(newDateRange?.start).format("YYYY-MM-DD")
        ) &&
          (moment(start_date).isBefore(
            moment(newDateRange?.end).format("YYYY-MM-DD")
          ) ||
            moment(start_date).isSame(
              moment(newDateRange?.end).format("YYYY-MM-DD")
            )))
      ) {
        injSel.push(injury);
      }
    }

    setPatientInjSel(injSel);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
      });
      let item = {};
      item["idGruppo"] = tempGroup.id;
      item["nameGruppo"] = tempGroup.name;
      if (!newDateRange) newDateRange = dateRange;
      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );

      item[trainingLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "training"
      );

      item[partitaLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "race"
      );

      temp.push(item);
    }

    setSelectedGroups(mapGroup);
    setTrainingGroups(temp);
    const tempPat = tempPatients.find(({ id }) => id === parseInt(patId));

    const patAva = selectionActionFunction(
      mapGroup,
      temp,
      dateRange,
      tempDisponibilityPatients
    );
    setDataChartTraining(patAva);

    await setDataByMonthFunctionPatient(
      tempPat ? [tempPat] : [],
      temp,
      tempDisponibilityPatients
    );

    setLoading(false);
  };

  const setDataByMonthFunctionPatient = (
    patients,
    tempTrainingGroups = trainingGroups,
    tempDisponibilityPatients
  ) => {
    return new Promise((resolve, reject) => {
      try {
        let resultMonth = selectPatientsFilteredByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          trainingLabel,
          dateRange,
          patId,
          tempDisponibilityPatients
        );

        setDataChartMonth(resultMonth.dataChart);

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  const selectionActionFunction = (
    newSelectedGroups,
    tempTrainingGroups,
    dateRange,
    tempDisponibilityPatients
  ) => {
    let date_start = moment(dateRange?.start).format("YYYY-MM-DD");
    let date_end = moment(dateRange?.end).format("YYYY-MM-DD");

    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = newSelectedGroups[0]?.patients.find(
      ({ id }) => parseInt(id) === parseInt(patId)
    );

    // Find matching training group
    let groupsMatch = tempTrainingGroups[0][partitaLabel];
    let groupsTrain = tempTrainingGroups[0][trainingLabel];

    let patAva = [];
    for (let pat of [newPatientsSelected]) {
      let trainLen = 0;
      let trainLenPat = 0;
      for (let tr in groupsTrain) {
        if (groupsTrain.hasOwnProperty(tr)) {
          const dateStartFormatted = moment(dateRange.start).format(
            "YYYY-MM-DD"
          );
          const dateEndFormatted = moment(date_end).format("YYYY-MM-DD");
          const dateStartRangeFormatted =
            moment(date_start).format("YYYY-MM-DD");

          if (
            dateStartFormatted === dateEndFormatted ||
            dateStartFormatted === dateStartRangeFormatted ||
            (moment(dateRange.start).isBefore(date_end, "day") &&
              moment(dateRange.start).isAfter(date_start, "day"))
          ) {
            if (
              pat &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(pat?.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              trainLen += 1;
              let patient = groupsTrain[tr]?.patients.find(
                ({ id }) => parseInt(id) === parseInt(pat?.id)
              );
              trainLenPat += patient ? 1 : 0;
            }
          }
        }
      }

      let MatchLen = 0;
      let MatchLenPat = 0;
      for (let tr in groupsMatch) {
        if (groupsMatch.hasOwnProperty(tr)) {
          const dateStartFormatted = moment(dateRange.start).format(
            "YYYY-MM-DD"
          );
          const dateEndFormatted = moment(date_end).format("YYYY-MM-DD");
          const dateStartRangeFormatted =
            moment(date_start).format("YYYY-MM-DD");

          if (
            dateStartFormatted === dateEndFormatted ||
            dateStartFormatted === dateStartRangeFormatted ||
            (moment(dateRange.start).isBefore(date_end, "day") &&
              moment(dateRange.start).isAfter(date_start, "day"))
          ) {
            if (
              pat &&
              tempDisponibilityPatients.filter(
                ({ id_patient, date, patient_disponibility }) =>
                  parseInt(id_patient) === parseInt(pat?.id) &&
                  moment(date).format("YYYY-MM-DD") ===
                    moment(dateStartRangeFormatted).format("YYYY-MM-DD") &&
                  (patient_disponibility === "fit" ||
                    patient_disponibility === null)
              )
            ) {
              MatchLen += 1;
              let patient = groupsMatch[tr]?.patients.find(
                ({ id }) => parseInt(id) === parseInt(pat?.id)
              );
              MatchLenPat += patient ? 1 : 0;
            }
          }
        }
      }

      if (trainLenPat / MatchLenPat > 0) {
        patAva.push({
          patientName: pat?.givenName + " " + pat?.familyName,
          [trainingLabel]: trainLenPat,
          [trainingLabel + "_ava"]: parseFloat(
            (trainLenPat * 100) / (groupsTrain.length || 1)
          ).toFixed(1),
          [partitaLabel]: MatchLenPat,
          [partitaLabel + "_ava"]: parseFloat(
            (MatchLenPat * 100) / (groupsMatch.length || 1)
          ).toFixed(1),
          ratio: trainLenPat / MatchLenPat,
        });
      }
    }

    return patAva;
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      setSelectedGroups([]);
      await selectExposureActivity(groups, newDateRange);
      setSelectedGroups([]);
      await selectExposureActivity(groups, newDateRange);
      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  // Cards plot
  const Cards_ = () => {
    let allenamentoValue,
      allenamentoPer,
      partitaValue,
      partitaPer,
      parTrainValue,
      parTrainPer;
    if (dataChartTraining && dataChartTraining.length > 0) {
      allenamentoValue = dataChartTraining[0][trainingLabel];
      allenamentoPer = parseFloat(
        dataChartTraining[0][trainingLabel + "_ava"]
      ).toFixed(1);
      partitaValue = dataChartTraining[0][partitaLabel];
      partitaPer = parseFloat(
        dataChartTraining[0][partitaLabel + "_ava"]
      ).toFixed(1);
      parTrainValue = allenamentoValue + partitaValue;
      parTrainPer = parseFloat(
        (parseFloat(dataChartTraining[0][partitaLabel + "_ava"]) +
          parseFloat(dataChartTraining[0][trainingLabel + "_ava"])) /
          2
      ).toFixed(1);
    } else {
      allenamentoValue = 0;
      allenamentoPer = 0;
      partitaValue = 0;
      partitaPer = 0;
      parTrainValue = 0;
      parTrainPer = 0;
    }
    return (
      <>
        <Grid container item xs={12} direction="row" spacing={2}>
          <Grid item xs={4}>
            <ThresholdCard
              title={trainingLabel}
              description={""}
              value={allenamentoValue}
              perc={allenamentoPer}
              threshold1={40}
              threshold2={60}
              threshold3={90}
              ava={true}
            />
          </Grid>
          <Grid item xs={4}>
            <ThresholdCard
              title={partitaLabel}
              description={""}
              value={partitaValue}
              perc={partitaPer}
              threshold1={40}
              threshold2={60}
              threshold3={90}
              ava={true}
            />
          </Grid>
          <Grid item xs={4}>
            <ThresholdCard
              title={trainingLabel + " + " + partitaLabel}
              description={""}
              value={parTrainValue}
              perc={parTrainPer}
              threshold1={40}
              threshold2={60}
              threshold3={90}
              ava={true}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const ExpoPlotMonth = ({ data, lab, lab1 }) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{
              value: labels.injSur.yLabN,
              angle: -90,
              style: { fill: "grey" },
            }}
            labelOffset={-50}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={lab}
            stroke={theme.colors.surveillance.lightViolet}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey={lab1}
            stroke={theme.colors.surveillance.lightPink}
            fill="url(#colorUv2)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const ExpoPlotMonthRatio = ({ data, lab }) => {
    const maxYValue = Array.isArray(data)
      ? Math.max(...data.map((item) => item[lab]))
      : 0;
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUvarea" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{ value: lab, angle: -90, style: { fill: "grey" } }}
            domain={[0, maxYValue + 1]}
            labelOffset={-50}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={lab}
            stroke={theme.colors.surveillance.lightBlue}
            fillOpacity={1}
            fill="url(#colorUvarea)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const TrainingReportA = (props) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={props.dataChartT}>
          <defs>
            <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{
              value: labels.injSur.yLabP,
              angle: -90,
              style: { fill: "grey" },
            }}
            labelOffset={-50}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
            formatter={(value, name) => [value.toFixed(1), name]}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={props.lab + ["_ava"]}
            stroke={theme.colors.surveillance.violet}
            fill="url(#colorUv1)"
          />
          <Area
            type="monotone"
            dataKey={props.lab1 + ["_ava"]}
            stroke="#ff668c"
            fill="url(#colorUv3)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  // Treemap plot
  const TreeMapChart = ({ data }) => {
    // Initialize max value with negative infinity
    let maxValue = 0;

    // Iterate over the array to find the maximum value
    data.forEach((item) => {
      if (item.value > maxValue) {
        maxValue = item.value;
      }
    });

    return (
      <ResponsiveContainer width={"95%"} height={200}>
        <Treemap
          width={"80%"}
          height={"90%"}
          data={data}
          dataKey="value"
          ratio={4 / 4}
          stroke="#ffffff"
          fill="#3d988e20"
          content={<CustomizedLabel maxValue={maxValue} />}
        >
          <Tooltip
            formatter={(value, name, props) => [
              `${props.payload.name}: ${value}`,
              "",
            ]}
            contentStyle={{ fontSize: "15px" }}
          />
        </Treemap>
      </ResponsiveContainer>
    );
  };

  // CustomizedLabel component to render label inside rectangle
  const CustomizedLabel = ({
    depth,
    x,
    y,
    width,
    height,
    name,
    value,
    maxValue,
  }) => {
    const fontSize = Math.min(width, height) / 8; // Adjust font size based on rectangle size
    if (depth === 1) {
      if (value > 0) {
        const textX = x + width / 2;
        const textY = y + height / 2;
        let displayName = "";
        if (width * 4 > height) {
          displayName = name.split(" ");
        } else {
          displayName = [name];
        }
        const alpha = (value / maxValue).toFixed(2) * 1.5; // Calculate alpha value based on the ratio of value to maxValue
        const fillColor = `rgba(152, 59, 69, ${alpha})`; // Set fill color with calculated alpha
        return (
          <>
            <rect x={x} y={y} width={width} height={height} fill={fillColor} />
            {displayName.map((part, index) => (
              <text
                key={index}
                x={textX}
                y={textY + (index - (displayName.length - 1) / 2) * fontSize}
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={fontSize}
                style={{
                  fontFamily: "Ariel",
                  fill: "#FFFFFF",
                  fontWeight: "light",
                  stroke: "None",
                  strokeWidth: 0,
                }}
              >
                {part}
              </text>
            ))}
          </>
        );
      } else {
        return (
          <>
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              fill={`rgba(152, 59, 69, 0)`}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <rect x={x} y={y} width={width} height={height} />
        </>
      );
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      <Grid container item xs={12}>
        <h1 style={{ color: "#31ccad" }}> {labels.injSur.genInj} </h1>
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.severity} </h2>
          <TreeMapChart data={countBy(patientInjSel, "severity")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.region} </h2>
          <TreeMapChart data={countBy(patientInjSel, "region")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.structure} </h2>
          <TreeMapChart data={countBy(patientInjSel, "structure")} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.patology} </h2>
          <TreeMapChart data={countBy(patientInjSel, "pathology")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.mechanism} </h2>
          <TreeMapChart data={countBy(patientInjSel, "mechanism")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.actType} </h2>
          <TreeMapChart data={countBy(patientInjSel, "specific_action")} />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <h1 style={{ color: "#31ccad" }}> {labels.injSur.expAva} </h1>
        <Cards_ />
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
          spacing={2}
        >
          <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
            {labels.injSur.athExp}
          </SpText>
          <ExpoPlotMonth
            data={dataChartMonth}
            lab={trainingLabel}
            lab1={partitaLabel}
          />
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
          spacing={2}
        >
          <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
            {labels.injSur.athRatio}
          </SpText>
          <ExpoPlotMonthRatio data={dataChartMonth} lab={ratioTMLabel} />
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
          spacing={2}
        >
          <SpText variant="h4ComponentLabel" style={{ fontSize: "2em" }}>
            {labels.injSur.athDisp}
          </SpText>
          <TrainingReportA
            dataChartT={dataChartMonth}
            lab={trainingLabel}
            lab1={partitaLabel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
