const data = [
    { name: '01/10', Doms: 5, Fatica: 4, Sonno: 3, Stress: 2, RPE: 7, Tempo: 90 },
    { name: '02/10', Doms: 7, Fatica: 6, Sonno: 3, Stress: 2, RPE: 7, Tempo: 75 },
    { name: '03/10', Doms: 6, Fatica: 7, Sonno: 2, Stress: 3, RPE: 6, Tempo: 60 },
    { name: '04/10', Doms: 3, Fatica: 3, Sonno: 2, Stress: 3, RPE: 4, Tempo: 48 },
    { name: '05/10', Doms: 2, Fatica: 2, Sonno: 2, Stress: 2, RPE: 2, Tempo: 40 },
    { name: '06/10', Doms: 1, Fatica: 1, Sonno: 2, Stress: 3, RPE: 8, Tempo: 100 },
    { name: '07/10', Doms: 7, Fatica: 6, Sonno: 3, Stress: 2, RPE: 0, Tempo: 0 },
    { name: '08/10', Doms: 4, Fatica: 5, Sonno: 2, Stress: 3, RPE: 8, Tempo: 70 },
    { name: '09/10', Doms: 5, Fatica: 5, Sonno: 3, Stress: 2, RPE: 7, Tempo: 70 },
    { name: '10/10', Doms: 5, Fatica: 4, Sonno: 2, Stress: 3, RPE: 6, Tempo: 80 },
    { name: '11/10', Doms: 2, Fatica: 2, Sonno: 1, Stress: 2, RPE: 4, Tempo: 60 },
    { name: '12/10', Doms: 2, Fatica: 2, Sonno: 2, Stress: 4, RPE: 2, Tempo: 50 },
    { name: '13/10', Doms: 1, Fatica: 2, Sonno: 2, Stress: 2, RPE: 7, Tempo: 95 },
    { name: '14/10', Doms: 5, Fatica: 4, Sonno: 2, Stress: 2, RPE: 0, Tempo: 0 },
    { name: '15/10', Doms: 4, Fatica: 3, Sonno: 1, Stress: 3, RPE: 6, Tempo: 70 },
    { name: '16/10', Doms: 4, Fatica: 6, Sonno: 3, Stress: 1, RPE: 5, Tempo: 70 },
    { name: '17/10', Doms: 5, Fatica: 7, Sonno: 1, Stress: 1, RPE: 5, Tempo: 80 },
    { name: '18/10', Doms: 3, Fatica: 5, Sonno: 2, Stress: 3, RPE: 4, Tempo: 70 },
    { name: '19/10', Doms: 2, Fatica: 2, Sonno: 2, Stress: 3, RPE: 3, Tempo: 60 },
    { name: '20/10', Doms: 1, Fatica: 1, Sonno: 3, Stress: 2, RPE: 8, Tempo: 98 },
    { name: '21/10', Doms: 6, Fatica: 5, Sonno: 2, Stress: 2, RPE: 0, Tempo: 0 },
    { name: '22/10', Doms: 5, Fatica: 3, Sonno: 3, Stress: 3, RPE: 4, Tempo: 72 },
    { name: '23/10', Doms: 5, Fatica: 5, Sonno: 3, Stress: 2, RPE: 5, Tempo: 65 },
    { name: '24/10', Doms: 7, Fatica: 6, Sonno: 3, Stress: 3, RPE: 5, Tempo: 70 },
    { name: '25/10', Doms: 4, Fatica: 4, Sonno: 2, Stress: 2, RPE: 4, Tempo: 60 },
    { name: '26/10', Doms: 2, Fatica: 4, Sonno: 2, Stress: 1, RPE: 2, Tempo: 60 },
    { name: '27/10', Doms: 2, Fatica: 2, Sonno: 1, Stress: 2, RPE: 9, Tempo: 80 },
    { name: '28/10', Doms: 7, Fatica: 4, Sonno: 3, Stress: 2, RPE: 0, Tempo: 0 },
    { name: '28/10', Doms: 3, Fatica: 2, Sonno: 2, Stress: 1, RPE: 6, Tempo: 90 },
    { name: '28/10', Doms: 7, Fatica: 7, Sonno: 4, Stress: 5, RPE: 6, Tempo: 70 },
];

export default data;
