import React from "react";
import { labels } from "../../../shared/translations";
import { ThresholdCard } from "../../../../components/atoms/ThresholdCard";
import { Grid } from "@material-ui/core";

const Cards = ({ data }) => {
  data.forEach((entry) => {
    entry.TL = entry.RPE * entry.Tempo;
  });

  // Find the entry with the maximum value
  const maxTL = data.reduce(
    (max, entry) => (entry["TL"] > max["TL"] ? entry : max),
    data[0]
  );

  // Extract the last 7 values of the specified property
  const last7TLValues = data.slice(-7).map((entry) => entry["TL"]);
  const last28TLValues = data.slice(-28).map((entry) => entry["TL"]);

  // Calculate the mean
  const meanTL7 =
    last7TLValues.reduce((sum, value) => sum + value, 0) / last7TLValues.length;
  const meanTL28 =
    last28TLValues.reduce((sum, value) => sum + value, 0) /
    last28TLValues.length;

  // acwr
  const acwrTL = meanTL7 / meanTL28;

  // Function to calculate the mean of an array
  const calculateMean = (arr) =>
    arr.reduce((sum, value) => sum + value, 0) / arr.length;

  // Function to calculate the standard deviation of an array
  const calculateStandardDeviation = (arr) => {
    const mean = calculateMean(arr);
    const squaredDifferences = arr.map((value) => Math.pow(value - mean, 2));
    const variance = calculateMean(squaredDifferences);
    const standardDeviation = Math.sqrt(variance);
    return standardDeviation;
  };

  // Calculate the standard deviation
  const standardDeviationTL7 = calculateStandardDeviation(
    data.map((entry) => entry["TL"])
  );

  // Calculate monotony
  const monotonyTL = meanTL7 / standardDeviationTL7;

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={3}>
        <ThresholdCard
          title="Wellness"
          description="Hooper index"
          value={data[data.length - 1]?.HI || 0}
          threshold1={8}
          threshold2={19}
          threshold3={23}
        />
      </Grid>
      <Grid item xs={3}>
        <ThresholdCard
          title="Training load"
          description="sRPE"
          value={data[data.length - 1]?.TL || 0}
          threshold1={maxTL ? maxTL?.TL * 0.6 : 0}
          threshold2={maxTL ? maxTL?.TL * 0.8 : 0}
          threshold3={maxTL ? maxTL?.TL * 0.9 : 0}
        />
      </Grid>
      <Grid item xs={3}>
        <ThresholdCard
          title="ACWR"
          description="sRPE"
          value={(acwrTL || 0).toFixed(1)}
          threshold1={1}
          threshold2={1.5}
          threshold3={2}
        />
      </Grid>
      <Grid item xs={3}>
        <ThresholdCard
          title="Monotony"
          description="sRPE"
          value={(monotonyTL || 0).toFixed(1)}
          threshold1={1}
          threshold2={1.5}
        />
      </Grid>
    </Grid>
  );
};

export default Cards;
