import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import SpText from "../../../../components/atoms/SpText";
import { labels } from "../../../shared/translations";
import { theme } from "../../../../components/theme";

const LabelValue = ({ label, value }) =>
  value ? (
    <Grid direction="column" xs={6} style={{ marginBottom: "5px" }}>
      <SpText variant="text">{label}</SpText>
      <SpText variant="h4ComponentLabel">{value}</SpText>
    </Grid>
  ) : null;

const CommonDetails = ({ rows }) => {
  return (
    <Grid container xs={12}>
      <Divider
        style={{
          padding: "1px",
          width: "100%",
          backgroundColor: theme.colors.primary.lightBlue,
          marginTop: "1%",
          marginBottom: "1%",
        }}
      />
      {rows.map((item) => (
        <Grid
          key={item.id}
          container
          xs={4}
          style={{
            border: `1px solid ${theme.colors.primary.lightBlue}`,
            padding: "8px",
          }}
          direction="row"
        >
          <LabelValue
            label={labels.mylab.tempAdd.treatment.list.header[0]}
            value={item.activity}
          />
          <LabelValue
            label={labels.mylab.tempAdd.treatment.list.header[1]}
            value={item.duration}
          />
          <LabelValue
            label={labels.mylab.tempAdd.treatment.list.header[2]}
            value={item.description}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CommonDetails;

