import React from "react";
import { Redirect, Route } from "react-router-dom";

export const NextDirection = ({
  isAuthenticated,
  permission,
  isTokenSet,
  isFederation,
  pathname,
  getLoadingButton,
  rest,
  children,
}) => {
  if (isAuthenticated && permission) {
    if (isTokenSet) {
      if (
        isFederation &&
        !pathname.includes("federations") &&
        !pathname.includes("account")
      ) {
        return <Redirect to={{ pathname: "/federations" }} />;
      }
      // check per controllare se l'utente ha i permessi per accedere a quella pagina
      // altrimenti si viere reindirizzati verso la dashboard
      return <Route {...rest}>{children}</Route>;
    }

    return getLoadingButton();
  }
  return <Redirect to={{ pathname: "/login-auth0" }} />;
};
