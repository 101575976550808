import React from "react";
import { labels, psTranslate } from "../../../../../shared/translations";
import Grid from "@material-ui/core/Grid";
import { SpAutocomplete } from "../../../../../../components/atoms/SpAutocomplete";

export const TestSection = ({
  ruleInputs,
  setRuleInputs,
  ruleSelector,
  allRules,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <SpAutocomplete
          id={"selectParameter"}
          formControlWidth={"100%"}
          value={
            allRules[ruleSelector.selected]?.test.find(
              (el) => el === ruleInputs?.test
            ) ?? ""
          }
          disableListWrap
          label={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .testName
          }
          selectPlaceholder={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .selParam
          }
          onChange={(e, newValue) => {
            setRuleInputs({
              ...ruleInputs,
              test: newValue,
            });
          }}
          options={allRules[ruleSelector.selected]?.test}
          getOptionLabel={({ name }) => psTranslate(name)}
          getOptionSelected={({ id: id_opt }, { id: id_value }) =>
            id_opt === id_value
          }
        />
      </Grid>
      {/* parametro */}
      <Grid item xs={12}>
        <SpAutocomplete
          id={"selectSubParameter"}
          formControlWidth={"100%"}
          value={
            allRules[ruleSelector.selected]?.parameters.find(
              (el) => el === ruleInputs?.parameter
            ) ?? ""
          }
          disableListWrap
          label={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .parameter
          }
          selectPlaceholder={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .selSubparam
          }
          onChange={(e, newValue) => {
            setRuleInputs({
              ...ruleInputs,
              parameter: newValue,
            });
          }}
          options={allRules[ruleSelector.selected]?.parameters?.filter(
            ({ idParamRef }) => idParamRef === ruleInputs?.test?.id
          )}
          getOptionLabel={({ name }) => psTranslate(name)}
          getOptionSelected={({ id: id_opt }, { id: id_value }) =>
            id_opt === id_value
          }
        />
      </Grid>
      {/* sottoparametro */}
      <Grid item xs={12}>
        <SpAutocomplete
          id={"selectSubParameter"}
          formControlWidth={"100%"}
          value={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition.study.find(
              ({ key }) => key === ruleInputs?.subparameter
            ) ?? ""
          }
          disableListWrap
          label={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .subparameter
          }
          selectPlaceholder={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .selSubparam
          }
          onChange={(e, newValue) => {
            setRuleInputs({
              ...ruleInputs,
              subparameter: newValue.key,
            });
          }}
          options={
            labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
              .study
          }
          getOptionLabel={({ label }) => psTranslate(label)}
          getOptionSelected={({ key: key_opt }, { key: key_value }) =>
            key_opt === key_value
          }
        />
      </Grid>
    </>
  );
};
