import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { getAssessmentTestById } from "../../../models/actions/Activity";
import { labels } from "../../shared/translations";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const LabelValue = ({ label, value }) =>
  value ? (
    <Grid direction="column" xs={12} style={{ marginBottom: "5px" }}>
      <SpText variant="text">{label}</SpText>
      <SpText variant="h4ComponentLabel">{value}</SpText>
    </Grid>
  ) : null;

const AssessmentTestDetails = ({ setLoading, ...props }) => {
  const [assessmentTest, setAssessmentTest] = useState();

  const history = useHistory();
  const { idElement } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      const result = await getAssessmentTestById({
        id_assessment_test: idElement,
      });
      setAssessmentTest(result);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  }, []);

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <TitleToolbarSection>
          <ArrowBack
            onClick={() => history.goBack()}
            style={{
              width: 30,
              color: theme.colors.primary.lightBlue,
              height: 50,
              cursor: "pointer",
            }}
          />
          <SpText variant="h1">
            {labels.mylab.tempDetail.backButtonList + " / "}{" "}
            <span style={{ color: theme.colors.primary.white }}>
              {labels.mylab.tempDetail.assessmentTest.title}
            </span>
          </SpText>
        </TitleToolbarSection>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
          marginTop: "25px",
        }}
        direction="row"
      >
        <LabelValue
          label={labels.mylab.tempAdd.assessmentTest.headers.name}
          value={assessmentTest?.name}
        />
        <LabelValue
          label={labels.mylab.tempAdd.assessmentTest.headers.test}
          value={assessmentTest?.test}
        />
        <LabelValue
          label={labels.mylab.tempAdd.assessmentTest.headers.tag}
          value={assessmentTest?.tag}
        />
        <LabelValue
          label={labels.mylab.tempAdd.assessmentTest.headers.type}
          value={assessmentTest?.type}
        />
        <LabelValue
          label={labels.mylab.tempAdd.assessmentTest.headers.content}
          value={assessmentTest?.content}
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(AssessmentTestDetails);
