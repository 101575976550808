/* eslint-disable import/order */
import React, { useState, useEffect } from "react";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import SpText from "../../../components/atoms/SpText";
import { deletePatient } from "../../../models/actions/Patients";
import { ArrowBack } from "@material-ui/icons";
import { getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SpLoader from "../../../components/atoms/SpLoader";
import { titleCase } from "../../../utils/common";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import LogoUrl from "../../../assets/icon/iconSymbol.png";
import { useFlags } from "launchdarkly-react-client-sdk";

const drawerWidth = "15em";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#20262E",
    width: drawerWidth,
  },
}));

const SidebarNavColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

import {
  getPatientSidebarRoute,
  routesPatients,
} from "./PatientsEditSidebarRoutes";
import SpIconButton, {
  SpIconButtonMenu,
} from "../../../components/atoms/SpIconButton";
import { LogoMenu } from "../../shared/logo/style";
import { theme } from "../../../components/theme";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const PatientsEditSidebar = ({
  patId,
  history,
  props,
  currentPatient,
  sectionName,
}) => {
  const [statisticsPermission, setStatisticsPermission] = useState(false);
  const [analyticsPermission, setAnalyticsPermission] = useState(false);
  const [medicalNotesPermission, setMedicalNotesPermission] = useState(false);
  const [isChartEnabled, setIsChartEnabled] = useState(false);
  const [isPhysioResourcesEnabled, setIsPhysioResourcesEnabled] =
    useState(false);
  const [isMedicalResourcesEnabled, setIsMedicalResourcesEnabled] =
    useState(false);
  const [isAlertEnabled, setIsAlertEnabled] = useState(false);
  const [patientMenu, setPatientMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const { medicalNotes, statisticsAnalytics, statistics, analytics } =
    useFlags();

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    setLoading(true);
    const tempStatisticPermission = await getUserPermission(
      "manage:patient-statistics",
      getAccessTokenSilently
    );
    const tempAnalyticPermission = await getUserPermission(
      "manage:patient-analytics",
      getAccessTokenSilently
    );
    const tempMedicalNotesPermission = await getUserPermission(
      "manage:medical-notes",
      getAccessTokenSilently
    );

    const tempChartPermission = await getUserPermission(
      "manage:chart",
      getAccessTokenSilently
    );

    const tempAlertPermission = await getUserPermission(
      "manage:alert",
      getAccessTokenSilently
    );

    const patientMenuTemp = await getUserPermission(
      "patient-menu",
      getAccessTokenSilently
    );

    setPatientMenu(patientMenuTemp);

    setIsAlertEnabled(tempAlertPermission);

    setIsChartEnabled(tempChartPermission);

    const tempPhysioResourcesPermission = await getUserPermission(
      "manage:physio-resources",
      getAccessTokenSilently
    );
    const tempMedicalResourcesPermission = await getUserPermission(
      "manage:medical-resources",
      getAccessTokenSilently
    );

    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );

    setIsPatientLabel(patientLabelTemp);

    setIsPhysioResourcesEnabled(tempPhysioResourcesPermission);
    setIsMedicalResourcesEnabled(tempMedicalResourcesPermission);
    setStatisticsPermission(tempStatisticPermission);
    setAnalyticsPermission(tempAnalyticPermission);
    setMedicalNotesPermission(tempMedicalNotesPermission);
    setLoading(false);
  }, []);

  const getTextVariant = (permission, path) => {
    if (!permission) return "disable";
    if (location.pathname !== path) return `sidebarItem`;
  };
  const [storedValue, setStoredValue] = useState();

  useEffect(() => {
    setLoading(true);
    setStoredValue(props.getStep());
    setLoading(false);
  }, []);

  useEffect(() => {}, [storedValue]);

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      {storedValue?.location && storedValue?.componentName && (
        <Grid item xs={12} style={{ paddinLeft: "8px" }}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => {
                history.push(storedValue?.location);
              }}
              style={{
                width: 15,
                color: theme.colors.primary.lightBlue,
                height: 15,
                cursor: "pointer",
              }}
            />
            <SpText
              onClick={() => history.push(storedValue?.location)}
              variant="h4ComponentLabelColored"
              style={{
                marginRight: "5px",
                textDecoration: "underline",
                textDecorationThickness: "2px",
                textUnderlineOffset: "3px",
              }}
            >{`${titleCase(storedValue?.componentName)} `}</SpText>
            <SpText
              onClick={() => history.push("/patients")}
              variant="h4ComponentLabel"
            >{`/ ${titleCase(
              currentPatient?.givenName ? currentPatient?.givenName : ""
            )} ${titleCase(
              currentPatient?.familyName ? currentPatient?.familyName : ""
            )}`}</SpText>
            {patientMenu && (
              <SpIconButtonMenu onMouseEnter={() => setIsDrawerOpen(true)}>
                <MenuIcon style={{ color: "#31caad" }} id="patientsMenu" />
              </SpIconButtonMenu>
            )}
          </TitleToolbarSection>
        </Grid>
      )}
      {!storedValue?.location && !storedValue?.componentName && (
        <Grid item xs={12} style={{ paddinLeft: "8px" }}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/patients")}
              style={{
                width: 15,
                color: theme.colors.primary.lightBlue,
                height: 15,
                cursor: "pointer",
              }}
            />
            <SpText
              onClick={() => history.push("/patients")}
              variant="h4ComponentLabelColored"
              style={{
                marginRight: "5px",
                textDecoration: "underline",
                textDecorationThickness: "2px",
                textUnderlineOffset: "3px",
              }}
            >{`${titleCase(
              isPatientLabel
                ? labels.nav.patients
                : labels.patient.component.backButtonLista
            )} `}</SpText>

            <SpText
              variant="h4ComponentLabelColored"
              style={{ marginRight: "5px" }}
            >
              {`/  ${titleCase(sectionName)} / `}
            </SpText>
            <SpText
              onClick={() => history.push("/patients")}
              variant="h4ComponentLabel"
            >{`${titleCase(
              currentPatient?.givenName ? currentPatient?.givenName : ""
            )} ${titleCase(
              currentPatient?.familyName ? currentPatient?.familyName : ""
            )}`}</SpText>
            {patientMenu && (
              <SpIconButtonMenu onMouseEnter={() => setIsDrawerOpen(true)}>
                <MenuIcon style={{ color: "#31caad" }} id="patientsMenu" />
              </SpIconButtonMenu>
            )}
          </TitleToolbarSection>
        </Grid>
      )}

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        SlideProps={{
          timeout: { enter: 300, exit: 200 },
        }}
      >
        <SidebarNavColumn>
          <Grid
            container
            xs={12}
            style={{ alignContent: "center" }}
            direction="row"
          >
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "4em", paddingTop: "1em" }}
            >
              <LogoMenu id={"logoImage"} src={LogoUrl} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {}
            <SpIconAndTextButton
              id={"navStatus"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.status, patId)
              )}
              onClick={() => {
                props.prevStep(null, null);
                history.push(
                  getPatientSidebarRoute(routesPatients.status, patId)
                );
              }}
              text={
                isPatientLabel
                  ? labels.patient.sideBarPatEdit.dettPaz.toUpperCase()
                  : labels.patient.sideBarPatEdit.dettAthlete.toUpperCase()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navActivityPlan"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.plan, patId)
              )}
              onClick={() => {
                props.prevStep(null, null);
                history.push(
                  getPatientSidebarRoute(routesPatients.plan, patId)
                );
              }}
              text={labels.patient.sideBarPatEdit.viewPlan.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navMonitoring"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.monitoring, patId)
              )}
              onClick={() => {
                props.prevStep(null, null);
                history.push(
                  getPatientSidebarRoute(routesPatients.monitoring, patId)
                );
              }}
              text={labels.patient.sideBarPatEdit.monit.toUpperCase()}
            />
          </Grid>
          {(isChartEnabled ||
            isAlertEnabled ||
            statisticsAnalytics ||
            statistics ||
            statisticsPermission ||
            analyticsPermission ||
            analytics) && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"controlRoom"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.controlRoom, patId)
                )}
                onClick={() => {
                  props.prevStep(null, null);
                  history.push(
                    getPatientSidebarRoute(routesPatients.controlRoom, patId)
                  );
                }}
                text={labels.patient.sideBarPatEdit.controlRoom.toUpperCase()}
              />
            </Grid>
          )}
          {medicalNotes && medicalNotesPermission && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navMedicalNotes"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.medicalNotes, patId)
                )}
                onClick={() => {
                  props.prevStep(null, null);
                  history.push(
                    getPatientSidebarRoute(routesPatients.medicalNotes, patId)
                  );
                }}
                text={labels.patient.sideBarPatEdit.medicalNotes.toUpperCase()}
              />
            </Grid>
          )}
          {(isMedicalResourcesEnabled || isPhysioResourcesEnabled) && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navResources"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.medicalResource, patId)
                )}
                onClick={() => {
                  props.prevStep(null, null);
                  history.push(
                    getPatientSidebarRoute(
                      routesPatients.medicalResource,
                      patId
                    )
                  );
                }}
                text={labels.patient.sideBarPatEdit.medicalRis.toUpperCase()}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navDiary"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.diary, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.diary, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.diary.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navSettings"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.settings, patId)
              )}
              onClick={() => {
                props.prevStep(null, null);
                history.push(
                  getPatientSidebarRoute(routesPatients.settings, patId)
                );
              }}
              text={labels.patient.sideBarPatEdit.settings.toUpperCase()}
            />
          </Grid>
        </SidebarNavColumn>
      </Drawer>
    </>
  );
};

export default PatientsEditSidebar;
