import React, { forwardRef, useEffect, useState } from "react";
import { labels } from "../../../../../shared/translations";
// material ui
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
// models
import {
  getAssessmentPartsElementsAndCategories,
  getAssessmentTemplateDetail,
  getAssessmentTemplates,
} from "../../../../../../models/actions/Assessment";
import { getFeedbackParameters } from "../../../../../../models/actions/GenericRiskFactor";
import {
  calculatePromScore,
  getAllPromsNames,
  getPromsRiskFactorDefault,
} from "../../../../../../models/actions/Proms";
// components
import { styled } from "../../../../../../components/styled";
import { theme } from "../../../../../../components/theme";
// atoms
import SpButton from "../../../../../../components/atoms/SpButton";
import SpCheckbox from "../../../../../../components/atoms/SpCheckbox";
import SpIconButton from "../../../../../../components/atoms/SpIconButton";
import SpText from "../../../../../../components/atoms/SpText";
import { RuleComposition } from "./genericRiskFactorCompAdd_RS_ruleComposition";
import { StrDysfFilter } from "./genericRiskFactorCompAdd_RS_strDysfFilter";
// utils
import { handleNewRule } from "../../../genericRiskFactorFunction";
// mocks

const StyledGridContainerA = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "1%",
  paddingTop: "2%",
});

const StyledGridContainerB = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
});

export const RuleSection = forwardRef(
  ({
    structures,
    dysfunctions,
    dysfunctionsType,
    inputValues,
    setInputValues,
    filterLocks,
    setFilterLocks,
    ruleInputs,
    setRuleInputs,
    ruleAdded,
    setRuleAdded,
    thresholdRef,
    setThresholdRef,
    thresholdRef2,
    setThresholdRef2,
  }) => {
    const rulesSelectable = ["workload", "prom", "assessment", "test"];
    const [ruleSelector, setRuleSelector] = useState({
      selected: rulesSelectable[0],
    });
    const [allRules, setAllRules] = useState({
      workload: {
        parameters: [],
        subparameters: [],
      },
      prom: {
        parameters: [],
        subparameters: [],
      },
      assessment: {
        anatomicalParts: [],
        parameters: [],
        subparameters: [],
        triads: [],
      },
      test: {
        parameters: [],
        subparameters: [],
        test: [],
      },
    });
    const [filteredTriads, setFilteredTriads] = useState([]);
    const [filteredElements, setFilteredElements] = useState([]);

    const logicals = Object.values(
      labels.mylab.generalRiskFactor.addComp.ruleSection.ruleComposition
        .ruleLogicals
    );

    useEffect(() => {
      if (ruleSelector.selected === rulesSelectable[2]) {
        /* ottengo le triadi di ID filtrate per id_categoria */
        const resFilterdTriads = allRules[
          ruleSelector.selected
        ]?.triads?.filter(({ id_c }) => id_c === ruleInputs?.parameter?.id);
        setFilteredTriads(resFilterdTriads);

        /* ricavo le info complete dei sottoparametri filtrati */
        const subparamAssessment = allRules[
          ruleSelector.selected
        ].subparameters?.flatMap((sub) =>
          sub.ids?.filter((e) =>
            resFilterdTriads.map(({ id_e }) => id_e).includes(e)
          ).length > 0
            ? sub
            : []
        );
        setFilteredElements(subparamAssessment);
      }
    }, [ruleInputs.parameter]);

    useEffect(() => {
      if (ruleSelector.selected === rulesSelectable[2]) {
        /* ottengo gli id delle parti anatomiche coinvolte */
        const resFilterAnatomicalIDs = filteredTriads.flatMap(
          ({ id_e, id_p }) =>
            ruleInputs?.subparameter?.ids?.includes(id_e) ? id_p : []
        );

        /* recupero le info per quelle parti */
        const anatomicalAssessment = allRules[
          ruleSelector.selected
        ].anatomicalParts?.filter(({ id }) =>
          resFilterAnatomicalIDs.includes(id)
        );
        setRuleInputs({ ...ruleInputs, anatomicalParts: anatomicalAssessment });
      }
    }, [ruleInputs.subparameter]);

    const fetchData = async () => {
      /* WORKLOAD / FEEDBACK */

      /**
       * @type {array}
       * @example of resFeedback's entry
       * {
       * "id":1,
       * "id_category_load":1,
       * "name":"Frequenzacardiacamedia",
       * "description":null,
       * "um":"BPM",
       * "key":"freqcardmedia",
       * "createdAt":"2030-08-21T13:54:00.000Z",
       * "updatedAt":"2030-08-21T13:54:00.000Z"
       * }
       */
      const resFeedback = await getFeedbackParameters();

      let tempSubFeed = [];
      for (let index = 0; index < resFeedback.length; index++) {
        tempSubFeed.push({
          name: "ACWR",
          id: -resFeedback.length + index, // NOTE: per ora fittizio
          idParamRef: resFeedback[index].id,
        });
        tempSubFeed.push({
          name: "Monotony",
          id: -resFeedback.length + index, // NOTE: per ora fittizio
          idParamRef: resFeedback[index].id,
        });
      }

      /* PROMS */
      const { promPara, promSub } = await getPromsRiskFactorDefault();

      /* ASSESSMENTS */

      const resAssessm = await getAssessmentPartsElementsAndCategories({
        forGrf: true,
      });
      // aggiungo la chiave 'key' ad ogni sezione dell'oggetto resAssessm
      resAssessm.parts.map(({ key, id }) => {
        key = id;
      });
      resAssessm.categories.map(({ key, id }) => {
        key = id;
      });
      /* N.B.: Nella sezione degli ELEMENTS sono presenti più chiavi
      e non esiste un ID univoco per ogni nome. Per ricavarne uno
      si deveno incrociare id_assessment_category + id_assessment.
      Per ricavarli esiste una chiave 'elements.triads' che contiene
      le triplette univoche tra elemento, categoria e parte anatomica
      (un prodotto cartesiano) */
      resAssessm.elements.map((e, index) => {
        e.id = index; // fittizio, per usarlo negli autocomplete, non nel DB
        e.key = index;
      });

      // TESTs
      const tests = await getAssessmentTemplates({ is_handbook: 0 });
      let subparamTest = [];
      tests.map(({ id, AssesmentElement }) => {
        AssesmentElement.map((ass) => {
          if (!subparamTest.some(({ id }) => id == ass.id))
            subparamTest.push({
              id: ass.id,
              name: ass.name,
              idParamRef: id,
            });
        });
      });

      setAllRules({
        workload: { parameters: resFeedback, subparameters: tempSubFeed },
        prom: { parameters: promPara, subparameters: promSub },
        assessment: {
          anatomicalParts: resAssessm.parts,
          parameters: resAssessm.categories,
          subparameters: resAssessm.elements,
          triads: resAssessm.triads,
        },
        test: {
          test: tests.map(({ id, name }) => ({ id, name })),
          parameters: subparamTest,
          subparameters: tempSubFeed,
        },
      });
    };

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <StyledGridContainerA key="">
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            direction: "row",
          }}
        >
          <Grid item container xs={12} style={{ flexDirection: "column" }}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.generalRiskFactor.addComp.ruleSection.title}
            </SpText>
          </Grid>

          {/* BLOCCO STRUCTURE/DYSFUNCTION */}
          <StrDysfFilter
            structures={structures}
            dysfunctions={dysfunctions}
            dysfunctionsType={dysfunctionsType}
            inputValues={inputValues}
            setInputValues={setInputValues}
            filterLocks={filterLocks}
            setFilterLocks={setFilterLocks}
          />
          {/* BLOCCO RULE COMPOSITION */}
          {/* blocco rule composition parametrizzato */}
          <RuleComposition
            ruleInputs={ruleInputs}
            setRuleInputs={setRuleInputs}
            thresholdRef={thresholdRef}
            setThresholdRef={setThresholdRef}
            thresholdRef2={thresholdRef2}
            setThresholdRef2={setThresholdRef2}
            logicals={logicals}
            rulesSelectable={rulesSelectable}
            ruleSelector={ruleSelector}
            setRuleSelector={setRuleSelector}
            filteredElements={filteredElements}
            allRules={allRules}
          />
          {/* add button */}
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SpButton
              id="addRuleBtn"
              buttonType="accept"
              text={labels.mylab.generalRiskFactor.addComp.ruleSection.button}
              onClick={() => {
                handleNewRule({
                  ruleSelected: ruleSelector?.selected,
                  ruleInputs: ruleInputs,
                  thresholdRef: thresholdRef,
                  thresholdRef2: thresholdRef2,
                  ruleAdded: ruleAdded,
                  setRuleAdded: setRuleAdded,
                });
                setRuleInputs({
                  anatomicalParts: null,
                  parameter: null,
                  subparameter: null,
                  logical: null,
                });
                setThresholdRef(0);
                ruleInputs.logical?.key === "betw" ? (thresholdRef2 = "") : {};
              }}
              variant="h1"
            />
          </Grid>

          {/* rule list */}
          <Grid container item xs={4}>
            <StyledGridContainerB>
              <StyledGridContainerA>
                <Grid container xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SpText variant="listHeader">
                      {
                        labels.mylab.generalRiskFactor.addComp.ruleSection
                          .ruleList.header
                      }
                    </SpText>
                  </Grid>
                  <Divider
                    style={{
                      padding: "1px",
                      width: "100%",
                      backgroundColor: theme.colors.primary.lightBlue,
                    }}
                  />
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      padding: "1%",
                    }}
                  >
                    {ruleAdded.map(({ rule }, index) => (
                      <Chip
                        variant="outlined"
                        key={index}
                        id={`rule-${index}`}
                        avatar={
                          <SpIconButton
                            variant="redFill"
                            onClick={(e) => {
                              ruleAdded.splice(index, 1);
                              setRuleAdded([...ruleAdded]);
                            }}
                          >
                            <CloseIcon
                              style={{ color: theme.colors.secondary.red }}
                            />
                          </SpIconButton>
                        }
                        style={{
                          backgroundColor: theme.colors.primary.lightBlue,
                          color: theme.colors.primary.white,
                          margin: "8px",
                        }}
                        label={rule}
                        size="medium"
                      />
                    ))}
                  </Grid>
                </Grid>
              </StyledGridContainerA>
            </StyledGridContainerB>
          </Grid>
          <Grid item xs={1} style={{ marginBottom: "0.1em" }}>
            <SpCheckbox
              id={"lockRuleList"}
              label={
                labels.mylab.generalRiskFactor.addComp.inputSection.lockList
              }
              rightlabel={true}
              formControlStyle={{
                justifyContent: "flex-start",
                marginLeft: "5%",
                display: "flex",
              }}
              checked={filterLocks.rulesListToggle}
              onChange={(evnt) => {
                setFilterLocks({
                  ...filterLocks,
                  rulesListToggle: evnt.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>
      </StyledGridContainerA>
    );
  }
);
