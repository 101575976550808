import React, { useEffect, useState } from "react";
import SpText from "../../components/atoms/SpText";
import { labels, psTranslate } from "../../pages/shared/translations";
import { Grid } from "@material-ui/core";
import { getResourceFromServer } from "../../models/actions/Professionals";
import SpDialog from "./SpDialog";

const SpExerciseDialog = ({
  isPrint,
  dialogExerciseOpen,
  setDialogExerciseOpen,
  exerciseDetail,
}) => {
  const [currentExerciseURL, setCurrentExerciseURL] = useState(null);
  const [fileExtention, setFileExtention] = useState("");

  const LabelValue = (props) =>
    props.value && (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText isPrint={isPrint} variant="h4ComponentLabel">
          {props.value}
        </SpText>
      </Grid>
    );

  const getCurrentFileUrl = async () => {
    try {
      if (exerciseDetail) {
        const resourceDetail = await getResourceFromServer({
          id_file: exerciseDetail?.file.id,
        });
        setFileExtention(exerciseDetail?.file?.extension);

        if (resourceDetail) setCurrentExerciseURL(resourceDetail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCurrentFileUrl();
  }, [exerciseDetail]);

  return (
    <SpDialog
      open={dialogExerciseOpen}
      setOpen={setDialogExerciseOpen}
      title={labels.mylab.tempAdd.excerciseSheet.dialog.title}
      style={{ marginRight: "60%" }}
    >
      <Grid container xs={12} style={{ marginBottom: "10px" }}>
        <Grid
          item
          xs={8}
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.dialog.content.name}
            value={psTranslate(exerciseDetail?.name)}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.dialog.content.tags}
            value={psTranslate(exerciseDetail?.tags)}
          />
        </Grid>
        {currentExerciseURL && (
          <Grid item xs={4}>
            {fileExtention === "mp4" || fileExtention === "mov" ? (
              <video
                controls
                src={currentExerciseURL}
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  border: "1px solid white",
                }}
              />
            ) : (
              <img
                src={currentExerciseURL}
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  border: "1px solid white",
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
    </SpDialog>
  );
};

export default SpExerciseDialog;
