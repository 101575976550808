import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Payment.css";
import SpButton from "../../components/atoms/SpButton";
import { labels } from "../shared/translations";
import patient from "../../assets/icon/patient.png";
import { enableUserAuth0 } from "../../models/actions/Common";
import SpLoader from "../../components/atoms/SpLoader";
import { withSnackbar } from "../../components/atoms/SpSnackBar";

const PaymentSuccesfull = (props) => {
  const [loading, setLoading] = useState(false);
  let { email } = useParams();
  const history = useHistory();

  const enableUser = async () => {
    const enabled = await enableUserAuth0({ user: email });
    if (enabled.error) {
      props.snackbarShowErrorMessage(enabled.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    enableUser();
  }, []);

  return (
    <div className="registration-success">
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <div className="card">
        <div className="checkmark-icon">
          <img
            id={"patientIcon"}
            src={patient}
            alt="patientImage"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <h1>{labels.confirmationPage.title}</h1>

        <p>{labels.confirmationPage.subtitle}</p>
        <SpButton
          disabled={loading}
          id={"confirmationButton"}
          style={{ marginTop: "3%", marginRight: "0 !important" }}
          buttonType={"accept"}
          variant="none"
          type="submit"
          text={labels.confirmationPage.button}
          onClick={() => history.push("/login-auth0")}
        />
      </div>
    </div>
  );
};

export default withSnackbar(PaymentSuccesfull);
