import { Grid } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import "../../../App.css";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpText from "../../../components/atoms/SpText";
import { labels, psTranslate } from "../../shared/translations";

export const getList = ({
  injuryDetail,
  mapSelectInjuryCategory,
  setMapSelectInjuryCategory,
  result,
  control,
  presentation,
}) => {
  let listColumn = [];
  let listCategory = [];

  let index = 0;
  for (const [key, value] of Object.entries(injuryDetail)) {
    let element;

    for (const [keyX, valueX] of Object.entries(injuryDetail[key])) {
      index++;
      element = valueX[0];
      let mapSelect = mapSelectInjuryCategory;
      mapSelect.push({
        injury_category: element.category_name,
        index: index,
      });
      setMapSelectInjuryCategory(mapSelect);
      listCategory.push(
        <Controller
          style={{ marginTop: "3%", minWidth: "100%", width: "100%" }}
          render={(props) => (
            <SpSelect
              value={props.value}
              selectPlaceholder={psTranslate(valueX[0]?.category_name)}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
            >
              {valueX?.map((p) => (
                <SpSelectMenuItem key={p.id} value={p.id}>
                  {psTranslate(p.item_name)}
                </SpSelectMenuItem>
              ))}
            </SpSelect>
          )}
          defaultValue={""}
          name={`select` + index}
          control={control}
        />
      );
    }

    listColumn.push(
      <Grid
        container
        item
        direction="column"
        xs={12}
        style={{ marginBottom: "2%", width: "100%" }}
      >
        <SpText variant="text">{psTranslate(element.column_name)}</SpText>
        <SpText variant="h4ComponentLabel">
          {presentation?.patient?.givenName}
        </SpText>

        <Grid container direction="column" xs={12}>
          {listCategory}
        </Grid>
      </Grid>
    );
    listCategory = [];
  }
  return listColumn;
};
