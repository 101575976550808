import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import { labels, psTranslate } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import {
  createDevicesAccount,
  deleteDevicesAccount,
  getDevicesAccountByProfessionalId,
  getDevicesProducer,
  syncDeviceTeams,
  updateDevicesAccount,
  selectGpexeTemplate,
} from "../../../models/actions/Devices";
import { isMobile } from "react-device-detect";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { useForm } from "react-hook-form";
import { rollbar } from "../../../utils/common";
import { theme } from "../../../components/theme";
import { TableCell } from "@material-ui/core";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpLoader from "../../../components/atoms/SpLoader";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import { createAccountFunction } from "./MyLabGpsDevicesAccountFunction";
import jwt_decode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";

const MyLabGpsDevicesAccount = (props) => {
  const idProfessional = localStorage.getItem("userId");
  const history = useHistory();
  const [devicesAccount, setDevicesAccount] = useState([]);
  const [devicesProducer, setDevicesProducer] = useState([]);
  const [selectedDialogCredentials, setSelectedDialogCredentials] = useState(
    []
  );
  const [openAddAccount, setOpenAddAccount] = useState(false);
  const [openChoseTeamAccount, setOpenChoseTeamAccount] = useState(false);
  const [openChoseTemplate, setOpenChoseTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [deviceAccount, setDeviceAccount] = useState();
  const [templateSelected, setTemplateSelected] = useState({});
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [producer, setProducer] = useState();
  const [credentials, setCredetials] = useState();

  const [teamsAccount, setTeamsAccount] = useState([]);
  const [teamsAccountSelected, setTeamsAccountSelected] = useState([]);

  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [loading, setLoading] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [endConfiguration, setEndConfiguration] = useState(false);

  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const { control, getValues, handleSubmit, register, setValue } = useForm({
    shouldUnregister: false,
  });

  {
    /** CUSTOM STYLED COMPONENTS **/
  }
  const TitleToolbarSection = styled("div")({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  });

  const StyledTableColumn = styled("div")({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: "5%",
  });

  {
    /** COMMUNICATION WITH THE BACKEND **/
  }
  const fetchDevicesAccount = async () => {
    try {
      const results = await getDevicesAccountByProfessionalId({
        idProfessional: idProfessional,
      });
      setDevicesAccount(results);
      setEndConfiguration(true);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchDevicesProducer = async () => {
    try {
      const results = await getDevicesProducer();
      // From the results create a new structure with id as key
      const resultsDict = {};
      results.map((result) => {
        resultsDict[result.id] = {
          id: result.id,
          name: result.name,
          credentials: result.credentials,
          key: result.key,
        };
      });

      const accessToken = await getAccessTokenSilently();
      const accessTokenDecoded = jwt_decode(accessToken);
      const permissions = accessTokenDecoded?.permissions;

      // filter device producer using auth0 permissions
      let resultsDictFiltered = {};
      for (const [key, value] of Object.entries(resultsDict)) {
        resultsDictFiltered[key] = value;
      }
      setDevicesProducer(resultsDictFiltered);

      const deviceAccountReult = await getDevicesAccountByProfessionalId({
        idProfessional: idProfessional,
      });

      const deviceAccountFiltered = deviceAccountReult.filter(
        ({ id_producer }) => resultsDictFiltered[id_producer]
      );

      deviceAccountFiltered.forEach((account) => {
        if (account.gpexeTeams) {
          setTeamsAccount(account.gpexeTeams);
        }
      });
      setEndConfiguration(true);
      setDevicesAccount(deviceAccountFiltered);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    const tempGroups = await listGroupsByProfessional();
    setGroups(tempGroups.map(({ group }) => group));
    setLoading(true);
    await fetchDevicesProducer();
    setLoading(false);
  }, []);

  const createAccount = async (action) => {
    setLoading(true);
    const parameter = {
      action: createDevicesAccount,
      data: {},
      idProfessional: idProfessional,
      getValues: getValues,
      devicesProducer: devicesProducer,
      setError: setError,
      error: error,
      labels: labels,
    };
    const result = await createAccountFunction(parameter);

    await fetchDevicesAccount();
    setLoading(false);

    if (result.error) {
      props.snackbarShowErrorMessage(result.error);
    }
    if (result.message) {
      props.snackbarShowMessage(result.message);
      setOpenAddAccount(false);
      // solo in caso di Gpexe si seleziona i tems
      if (result.value) {
        setTeamsAccount(result.value);
        // After created acount the user must chose teams to syncronize
        setOpenChoseTeamAccount(true);
      }
    }
    if (result.exception) {
      // try catch fallito con il return dell'errore
      rollbar.error("Gps Device Account: - " + action, result.exception);
      props.snackbarShowErrorMessage(result.exception);
    }
  };

  const deleteAccount = async () => {
    try {
      const deletedAccount = await deleteDevicesAccount({
        idAccountToDelete: selectedRow?.id,
      });
      if (!deletedAccount?.error) {
        props.snackbarShowMessage(deletedAccount?.message);
        await fetchDevicesAccount();
      } else {
        props.snackbarShowErrorMessage(deletedAccount?.error);
      }
      resetDeleteAccountFields();
    } catch (error) {
      rollbar.error("Gps Device Account: - deleteAccount", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  {
    /** LOCAL FUNCTIONS **/
  }
  const handleChangeDialogProducer = (event, devicesProducer) => {
    setProducer(event.target.value);
    setSelectedDialogCredentials(
      devicesProducer[event.target.value].credentials
    );
  };

  const resetNewAccountFields = () => {
    if (selectedDialogCredentials) {
      selectedDialogCredentials.map((credential) => {
        setValue(credential.name, "");
      });
      setSelectedDialogCredentials([]);
    }
    setOpenAddAccount(false);
  };

  const confirmDeleteAccountFields = (row) => {
    setSelectedRow(JSON.parse(JSON.stringify(row)));
    setOpenDeleteAccount(true);
  };

  const resetDeleteAccountFields = () => {
    setSelectedRow({});
    setOpenDeleteAccount(false);
  };

  const beautifyCredentials = (credentials) => {
    let formattedOutput = [];
    Object.entries(credentials).forEach(([key, value]) => {
      formattedOutput.push(
        <SpText variant="tableText" key={key}>
          {key + ": " + value}
        </SpText>
      );
    });
    return formattedOutput;
  };

  {
    /** HEADER CELLS **/
  }
  const accountHeadCells = [
    {
      id: "producer",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.accounts.producer,
      isAction: false,
    },
    {
      id: "credentials",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.accounts.credentials,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.accounts.actions,
      isAction: true,
    },
  ];

  const selectTemplate = async () => {
    let tempCredentials = {};

    setLoading(true);
    tempCredentials = { ...credentials, template: templateSelected };
    const res = await selectGpexeTemplate({
      credentials: tempCredentials,
      template: templateSelected,
      idAccount: deviceAccount,
    });
    setOpenChoseTemplate(false);
    await fetchDevicesProducer();
    setLoading(false);
    if (res.message) {
      props.snackbarShowMessage(res?.message);
    } else if (res.error) {
      props.snackbarShowErrorMessage(res.error);
    }
  };

  const selectTeamsAction = async () => {
    let tempCredentials = {};
    let tempIdProducer = getValues("idProducer");
    devicesProducer[tempIdProducer].credentials.map((credential) => {
      if (!getValues(credential.name)) {
        setError(labels.gpsDevices.account.allDataAreRequired);
      } else {
        tempCredentials[credential.name] = getValues(credential.name);
      }
    });

    setLoading(true);
    setCredetials(tempCredentials);
    const teamsSyncRes = await syncDeviceTeams({
      id_group: selectedGroup ? selectedGroup : null,
      idProducer: tempIdProducer,
      teams_list: teamsAccountSelected,
      credentials: tempCredentials,
    });
    setLoading(false);

    resetNewAccountFields();
    if (devicesProducer[producer].key === "gpexe") {
      if (teamsSyncRes.message) {
        props.snackbarShowMessage(teamsSyncRes?.message);
        setTemplates(teamsSyncRes.templates);
        setDeviceAccount(teamsSyncRes.idDeviceAccount);
        setOpenChoseTemplate(true);
        setOpenChoseTeamAccount(false);
        setEndConfiguration(true);
      } else if (teamsSyncRes.error) {
        setOpenChoseTeamAccount(false);
        props.snackbarShowErrorMessage(teamsSyncRes.error);
      }
    }
  };

  const updateAccount = async () => {
    setLoading(true);
    const params = {
      teamsList: teamsAccountSelected,
      id_group: selectedGroup ? selectedGroup.id : null,
    };

    const resultUpdate = await updateDevicesAccount({ params });
    setOpenEditAccount(false);
    setLoading(false);
    if (resultUpdate.message) props.snackbarShowMessage(resultUpdate?.message);
    if (resultUpdate.error) props.snackbarShowErrorMessage(resultUpdate.error);
  };

  const AccountsRow = ({ row }) => {
    /**
     * For each row this function render all the fields of the relative Account
     *
     * @param {object} row : The single row to be rendered
     */
    const formattedCredentials = beautifyCredentials(row?.credentials);

    return (
      <SpTableRow tabIndex={-1} key={row?.id}>
        <SpTableCell>
          <SpText variant="tableText">
            {devicesProducer[row?.id_producer]?.name}
          </SpText>
        </SpTableCell>
        <SpTableCell>{formattedCredentials}</SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          {devicesProducer[row?.id_producer]?.key === "gpexe" && (
            <SpButton
              variant={"standard"}
              type="tableList"
              text={labels.mylab.gpsDevices.account.actions.manage}
              onClick={() => setOpenEditAccount(true)}
            />
          )}
          <SpButton
            variant={"standardOpposite"}
            type="tableList"
            text={labels.mylab.gpsDevices.account.actions.delete}
            onClick={() => confirmDeleteAccountFields(row)}
          />
        </TableCell>
      </SpTableRow>
    );
  };

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {labels.mylab.gpsDevices.header.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            {devicesAccount && devicesAccount.length === 0 && (
              <SpButton
                style={{ marginLeft: "2%" }}
                buttonType="accept"
                text={labels.mylab.tempDetail.toolbar.add}
                onClick={() => {
                  setEndConfiguration(false);
                  setOpenAddAccount(true);
                }}
                variant="h1"
              />
            )}
          </Grid>
        </Grid>

        {/** DEVICES ACCOUNT TABLE **/}
        <Grid item xs={12} style={{ marginTop: "0.2em" }}>
          {devicesAccount &&
            devicesAccount.length === 0 &&
            endConfiguration && (
              <SpText variant="h1PageTitle">
                {labels.mylab.gpsDevices.account.empty}
              </SpText>
            )}
          {endConfiguration && devicesAccount && devicesAccount.length > 0 && (
            <StyledTableColumn>
              <SpTable
                headCells={accountHeadCells}
                rows={devicesAccount}
                pagination={true}
                rowKey="id"
                padding={false}
                notCheckable={false}
                tableContainerMaxHeight={
                  isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                }
              >
                <AccountsRow />
              </SpTable>
            </StyledTableColumn>
          )}
        </Grid>
      </Grid>

      {/** EDIT GPEXE GPS ACCOUNT **/}
      {openEditAccount && (
        <form onSubmit={handleSubmit(() => updateAccount())}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Controller
              control={control}
              name={"idTeams"}
              render={(props) => (
                <SpSelect
                  multiple
                  label={labels.mylab.gpsDevices.account.selectTeam.select}
                  value={teamsAccountSelected}
                  formControlWidth={"100%"}
                  onChange={(event) => {
                    props.onChange(event.target.value);
                    setTeamsAccountSelected(event.target.value);
                  }}
                >
                  {teamsAccount.map((team) => (
                    <SpSelectMenuItem key={team.id} value={team.id}>
                      {`${team.name} ${team.season}`}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
            />
            <SpSelect
              label={labels.mylab.gpsDevices.account.selectTeam.group}
              value={selectedGroup}
              formControlWidth={"100%"}
              onChange={(event) => {
                setSelectedGroup(event.target.value);
              }}
            >
              {groups.map((group) => (
                <SpSelectMenuItem key={group.id} value={group.id}>
                  {`${group.name}`}
                </SpSelectMenuItem>
              ))}
            </SpSelect>
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}
            <SpButton
              buttonType={"accept"}
              variant="none"
              text={labels.mylab.gpsDevices.account.updateDialog.submit}
              style={{ marginTop: "4%" }}
              type="submit"
            />
          </div>
        </form>
      )}

      {/** ADD A NEW GPS ACCOUNT **/}
      {openAddAccount && (
        <div>
          <form onSubmit={handleSubmit(() => createAccount("createAccount"))}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Controller
                control={control}
                name={"idProducer"}
                render={(props) => (
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <SpSelect
                        label={labels.mylab.gpsDevices.accounts.producer}
                        value={props?.value}
                        onChange={(event) => {
                          props.onChange(event.target.value);
                          handleChangeDialogProducer(event, devicesProducer);
                        }}
                      >
                        {devicesProducer &&
                          Object.keys(devicesProducer).map((idProducer) => (
                            <SpSelectMenuItem
                              key={idProducer}
                              value={idProducer}
                            >
                              {psTranslate(devicesProducer[idProducer].name)}
                            </SpSelectMenuItem>
                          ))}
                      </SpSelect>
                    </Grid>
                  </Grid>
                )}
              />
              <Grid
                container
                direction="row"
                spacing={1}
                style={{ paddingTop: "8px" }}
              >
                {selectedDialogCredentials &&
                  selectedDialogCredentials.map((credential) => {
                    return (
                      <Grid item xs={4}>
                        <SpTextInput
                          style={{ width: "100%" }}
                          type={credential.type}
                          name={credential.name}
                          inputRef={register({ required: true })}
                          label={
                            labels.mylab.gpsDevices.accounts[credential.name]
                          }
                        />
                      </Grid>
                    );
                  })}
                {error && (
                  <SpText variant="h4ComponentLabelError">{error}</SpText>
                )}
              </Grid>
              <SpButton
                buttonType={"accept"}
                variant="none"
                text={labels.mylab.gpsDevices.account.addDialog.submit}
                style={{ marginTop: "4%" }}
                type="submit"
              />
            </div>
          </form>
        </div>
      )}

      {/** CHOSE GPS TEAM ACCOUNT **/}
      {openChoseTeamAccount && (
        <form onSubmit={handleSubmit(() => selectTeamsAction())}>
          <Grid container direction="row" spacing={1}>
            {devicesProducer[producer].key === "gpexe" && (
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={"idTeams"}
                  render={(props) => (
                    <SpSelect
                      multiple
                      label={labels.mylab.gpsDevices.account.selectTeam.select}
                      value={teamsAccountSelected}
                      formControlWidth={"100%"}
                      onChange={(event) => {
                        props.onChange(event.target.value);
                        setTeamsAccountSelected(event.target.value);
                      }}
                    >
                      {teamsAccount.map((team) => (
                        <SpSelectMenuItem key={team.id} value={team.id}>
                          {`${team.name} ${team.season}`}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <SpSelect
                label={labels.mylab.gpsDevices.account.selectTeam.group}
                value={selectedGroup}
                formControlWidth={"100%"}
                onChange={(event) => {
                  setSelectedGroup(event.target.value);
                }}
              >
                {groups.map((group) => (
                  <SpSelectMenuItem key={group.id} value={group.id}>
                    {`${group.name}`}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
            </Grid>
            <Grid item xs={12}>
              <SpButton
                buttonType={"accept"}
                variant="none"
                text={labels.mylab.gpsDevices.account.selectTeam.submit}
                style={{ marginTop: "4%", width: "100%" }}
                type="submit"
              />
            </Grid>
          </Grid>
        </form>
      )}

      {/** CHOSE GPS TEMPLATE **/}
      {openChoseTemplate && (
        <form onSubmit={handleSubmit(() => selectTemplate())}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid item xs={4}>
              <Controller
                control={control}
                name={"idTemplates"}
                render={(props) => (
                  <SpSelect
                    label={"Templates"}
                    value={templateSelected}
                    formControlWidth={"100%"}
                    onChange={(event) => {
                      props.onChange(event.target.value);
                      setTemplateSelected(event.target.value);
                    }}
                  >
                    {templates.map(({ id, name }) => (
                      <SpSelectMenuItem key={id} value={id}>
                        {`${name}`}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
              />
            </Grid>
            <SpButton
              buttonType={"accept"}
              variant="none"
              text={"select template"}
              style={{ marginTop: "4%" }}
              type="submit"
            />
          </div>
        </form>
      )}

      {/** REMOVE A GPS ACCOUNT **/}
      {openDeleteAccount && (
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.mylab.gpsDevices.account.deleteDialog.content}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.general.yes}
                buttonType="accept"
                onClick={deleteAccount}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.general.no}
                buttonType="accept"
                onClick={() => resetDeleteAccountFields()}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(MyLabGpsDevicesAccount);
