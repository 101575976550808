import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { TextureLoader, PlaneGeometry, MeshBasicMaterial, Mesh } from "three";
import it_flag from "../assets/icon/img_0.png";
import logo from "../assets/icon/powerset_logo_web_white.png";

import { theme } from "../components/theme";

const LoginThumbnail = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();

    const loader = new TextureLoader();
    loader.load(it_flag, function (texture) {
      scene.background = texture;
    });

    loader.load(logo, function (texture) {
      const planeGeometry = new THREE.PlaneGeometry(0.2, 0.03); // Regola le dimensioni in base alla tua necessità
      const planeMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true, // Importante per la trasparenza
        opacity: 1, // Regola l'opacità se necessario, 1 = completamente opaco
      });
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);

      // Assicurati che l'immagine sia davanti a tutti gli altri oggetti
      plane.position.z = camera.position.z - 1;
      scene.add(plane);
    });

    const camera = new THREE.PerspectiveCamera(
      15,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth * 0.7, window.innerHeight * 0.9);
    mountRef.current.appendChild(renderer.domElement);

    // Generazione di molti triangoli vuoti
    const material = new THREE.LineBasicMaterial({
      color: theme.colors.primary.lightBlue,
    }); // Verde

    const numberOfTriangles = 10; // Numero desiderato di triangoli

    for (let i = 0; i < numberOfTriangles; i++) {
      const points = [];
      for (let j = 0; j < 3; j++) {
        points.push(
          new THREE.Vector3(
            Math.random() * 2 - 1,
            Math.random() * 2 - 1,
            Math.random() * 2 - 1
          )
        );
      }
      points.push(points[0]); // Chiudi il triangolo riconnettendo l'ultimo punto al primo

      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const line = new THREE.LineLoop(geometry, material);
      scene.add(line);
      scene.children.forEach((child) => {
        if (child instanceof THREE.LineLoop) {
          child.position.y += 0.01;
          if (child.position.y > 2) {
            // Un esempio di reset della posizione
            child.position.y = -2;
          }
        }
      });
    }

    camera.position.z = 5;

    const animate = function () {
      requestAnimationFrame(animate);

      // Ruota ogni oggetto nella scena
      scene.children.forEach((child) => {
        if (child instanceof THREE.LineLoop) {
          child.rotation.x += 0.01;
          child.rotation.y += 0.01;
        }
      });

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef}></div>;
};

export default LoginThumbnail;
