import React, { useEffect, useState } from "react";
import "../App.css";
import { useHistory } from "react-router-dom";
import { labels } from "./shared/translations";
// material ui
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Avatar, Grid, TableCell, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import SpSearch from "../components/atoms/SpSearch";
import SpIconButton from "../components/atoms/SpIconButton";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

// atoms
// utils
import {
  controlRoomStep,
  getAge,
  getColorFromPatientStatus,
  getLabelFromPatientStatus,
  getLabelRangeFromPatientStatus,
  getUserPermission,
  riskScoreThreshold,
  titleCase,
} from "../utils/common";
import { theme } from "../components/theme";
import SpTooltip from "../components/atoms/SpTooltip";
import moment from "moment-timezone";

export const headCellsDefault = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: false,
    label: "",
    isAction: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: `${labels.patient.table.header.name} ${labels.patient.table.header.surname}`,
    isAction: false,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: labels.patient.patientRegistry.mainRole,
    isAction: false,
  },
  {
    id: "birth_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.age,
    isAction: false,
  },
  {
    id: "last_visit",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.last_visit,
    isAction: false,
  },
  {
    id: "alert",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.risk,
    isAction: false,
  },
  {
    id: "risk",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.alert,
    isAction: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.actions,
    isAction: true,
  },
];

export const headCellsNoRisk = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: false,
    label: "",
    isAction: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: `${labels.patient.table.header.name} ${labels.patient.table.header.surname}`,
    isAction: false,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: labels.patient.patientRegistry.mainRole,
    isAction: false,
  },
  {
    id: "birth_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.age,
    isAction: false,
  },
  {
    id: "last_visit",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.last_visit,
    isAction: false,
  },
];

export const headCellsWrestlingDefault = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: false,
    label: "",
    isAction: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: `${labels.patient.table.header.name} ${labels.patient.table.header.surname}`,
    isAction: false,
  },
  {
    id: "sport",
    numeric: false,
    disablePadding: false,
    label: "sport",
    isAction: false,
  },
  {
    id: "specialita",
    numeric: false,
    disablePadding: false,
    label: "specialita",
    isAction: false,
  },
  {
    id: "po",
    numeric: false,
    disablePadding: false,
    label: "po",
    isAction: false,
  },
  {
    id: "partner",
    numeric: false,
    disablePadding: false,
    label: "partner",
    isAction: false,
  },
  {
    id: "starter",
    numeric: false,
    disablePadding: false,
    label: "starter",
    isAction: false,
  },
  {
    id: "weight_category",
    numeric: false,
    disablePadding: false,
    label: "weight",
    isAction: false,
  },
  {
    id: "birth_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.age,
    isAction: false,
  },
  {
    id: "last_visit",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.last_visit,
    isAction: false,
  },
  {
    id: "alert",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.risk,
    isAction: false,
  },
  {
    id: "risk",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.alert,
    isAction: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.actions,
    isAction: true,
  },
];
export const headCellsWrestlingNoRisk = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: false,
    label: "",
    isAction: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: `${labels.patient.table.header.name} ${labels.patient.table.header.surname}`,
    isAction: false,
  },
  {
    id: "sport",
    numeric: false,
    disablePadding: false,
    label: "sport",
    isAction: false,
  },
  {
    id: "specialita",
    numeric: false,
    disablePadding: false,
    label: "specialita",
    isAction: false,
  },
  {
    id: "po",
    numeric: false,
    disablePadding: false,
    label: "po",
    isAction: false,
  },
  {
    id: "partner",
    numeric: false,
    disablePadding: false,
    label: "partner",
    isAction: false,
  },
  {
    id: "starter",
    numeric: false,
    disablePadding: false,
    label: "starter",
    isAction: false,
  },
  {
    id: "weight_category",
    numeric: false,
    disablePadding: false,
    label: "weight",
    isAction: false,
  },
  {
    id: "birth_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.age,
    isAction: false,
  },
  {
    id: "last_visit",
    numeric: false,
    disablePadding: false,
    label: labels.patient.table.header.last_visit,
    isAction: false,
  },
];

export const PatientRequestRow = ({
  isAlertEnabled,
  isGroupEnabled,
  isChartEnabled,
  patIdUnchekedAlert,
  patIdChekedAlert,
  row,
  index,
}) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    largeAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));

  const classes = useStyles();
  return (
    <SpTableRow
      role="checkbox"
      id={row.id}
      tabIndex={index}
      key={`patient-${index}`}
    >
      <SpTableCell>
        <Avatar
          imgProps={{
            style: {
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
              borderRadius: "50%", // Rende l'immagine rotonda
              overflow: "hidden", // Nasconde le parti fuoriuscite
              border: "2px solid white", // Opzionale: Aggiunge un bordo bianco
            },
          }}
          className={classes.largeAvatar}
          src={row?.profileImage ? row?.profileImage : ""}
        />
      </SpTableCell>

      <SpTableCell>
        <SpIconAndTextButton
          id={`patient-row-${row?.givenName}-${row?.familyName}`}
          isIconRight={true}
          text={titleCase(`${row?.givenName} ${row?.familyName}`)}
          textVariant={"tableTextDetails"}
          onClick={() => {
            history.push(`/patients/edit/${row?.id}`);
          }}
        >
          <ArrowForwardIosIcon
            style={{
              width: 15,
              color: theme.colors.primary.lightBlue,
              height: 30,
              cursor: "pointer",
            }}
          />
        </SpIconAndTextButton>
      </SpTableCell>
      <SpTableCell>
        <SpIconAndTextButton
          id={`patient-row-${row?.main_role}`}
          isIconRight={true}
          text={titleCase(`${row?.main_role}`)}
          textVariant={"tableTextDetails"}
          onClick={() => {
            history.push(`/patients/edit/${row?.id}`);
          }}
        ></SpIconAndTextButton>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{getAge(row?.birth_date)}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpTooltip title={getLabelFromPatientStatus({ status: row?.status })}>
          <SpIconButton variant="lightBlue" small="true">
            <FiberManualRecordIcon
              style={{
                cursor: "default",
                color: getColorFromPatientStatus({ status: row?.status }),
              }}
            />
          </SpIconButton>
        </SpTooltip>
      </SpTableCell>
      <SpTableCell>
        {isAlertEnabled && (
          <SpText
            onClick={() => {
              setSelectedRow(row);
              setDialogRedFlagOpen(true);
            }}
            variant="tableTextDetails"
          >
            {row?.injury_risk?.score
              ? getLabelRangeFromPatientStatus({
                  value: row.injury_risk.score,
                  threshold1: riskScoreThreshold.low,
                  threshold2: riskScoreThreshold.moderate,
                  threshold3: riskScoreThreshold.hight,
                })
              : labels.patient.injuryRisk.low}
          </SpText>
        )}
      </SpTableCell>

      <SpTableCell>
        {isAlertEnabled && (
          <NotificationImportantIcon
            id={`alertIcon-${row.id}`}
            onClick={() => {
              history.push(`/patients/edit/${row.id}/alerts`);
            }}
            style={{
              cursor: "default",
              color: patIdUnchekedAlert?.find((id) => id === row?.id)
                ? "red"
                : patIdChekedAlert?.find((id) => id === row?.id)
                ? "yellow"
                : "green",
            }}
          />
        )}
      </SpTableCell>

      <TableCell
        align={"right"}
        style={{
          padding: 0,
          verticalAlign: "bottom",
          borderBottom: 0,
        }}
      >
        {isGroupEnabled && (
          <SpButton
            variant={"standard"}
            text={labels.patient.table.actions.groups}
            type="tableList"
            onClick={() => {
              history.push({ pathname: `/groups`, state: { patId: row.id } });
            }}
          />
        )}
        {isChartEnabled && (
          <SpButton
            variant={"standardOpposite"}
            type="tableList"
            text={labels.patient.sideBarPatEdit.controlRoom.toUpperCase()}
            onClick={() => {
              history.push({
                pathname: `patients/edit/${row.id}/control-room`,
              });
            }}
          />
        )}
      </TableCell>
    </SpTableRow>
  );
};

export const PatientRequestRowWrestling = ({
  isAlertEnabled,
  isGroupEnabled,
  isChartEnabled,
  patIdUnchekedAlert,
  patIdChekedAlert,
  row,
  index,
}) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    largeAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));

  const classes = useStyles();

  return (
    <SpTableRow
      role="checkbox"
      id={row.id}
      tabIndex={index}
      key={`patient-${index}`}
    >
      <SpTableCell>
        <Avatar
          imgProps={{
            style: {
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
              borderRadius: "50%", // Rende l'immagine rotonda
              overflow: "hidden", // Nasconde le parti fuoriuscite
              border: "2px solid white", // Opzionale: Aggiunge un bordo bianco
            },
          }}
          className={classes.largeAvatar}
          src={row?.profileImage ? row?.profileImage : ""}
        />
      </SpTableCell>

      <SpTableCell>
        <SpIconAndTextButton
          id={`patient-row-${row?.givenName}-${row?.familyName}`}
          isIconRight={true}
          text={titleCase(`${row?.givenName} ${row?.familyName}`)}
          textVariant={"tableTextDetails"}
          onClick={() => {
            history.push(`/patients/edit/${row?.id}`);
          }}
        >
          <ArrowForwardIosIcon
            style={{
              width: 15,
              color: theme.colors.primary.lightBlue,
              height: 30,
              cursor: "pointer",
            }}
          />
        </SpIconAndTextButton>
      </SpTableCell>
      <SpTableCell>
        <SpIconAndTextButton
          id={`patient-row-sports`}
          isIconRight={true}
          text={titleCase(`${row?.sports.map(({ answer }) => answer)}`)}
          textVariant={"tableTextDetails"}
          onClick={() => {
            history.push(`/patients/edit/${row?.id}`);
          }}
        ></SpIconAndTextButton>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{`${row?.discipline.map(
          ({ answer }) => answer
        )}`}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{row?.po}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{row?.partner}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{row?.starter}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{`${row?.weight_category.map(
          ({ answer }) => answer
        )}`}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{getAge(row?.birth_date)}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpTooltip title={getLabelFromPatientStatus({ status: row?.status })}>
          <SpIconButton variant="lightBlue" small="true">
            <FiberManualRecordIcon
              style={{
                cursor: "default",
                color: getColorFromPatientStatus({ status: row?.status }),
              }}
            />
          </SpIconButton>
        </SpTooltip>
      </SpTableCell>
      <SpTableCell>
        {isAlertEnabled && (
          <SpText
            onClick={() => {
              setSelectedRow(row);
              setDialogRedFlagOpen(true);
            }}
            variant="tableTextDetails"
          >
            {row?.injury_risk?.score
              ? getLabelRangeFromPatientStatus({
                  value: row.injury_risk.score,
                  threshold1: riskScoreThreshold.low,
                  threshold2: riskScoreThreshold.moderate,
                  threshold3: riskScoreThreshold.hight,
                })
              : labels.patient.injuryRisk.low}
          </SpText>
        )}
      </SpTableCell>

      <SpTableCell>
        {isAlertEnabled && (
          <NotificationImportantIcon
            id={`alertIcon-${row.id}`}
            onClick={() => {
              history.push(`/patients/edit/${row.id}/alerts`);
            }}
            style={{
              cursor: "default",
              color: patIdUnchekedAlert?.find((id) => id === row?.id)
                ? "red"
                : patIdChekedAlert?.find((id) => id === row?.id)
                ? "yellow"
                : "green",
            }}
          />
        )}
      </SpTableCell>

      <TableCell
        align={"right"}
        style={{
          padding: 0,
          verticalAlign: "bottom",
          borderBottom: 0,
        }}
      >
        {isGroupEnabled && (
          <SpButton
            variant={"standard"}
            text={labels.patient.table.actions.groups}
            type="tableList"
            onClick={() => {
              history.push({
                pathname: `/groups`,
                state: { patId: row.id },
              });
            }}
          />
        )}
        {isChartEnabled && (
          <SpButton
            variant={"standardOpposite"}
            type="tableList"
            text={labels.patient.sideBarPatEdit.controlRoom.toUpperCase()}
            onClick={() => {
              history.push({
                pathname: `patients/edit/${row.id}/control-room`,
              });
            }}
          />
        )}
      </TableCell>
    </SpTableRow>
  );
};

export const BasicPatients = ({ displayPatients }) => {
  const history = useHistory();

  const useStyles = makeStyles((themeStyle) => ({
    media: {
      height: 150,
      objectFit: "cover", // Oppure usa 'contain' se vuoi che l'immagine si adatti senza essere tagliata
    },
    content: {
      color: "#ffffff", // Colore del testo bianco
    },
    description: {
      display: "-webkit-box",
      WebkitLineClamp: 4, // Mostra al massimo 3 righe
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis", // Indica che il testo è stato troncato
    },
    name: {
      transition: "background-color 0.3s ease",
      padding: "0.2rem",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#f0f0f0", // Colore grigio chiaro al passaggio del mouse
      },
    },
    actionArea: {
      width: 200,
      height: 300, // Altezza fissa per ogni card
      margin: "1rem",
      backgroundColor: "#002b24", // Verde scuro come sfondo
      "&:hover": {
        backgroundColor: theme.colors.primary.lightBlue, // Colore grigio chiaro al passaggio del mouse sull'intera CardActionArea
      },
      "&:focus": {
        backgroundColor: theme.colors.primary.lightBlue, // Colore grigio quando viene cliccata/focalizzata
      },
    },
    largeAvatar: {
      margin: "8px",
      width: themeStyle.spacing(15),
      height: themeStyle.spacing(15),
    },
  }));

  // Componente per una singola figurina
  const PersonCard = ({ person }) => {
    const classes = useStyles();
    return (
      <CardActionArea
        id={`patient-row-${person?.givenName}-${person?.familyName}`}
        className={classes.actionArea}
        onClick={() => {
          history.push(`/patients/edit/${person?.id}`);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Disporre gli elementi in verticale
            justifyContent: "center", // Centrare verticalmente
            alignItems: "center",
          }}
        >
          <Avatar
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              },
            }}
            className={classes.largeAvatar}
            src={person?.profileImage}
          />
        </div>

        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            {titleCase(`${person?.givenName} ${person?.familyName}`)}
          </Typography>
          {person?.birth_date && (
            <Typography variant="body2" component="p">
              {`${labels.patient.table.header.age}: ` +
                moment().diff(moment(person?.birth_date), "years")}
            </Typography>
          )}
          {person?.sports?.length > 0 && (
            <Typography variant="body2" component="p">
              {"Sport: " + person?.sports.map(({ answer }) => `${answer}, `)}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    );
  };

  const CardList = ({ people }) => {
    return (
      <Grid container spacing={2} justifyContent="left">
        {people.map((person) => (
          <Grid item key={person.id}>
            <PersonCard person={person} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return displayPatients?.length > 0 ? (
    <CardList people={displayPatients} />
  ) : (
    <></>
  );
};
