import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const LogoImg = styled.img`
  max-width: 20%;
`;

export const LogoMenu = styled.img`
  max-width: 50%;
`;

export const LogoLogin = styled.img`
  max-width: 30%;
`;
