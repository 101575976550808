import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@material-ui/core";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import MenuAgency from "../menu/MenuAgency";
import Profile from "../profile/Profile";
import {
  HeaderContainer,
  HeaderContainerRow,
  HeaderContainerRowMenu,
} from "./style";
import SpText from "../../../components/atoms/SpText";
import { getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "../../../components/theme";

const Header = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isFederation, setIsFederation] = useState(false);
  const fetchData = async () => {
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    setIsFederation(permissionFederationGroups);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HeaderContainer>
      <HeaderContainerRow container spacing={2}>
        <Grid item xs={8}>
          <Logo />
        </Grid>
        <Grid item xs={4}>
          <Profile />
        </Grid>
      </HeaderContainerRow>
      <Divider style={{ backgroundColor: theme.colors.primary.lightBlue }} />
      {!isFederation && (
        <HeaderContainerRowMenu>
          <Menu />
        </HeaderContainerRowMenu>
      )}
    </HeaderContainer>
  );
};

export const HeaderAgency = (props) => (
  <HeaderContainer>
    <HeaderContainerRow>
      <Logo />
      <Profile />
    </HeaderContainerRow>
    <Divider style={{ backgroundColor: theme.colors.primary.lightBlue }} />
    <HeaderContainerRow>
      <MenuAgency />
    </HeaderContainerRow>
  </HeaderContainer>
);

Header.propTypes = {};

export default Header;
