import { Divider, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { labels } from "../../../pages/shared/translations";
import SpButton from "../../atoms/SpButton";
import SpText from "../../atoms/SpText";
import FeedbackElement from "./FeedbackElement";
import SpLoader from "../../atoms/SpLoader";
import { theme } from "../../theme";
import { getCalendarActivityById } from "../../../models/actions/CalendarActivity";
const Feedbacks = ({
  event,
  setValue,
  click,
  register,
  isPrint = false,
  sync,
  snackbarShowErrorMessage,
  snackbarShowMessage,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);

  const fetchData = async () => {
    const res = await getCalendarActivityById({
      id_activity_groups: event.id_activity_groups,
    });
    setFeedbacks(res.patients);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid
        direction="column"
        container
        style={{ marginTop: "2%" }}
        spacing={2}
      >
        {feedbacks.map((patAct) => {
          return (
            <Grid
              key={patAct.id}
              direction="column"
              container
              style={{ marginTop: "2%" }}
              spacing={2}
              xs={12}
            >
              <Grid item container direction="row" xs={12}>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      marginTop: "1%",
                      height: 1,
                      marginBottom: "1%",
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SpText variant={"h4ComponentLabelColored"}>
                    {patAct.patientName}
                  </SpText>
                </Grid>
              </Grid>
              <FeedbackElement
                feedbacks={patAct.patientFeedbacks}
                event={event}
                setValue={setValue}
                register={register}
                isPrint={isPrint}
              />
            </Grid>
          );
        })}
        {!(
          event.archiving_status === "completed_and_feedback_not_sent" ||
          event.archiving_status === "completed_and_feedback_sent"
        ) && (
          <Grid item container direction="row" xs={12}>
            <SpButton
              buttonType="accept"
              style={{ width: "95%" }}
              text={labels.bundles.calendarPlan.feedback.save}
              variant="h1PageTitle"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                click();
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Feedbacks;
