import React from "react";
import "./Payment.css";
import SpButton from "../../components/atoms/SpButton";
import { labels } from "../shared/translations";
import patient from "../../assets/icon/patientFailed.png";
import { useHistory, useParams } from "react-router-dom";

export const PaymentFailed = (props) => {
  const history = useHistory();

  return (
    <div className="registration-success">
      <div className="card">
        <div className="checkmark-icon">
          <h1>{labels.confirmationPage.failedTitle}</h1>

          <img
            id={"patientIcon"}
            src={patient}
            alt="patientImage"
            style={{ width: "200px", height: "auto" }}
          />
        </div>

        <p>{labels.confirmationPage.failedMessage}</p>
        <SpButton
          id={"confirmationButton"}
          style={{ marginTop: "3%", marginRight: "0 !important" }}
          buttonType={"accept"}
          variant="none"
          type="submit"
          text={labels.confirmationPage.button}
          onClick={() => history.push("/login-auth0")}
        />
      </div>
    </div>
  );
};
