import { call } from "./utils";

export const getGroupsById = async (data) =>
  await call({ url: "/group/by/id", data: { data } });

export const listGroupsByProfessional = async (data) =>
  await call({ url: "/groups/list/by/professional", data: { data } });

export const createGroup = async (data) =>
  await call({ url: "/groups/add", data: { data } });

export const deleteGroup = async (data) =>
  await call({ url: "/groups/delete", data: { data } });

export const exitGroup = async (data) =>
  await call({ url: "/groups/exit", data: { data } });

export const updateGroupAvatar = async (data) =>
  await call({ url: "/groups/update/avatar", data: { data } });

export const updateGroup = async (data) =>
  await call({ url: "/groups/update", data: { data } });

export const deleteProfessionalGroup = async (data) =>
  await call({
    url: "/groups/remove/professional",
    data: { data },
  });

export const deletePatientGroup = async (data) =>
  await call({ url: "/groups/remove/patient", data: { data } });

export const setGroupProfileImage = async (data) =>
  await call({ url: "/groups/set/profile/image", data: { data } });

export const getFirstTableGroupsData = async (data) =>
  await call({
    url: "/groups/get/first/reports/data",
    data: { data },
  });

export const getSecondTableGroupsData = async (data) =>
  await call({
    url: "/groups/get/second/reports/data",
    data: { data },
  });

export const getThirdTableGroupsData = async (data) =>
  await call({
    url: "/groups/get/third/reports/data",
    data: { data },
  });
