import { Grid } from "@material-ui/core";
import { psTranslate } from "../../shared/translations";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getGroupsById } from "../../../models/actions/Groups";
import { dateFormat } from "../../../utils/common";
import { labels } from "../../shared/translations.js";
import GroupEditSidebar from "../shared/GroupEditSidebar";
import {
  getHeader,
  getRange,
} from "../../patients/patientsReports/patientsReportInjury/InjuryReportCommonFunction";
import InjurySurveillanceConfigurable from "../../patients/patientsReports/InjurySurveillanceConfigurable";
import AvaExposure from "./exposure.jsx";
import { TableComponent } from "./TableComponent.js";
import { TreeMapChart } from "./TreeMapChart.js";
const moment = extendMoment(Moment);

// set data range
const DEFAULT_RANGE = getRange();

// count function
function countBy(data, key) {
  const counts = {};
  data.forEach((item) => {
    if (item[key] !== null) {
      const value = item[key];
      counts[value] = (counts[value] || 0) + 1;
    }
  });

  const result = Object.keys(counts).map((key) => ({
    name: key,
    value: counts[key],
  }));

  return result;
}

const Disponibility = ({ ...props }) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [selectedGroupsInj, setSelectedGroupsInj] = useState([]);
  const [groups, setGroups] = useState([]);
  const { patId, groupId } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fetchData = async () => {
    const groupResults = await getGroupsById({ id_group: groupId });
    setGroups(groupResults);

    let groupsDataInj = [];

    // create dataset for visualization
    for (let el = 0; el < groupResults["patients"].length; el++) {
      let patName =
        groupResults["patients"][el].givenName +
        " " +
        groupResults["patients"][el].familyName;
      for (
        let injIdx = 0;
        injIdx < groupResults["patients"][el]["presentations"].length;
        injIdx++
      ) {
        let injVal = groupResults["patients"][el]["presentations"][injIdx];
        injVal["playersName"] = patName;
        let duration;
        if (injVal["end_date"] !== null) {
          duration = parseFloat(
            moment(injVal["end_date"]).diff(
              moment(injVal["start_date"]),
              "days"
            )
          );
        } else {
          injVal["end_date"] = moment();
          duration = parseFloat(
            moment().diff(moment(injVal["start_date"]), "days")
          );
        }
        injVal["duration"] = duration;
        let severity = "None";
        if (duration === 0) {
          severity = "No time loss";
        } else if (duration <= 3) {
          severity = labels.injSur.minlow;
        } else if (duration > 3 && duration <= 7) {
          severity = labels.injSur.low;
        } else if (duration > 7 && duration <= 28) {
          severity = labels.injSur.moderate;
        } else {
          severity = labels.injSur.severe;
        }
        injVal["severity"] = severity;

        if (injVal["region"]) {
          injVal["region"] = psTranslate(injVal?.region?.name);
        }
        if (injVal["structure"]) {
          injVal["structure"] = psTranslate(injVal?.structure?.name);
        }
        if (injVal["pathology"]) {
          injVal["pathology"] = psTranslate(injVal?.pathology?.name);
        }
        if (injVal["mechanism"]) {
          injVal["mechanism"] = psTranslate(
            labels.patient.presentation.add.stepper.step0.detail.mechanism.choise.find(
              ({ key }) => key === injVal["mechanism"]
            )?.value
          );
        }
        if (injVal["specific_action"]) {
          injVal["specific_action"] = psTranslate(
            labels.patient.presentation.add.stepper.step0.detail.specificAction.choise.find(
              ({ key }) => key === injVal["specific_action"]
            )?.value
          );
        }

        groupsDataInj.push(injVal);
      }
    }
    {/* setCurrentGroup(groupsDataInj); */}

    const groupDataInj4Tree = []
    for (let p of groupsDataInj) {
      if (p['region']) {
        p['region'] = p['region'].replace(/Right|Left|Destra|Sinistra|Sinistro|Destro|de la izquierda|de la derecha|izquierda|derecha/g, '')
      }
      if (p['structure']) {
        p['structure'] = p['structure'].replace(/RIGHT|LEFT|DI DESTRA|SINISTRA|SINISTRO|DESTRO|de la izquierda|de la derecha|izquierda|derecha/g, '')
      }
      groupDataInj4Tree.push(p)
    }

    setCurrentGroup(groupDataInj4Tree);

    // select injuries from daterenge
    let injuryRange = [];
    for (let el = 0; el < groupDataInj4Tree.length; el++) {
      if (
        moment(groupDataInj4Tree[el]["start_date"]).isBetween(
          moment(dateRange.start._d).subtract(1, "days"),
          moment(dateRange.end._d).add(1, "days")
        ) ||
        moment(groupDataInj4Tree[el]["end_date"]).isBetween(
          moment(dateRange.start._d).subtract(1, "days"),
          moment(dateRange.end._d).add(1, "days")
        )
      ) {
        injuryRange.push(groupDataInj4Tree[el]);
      }
    }
    setSelectedGroupsInj(injuryRange);
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    let injuryRange = [];
    for (let el = 0; el < currentGroup.length; el++) {
      if (
        moment(currentGroup[el]["start_date"]).isBetween(
          moment(newDateRange.start._d).subtract(1, "days"),
          moment(newDateRange.end._d).add(1, "days")
        ) ||
        moment(currentGroup[el]["end_date"]).isBetween(
          moment(newDateRange.start._d).subtract(1, "days"),
          moment(newDateRange.end._d).add(1, "days")
        )
      ) {
        injuryRange.push(currentGroup[el]);
      }
    }
    setSelectedGroupsInj(injuryRange);

    setLoading(false);
    setDateRange(newDateRange);
  };

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container spacing={0} direction="row">
        <Grid item xs={4}>
          <GroupEditSidebar
            props={props}
            groupId={groupId}
            history={history}
            currentGroup={groups}
          />
        </Grid>

        <Grid container item xs={8}>
          {/* selezione del range temporale */}
          {getHeader(changeDate, dateRange, dateFormat)}
        </Grid>
      </Grid>
      <h1 style={{ color: "#31ccad" }}> {labels.injSur.genInj} </h1>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.severity} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "severity")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.region} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "region")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.structure} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "structure")} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.patology} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "pathology")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.mechanism} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "mechanism")} />
        </Grid>
        <Grid item xs={4}>
          <h2 style={{ color: "#fff" }}> {labels.injSur.actType} </h2>
          <TreeMapChart data={countBy(selectedGroupsInj, "specific_action")} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        style={{ paddingLeft: "1%" }}
        direction="row"
      >
        <TableComponent data={selectedGroupsInj} />
      </Grid>

      <h1 style={{ color: "#31ccad" }}> {labels.injSur.expAva} </h1>
      <AvaExposure dateRangeGen={dateRange} />

      <hr style={{ color: "#31ccad" }}></hr>
      <h1 style={{ color: "#31ccad" }}> {labels.injSur.details} </h1>
      <InjurySurveillanceConfigurable />
    </Grid>
  );
};

export default Disponibility;
