/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */

export const routesPatients = {
  status: "status",
  registry: "registry",
  pastHistory: "past-history",
  monitoring: "monitoring",
  alerts: "alerts",
  presentationsIllness: "presentationsIllness",
  presentation: "presentation",
  plan: "plan",
  statistics: "statistics",
  statisticsAnalytics: "statistics-analytics",
  report: "report",
  controlRoom: "control-room",
  medicalResource: "medical-resource",
  physioResource: "physio-resource",
  settings: "settings",
  medicalNotes: "medical-notes",
  diary: "diary",
};

export const getPatientSidebarRoute = (route, patId) => {
  let routeReturn = "";

  switch (route) {
    case "registry":
      routeReturn = `/patients/edit/${patId}/registry`;
      break;

    case "medical-notes":
      routeReturn = `/patients/edit/${patId}/medical-notes`;
      break;

    case "past-history":
      routeReturn = `/patients/edit/${patId}/past-history`;
      break;

    case "monitoring":
      routeReturn = `/patients/edit/${patId}/monitoring`;
      break;

    case "alerts":
      routeReturn = `/patients/edit/${patId}/alerts`;
      break;

    case "presentationsIllness":
      routeReturn = `/patients/edit/${patId}/patientsInjuryIllness`;
      break;

    case "presentation":
      routeReturn = `/patients/edit/${patId}/presentation`;
      break;

    case "plan":
      routeReturn = `/patients/edit/${patId}/plan`;
      break;

    case "statistics":
      routeReturn = `/patients/edit/${patId}/statistics`;
      break;

    case "statistics-analytics":
      routeReturn = `/patients/edit/${patId}/statistics-anlytics`;
      break;

    case "control-room":
      routeReturn = `/patients/edit/${patId}/control-room`;
      break;

    case "report":
      routeReturn = `/patients/edit/${patId}/report`;
      break;

    case "medical-resource":
      routeReturn = `/patients/edit/${patId}/medical-resource`;
      break;

    case "physio-resource":
      routeReturn = `/patients/edit/${patId}/physio-resource`;
      break;

    case "settings":
      routeReturn = `/patients/edit/${patId}/settings`;
      break;

    case "diary":
      routeReturn = `/patients/edit/${patId}/diary`;
      break;

    default:
      routeReturn = `/patients/edit/${patId}`;
      break;
  }
  return routeReturn;
};
