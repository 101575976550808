import Grid from "@material-ui/core/Grid";
import React from "react";
import { theme } from "../../../../../components/theme";
import { Box, Typography } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";

export const SelectedExercise = ({ index, row }) => {
  return (
    <Draggable key={index} draggableId={`${index}`} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            background: theme.colors.primary.lightBlue,
            borderRadius: 4,
            padding: 8,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography>{row?.name} </Typography>
            </Grid>
            <Grid item xs={12}>
              {row?.url ? (
                row?.extention === "mp4" || row?.extention === "mov" ? (
                  <video
                    controls
                    src={row.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    src={row.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </Draggable>
  );
};
