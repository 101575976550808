import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { theme } from "../../components/theme";
import {
  getAllCalendarActivityByMonitoring,
  getCalendarActivityById,
} from "../../models/actions/CalendarActivity";
import moment from "moment";
import {
  handleEventDetailSelected,
  SelectPeriod,
} from "../groups/situation/SituationTableHelper";
import SpLoader from "../../components/atoms/SpLoader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getPresentationsByPatProf } from "../../models/actions/Presentation";
import { getMonitoringData } from "../../models/actions/Monitoring";
import SpDialog from "../../components/atoms/SpDialog";
import AgendaCalendarEventCard from "../agenda/AgendaCalendarEventCard";
import { useForm } from "react-hook-form";
import { getAllFeedbackParameters } from "../../models/actions/Activity";
import { titleCase } from "../../utils/common";

const Diary = ({ ...props }) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [events, setEvents] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(
    moment().subtract(1, "months")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [openDetailActivity, setOpenDetailActivity] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [presentations, setPresentations] = useState([]);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const minutesActivitiesCount = useRef(0);

  const { patId } = useParams();
  const history = useHistory();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  //Fetching patient data
  const fetchData = async () => {
    try {
      setLoading(true);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      let responseEvents = await getAllCalendarActivityByMonitoring({
        id_patient: patId,
        start_date: filterStartDate,
        end_date: filterEndDate,
        hide_feedbacks: true,
      });
      responseEvents = responseEvents.sort((a, b) =>
        moment(b.start_date).diff(moment(a.start_date))
      );

      const temoLoadPar = await getAllFeedbackParameters();

      setLoadParametersData(temoLoadPar);
      setEvents(responseEvents);

      const response = await getPresentationsByPatProf({
        id_patient: patId,
      });

      let newResponseArray = [
        { id: -1, name: labels.patient.patientPlan.defaultSelectValue },
      ];
      response.map((elem) => {
        if (elem.region) {
          newResponseArray.push({
            id: elem?.id,
            name:
              (elem.pathology ? elem.pathology?.name : elem.type) +
              " " +
              elem.region?.name.toLowerCase(),
          });
        }
      });
      setPresentations(newResponseArray);

      setLoading(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.medicalNotes.report.date,
      isAction: false,
      width: "10%",
    },
    {
      id: "professional",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.professional,
      isAction: false,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: labels.requestsList.activity,
      isAction: false,
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: labels.requestsList.note,
      isAction: false,
      width: "40%",
    },
  ];

  const DiaryRow = ({ row, index }) => {
    return (
      <>
        <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
          <SpTableCell>
            <SpIconAndTextButton
              id={`date-${row.id}`}
              isIconRight={true}
              text={moment(row?.start_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
              textVariant={"tableTextDetails"}
              onClick={async () => {
                const res = await getCalendarActivityById({
                  id: row.id,
                });
                if (res.patients.length > 0) {
                  if (
                    Array.isArray(res.patients[0]?.patientFeedbacks) &&
                    res.patients[0].patientFeedbacks.length > 0
                  ) {
                    const temp = res.patients[0].patientFeedbacks?.map(
                      (el) => el.feedback_parameter
                    );

                    setLoadParametersList(temp);
                  }
                }

                handleEventDetailSelected({
                  activityData: row,
                  minutesActivitiesCount,
                  setSelectedActivity,
                  setOpenDetailDialog,
                  setOpenDetailActivity,
                  openDetailActivity,
                });
              }}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 15,
                  color: theme.colors.primary.lightBlue,
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {titleCase(
                `${row?.monitoring?.professional?.name} ${row?.monitoring?.professional?.surname}`
              )}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row?.activity_datum?.activity_type?.name}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row?.event_note}</SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  useEffect(() => {
    fetchData();
  }, [filterStartDate, filterEndDate]);

  return (
    <div>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item container xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.sideBarPatEdit.diary.toUpperCase()}
              </SpText>
            </Grid>
          </Grid>
          <div style={{ width: "100%" }}>
            <SelectPeriod
              setFilterStartDate={setFilterStartDate}
              filterStartDate={filterStartDate}
              setFilterEndDate={setFilterEndDate}
              filterEndDate={filterEndDate}
            />
          </div>
          <Grid item xs={12}>
            <SpTable
              pagination={true}
              headCells={headCells}
              rows={events}
              rowKey="id"
              padding={false}
              notCheckable={false}
              tableContainerMaxHeight={"calc(100vh - 320px)"}
            >
              <DiaryRow />
            </SpTable>
          </Grid>
        </Grid>
      </Grid>

      <SpDialog
        style={{ display: "flex", flex: 1 }}
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
      >
        <AgendaCalendarEventCard
          event={selectedActivity}
          fetchData={() => {}}
          setOpenDetailActivity={setOpenDetailActivity}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          forceDisabled={true}
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          presentations={presentations}
          getValues={getValues}
          setValue={setValue}
        />
      </SpDialog>
    </div>
  );
};

export default withSnackbar(Diary);
