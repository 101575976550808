import React from "react";
import SpText from "./SpText";
import { Grid } from "@material-ui/core";
import { psTranslate } from "../../pages/shared/translations";

const SpCalendarDragAndDropItems = ({
  activitiesAvailable,
  handleDragStart,
}) => (
  <Grid
    xs={1}
    style={{
      position: "sticky",
      top: 0,
      height: "100%",
      marginTop: "58px",
    }}
    key={"activity_type_list"}
  >
    {activitiesAvailable.map((p) => (
      <div
        id={`activity-${p.key}`}
        className="rbc-event dd-calendar-items"
        draggable="true"
        onDrag={(e) => {
          e.preventDefault();
          handleDragStart(p);
        }}
        key={p.key}
      >
        <div
          className="rbc-event-label"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={require(`../../assets/activities/${p.key}.png`)}
              style={{
                maxWidth: 25,
                maxHeight: 25,
                minWidth: 10,
                minHeight: 10,
              }}
              className="img-responsive"
              alt="image"
            />
            <div>
              <SpText variant="textWhite">{psTranslate(p.name)}</SpText>
            </div>
          </div>
        </div>
      </div>
    ))}
  </Grid>
);

export default SpCalendarDragAndDropItems;
