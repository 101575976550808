import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpButton from "../../../components/atoms/SpButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpText from "../../../components/atoms/SpText";
import {
  getAssessmentMonitList,
  getAssessmentPresentationList,
  getAssessmentTemplateDetail,
} from "../../../models/actions/Assessment";
import { labels } from "../../shared/translations";

const AssessmentInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,
  event,
  setValue,
  register,
  isGroupCard,
  disabled = false,
  isPrint = false,
}) => {
  const [viewVisible, setViewVisible] = useState(false);

  const history = useHistory();
  const { patId } = useParams();

  useEffect(async () => {
    const templateDetail = await getAssessmentTemplateDetail({
      id_template: selectedTemplate,
    });
    if (templateDetail) {
      setValue("assessment_motivation", templateDetail.name);
    }
  }, [watchTemplateSelect]);

  useEffect(async () => {
    if (templateData) {
      setValue("assessment_motivation", templateData.assessment_motivation);

      //If existing assessment for this template -> show view button
      if (templateData.id_template) {
        const assessments = await (event.id_presentation
          ? getAssessmentPresentationList({
              id_patient: event.patient?.id ?? patId,
              id_presentation: event.id_presentation,
            })
          : getAssessmentMonitList({ id_patient: event.patient?.id ?? patId }));
        const detail = assessments.find(
          (a) => a.id_template === templateData.id_template
        );
        if (detail) setViewVisible(true);
      }
    }
  }, [templateData]);

  const navigateTo = (viewOrRepeat, patientId) => {
    const baseURL = `/patients/edit/${patientId}/${
      event.id_presentation
        ? `presentation/${event.id_presentation}`
        : "monitoring"
    }`;
    const params = `assessments=1&idTemplate=${
      templateData?.id_template ?? 0
    }&viewOrRepeat=${viewOrRepeat ? 1 : 0}&date=${moment(
      event.start
    ).valueOf()}`;
    history.push(`${baseURL}?${params}`);
  };

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid item xs={12}>
        <SpTextInput
          name="assessment_motivation"
          inputRef={register}
          formControlStyle={{ width: "100%" }}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
      </Grid>
      {/* Card for patient */}
      {event && !event.is_from_template && !isGroupCard && (
        <Grid container item xs={12} style={{ marginTop: "15px" }} spacing={1}>
          <Grid item xs={6}>
            {
              <SpButton
                buttonType="accept"
                text={labels.patient.viewPlan.detailActivity.compile}
                variant="h1PageTitle"
                onClick={() => navigateTo(false, event.patient?.id ?? patId)}
                style={{ width: "100%" }}
              />
            }
          </Grid>
          {
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.viewPlan.detailActivity.view}
                variant="h1PageTitle"
                onClick={() => navigateTo(true, event.patient?.id ?? patId)}
                style={{ width: "100%" }}
              />
            </Grid>
          }
        </Grid>
      )}
      {/* Card for group */}
      {event && !event.is_from_template && isGroupCard && (
        <Grid container item xs={12} style={{ marginTop: "15px" }} spacing={1}>
          {event.patients.map((patient) => (
            <Grid item container direction="row" xs={12}>
              <Grid item direction="column">
                <SpText>{patient.patientName}</SpText>
              </Grid>

              <Grid item container direction="row" xs={12}>
                <Grid item xs={6}>
                  <SpButton
                    buttonType="accept"
                    text={labels.patient.viewPlan.detailActivity.compile}
                    variant="h1PageTitle"
                    onClick={() => navigateTo(false, patient.id)}
                    style={{ width: "100%", marginRight: "8px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    buttonType="accept"
                    text={labels.patient.viewPlan.detailActivity.view}
                    variant="h1PageTitle"
                    onClick={() => navigateTo(true, patient.id)}
                    style={{ width: "100%", marginLeft: "8px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default AssessmentInputDetails;
