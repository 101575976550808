import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../shared/translations";
import {
  computeInRange,
  genericIsEnabled,
  getMomentIdFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const moment = extendMoment(Moment);

const MIN_STUDY_DAYS = 1;
const MAX_STUDY_DAYS = 999;

const StudyStrain = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [daysBefore, setDaysBefore] = useState(7);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const strainOverPrevDays = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          accum[studyParamKey] = computeInRange(
            dateRange,
            by,
            (range) =>
              moment
                .rangeFromInterval("day", -daysBefore, range.end)
                .reverseBy("day", { excludeStart: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            (values) => {
              const sum = values.reduce((a, b) => a + b, 0);
              const average = sum / values.length;
              const stddev = Math.sqrt(
                values.reduce(
                  (acc, val) => acc + Math.pow(val - average, 2),
                  0
                ) / values.length
              );
              const monotony = stddev !== 0 ? average / stddev : 0;
              return monotony * sum;
            }
          );
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && isEnabled()) {
      onFetchDateDaysChange(-daysBefore);
      newData = {
        [study.key]: {
          "": strainOverPrevDays(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { "": {} } };
    }
    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView, daysBefore]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
      if (config.daysBefore)
        setDaysBefore(config.daysBefore);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        daysBefore: daysBefore,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    >
      <Grid container item xs={12} alignItems={"center"}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.period}
          value={daysBefore}
          onChange={(evnt) => {
            const value = evnt.target.value;
            if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
              setDaysBefore(value);
          }}
          type="number"
        />
      </Grid>
    </StudySharedControls>
  );
};

export default StudyStrain;
