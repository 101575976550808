import { Grid, useTheme } from "@material-ui/core";
import React from "react";
import SpTextInput from "../../components/atoms/SpTextInput";
import { labels } from "../shared/translations";
import SpButton from "../../components/atoms/SpButton";
import { useMediaQuery } from "react-responsive";

export const RegistrationPage = ({
  setPrivacyPolicy,
  privacyPolicy,
  name,
  surname,
  email,
  password,
  ragionesociale,
  address,
  city,
  cap,
  paese,
  piva,
  fiscalcode,
  createProfessional,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  return !isSmallScreen ? (
    <Grid xs={12} container direction="row" spacing={3}>
      <Grid item xs={6}>
        <p className="titlePrice">
          {labels.patient.patientRegistry.registration}
        </p>
      </Grid>
      <Grid item xs={6}>
        <p className="titlePrice">{labels.patient.patientRegistry.billing}</p>
      </Grid>
      <Grid container item xs={6} direction="column" spacing={1}>
        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="name"
            displayLabel={true}
            label={labels.patient.patientRegistry.givenName}
            placeholder={labels.patient.patientRegistry.givenName}
            style={{
              width: "100%",
            }}
            inputRef={name}
          />
        </Grid>

        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="email"
            displayLabel={true}
            label={"Email"}
            placeholder={"Email"}
            style={{
              width: "100%",
            }}
            inputRef={email}
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="surname"
            displayLabel={true}
            label={labels.patient.patientRegistry.familyName}
            placeholder={labels.patient.patientRegistry.familyName}
            style={{
              width: "100%",
            }}
            inputRef={surname}
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            type="password"
            isRequired={true}
            id="password"
            displayLabel={true}
            label={"Password"}
            placeholder={"Password"}
            style={{
              width: "100%",
            }}
            inputRef={password}
          />
        </Grid>
      </Grid>
      <Grid container item xs={6} direction="column" spacing={1}>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.business_name}
            placeholder={labels.account.accountForm.business_name}
            style={{
              width: "100%",
            }}
            inputRef={ragionesociale}
          />
        </Grid>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.address}
            placeholder={labels.account.accountForm.address}
            style={{
              width: "100%",
            }}
            inputRef={address}
          />
        </Grid>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.city}
            placeholder={labels.account.accountForm.city}
            style={{
              width: "100%",
            }}
            inputRef={city}
          />
        </Grid>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.cap}
            placeholder={labels.account.accountForm.cap}
            style={{
              width: "100%",
            }}
            inputRef={cap}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.nation}
            placeholder={labels.account.accountForm.nation}
            style={{
              width: "100%",
            }}
            inputRef={paese}
          />
        </Grid>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.piva}
            placeholder={labels.account.accountForm.piva}
            style={{
              width: "100%",
            }}
            inputRef={piva}
          />
        </Grid>
        <Grid item xs={4}>
          <SpTextInput
            isRequired={true}
            id="password"
            displayLabel={true}
            label={labels.account.accountForm.fiscalCode}
            placeholder={labels.account.accountForm.fiscalCode}
            style={{
              width: "100%",
            }}
            inputRef={fiscalcode}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div>
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            checked={privacyPolicy}
            onChange={() => {
              setPrivacyPolicy(!privacyPolicy);
            }}
          />
          <label htmlFor="privacyPolicy" style={{ color: "white" }}>
            Accept the{" "}
            <a
              style={{ color: "white" }}
              href="https://mypowerset.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </label>
        </div>
      </Grid>
      <Grid item xs={12}>
        <SpButton
          disabled={!privacyPolicy}
          buttonType={"accept"}
          style={{ marginTop: "2em" }}
          onClick={createProfessional}
          text={labels.manageUsers.payment}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid xs={12} container direction="row" spacing={3}>
      <Grid item xs={12}>
        <p className="titlePrice">
          {labels.patient.patientRegistry.registration}
        </p>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={1}>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="name"
            displayLabel={true}
            label={labels.patient.patientRegistry.givenName}
            placeholder={labels.patient.patientRegistry.givenName}
            style={{
              width: "100%",
            }}
            inputRef={name}
          />
        </Grid>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="surname"
            displayLabel={true}
            label={labels.patient.patientRegistry.familyName}
            placeholder={labels.patient.patientRegistry.familyName}
            style={{
              width: "100%",
            }}
            inputRef={surname}
          />
        </Grid>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="email"
            displayLabel={true}
            label={"Email"}
            placeholder={"Email"}
            style={{
              width: "100%",
            }}
            inputRef={email}
          />
        </Grid>

        <Grid item xs={12}>
          <SpTextInput
            type="password"
            isRequired={true}
            id="password"
            displayLabel={true}
            label={"Password"}
            placeholder={"Password"}
            style={{
              width: "100%",
            }}
            inputRef={password}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <p className="titlePrice">{labels.patient.patientRegistry.billing}</p>
      </Grid>

      <Grid container item xs={12} direction="row" spacing={1}>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="ragionesociale"
            displayLabel={true}
            label={labels.account.accountForm.business_name}
            placeholder={labels.account.accountForm.business_name}
            style={{
              width: "100%",
            }}
            inputRef={ragionesociale}
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="address"
            displayLabel={true}
            label={labels.account.accountForm.address}
            placeholder={labels.account.accountForm.address}
            style={{
              width: "100%",
            }}
            inputRef={address}
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="city"
            displayLabel={true}
            label={labels.account.accountForm.city}
            placeholder={labels.account.accountForm.city}
            style={{
              width: "100%",
            }}
            inputRef={city}
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="cap"
            displayLabel={true}
            label={labels.account.accountForm.cap}
            placeholder={labels.account.accountForm.cap}
            style={{
              width: "100%",
            }}
            inputRef={cap}
          />
        </Grid>

        <Grid item xs={6}>
          <SpTextInput
            isRequired={true}
            id="paese"
            displayLabel={true}
            label={labels.account.accountForm.nation}
            placeholder={labels.account.accountForm.nation}
            style={{
              width: "100%",
            }}
            inputRef={paese}
          />
        </Grid>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="piva"
            displayLabel={true}
            label={labels.account.accountForm.piva}
            placeholder={labels.account.accountForm.piva}
            style={{
              width: "100%",
            }}
            inputRef={piva}
          />
        </Grid>
        <Grid item xs={12}>
          <SpTextInput
            isRequired={true}
            id="fiscalcode"
            displayLabel={true}
            label={labels.account.accountForm.fiscalCode}
            placeholder={labels.account.accountForm.fiscalCode}
            style={{
              width: "100%",
            }}
            inputRef={fiscalcode}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div>
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            checked={privacyPolicy}
            onChange={() => {
              setPrivacyPolicy(!privacyPolicy);
            }}
          />
          <label htmlFor="privacyPolicy" style={{ color: "white" }}>
            Accept the{" "}
            <a
              style={{ color: "white" }}
              href="https://mypowerset.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </label>
        </div>
      </Grid>
      <Grid item xs={12}>
        <SpButton
          disabled={!privacyPolicy}
          buttonType={"accept"}
          style={{ marginTop: "2em" }}
          onClick={createProfessional}
          text={labels.manageUsers.payment}
        />
      </Grid>
    </Grid>
  );
};
