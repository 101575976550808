import React, { useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { Grid } from "@material-ui/core";

const A5_RestDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
  isPrint = false,
}) => {
  const data = event.data;
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
        {data?.rest_motivation}
      </SpText>

      {allowFeedback && (
        <Grid item xs={12} style={{ paddingTop: "1em" }}>
          <SpButton
            style={{ width: "100%" }}
            text={
              showFeedback
                ? labels.patient.viewPlan.addActivity.inputForm.hideFeedbacks
                : labels.patient.viewPlan.addActivity.inputForm.showFeedbacks
            }
            onClick={() => setShowFeedback(!showFeedback)}
          />
        </Grid>
      )}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ))}
    </>
  );
};

export default A5_RestDisabledDetails;
