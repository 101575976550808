import React from "react";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { labels } from "../../shared/translations";

import {
  ResponsiveContainer,
  AreaChart,
  BarChart,
  XAxis,
  YAxis,
  Area,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { theme } from "../../../components/theme";

const TrainingReportRatio = (props) => {
  if (props.type === "Area") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={props.dataChart}>
          <defs>
            <linearGradient id="colorUvarea" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{
              value: labels.injSur.yLabR,
              angle: -90,
              style: { fill: "grey" },
            }}
            labelOffset={-20}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
            //formatter={(value, name) => [value.toFixed(1), name]}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={props.labR}
            stroke={theme.colors.surveillance.lightBlue}
            fillOpacity={1}
            fill="url(#colorUvarea)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  } else {
    // Calculate ratio between labT and labM
    for (let i = 0; i < props.dataChart.length; i++) {
      const labTValue = props.dataChart[i][props.labT];
      const labMValue = props.dataChart[i][props.labM];
      if (labMValue === 0) {
        props.dataChart[i][labels.injSur.yLabR] = labTValue;
      } else {
        props.dataChart[i][labels.injSur.yLabR] = labTValue / labMValue;
      }
    }

    return (
      <ResponsiveContainer
        width="100%"
        height={500 + props.dataChart.length * 10}
      >
        <BarChart
          data={props.dataChart}
          layout="vertical"
          margin={{ top: 0, right: 20, left: 80, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorUvbar" x1="1" y1="0" x2="0" y2="0">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightBlue}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            type="number"
            stroke="grey"
            label={{
              value: labels.injSur.yLabR,
              angle: 0,
              style: { fill: "grey" },
              dy: 15,
            }}
          />
          <YAxis type="category" dataKey="patientName" stroke="grey" />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
            formatter={(value, name) => [value.toFixed(1), name]}
          />
          <Legend wrapperStyle={{ color: "gray" }} verticalAlign="top" />
          <CartesianGrid
            stroke="#f5f5f530"
            vertical={true}
            horizontal={false}
          />
          <Bar
            type="monotone"
            dataKey={labels.injSur.yLabR}
            fill="#66d9ff70"
            stroke="#FFFFFF80"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default withSnackbar(TrainingReportRatio);
