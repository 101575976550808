import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { isMobile } from "react-device-detect";
import PatientsMonitoringAddProms from "../patientsMonitoring/PatientsMonitoringAddProms";

import {
  getAllPromsSurveys,
  getPromsAnswersByMonitoring,
} from "../../../models/actions/Proms";
import moment from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import NoData from "../shared/utils/NoData";
import { Controller, useForm } from "react-hook-form";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { Grid } from "@material-ui/core";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import { FilterAutocomplete } from "../patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  findPatientByPk,
  saveControlQuestionnaires,
} from "../../../models/actions/Patients";
import PromsTableDetails from "./PromsTableDetails";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
});

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "33%",
  flexDirection: "column",
});

const PatientsMonitoringStep3 = (props) => {
  const [displayProms, setDisplayProms] = useState([]);
  const [addPresentationProms, setAddPresentationProms] = useState(false);
  const [detailPresentationProms, setDetailPresentationProms] = useState(false);
  const [selectedProms, setSelectedProms] = useState();
  const [promsQuestList, setPromsQuestList] = useState([]);
  const [sendNotification, setSendNotification] = useState(false);
  const [promSelected, setPromSelected] = useState(null);

  const [selectedDays, setSelectedDays] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();

  useEffect(() => {}, []);
  const { patId } = useParams();
  const { endDate } = props;
  const { setLoading } = props;

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm({
      defaultValues: {
        proms: null,
        frequency: null,
        duration: null,
      },
      shouldUnregister: false,
    });

  const fetchData = async () => {
    try {
      setLoading(true);
      // const responseDetail = await findPatientByPk({
      //   userId: props?.patId ? props?.patId : patId,
      // });
      setCurrentPatientDetail(
        props.patient
          ? props.patient
          : await findPatientByPk({
              userId: props?.patId ? props?.patId : patId,
            })
      );
      if (!props.promsAnswers) {
        const promsByMonitoring = await getPromsAnswersByMonitoring({
          id_patient: props?.patId ? props?.patId : patId,
        });
        if (promsByMonitoring) {
          setDisplayProms(promsByMonitoring);
        }
      } else {
        setDisplayProms(props.promsAnswers);
      }
      const resultProms = props?.promsQuestList
        ? props?.promsQuestList
        : await getAllPromsSurveys();
      setPromsQuestList([...resultProms]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!addPresentationProms) {
      fetchData();
    }
  }, [addPresentationProms]);

  const closeAssessmentAdd = () => {
    setAddPresentationProms(false);
  };
  const handlePromsDetail = async (selProms) => {
    setSelectedProms(selProms.id_proms);
    setDetailPresentationProms(true);
  };

  // Columns
  const headCellsPresentation = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.presentation.detail.stepper.step6.table.header.type.toUpperCase(),
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.presentation.detail.stepper.step6.table.header.date.toUpperCase(),
      isAction: false,
    },
  ];

  const _onRowClick = (proms) => {
    if (proms?.monitoring_proms_answers?.length === 0) {
      props.snackbarShowErrorMessage(labels.patient.proms.errors.no_answers);
    } else {
      handlePromsDetail(proms);
    }
  };

  const PromsRow = ({ row }) => {
    return (
      <>
        <SpTableRow key={row.id} tabIndex={-1}>
          <SpTableCell
            style={{ cursor: "pointer" }}
            onClick={() => _onRowClick(row)}
          >
            <SpText variant="tableText">
              {psTranslate(row.prom?.name?.toUpperCase())}
            </SpText>
          </SpTableCell>
          <SpTableCell
            style={{ cursor: "pointer" }}
            onClick={() => _onRowClick(row)}
          >
            <SpText variant="tableText">
              {row.date ? moment(row.date).format("DD/MM/YYYY") : ""}
            </SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const saveControlsQuestionnaires = async () => {
    try {
      if (selectedDays.length === 0) {
        props.snackbarShowErrorMessage(
          labels.patient.controlQuestions.insertAllData
        );
        return;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // get email's patient
      const saveResponse = await saveControlQuestionnaires({
        id_patients: [props?.patId ? props?.patId : patId],
        id_questionnaires: promSelected,
        frequency: getValues().frequency,
        days: selectedDays.map(({ id }) => id),
        monitoring_questions: promSelected.is_monit_question,
        emails: sendNotification ? [currentPatient.email] : null,
        tz: timeZone,
        start_time: moment().format("HH:mm"),
      });
      if (saveResponse?.message) {
        props.snackbarShowMessage(saveResponse.message);
        reset({
          proms: null,
          frequency: null,
        });
        setPromSelected(null);
        setSelectedDays([]);
      } else {
        props.snackbarShowErrorMessage(saveResponse.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <StyledBodyColumn>
      {!props?.patId && (
        <>
          <Grid container direction="row" style={{ width: "100%" }}>
            {!detailPresentationProms && !addPresentationProms && (
              <Grid item xs={5} style={{ width: "100%" }}>
                <form onSubmit={handleSubmit(saveControlsQuestionnaires)}>
                  <Grid item xs={12}>
                    <Controller
                      style={{ marginTop: "3%" }}
                      render={(props) => (
                        <SpSelect
                          label={
                            labels.patient.controlQuestions.selectProms.choose
                          }
                          value={props.value}
                          formControlWidth={400}
                          selectPlaceholder={
                            labels.patient.controlQuestions.selectProms
                              .placeholder
                          }
                          onChange={(e) => {
                            props.onChange(e.target.value);
                            setPromSelected(e.target.value);
                          }}
                        >
                          {promsQuestList.map((p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {p.name
                                ? psTranslate(p.name)
                                : labels.patient.controlQuestions.selectProms
                                    .placeholder}
                            </SpSelectMenuItem>
                          ))}
                        </SpSelect>
                      )}
                      defaultValue={""}
                      name={"proms"}
                      control={control}
                    />
                    {
                      <SpCheckbox
                        label={
                          labels.patient.controlQuestions.selectProms
                            .sendNotification
                        }
                        rightlabel={true}
                        formControlStyle={{
                          justifyContent: "flex-start",
                          marginTop: "8px",
                        }}
                        checked={sendNotification}
                        onChange={(evnt) => {
                          setSendNotification(evnt.target.checked);
                        }}
                      />
                    }
                  </Grid>
                  {promSelected && (
                    <Grid item container xs={12} spacing={1} direction="row">
                      <Grid item xs={12}>
                        <FilterAutocomplete
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .title
                          }
                          value={selectedDays}
                          onChange={(_, newValue) => {
                            setSelectedDays(newValue);
                          }}
                          renderOptions={selectedDays}
                          options={labels.week}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: "0.5em" }}>
                        <SpTextInput
                          style={{ minWidth: 500, width: "100%" }}
                          name="frequency"
                          label={labels.patient.controlQuestions.selectFrequency.placeholder.toUpperCase()}
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .placeholder
                          }
                          inputRef={register}
                          type="number"
                          minValue={1}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {promSelected && (
                    <Grid item xs={12}>
                      <SpButton
                        style={{ minWidth: 300 }}
                        buttonType="accept"
                        type="submit"
                        variant="column"
                        text={labels.patient.controlQuestions.sendQuestionButton.toUpperCase()}
                      />
                    </Grid>
                  )}
                </form>
              </Grid>
            )}
            {!detailPresentationProms &&
              !addPresentationProms &&
              !addPresentationProms &&
              !detailPresentationProms &&
              !promSelected && (
                <Grid
                  item
                  xs={2}
                  style={{ width: "100%", paddingTop: "1.8em" }}
                >
                  <SpText variant="tableText">
                    {labels.patient.controlQuestions.selectProms.or.toUpperCase()}
                  </SpText>
                </Grid>
              )}
            <Grid item xs={5} style={{ width: "100%", paddingTop: "1.8em" }}>
              {!endDate &&
                !addPresentationProms &&
                !detailPresentationProms &&
                !promSelected && (
                  <SpButton
                    style={{ width: "100%" }}
                    buttonType="accept"
                    variant="none"
                    text={labels.patient.controlQuestions.selectProms.insertManually.toUpperCase()}
                    onClick={() =>
                      setAddPresentationProms(!addPresentationProms)
                    }
                  />
                )}
            </Grid>
          </Grid>
        </>
      )}

      {detailPresentationProms && (
        <SpButton
          buttonType="accept"
          variant="none"
          style={{ width: "100%" }}
          text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
          onClick={() => setDetailPresentationProms(false)}
        />
      )}
      {displayProms.length === 0 && (
        <NoData label={labels.patient.monitoring.proms.noProms} />
      )}
      {!addPresentationProms ? (
        displayProms?.length > 0 &&
        !detailPresentationProms && (
          <StyledTableColumn>
            <StyledRow>
              <SpTable
                headCells={headCellsPresentation}
                pagination={true}
                rows={displayProms}
                rowKey="id"
                padding={false}
                notCheckable={false}
                tableContainerMaxHeight={
                  isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                }
              >
                <PromsRow />
              </SpTable>
            </StyledRow>
          </StyledTableColumn>
        )
      ) : (
        <PatientsMonitoringAddProms
          setLoading={setLoading}
          closeAssessmentAdd={closeAssessmentAdd}
        />
      )}

      {detailPresentationProms && (
        <PromsTableDetails
          patId={props?.patId ? props?.patId : patId}
          closeAssessmentAdd={closeAssessmentAdd}
          proms={selectedProms}
          setLoading={setLoading}
        />
      )}
    </StyledBodyColumn>
  );
};

export default withSnackbar(PatientsMonitoringStep3);
