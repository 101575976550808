import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { theme } from "../../../../components/theme.js";
import PatientInjuryRiskDetails from "../../patientInjuryRiskDetails.js";
import {
  controlRoomStep,
  getLabelRangeFromPatientStatus,
  getUserPermission,
  riskScoreThreshold,
} from "../../../../utils/common.js";
import { labels } from "../../../shared/translations";
import { useAuth0 } from "@auth0/auth0-react";

export const ChartSummary = ({
  currentPatient,
  selectedStep,
  setSelectedStep,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [injuryRisk, setInjuryRisk] = useState(false);

  const fetchData = async () => {
    const temIinjuryRisk = await getUserPermission(
      "injury-risk",
      getAccessTokenSilently
    );
    setInjuryRisk(temIinjuryRisk);
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  if (currentPatient)
    currentPatient = {
      ...currentPatient,
      injury_risk: JSON.parse(currentPatient?.injury_risk),
    };

  const baseColor = "red";

  // Function to get the color based on the numerical value
  const getColorInj = (value) => {
    if (value > riskScoreThreshold.hight) {
      return "green";
    } else if (value > riskScoreThreshold.moderate) {
      return "orange";
    } else if (value > riskScoreThreshold.low) {
      return "tomato";
    } else {
      return baseColor;
    }
  };

  // Function to get the color based on the numerical value
  const getColorRead = (value) => {
    if (value > 80) {
      return "green";
    } else if (value > 60) {
      return "lightGreen";
    } else if (value > 40) {
      return "orange";
    } else if (value > 20) {
      return "tomato";
    } else {
      return baseColor;
    }
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {injuryRisk && (
        <>
          <Grid item xs={6}>
            <h1
              style={{
                color: theme.colors.primary.white,
                textAlign: "center",
                fontSize: 40,
                backgroundColor: "#3d988e80",
              }}
            >
              INJURY RISK
            </h1>
            <h1
              style={{
                color: getColorInj(100 - currentPatient?.injury_risk?.score),
                fontSize: 80,
                textAlign: "center",
                backgroundColor: "#3d988e80",
              }}
            >
              {currentPatient?.injury_risk?.score
                ? getLabelRangeFromPatientStatus({
                    value: currentPatient.injury_risk.score,
                    threshold1: riskScoreThreshold.low,
                    threshold2: riskScoreThreshold.moderate,
                    threshold3: riskScoreThreshold.hight,
                  })
                : labels.patient.injuryRisk.low}
            </h1>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <h1
              style={{
                color: theme.colors.primary.white,
                textAlign: "center",
                fontSize: 40,
                backgroundColor: "#3d988e80",
              }}
            >
              READINESS
            </h1>
            <h1
              style={{
                color: getColorRead(100 - currentPatient?.injury_risk?.score),
                fontSize: 80,
                textAlign: "center",
                backgroundColor: "#3d988e80",
              }}
            >
              {100 - currentPatient?.injury_risk?.score + "%"}
            </h1>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <PatientInjuryRiskDetails
          selectedRow={currentPatient}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
        />
      </Grid>
    </Grid>
  );
};
