import { sendGenericRiskFactor } from "../../../models/actions/GenericRiskFactor";
import { labels, psTranslate } from "../../shared/translations";

const emptyTemplateValues = {
  name: "",
  sport: "",
  sex: "",
  rfStructure: null,
  rfDysfunction: null,
  rfDysfunctionType: null,
  significative: null,
  intrinsic: null,
  modifiable: null,
  notes: "",
  reference: "",
  impRegion: null,
  impArea: null,
  impStructure: null,
  impact: 0,
  rules: [],
};

export const handleNewRAS = ({
  inputs,
  setInputValues,
  impactedZone,
  setImpactedZones,
  percentageRef,
  weightRef,
  zonesImpacted,
  setZonesImpacted,
}) => {
  if (impactedZone.region !== null && weightRef !== "") {
    const tempZone = {
      region: impactedZone.region,
      area: impactedZone.area ?? "",
      structure: impactedZone.structure ?? "",
      percentage: percentageRef === "" ? "1" : percentageRef,
      weight: weightRef,
    };
    if (impactedZone.structure) {
      tempZone["zone"] = psTranslate(
        `Structure zone: ${impactedZone.structure.name}, %:${
          percentageRef === "" ? "1" : percentageRef
        }, weight: ${weightRef}`
      );
    } else if (impactedZone.area) {
      tempZone["zone"] = psTranslate(
        `Area zone: ${impactedZone.area.name}, %: ${
          percentageRef === "" ? "1" : percentageRef
        }, weight: ${weightRef}`
      );
    } else {
      tempZone["zone"] = psTranslate(
        `Region zone: ${impactedZone.region.name}, %: ${
          percentageRef === "" ? "1" : percentageRef
        }, weight: ${weightRef}`
      );
    }
    setZonesImpacted([...zonesImpacted, tempZone]);
  }
};

export const calculateRuleText = (
  ruleSelected,
  ruleInputs,
  thresholdRef,
  thresholdRef2
) => {
  if (ruleSelected === "test") {
    return `[${psTranslate(ruleInputs?.test?.name)}] - ${psTranslate(
      ruleInputs?.parameter?.name
    )}\n Subparam: ${psTranslate(
      ruleInputs?.subparameter
    )}\n Logic: ${psTranslate(
      ruleInputs?.logical?.label
    )} \n Threshold: ${thresholdRef} \n Threshold1: ${thresholdRef2}`;
  } else {
    return `${psTranslate(
      ruleInputs?.parameter?.name
    )}\n Subparam: ${psTranslate(
      ruleInputs?.subparameter
    )}\n Logic: ${psTranslate(
      ruleInputs?.logical?.label
    )}\n Threshold: ${thresholdRef}\n Threshold1: ${thresholdRef2}`;
  }
};

export const handleNewRule = ({
  ruleSelected,
  ruleInputs,
  thresholdRef,
  thresholdRef2,
  ruleAdded,
  setRuleAdded,
}) => {
  if (ruleInputs.parameter && ruleInputs.logical && thresholdRef) {
    let temprule = {
      type: ruleSelected, // workload, PROM or assessment
      anatomicalParts: ruleInputs?.anatomicalParts,
      test: ruleInputs?.test,
      param: ruleInputs.parameter,
      subparam: ruleInputs.subparameter,
      um: ruleInputs?.subparameter?.um,
      logical: ruleInputs.logical,
      threshold: thresholdRef,
      thresholdRef2: ruleInputs.logical.key === "betw" ? thresholdRef2 : null,
      // note: valutare se tenere
    };

    temprule["rule"] = calculateRuleText(
      ruleSelected,
      ruleInputs,
      thresholdRef,
      thresholdRef2
    );
    console.log("temprule", temprule);
    setRuleAdded([...ruleAdded, temprule]);
  }
};

export const collectRFAndSend = async ({
  rfnameRef,
  impactRef,
  setImpactRef,
  weightRef,
  setWeightRef,
  inputs,
  locks,
  setInputValues,
  impactedZone,
  setImpactedZone,
  zones,
  setZones,
  ruleInputs,
  setRuleInputs,
  thresholdRef,
  setThresholdRef,
  thresholdRef2,
  setThresholdRef2,
  rules,
  setRules,
  notesRef,
  scores,
  referencesRef,
  snackbarShowMessage,
  snackbarShowErrorMessage,
}) => {
  let collection = {
    name: rfnameRef,
    rfStructure: inputs.rfStructure,
    rfDysfunction: inputs.rfDysfunction,
    rfDysfunctionType: inputs.rfDysfunctionType,
    sport: inputs.sportSelected,
    sex: inputs.sexSelected,
    intrinsic: inputs.intrinsicSelected,
    modifiable: inputs.modifiableSelected,
    notes: notesRef,
    reference: referencesRef,
    impact: parseFloat(impactRef) || parseFloat(1),
    weight: parseFloat(weightRef) || parseFloat(1),
    zones: zones,
    rules: rules,
    scores: scores,
  };

  // check for non-empty fields:
  const resultCheckRequired = checkRequired({
    collection: collection,
  });

  // send to DB or return error if some fields are missing
  if (resultCheckRequired === 0) {
    const resultSend = await sendGenericRiskFactor({ rfData: collection });
    if (resultSend.message) {
      collection = { ...collection, id: resultSend?.newId };
      snackbarShowMessage(labels.mylab.generalRiskFactor.addComp.success);
      emptyInputTemplate({
        setWeightRef: setWeightRef,
        setImpactRef: setImpactRef,
        rfnameRef: rfnameRef,
        impactRef: impactRef,
        weightRef: weightRef,
        inputs: inputs,
        locks: locks,
        setInputValues: setInputValues,
        setImpactedZone: setImpactedZone,
        setZones: setZones,
        setRuleInputs: setRuleInputs,
        thresholdRef: thresholdRef,
        setThresholdRef: setThresholdRef,
        thresholdRef2: thresholdRef2,
        setThresholdRef2: setThresholdRef2,
        setRules: setRules,
        notesRef: notesRef,
        referencesRef: referencesRef,
      });
    } else {
      snackbarShowErrorMessage(
        labels.mylab.generalRiskFactor.addComp.error.insertion
      );
      return {};
    }
  } else {
    snackbarShowErrorMessage(resultCheckRequired);
    return {};
  }
  return { rfData: collection };
};

const checkRequired = ({ collection }) => {
  // check della sezione info
  let check = false;
  const requiredInfo = ["name", "intrinsic", "modifiable"];
  for (const field of requiredInfo) {
    if (collection[field] === emptyTemplateValues[field]) {
      check = true;
      break;
    }
  }
  if (check) {
    return labels.mylab.generalRiskFactor.addComp.error.required;
  }

  // check sulla sezione delle regole
  if (
    collection.rules.length === 0 &&
    collection.rfStructure === emptyTemplateValues.rfStructure
  ) {
    // se non viene completato almeno un campo nella sezione delle regole
    return labels.mylab.generalRiskFactor.addComp.error.other;
  } else if (
    collection.rfDysfunction !== emptyTemplateValues.rfDysfunction &&
    collection.rfDysfunctionType === emptyTemplateValues.rfDysfunctionType
  ) {
    // se viene completato solo il campo disfunznione (categoria) ma non quello della tipologia
    return labels.mylab.generalRiskFactor.addComp.error.type_missing;
  } else {
    return 0;
  }
};

const emptyInputTemplate = ({
  rfnameRef,
  impactRef,
  setImpactRef,
  weightRef,
  setWeightRef,
  inputs,
  locks,
  setImpactedZone,
  setZones,
  setInputValues,
  setRuleInputs,
  thresholdRef,
  setThresholdRef,
  setRules,
  notesRef,
  referencesRef,
}) => {
  /* In base ai blocca-valore inseriti nella dashboard
  vado a popolare un oggetto che verrà usato per memorizzare i valori iniziali
  dei vari elementi presenti
   */
  const updateInfoInputs = {
    sportSelected: locks.sportToggle ? inputs.sportSelected : null,
    sexSelected: locks.sexToggle ? inputs.sexSelected : null,
    rfStructure: locks.rfStructureToggle ? inputs.rfStructure : null,
    rfDysfunction: locks.rfDysfunctionToggle ? inputs.rfDysfunction : null,
    rfDysfunctionType: locks.rfDysfunctionTypeToggle
      ? inputs.rfDysfunctionType
      : null,
    impListToggle: locks.impListToggle,

    intrinsicSelected: locks.intrinsicToggle ? inputs.intrinsicSelected : null,
    modifiableSelected: locks.modifiableToggle
      ? inputs.modifiableSelected
      : null,
  };
  // empty input section
  setInputValues(updateInfoInputs);
  locks.nameToggle ? null : (rfnameRef = "");
  notesRef = "";
  referencesRef = "";
  // empty RAS section
  // TODO: impListToggle implementare
  const updateZones = { region: null, area: null, structure: null };
  setImpactedZone(updateZones);
  setImpactRef("");
  setWeightRef("");
  // empty RAS list

  locks.impListToggle ? {} : setZones([]);
  // empty add rule section
  // TODO: ruleListToggle implementare
  setRuleInputs({ parameter: null, logical: null });
  setThresholdRef("");
  // empty rule list
  locks.rulesListToggle ? {} : setRules([]);
};
