import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";
import PatientsReportsConfigurable from "./patientsReports/PatientsReportsConfigurable";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";

const PatientsReports = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const history = useHistory();

  useEffect(async () => {
    const responseDetail = await findPatientByPk({ userId: patId });
    setCurrentPatientDetail(responseDetail);
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsReportsConfigurable />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsReports);
