import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../../models/actions/Patients";
import PatientsEditSidebar from "../shared/PatientsEditSidebar";
import PatientsStatisticsAnalyticsFilters from "./patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFilters";
import PatientsStatisticsAnalyticsChar from "./patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsChar";
import moment from "moment";
import SpLoader from "../../../components/atoms/SpLoader";
import GroupEditSidebar from "../../groups/shared/GroupEditSidebar";
import { getGroupsById } from "../../../models/actions/Groups";
import { TempChart } from "./tempChart";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";

const PatientsStatisticsAnalytics = ({ ...props }) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [currentGroup, setCurrentGroup] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [filters, setFilters] = useState({
    startDate: moment().subtract(14, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    selected: "activity",
    dataChart: {},
    dataTable: {},
    dataTablePROMS: {},
    dataTableMeasurement: {},
    comparison: {
      unlinkedPatients: [],
      linkedPatients: [],
      groupsPatients: [],

      selectedUnlinkedPatients: [],
      selectedLinkedPatients: [],
      selectedGroupsPatients: [],
    },
    graph: {
      graphType: [],
      selectedGraphType: [],
    },
    activity: {
      activities: [],
      selectedActivites: [],
      studyParams: [],
      studyParamsPROMS: [],
      measurements: [],
      selectedMeasurements: [],
      selectedStudyParams: [],
      selectedStudyParamsPROMS: [],
      dateView: [],
      selectedDateView: [],
      assessmentsTemplates: [],
      selectedAssessmentsTemplates: [],
    },
    workloadStudy: {
      checkMonotony: false,
      monotonyPeriod: 7,
      checkStrain: false,
      strainPeriod: 7,
      checkACWRRA: false,
      ACWRRAAcute: 7,
      ACWRRAChronic: 28,
      checkACWREWMA: false,
      ACWREWMAAcute: 7,
      ACWREWMAChronic: 28,
      checkCOMPAREPERC: false,
      checkCOMPAREASS: false,
      checkCONFIDENCE: false,

      checkZscore: false,
      zScorePeriod: 7,
      checkPeriod: false,
      zScorePreviousValues: 7,
      checkPreviousValues: false,

      startDateFixedZscore: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedZscore: moment().format("YYYY-MM-DD"),
      checkFixedPeriod: false,
      zScoreSubjectPeriod: 7,
      checkSubjectPeriod: false,

      checkPreviousValueSubject: false,
      checkFixedPeriodSubject: false,

      checkPreviousPeriod: false,
      previousPeriodValue: 7,

      checkPreviousSubject: false,
      previousSubjectValue: 7,

      checkFixedSubject: false,
      startDateFixedSubject: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedSubject: moment().format("YYYY-MM-DD"),
    },
    promsStudy: {
      checkMonotony: false,
      monotonyPeriod: 7,
      checkStrain: false,
      strainPeriod: 7,
      checkACWRRA: false,
      ACWRRAAcute: 7,
      ACWRRAChronic: 28,
      checkACWREWMA: false,
      ACWREWMAAcute: 7,
      ACWREWMAChronic: 28,
      checkCOMPAREPERC: false,
      checkCOMPAREASS: false,
      checkCONFIDENCE: false,

      checkSYMMETRY: false,
      checkASYMMETRY: false,
      checkSYMMETRYASS: false,
      checkINDEXES: false,

      checkZscore: false,
      zScorePeriod: 7,
      checkPeriod: false,
      zScorePreviousValues: 7,
      checkPreviousValues: false,

      startDateFixedZscore: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedZscore: moment().format("YYYY-MM-DD"),
      checkFixedPeriod: false,
      zScoreSubjectPeriod: 7,
      checkSubjectPeriod: false,

      checkPreviousValueSubject: false,
      checkFixedPeriodSubject: false,

      checkPreviousPeriod: false,
      previousPeriodValue: 7,

      checkPreviousSubject: false,
      previousSubjectValue: 7,

      checkFixedSubject: false,
      startDateFixedSubject: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedSubject: moment().format("YYYY-MM-DD"),
    },
    measurementsStudy: {
      checkMonotony: false,
      monotonyPeriod: 7,
      checkStrain: false,
      strainPeriod: 7,
      checkACWRRA: false,
      ACWRRAAcute: 7,
      ACWRRAChronic: 28,
      checkACWREWMA: false,
      ACWREWMAAcute: 7,
      ACWREWMAChronic: 28,
      checkCOMPAREPERC: false,
      checkCOMPAREASS: false,
      checkCONFIDENCE: false,

      checkSYMMETRY: false,
      checkASYMMETRY: false,
      checkSYMMETRYASS: false,
      checkINDEXES: false,

      checkZscore: false,
      zScorePeriod: 7,
      checkPeriod: false,
      zScorePreviousValues: 7,
      checkPreviousValues: false,

      startDateFixedZscore: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedZscore: moment().format("YYYY-MM-DD"),
      checkFixedPeriod: false,
      zScoreSubjectPeriod: 7,
      checkSubjectPeriod: false,

      checkPreviousValueSubject: false,
      checkFixedPeriodSubject: false,

      checkPreviousPeriod: false,
      previousPeriodValue: 7,

      checkPreviousSubject: false,
      previousSubjectValue: 7,

      checkFixedSubject: false,
      startDateFixedSubject: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDateFixedSubject: moment().format("YYYY-MM-DD"),
    },
  });
  const { patId, groupId } = useParams();
  const history = useHistory();

  const fetchData = async () => {
    try {
      if (patId) {
        const responseDetail = await findPatientByPk({ userId: patId });
        if (responseDetail) {
          setCurrentPatientDetail(responseDetail);
        }
      }
      if (groupId) {
        const groupResults = await getGroupsById({ id_group: groupId });
        setCurrentGroup(groupResults);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        {loading && <SpLoader />}
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, {
            setLoading: setLoading,
            componentName: props.componentName,
          })
        )}
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12} container direction="row" spacing={1}>
            <PatientsStatisticsAnalyticsFilters
              filters={filters}
              setFilters={setFilters}
              data={data}
              setData={setData}
              setLoading={setLoading}
            />
            <PatientsStatisticsAnalyticsChar data={data} filters={filters} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsStatisticsAnalytics);
