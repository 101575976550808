import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { getAllPromsSurveys, getGroupProms } from "../../models/actions/Proms";
import { getGroupsById } from "../../models/actions/Groups";

import { SpStepper, StepTitle } from "../../components/bundles";
import GroupProms from "./GroupProms";
import ReadinessTable from "./readiness/ReadinessTable";
import { getUserPermission } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";

const GroupMonitoring = (props) => {
  const [promsQuestList, setPromsQuestList] = useState([]);

  const [currentGroup, setCurrentGroup] = useState([]);
  const [spMenuLabels, setspMenuLabels] = useState([]);
  const [patients, setPatients] = useState([]);
  const [readinessIsEnabled, setReadinessIsEnabled] = useState(false);
  const [isFederation, setIsFederation] = useState(false);
  const [permissionProms, setPermissionProms] = useState(false);

  const { groupId } = useParams();
  const history = useHistory();
  const { setLoading } = props;
  const { getAccessTokenSilently } = useAuth0();
  const initialStep = 0;
  const { readinessTable } = useFlags();
  const fetchData = async () => {
    const groupResults = await getGroupsById({ id_group: groupId });
    setCurrentGroup(groupResults);
    setPatients(groupResults.patients);

    try {
      setLoading(true);
      const temp = await getGroupProms({ id_group: groupId });
      const resultProms = await getAllPromsSurveys();

      setPromsQuestList([...resultProms]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(async () => {
    setLoading(true);
    const permissionReadiness = await getUserPermission(
      "readiness-table-group",
      getAccessTokenSilently
    );
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    const tempPermissionProms = await getUserPermission(
      "proms-group",
      getAccessTokenSilently
    );
    setReadinessIsEnabled(readinessTable && permissionReadiness);
    setIsFederation(permissionFederationGroups);
    setPermissionProms(tempPermissionProms);

    let tmpLabels = labels.group.groupMonitoring.titles;
    if (!tempPermissionProms)
      tmpLabels = tmpLabels.filter((item) => item.key !== "proms");
    if (!permissionReadiness)
      tmpLabels = tmpLabels.filter((item) => item.key !== "readiness_table");

    tmpLabels = tmpLabels.map((item) => item.value);
    setspMenuLabels(tmpLabels.map((t) => <div>{t}</div>));

    setLoading(false);
  }, [readinessTable]);

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item container xs={12} spacing={2} direction="row">
        <Grid item xs={12}>
          <GroupEditSidebar
            props={props}
            groupId={groupId}
            history={history}
            currentGroup={currentGroup}
            sectionName={labels.group.sideBarGroup.monitoring}
          />
        </Grid>

        <Grid item xs={12}>
          <SpStepper
            initialStep={initialStep}
            titles={spMenuLabels}
            stepperControls={StepperControls(errors)}
          >
            {permissionProms && <GroupProms setLoading={setLoading} />}
            {readinessIsEnabled && !isFederation && <ReadinessTable />}
          </SpStepper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(GroupMonitoring);
