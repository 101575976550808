import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import {
  getExerciseById,
  createExercise,
  updateExercise,
} from "../../../models/actions/Excercise";
import { labels, psTranslate } from "../../shared/translations";
import ExerciseRow from "./Rows/Single/ExerciseRow";
import ExerciseDetails from "./Details/ExerciseDetails";
import { getProfessionalById } from "../../../models/actions/Professionals";
import { UPLOAD_LIMIT } from "../../../utils/common";
import { uploadFile } from "../../../models/actions/awsUtils";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddExercise = ({ setLoading, isClone, ...props }) => {
  const [idProfessional, setIdProfessional] = useState(-1);
  const [exerciseDetail, setExerciseDetail] = useState({
    name: "",
    tags: "",
    guidance_instructions: "",
    id_categories: [],
  });
  const [file, setFile] = useState(null);

  const history = useHistory();

  const { idElement } = useParams();

  const readOnly =
    !isClone && idElement && exerciseDetail?.id_professional !== idProfessional;
  const isCreate = isClone || !idElement;

  const fetchData = async () => {
    setLoading(true);

    const professionalProfile = await getProfessionalById();
    setIdProfessional(professionalProfile.id);

    if (idElement) {
      try {
        const result = await getExerciseById({ id_exercise: idElement });
        if (result) {
          if (isClone) delete result.id;
          if (isClone && result?.id_professional !== idProfessional)
            result.id_categories = [];
          else
            result.id_categories = result.exercise_exercise_categories.map(
              (el) => el.id_exercise_category
            );
          setExerciseDetail(result);
        }
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const editTemplate = async () => {
    try {
      //Update template
      if (file) {
        if (file.size / (1024 * 1024) > UPLOAD_LIMIT) {
          throw labels.patient.resourcesUpload.errors.uploadLimit;
        }
        await uploadFile(file);
        const results = await updateExercise({
          ...exerciseDetail,
          id_exercise: idElement,
          fileName: file.name,
          fileSize: file.size,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
        });
        if (results.error) {
          props.snackbarShowErrorMessage(results.error);
        } else {
          props.snackbarShowMessage(results.message);
          history.goBack();
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async () => {
    if (!exerciseDetail.name) {
      props.snackbarShowErrorMessage(labels.mylab.insertTemplateName);
      return;
    }
    try {
      //Create template
      if (file) {
        if (file.size / (1024 * 1024) > UPLOAD_LIMIT) {
          throw labels.patient.resourcesUpload.errors.uploadLimit;
        }
        await uploadFile(file);
        const result = await createExercise({
          ...exerciseDetail,
          fileName: file.name,
          fileSize: file.size,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
        });
        if (result.message) {
          props.snackbarShowMessage(result.message);
          history.goBack();
        } else {
          props.snackbarShowMessage(result.error);
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.goBack()}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {labels.mylab.tempDetail.customExercise.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {/*Top create button*/}
          {!readOnly && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                variant="none"
                style={{ marginLeft: "2%" }}
                text={
                  !isCreate
                    ? labels.mylab.tempAdd.treatment.actions.edit
                    : labels.mylab.tempAdd.treatment.actions.create
                }
                buttonType="accept"
                onClick={() => (!isCreate ? editTemplate() : saveTemplate())}
              />
            </Grid>
          )}
        </Grid>

        {/*Name & quantity*/}
        <Grid item xs={12} container style={{ justifyContent: "flex-start" }}>
          <SpTextInput
            disabled={readOnly}
            style={{ width: "95%" }}
            label={labels.mylab.tempAdd.treatment.inputs.name}
            value={
              readOnly ? psTranslate(exerciseDetail.name) : exerciseDetail.name
            }
            onChange={(evnt) =>
              setExerciseDetail({
                ...exerciseDetail,
                name: evnt.target.value,
              })
            }
          />
        </Grid>

        {/*Data rows*/}
        {readOnly ? (
          <ExerciseDetails exercise={exerciseDetail} />
        ) : (
          <ExerciseRow
            exercise={exerciseDetail}
            updateExercise={(key, value) =>
              setExerciseDetail({ ...exerciseDetail, [key]: value })
            }
            setFile={setFile}
          />
        )}

        {/*Bottom create button*/}
        {!readOnly && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            <SpButton
              text={
                !isCreate
                  ? labels.mylab.tempAdd.treatment.actions.edit
                  : labels.mylab.tempAdd.treatment.actions.create
              }
              buttonType="accept"
              variant="h1"
              onClick={() => (!isCreate ? editTemplate() : saveTemplate())}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddExercise);
