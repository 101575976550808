import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import "../App.css";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { styled } from "../components/styled";
import { Grid } from "@material-ui/core";
import { labels } from "../pages/shared/translations";
import { theme } from "../components/theme";

const MyGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  height: "70vh",
});

const errorStyle = {
  color: "white",
  display: "flex",
  flexDirection: "column",
};
const errorDetails = {
  color: "red",
};

const goBackLink = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  alignItems: "center",

  color: theme.colors.primary.lightBlue,
  height: 50,
  cursor: "pointer",
};
const supportReference = {
  color: "white",
};
const supportLnk = {
  color: theme.colors.primary.lightBlue,
  textDecoration: "none",
  fontWeight: "bold",
};

const Error = (props) => {
  let history = useHistory();

  const GoBack = () => {
    return (
      <div onClick={() => history.push("/")} style={goBackLink}>
        <ArrowBack
          style={{
            width: 30,
            color: theme.colors.primary.lightBlue,
            height: 50,
            cursor: "pointer",
          }}
        />
        <SpText variant="h1">{labels.error.lnkGoBack}</SpText>
      </div>
    );
  };

  const SupportReference = () => {
    return (
      <div style={supportReference}>
        <p>
          {labels.error.contactAssistenceMsg}
          <a href={labels.error.lnkToSupport} style={supportLnk}>
            {labels.error.labelLnkToSupport}
          </a>
        </p>
      </div>
    );
  };

  const ErrorDetails = () => {
    if (process.env.REACT_APP_ENV_NAME == "dev") {
      return (
        <div style={errorStyle}>
          <details>
            <div style={errorDetails}>
              <p>componentError: {props.location.state.componentError}</p>
            </div>

            <div style={errorDetails}>
              <p>
                {props.location.state.error &&
                  props.location.state.error.toString()}
              </p>
            </div>

            <div style={errorDetails}>
              <p>errorInfo: {props.location.state.errorInfo.componentStack}</p>
            </div>
          </details>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <MyGrid>
        <SpText variant={"h1PageTitle"}>{labels.error.errorMsg}</SpText>
        <GoBack />
        <SupportReference />
        <ErrorDetails />
      </MyGrid>
    </div>
  );
};
export default Error;
