import {
  Avatar,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { theme } from "../../../components/theme";
import CloseIcon from "@material-ui/icons/Close";
import SpTextInput from "../../../components/atoms/SpTextInput";
import moment from "moment";
import { labels } from "../../shared/translations";

export const ProfessionalCard = ({ displayPatients, onDelete }) => {
  const useStyles = makeStyles((themeStyle) => ({
    media: {
      height: 150,
      objectFit: "cover", // Oppure usa 'contain' se vuoi che l'immagine si adatti senza essere tagliata
    },
    content: {
      color: "#ffffff", // Colore del testo bianco
    },
    description: {
      display: "-webkit-box",
      WebkitLineClamp: 4, // Mostra al massimo 3 righe
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis", // Indica che il testo è stato troncato
    },
    name: {
      transition: "background-color 0.3s ease",
      padding: "0.2rem",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#f0f0f0", // Colore grigio chiaro al passaggio del mouse
      },
    },
    actionArea: {
      width: 200,
      height: 200, // Altezza fissa per ogni card
      margin: "1rem",
      backgroundColor: "#002b24", // Verde scuro come sfondo
      "&:hover": {
        backgroundColor: theme.colors.primary.lightBlue, // Colore grigio chiaro al passaggio del mouse sull'intera CardActionArea
      },
      "&:focus": {
        backgroundColor: theme.colors.primary.lightBlue, // Colore grigio quando viene cliccata/focalizzata
      },
    },
    largeAvatar: {
      margin: "8px",
      width: themeStyle.spacing(7),
      height: themeStyle.spacing(7),
    },
  }));

  // Componente per una singola figurina
  const PersonCard = ({ person, onDelete }) => {
    const classes = useStyles();

    return (
      <CardActionArea
        id={`patient-row-${person?.name}-${person?.surname}`}
        className={classes.actionArea}
        style={{ position: "relative" }}
      >
        {/* Icona "X" in alto a destra */}
        <Box
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "red",
            borderRadius: "50%",
          }}
        >
          <IconButton
            size="small"
            onClick={() => onDelete(person)} // Funzione di callback per eliminare il professionista
            style={{ color: "white" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              },
            }}
            className={classes.largeAvatar}
            src={person?.profileImage}
          />
        </div>

        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            {`${person?.name} ${person?.surname}`}
          </Typography>
          {person?.email && (
            <Typography variant="body2" component="p">
              {person?.email}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    );
  };

  const CardList = ({ people, onDelete }) => {
    return (
      <Grid container spacing={2} justifyContent="left">
        {people.map((person) => (
          <Grid item key={person.id}>
            <PersonCard person={person} onDelete={onDelete} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return displayPatients?.length > 0 ? (
    <CardList people={displayPatients} onDelete={onDelete} />
  ) : (
    <></>
  );
};
export const generateSlotsForDay = (slotDuration, morning, afternoon) => {
  const slots = [];
  let startTime = afternoon
    ? moment().set({ hour: 8, minute: 0 })
    : moment().set({ hour: 13, minute: 0 });
  let endTime = morning
    ? moment().set({ hour: 20, minute: 0 })
    : moment().set({ hour: 13, minute: 0 });

  while (startTime.isBefore(endTime)) {
    const slotEndTime = startTime.clone().add(slotDuration, "minutes");
    if (slotEndTime.isAfter(endTime)) break;
    slots.push({
      startTime: startTime.format("HH:mm"),
      endTime: slotEndTime.format("HH:mm"),
    });
    startTime = slotEndTime;
  }

  return slots;
};

export const Schedule = ({ slot, index }) => {
  return (
    <Grid container item xs={12} spacing={2} key={index} alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ color: theme.colors.primary.lightBlue }}
        >
          {slot.startTime} - {slot.endTime}
        </Typography>
      </Grid>
    </Grid>
  );
};
