import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { getAllExercises } from "../../../models/actions/Excercise";
import { labels, psTranslate } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const A3_ExerciseDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
  isPrint = false,
  isFromAgenda,
}) => {
  const [exercisesList, setExercisesList] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();
  const { patId, groupId } = useParams();

  useEffect(async () => {
    const results = await getAllExercises();
    setExercisesList(results);
  }, []);

  const RowExercise = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: !isPrint
              ? theme.colors.primary.lightBlue
              : "white",
          }}
        />
      )}
      <Grid item xs={12} container>
        <Grid item xs={2}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
            {psTranslate(
              exercisesList.find(
                (excercise) => excercise.id === row.id_excercise
              )?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={1}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.series}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.repetition_time}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.resting}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.load}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.accessories}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.execution}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}
            formControlStyle={{ minWidth: 40 }}
          >
            {row.note}
          </SpText>
        </Grid>
      </Grid>
    </div>
  );

  //Columns that contains text (vs numerical columns)
  const textualRowExerciseCols = [0, 7, 6, 5];

  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
        {data?.excercises_motivation}
      </SpText>
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
            {data.name}
          </SpText>
        </Grid>
        {data?.excercises?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.excerciseSheet.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.excercises?.length > 0 && (
          <Grid
            item
            xs={12}
            container
            style={{
              border: `1px solid ${
                isPrint ? "white" : theme.colors.primary.lightBlue
              }`,
            }}
          >
            <Grid item xs={12}>
              <SpList
                rows={data?.excercises}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {Object.values(
                      labels.mylab.tempAdd.excerciseSheet.list.header
                    ).map((head, idx) => (
                      <Grid
                        key={head}
                        item
                        xs={textualRowExerciseCols.includes(idx) ? 2 : 1}
                      >
                        <SpText
                          variant={
                            isPrint ? "textPrint" : "h4ComponentLabelCenter"
                          }
                        >
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowExercise
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}

        {allowFeedback &&
          showFeedbacksGrid({
            event,
            patId,
            groupId,
            activity: "exercise",
            showFeedback,
            setShowFeedback,
            isFromAgenda,
          })}

        {allowFeedback &&
          showFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ))}
      </Grid>
    </>
  );
};

export default A3_ExerciseDisabledDetails;
