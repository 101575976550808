import { call } from "./utils";

export const getGenericRiskFactorList = async (data) =>
  await call({
    url: "/genericRiskFactor/getList",
    data: { data },
  });

export const sendGenericRiskFactor = async (data) =>
  await call({
    url: "/genericRiskFactor/add",
    data: { data },
  });

export const deleteGenericRiskFactor = async (data) =>
  await call({
    url: "/genericRiskFactor/delete/by/id",
    data: { data },
  });

export const getFeedbackParameters = async (data) =>
  await call({
    url: "/get/feedbackParameters",
    data: { data },
  });
