/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import { Grid } from "@material-ui/core";
import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";
import { rollbar } from "../../../utils/common.js";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientInjury,
  getPatientsSelection,
  getRange,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  injuryGroupsByGravity,
  injuryGroupsByReinjury,
  injuryPatientsByGravity,
  injuryPatientsByReinjury,
  selectedGroupsBurder,
  selectedGroupsDaysAbsence,
  selectedGroupsInjury,
  selectedGroupsInjuryRate,
  selectPatientsDaysAbsence,
  selectPatientsInjury,
  selectPatientsInjuryBurden,
  selectPatientsInjuryRate,
} from "./patientsReportInjury/MuscleLigamentInjuryFunction";

import {
  getAutocomplete,
  injuryLocalizationGroups,
} from "./patientsReportInjury/GeneralInjuryFunction";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { theme } from "../../../components/theme.js";

const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";
const localizationDistributionLabel =
  labels.analytics.injuryReport.graphs.localizationDistribution;
const gravityLabel = labels.analytics.injuryReport.graphs.gravity;
const injuryRate = labels.analytics.injuryReport.graphs.injuryRate;
const reinjuryLabel = labels.analytics.injuryReport.graphs.reinjury;
const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const injuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;

const PatientsReportsMuscleLigamentInjury = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [injuryGroup, setInjuryGroup] = useState([]);

  const [dataChartLocalization, setDataChartLocalization] = useState({});
  const [barCharLocalization, setBarCharLocalization] = useState([]);
  const [dataChartGravity, setDataChartGravity] = useState({});
  const [barCharGravity, setBarCharGravity] = useState([]);
  const [dataChartRatio, setDataChartRatio] = useState({});
  const [barCharRatio, setBarCharRatio] = useState([]);
  const [dataChartReinjury, setDataChartReinjury] = useState({});
  const [barCharReinjury, setBarCharReinjury] = useState([]);
  const [dataChartDays, setDataChartDays] = useState({});
  const [barCharDays, setBarCharDays] = useState([]);
  const [dataChartBurden, setDataChartBurden] = useState({});
  const [barCharBurden, setBarCharBurden] = useState([]);

  const [allRegions, setAllRegions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAreasAvailable, setAllAreasAvailable] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState([]);

  const [fullSizeLocalization, setFullSizeLocalization] = useState(false);
  const [fullSizeGravity, setFullSizeGravity] = useState(false);
  const [fullSizeInjuryRate, setFullSizeInjuryRate] = useState(false);
  const [fullSizeReinjury, setFullSizeReinjury] = useState(false);
  const [fullSizeDays, setFullSizeDays] = useState(false);
  const [fullSizeBurden, setFullSizeBurden] = useState(false);

  const { patId, groupId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      const allRegionTemp = await getRegionAreaStructureLists({});
      setAllRegions(allRegionTemp?.regionList);
      setAllAreas(allRegionTemp?.areaList);
      setAllAreasAvailable(allRegionTemp?.areaList);

      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);
        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        let tempInjuryGroup = await getPatientInjury(
          tempGroupPatient,
          dateRange
        );

        for (let g = 0; g<tempGroupPatient.length; g++) {
          let groupP = tempGroupPatient[g]
          let groupInj = tempInjuryGroup.find(({ group }) => parseInt(group.id) === parseInt(groupP.id) )

          for (let p = 0; p < groupP?.patients.length; p++) {
            let pat = groupP?.patients[p];

            let patientId = parseInt(pat?.id);
            if (!isNaN(patientId)) {
                let inj_lst = groupInj?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
                if (inj_lst !== undefined) {
                  tempGroupPatient[g].patients[p].injuries = inj_lst;
                } 
            }
          }
        }

        setPatients(result?.allPatients);
        setGroups(tempGroupPatient);
        setInjuryGroup(tempInjuryGroup);
        // calcolo del paziente attuale in base al suo id
        let tempPat = [
          result?.allPatients.find(
            ({ id }) => parseInt(id) === parseInt(patId)
          ),
        ];
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction(tempPat, "group", tempGroupPatient);
      } else {
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );

        let tempInjuryGroup = await getPatientInjury(tempGroups, dateRange);
        setInjuryGroup(tempInjuryGroup);

        let tempInjuryGroupI = []
        for (let g of tempInjuryGroup) {
          if ( parseInt(g?.group?.id) === parseInt(groupId) ) {
            tempInjuryGroupI.push(g)
          }
        }

        for (let p = 0; p < groupsSelected?.patients.length; p++) {
          let pat = groupsSelected?.patients[p];

          let patientId = parseInt(pat?.id);
          if (!isNaN(patientId)) {
              let inj_lst = tempInjuryGroupI[0]?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
              if (inj_lst !== undefined) {
                groupsSelected.patients[p].injuries = inj_lst;
              } 
          }
        }

        setInjuryGroup([groupsSelected]);
        setSelectedGroups([groupsSelected]);

        setInjuryGroup(tempInjuryGroup);

        selectionActionFunction([], "group", [groupsSelected]);
      }

      setLoading(false);
    } catch (error) {
      rollbar.error("PatientsReportsMuscleLigamentInjury - useEffect", error);

      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  /**
   * Funzione viene richiamata quando cambia la regione selezionata
   * @param newRegionSelected: nuova regione selezionata
   *
   */
  const selectRegionFunction = (newRegionSelected) => {
    // pulisco i gruppi selezionati
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(
        injuryGroup,
        newRegionSelected,
        areaSelected,
        props.idTissueType
      );
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        newRegionSelected,
        areaSelected,
        props.idTissueType
      );

      let result = selectPatientsInjury(
        selectedPatients,
        newRegionSelected,
        areaSelected,
        props.idTissueType
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result.dataChart;
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }

    setRegionSelected(newRegionSelected);
    let tempArea = [];
    newRegionSelected.forEach((region) => {
      const tempAreas = allAreas.filter(
        (area) => parseInt(area?.id_region) === parseInt(region?.id)
      );
      tempArea = tempArea.concat(tempAreas);
    });
    setAllAreasAvailable(tempArea);
  };

  /**
   * Funzione viene richiamata quando cambia l'area selezionata
   * @param newAreaSelected: nuova area selezionata
   *
   */
  const selectAreaFuncion = (newAreaSelected) => {
    setAreaSelected(newAreaSelected);
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, newAreaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        regionSelected,
        newAreaSelected,
        props.idTissueType
      );
      let result = selectPatientsInjury(
        selectedPatients,
        regionSelected,
        newAreaSelected,
        props.idTissueType
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result.dataChart;
      setDataChartLocalization(result.dataChart);
      setBarCharLocalization(result.barChart);
    }
  };

  /**
   * Funzione che mostra tutti gli infortuni divisi per gruppo
   *
   * @param tempInjuryGroup: lista di gruppi
   * @param regions: regioni selezionate
   * @param areas: aree selezionate
   *
   */
  const setAllInjuryGroup = (tempInjuryGroup, regions, areas) => {
    let result = injuryLocalizationGroups(tempInjuryGroup, regions, areas);
    setDataChartLocalization(result.dataChart);
    setBarCharLocalization(result.barChart);
  };

  const actionAfterGroupSelection = (tempGroups) => {
    selectionActionFunction([], "group", tempGroups);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   */
  const selectionActionFunction = async (
    patients,
    type,
    newSelectedGroups = selectedGroups
  ) => {
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );

    let resultInjuryGroupsLocalization = selectedGroupsInjury(
      newSelectedGroups,
      regionSelected,
      areaSelected,
      props.idTissueType
    );
    let resultLocalization = selectPatientsInjury(
      newPatientsSelected,
      regionSelected,
      areaSelected,
      props.idTissueType
    );

    let resultInjuryGroupsGravity = injuryGroupsByGravity(
      newSelectedGroups,
      labels.analytics.injuryReport.gravityRange,
      true,
      props.idTissueType
    );
    let resultGravity = injuryPatientsByGravity(
      newPatientsSelected,
      labels.analytics.injuryReport.gravityRange,
      props.idTissueType
    );

    let resultInjuryGroupsReinjury = injuryGroupsByReinjury(
      newSelectedGroups,
      labels.analytics.injuryReport.reinjuryRange,
      true,
      props.idTissueType
    );
    let resultReinjury = injuryPatientsByReinjury(
      newPatientsSelected,
      labels.analytics.injuryReport.reinjuryRange,
      props.idTissueType
    );

    let resultInjuryGroupsRatio = await selectedGroupsInjuryRate(
      newSelectedGroups,
      dateRange,
      props.idTissueType
    );
    let resultRatio = await selectPatientsInjuryRate(
      newPatientsSelected,
      groups,
      dateRange,
      props.idTissueType
    );

    let resultInjuryGroupsDays = await selectedGroupsDaysAbsence(
      newSelectedGroups,
      props.idTissueType
    );
    let resultDays = await selectPatientsDaysAbsence(
      newPatientsSelected,
      props.idTissueType
    );

    let resultInjuryGroupsBurder = await selectedGroupsBurder(
      newSelectedGroups,
      dateRange,
      props.idTissueType
    );
    let resultBurder = await selectPatientsInjuryBurden(
      newPatientsSelected,
      groups,
      dateRange,
      props.idTissueType
    );

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    resultLocalization.dataChart =
      resultInjuryGroupsLocalization.dataChart !== undefined
        ? resultLocalization.dataChart.concat(
            resultInjuryGroupsLocalization.dataChart
          )
        : resultLocalization.dataChart;

    resultGravity.dataChart =
      resultInjuryGroupsGravity.dataChart !== undefined
        ? resultGravity.dataChart.concat(resultInjuryGroupsGravity.dataChart)
        : resultGravity.dataChart;

    resultReinjury.dataChart =
      resultInjuryGroupsReinjury.dataChart !== undefined
        ? resultReinjury.dataChart.concat(resultInjuryGroupsReinjury.dataChart)
        : resultReinjury.dataChart;

    resultDays.dataChart =
      resultInjuryGroupsDays.dataChart !== undefined
        ? resultDays.dataChart.concat(resultInjuryGroupsDays.dataChart)
        : resultDays.dataChart;

    resultRatio.dataChart =
      resultInjuryGroupsRatio.dataChart !== undefined
        ? resultRatio.dataChart.concat(resultInjuryGroupsRatio.dataChart)
        : resultRatio.dataChart;

    resultBurder.dataChart =
      resultInjuryGroupsBurder.dataChart !== undefined
        ? resultBurder.dataChart.concat(resultInjuryGroupsBurder.dataChart)
        : resultBurder.dataChart;

    setLoading(true);
    setDataChartLocalization(resultLocalization.dataChart);
    setBarCharLocalization(resultLocalization.barChart);

    setDataChartGravity(resultGravity.dataChart);
    setBarCharGravity(resultGravity.barChart);

    setDataChartReinjury(resultReinjury.dataChart);
    setBarCharReinjury(resultReinjury.barChart);

    setDataChartDays(resultDays.dataChart);
    setBarCharDays(resultDays.barChart);

    setDataChartRatio(resultRatio.dataChart);
    setBarCharRatio(resultRatio.barChart);

    setDataChartBurden(resultBurder.dataChart);
    setBarCharBurden(resultBurder.barChart);

    setSelectedPatients(newPatientsSelected);

    setLoading(false);
    if (newPatientsSelected.length === 0 && newSelectedGroups.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, areaSelected);
    }
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    try {
      if (newDateRange.start < newDateRange.end) {
        setDateRange(newDateRange);
        if (patId) {
          let tempInjuryGroup = await getPatientInjury(groups, newDateRange);
          setInjuryGroup(tempInjuryGroup);
          setSelectedGroups([]);

          // calcolo del paziente attuale in base al suo id
          let tempPat = [patients.find(({ id }) => id === parseInt(patId))];
          await selectionActionFunction(tempPat, "group", groups);
        } else {
          let tempInjuryGroup = await getPatientInjury(
            selectedGroups,
            newDateRange
          );
          setInjuryGroup(tempInjuryGroup);
          await selectionActionFunction([], "group", selectedGroups);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      <Grid item container xs={8}>
        {/* selezione dei pazienti e dei gruppi  */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}
        {groupId &&
          getGroupsSelection(groups, selectedGroups, actionAfterGroupSelection)}
      </Grid>
      {/* autocomplete per selezionare la regione */}
      {getAutocomplete(
        labels.analytics.injuryReport.selectRegion,
        regionSelected,
        allRegions,
        selectRegionFunction
      )}

      {/* autocomplete per selezionare l'area */}
      {regionSelected.length > 0 &&
        getAutocomplete(
          labels.analytics.injuryReport.selectArea,
          areaSelected,
          allAreasAvailable,
          selectAreaFuncion
        )}

      <Grid container item xs={12} spacing={2}>
        {/* Localization char */}
        <Grid
          container
          item
          xs={fullSizeLocalization ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {localizationDistributionLabel}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeLocalization(!fullSizeLocalization);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeLocalization ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartLocalization}
            barChar={barCharLocalization}
          />
        </Grid>
        {/* Gravity char */}
        <Grid
          container
          item
          xs={fullSizeGravity ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">{gravityLabel}</SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeGravity(!fullSizeGravity);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeGravity ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartGravity}
            barChar={barCharGravity}
          />
        </Grid>
        {/* Rate char */}
        <Grid
          container
          item
          xs={fullSizeInjuryRate ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">{injuryRate}</SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeInjuryRate(!fullSizeInjuryRate);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeInjuryRate ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartRatio} barChar={barCharRatio} />
        </Grid>
        {/* Reinjury char */}
        <Grid
          container
          item
          xs={fullSizeReinjury ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">{reinjuryLabel}</SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeReinjury(!fullSizeReinjury);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeReinjury ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartReinjury}
            barChar={barCharReinjury}
          />
        </Grid>
        {/* Days absence char */}
        <Grid
          container
          item
          xs={fullSizeDays ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">{daysAbsenceLabel}</SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeDays(!fullSizeDays);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeDays ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartDays} barChar={barCharDays} />
        </Grid>
        {/* Burden char */}
        <Grid
          container
          item
          xs={fullSizeBurden ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">{injuriesBurdenLabel}</SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeBurden(!fullSizeBurden);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeBurden ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartBurden} barChar={barCharBurden} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsMuscleLigamentInjury);
