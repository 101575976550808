import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "../../App.css";
import {
  getProfessionalPatientByEmail,
  transferPatientToProfessional,
} from "../../models/actions/Professionals";
import { findPatientByPk } from "../../models/actions/Patients";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpButton from "../../components/atoms/SpButton";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { theme } from "../../components/theme";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  alignSelf: "flex-start",
  justifyContent: "flex-start",

  paddingRight: "2%",
});

const StyledFormRow = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  paddingRight: "1%",
  marginTop: "1%",
});

const StyledFoundPatWrapper = styled("div")({
  padding: "2%",
  marginBottom: "2%",
  backgroundColor: "#333333",
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
  backgroundOpacity: "0.2",
});

const StyledColumnFoundWrapper = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  marginTop: "2%",
});

const StyledTransferWrapper = styled(Grid)({
  padding: "3%",
  backgroundColor: "#333333",
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
});

const PatientsTransferPatient = (props) => {
  const [professionalFound, setProfessionalFound] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const history = useHistory();

  const { register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const findProfessional = async (data) => {
    try {
      const professionalResult = await getProfessionalPatientByEmail({
        email: data.email,
        id_patient: patId,
      });
      setProfessionalFound(professionalResult);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const transferPatient = async (id_professional) => {
    try {
      const result = await transferPatientToProfessional({
        id_new_professional: id_professional,
        id_patient: patId,
      });
      setProfessionalFound([]);
      props.snackbarShowMessage(result?.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <SpText variant="h1Subtitle">
                {labels.patient.transferPatient.subtitle}
              </SpText>
            </Grid>
            <StyledTransferWrapper item xs={12}>
              <StyledRow>
                <SpText variant={"h1"}>
                  {labels.patient.transfer.form.title}
                </SpText>
              </StyledRow>
              <StyledRow>
                <SpText variant={"h4ComponentLabel"}>
                  {labels.patient.transfer.form.searchInputLabel}
                </SpText>
              </StyledRow>
              <form onSubmit={handleSubmit(findProfessional)}>
                <StyledFormRow>
                  <SpTextInput
                    style={{ maxWidth: "40%", marginRight: "10%" }}
                    label={labels.patient.transfer.form.searchInputLabelTop.toUpperCase()}
                    name={"email"}
                    inputRef={register}
                  />
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    type={"submit"}
                    text={labels.patient.transfer.form.searchButton}
                  />
                </StyledFormRow>
              </form>
              {professionalFound.length > 0 && (
                <StyledColumnFoundWrapper>
                  <SpText variant={"h4ComponentLabel"}>
                    {labels.patient.transfer.form.foundPatients.formTitle}
                  </SpText>
                  <StyledFoundPatWrapper>
                    {professionalFound.map((professional, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              style={{
                                borderBottom: `1px solid ${theme.colors.primary.lightBlue}`,
                                marginBottom: "1%",
                              }}
                            >
                              <Grid item xs={3}>
                                <SpText variant="h4">
                                  {labels.patient.transferPatient.name}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpText variant="h4">
                                  {labels.patient.transferPatient.surname}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpText variant="h4">
                                  {labels.patient.transferPatient.email}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpText variant="h4">
                                  {labels.dashboard.operations.actions}
                                </SpText>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={3}>
                                <SpText variant="h4ComponentLabel">
                                  {professional.name}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpText variant="h4ComponentLabel">
                                  {professional.surname}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpText variant="h4ComponentLabel">
                                  {professional.email}
                                </SpText>
                              </Grid>
                              <Grid item xs={3}>
                                <SpButton
                                  variant="listButton"
                                  buttonType={"accept"}
                                  text={
                                    labels.patient.transfer.form.foundPatients
                                      .transferButton
                                  }
                                  onClick={() =>
                                    transferPatient(professional.id)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        );
                      } else {
                        return (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                              <SpText variant="h4ComponentLabel">
                                {professional.givenName}
                              </SpText>
                            </Grid>
                            <Grid item xs={3}>
                              <SpText variant="h4ComponentLabel">
                                {professional.familyName}
                              </SpText>
                            </Grid>
                            <Grid item xs={3}>
                              <SpText variant="h4ComponentLabel">
                                {professional.email}
                              </SpText>
                            </Grid>
                            <Grid item xs={3}>
                              <SpButton
                                variant="listButton"
                                buttonType={"accept"}
                                text={
                                  labels.patient.transfer.form.foundPatients
                                    .transferButton
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                  </StyledFoundPatWrapper>
                </StyledColumnFoundWrapper>
              )}
            </StyledTransferWrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsTransferPatient);
