import React from "react";
import { Grid } from "@material-ui/core";
import SpText from "../atoms/SpText";
import { labels } from "../../pages/shared/translations";

const dateStyle = {
  paddingRight: "1em",
};
const modifyElementScroll = {
  maxHeight: "4em", // 3 righe
  overflow: "auto",
};

const colSize = 12;

export const SpElementCrudDetails = ({ crudDetails }) => {
  const ElemDatePerson = ({ date, name, type }) => {
    return (
      <React.Fragment>
        <Grid item container xs={12} direction="row">
          <Grid container item xs={6}>
            <div style={dateStyle}>
              {type === "createdBy" && (
                <SpText variant="text">
                  {labels.bodymap.logEvent.createdBy}
                </SpText>
              )}
              {type === "modifiedBy" && (
                <SpText variant="text">
                  {labels.bodymap.logEvent.modifiedBy}
                </SpText>
              )}
              {type === "deletedBy" && (
                <SpText variant="text">
                  {labels.bodymap.logEvent.deletedBy}
                </SpText>
              )}
              {type === "archivedBy" && (
                <SpText variant="text">
                  {labels.bodymap.logEvent.archivedBy}
                </SpText>
              )}
            </div>
          </Grid>
          <Grid item container xs={6}>
            <div>
              <SpText variant="h4ComponentLabelRegular">{name}</SpText>
              <SpText variant="h4ComponentLabelRegular">{date}</SpText>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const FieldType = ({ actionType }) => {
    if (Array.isArray(crudDetails[actionType])) {
      return (
        <React.Fragment>
          <div style={modifyElementScroll}>
            {crudDetails[actionType].map((professional, index) => {
              return (
                <SpText
                  key={index}
                  variant="h4ComponentLabelRegular"
                  className="elemDatePersonMod"
                >
                  <ElemDatePerson
                    type={professional?.type}
                    name={professional?.name}
                    date={professional?.date}
                  />
                </SpText>
              );
            })}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <SpText variant="h4ComponentLabelRegular" className="elemDatePerson">
            <ElemDatePerson
              name={crudDetails[actionType]?.name}
              date={crudDetails[actionType]?.date}
              type={crudDetails[actionType]?.type}
            />
          </SpText>
        </React.Fragment>
      );
    }
  };

  return (
    <Grid item xs={12} container className="spElementCrudDetailsContainer">
      {Object.keys(crudDetails).map((fieldType, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={colSize} className="fieldType">
              <SpText variant="text">
                {labels.bodymap.dialogDetail[fieldType]}
              </SpText>
              <FieldType
                fieldTypeLabel={labels.bodymap.dialogDetail[fieldType]}
                actionType={fieldType}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
