import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const SpTooltip = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} {...rest}>
      {children}
    </Tooltip>
  );
};

export default SpTooltip;
