// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/Khand-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./assets/fonts/Khand-SemiBold.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./assets/fonts/Khand-Medium.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: regularFont;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: semiBoldFont;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: MediumFont;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n* {\n  font-family: regularFont !important;\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n.rbc-time-slot {\n  color: white;\n}\n\n.rbc-day-slot .rbc-events-container {\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  /* margin-right: 0px; */\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,4CAA4C;AAC9C;;AAEA;EACE,yBAAyB;EACzB,4CAA6C;AAC/C;;AAEA;EACE,uBAAuB;EACvB,4CAA2C;AAC7C;;AAEA;EACE,mCAAmC;EACnC,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,OAAO;EACP,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,uBAAuB;AACzB","sourcesContent":["@font-face {\n  font-family: regularFont;\n  src: url(\"./assets/fonts/Khand-Regular.ttf\");\n}\n\n@font-face {\n  font-family: semiBoldFont;\n  src: url(\"./assets/fonts/Khand-SemiBold.ttf\");\n}\n\n@font-face {\n  font-family: MediumFont;\n  src: url(\"./assets/fonts/Khand-Medium.ttf\");\n}\n\n* {\n  font-family: regularFont !important;\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n.rbc-time-slot {\n  color: white;\n}\n\n.rbc-day-slot .rbc-events-container {\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  /* margin-right: 0px; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
