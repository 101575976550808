import React from "react";

import { styled } from "../styled";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import { theme } from "../theme";

const CustomFormControl = styled(FormControl)({
  minWidth: 120,
  width: "100%",
  flex: 1,
});

const CustomTextField = styled(TextField)(({ textfieldvariant, isPrint }) => ({
  ...textfieldvariant,
  paddingLeft: 10,
  borderRadius: 2,
  borderColor: theme.colors.primary.lightBlue,
  color: "white",
  border: "1px solid",
  backgroundColor: !isPrint ? "#333333" : "white",
  textAlign: "center",
}));

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: theme.colors.primary.lightBlue,
  fontWeight: "bold",
  fontSize: 14,
});

const formControlLabelPlacementTop = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  grey: {
    backgroundColor: "#f9f5fa",
    color: "#FFFFFF",
  },
  white: {
    backgroundColor: "white",
    color: "#FFFFFF",
    textAlign: "center",
    verticalAlign: "center",
  },
  column: {
    marginTop: "4%",
  },
};

export const SpTextArea = ({
  label,
  displayLabel = true,
  formControlStyle,
  textfieldVariant = "white",
  labelPlacementLeft,
  InputProps,
  inputPropsStyle,
  isPrint,
  isRequired = false,
  ...rest
}) => {
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};

  return (
    <CustomFormControl style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle}>
          {isRequired && <span style={{ color: "red" }}>*</span>} {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      <CustomTextField
        {...rest}
        textfieldvariant={variants[textfieldVariant]}
        variant={"standard"}
        multiline
        rowsMax={3}
        rows={3}
        isPrint={isPrint}
        InputProps={{
          ...InputProps,
          disableUnderline: true,
        }}
        inputProps={{
          style: {
            color: !isPrint ? "#FFFFFF" : "black",
            ...inputPropsStyle,
          },
        }}
      />
    </CustomFormControl>
  );
};

export default SpTextArea;
