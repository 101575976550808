import { call } from "./utils";

export const getPatientAlertsList = async (data) =>
  await call({
    url: "/patientAlerts/getList",
    data: { data },
  });

export const getPatientsWithUncheckedAlerts = async (data) =>
  await call({
    url: "/patientAlerts/id/uncheckedAlerts",
    data: { data },
  });

export const setPatientAlertChecked = async (data) =>
  await call({
    url: "/patientAlerts/updateChecked",
    data: { data },
  });
