import React, { useEffect, useState } from "react";
import {
  getPromsIndexes,
  maxPromsScores,
} from "../../../../../models/actions/Proms";
import { dateFormat } from "../../../../../utils/common";
import { labels } from "../../../../shared/translations";
import {
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const StudyMcidIndexes = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  mcidList,
}) => {
  const [promsIndexes, setPromsIndexes] = useState([]);
  const [usedKeys, setUsedKeys] = useState([]);
  const [active, setActive] = useState(true);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const mcidData = () => {
    return promsIndexes.reduce((accum, promIndex) => {
      const mcids = promIndex.value.split("-");
      let i = 1;
      mcids.forEach((mcid) => {
        const key = `${promIndex.name} ${i}`;
        const mcidValue = parseFloat(mcid);
        accum[`+${key}`] = mcidLoop(promIndex.id_proms, mcidValue, true);
        accum[`-${key}`] = mcidLoop(promIndex.id_proms, mcidValue, false);
        i++;
      });
      return accum;
    }, {});
  };

  const mcidLoop = (idProms, mcidValue, positive) => {
    const possibleDays = [...dateRange.reverseBy("day")].map((day) =>
      day.format(dateFormat)
    );
    const dateRangeEndStr = dateRange.end.format(dateFormat);
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          const dashIdx = studyParamKey.indexOf("-");
          const realKey = dashIdx !== -1 ? studyParamKey.substring(0, dashIdx).trim() : studyParamKey;
          const currParam = currStudyParameters.find(p => p.key === realKey);
          if (currParam.id === idProms) {
            //Obtain last value
            let endDate = possibleDays.find((dayStr) => {
              return studyData[dayStr] || defaultZero;
            });
            endDate = endDate ?? dateRangeEndStr;
            const lastValue = studyData[endDate] ?? 0;

            //Compute mcid values
            if (lastValue) {
              const delta =
                ((maxPromsScores[studyParamKey] * parseFloat(mcidValue)) /
                  100.0) *
                (positive ? 1 : -1);
              accum[studyParamKey] = {
                [dateRange.start.format(dateFormat)]: lastValue + delta,
                [dateRange.end.format(dateFormat)]: lastValue + delta,
              };
            }
          }

          return accum;
        },
        {}
      );

      return accum;
    }, {});
  };

    useEffect(async () => {
    if (mcidList) {
      //Show mcids
      const indexes = await getPromsIndexes({
        id_proms: [...mcidList],
        type: "mcid",
      });
      setPromsIndexes(indexes ?? []);
    }
  }, [mcidList]);

  //Effects
  useEffect(() => {
    //In case of week view, average over next 7 days
    onFetchDateDaysChange(getNDaysFromKey(graphDateView?.key));
    //Update filter data
    const newData = {
      [study.key]: usedKeys.reduce((accum, key) => {
        accum[key] = {};
        return accum;
      }, {})
    };

    if (active && isEnabled())
      newData[study.key] = { ...newData[study.key], ...mcidData(getMomentIdFromKey()) };

    setUsedKeys(Object.keys(newData[study.key]));

    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView, promsIndexes]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    />
  );
};

export default StudyMcidIndexes;
