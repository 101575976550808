import React, { useEffect, useState } from "react";
import PrivateRouteAuth0 from "./PrivateRouteAuth0";

import { ampli } from "../../ampli";
const PrivateRoute = ({ children, componentName, ...rest }) => {
  ampli.changeComponent({ component: componentName });
  return <PrivateRouteAuth0 {...rest}>{children}</PrivateRouteAuth0>;
};

export default PrivateRoute;
