import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import regression from "regression";
import { labels } from "../../../shared/translations";

const ScatterPlot = ({ data }) => {
  // Calculate and add the sum per row to the data array

  data.forEach((entry) => {
    entry.TL = entry.RPE * entry.Tempo;
  });

  // Shift the "TL" values by one index
  const shiftedData = data.map((entry, index) => {
    const nextIndex = (index + 1) % data.length; // Circular shift
    return { ...entry, HI: data[nextIndex].HI, key: `scatter-${index}` };
  });

  // Set customized tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      const tooltipContent = payload.map((entry, index) => (
        <p
          key={`tooltip-${index}`}
          style={{ color: "#b8b6b6", margin: "5px 0", paddingLeft: "10px" }}
        >
          {`${entry.Date}: ${entry.value}`}
        </p>
      ));

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "#1f1f1f", padding: "10px" }}
        >
          {tooltipContent}
        </div>
      );
    }

    return null;
  };

  const CustomScatterShape = ({ cx, cy, radius, color }) => {
    return <circle cx={cx} cy={cy} r={radius} fill={color} />;
  };

  // Calculate the regression line
  const regressionData = shiftedData.map((entry) => [entry.HI, entry.TL]);
  const result = regression.linear(regressionData, { precision: 15 });
  const slope = result.equation[0];
  const intercept = result.equation[1];

  // Find the minimum and maximum values of HI in shiftedData
  const minHI = Math.min(...shiftedData.map((entry) => entry.HI)) - 1;
  const maxHI = Math.max(...shiftedData.map((entry) => entry.HI)) + 1;

  // Regression line points
  const regressionLineData = [
    { HI: minHI, TL: parseFloat(slope * minHI + intercept).toFixed(2) },
    { HI: maxHI, TL: parseFloat(slope * maxHI + intercept).toFixed(2) },
  ];

  // Calculate the residuals
  const residuals = shiftedData.map((entry) => {
    const predictedTL = slope * entry.HI + intercept;
    return entry.TL - predictedTL;
  });

  // Calculate RMSE
  const RMSE = Math.sqrt(
    residuals.reduce((sum, res) => sum + res ** 2, 0) / residuals.length
  );

  // Calculate confidence interval points using RMSE
  const confidenceIntervalData_up = result.points.map((point) => {
    const predictedTL = slope * point[0] + intercept;
    return {
      HI: point[0],
      TL: predictedTL + RMSE,
    };
  });

  const confidenceIntervalData_lw = result.points
    .map((point) => {
      const predictedTL = slope * point[0] + intercept;
      return {
        HI: point[0],
        TL: predictedTL - RMSE,
      };
    })
    .filter((entry) => entry.TL >= 0);

  return (
    <ResponsiveContainer width="100%" height={320}>
      <ScatterChart
        width={400}
        height={400}
        allowDataOverflow={false}
        domain={{ y: [0, "auto"], x: [7, "auto"] }}
        margin={{ top: 25, right: 0, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
        <XAxis
          domain={[6]}
          dataKey="HI"
          type="number"
          Date="Hooper index"
          label={{
            value: "Hooper index",
            position: "Bottom",
            style: { fontSize: "20px", fill: "#FFFFFF" },
          }}
          height={80}
        />
        <YAxis
          domain={[0, 1000]}
          dataKey="TL"
          type="number"
          Date="Training load"
          label={{
            value: "Training load (day-1)",
            angle: -90,
            position: "Left",
            style: { fontSize: "20px", fill: "#FFFFFF" },
          }}
          width={120}
        />

        <Tooltip content={<CustomTooltip />} />

        {/* Regression line */}
        <Scatter
          Date="Regression Line"
          data={regressionLineData}
          shape={<CustomScatterShape radius={0} />}
          line={{ stroke: "gray", strokeWidth: 4, strokeDasharray: "3 3" }}
        />

        {/* Confidence interval */}
        <Scatter
          Date="Confidence Interval hi"
          data={confidenceIntervalData_up}
          shape={<CustomScatterShape radius={0} />}
          line={{ strokeWidth: 1, stroke: "green", strokeDasharray: "3 3" }}
        />
        <Scatter
          Date="Confidence Interval low"
          data={confidenceIntervalData_lw}
          shape={<CustomScatterShape radius={0} />}
          line={{ strokeWidth: 1, stroke: "red", strokeDasharray: "3 3" }}
        />

        {shiftedData.map((entry, index) => (
          <Scatter
            key={`scatter-${index}-${entry.someUniqueProperty}`}
            Date="Data"
            data={[entry]}
            shape={<CustomScatterShape radius={6} color={"#707ecc"} />}
          />
        ))}

        <Scatter
          Date="Data"
          data={[shiftedData[shiftedData.length - 2]]}
          shape={<CustomScatterShape radius={12} color={"#e0e0e0"} />}
        />

        <text
          x="130"
          y="12%"
          style={{ fontSize: 14, fontWeight: "bold", fill: "#148731" }}
          width={100}
          textAnchor="start"
        >
          High fit
        </text>

        <text
          x="99%"
          y="70%"
          style={{ fontSize: 14, fontWeight: "bold", fill: "#a11616" }}
          width={100}
          textAnchor="end"
        >
          High stress
        </text>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default ScatterPlot;
