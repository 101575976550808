import React from "react";
import { labels } from "../../../shared/translations";
// material ui
import { Grid } from "@material-ui/core";
// models
// components
import SpText from "../../../../components/atoms/SpText";
// atoms
// utils
// mocks

const LabelValue = (props) =>
  props?.value ? (
    <Grid direction="column" style={{ marginBottom: "5px" }}>
      <SpText variant="text">{props.label}</SpText>
      <SpText variant="h4ComponentLabel">{props?.value}</SpText>
    </Grid>
  ) : (
    <></>
  );

const DialogFeedback = ({ selectedRow }) => {
  // note: aggiungi label per l'operatore logico
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={3}>
          <LabelValue
            label={labels.patient.patientAlerts.table.button.note}
            value={selectedRow?.alert_data?.note}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const DialogDetails = ({ row }) => <DialogFeedback selectedRow={row} />;
