import { call } from "./utils";

export const getDevicesAccountByProfessionalId = async (data) =>
  await call({
    url: "/devices/account/by/professional/id",
    data: { data },
  });

export const createDevicesAccount = async (data) =>
  await call({
    url: "/devices/account/create",
    data: { data },
  });

export const syncDeviceTeams = async (data) =>
  await call({
    url: "/device/teams/sync",
    data: { data },
  });

export const updateDevicesAccount = async (data) =>
  await call({
    url: "/devices/account/update",
    data: { data },
  });

export const deleteDevicesAccount = async (data) =>
  await call({
    url: "/devices/account/delete",
    data: { data },
  });

export const getDevicesProducer = async () =>
  await call({
    url: "/devices/producer",
    data: {},
  });

export const getDevicesPlayerByProfessionalId = async (data) =>
  await call({
    url: "/devices/player/by/professional/id",
    data: { data },
  });

export const syncDevicePatientsToPatients = async (data) =>
  await call({
    url: "/devices/account/sync",
    data: { data },
  });

export const getDevicesByPatientId = async (data) =>
  await call({
    url: "/devices/by/patient/id",
    data: { data },
  });

export const syncDeviceDataByPatientsName = async (data) =>
  await call({
    url: "/device/sync/data",
    data: { data },
  });

export const updateDevicePatient = async (data) =>
  await call({
    url: "/device/device/patient/update",
    data: { data },
  });
export const selectGpexeTemplate = async (data) =>
  await call({
    url: "/devices/gpexe/template",
    data: { data },
  });
