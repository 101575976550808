import React, { useEffect, useState } from "react";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Button as MuiButton, Grid } from "@material-ui/core";
import PatientsEditSidebar from "../patients/shared/PatientsEditSidebar";
import { useHistory, useParams } from "react-router-dom";
import { findPatientByPk } from "../../models/actions/Patients";
import { makeStyles } from "@material-ui/core/styles";
import SpText from "../../components/atoms/SpText";
import { labels } from "../shared/translations";
import { SpStepper } from "../../components/bundles";
import { useQueryParams } from "../../utils/common";
import { useForm } from "react-hook-form";

import PatientsMedicalNotesAllergies from "./patientsMedicalNotesSteps/PatientsMedicalNotesAllergies";
import PatientsMedicalNotesCompetitiveVisits from "./patientsMedicalNotesSteps/PatientsMedicalNotesCompetitiveVisits";
import PatientsMedicalNotesDrugs from "./patientsMedicalNotesSteps/PatientsMedicalNotesDrugs";
import PatientsMedicalNotesReports from "./patientsMedicalNotesSteps/PatientsMedicalNotesReports";
const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PatientsMedicalNotes = (props) => {
  const [currentPatient, setCurrentPatient] = useState();

  const { patId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { register, errors, control, getValues } = useForm({
    defaultValues: {
      patientName: "",
      birthDate: "",
      phone: "",
      job: "",
    },
    shouldUnregister: false,
  });

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justyfyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const query = useQueryParams();
  const initialStep = query.get("Allergie") ? 1 : 0;

  const { setLoading } = props;

  const fetchData = async () => {
    try {
      const response = await findPatientByPk({ userId: patId });
      setCurrentPatient(response);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const titles = labels.patient.medicalNotes.steps.map((t, idx) => (
    <div id={`stepperMedicalNotes${idx}`} key={idx}>
      {t}
    </div>
  ));
  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid item xs={2} style={{ margin: "auto 0", padding: 0 }}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ paddingTop: "1em" }}
          >
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.medicalNotes.title.toUpperCase()}
              </SpText>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "1em" }}>
              <SpStepper
                initialStep={initialStep}
                titles={titles}
                stepperControls={StepperControls(errors)}
                containerWidth={"92%"}
              >
                <PatientsMedicalNotesReports
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />
                <PatientsMedicalNotesAllergies
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />

                <PatientsMedicalNotesDrugs
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />

                <PatientsMedicalNotesCompetitiveVisits
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />
              </SpStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsMedicalNotes);
