import React from "react";
import Slider from "@material-ui/core/Slider";
import FormLabel from "@material-ui/core/FormLabel";
import { styled } from "../styled";
import { withStyles } from "@material-ui/core/styles";
import { theme } from "../theme";

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 0,
  paddingBottom: 0,
  color: theme.colors.primary.lightBlue,
  fontWeight: "bold",
  fontSize: 14,
});

const SpSliderContainer = styled(FormLabel)(({ rightlabel }) => ({
  display: "flex",
  flexDirection: rightlabel ? "row" : "column",

  flex: 1,
  alignItems: "flex-start",
  justifyContent: "flex-start",
}));

const StyledSlider = withStyles({
  root: {
    color: theme.colors.primary.lightBlue,
  },
})(Slider);

const SpSlider = ({
  label,
  labelTop,
  rightlabel,
  displayLabel = true,
  formControlStyle,
  value,
  onChange,
  ...props
}) => {
  return (
    <SpSliderContainer style={formControlStyle} rightlabel={rightlabel}>
      {labelTop ? <CustomFormLabel>{label}</CustomFormLabel> : <></>}
      <StyledSlider
        value={value}
        onChange={onChange}
        style={{ paddingRight: rightlabel ? 4 : 0 }}
        {...props}
      />
      {displayLabel && rightlabel ? (
        <CustomFormLabel>{label}</CustomFormLabel>
      ) : (
        <></>
      )}
    </SpSliderContainer>
  );
};

export default SpSlider;
