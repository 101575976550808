import React from "react";
import moment from "moment";
import { getLanguage, labels, psTranslate } from "../../../shared/translations";
import { Grid, Chip } from "@material-ui/core";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import SpText from "../../../../components/atoms/SpText";
import { SpAutocomplete } from "../../../../components/atoms/SpAutocomplete";
import {
  getGroupPresentations,
  getPresentationsByPatProf,
} from "../../../../models/actions/Presentation";
import { titleCase } from "../../../../utils/common";
import { theme } from "../../../../components/theme";

const constPadding = { padding: "5px" };
const injuryRateLabel = labels.analytics.injuryReport.graphs.injuryRate;

const language = getLanguage();
moment.locale(language);

export const getRange = () => {
  const end = moment().format("YYYY-MM-DD");
  const month = moment(end).month() + 1;
  let year;
  if (month <= 12 && month >= 8) {
    year = moment(end).year();
  } else {
    year = moment(end).year() - 1;
  }
  const start = moment(`${year}-07-01`).format("YYYY-MM-DD");
  const range = moment.range(start, end);
  return range;
};

/**
 * Funzione che restituisce tutti i pazienti disponibili e la mappa dei gruppi
 * con le attivita relative
 *
 * @param listGroupByProfessional: lista dei gruppi del professionista
 * @param patId: id del paziente
 *
 */
export const getGroupPatients = (listGroupByProfessional, patId) => {
  let mapGroup = listGroupByProfessional.map((group) => {
    return {
      id: group.id_group,
      name: group.group.name,
      patients: group.group.patients,
    };
  });
  let tempGroupsOfCurrentPatient = [];
  let tempPatients = [];
  mapGroup.forEach((group) => {
    group.patients.forEach((patient) => {
      if (
        tempPatients.find(({ id }) => patient.id === id) === undefined &&
        patient.id !== parseInt(patId)
      ) {
        patient["groupPatient"] = false;
        tempPatients.push(patient);
      }
      if (
        tempPatients.find(({ id }) => patient.id === id) === undefined &&
        patient.id === parseInt(patId)
      )
        if (patient.id === parseInt(patId))
          tempGroupsOfCurrentPatient.push(group);
    });
  });

  let tempPatientsOfCurrentGroup = [];
  tempGroupsOfCurrentPatient.forEach(({ patients }) => {
    patients.forEach((patient) => {
      patient["groupPatient"] = true;
      tempPatientsOfCurrentGroup.push(patient);
    });
  });
  // salvo tutti i pazienti non appartenenti al gruppo del pazie
  let allPatients = tempPatientsOfCurrentGroup;
  tempPatients.forEach((pat) => {
    if (allPatients.find(({ id }) => pat.id === id) === undefined) {
      allPatients.push(pat);
    }
  });
  return { allPatients: allPatients, mapGroup: mapGroup };
};

/**
 * Funzione che resituisce una grid per selezionare il range temporale
 *
 * @param changeDate: funzione che viene richiamata quando cambia il range temporale
 * @param dateRange: range imposto
 * @param dateFormat: formato della data
 *
 */
export const getHeader = (changeDate, dateRange, dateFormat) => {
  return (
    <>
      <Grid item xs={6} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.start_date}
          value={dateRange.start.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            changeDate(moment.range(evnt.target.value, dateRange.end))
          }
        />
      </Grid>
      <Grid item xs={6} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.end_date}
          value={dateRange.end.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            changeDate(moment.range(dateRange.start, evnt.target.value))
          }
        />
      </Grid>
    </>
  );
};

/**
 * Funzione che restituisce la lista di pazienti e per ognuno di essi la lista degli infortuni sulla base
 * del range temporale scelto
 *
 * @param mapGroup: lista dei gruppi dove per ognuno vengono indicatii pazienti con i rispettivi infortuni
 * @param dateRange: data range temporale selezionato dall'utente
 * @returns {Array} injuriesData: lista di oggetti pazieni - infortuni
 *
 */
export const getPatientInjury = async (mapGroup, dateRange) => {
  let injuriesData = [];
  for (let group of mapGroup) {
    const groupProfessional = await getGroupPresentations({
      id_group: group.id,
      start_date: moment(dateRange.start).format("YYYY-MM-DD"),
      end_date: moment(dateRange.end).format("YYYY-MM-DD"),
      
    });

    injuriesData.push(groupProfessional);
  }
  return injuriesData;
};

/**
 * Funzione che restituisce una grid per selezionare i pazienti appartenenti al gruppo del
 * paziente selezionato, oppure i pazienti che non fanno parte del gruppo del paziente selezionato
 * oppure la lista dei gruppi disponibili
 *
 * @param patients: lista dei pazienti
 * @param selectedPatients: lista dei pazienti selezionati
 * @param selectionActionFunction: azione da scatenare quando cambia il paziente o il gruppo
 * selezionato
 *  @param groups: lista dei gruppi disponibili
 *  @param selectGroupFunction: funzione da richiamare quando non sono presenti pazienti selezionati
 *  @param selectedGroups: lista dei gruppi selezionati
 */
export const getPatientsAndGroupsSelection = (
  patients,
  selectedPatients,
  selectionActionFunction,
  groups,
  selectGroupFunction,
  selectedGroups,
  groupsLabel
) => {
  return (
    <Grid item container direction="row">
      <Grid
        item
        container
        xs={4}
        direction="column"
        alignItems={"left"}
        style={{ paddingRight: "8px" }}
      >
        <SpText>{labels.analytics.injuryReport.patientsGroup} </SpText>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.analytics.injuryReport.patientsGroup}
          value={selectedPatients}
          onChange={(_, newValue) =>
            selectionActionFunction(newValue, "gruppo")
          }
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedPatients.map(
              (option, index) =>
                option.groupPatient && (
                  <Chip
                    key={`${option.familyName} ${option.givenName}`}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: "white",
                    }}
                    label={psTranslate(
                      `${option.familyName} ${option.givenName}`
                    )}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                )
            )
          }
          options={patients.filter(({ groupPatient }) => groupPatient)}
          getOptionLabel={(option) =>
            psTranslate(`${option.familyName} ${option.givenName}`)
          }
        />
      </Grid>
      <Grid
        item
        container
        xs={4}
        direction="column"
        alignItems={"left"}
        style={{ paddingRight: "8px", paddingLeft: "8px" }}
      >
        <SpText>{labels.analytics.injuryReport.patientsOtherGrous} </SpText>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.analytics.injuryReport.patientsOtherGrous}
          value={patients.find(
            ({ id, groupPatient }) => id == selectedPatients && !groupPatient
          )}
          onChange={(_, newValue) => selectionActionFunction(newValue, "altri")}
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedPatients.map(
              (option, index) =>
                !option.groupPatient && (
                  <Chip
                    key={`${option.familyName} ${option.givenName}`}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: "white",
                    }}
                    label={psTranslate(
                      `${option.familyName} ${option.givenName}`
                    )}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                )
            )
          }
          options={patients.filter(({ groupPatient }) => !groupPatient)}
          getOptionLabel={(option) =>
            psTranslate(`${option.familyName} ${option.givenName}`)
          }
        />
      </Grid>
      <Grid
        item
        container
        xs={4}
        direction="column"
        alignItems={"left"}
        style={{ paddingLeft: "8px" }}
      >
        <SpText>{groupsLabel}</SpText>
        <SpAutocomplete
          multiple={true}
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={groupsLabel}
          value={groups.find((sel) => sel.id === selectedGroups)}
          onChange={(_, newValue) => {
            if (selectedPatients.length === 0) {
              selectGroupFunction(newValue);
            } else {
              selectionActionFunction([], "tutti", newValue);
            }
          }}
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedGroups.map((option, index) => (
              <Chip
                key={option.name}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: "white",
                }}
                label={psTranslate(option.name)}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
          options={groups}
          getOptionLabel={(option) => psTranslate(option.name)}
          getOptionSelected={(option, value) => option.id === value?.id}
        />
      </Grid>
    </Grid>
  );
};

export const getGroupsSelection = (
  groups,
  selectedGroups,
  selectionActionFunction
) => {
  return (
    <Grid item container direction="row">
      <Grid
        item
        container
        xs={12}
        direction="column"
        alignItems={"left"}
        style={{ paddingRight: "8px" }}
      >
        <SpText>{labels.analytics.injuryReport.selectGroup} </SpText>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.analytics.injuryReport.selectGroup}
          value={selectedGroups}
          onChange={(_, newValue) => {
            let patients = [];
            let arrayPatients = newValue.map(({ patients }) => patients);
            arrayPatients.forEach((patient) => patients.push(...patient));
            const uniqueIds = new Set();
            patients = patients.filter((element) => {
              const isDuplicate = uniqueIds.has(element.id);
              uniqueIds.add(element.id);
              return !isDuplicate;
            });

            selectionActionFunction(newValue, null, patients);
          }}
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedGroups.map((option, index) => (
              <Chip
                key={`${option.name}`}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: "white",
                }}
                label={psTranslate(`${option.name}`)}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
          options={groups}
          getOptionLabel={(option) => psTranslate(`${option.name}`)}
        />
      </Grid>
    </Grid>
  );
};

export const getPatientsSelection = (
  patients,
  selectedPatients,
  selectionActionFunction
) => {
  return (
    <Grid item container direction="row">
      <Grid
        item
        container
        xs={6}
        direction="column"
        alignItems={"left"}
        style={{ paddingRight: "8px" }}
      >
        <SpText>{labels.analytics.injuryReport.patientsGroup} </SpText>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.analytics.injuryReport.patientsGroup}
          value={selectedPatients}
          onChange={(_, newValue) =>
            selectionActionFunction(newValue, "gruppo")
          }
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedPatients.map(
              (option, index) =>
                option.groupPatient && (
                  <Chip
                    key={`${option.familyName} ${option.givenName}`}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: "white",
                    }}
                    label={psTranslate(
                      `${option.familyName} ${option.givenName}`
                    )}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                )
            )
          }
          options={patients.filter(({ groupPatient }) => groupPatient)}
          getOptionLabel={(option) =>
            psTranslate(`${option.familyName} ${option.givenName}`)
          }
        />
      </Grid>
      <Grid
        item
        container
        xs={6}
        direction="column"
        alignItems={"left"}
        style={{ paddingRight: "8px", paddingLeft: "8px" }}
      >
        <SpText>{labels.analytics.injuryReport.patientsOtherGrous} </SpText>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.analytics.injuryReport.patientsOtherGrous}
          value={patients.find(
            ({ id, groupPatient }) => id == selectedPatients && !groupPatient
          )}
          onChange={(_, newValue) => selectionActionFunction(newValue, "altri")}
          displayLabel={false}
          renderTags={(value, getTagProps) =>
            selectedPatients.map(
              (option, index) =>
                !option.groupPatient && (
                  <Chip
                    key={`${option.familyName} ${option.givenName}`}
                    style={{
                      backgroundColor: theme.colors.primary.lightBlue,
                      color: "white",
                    }}
                    label={psTranslate(
                      `${option.familyName} ${option.givenName}`
                    )}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                )
            )
          }
          options={patients.filter(({ groupPatient }) => !groupPatient)}
          getOptionLabel={(option) =>
            psTranslate(`${option.familyName} ${option.givenName}`)
          }
        />
      </Grid>
    </Grid>
  );
};

export const matchTrainingArrayUnion = (trainingArray, matchArray) => {
  let dataChart = [];

  // 1- unisco i dati di training con match
  if (trainingArray.dataChart) {
    trainingArray.dataChart.forEach((data) => {
      const matchPatient = matchArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      if (matchPatient !== undefined) {
        let result = {
          minutes: data.minutes,
          groupDate: titleCase(data.groupDate),
          injuries: data.injuries + matchPatient.injuries,
        };
        result[injuryRateLabel] =
          result.minutes === 0
            ? (1000 * result.injuries).toFixed(1)
            : ((1000 * result.injuries) / result.minutes).toFixed(1);
        dataChart.push(result);
      } else {
        dataChart.push(data);
      }
    });

    // 2- unisco i dati di match con training
    matchArray.dataChart.forEach((data) => {
      const trainingPatient = trainingArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      // solo se non e' gia' stato fatto l'inverso posso considerare
      if (
        dataChart.find(({ groupDate }) => groupDate === data.groupDate) ===
        undefined
      ) {
        if (trainingPatient !== undefined) {
          let result = {
            minutes: data.minutes,
            groupDate: titleCase(data.groupDate),
            injuries: data.injuries + trainingPatient.injuries,
          };
          result[injuryRateLabel] =
            result.minutes === 0
              ? (1000 * result.injuries).toFixed(1)
              : ((1000 * result.injuries) / result.minutes).toFixed(1);
          dataChart.push(result);
        } else {
          dataChart.push(data);
        }
      }
    });
  }

  return dataChart;
};
