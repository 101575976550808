import React from "react";

import { NavLink } from "react-router-dom";
import { LinkContainer } from "../../pages/shared/menu/style";
import { theme } from "../theme";

const linkDisabled = {
  backgroundColor: "grey",
  color: "#505050",
};

const getNavLinkClass = (path, location) =>
  path === "/"
    ? location.pathname === path
    : location.pathname.includes(path) ||
      location.pathname.includes(path + "/");
const SpLinkContainer = ({
  id,
  className,
  destination,
  label,
  path,
  location,
  ...rest
}) => (
  <LinkContainer
    id={id}
    className={className}
    style={
      className === "linkDisabled"
        ? linkDisabled
        : {
            backgroundColor:
              getNavLinkClass(path, location) && theme.colors.primary.lightBlue,
          }
    }
  >
    <NavLink
      to={className !== "linkDisabled" && destination}
      activeClassName={"active-page"}
    >
      {label}
    </NavLink>
  </LinkContainer>
);

export default SpLinkContainer;
