import React from "react";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { labels } from "../../shared/translations";

import {
  ResponsiveContainer,
  AreaChart,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Area,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { theme } from "../../../components/theme";

const TrainingReport = (props) => {
  if (props.type === "Area") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={props.dataChart}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{
              value: labels.injSur.yLabN,
              angle: -90,
              style: { fill: "grey" },
            }}
            labelOffset={-20}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={props.labT}
            stroke={theme.colors.surveillance.lightViolet}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey={props.labM}
            stroke={theme.colors.surveillance.lightPink}
            fill="url(#colorUv2)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer
        width="100%"
        height={500 + props.dataChart.length * 10}
      >
        <BarChart
          data={props.dataChart}
          layout="vertical"
          margin={{ top: 0, right: 20, left: 80, bottom: 20 }}
          barCategoryGap={2.5} // Adjust the gap between categories
          barGap={1.5}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="1" x2="0" y2="0">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightViolet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv2" x1="0" y1="1" x2="0" y2="0">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.lightPink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            type="number"
            stroke="grey"
            label={{
              value: labels.injSur.yLabN,
              angle: 0,
              style: { fill: "grey" },
              dy: 15,
            }}
          />
          <YAxis type="category" dataKey="patientName" stroke="grey" />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
          />
          <Legend wrapperStyle={{ color: "gray" }} verticalAlign="top" />
          <CartesianGrid
            stroke="#f5f5f530"
            vertical={true}
            horizontal={false}
          />
          <Bar
            type="monotone"
            dataKey={props.labT}
            fill="#8884d890"
            stroke="#FFFFFF80"
          />
          <Bar
            type="monotone"
            dataKey={props.labM}
            fill="#ff7d7a90"
            stroke="#FFFFFF80"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default withSnackbar(TrainingReport);
