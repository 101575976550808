import Grid from "@material-ui/core/Grid";
import React from "react";
import { labels } from "../../../shared/translations";
import { FilterAutocomplete } from "./PatientsStatisticsAnalyticsFiltersHelper";

export const AthleteFilters = ({ filters, setFilters }) => {
  return (
    <>
      <Grid container xs={12} direction="row">
        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters
                .unlinked_patients
            }
            value={filters.comparison.selectedUnlinkedPatients}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                comparison: {
                  ...filters.comparison,
                  selectedUnlinkedPatients: newValue,
                },
              })
            }
            renderOptions={filters.comparison.selectedUnlinkedPatients}
            options={filters.comparison.unlinkedPatients}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters
                .linked_patients
            }
            value={filters.comparison.selectedLinkedPatients}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                comparison: {
                  ...filters.comparison,
                  selectedLinkedPatients: newValue,
                },
              })
            }
            renderOptions={filters.comparison.selectedLinkedPatients}
            options={filters.comparison.linkedPatients}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "8px" }}>
          <FilterAutocomplete
            placeholder={
              labels.patient.graphReport.section.feedback.filters.patients_group
            }
            value={filters.comparison.selectedGroupsPatients}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                comparison: {
                  ...filters.comparison,
                  selectedGroupsPatients: newValue,
                },
              })
            }
            renderOptions={filters.comparison.selectedGroupsPatients}
            options={filters.comparison.groupsPatients}
          />
        </Grid>
      </Grid>
    </>
  );
};
