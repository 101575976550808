import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getAllActivitiesTypes,
  getActivityFeedbackParameters,
} from "../../../models/actions/Activity";
import { getActivityFeedbacksInRange } from "../../../models/actions/CalendarActivity";
import {
  getActivityResponseParameterFeedbacks,
  getActivityResponseParameterFeedbacksGroups,
} from "../../../models/actions/Patients";
import { dateFormat, rollbar } from "../../../utils/common";
import {
  addProxyParameters,
  fixForProxyParams,
  fixForProxyParamsResults,
  fixProxyParamsListAvailable,
} from "./FeedbackDataHelper";
import TemplatePatientsReports from "./TemplatePatientsReports";
import { useAuth0 } from "@auth0/auth0-react";

const EXCLUDED_STUDIES = ["SYMMETRY", "ASYMMETRY", "SYMMETRYASS", "INDEXES"];

const PatientsReportsStep1Feedback = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [activities, setActivities] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  //Effects
  useEffect(async () => {
    try {
      //Fetch additional data
      const activs = await getAllActivitiesTypes(getAccessTokenSilently);
      setActivities(activs);
    } catch (error) {
      rollbar.error("PatientsReportsStep1Feedback - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const getStudyParamsInRange = async (
    currActivities,
    fetchDateRange,
    patId,
    groupId
  ) => {
    return await getActivityFeedbacksInRange({
      id_activity_types: currActivities.map((act) => act.id),
      startDate: fetchDateRange.start.format(dateFormat),
      endDate: fetchDateRange.end.format(dateFormat),
      ids_patient: [patId],
      id_group: groupId,
    });
  };

  const fetchPatientsData = async (
    currActivities,
    fetchDateRange,
    currStudyParameterIds,
    patientIds
  ) => {
    return await getActivityResponseParameterFeedbacks({
      id_activity_types: currActivities.map((act) => act.id),
      id_patients: patientIds,
      parameters: currStudyParameterIds,
      startDate: fetchDateRange.start.format(dateFormat),
      endDate: fetchDateRange.end.format(dateFormat),
    });
  };

  const fetchGroupData = async (
    currActivities,
    fetchDateRange,
    currStudyParameterIds,
    groups
  ) => {
    return await getActivityResponseParameterFeedbacksGroups({
      id_activity_types: currActivities.map((act) => act.id),
      id_groups: groups,
      parameters: currStudyParameterIds,
      startDate: fetchDateRange.start.format(dateFormat),
      endDate: fetchDateRange.end.format(dateFormat),
    });
  };

  return (
    <Grid container item xs={12}>
      <TemplatePatientsReports
        elements={activities}
        excludedStudies={EXCLUDED_STUDIES}
        getStudyParams={getActivityFeedbackParameters}
        getStudyParamsInRange={getStudyParamsInRange}
        addProxyParameters={addProxyParameters}
        fixProxyParamsListAvailable={fixProxyParamsListAvailable}
        fixForProxyParams={fixForProxyParams}
        fixForProxyParamsResults={fixForProxyParamsResults}
        fetchPatientsData={fetchPatientsData}
        fetchGroupData={fetchGroupData}
        enableCombinedParameters={true}
        defaultZero={true}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
      />
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep1Feedback);
