import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "../App.css";
import {
  getPatientsByProfessional,
  getPatientsByProfessionalDetails,
  getPatientToProfessionalRequest,
} from "../models/actions/Professionals";
import { listGroupsByProfessional } from "../models/actions/Groups";
import { getActivitiesForProfessional } from "../models/actions/CalendarActivity";
import { getPatientsWithUncheckedAlerts } from "../models/actions/PatientAlerts";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import { styled } from "../components/styled";
import { labels, psTranslate } from "./shared/translations";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import { acceptRequest, refuseRequest } from "../models/actions/Patients";
import moment from "moment-timezone";
import { Avatar, Divider, makeStyles } from "@material-ui/core";
import { getLogsByProf } from "../models/actions/Logs";
import { getUserPermission, rollbar, titleCase } from "../utils/common";
import { theme } from "../components/theme";
import { SpSelect, SpSelectMenuItem } from "../components/atoms/SpSelect.js";
import SpTooltip from "../components/atoms/SpTooltip.js";
import { getLabelFromPatientStatus } from "../utils/common.js";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const DailyCalendarActivityContainer = styled(Grid)({
  paddingTop: 20,
  paddingBottom: 20,
});
const MyGroupContainer = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: 5,
  paddingTop: 20,
  paddingBottom: 20,
});
const PatientRequestContainer = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: 5,
  paddingTop: 20,
  paddingBottom: 20,
});

const GridStyleCard = {
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  overflow: "hidden",
  boxSizing: "border-box",
  marginTop: 5,
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: "#3d988e70",
  minHeight: "10%",
};

const Dashboard = (props) => {
  const [patients, setPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [patientRequests, setPatientRequests] = useState([]);
  const [logs, setLogs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [injured, setInjured] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [isAlertEnabled, setIsAlertEnabled] = useState(false);
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const [advancedDashboardEnabled, setAdvancedDashboardEnabled] =
    useState(false);

  const classes = useStyles();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const GroupsData = async () => {
    try {
      let response = await listGroupsByProfessional();
      if (response) setGroups(response);
      let groupsN = [];
      for (const element of response) {
        groupsN.push(element.group);
      }

      setGroupsName(groupsN);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("Dashboard - groupData", error);
    }
  };

  useEffect(async () => {
    await GroupsData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getPatientsByProfessionalDetails();

      const tempAlertPermission = await getUserPermission(
        "manage:alert",
        getAccessTokenSilently
      );
      const advancedDashboard = await getUserPermission(
        "advanced-dashboard",
        getAccessTokenSilently
      );

      const patientLabelTemp = await getUserPermission(
        "patient-label",
        getAccessTokenSilently
      );

      setIsPatientLabel(patientLabelTemp);
      setAdvancedDashboardEnabled(advancedDashboard);
      setIsAlertEnabled(tempAlertPermission);

      if (response) setPatients(response);
      let filteredInjured = response.filter(
        ({ training_status, status }) =>
          training_status === "unavailable" || status === "time_loss"
      );
      setInjured(filteredInjured);

      const resultAlerts = await getPatientsWithUncheckedAlerts({
        ids: response.map((atl) => atl?.id),
      });
      const uniqueAlert = resultAlerts.uncheckedIds.reduce(
        (accumulator, currentValue) => {
          if (!accumulator.includes(currentValue)) {
            accumulator.push(currentValue);
          }
          return accumulator;
        },
        []
      );
      let filteredAlerts = response.filter(
        ({ id }) => uniqueAlert.indexOf(id) != -1
      );
      setAlerts(filteredAlerts);

      let activities = await getActivitiesForProfessional();
      let filteredAppointments = activities.filter(({ start_date }) =>
        moment(start_date).isSameOrAfter(moment().format("YYYY-MM-DD"))
      );
      filteredAppointments = filteredAppointments.sort(
        ({ start_date: date1 }, { start_date: date2 }) =>
          moment(date1).diff(moment(date2))
      );
      if (activities) setAppointments(filteredAppointments);

      const requestoPatient = await getPatientToProfessionalRequest();
      if (requestoPatient) setPatientRequests(requestoPatient);

      const logs = await getLogsByProf();
      if (logs) setLogs(logs);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("Dashboard - fetchData", error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const acceptPatRequest = async (id_request) => {
    try {
      const response = await acceptRequest({ id_request: id_request });
      if (response.error) {
        return props.snackbarShowErrorMessage(response.error);
      }
      const responseList = await getPatientToProfessionalRequest();
      if (responseList) setPatientRequests(responseList);
      const responsePatList = await getPatientsByProfessional();
      if (responsePatList) setPatients(responsePatList);
      props.snackbarShowMessage(labels.dashboard.requestAccepted);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const refusePatRequest = async (id_request) => {
    try {
      const response = await refuseRequest({ id_request: id_request });
      if (response.error) {
        return props.snackbarShowErrorMessage(response.error);
      }
      props.snackbarShowMessage(labels.dashboard.requestRefused);
      const responseList = await getPatientToProfessionalRequest();
      if (responseList) setPatientRequests(responseList);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  // Columns
  const headCellsDailyAppointments = [
    {
      width: "50%",
      id: "patient",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.patient,
      isAction: false,
    },
    {
      id: "object",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.object,
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      isAction: false,
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.time,
      isAction: false,
    },
  ];

  const DailyCalendarActivityTableRow = ({ row, index }) => {
    const startDate = `${row.start_date} ${row.start_time}`;
    const endDate = `${row.start_date} ${row.end_time}`;
    const start_time = moment.utc(startDate).local().format("HH:mm");
    const end_time = moment.utc(endDate).local().format("HH:mm");
    return (
      <SpTableRow
        role="checkbox"
        id={row?.monitoring?.patient?.id}
        tabIndex={index}
      >
        <SpTableCell>
          <SpTooltip
            id={`alertIconTooltip-workload`}
            title={
              row?.patients
                ? row?.patients.map(
                    ({ patientName }) => `${titleCase(patientName)}, `
                  )
                : `${row?.monitoring?.patient?.givenName} ${row?.monitoring?.patient?.familyName}`
            }
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "inherit",
                cursor: "pointer",
                padding: "0",
                outline: "none",
                width: "100%",
              }}
            >
              <Grid container direction="row">
                {row?.patients ? (
                  row?.patients.map(({ id }) => {
                    const patient = patients.find(
                      ({ id: idPat }) => idPat === id
                    );
                    return (
                      <Grid item xs={1}>
                        <Avatar
                          imgProps={{
                            style: {
                              maxHeight: "100%",
                              maxWidth: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                              overflow: "hidden",
                              border: "2px solid white",
                            },
                          }}
                          className={classes.medium}
                          src={
                            patient?.profileImage ? patient?.profileImage : ""
                          }
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Avatar
                    imgProps={{
                      style: {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                        overflow: "hidden",
                        border: "2px solid white",
                      },
                    }}
                    className={classes.medium}
                    src={
                      patients.find(
                        ({ id: idPat }) =>
                          idPat === row?.monitoring?.patient?.id
                      )?.profileImage
                    }
                  />
                )}
              </Grid>
            </button>
          </SpTooltip>
        </SpTableCell>

        <SpTableCell>
          {row?.activity_datum?.activity_type?.key && (
            <SpTooltip title={row?.activity_datum?.activity_type?.key}>
              <img
                src={require(`../assets/activities/${row?.activity_datum?.activity_type?.key}.png`)}
                style={{
                  maxWidth: 25,
                  maxHeight: 25,
                  marginLeft: 4,
                  marginRight: 4,
                }}
                alt={"img"}
              />
            </SpTooltip>
          )}
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{`${moment(row.start_date).format(
            "DD/MM"
          )}`}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{`${start_time} - ${end_time}`}</SpText>
        </SpTableCell>
      </SpTableRow>
    );
  };

  const DailyCalendarActivity = () => {
    let appointmentsFiltres = [];
    if (selectedGroup.length === 0) {
      appointmentsFiltres = appointments;
    } else {
      let patientOfGroupFound = groups.find(
        (group) => group.group.id === selectedGroup
      )?.group?.patients;
      // let idxsPatientsGroup = [];

      if (patientOfGroupFound) {
        let patientsGroupsIds = patientOfGroupFound.map(({ id }) => id);
        for (let appointment of appointments) {
          let tuttiInclusi = appointment.patients
            .map(({ id }) => id)
            .every((element) => patientsGroupsIds.includes(element));
          if (tuttiInclusi) {
            appointmentsFiltres.push(appointment);
          }
        }
      }
    }

    return (
      <DailyCalendarActivityContainer container direction="column" spacing={1}>
        <Grid item xs={12}>
          {appointmentsFiltres.length > 0 ? (
            <SpTable
              style={{ width: "100%", height: "100%" }}
              headCells={headCellsDailyAppointments}
              rows={appointmentsFiltres}
              rowKey="id"
              padding={false}
              notCheckable={false}
              tableContainerMaxHeight={
                isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
              }
            >
              <DailyCalendarActivityTableRow />
            </SpTable>
          ) : (
            <SpText variant={"h4ComponentLabel"}>
              {labels.dashboard.dailyAppointment.noAppointments}
            </SpText>
          )}
        </Grid>
      </DailyCalendarActivityContainer>
    );
  };

  const AvatarPatient = ({ item, url, text }) => {
    let profileImage;
    if (!item?.profileImage)
      profileImage = patients.find(({ id }) => id === item.id)?.profileImage;
    return (
      <Grid container direction="row">
        <Grid item xs={2}>
          <Avatar
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                borderRadius: "50%",
                overflow: "hidden",
                border: "2px solid white",
              },
            }}
            className={classes.large}
            src={profileImage ? profileImage : item.profileImage}
          />
        </Grid>
        <Grid item xs={10}>
          <SpIconAndTextButton
            id={`patient-row-${item.givenName}`}
            isIconRight={true}
            text={text}
            textVariant={"tableTextDetails"}
            onClick={() => {
              history.push(url);
            }}
          ></SpIconAndTextButton>
        </Grid>
      </Grid>
    );
  };

  const CardJnjury = () => {
    //lista indisponibili
    let injFiltres = [];
    if (selectedGroup.length === 0) {
      injFiltres = injured;
    } else {
      let lstPatietnsGroup = groups.find(
        (group) => group.group.id === selectedGroup
      ).group.patients;
      let idxsPatientsGroup = [];
      for (const element of lstPatietnsGroup) {
        idxsPatientsGroup.push(element.id);
      }
      for (const element of injured) {
        if (idxsPatientsGroup.includes(element.id)) {
          injFiltres.push(element);
        }
      }
    }

    return (
      <Grid
        item
        direction="column"
        xs={4}
        spacing={1}
        style={{ ...GridStyleCard, marginLeft: -30 }}
      >
        <SpText
          id={"injured"}
          variant={"h1PageTitle"}
          style={{ marginBottom: "5%" }}
        >
          {`${labels.dashboard.injured.toUpperCase()}: ${injFiltres.length}`}
        </SpText>
        <Divider
          style={{
            backgroundColor: "white",
            marginTop: "1%",
            height: 1,
            marginBottom: "5%",
          }}
        />
        {injFiltres.map((item, index) => {
          return (
            <div key={index} style={{ marginBottom: "10px" }}>
              <AvatarPatient
                item={item}
                url={`/patients/edit/${item.id}/patientsInjuryIllness`}
                text={titleCase(
                  `${item.givenName} ${
                    item.familyName
                  } - ${getLabelFromPatientStatus({ status: item.status })}`
                )}
              />
            </div>
          );
        })}
      </Grid>
    );
  };

  const CardAlert = () => {
    let altFiltres = [];
    if (selectedGroup.length === 0) {
      altFiltres = alerts;
    } else {
      let lstPatietnsGroup = groups.find(
        (group) => group.group.id === selectedGroup
      ).group.patients;
      let idxsPatientsGroup = [];
      for (const element of lstPatietnsGroup) {
        idxsPatientsGroup.push(element.id);
      }
      for (const element of alerts) {
        if (idxsPatientsGroup.includes(element.id)) {
          altFiltres.push(element);
        }
      }
    }

    return (
      <Grid
        item
        direction="column"
        xs={4}
        spacing={1}
        style={{ ...GridStyleCard, marginLeft: 10 }}
      >
        <SpText
          id={"injured"}
          variant={"h1PageTitle"}
          style={{ marginBottom: "5%" }}
        >
          {`${labels.dashboard.alertT.toUpperCase()}: ${altFiltres.length}`}
        </SpText>
        <Divider
          style={{
            backgroundColor: "white",
            marginTop: "1%",
            height: 1,
            marginBottom: "5%",
          }}
        />
        {altFiltres.map((item, index) => {
          return (
            <div key={index} style={{ marginBottom: "10px" }}>
              <AvatarPatient
                item={item}
                url={`/patients/edit/${item.id}/alerts`}
                text={titleCase(`${item.givenName} ${item.familyName}`)}
              />
            </div>
          );
        })}
      </Grid>
    );
  };

  const CardRisk = () => {
    let altFiltres = [];
    if (selectedGroup.length === 0) {
      for (let i = 0; i < groups.length; i++) {
        for (let p = 0; p < groups[i].group.patients.length; p++) {
          const injuryRisk = JSON.parse(
            groups[i].group.patients[p].injury_risk
          );
          if (parseFloat(injuryRisk?.score) > 80) {
            altFiltres.push(groups[i].group.patients[p]);
          }
        }
      }
    } else {
      let allFiltres = groups.find((group) => group.group.id === selectedGroup)
        .group.patients;
      for (const element of allFiltres) {
        const injuryRisk = JSON.parse(element.injury_risk);
        if (parseFloat(injuryRisk?.score) > 80) {
          altFiltres.push(element);
        }
      }
    }

    return (
      <Grid
        item
        direction="column"
        xs={4}
        spacing={1}
        style={{ ...GridStyleCard, marginLeft: 10 }}
      >
        <SpText
          id={"injured"}
          variant={"h1PageTitle"}
          style={{ marginBottom: "5%" }}
        >
          {`${labels.dashboard.riskT.toUpperCase()}: ${altFiltres.length}`}
        </SpText>
        <Divider
          style={{
            backgroundColor: "white",
            marginTop: "1%",
            height: 1,
            marginBottom: "5%",
          }}
        />
        {altFiltres.map((item, index) => {
          const risk = JSON.parse(item.injury_risk);
          // const tooltipText = `Injury: ${risk?.ratio_injury}% - Proms: ${risk?.ratio_proms}% - Test: ${risk?.ratio_test}% - Workload: ${risk?.ratio_workload}%`;

          return (
            // <SpTooltip
            //  title={<h2 style={{ color: "withe" }}>{tooltipText}</h2>}
            // >
            <div key={index} style={{ marginBottom: "10px" }}>
              <AvatarPatient
                item={item}
                url={`/patients/edit/${item.id}/alerts`}
                text={titleCase(`${item.givenName} ${item.familyName}`)}
              />
            </div>
            //</SpTooltip>
          );
        })}
      </Grid>
    );
  };

  const ConnectedPatientsG = () => {
    let patN = 0;
    if (selectedGroup.length === 0) {
      patN = patients.length;
    } else {
      patN = groups.find((group) => group.group.id === selectedGroup).group
        .patients.length;
    }

    return (
      <MyGroupContainer>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            <SpText
              id={"patientsNumber"}
              variant={"h2PageTitle"}
              style={{ fontSize: "2em" }}
            >
              {labels.dashboard.patientsNumber.toUpperCase()}: {patN}
            </SpText>
          </Grid>
        </Grid>
      </MyGroupContainer>
    );
  };

  const headCellsPatientsRequest = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.patientRequests.table.header.name,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const PatientsRequestsRow = ({ row, index }) => {
    return (
      <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
        <SpTableCell>
          <SpText variant="tableText">{`${row.patient.givenName} ${row.patient.familyName}`}</SpText>
        </SpTableCell>
        <SpTableCell align={"right"} style={{ paddingRight: "0" }}>
          <SpButton
            buttonType={"accept"}
            text={labels.dashboard.patientRequests.table.requestRow.accept.toUpperCase()}
            onClick={() => {
              acceptPatRequest(row.id);
            }}
          />
          <SpButton
            buttonType={"cancel"}
            text={labels.dashboard.patientRequests.table.requestRow.refuse.toUpperCase()}
            variant="h1PageTitle"
            onClick={() => {
              refusePatRequest(row.id);
            }}
          />
        </SpTableCell>
      </SpTableRow>
    );
  };

  const MyPatientsRequest = () => {
    return (
      <MyGroupContainer>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            <SpText variant={"h1PageTitle"}>
              {labels.dashboard.patientRequests.title.toUpperCase()}
            </SpText>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <SpIconAndTextButton
              isIconRight={true}
              text={labels.dashboard.patientRequests.goToPatients.toUpperCase()}
              style={{
                backgroundColor: theme.colors.primary.lightBlue,
                width: "100%",
              }}
              isUpperCase={true}
              isIconButton={true}
              onClick={() => history.push(`/patients`)}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#FFFFFF",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </Grid>
        </Grid>
        <Grid item container xs={11}>
          <SpTable
            style={{ width: "100%" }}
            headCells={headCellsPatientsRequest}
            rows={patientRequests}
            rowKey="id"
            padding
            notCheckable={false}
            tableContainerMaxHeight={
              isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
            }
          >
            <PatientsRequestsRow />
          </SpTable>
        </Grid>
      </MyGroupContainer>
    );
  };

  const headCellsLogs = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.operations.table.header.title,
      isAction: false,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.operations.table.header.description,
      isAction: false,
    },
  ];

  const OperationsTableRow = ({ row, index }) => (
    <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
      <SpTableCell>
        <SpText variant="tableText">{row.title}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableText">{row.content}</SpText>
      </SpTableCell>
    </SpTableRow>
  );

  const OperationTable = () => {
    return (
      <PatientRequestContainer>
        <SpText variant={"h1PageTitle"}>
          {labels.dashboard.operations.title.toUpperCase()}
        </SpText>
        <Grid item xs={12}>
          <SpTable
            style={{ width: "100%", marginTop: "0px !important" }}
            headCells={headCellsLogs}
            rows={logs}
            rowKey="id"
            padding={false}
            notCheckable={false}
            tableContainerMaxHeight={
              isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
            }
          >
            <OperationsTableRow />
          </SpTable>
        </Grid>
      </PatientRequestContainer>
    );
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={5} direction="column" style={{ margin: "1rm" }}>
        {groupsName?.length > 0 && (
          <Grid item xs={12} container direction="row" spacing={1}>
            <SpSelect
              displayLabel={true}
              label={
                isPatientLabel
                  ? labels.dashboard.groupSelPat
                  : labels.dashboard.groupSel
              }
              onChange={(e) => setSelectedGroup(e.target.value)}
              value={selectedGroup}
              labelStyle={{ fontSize: "2em" }}
            >
              <SpSelectMenuItem value="">
                {labels.dashboard.groupAll.toUpperCase()}
              </SpSelectMenuItem>

              {groupsName.map(({ id, name }, index) => (
                <SpSelectMenuItem key={index} value={id}>
                  {name}
                </SpSelectMenuItem>
              ))}
            </SpSelect>
          </Grid>
        )}
        <ConnectedPatientsG />
        <Grid item xs={12}>
          <Divider
            style={{
              backgroundColor: theme.colors.primary.lightBlue,
              marginTop: "1%",
              height: 1,
              marginBottom: "1%",
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <SpText id={"dailyAppointment"} variant={"h1PageTitle"}>
            {labels.dashboard.dailyAppointment.title.toUpperCase()}
          </SpText>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "65vh", overflow: "auto" }}
          direction="column"
        >
          <DailyCalendarActivity />
        </Grid>
        {patientRequests.length > 0 && <MyPatientsRequest pagination={false} />}
        {logs.length > 0 && (
          <Grid item xs={12}>
            <Divider
              style={{
                backgroundColor: theme.colors.primary.lightBlue,
                marginTop: "1%",
                height: 1,
                marginBottom: "1%",
              }}
            />
          </Grid>
        )}
        {logs.length > 0 && <OperationTable />}
      </Grid>
      <Grid item xs={7} container direction="row" spacing={1}>
        {advancedDashboardEnabled && (
          <Grid item xs={12} container direction="row" spacing={1}>
            <CardJnjury />
            {isAlertEnabled && (
              <>
                <CardAlert />
                <CardRisk />
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Dashboard);
