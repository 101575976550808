import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { theme } from "../../../../../components/theme";
import { styled } from "../../../../../components/styled";

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const CommonRow = ({ row, index, updateRow }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: theme.colors.primary.lightBlue,
          }}
        />
      )}
      <StyledRowList
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
        spacing={1}
      >
        <Grid item xs={4}>
          <SpTextInput
            style={{ width: "100%" }}
            value={row.activity}
            onChange={(evnt) => updateRow(index, "activity", evnt.target.value)}
            defaultValue={row.activity}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            type="number"
            minValue={1}
            style={{ width: "100%" }}
            value={row.duration}
            onChange={(evnt) => updateRow(index, "duration", evnt.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            style={{ width: "100%" }}
            value={row.description}
            onChange={(evnt) =>
              updateRow(index, "description", evnt.target.value)
            }
          />
        </Grid>
      </StyledRowList>
    </div>
  );
};

export default CommonRow;
