import React from "react";
import "../../App.css";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { ThresholdCard } from "../../components/atoms/ThresholdCard";
import { Grid } from "@material-ui/core";
import SpText from "../../components/atoms/SpText";
import { labels } from "../shared/translations";
import { controlRoomStep } from "../../utils/common";
import SpTooltip from "../../components/atoms/SpTooltip";

export const PatientInjuryRiskDetails = ({
  selectedRow,
  selectedStep,
  setSelectedStep,
}) => {
  const threshold1 = 20;
  const threshold2 = 60;
  const threshold3 = 80;

  const ButtonCard = ({
    tooltip,
    title,
    value,
    description,
    event,
    isSelected = false,
  }) => (
    <Grid item xs={4}>
      <SpTooltip id={`alertIconTooltip-workload`} title={tooltip}>
        <button
          onClick={event}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "inherit",
            cursor: "pointer",
            padding: "0",
            outline: "none",
            width: "100%",
          }}
        >
          <ThresholdCard
            title={title}
            //description={description}
            value={value}
            threshold1={threshold1}
            threshold2={threshold2}
            threshold3={threshold3}
            isPersentage={true}
            isSelected={isSelected}
            show_val={false}
          />
        </button>
      </SpTooltip>
    </Grid>
  );

  return (
    <Grid item container direction="row" spacing={1}>
      {/*{selectedStep === controlRoomStep.default && (
        <SpText variant="h4ComponentLabel">
          {labels.patient.injuryRisk.title}
        </SpText>
      )}*/}
      {/*<ButtonCard
        tooltip={labels.patient.injuryRisk.injury}
        title={"Injuries"}
        value={selectedRow?.injury_risk?.ratio_injury}
        description={"Injury risk"}
      />*/}
      <ButtonCard
        tooltip={labels.patient.injuryRisk.proms}
        title={"PROMs"}
        value={selectedRow?.injury_risk?.ratio_proms}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        isSelected={selectedStep === controlRoomStep.proms}
        event={() => {
          if (selectedStep === controlRoomStep.proms) {
            setSelectedStep(controlRoomStep.default);
          } else {
            setSelectedStep(controlRoomStep.proms);
          }
        }}
      />
      <ButtonCard
        tooltip={labels.patient.injuryRisk.workload}
        title={"Workloads"}
        value={selectedRow?.injury_risk?.ratio_workload}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        isSelected={selectedStep === controlRoomStep.workload}
        event={() => {
          if (selectedStep === controlRoomStep.workload) {
            setSelectedStep(controlRoomStep.default);
          } else {
            setSelectedStep(controlRoomStep.workload);
          }
        }}
      />
      <ButtonCard
        tooltip={labels.patient.injuryRisk.test}
        title={"Tests"}
        value={selectedRow?.injury_risk?.ratio_test}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        isSelected={selectedStep === controlRoomStep.test}
        event={() => {
          if (selectedStep === controlRoomStep.test) {
            setSelectedStep(controlRoomStep.default);
          } else {
            setSelectedStep(controlRoomStep.test);
          }
        }}
      />
    </Grid>
  );
};

export default withSnackbar(PatientInjuryRiskDetails);
