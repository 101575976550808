import { TableCell } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpSearch from "../../components/atoms/SpSearch";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import {
  updateDefaultParameter,
  getDefaultParameter,
} from "../../models/actions/Activity";
import {
  duplicateProtocolTemplate,
  duplicateRehabTemplate,
  duplicateRiskFactorTemplate,
  duplicateTrainingTemplate,
  getAllActivityPlanTemplates,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllRiskFactorTemplates,
  getAllTrainingTemplates,
  getAllAssessmentTests,
  removeTemplateById,
} from "../../models/actions/Activity";
import {
  duplicateAssessmentTemplate,
  getAssessmentsTemplateCategories,
  getAssessmentTemplates,
} from "../../models/actions/Assessment";
import { duplicateCalendarActivityTemplate } from "../../models/actions/CalendarActivity";
import {
  deleteExercise,
  duplicateExercise,
  duplicateExerciseTemplate,
  getAllExerciseCategories,
  getAllExercises,
  getExerciseTemplates,
} from "../../models/actions/Excercise";
import { getProfessionalById } from "../../models/actions/Professionals";
import ProfessionalShareDialog from "../shared/dialogs/ProfessionalShareDialog";
import { labels, psTranslate } from "../shared/translations";
import { titleCase } from "../../utils/common";
import { Checkbox, Chip, Grid } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import SpAutocomplete from "../../components/atoms/SpAutocomplete";
import { getAllFeedbackParameters } from "../../models/actions/Activity";

const mylabData = {
  "activity-plan": {
    header: labels.mylab.tempDetail.activityPlanList,
    fetch: getAllActivityPlanTemplates,
    clone: duplicateCalendarActivityTemplate,
  },
  treatment: {
    header: labels.mylab.tempDetail.treatment,
    fetch: getAllProtocolTemplates,
    clone: duplicateProtocolTemplate,
  },
  rehab: {
    header: labels.mylab.tempDetail.rehab,
    fetch: getAllRehabTemplates,
    clone: duplicateRehabTemplate,
  },
  risk_factor: {
    header: labels.mylab.tempDetail.riskFactor,
    fetch: getAllRiskFactorTemplates,
    clone: duplicateRiskFactorTemplate,
  },
  training: {
    header: labels.mylab.tempDetail.training,
    fetch: getAllTrainingTemplates,
    clone: duplicateTrainingTemplate,
  },
  excercise: {
    header: labels.mylab.tempDetail.excerciseSheet,
    fetch: getExerciseTemplates,
    clone: duplicateExerciseTemplate,
  }, //Intentional spelling error
  assesment: {
    header: labels.mylab.tempDetail.assesment,
    fetch: () => getAssessmentTemplates({ is_handbook: 0 }),
    clone: duplicateAssessmentTemplate,
  }, //Intentional spelling error
  handbook: {
    header: labels.mylab.tempDetail.handbook,
    fetch: () => getAssessmentTemplates({ is_handbook: 1 }),
    clone: duplicateAssessmentTemplate,
  }, //Intentional, handbook is same as assessment
  "custom-exercise": {
    header: labels.mylab.tempDetail.customExercise,
    fetch: getAllExercises,
    clone: duplicateExercise,
    fetchFilters: getAllExerciseCategories,
    filterHasNone: true,
    filter: (arr, filters, searchString) => {
      let elems =
        filters.length > 0
          ? arr.filter((ex) => {
              const exCategories = ex.exercise_exercise_categories.map(
                (el) => el.id_exercise_category
              );
              if (exCategories.length === 0) exCategories.push(null);
              return filters.some((filter) => exCategories.includes(filter));
            })
          : arr;
      if (searchString)
        elems = elems.filter(
          (i) =>
            i.name.toLowerCase().includes(searchString) ||
            i.tags.toLowerCase().includes(searchString)
        );
      return elems;
    },
    search: true,
  },
  "assessment-test": {
    header: labels.mylab.tempDetail.assessmentTest,
    fetch: getAllAssessmentTests,
    clone: null,
    fetchFilters: getAssessmentsTemplateCategories,
    filterHasNone: false,
    filter: (arr, filters, searchString) => {
      let elems =
        filters.length > 0
          ? arr.filter((test) => {
              return filters.some(
                (filter) =>
                  test.assessment_element.assessment_assessment_category
                    .id_assessment_category === filter
              );
            })
          : arr;
      if (searchString)
        elems = elems.filter(
          (i) =>
            i.name.toLowerCase().includes(searchString) ||
            i.tag.toLowerCase().includes(searchString) ||
            i.type.toLowerCase().includes(searchString)
        );
      return elems;
    },
    search: true,
  },
  organization: {
    header: labels.mylab.tempDetail.assesment,
    fetch: () => {},
    clone: null,
  },
  organizationDetails: {
    header: labels.mylab.tempDetail.assesment,
    fetch: () => {},
    clone: null,
  },
};

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

//rendering the profile editing form
const MyLabTableRow = ({
  row,
  index,
  sectionName,
  idProfessional,
  setSelectedRow,
  setOpenAddProfessional,
  setOpenDeleteTemplateDialog,
}) => {
  const history = useHistory();

  return (
    <SpTableRow key={row.id} role="checkbox" id={row.id} tabIndex={index}>
      <SpTableCell>
        {sectionName !== "activity-plan" ? (
          <SpText variant="tableText">
            {titleCase(psTranslate(row.name))}
          </SpText>
        ) : (
          <SpText variant="tableText">{row.template_name}</SpText>
        )}
      </SpTableCell>
      <TableCell
        align={"right"}
        style={{
          padding: 0,
          verticalAlign: "bottom",
          borderBottom: 0,
          maxWidth: "512px",
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs={sectionName !== "assessment-test" ? 3 : 12}>
            <SpButton
              variant={"standardMax"}
              type="tableList"
              text={labels.mylab.tempList.action.view}
              onClick={() =>
                history.push(`/mylab/${sectionName}/add/${row.id}`)
              }
            />
          </Grid>
          {sectionName !== "assessment-test" && (
            <>
              <Grid item xs={3}>
                <SpButton
                  variant={"standardOppositeMax"}
                  disabled={sectionName === "risk_factor"}
                  type="tableList"
                  text={labels.mylab.tempList.action.clone}
                  onClick={() => {
                    history.push(`/mylab/${sectionName}/clone/${row.id}`);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SpButton
                  disabled={
                    sectionName === "custom-exercise" &&
                    row.id_professional !== idProfessional
                  }
                  variant={"standardMax"}
                  type="tableList"
                  text={labels.mylab.tempList.action.share}
                  onClick={() => {
                    setSelectedRow(row);
                    setOpenAddProfessional(true);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SpButton
                  disabled={
                    sectionName === "custom-exercise" &&
                    row.id_professional !== idProfessional
                  }
                  variant={"standardOppositeMax"}
                  type="tableList"
                  text={labels.mylab.tempList.action.delete}
                  onClick={() => {
                    setSelectedRow(row);
                    setOpenDeleteTemplateDialog(true);
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </TableCell>
    </SpTableRow>
  );
};

const MyLabTemp = (props) => {
  const [idProfessional, setIdProfessional] = useState(-1);
  const history = useHistory();
  const { sectionName } = useParams();
  const [displayItems, setDisplayItems] = useState([]);
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] =
    useState(false);
  const [openProfessional, setOpenAddProfessional] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [filterOptions, setFilterOptions] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const { setLoading } = props;
  const header = mylabData[sectionName]?.header;

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchStringVal, setSearchStringVal] = useState("");
  const [searchString, setSearchString] = useState(null);
  const [currItems, setCurrItems] = useState([]);

  const loadParametersDataSorted = loadParametersData.sort((paramA, paramB) =>
    paramA.name.localeCompare(paramB.name)
  );

  const _fetch = async () => {
    setLoading(true);

    const defaultFeedback = await getDefaultParameter();
    setLoadParametersList(defaultFeedback);

    const feedbackParametersList = await getAllFeedbackParameters();
    feedbackParametersList && setLoadParametersData(feedbackParametersList);

    const professionalProfile = await getProfessionalById();
    setIdProfessional(professionalProfile.id);

    const currMylab = mylabData[sectionName];
    const results = await currMylab.fetch();

    const items = results.sort((a, b) =>
      (psTranslate(a.name) ?? a.template_name).localeCompare(
        psTranslate(b.name) ?? b.template_name
      )
    );
    setDisplayItems(items);
    setCurrItems(items);

    //Fetch eventual filters
    if (currMylab.fetchFilters) {
      const filters = await currMylab.fetchFilters();
      if (currMylab.filterHasNone) {
        filters.push({
          id: null,
          name: labels.mylab.tempDetail.toolbar.uncategorized,
        });
      }
      setFilterOptions(
        filters.sort((a, b) =>
          psTranslate(a.name).localeCompare(psTranslate(b.name))
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    _fetch();
  }, []);

  useEffect(() => {
    let newItems =
      filterOptions || searchString
        ? mylabData[sectionName]?.filter(
            displayItems,
            selectedFilters,
            searchString
          )
        : displayItems;
    setCurrItems([...newItems]);
  }, [selectedFilters, searchString]);

  const saveDefaultFeedback = async () => {
    try {
      const updateFeedbackResult = await updateDefaultParameter({
        feedback_ids: loadParametersList.map(({ id }) => id),
      });
      if (updateFeedbackResult.message)
        props.snackbarShowMessage(updateFeedbackResult.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  // Columns
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.name,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.actions,
      isAction: true,
    },
  ];

  const removeTemplate = async () => {
    try {
      let result;
      if (sectionName === "custom-exercise") {
        result = await deleteExercise({
          id_exercise: selectedRow.id,
        });
      } else {
        result = await removeTemplateById({
          id_template: selectedRow.id,
          section: sectionName,
        });
      }
      if (result.message) {
        props.snackbarShowMessage(result.message);
        await _fetch();
      } else {
        props.snackbarShowErrorMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setOpenDeleteTemplateDialog(false);
  };

  const duplicateTemplate = async (
    professional,
    currSelectedRow,
    newName,
    newReference,
    newNote
  ) => {
    if (professional) {
      setOpenAddProfessional(false);
      setLoading(true);
      try {
        const cloneFn = mylabData[sectionName]?.clone;
        await cloneFn({
          id_template: currSelectedRow.id,
          id_target_professionals: [professional],
          new_name:
            newName ?? currSelectedRow.name ?? currSelectedRow.template_name,
          new_reference:
            newReference ??
            currSelectedRow.reference ??
            currSelectedRow.template_reference,
          new_note:
            newNote ?? currSelectedRow.note ?? currSelectedRow.template_note,
        });
        await _fetch();
        props.snackbarShowMessage(labels.mylab.tempList.cloneSuccess);
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={filterOptions ? 4 : 8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>{header?.subtitle}</span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {sectionName === "custom-exercise" && (
            <Grid
              item
              xs={10}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                buttonType="accept"
                text={labels.mylab.tempDetail.toolbar.editCategory}
                onClick={() =>
                  history.push(`/mylab/${sectionName}/add-category`)
                }
                variant="h1"
              />
            </Grid>
          )}
          {sectionName !== "assessment-test" && (
            <Grid
              item
              xs={sectionName === "custom-exercise" ? 2 : 5}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                buttonType="accept"
                text={labels.mylab.tempDetail.toolbar.add}
                onClick={() => history.push(`/mylab/${sectionName}/add`)}
                variant="h1"
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} container alignItems={"flex-end"} spacing={1}>
          {filterOptions && (
            <Grid
              item
              xs={3}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpSelect
                multiple
                label={labels.mylab.tempDetail.toolbar.filter}
                formControlWidth={"100%"}
                labelPaddingTop={"0"}
                value={selectedFilters}
                onChange={(evnt) => setSelectedFilters(evnt.target.value)}
              >
                {filterOptions.map((filter) => (
                  <SpSelectMenuItem key={filter.id} value={filter.id}>
                    {psTranslate(filter.name)}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
            </Grid>
          )}

          {mylabData[sectionName]?.search && (
            <Grid
              item
              xs={3}
              alignItems={"center"}
              style={{ display: "flex", flex: 1 }}
            >
              <SpSearch
                id={"mylabSearch"}
                onChange={(e) => {
                  clearTimeout(searchTimeout);
                  const val = e.currentTarget.value.toLowerCase();
                  setSearchStringVal(val);
                  setSearchTimeout(
                    setTimeout(() => setSearchString(val), 1000)
                  );
                }}
                value={searchStringVal}
                style={{ width: "100%" }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={2}
            alignItems={"center"}
            style={{ display: "flex", flex: 1 }}
          >
            <SpButton
              buttonType="accept"
              text={labels.mylab.tempDetail.toolbar.clear}
              onClick={() => {
                clearTimeout(searchTimeout);
                setSelectedFilters([]);
                setSearchStringVal("");
                setSearchString(null);
              }}
              variant="h1"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "0.2em" }}>
          {displayItems && displayItems.length === 0 && (
            <SpText variant="h1PageTitle">{labels.mylab.no_template}</SpText>
          )}
          {displayItems && displayItems.length > 0 && (
            <SpTable
              pagination={true}
              headCells={headCells}
              rows={currItems}
              rowKey="id"
              padding={false}
              notCheckable={false}
            >
              <MyLabTableRow
                sectionName={sectionName}
                idProfessional={idProfessional}
                setSelectedRow={setSelectedRow}
                setOpenAddProfessional={setOpenAddProfessional}
                setOpenDeleteTemplateDialog={setOpenDeleteTemplateDialog}
              />
            </SpTable>
          )}
          {!currItems?.length && (
            <SpText variant="h1PageTitle">
              {labels.mylab.tempDetail.table.empty}
            </SpText>
          )}
        </Grid>
      </Grid>
      <ProfessionalShareDialog
        openProfessional={openProfessional}
        setOpenAddProfessional={setOpenAddProfessional}
        addCallback={(professional) =>
          duplicateTemplate(professional.id, selectedRow)
        }
        selectValues={null}
      />
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteTemplateDialog}
        setOpen={setOpenDeleteTemplateDialog}
        title={labels.mylab.dialog.delete.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.mylab.dialog.delete.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.mylab.dialog.delete.yes}
                buttonType="accept"
                onClick={() => removeTemplate()}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.mylab.dialog.delete.no}
                buttonType="accept"
                onClick={() => setOpenDeleteTemplateDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>

      {/* Default parameter for activity */}
      {sectionName === "activity-plan" && (
        <>
          <Grid
            item
            container
            xs={12}
            style={{ marginLeft: "1em", marginTop: "5em" }}
          >
            <SpText variant="h1">
              <span style={{ color: theme.colors.primary.white }}>
                {labels.mylab.gpsDevices.manage.defaultParameterTitle}
              </span>
            </SpText>
          </Grid>
          <Grid item container xs={12} style={{ marginLeft: "1em" }}>
            <Grid item xs={6} container direction="column">
              <SpAutocomplete
                multiple
                style={{ width: "100%", height: "100%", maxHeight: "100%" }}
                formControlWidth={"100%"}
                selectPlaceholder={
                  labels.mylab.gpsDevices.manage.defaultParameterSelect
                }
                options={loadParametersDataSorted}
                getOptionLabel={(option) => psTranslate(option.name)}
                getOptionSelected={(opt, value) => {
                  return opt.id === value.id;
                }}
                value={loadParametersList}
                onChange={(_, newValue) => {
                  setLoadParametersList(newValue);
                }}
                renderOption={(props, option) => (
                  <li
                    key={`option_${props.id}`}
                    style={{
                      color: option.selected
                        ? theme.colors.primary.white
                        : theme.colors.primary.black,
                    }}
                  >
                    <Checkbox
                      name={props.id}
                      icon={<CheckBoxOutlineBlankIcon small />}
                      checkedIcon={<CheckBoxIcon small />}
                      style={{
                        marginRight: 5,
                        color: option.selected
                          ? theme.colors.primary.white
                          : theme.colors.primary.black,
                      }}
                      checked={option.selected}
                    />
                    {psTranslate(props.name)}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const _val = loadParametersDataSorted.find(
                      (x) => x.id === (option.id ?? option)
                    );
                    return (
                      <Chip
                        key={_val?.id}
                        style={{
                          backgroundColor: theme.colors.primary.lightBlue,
                          color: theme.colors.primary.white,
                        }}
                        label={psTranslate(_val?.name)}
                        size="medium"
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: "8px" }}>
              <SpButton
                style={{ marginTop: "1em" }}
                type="tableList"
                text={labels.mylab.gpsDevices.manage.updateButton}
                onClick={saveDefaultFeedback}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withSnackbar(MyLabTemp);
