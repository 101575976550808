import { Divider, Grid } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { useState } from "react";
import { SpAutocomplete } from "../../../../../components/atoms/SpAutocomplete";
import SpIconButton from "../../../../../components/atoms/SpIconButton";
import SpText from "../../../../../components/atoms/SpText";
import SpTextArea from "../../../../../components/atoms/SpTextArea";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { theme } from "../../../../../components/theme";
import { labels, psTranslate } from "../../../../shared/translations";

const ExerciseSheetRow = ({
  isPrint,
  row,
  index,
  exerciseCategoriesList,
  exercisesList,
  filterOptions,
  updateRow,
  setExercisesDetail,
  setDialogExerciseOpen,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const filteredExercises = exercisesList.filter((ex) => {
    const exCategories = ex.exercise_exercise_categories.map(
      (el) => el.id_exercise_category
    );
    if (exCategories.length === 0) exCategories.push(null);
    return !selectedCategory || exCategories.includes(selectedCategory?.id);
  });

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: theme.colors.primary.lightBlue,
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
      )}
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={3}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.categoryHeader}
          </SpText>
          <SpAutocomplete
            isPrint={isPrint}
            formControlWidth={"100%"}
            selectPlaceholder={labels.mylab.selectExercise}
            value={selectedCategory}
            onChange={(e, newValue) => {
              setSelectedCategory(newValue);
              updateRow(index, "id_excercise", null);
            }}
            options={exerciseCategoriesList}
            getOptionLabel={(option) => psTranslate(option.name)}
            getOptionSelected={(option, value) => option.id === value?.id}
          />
        </Grid>

        <Grid item xs={9}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.name}
          </SpText>
          <SpAutocomplete
            isPrint={isPrint}
            formControlWidth={"100%"}
            disableListWrap
            selectPlaceholder={labels.mylab.selectExercise}
            value={
              exercisesList.find((el) => el.id === row.id_excercise) ?? null
            }
            onChange={(e, newValue) =>
              updateRow(index, "id_excercise", newValue?.id)
            }
            options={filteredExercises}
            getOptionLabel={(option) => psTranslate(option.name)}
            getOptionSelected={(option, value) => option.id === value?.id}
            filterOptions={filterOptions}
            renderOption={(option) => (
              <Grid
                direction="row"
                container
                alignItems={"center"}
                alignSelf={"center"}
              >
                <Grid item xs={1}>
                  <SpIconButton
                    buttonType={"accept"}
                    small
                    onClick={() => {
                      setExercisesDetail(option);
                      setDialogExerciseOpen(true);
                    }}
                  >
                    <InfoIcon />
                  </SpIconButton>
                </Grid>
                <Grid item xs={11}>
                  <SpText variant="text" color={theme.colors.primary.black}>
                    {psTranslate(option.name)}
                  </SpText>
                </Grid>
              </Grid>
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.series}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            type="number"
            minValue={0}
            style={{ width: "100%" }}
            onChange={(evnt) => updateRow(index, "series", evnt.target.value)}
            value={row.series}
          />
        </Grid>
        <Grid item xs={3}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.duration}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            type="number"
            minValue={0}
            style={{ width: "100%" }}
            onChange={(evnt) =>
              updateRow(index, "repetition_time", evnt.target.value)
            }
            value={row.repetition_time}
          />
        </Grid>

        <Grid item xs={3}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.recovery}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            type="number"
            minValue={0}
            style={{ width: "100%" }}
            onChange={(evnt) => updateRow(index, "resting", evnt.target.value)}
            value={row.resting}
          />
        </Grid>
        <Grid item xs={3}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.load}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            type="number"
            minValue={0}
            style={{ width: "100%" }}
            onChange={(evnt) => updateRow(index, "load", evnt.target.value)}
            value={row.load}
          />
        </Grid>

        <Grid item xs={6}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.accessories}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            onChange={(evnt) =>
              updateRow(index, "accessories", evnt.target.value)
            }
            style={{ width: "100%" }}
            value={row.accessories}
          />
        </Grid>
        <Grid item xs={6}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.mode}
          </SpText>
          <SpTextInput
            isPrint={isPrint}
            onChange={(evnt) =>
              updateRow(index, "execution", evnt.target.value)
            }
            style={{ width: "100%" }}
            value={row.execution}
          />
        </Grid>

        <Grid item xs={12}>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header.notes}
          </SpText>
          <SpTextArea
            isPrint={isPrint}
            onChange={(evnt) => updateRow(index, "note", evnt.target.value)}
            style={{ width: "100%" }}
            value={row.note}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExerciseSheetRow;
