import { Button as MuiButton, Grid, Chip, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";

import SpSearch from "../../components/atoms/SpSearch";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { getGroupsById } from "../../models/actions/Groups";
import { rollbar } from "../../utils/common";
import { labels } from "../shared/translations";
import { getProfessionalsTeams } from "../../models/actions/Team";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const FederationProfessionalsTeams = (props) => {
  const [searchStringProf, setSearchStringProf] = useState(null);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamsSearch, setTeamSearch] = useState([]);
  const { groupId } = useParams();
  const { setLoading } = props;

  const history = useHistory();

  const fetchData = async () => {
    try {
      setLoading(true);
      const tempTeams = await getProfessionalsTeams({ id_federation: groupId });
      setTeams(tempTeams);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Columns
  const headCellsProfessional = [
    {
      width: "20%",
      id: "professionalName",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.teamList.groupName,
    },
    {
      width: "80%",
      id: "professionalEmail",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.teamList.groupProfessional,
    },
  ];

  const filterProf = (e) => {
    setTeamSearch(teams.filter(({ name }) => name.includes(e.target.value)));
  };

  const GroupAddProfessionalRow = ({ row, index }) => {
    const { name, professionals } = row;

    return (
      <>
        <SpTableRow role="checkbox" key={name} tabIndex={index}>
          <SpTableCell>
            <SpText id={`teamName${index}`} variant="tableTextDetails">
              {name}
            </SpText>
          </SpTableCell>
          <SpTableCell id={`teamContent${index}`}>
            {professionals?.map(({ name, surname }, index) => (
              <Chip
                variant="outlined"
                key={index}
                avatar={
                  <Avatar>{`${name} ${surname}`.match(/\b(\w)/g)}</Avatar>
                }
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                  margin: "8px",
                }}
                label={`${name} ${surname}`}
                size="medium"
              />
            ))}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>
          <Grid item xs={12} container spacing={1} direction="row">
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.groups.federationAdd.teamList.title}
              </SpText>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <StyledRow>
                  <SpSearch
                    onKeyDown={prevent}
                    onChange={(e) => filterProf(e)}
                    value={searchStringProf}
                  />
                </StyledRow>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SpTable
                style={{ width: "100%" }}
                headCells={headCellsProfessional}
                rows={teamsSearch.length > 0 ? teamsSearch : teams}
                rowKey="id"
              >
                <GroupAddProfessionalRow />
              </SpTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(FederationProfessionalsTeams);
