import React, { useEffect, useRef, useState } from "react";
import "../../../App.css";
import { Divider, Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getPatientRegistryAnswers } from "../../../models/actions/Patients";
import { useParams } from "react-router-dom";
import SpText from "../../../components/atoms/SpText";
import { labels, psTranslate } from "../../shared/translations";
import moment from "moment";
import SpButton from "../../../components/atoms/SpButton";
import ReactToPrint from "react-to-print";
import { theme } from "../../../components/theme";

const PatientRegistrySumup = (props) => {
  const [currentPatientRegistryAnswers, setCurrentPatientRegistryAnswers] =
    useState([]);
  const [patient, setPatient] = useState([]);
  const componentRef = useRef();
  const { setLoading } = props;
  const { patId } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getPatientRegistryAnswers({ id_patient: patId });
      setCurrentPatientRegistryAnswers(result?.answers);
      setPatient(result?.patient);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={props.toPrint ? "textPrint" : "text"}>
        {props.label}
      </SpText>
      <SpText
        variant={
          props.toPrint ? "h4ComponentLabelPrint" : "h4ComponentLabelRegular"
        }
      >
        {props.value}
      </SpText>
    </Grid>
  );

  const RenderPersonalData = (props) => (
    <Grid item xs={12}>
      <Grid item xs={12} direction="row" container>
        <Grid
          item
          xs={6}
          direction="column"
          container
          style={{ padding: "8px", alignSelf: "baseline" }}
        >
          {patient?.givenName && (
            <LabelValue
              label={labels.patient.patientRegistry.givenName.toUpperCase()}
              value={patient?.givenName}
              toPrint={props.toPrint}
            />
          )}
          {patient?.familyName && (
            <LabelValue
              label={labels.patient.patientRegistry.familyName.toUpperCase()}
              value={patient?.familyName}
              toPrint={props.toPrint}
            />
          )}
          {patient?.birth_date && (
            <LabelValue
              label={labels.patient.patientRegistry.birthDate.toUpperCase()}
              value={moment(patient?.birth_date).format("DD/MM/yyyy")}
              toPrint={props.toPrint}
            />
          )}
          {patient?.job && (
            <LabelValue
              label={labels.patient.patientRegistry.job.toUpperCase()}
              value={patient?.job}
              toPrint={props.toPrint}
            />
          )}
          {patient?.email && (
            <LabelValue
              label={labels.patient.patientRegistry.email.toUpperCase()}
              value={patient?.email}
              toPrint={props.toPrint}
            />
          )}
          {patient?.gender && (
            <LabelValue
              label={labels.patient.patientRegistry.sex.toUpperCase()}
              value={
                patient?.gender === "F"
                  ? labels.patient.patientRegistry.sexWoman.toUpperCase()
                  : labels.patient.patientRegistry.sexMan.toUpperCase()
              }
              toPrint={props.toPrint}
            />
          )}
        </Grid>

        <Grid
          xs={6}
          direction="column"
          container
          style={{ padding: "8px", alignSelf: "baseline" }}
        >
          {patient?.height && (
            <LabelValue
              label={labels.patient.patientRegistry.height.toUpperCase()}
              value={patient?.height}
              toPrint={props.toPrint}
            />
          )}
          {patient?.weight && (
            <LabelValue
              label={labels.patient.patientRegistry.weight.toUpperCase()}
              value={patient?.weight}
              toPrint={props.toPrint}
            />
          )}
          {patient?.BMI && (
            <LabelValue
              label={labels.patient.patientRegistry.bmi.toUpperCase()}
              value={parseFloat(patient?.BMI).toFixed(3)}
              toPrint={props.toPrint}
            />
          )}
          {patient?.family_doctor && (
            <LabelValue
              label={labels.patient.patientRegistry.doctor.toUpperCase()}
              value={patient?.family_doctor}
              toPrint={props.toPrint}
            />
          )}
          {patient?.address && (
            <LabelValue
              label={labels.patient.patientRegistry.address.toUpperCase()}
              value={patient?.address}
              toPrint={props.toPrint}
            />
          )}
          {patient?.phone && (
            <LabelValue
              label={labels.patient.patientRegistry.phone.toUpperCase()}
              value={patient?.phone}
              toPrint={props.toPrint}
            />
          )}
          {patient?.emergency_name && (
            <LabelValue
              label={labels.patient.patientRegistry.emergencyContactName.toUpperCase()}
              value={patient?.emergency_name}
              toPrint={props.toPrint}
            />
          )}
          {patient?.emergency_telephone && (
            <LabelValue
              label={labels.patient.patientRegistry.emergencyContactPhone.toUpperCase()}
              value={patient?.emergency_telephone}
              toPrint={props.toPrint}
            />
          )}
          {patient?.prevalent_hand && (
            <LabelValue
              label={labels.patient.patientRegistry.dominantHand.toUpperCase()}
              value={
                patient?.prevalent_hand === "R"
                  ? labels.patient.patientRegistry.right.toUpperCase()
                  : labels.patient.patientRegistry.left.toUpperCase()
              }
              toPrint={props.toPrint}
            />
          )}
          {patient?.prevalent_leg && (
            <LabelValue
              label={labels.patient.patientRegistry.dominantFoot.toUpperCase()}
              value={
                patient?.prevalent_leg === "R"
                  ? labels.patient.patientRegistry.right_m.toUpperCase()
                  : labels.patient.patientRegistry.left_m.toUpperCase()
              }
              toPrint={props.toPrint}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const CustomHeader = (props) => (
    <Grid item xs={12} container direction="row">
      <Grid item xs={12} style={{ marginTop: "1%" }}>
        <Divider
          style={{
            padding: "1px",
            backgroundColor: props.toPrint
              ? "#000"
              : theme.colors.primary.lightBlue,
          }}
        />
      </Grid>
      <Grid item xs={12} container direction="row" style={{ marginTop: "1%" }}>
        <SpText
          variant={props.toPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"}
        >
          {props.label}
        </SpText>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1%" }}>
        <Divider
          style={{
            padding: "1px",
            backgroundColor: props.toPrint
              ? "#000"
              : theme.colors.primary.lightBlue,
          }}
        />
      </Grid>
    </Grid>
  );

  const RenderRegistryAnswer = ({ toPrint }) => (
    <>
      {currentPatientRegistryAnswers?.map((item) => (
        <>
          <CustomHeader label={psTranslate(item.category)} toPrint={toPrint} />
          <Grid
            item
            xs={12}
            container
            direction="column"
            style={{ marginTop: "1%" }}
          >
            {item.questions?.map((question) => (
              <LabelValue
                label={psTranslate(question.question)}
                value={question.answers
                  ?.map((a) => psTranslate(a.answer))
                  ?.join(", ")}
                toPrint={toPrint}
              />
            ))}
          </Grid>
        </>
      ))}
    </>
  );

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <Grid container xs={12} direction="row" style={{ padding: "10px" }}>
          <CustomHeader
            toPrint={true}
            label={labels.patient.patientRegistry.registryData}
          />
          <RenderPersonalData toPrint={true} />
          <RenderRegistryAnswer toPrint={true} />
        </Grid>
      );
    }
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
          <ReactToPrint
            trigger={() => (
              <SpButton
                style={{ marginLeft: "2%" }}
                text={labels.patient.patientRegistry.printButton.toUpperCase()}
                buttonType="accept"
              />
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} />
          </div>
        </Grid>
        <CustomHeader label={labels.patient.patientRegistry.registryData} />
        <RenderPersonalData />
      </Grid>
      {currentPatientRegistryAnswers && (
        <Grid direction="column" container>
          <RenderRegistryAnswer
            currentPatientRegistryAnswers={currentPatientRegistryAnswers}
          />
        </Grid>
      )}
    </>
  );
};
export default withSnackbar(PatientRegistrySumup);
