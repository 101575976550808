import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "./ag-theme-alpine.css";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
} from "recharts";

import { CustomTooltip } from "./customization.jsx";

const MyAgGridComponent = ({ data }) => {
  const rowSelection = "single";
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [concatenatedData, setConcanetedData] = useState([]);
  const [redOffset, setRedOffset] = useState();
  const [redyellOffset, setRedyellOffset] = useState();
  const [yellowOffset, setYellowOffset] = useState();
  const [greenOffset, setGreenOffset] = useState();
  const [minACWRValue, setMinACWRValue] = useState();
  const [maxACWRValue, setMaxACWRValue] = useState();
  const [variables, setVariables] = useState();
  useEffect(() => {
    fetchInitial();
  }, [data]);

  const setWorkloadData = async () => {
    const concanetedDataTemp = data.slice(-28);
    // Concatenate data
    setConcanetedData(concanetedDataTemp);

    // Calculate the maximum value in the data
    const maxACWRValueTemp = Math.max(
      ...concanetedDataTemp.map((item) => item[`ACWR_${selectedRow}`])
    );
    setMaxACWRValue(maxACWRValueTemp);
    setMinACWRValue(
      Math.min(...concanetedDataTemp.map((item) => item[`ACWR_${selectedRow}`]))
    );

    // Set specific threshold values
    const redThreshold = 1.6;
    const redyellThreshold = 1.5;
    const yellowThreshold = 1.2;
    const greenThreshold = 0.6;

    // Determine the offsets based on threshold values
    setRedOffset(`${(1 - redThreshold / maxACWRValueTemp) * 100}%`);
    setRedyellOffset(`${(1 - redyellThreshold / maxACWRValueTemp) * 100}%`);
    setYellowOffset(`${(1 - yellowThreshold / maxACWRValueTemp) * 100}%`);
    setGreenOffset(`${(1 - greenThreshold / maxACWRValueTemp) * 100}%`);
  };

  useEffect(() => {
    setWorkloadData();
  }, [selectedRow]);

  const onGridReady = (params) => {
    if (variables) {
      const defaultSelectedVariable = variables[0];
      const defaultSelectedIndex = variables.indexOf(defaultSelectedVariable);

      if (defaultSelectedIndex !== -1) {
        params.api.forEachNode((node) => {
          if (node.data.variable === defaultSelectedVariable) {
            node.setSelected(true);
          }
        });
      }
    }
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setSelectedRow(selectedRows[0]["variable"]);
    }
  };

  const colorColumnBasedOnValues = (params) => {
    const columnId = params.colDef.field; // Get the column ID
    const value = params.value;

    // Customize color logic based on column ID and cell value
    switch (columnId) {
      case "ACWR":
        // Logic for ACWR column
        if (value >= 0 && value <= 0.8) {
          return {
            backgroundColor: "#383838",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 0.8 && value <= 1.3) {
          return {
            backgroundColor: "rgba(0, 255, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 1.3 && value <= 1.5) {
          return {
            backgroundColor: "rgba(255, 255, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 1.5) {
          return {
            backgroundColor: "rgba(255, 0, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        }
        break;

      case "Mono":
        // Logic for Monotony column
        if (value >= 0 && value <= 1) {
          return {
            backgroundColor: "#383838",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 1 && value <= 1.5) {
          return {
            backgroundColor: "rgba(0, 255, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 1.5 && value <= 2) {
          return {
            backgroundColor: "rgba(255, 255, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        } else if (value > 2) {
          return {
            backgroundColor: "rgba(255, 0, 0, 0.3)",
            color: "white",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };
        }
        break;

      default:
        // Default styles
        return {};
    }
  };

  const fetchInitial = () => {
    // Extract last values
    const value_last = data[data.length - 1];
    const data_last = [value_last];
    const variablesTemp = Object.keys(data_last[0]).filter(
      (key) =>
        key !== "Date" &&
        key !== "type" &&
        !key.includes("Monotony") &&
        !key.includes("SWC") &&
        !key.includes("ACWR")
    );
    setVariables(variablesTemp);
    const transposedArray = variablesTemp.map((variable) => {
      return {
        variable,
        value: value_last[variable],
        ACWR: value_last[`ACWR_${variable}`],
        Mono: value_last[`Monotony_${variable}`],
        SWC: value_last[`SWC_${variable}`],
      };
    });
    setRowData(transposedArray);
    // Define columns options
    setColDefs([
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: "",
        maxWidth: 40,
        pinned: "left",
      },
      {
        headerName: "Variable",
        field: "variable",
        flex: 1,
        minWidth: 150,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      {
        headerName: "Value",
        field: "value",
        flex: 1,
        cellStyle: {
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      {
        headerName: "ACWR",
        field: "ACWR",
        flex: 1,
        cellStyle: colorColumnBasedOnValues,
      },
      {
        headerName: "Monotony",
        field: "Mono",
        flex: 1,
        cellStyle: colorColumnBasedOnValues,
      }
    ]);
  };

  useEffect(() => {
    if (rowData && rowData[0]) setSelectedRow(rowData[0]["variable"]);
  }, [rowData]);

  const formatTicks = (value) => {
    return value.toFixed(2);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "350px",
        margin: "0",
        boxSizing: "border-box",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ flex: "0 0 30%", padding: "10px", boxSizing: "border-box" }}
      >
        <AgGridReact
          columnDefs={colDefs}
          rowData={rowData}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
        />
      </div>

      <div
        style={{ flex: "0 0 70%", padding: "10px", boxSizing: "border-box" }}
      >
        <ResponsiveContainer width="100%">
          <ComposedChart
            data={concatenatedData}
            width={800}
            height={400}
            margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
          >
            <defs>
              <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset={redOffset} stopColor="red" />
                <stop offset={redyellOffset} stopColor="#ff8800" />
                <stop offset={yellowOffset} stopColor="green" />
                <stop offset={greenOffset} stopColor="#51c26f" />
                <stop offset="100%" stopColor="white" />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="Date"
              tick={{ angle: -30, textAnchor: "end", dy: 5 }}
            />
            <YAxis
              label={{
                value: selectedRow,
                angle: -90,
                position: "Left",
                style: { fontSize: "1.2em" },
                stroke: 0,
                fill: "white"
              }}
              width={130}
            />
            <YAxis
              label={{
                value: "ACWR",
                angle: 90,
                position: "Right",
                style: { fontSize: "1.2em" },
                stroke: 0,
                fill: "white"
              }}
              width={120}
              domain={[Math.ceil(minACWRValue), Math.floor(maxACWRValue)]}
              yAxisId="right"
              orientation="right"
              tickFormatter={formatTicks}
            />
            <Tooltip content={<CustomTooltip />} />

            <CartesianGrid
              horizontal={true} // Disable grid lines on the X-axis
              vertical={false} // Enable grid lines on the Y-axis
              stroke="#f5f5f5"
              strokeOpacity={0.1}
            />

            <Bar dataKey={`${selectedRow}`} fill="#41657d" />

            <Line
              type="monotone"
              dataKey={`ACWR_${selectedRow}`}
              strokeWidth={5}
              dot={false}
              yAxisId="right"
              stroke="url(#lineGradient)"
              Date={`ACWR`}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MyAgGridComponent;
