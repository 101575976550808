import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import SpExerciseDialog from "../../../../components/atoms/SpExerciseDialog";
import SpIconButton from "../../../../components/atoms/SpIconButton";
import SpText from "../../../../components/atoms/SpText";
import { getAllExercises } from "../../../../models/actions/Excercise";
import { labels, psTranslate } from "../../../shared/translations";
import { theme } from "../../../../components/theme";

const LabelValue = ({ label, value, isPrint }) =>
  value ? (
    <Grid direction="column" style={{ marginBottom: "5px" }}>
      <SpText variant="text">{label}</SpText>
      <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"}>
        {value}
      </SpText>
    </Grid>
  ) : null;

const LabelValueExcerciseInfo = ({
  label,
  value,
  idExercise,
  setExercisesDetail,
  setDialogExerciseOpen,
  exercisesList,
  isPrint,
}) =>
  value ? (
    <Grid direction="column" style={{ marginBottom: "5px" }}>
      <SpText variant="text">{label}</SpText>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SpIconButton
          style={{ margin: "5px !important", marginTop: "0px !important" }}
          buttonType={"accept"}
          small
          onClick={(e) => {
            setExercisesDetail(
              exercisesList.find((ex) => ex.id === idExercise)
            );
            setDialogExerciseOpen(true);
          }}
        >
          <InfoIcon />
        </SpIconButton>
        <SpText
          variant={isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"}
        >
          {psTranslate(value)}
        </SpText>
      </div>
    </Grid>
  ) : null;

const ExerciseSheetDetails = ({ rows, isPrint, ...props }) => {
  const [exercisesList, setExercisesList] = useState([]);

  const [exerciseDetail, setExercisesDetail] = useState(null);
  const [dialogExerciseOpen, setDialogExerciseOpen] = useState(false);

  useEffect(async () => {
    try {
      const results = await getAllExercises();
      setExercisesList(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <Grid container xs={12}>
      <Divider
        style={{
          padding: "1px",
          width: "100%",
          backgroundColor: theme.colors.primary.lightBlue,
          marginTop: "1%",
          marginBottom: "1%",
        }}
      />
      {rows.map((item) => (
        <Grid
          key={item.id}
          container
          xs={4}
          style={{
            border: `1px solid ${theme.colors.primary.lightBlue}`,
            padding: "8px",
          }}
          direction="column"
        >
          <LabelValueExcerciseInfo
            isPrint={isPrint}
            idExercise={item.id_excercise}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.name}
            value={psTranslate(item.excercise?.name)}
            setExercisesDetail={setExercisesDetail}
            setDialogExerciseOpen={setDialogExerciseOpen}
            exercisesList={exercisesList}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.series}
            value={item.series}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.duration}
            value={item.repetition_time}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.recovery}
            value={item.resting}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.load}
            value={item.load}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.accessories}
            value={item.accessories}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.mode}
            value={item.execution}
          />
          <LabelValue
            isPrint={isPrint}
            label={labels.mylab.tempAdd.excerciseSheet.list.header.notes}
            value={item.note}
          />
        </Grid>
      ))}
      <SpExerciseDialog
        dialogExerciseOpen={dialogExerciseOpen}
        setDialogExerciseOpen={setDialogExerciseOpen}
        exerciseDetail={exerciseDetail}
      />
    </Grid>
  );
};

export default ExerciseSheetDetails;
