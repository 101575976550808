import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import { labels, psTranslate } from "../../shared/translations";
// material ui
import { Collapse, TableCell } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
// models
import {
  getRegionAreaStructureLists,
  getRiskFactorTemplateById,
} from "../../../models/actions/Activity";
import {
  deleteGenericRiskFactor,
  getGenericRiskFactorList,
} from "../../../models/actions/GenericRiskFactor";
// components
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { ComponentAddRF } from "./components/addComp/genericRiskFactorCompAdd";
import { ComponentToolbarSearchRF } from "./components/searchComp/genericRiskFactorCompSearch";
import { DialogDetails } from "./components/tableRow/genericRiskFactor_dialogDetails";
// atoms
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import SpText from "../../../components/atoms/SpText";
// utils
import {
  getSortedAndTranslatedArray,
  rollbar,
  titleCase,
} from "../../../utils/common";
// mocks

const StyledTableColumn = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const GenericRiskFactor = ({ ...props }) => {
  const history = useHistory();

  const [showAdd, setShowAdd] = useState(false);
  const [generalRiskList, setGeneralRiskList] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedRf, setSelectedRf] = useState();

  const { idElement } = useParams();
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      let res = await getGenericRiskFactorList();
      setGeneralRiskList(res);
      if (idElement) {
        const result = await getRiskFactorTemplateById({
          id_template: idElement,
        });
      } else {
        const result = await getRegionAreaStructureLists();
        if (result) {
          setRegions(getSortedAndTranslatedArray(result.regionList));
          setAreas(getSortedAndTranslatedArray(result.areaList));
          setStructures(getSortedAndTranslatedArray(result.structureList));
        }
      }
    } catch (error) {
      rollbar.error("AddRiskFactor - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteGenericRiskFactorFunction = async () => {
    setLoading(true);
    setDialogDelete(false);
    try {
      const resDelete = await deleteGenericRiskFactor({
        id: selectedRow.id,
      });
      if (resDelete.message) {
        props.snackbarShowMessage(resDelete.message);
      }
      // NOTE: ottimizzare e non riprendere da DB
      // riprendere il GRF da cancellare e semplicemente
      // risettare la lista (se l'eleiminazione dal DB
      // è andata a buon fine)
      const res = await getGenericRiskFactorList();
      setGeneralRiskList(res);
      setLoading(false);
    } catch (e) {
      props.snackbarShowErrorMessage("error while deleting risk factor");
    }
  };

  const accountHeadCells = [
    {
      id: "nome",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.generalRiskFactor.rfTable.headCell.name,
      isAction: false,
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.generalRiskFactor.rfTable.headCell.note,
      isAction: false,
    },
    {
      id: "structure",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.generalRiskFactor.rfTable.headCell.structure,
      isAction: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const GenericRiskRow = ({ row }) => {
    return (
      <SpTableRow tabIndex={row?.tableRowIndex} key={row?.id}>
        <SpTableCell>
          <SpText id={`textGRFName-${row.tableRowIndex}`} variant="tableText">
            {row?.name_rf}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{row?.note}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {titleCase(
              psTranslate(
                row?.id_structure_dysfunction
                  ? row?.structure_dysfunction?.structure?.name
                  : row?.structure?.name
              )
            )}
          </SpText>
        </SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          <SpButton
            variant={"standard"}
            type="tableList"
            text={labels.mylab.generalRiskFactor.rfTable.details}
            onClick={() => {
              setSelectedRow(row);
              setDialog(true);
            }}
          />
          <SpButton
            id={`editGenericRiskFactorBtn-${row.tableRowIndex}`}
            variant={"standard"}
            type="tableList"
            text={labels.mylab.generalRiskFactor.rfTable.edit}
            onClick={() => {
              setSelectedRf(row);
              setShowAdd(true);
            }}
          />
          <SpButton
            id={`deleteGenericRiskFactorBtn-${row.tableRowIndex}`}
            variant={"standardOpposite"}
            type="tableList"
            text={labels.mylab.generalRiskFactor.rfTable.delete}
            onClick={() => {
              setSelectedRow(row);
              setDialogDelete(true);
            }}
          />
        </TableCell>
      </SpTableRow>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={3}
      >
        <Grid item xs={12} container alignItems={"center"}>
          {/* BREADCRUMB navigator */}
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {`${labels.mylab.backButtonList} / `}
                <span style={{ color: theme.colors.primary.white }}>
                  {labels.mylab.tempList.generalRiskFactor}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {/* open dashboard button */}
          <Grid
            item
            xs={4}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              id="openAddRiskFactorBtn"
              style={{ marginLeft: "2%" }}
              buttonType="accept"
              text={labels.mylab.generalRiskFactor.toolbar.add}
              onClick={() => {
                setSelectedRf({});
                setShowAdd(!showAdd);
              }}
              variant="h1"
            />
          </Grid>
        </Grid>

        {/* RISK FACTOR PAGE */}

        <Grid container xs={12} style={{ paddingLeft: "1%" }}>
          {/* ADD SECTION */}

          <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
              <Collapse in={showAdd} collapsedSize={0}>
                <SpText variant="h1PageSubtitle">
                  {labels.mylab.generalRiskFactor.addComp.title}
                </SpText>
                <ComponentAddRF
                  selectedRf={selectedRf}
                  snackbarShowErrorMessage={props.snackbarShowErrorMessage}
                  snackbarShowMessage={props.snackbarShowMessage}
                  setLoading={setLoading}
                  listRegions={regions}
                  listAreas={areas}
                  listStructures={structures}
                  rfList={generalRiskList}
                  setRfList={setGeneralRiskList}
                  setGeneralRiskList={setGeneralRiskList}
                />
              </Collapse>
            </Grid>
          </Grid>

          {/* RISK FACTOR TABLE */}

          {/* search bar */}
          <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SpText variant="h1PageSubtitle" style={{ marginTop: "2%" }}>
                {labels.mylab.generalRiskFactor.searchComp.title}
              </SpText>
              <ComponentToolbarSearchRF />
            </Grid>
          </Grid>

          {/* table with rows */}
          <Grid container xs={12} alignItems={"center"}>
            <Grid item xs={12} style={{ paddingRight: "1.3%" }}>
              {generalRiskList.length > 0 && (
                <StyledTableColumn>
                  <SpTable
                    id={"genericRiskFactorTable"}
                    headCells={accountHeadCells}
                    rows={generalRiskList}
                    pagination={true}
                    padding={false}
                    notCheckable={false}
                    tableContainerMaxHeight={
                      isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                    }
                  >
                    <GenericRiskRow />
                  </SpTable>
                </StyledTableColumn>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* DIALOG DETAILS */}

        <DialogDetails
          selectedRow={selectedRow}
          dialog={dialog}
          setDialog={setDialog}
        />

        {/* DIALOG DELETE */}

        <SpDialog
          style={{ padding: "1%" }}
          open={dialogDelete}
          setOpen={setDialogDelete}
          title={labels.mylab.generalRiskFactor.dialogDelete.title}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.mylab.generalRiskFactor.dialogDelete.content}
              </SpText>
            </Grid>
            <Grid item container xs={12} alignItems={"flex-start"}>
              <Grid item xs={4}>
                <SpButton
                  id={"deleteButtonYes"}
                  text={labels.groups.groupDetail.deletePatientDialog.yes}
                  buttonType="accept"
                  onClick={deleteGenericRiskFactorFunction}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: "1%" }}>
                <SpButton
                  text={labels.groups.groupDetail.deletePatientDialog.no}
                  buttonType="accept"
                  onClick={() => setDialogDelete(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </SpDialog>
      </Grid>
    </>
  );
};
export default withSnackbar(GenericRiskFactor);
