import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Add as AddIcon, DeleteForever } from "@material-ui/icons";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import {
  deleteExitCriteria,
  getExitCriteriaByPresentation,
  updateExitCriteria,
} from "../../../models/actions/Presentation";
import { rollbar } from "../../../utils/common";
import { labels } from "../../shared/translations";
import { theme } from "../../../components/theme";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
});

const StyledColumn = styled("div")({
  flex: "1 1 100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "45%",
  flexDirection: "column",
});

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "3%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
  "&:disabled": {
    color: theme.colors.primary.lightBlue,
  },
});

const formControlStyleCheck = {
  minWidth: 60,
  paddingLeft: "2%",
  paddingRight: "2%",
  paddingTop: "2%",
  justifyContent: "flex-start",
  color: theme.colors.primary.lightBlue,
  "&:disabled": {
    color: theme.colors.primary.lightBlue,
  },
};

const PatientsPresentationDetailExitCriteria = (props) => {
  const { presentationId, patId } = useParams();
  const { setLoading } = props;

  const { control, register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "exitcriterias",
    keyName: "id",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const results = await getExitCriteriaByPresentation({
        id_presentation: presentationId,
        id_patient: patId,
      });
      const formattedResults = results.exit_criteria_answers.map(
        (exitCriteria) => {
          return {
            id: exitCriteria.id,
            name: exitCriteria.name,
            satisfied: exitCriteria.satisfied,
          };
        }
      );
      remove();
      append(formattedResults);
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailExitCriteria - fetchData",
        error
      );
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  const addExitCriteria = () => {
    try {
      append({ id: "", name: "", satisfied: "" });
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailExitCriteria - addExitCriteria",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const removeExitCriteria = async (exitCriteriaId, idx) => {
    try {
      const result = await deleteExitCriteria({
        id_exit_criteria: exitCriteriaId,
        id_patient: patId,
      });
      if (result.message) {
        remove(idx);
        props.snackbarShowMessage(result.message);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailExitCriteria - removeExitCriteria",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateExitCriteriaAnswer = async (data) => {
    try {
      const result = await updateExitCriteria({
        exitCriterias: data.exitcriterias,
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (result.message) {
        props.snackbarShowMessage(result.message);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailExitCriteria - updateExitCriteriaAnswer",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <StyledBodyColumn>
        <StyledRow>
          <StyledBodyHalfColumnToolbar>
            <SpText variant="h1PageTitle">
              {labels.patient.presentation.detail.stepper.step2.title.toUpperCase()}
            </SpText>
            {props?.endDate &&
              (fields.length > 0 ? (
                <Grid item xs={12}>
                  <SpText variant="h1PageSubtitle">
                    {
                      labels.patient.presentation.detail.stepper.step2
                        .closedPresentation
                    }
                  </SpText>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <SpText variant="h1PageSubtitle">
                    {labels.patient.presentation.detail.stepper.step2.noData}
                  </SpText>
                </Grid>
              ))}
          </StyledBodyHalfColumnToolbar>
        </StyledRow>
        {!props?.endDate && (
          <Grid item xs={4}>
            <SpButton
              style={{ width: "100%" }}
              variant="none"
              onClick={() => handleSubmit(updateExitCriteriaAnswer)()}
              buttonType="accept"
              text={labels.patient.presentation.add.save}
            />
          </Grid>
        )}
        <form>
          <StyledColumn>
            {fields.map((exitCriteria, obsIdx) => (
              <StyledGridContainer key={exitCriteria.id}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} direction="row">
                    <SpTextInput
                      label={
                        labels.patient.presentation.detail.stepper.step2
                          .inputLabel
                      }
                      name={`exitcriterias[${obsIdx}].name`}
                      formControlStyle={{ width: "100%" }}
                      defaultValue={exitCriteria.name}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={11} direction="row">
                    <Controller
                      as={
                        <RadioGroup
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="false"
                            disabled={props?.endDate}
                            control={<RedRadio />}
                            label={labels.patient.presentation.detail.stepper.step2.notSatisfied.toUpperCase()}
                          />
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="true"
                            disabled={props?.endDate}
                            control={<GreenRadio />}
                            label={labels.patient.presentation.detail.stepper.step2.satisfied.toUpperCase()}
                          />
                        </RadioGroup>
                      }
                      name={`exitcriterias[${obsIdx}].satisfied`}
                      control={control}
                      defaultValue={exitCriteria.satisfied}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {!props?.endDate && (
                      <SpIconButton
                        variant="lightBlueFill"
                        onClick={() =>
                          removeExitCriteria(exitCriteria.id, obsIdx)
                        }
                      >
                        <DeleteForever />
                      </SpIconButton>
                    )}
                  </Grid>
                </Grid>
              </StyledGridContainer>
            ))}
            {!props?.endDate && (
              <SpIconButton
                variant="lightBlueFill"
                onClick={() => addExitCriteria()}
              >
                <AddIcon />
              </SpIconButton>
            )}
          </StyledColumn>
        </form>
      </StyledBodyColumn>
    </>
  );
};
export default withSnackbar(PatientsPresentationDetailExitCriteria);
