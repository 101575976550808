import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid, Avatar } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import PatientsTransferPatient from "./PatientsTransferPatient";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpButton from "../../components/atoms/SpButton";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { makeStyles } from "@material-ui/core/styles";
import SpIconButton from "../../components/atoms/SpIconButton";
import { DeleteForever } from "@material-ui/icons";
import { theme } from "../../components/theme";
import { deletePatient } from "../../models/actions/Patients";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});

const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
  smallAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const PatientsSettingsInternalMenu = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [openDeletePatient, setOpenDeletePatient] = useState();
  const [spMenuLabels, setspMenuLabels] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();
  const classes = useStyles();

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  //Fetching patient data
  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const removePatient = async () => {
    try {
      const response = await deletePatient({ id_patient: parseInt(patId) });
      setOpenDeletePatient(false);
      if (response?.error) {
        props.snackbarShowErrorMessage(response?.error);
      } else {
        props.snackbarShowMessage(response?.message);
        history.push(`/patients`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {}, [currentPatient]);

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  useEffect(async () => {
    let tmpLabels = labels.patient.settingsInternalMenu.steps;

    setspMenuLabels(tmpLabels.map((t) => <StepTitle>{t}</StepTitle>));
  }, []);

  return (
    <div>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
              sectionName={labels.patient.settingsInternalMenu.title.toUpperCase()}
            />
          </Grid>

          <Grid item xs={12} container spacing={2} direction="row">
            <StyledGridContainer container spacing={1}>
              <StyledBodyColumn>
                <SpStepper
                  titles={spMenuLabels}
                  stepperControls={StepperControls(errors)}
                >
                  <PatientsTransferPatient
                    register={register}
                    control={control}
                    setLoading={setLoading}
                  />
                  <>
                    <SpTableRow
                      role="checkbox"
                      key={"professional?.id"}
                      tabIndex={"index"}
                    >
                      <SpTableCell>
                        <Avatar
                          imgProps={{
                            style: {
                              width: "100%",
                              mHeight: "100%",
                              maxWidth: "100%",
                            },
                          }}
                          className={classes.smallAvatar}
                        />
                      </SpTableCell>
                      <SpTableCell>
                        <SpText variant="tableTextDetails">
                          {currentPatient?.givenName}{" "}
                          {currentPatient?.familyName}
                        </SpText>
                      </SpTableCell>
                      <SpTableCell>
                        <SpText variant="tableTextDetails">
                          {`${currentPatient?.email}`}
                        </SpText>
                      </SpTableCell>

                      <SpTableCell align={"right"}>
                        <SpIconButton
                          id={"navDelete"}
                          textVariant={`sidebarItem`}
                          onClick={() => setOpenDeletePatient(true)}
                          text={labels.patient.sideBarPatEdit.deletePatient.toUpperCase()}
                        >
                          <DeleteForever />
                        </SpIconButton>
                      </SpTableCell>
                      <SpDialog
                        style={{ padding: "1%" }}
                        open={openDeletePatient}
                        setOpen={setOpenDeletePatient}
                        title={
                          labels.patient.sideBarPatEdit.deletePatientDialogTitle
                        }
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <SpText id={"dialogContent"} variant="text">
                              {
                                labels.patient.sideBarPatEdit
                                  .deletePatientDialogContent
                              }
                            </SpText>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            alignItems={"flex-start"}
                          >
                            <Grid item xs={4}>
                              <SpButton
                                id={"dialogButtonYes"}
                                text={
                                  labels.groups.groupDetail.deletePatientDialog
                                    .yes
                                }
                                buttonType="accept"
                                onClick={removePatient}
                              />
                            </Grid>
                            <Grid item xs={4} style={{ marginBottom: "1%" }}>
                              <SpButton
                                id={"dialogButtonNo"}
                                text={
                                  labels.groups.groupDetail.deletePatientDialog
                                    .no
                                }
                                buttonType="accept"
                                onClick={() => setOpenDeletePatient(false)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </SpDialog>
                    </SpTableRow>
                  </>
                </SpStepper>
              </StyledBodyColumn>
            </StyledGridContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withSnackbar(PatientsSettingsInternalMenu);
