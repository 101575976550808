import { Chip, Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { SpAutocomplete } from "../../../../components/atoms/SpAutocomplete";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { getCharts, getTimeViews } from "../../../../models/actions/Patients";
import { dateFormat, rollbar } from "../../../../utils/common";
import { labels, psTranslate } from "../../../shared/translations";
import { getMomentIdFromKey } from "../ReportHelperFns";
import { theme } from "../../../../components/theme";

const moment = extendMoment(Moment);

const RADAR_KEY = "RADAR";

const ReportGlobalFilters = ({
  dateRange,
  graphType,
  graphDateView,
  activities,
  currActivities,
  studyParameters,
  combinedParams,
  additionalStudySubjects,
  currStudyParameters,
  currAdditionalStudySubjects,
  onDateRangeChange,
  onActivitiesChange,
  onStudyParametersChange,
  onGraphTypeChange,
  onGraphDateViewChange,
  onAdditionalStudySubjectsChange,
  studiesMeasureUnits,

  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [graphTypes, setGraphTypes] = useState([]);
  const [dateViews, setDateViews] = useState([]);

  const changeDate = (newDateRange) => {
    //Snap to start/end of week/month
    const start = newDateRange.start.startOf(
      getMomentIdFromKey(graphDateView.key)
    );
    let end = newDateRange.end;
    //Let's use week as example to explain:
    end.add(-1, "hour"); //Move yesterday in case user selected a monday, else no harm
    end.endOf(getMomentIdFromKey(graphDateView.key)); //Reach end of week, which is now always sunday of the prev week at 23:59
    end.add(1, "hour"); //Move to monday, start of next element, now it's monday 00:59
    end = end.startOf("day"); //Fix hours to start of day, move back to 00:00

    onDateRangeChange(moment.range(start, end));
  };

  const doStudyParametersChange = (values) => {
    //If more than 3 measure units, force radar graph
    if (new Set(values.map((p) => p.um).concat(studiesMeasureUnits)).size >= 3)
      onGraphTypeChange(
        graphTypes.find((graphType) => graphType.key === RADAR_KEY)
      );
    onStudyParametersChange(values);
  };

  //Effects
  useEffect(async () => {
    //One time fetch to fill dropdowns
    try {
      const promises = [getCharts(), getTimeViews()];
      const [fetchedGraphTypes, fetchedDateViews] = await Promise.all(promises);

      setGraphTypes(fetchedGraphTypes);
      if (!graphType) onGraphTypeChange(fetchedGraphTypes[0]);

      setDateViews(fetchedDateViews);
      if (!graphDateView) onGraphDateViewChange(fetchedDateViews[0]);
    } catch (error) {
      rollbar.error("ReportGlobalFilters - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  //Default select all activities
  useEffect(() => onActivitiesChange(activities ?? []), [activities]);

  useEffect(() => {
    if (graphDateView && dateViews.length > 0) changeDate(dateRange);
  }, [graphDateView]);

  useEffect(() => {
    if (
      currStudyParameters.length === 0 ||
      currStudyParameters.find(
        (param) =>
          !studyParameters.find((currParam) => currParam.id === param.id)
      )
    )
      onStudyParametersChange(
        studyParameters.length > 0 ? [studyParameters[0]] : []
      );
  }, [studyParameters]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (
      !configDone &&
      config &&
      graphType &&
      graphDateView &&
      studyParameters.length > 0 &&
      additionalStudySubjects
    ) {
      if (config.additionalStudySubjects) {
        const newAdditionalStudySubjects = Object.entries(
          config.additionalStudySubjects
        ).reduce((accum, [label, valuesIds]) => {
          accum[label] = valuesIds
            .map((id) =>
              additionalStudySubjects[label]?.find((addSS) => addSS.id == id)
            )
            .filter((elem) => elem);
          return accum;
        }, {});
        onAdditionalStudySubjectsChange(newAdditionalStudySubjects);
      }
      if (config.dateRange) {
        const nowStart = moment()
          .startOf("day")
          .add(config.dateRange[0], "days");
        const nowEnd = moment().startOf("day").add(config.dateRange[1], "days");
        onDateRangeChange(moment.range(nowStart, nowEnd));
      }
      if (config.studyParameters) {
        const studies = config.studyParameters.reduce((accum, studyParamId) => {
          const study = studyParameters.find(
            (studyP) => studyP.id == studyParamId
          );
          if (study) accum.push(study);
          return accum;
        }, []);
        onStudyParametersChange(studies);
      }
      if (config.graphType) {
        const type = graphTypes.find((type) => type.id == config.graphType);
        onGraphTypeChange(type);
      }
      if (config.graphDateView) {
        const dateView = dateViews.find(
          (type) => type.id == config.graphDateView
        );
        onGraphDateViewChange(dateView);
      }
      setConfigDone(true);
    }
  }, [
    config,
    configDone,
    graphType,
    graphDateView,
    studyParameters,
    additionalStudySubjects,
  ]);
  useEffect(() => {
    if (requestSaveFlag) {
      const now = moment().startOf("day");
      //Save config
      setConfig({
        additionalStudySubjects: Object.entries(
          currAdditionalStudySubjects
        ).reduce((accum, [label, values]) => {
          accum[label] = values.map((value) => value.id);
          return accum;
        }, {}),
        dateRange: [
          dateRange.start.diff(now, "days"),
          dateRange.end.diff(now, "days"),
        ],
        studyParameters: currStudyParameters.map((param) => param.id),
        graphType: graphType.id,
        graphDateView: graphDateView.id,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  const constPadding = { padding: "5px" };

  return (
    <Grid container xs={12}>
      {/*Activities*/}
      {activities && (
        <Grid item xs={12} style={constPadding}>
          <SpAutocomplete
            label={
              labels.patient.graphReport.section.feedback.filters.activities
            }
            multiple
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={currActivities}
            onChange={(_, value) => onActivitiesChange(value)}
            options={activities}
            getOptionLabel={(elem) => elem.name ?? null}
            getOptionSelected={(option, value) => option.id === value?.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: "white",
                  }}
                  label={option.name}
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
      )}

      <Grid item container xs={6} direction="row">
        {/*Study Parameters*/}
        <Grid item xs={12} style={constPadding}>
          <SpAutocomplete
            label={
              labels.patient.graphReport.section.feedback.filters
                .study_parameters
            }
            multiple
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={currStudyParameters}
            onChange={(_, value) => doStudyParametersChange(value)}
            options={studyParameters}
            getOptionLabel={(elem) => psTranslate(elem.name) ?? null}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionDisabled={(option) =>
              !(
                combinedParams == null ||
                combinedParams.includes(option.id) ||
                currStudyParameters.length === 0
              )
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: "white",
                  }}
                  label={psTranslate(option.name)}
                  size="medium"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>

        {/*Dates*/}
        <Grid item xs={6} style={constPadding}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.start_date
            }
            value={dateRange.start.format(dateFormat)}
            style={{ width: "100%" }}
            type={"date"}
            disableKeyboardInput={true}
            onChange={(evnt) =>
              moment(evnt.target.value).isBefore(dateRange.end) &&
              changeDate(moment.range(evnt.target.value, dateRange.end))
            }
          />
        </Grid>

        <Grid item xs={6} style={constPadding}>
          <SpTextInput
            label={labels.patient.graphReport.section.feedback.filters.end_date}
            value={dateRange.end.format(dateFormat)}
            style={{ width: "100%" }}
            type={"date"}
            disableKeyboardInput={true}
            onChange={(evnt) =>
              dateRange.start.isBefore(moment(evnt.target.value)) &&
              changeDate(moment.range(dateRange.start, evnt.target.value))
            }
          />
        </Grid>

        {/*Date view type*/}
        <Grid item xs={6} style={constPadding}>
          <SpSelect
            label={
              labels.patient.graphReport.section.feedback.filters.date_view
            }
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={graphDateView}
            onChange={(evnt) => onGraphDateViewChange(evnt.target.value)}
          >
            {dateViews.map((dateView) => (
              <SpSelectMenuItem key={dateView.key} value={dateView}>
                {
                  labels.patient.graphReport.section.feedback.filters[
                    dateView.name.trim().toLowerCase()
                  ]
                }
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>

        {/*Graph types*/}
        <Grid item xs={6} style={constPadding}>
          <SpSelect
            label={
              labels.patient.graphReport.section.feedback.filters.graph_types
            }
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={graphType}
            onChange={(evnt) => onGraphTypeChange(evnt.target.value)}
          >
            {graphTypes.map((graphType) => (
              <SpSelectMenuItem
                key={graphType.key}
                value={graphType}
                disabled={
                  (graphType.key !== RADAR_KEY &&
                    new Set(currStudyParameters.map((p) => p.um)).size >= 3) ||
                  (graphType.key === RADAR_KEY &&
                    currStudyParameters.length < 2)
                }
              >
                {
                  labels.patient.graphReport.section.feedback.filters[
                    graphType.name.trim().toLowerCase()
                  ]
                }
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
      </Grid>

      <Grid item container xs={6} direction="row" alignItems={"center"}>
        {/*Additional subjects to study*/}
        {Object.entries(additionalStudySubjects ?? {}).map(
          ([label, values]) => (
            <Grid item xs={12} style={constPadding} key={label}>
              <SpAutocomplete
                label={label}
                multiple
                formControlWidth={"100%"}
                labelPaddingTop={"0"}
                value={currAdditionalStudySubjects[label] ?? []}
                onChange={(_, value) =>
                  onAdditionalStudySubjectsChange({
                    ...currAdditionalStudySubjects,
                    [label]: value,
                  })
                }
                options={values}
                groupBy={
                  values && values[0]?.groupByKey != null
                    ? (option) => option.groupByKey
                    : null
                }
                getOptionLabel={(elem) => elem.name ?? null}
                getOptionSelected={(option, value) => option.id === value?.id}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      style={{
                        backgroundColor: theme.colors.primary.lightBlue,
                        color: "white",
                      }}
                      label={option.name}
                      size="medium"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(ReportGlobalFilters);
