import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels } from "../../shared/translations";

const OtherInputDetails = ({
  templateData,
  setValue,
  register,
  disabled = false,
  isPrint = false,
}) => {
  useEffect(() => {
    if (templateData) {
      setValue("appointment_motivation", templateData.appointment_motivation);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <SpTextInput
        name="appointment_motivation"
        inputRef={register}
        formControlStyle={{ width: "100%" }}
        label={
          labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
        }
        disabled={disabled}
        isPrint={isPrint}
      />
    </Grid>
  );
};

export default OtherInputDetails;
