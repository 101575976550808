import { Chip, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { theme } from "../../../components/theme";
import { getActivityFeedbackParameters } from "../../../models/actions/Activity";
import { getActivityFeedbacksInRange } from "../../../models/actions/CalendarActivity";
import { getSecondTableGroupsData } from "../../../models/actions/Groups";
import { dateFormat, rollbar } from "../../../utils/common";
import {
  addProxyParameters,
  fixForProxyParams,
  fixForProxyParamsResultsSingle,
  fixProxyParamsListAvailable,
} from "../../patients/patientsReports/FeedbackDataHelper";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsFeedbacks = ({
  dateRange,
  currActivities,
  setData,

  dateLoaded,
  loadConfiguration,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [studyParameters, setStudyParameters] = useState(null);
  const [activeStudyParameters, setActiveStudyParameters] = useState([]);
  const [currStudyParameters, setCurrStudyParameters] = useState([]);

  const { patId, groupId } = useParams();

  useEffect(async () => {
    if (dateLoaded) {
      try {
        //Fetch data
        const studyParams = await getActivityFeedbackParameters();
        addProxyParameters(studyParams);
        setStudyParameters(studyParams);
      } catch (error) {
        rollbar.error("StatisticsFeedbacks - fetchDataInitial", error);
        props.snackbarShowErrorMessage(error);
      }
    }
  }, [dateLoaded]);

  useEffect(async () => {
    if (studyParameters) {
      //Fetch active study params
      const feedbackIds = await getActivityFeedbacksInRange({
        id_group: groupId,
        ids_patient: [patId],
        id_activity_types: currActivities,
        startDate: dateRange.start.format(dateFormat),
        endDate: dateRange.end.format(dateFormat),
      });
      fixProxyParamsListAvailable(feedbackIds);
      setActiveStudyParameters(
        feedbackIds.sort((idA, idB) =>
          studyParameters
            .find((s) => s.id === idA)
            .name.localeCompare(studyParameters.find((s) => s.id === idB).name)
        )
      );
      const newCurrStudyParams = currStudyParameters.filter((param) =>
        feedbackIds.includes(param)
      );
      if (newCurrStudyParams !== currStudyParameters)
        setCurrStudyParameters(newCurrStudyParams);
    }
  }, [studyParameters, currActivities, dateRange]);

  useEffect(async () => {
    if (studyParameters) {
      //Fetch activity data
      const secondTableData = await getSecondTableGroupsData({
        id_group: groupId,
        id_patients: patId ? [patId] : null,
        activitiesSelected: currActivities,
        feedbacksSelected: fixForProxyParams(currStudyParameters),
        filterActStartDate: dateRange.start.format(dateFormat),
        filterActEndDate: dateRange.end.format(dateFormat),
      });

      let newData = secondTableData.reduce((accum, patientData) => {
        const patientKey = `${patientData.patient.patientData.givenName} ${patientData.patient.patientData.familyName}`;
        const feedbackData = patientData.feedbacksData?.reduce(
          (accum1, feedback) => {
            //Compute feedback sum
            if (feedback.answer) {
              if (!accum1[`${feedback.name}`]) accum1[`${feedback.name}`] = {};
              accum1[`${feedback.name}`][feedback.date] = parseFloat(
                feedback.answer
              );
            }
            return accum1;
          },
          {}
        );
        accum[patientKey] = feedbackData ?? {};
        return accum;
      }, {});
      //Fix for special feedbacks
      newData = fixForProxyParamsResultsSingle(
        newData,
        currStudyParameters,
        studyParameters,
        false //use name
      );
      //Convert days into plain array (sorted)
      newData = Object.entries(newData).reduce((accum, [patKey, patData]) => {
        accum[patKey] = Object.entries(patData).reduce(
          (accum, [feedKey, feedData]) => {
            const translatedKey = psTranslate(feedKey);
            accum[translatedKey] = Object.entries(feedData)
              .sort(([k1], [k2]) => moment(k1) - moment(k2))
              .map(([_, val]) => val);
            return accum;
          },
          {}
        );
        return accum;
      }, {});

      setData(newData);
    }
  }, [studyParameters, currStudyParameters, dateRange]);

  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currStudyParameters)
        setCurrStudyParameters(loadConfiguration.currStudyParameters);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currStudyParameters: currStudyParameters,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={10}>
        <SpAutocomplete
          label={labels.groups.groupReports.secondTable.chooseParameter}
          multiple
          formControlWidth={"100%"}
          value={currStudyParameters}
          onChange={(_, value) => setCurrStudyParameters(value)}
          options={activeStudyParameters}
          getOptionLabel={(paramId) =>
            psTranslate(
              studyParameters?.find((param) => param.id === paramId)?.name
            ) ?? null
          }
          getOptionSelected={(option, value) => option === value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                }}
                label={psTranslate(
                  studyParameters?.find((param) => param.id === option)?.name
                )}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: "auto", marginBotton: "0px" }}>
        <SpButton
          buttonType={"accept"}
          variant="h4ComponentLabel"
          onClick={() => {
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? setCurrStudyParameters([])
              : setCurrStudyParameters(activeStudyParameters);
          }}
          text={
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? labels.groups.plan.deselectAll
              : labels.groups.plan.selectAll
          }
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(StatisticsFeedbacks);
