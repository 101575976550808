import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import { Grid } from "@material-ui/core";
import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";
import { rollbar } from "../../../utils/common.js";
import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientInjury,
  getPatientsSelection,
  getRange,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import {
  getAutocomplete,
  injuryLocalizationGroups,
  selectedGroupsInjury,
  selectedGroupsSevereInjuryRate,
  selectPatientsInjury,
  selectPatientsSevereInjuryRate,
} from "./patientsReportInjury/SevereInjuryFunction";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { theme } from "../../../components/theme.js";

const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";

const PatientsReportsStep8SevereInjury = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barCharPattern, setBarCharPattern] = useState([]);
  const [barCharRatio, setBarCharRatio] = useState([]);
  const [injuryGroup, setInjuryGroup] = useState([]);
  const [dataChartPattern, setDataChartPattern] = useState({});
  const [dataChartRatio, setDataChartRatio] = useState({});
  const [allRegions, setAllRegions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAreasAvailable, setAllAreasAvailable] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState([]);
  const [fullSizePattern, setFullSizePattern] = useState(false);
  const [fullSizeRatio, setFullSizeRatio] = useState(false);
  const [loading, setLoading] = useState(false);

  const { patId, groupId } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      const allRegionTemp = await getRegionAreaStructureLists({});
      setAllRegions(allRegionTemp?.regionList);
      setAllAreas(allRegionTemp?.areaList);
      setAllAreasAvailable(allRegionTemp?.areaList);

      if (patId) {
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);

        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        setPatients(result?.allPatients);
        
        let tempInjuryGroup = await getPatientInjury(
          tempGroupPatient,
          dateRange
        );
        
        for (let g = 0; g<tempGroupPatient.length; g++) {
          let groupP = tempGroupPatient[g]
          let groupInj = tempInjuryGroup.find(({ group }) => parseInt(group.id) === parseInt(groupP.id) )

          for (let p = 0; p < groupP?.patients.length; p++) {
            let pat = groupP?.patients[p];

            let patientId = parseInt(pat?.id);
            if (!isNaN(patientId)) {
                let inj_lst = groupInj?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
                if (inj_lst !== undefined) {
                  tempGroupPatient[g].patients[p].injuries = inj_lst;
                } 
            }
          }
        }

        setGroups(tempGroupPatient);
        setInjuryGroup(tempInjuryGroup);

        // calcolo del paziente attuale in base al suo id
        let tempPat = [
          result?.allPatients.find(
            ({ id }) => parseInt(id) === parseInt(patId)
          ),
        ];
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction(tempPat, "group", tempGroupPatient);
      } else {
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );
        let tempInjuryGroup = await getPatientInjury(tempGroups, dateRange);
        setInjuryGroup(tempInjuryGroup);

        let tempInjuryGroupI = []
        for (let g of tempInjuryGroup) {
          if ( parseInt(g?.group?.id) === parseInt(groupId) ) {
            tempInjuryGroupI.push(g)
          }
        }

        for (let p = 0; p < groupsSelected?.patients.length; p++) {
          let pat = groupsSelected?.patients[p];

          let patientId = parseInt(pat?.id);
          if (!isNaN(patientId)) {
              let inj_lst = tempInjuryGroupI[0]?.patient.find(({ patients }) => patients.id === patientId).patients.injuries;
              if (inj_lst !== undefined) {
                groupsSelected.patients[p].injuries = inj_lst;
              } 
          }
        }

        setInjuryGroup([groupsSelected]);
        setSelectedGroups([groupsSelected]);

        setInjuryGroup(tempInjuryGroup);
        selectionActionFunction([], "group", [groupsSelected]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      rollbar.error("PatientsReportsSpet8SevereInjury - useEffect", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const actionAfterGroupSelection = (tempGroups) => {
    selectionActionFunction([], "group", tempGroups);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectionActionFunction = async (
    patients,
    type,
    newSelectedGroups = selectedGroups
  ) => {
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );

    let resultInjuryGroupsPatter = selectedGroupsInjury(
      newSelectedGroups,
      regionSelected,
      areaSelected
    );
    let resultPattern = selectPatientsInjury(
      newPatientsSelected,
      regionSelected,
      areaSelected
    );

    let resultInjuryGroupsRatio = await selectedGroupsSevereInjuryRate(
      newSelectedGroups,
      dateRange
    );
    let resultRatio = await selectPatientsSevereInjuryRate(
      newPatientsSelected,
      newSelectedGroups,
      dateRange
    );

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    resultPattern.dataChart =
      resultInjuryGroupsPatter.dataChart !== undefined
        ? resultPattern.dataChart.concat(resultInjuryGroupsPatter.dataChart)
        : resultPattern.dataChart;

    resultRatio.dataChart =
      resultInjuryGroupsRatio.dataChart !== undefined
        ? resultRatio.dataChart.concat(resultInjuryGroupsRatio.dataChart)
        : resultRatio.dataChart;

    setLoading(true);
    setSelectedPatients(newPatientsSelected);

    setDataChartPattern(resultPattern.dataChart);
    setBarCharPattern(resultPattern?.barChart);

    setDataChartRatio(resultRatio.dataChart);
    setBarCharRatio(resultRatio?.barChart);
    setLoading(false);
  };

  /**
   * Funzione che mostra tutti gli infortuni divisi per gruppo
   *
   * @param tempInjuryGroup: lista di gruppi
   * @param regions: regioni selezionate
   * @param areas: aree selezionate
   *
   */
  const setAllInjuryGroup = (tempInjuryGroup, regions, areas) => {
    let result = injuryLocalizationGroups(tempInjuryGroup, regions, areas);

    setDataChartPattern(result?.dataChart);
    setBarCharPattern(result?.barChart);
  };

  /**
   * Funzione viene richiamata quando cambia la regione selezionata
   * @param newRegionSelected: nuova regione selezionata
   *
   */
  const selectRegionFunction = (newRegionSelected) => {
    // pulisco i gruppi selezionati
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, newRegionSelected, areaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        newRegionSelected,
        areaSelected
      );

      let result = selectPatientsInjury(
        selectedPatients,
        newRegionSelected,
        areaSelected
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result?.dataChart;
      setDataChartPattern(result?.dataChart);
      setBarCharPattern(result?.barChart);
    }

    setRegionSelected(newRegionSelected);
    let tempArea = [];
    newRegionSelected.forEach((region) => {
      const tempAreas = allAreas.filter(
        (area) => parseInt(area?.id_region) === parseInt(region?.id)
      );
      tempArea = tempArea.concat(tempAreas);
    });
    setAllAreasAvailable(tempArea);
  };

  /**
   * Funzione viene richiamata quando cambia l'area selezionata
   * @param newAreaSelected: nuova area selezionata
   *
   */
  const selectAreaFuncion = (newAreaSelected) => {
    setAreaSelected(newAreaSelected);
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, newAreaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        regionSelected,
        newAreaSelected
      );
      let result = selectPatientsInjury(
        selectedPatients,
        regionSelected,
        newAreaSelected
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result?.dataChart;
      setDataChartPattern(result?.dataChart);
      setBarCharPattern(result?.barChart);
    }
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      setDateRange(newDateRange);
      if (patId) {
        let tempInjuryGroup = await getPatientInjury(groups, newDateRange);
        setInjuryGroup(tempInjuryGroup);
        // calcolo del paziente attuale in base al suo id
        let tempPat = [
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
        ];
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction(tempPat, "group", groups);
      } else {
        let tempInjuryGroup = await getPatientInjury(
          selectedGroups,
          newDateRange
        );
        setInjuryGroup(tempInjuryGroup);
        // inizializzo il grafico con le statistiche del paziente
        selectionActionFunction([], "group", selectedGroups);
      }
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props?.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props?.componentName,
        })
      )}

      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      <Grid item container xs={8}>
        {/* selezione dei pazienti  */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}

        {/* selezione dei gruppi  */}
        {groupId &&
          getGroupsSelection(groups, selectedGroups, actionAfterGroupSelection)}
      </Grid>
      {/* autocomplete per selezionare la regione */}
      {getAutocomplete(
        labels.analytics.injuryReport.selectRegion,
        regionSelected,
        allRegions,
        selectRegionFunction
      )}

      {/* autocomplete per selezionare l'area */}
      {regionSelected.length > 0 &&
        getAutocomplete(
          labels.analytics.injuryReport.selectArea,
          areaSelected,
          allAreasAvailable,
          selectAreaFuncion
        )}
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={fullSizePattern ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .patternSevereInjury
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizePattern(!fullSizePattern);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizePattern ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport
            dataChart={dataChartPattern}
            barChar={barCharPattern}
          />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeRatio ? 12 : 6}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .ratioSevereInjury
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeRatio(!fullSizeRatio);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeRatio ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartRatio} barChar={barCharRatio} />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.selectGraph}
            </SpText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep8SevereInjury);
