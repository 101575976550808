import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getAssessmentMonitList,
  getAssessmentPresentationList,
} from "../../../models/actions/Assessment";
import { useQueryParams } from "../../../utils/common";
import AssessmentAdd from "./assessmentStep/AssessmentAdd";
import AssessmentDetail from "./assessmentStep/AssessmentDetail";
import AssessmentRepeat from "./assessmentStep/AssessmentRepeat";
import AssessmentTable from "./assessmentStep/AssessmentTable";

const STATUS_TABLE = "ASSESSMENT_TABLE";
const STATUS_DETAIL = "ASSESSMENT_DETAIL";
const STATUS_ADD = "ASSESSMENT_ADD";
const STATUS_REPEAT = "ASSESSMENT_REPEAT";

const PatientsMonitoringStep2 = ({ setLoading, ...props }) => {
  const [assessmentStatus, setAssessmentStatus] = useState(STATUS_TABLE);
  const [assessmentDetail, setAssessmentDetail] = useState();
  const [idTemplatePassedFromPlan, setIdTemplatePassedFromPlan] = useState(0);

  const { presentationId, patId } = useParams();
  const history = useHistory();

  const query = useQueryParams();
  const idTemplate = query.has("idTemplate")
    ? parseInt(query.get("idTemplate"))
    : null;
  const viewOrRepeat = parseInt(query.get("viewOrRepeat"));
  const date = query.get("date");

  useEffect(async () => {
    //Allow direct opening inside view/repeat sections
    if (idTemplate) {
      const assessments = await (presentationId
        ? getAssessmentPresentationList({
            id_patient: patId,
            id_presentation: presentationId,
          })
        : getAssessmentMonitList({ id_patient: patId }));
      const detail = assessments.find((a) => a.id_template === idTemplate);
      if (detail) {
        setAssessmentDetail(detail);

        if (viewOrRepeat) setAssessmentStatus(STATUS_DETAIL);
        else setAssessmentStatus(STATUS_REPEAT);
      } else if (!viewOrRepeat) {
        setAssessmentStatus(STATUS_ADD);
      }
    } else if (idTemplate === 0 && !viewOrRepeat) {
      setAssessmentStatus(STATUS_ADD);
    }
  }, [idTemplate, viewOrRepeat]);

  useEffect(() => {
    const idTemplateFromLocation = location.search
      ?.split("&")
      ?.find((params) => params.includes("idTemplate"))
      ?.split("=")[1];
    // if idTemplateFromLocation > 0 idTemplate is passed from activity plan
    if (idTemplateFromLocation && idTemplateFromLocation > 0) {
      setAssessmentStatus(STATUS_ADD);
      setIdTemplatePassedFromPlan(idTemplateFromLocation);
    }
  }, []);

  const close = () => {
    setAssessmentStatus(STATUS_TABLE);
    if (query.has("idTemplate")) {
      query.delete("idTemplate");
      query.delete("viewOrRepeat");
      query.delete("date");
      history.replace({
        search: query.toString(),
      });
    }
  };
  switch (assessmentStatus) {
    case STATUS_DETAIL:
      return (
        <AssessmentDetail
          assessmentDetail={assessmentDetail}
          close={close}
          setLoading={setLoading}
        />
      );
    case STATUS_ADD:
      return (
        <AssessmentAdd
          close={close}
          setLoading={setLoading}
          date={date}
          idTemplate={idTemplatePassedFromPlan}
        />
      );
    case STATUS_REPEAT:
      return (
        <AssessmentRepeat
          assessmentDetail={assessmentDetail}
          close={close}
          setLoading={setLoading}
          date={date}
        />
      );
    case STATUS_TABLE:
    default:
      return (
        <AssessmentTable
          openAssessmentDetail={(assessment) => {
            setAssessmentDetail(assessment);
            setAssessmentStatus(STATUS_DETAIL);
          }}
          addAssessment={() => {
            setAssessmentStatus(STATUS_ADD);
          }}
          repeatAssessment={(assessment) => {
            setAssessmentDetail(assessment);
            setAssessmentStatus(STATUS_REPEAT);
          }}
          setLoading={setLoading}
        />
      );
  }
};

export default withSnackbar(PatientsMonitoringStep2);
