import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import "../App.css";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { styled } from "../components/styled";
import { labels, psTranslate } from "./shared/translations";
import {
  getProfessionalById,
  updateProfessional,
} from "../models/actions/Professionals";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import AccountButton from "../components/bundles/account/AccountButton";
import {
  getUserPermission,
  isBusiness,
  isFeatureFlagEnabled,
  log,
} from "../utils/common";
import SpTextInput from "../components/atoms/SpTextInput";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";

const Account = (props) => {
  const stripePromise = loadStripe(process.env.STRIPE);
  const { control, register, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });

  const [enableAuth0, setEnableAuth0] = useState(false);
  const [professionalData, setProfessionalData] = useState();
  const [paymentMethod, setPaymentMetod] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      const response = await getProfessionalById();
      setProfessionalData(response);
      setUpdateProfessional(response);

      const paymentPermission = await getUserPermission(
        "update:payment-method",
        getAccessTokenSilently
      );

      setPaymentMetod(paymentPermission);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const setUpdateProfessional = (professionalDataUpdated) => {
    setValue("address", professionalDataUpdated.address);
    setValue("birth_date", professionalDataUpdated.birth_date);
    setValue("cap", professionalDataUpdated.cap);
    setValue("city", professionalDataUpdated.city);
    setValue("jobs", professionalDataUpdated.jobs);
    setValue("name", professionalDataUpdated.name);
    setValue("piva", professionalDataUpdated.piva);
    setValue("province", professionalDataUpdated.province);
    setValue("surname", professionalDataUpdated.surname);
    setValue("telephone", professionalDataUpdated.telephone);
    setValue("birth_area", professionalDataUpdated.birth_area);
    setValue("passport_area", professionalDataUpdated.passport_area);
    setValue("fiscal_code", professionalDataUpdated.fiscal_code);
    setValue("ragione_sociale", professionalDataUpdated.ragione_sociale);
  };

  useEffect(async () => {
    const auth0Enabled = await isFeatureFlagEnabled("auth0");
    setEnableAuth0(auth0Enabled);
    //Fetching data from the server
    await fetchData();
  }, []);

  const updateProfessionalFunction = async (data) => {
    if (data.name.length > 0 && data.surname.length > 0) {
      setUpdateProfessional(data);
      setProfessionalData(data);
      const res = await updateProfessional(data);
      if (res.message) {
        props.snackbarShowMessage(res.message);
      } else {
        props.snackbarShowErrorMessage(res.error);
      }
    } else {
      props.snackbarShowErrorMessage("Please insert name and surname");
    }
  };

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>
        {!props.value || props.value.toString().trim() === ""
          ? "---"
          : props.value}
      </SpText>
    </Grid>
  );

  const constPadding = { padding: "8px" };

  const PersonalInfo = () => (
    <form onSubmit={handleSubmit(updateProfessionalFunction)}>
      <Grid
        item
        container
        xs={12}
        direction="row"
        style={{ paddingLeft: "10em", paddingRight: "10em", paddingTop: "2em" }}
      >
        <Grid item xs={4} style={constPadding}>
          <Controller
            control={control}
            name={"name"}
            id="name"
            render={(props) => (
              <>
                <SpTextInput
                  inputRef={register}
                  style={{ width: "100%" }}
                  label={psTranslate(
                    labels.account.accountForm.givenName.toUpperCase()
                  )}
                  value={props.value}
                  onChange={(event) => {
                    props.onChange(event.target.value);
                  }}
                />
                {props.value === "" && (
                  <span style={{ color: "red" }}>
                    {labels.mylab.tempDetail.riskFactor.title}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <Controller
            control={control}
            name={"surname"}
            id="surname"
            render={(props) => (
              <>
                <SpTextInput
                  inputRef={register}
                  style={{ width: "100%" }}
                  onChange={(event) => {
                    props.onChange(event.target.value);
                  }}
                  value={props.value}
                  label={psTranslate(
                    labels.account.accountForm.familyName.toUpperCase()
                  )}
                />
                {props.value === "" && (
                  <span style={{ color: "red" }}>
                    {labels.mylab.tempDetail.riskFactor.title}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <Controller
            control={control}
            name={"birth_date"}
            id="birth_date"
            render={(props) => (
              <SpTextInput
                inputRef={register}
                label={psTranslate(
                  labels.account.accountForm.birthDate.toUpperCase()
                )}
                onChange={(event) => {
                  props.onChange(event.target.value);
                }}
                value={moment(props.value).format("yyyy-MM-DD")}
                style={{ width: "100%" }}
                type={"date"}
                disableKeyboardInput={true}
              />
            )}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            disabled
            name="email"
            id="email"
            value={professionalData?.email}
            style={{ width: "100%" }}
            label={psTranslate(labels.account.accountForm.email.toUpperCase())}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="fiscal_code"
            id="fiscal_code"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.fiscalCode.toUpperCase()}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="birth_area"
            id="birth_area"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.birth_area.toUpperCase()}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="passport_area"
            id="passport_area"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.passport_area.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="piva"
            id="piva"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.piva.toUpperCase()}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="telephone"
            id="telephone"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.telephone.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="address"
            id="address"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.address.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="city"
            id="city"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.city.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="cap"
            id="cap"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.cap.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="province"
            id="province"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.province.toUpperCase()}
          />
        </Grid>

        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="jobs"
            id="jobs"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.jobs.toUpperCase()}
          />
        </Grid>
        <Grid item xs={4} style={constPadding}>
          <SpTextInput
            name="ragione_sociale"
            id="ragione_sociale"
            inputRef={register}
            style={{ width: "100%" }}
            label={labels.account.accountForm.business_name.toUpperCase()}
          />
        </Grid>
        <Grid item xs={12} style={constPadding}>
          <SpButton
            buttonType={"accept"}
            variant="none"
            text={labels.general.update}
            style={{ marginTop: "4%" }}
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );

  const SectionTitle = ({ title }) => (
    <Grid item container xs={12}>
      <SpText style={{ flex: 1 }} variant="h1">
        {title}
      </SpText>
    </Grid>
  );

  const LicenseInfo = () => (
    <Grid
      item
      container
      xs={12}
      direction="row"
      style={{ paddingLeft: "10em", paddingRight: "10em", paddingTop: "2em" }}
    >
      <Grid item xs={4} style={constPadding}>
        <LabelValue
          label={labels.account.licenseForm.activationDate.toUpperCase()}
          value={
            professionalData?.activation_date
              ? moment(professionalData?.activation_date).format("DD/MM/YYYY")
              : moment(professionalData?.createdAt).format("DD/MM/YYYY")
          }
        />
      </Grid>
      <Grid item xs={4} style={constPadding}>
        <LabelValue
          label={labels.account.licenseForm.expiryDate.toUpperCase()}
          value={
            professionalData?.expiry_date
              ? moment(professionalData?.expiry_date).format("DD/MM/YYYY")
              : null
          }
        />
      </Grid>
      <Grid item xs={4} style={constPadding}>
        <LabelValue
          label={labels.account.licenseForm.subscription_type.toUpperCase()}
          value={professionalData?.subscription_type}
        />
      </Grid>

      <Grid item xs={12}>
        <AccountButton professionalData={professionalData} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={6}
      >
        <SectionTitle title={labels.account.title} />
        <PersonalInfo />

        {paymentMethod && (
          <>
            <SectionTitle title={labels.patient.patientRegistry.billing} />
            <Elements stripe={stripePromise}>
              <UpdatePaymentMethod
                emailProfessional={professionalData?.email}
              />
            </Elements>
          </>
        )}
        <SectionTitle title={labels.account.license_title} />
        <LicenseInfo />
      </Grid>
    </>
  );
};

export default withSnackbar(Account);
