import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { LinkContainer, MenuNav } from "./style";
import "./nav.css";
import { labels } from "../translations";
import { getUserPermission, isFeatureFlagEnabled } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SpLinkContainer from "../../../components/atoms/SpLinkContainer";
import { theme } from "../../../components/theme";
const Menu = (props) => {
  const [groupPermissions, setGroupPermissions] = useState(false);
  const [agendaPermissions, setAgendaPermissions] = useState(false);
  const [manageUsersPermission, setManageUsersPermission] = useState(false);
  const [manageMylabPermission, setManageMylabPermission] = useState(false);
  const [isFederation, setIsFederation] = useState(false);
  const [isPatientLabel, setIsPatientLabel] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    setIsFederation(permissionFederationGroups);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );
    setIsPatientLabel(patientLabelTemp);
    const permissionGroups = await getUserPermission(
      "manage:groups",
      getAccessTokenSilently
    );
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );

    const permissionManageUsers = await getUserPermission(
      "manage:users",
      getAccessTokenSilently
    );

    const permissionMylab = await getUserPermission(
      "manage:mylab",
      getAccessTokenSilently
    );
    setManageMylabPermission(permissionMylab);

    const permissionAgenda = await getUserPermission(
      "manage:agenda",
      getAccessTokenSilently
    );
    setAgendaPermissions(permissionAgenda);
    setManageMylabPermission(permissionMylab);
    setManageUsersPermission(permissionManageUsers);
    // if user has manage-groups or manage-federation-groups can go to Group section
    setGroupPermissions(permissionGroups || permissionFederationGroups);
  }, []);

  const getNavLinkClass = (path) =>
    path === "/"
      ? props.location.pathname === path
      : props.location.pathname.includes(path) ||
        props.location.pathname.includes(path + "/");

  return (
    <>
      <MenuNav>
        <LinkContainer
          style={{
            backgroundColor:
              getNavLinkClass("/") && theme.colors.primary.lightBlue,
          }}
        >
          <NavLink
            id={"dashboard"}
            exact
            to={`/`}
            activeClassName="active-page"
          >
            {labels.nav.dashboard}
          </NavLink>
        </LinkContainer>

        <SpLinkContainer
          id="patients"
          destination="/patients"
          label={isPatientLabel ? labels.nav.patients : labels.nav.athletes}
          path="/patients"
          location={props.location}
        ></SpLinkContainer>

        {isFederation ? (
          <SpLinkContainer
            id="federations"
            destination="/federations"
            label={labels.nav.federations}
            path="/federations"
            location={props.location}
            className={!groupPermissions && "linkDisabled"}
          ></SpLinkContainer>
        ) : (
          <>
            {groupPermissions && (
              <SpLinkContainer
                id="groups"
                destination="/groups"
                label={labels.nav.groups}
                path="/groups"
                location={props.location}
                className={!groupPermissions && "linkDisabled"}
              ></SpLinkContainer>
            )}
          </>
        )}
        {agendaPermissions && (
          <SpLinkContainer
            id="agenda"
            destination="/agenda"
            label={labels.nav.agenda}
            path="/agenda"
            location={props.location}
          ></SpLinkContainer>
        )}

        {/*<LinkContainer style={{ backgroundColor: getNavLinkClass("/library") && theme.colors.primary.lightBlue }}>*/}
        {/*	<NavLink to={`/library`} activeClassName='active-page'>*/}
        {/*		LIBRERIA MEDICA*/}
        {/*	</NavLink>*/}
        {/*</LinkContainer>*/}

        <SpLinkContainer
          id="reports"
          destination="/reports"
          label={labels.nav.stats}
          path="/reports"
          location={props.location}
        ></SpLinkContainer>

        {manageMylabPermission && (
          <SpLinkContainer
            id="mylab"
            destination="/mylab"
            label={labels.nav.mylab}
            path="/mylab"
            location={props.location}
          ></SpLinkContainer>
        )}

        {manageUsersPermission && (
          <SpLinkContainer
            id="manageUsers"
            destination="/manageUsers"
            label={labels.nav.manageUsers}
            path="/manageUsers"
            location={props.location}
          ></SpLinkContainer>
        )}
      </MenuNav>
    </>
  );
};

Menu.propTypes = {};

export default withRouter(Menu);
