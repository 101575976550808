import { labels } from "../../../../shared/translations";
import jsPDF from "jspdf";
import LogoUrl from "../../../../../assets/icon/powerset_logo_web.png";
import {
  createExcerciseTemplate,
  updateExerciseTemplate,
} from "../../../../../models/actions/Excercise";

export const saveExercise = async ({
  schedaEsercizi,
  exerciseList,
  name,
  setExerciseDetails,
  props,
  rows,
  idTemplate,
}) => {
  const retValue = Object.keys(schedaEsercizi).map((idx) => {
    return {
      ...exerciseList.find(({ id }) => idx == id),
      exercises: schedaEsercizi[idx].map((itemexercise) => {
        const found = rows.find(
          (item) => item.id_excercise == itemexercise.id_excercise
        );
        return { ...found, ...itemexercise };
      }),
    };
  });
  let ret;
  if (idTemplate) {
    ret = await updateExerciseTemplate({
      id_template: idTemplate,
      name: name,
      details: retValue,
    });
  } else {
    if (!name) {
      props.snackbarShowErrorMessage("Inserire un nome per il template");
      return;
    }
    ret = await createExcerciseTemplate({
      name: name,
      list: retValue,
    });
  }
  if (ret.message) {
    props.snackbarShowMessage(ret.message);
  } else {
    props.snackbarShowErrorMessage(ret.error);
  }
  setExerciseDetails(false);
};

export const downloadPDFExercise = async ({
  schedaEsercizi,
  rows,
  name,
  exerciseList,
}) => {
  const retValue = Object.keys(schedaEsercizi).map((idx) => {
    return {
      ...exerciseList.find(({ id }) => idx == id),
      exercises: schedaEsercizi[idx].map((itemexercise) => {
        const found = rows.find(
          (item) => item.id_excercise == itemexercise.id_excercise
        );
        return { ...found, ...itemexercise };
      }),
    };
  });
  await generateWorkoutPDF(retValue, name);
};

export const generateWorkoutPDF = async (workoutData, name) => {
  const doc = new jsPDF();
  const img = new Image();
  img.src = LogoUrl;

  const imgWidth = 25;
  const imgHeight = (img.height / img.width) * imgWidth;
  const pageHeight = doc.internal.pageSize.getHeight(); // Altezza della pagina
  const barHeight = 0.6;
  const pageWidth = doc.internal.pageSize.getWidth() - 50;

  let y = 10;

  const addPageIfNeeded = () => {
    if (y > 240) {
      doc.addPage();
      doc.setDrawColor(73, 197, 182);
      doc.setFillColor(73, 197, 182);
      doc.rect(0, pageHeight - 10, pageWidth, barHeight, "F");
      doc.addImage(img, "PNG", 172, pageHeight - 12, imgWidth, imgHeight);
      y = 20;
    }
  };

  // Barra inferiore e logo
  doc.setDrawColor(73, 197, 182);
  doc.setFillColor(73, 197, 182);
  doc.rect(0, pageHeight - 10, pageWidth, barHeight, "F");
  doc.addImage(img, "PNG", 172, pageHeight - 12, imgWidth, imgHeight);

  // Titolo
  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);

  doc.text(`${labels.mylab.exercise.pdfTitle}: ${name}`, 5, y);
  y += 10;

  for (const session of workoutData) {
    addPageIfNeeded();

    if (session.is_circuit) {
      doc.setFontSize(16);
      doc.setFillColor(200, 200, 200);
      doc.rect(5, y, 200, 10, "F");
      doc.text(
        `${labels.mylab.exercise.numRepCir}: ${session.circuitLength}`,
        10,
        y + 7
      );
      y += 15;
    }

    for (const exercise of session.exercises) {
      addPageIfNeeded();

      if (session.is_circuit) {
        doc.setFillColor(200, 200, 200);
        doc.rect(5, y - 7, 200, 10, "F");
      }
      doc.setFontSize(14);
      doc.text(`${exercise?.name}`, !session.is_circuit ? 10 : 15, y);
      doc.setTextColor(0, 0, 0);
      y += 6;

      doc.setFontSize(10);
      for (let i = 0; i < exercise.series; i++) {
        addPageIfNeeded();
        const serie = exercise.repetition[i];
        doc.setFontSize(12);
        if (session.is_circuit) {
          doc.setFillColor(200, 200, 200);
          doc.rect(5, y - 5, 200, 20, "F");
        }
        doc.text(
          `${labels.mylab.tempAdd.excerciseSheet.list.header.series}: ${i + 1}`,
          !session.is_circuit ? 15 : 20,
          y
        );
        y += 6;
        doc.setFontSize(10);

        doc.text(
          `${labels.mylab.tempAdd.excerciseSheet.list.header.duration}: ${serie?.repetition_time}`,
          !session.is_circuit ? 20 : 25,
          y
        );
        y += 6;
        doc.text(
          `${labels.mylab.tempAdd.excerciseSheet.list.header.load}: ${serie?.load}`,
          !session.is_circuit ? 20 : 25,
          y
        );
        y += 6;
      }
      if (session.is_circuit) {
        doc.setFillColor(200, 200, 200);
        doc.rect(5, y - 5, 200, 28, "F");
      }
      doc.text(
        `${labels.mylab.tempAdd.excerciseSheet.list.header.recovery}: ${
          exercise?.restExercise ?? ""
        }`,
        !session.is_circuit ? 15 : 20,
        y
      );
      y += 6;
      doc.text(
        `${labels.mylab.tempAdd.excerciseSheet.list.header.accessories}: ${
          exercise?.accessories ?? ""
        }`,
        !session.is_circuit ? 15 : 20,
        y
      );
      y += 6;
      doc.text(
        `${labels.mylab.tempAdd.excerciseSheet.list.header.mode}: ${
          exercise?.execution ?? ""
        }`,
        !session.is_circuit ? 15 : 20,
        y
      );

      y += 6;
      doc.text(
        `${labels.mylab.tempAdd.excerciseSheet.list.header.notes}: ${
          exercise?.note ?? ""
        }`,
        !session.is_circuit ? 15 : 20,
        y
      );

      y += 10;
    }
  }

  doc.save(`workout_${name}.pdf`);
};
