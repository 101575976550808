/* BUTTON IN THE NAVIGATION BAR */
import React from "react";
import Button from "@material-ui/core/Button";
import { styled } from "../styled";
import { theme } from "../theme";

const ButtonSubmit = styled(Button)((buttonType) => ({
  textAlign: "center",
  maxHeight: "30px",
  minWidth: "180px",
  borderRadius: 0,
  color: "white",
  borderColor: theme.colors.primary.lightBlue,
  backgroundColor: "#40173b",
  border: "1px solid",
  "& .MuiButton-label": {
    fontFamily: "Montserrat, sans-serif !important",
    fontWeight: "bold",
  },
}));
const variants = {
  none: {},
  column: {
    marginTop: 8,
  },
  row: {
    marginRight: "3%",
  },
  listButton: {
    minWidth: "100%",
  },
  standard: {
    backgroundColor: "transparent !important",
    borderBottom: `5px solid ${theme.colors.primary.lightBlue}`,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "50%",
    marginRight: 0,
  },
  standardMax: {
    backgroundColor: "transparent !important",
    borderBottom: `5px solid ${theme.colors.primary.lightBlue}`,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    marginRight: 0,
  },
  standardOppositeFull: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #40173b",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    marginRight: 0,
  },
  standardOpposite: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #40173b",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "50%",
    marginRight: 0,
  },
  standardOppositeMax: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #40173b",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    marginRight: 0,
  },
};

const SpButton = ({
  id,
  text,
  type,
  onClick,
  buttonType,
  variant,
  disabled,
  className,
  style,
  isNotification,
}) => {
  return (
    <ButtonSubmit
      id={id}
      className={className}
      disabled={disabled}
      type={type}
      style={{
        ...style,
        ...variants[variant],
        marginRight: !variant && "3%",
        backgroundColor:
          type === "tableList"
            ? "transparent"
            : buttonType === "accept" && theme.colors.primary.lightBlue,
      }}
      onClick={onClick}
      color="primary"
      disableElevation
      labelstyle={{
        fontFamily: "Montserrat, sans-serif !important",
      }}
    >
      {text}
      {isNotification && (
        <span
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "8px",
            height: "8px",
            backgroundColor: "red",
            borderRadius: "50%",
            animation: "blink 1s infinite",
          }}
        ></span>
      )}
    </ButtonSubmit>
  );
};

export default SpButton;
