import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { labels } from "./shared/translations";
import { styled } from "../components/styled";
import { useHistory } from "react-router-dom";
import { getUserPermission } from "../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { isFeatureFlagEnabled } from "../utils/common";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import { getGenericRiskFactor, getGpsDevice } from "./MyLabFunction";
import { theme } from "../components/theme";

const StyledRowTemplateItem = styled(Grid)({
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "1%",
  marginBottom: "1%",
});

const MyLab = () => {
  const history = useHistory();
  const [featureFlagGpsDevices, setFeatureFlagGpsDevices] = useState(false);
  const [roleGeneralRiskFactor, setRoleGeneralRiskFactor] = useState(false);

  useEffect(async () => {
    const gpsFeatureFlag = await isFeatureFlagEnabled("gps_devices");
    setFeatureFlagGpsDevices(gpsFeatureFlag);
  }, []);

  useState(false);

  const [gpsDevicePermission, setGpsDevicePermission] = useState(false);
  const [enabledAssessmentTestDataset, setEnableAssessmentTestDataset] =
    useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    const gpsDeviceTemp = await getUserPermission(
      "manage:gps-device",
      getAccessTokenSilently
    );
    setGpsDevicePermission(gpsDeviceTemp);

    const riskFactorTemp = await getUserPermission(
      "manage:general-risk-factor",
      getAccessTokenSilently
    );
    setRoleGeneralRiskFactor(riskFactorTemp);

    const assessmentTestEnabled = await isFeatureFlagEnabled(
      "assessment-test-dataset"
    );
    setEnableAssessmentTestDataset(assessmentTestEnabled);
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SpText variant="h1">{labels.mylab.page.title}</SpText>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: "2em" }}
          direction="column"
          container
          spacing={3}
        >
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.activityPlan}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/activity-plan");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/activity-plan/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.excerciseSheet}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/excercise");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/excercise/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.assesment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/assesment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/assesment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.handbook}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/handbook");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/handbook/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.training}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/training");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/training/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.rehab}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/rehab");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/rehab/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.treatment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/treatment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/treatment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>

          {/* GENERIC RISK FACTOR */}
          {getGenericRiskFactor(roleGeneralRiskFactor, history)}

          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.riskFactor}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/risk_factor");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/risk_factor/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.customExercise}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                id="viewCustomExerciseButton"
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/custom-exercise");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                id="addCustomExerciseButton"
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/custom-exercise/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          {enabledAssessmentTestDataset && (
            <StyledRowTemplateItem item xs={12} container>
              <Grid item xs={8}>
                <SpText variant="h4">
                  {labels.mylab.tempList.assessmentTest}
                </SpText>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <SpButton
                  buttonType="accept"
                  text={labels.mylab.tempList.action.view}
                  onClick={() => {
                    history.push("/mylab/assessment-test");
                  }}
                />
              </Grid>
            </StyledRowTemplateItem>
          )}
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.dysfunctionWeights}
              </SpText>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <SpButton
                id={"weightDisordersButton"}
                buttonType="accept"
                text={labels.mylab.tempList.action.manage}
                onClick={() => {
                  history.push("/mylab/dysfunction_weights/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          {/*GPS DEVICE*/}
          {getGpsDevice(featureFlagGpsDevices, gpsDevicePermission, history)}
        </Grid>
      </Grid>
    </>
  );
};

export default MyLab;
