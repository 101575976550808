import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import {
  getAllPromsSurveys,
  getQuestionnairesAnswered,
  sendPromsMonitoringAnswer,
  sendPromsToPatient,
} from "../../../models/actions/Proms";
import { rollbar } from "../../../utils/common";
import { theme } from "../../../components/theme";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledAdditionalInfoWrapper = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  // margin: "2%",
  padding: "2%",
  backgroundColor: "#333333",
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
});

const PatientsMonitoringAddProms = (props) => {
  const [selectedProms, setSelectedProms] = useState();
  const [promsData, setPromsData] = useState([]);
  const [promsSelectedData, setPromsSelectedData] = useState([]);
  const [promsObj, setPromsObj] = useState([]);
  const [promsSendingStatus, setPromsSendingStatus] = useState(false);
  const [score, setScore] = useState(0);
  const [injuryOrIllenss, setInjuryOrIllness] = useState("");
  const globalIndex = useRef(0);
  const { patId, presentationId } = useParams();
  const { setLoading } = props;

  const { reset, register, errors, control, getValues, handleSubmit, watch } =
    useForm({
      shouldUnregister: false,
    });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "proms_answers",
  //   keyName: "id",
  //   shouldUnregister: false,
  // });

  const watchPromsSelect = watch("proms");

  const handleChange = async (value) => {
    await setSelectedProms(value);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getAllPromsSurveys();
      setPromsData(result);
    } catch (error) {
      rollbar.error("PatientsMonitoringAddProms - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkQuestAnswered = async (promsId) => {
    const responseDeleg = await getQuestionnairesAnswered({
      id_patient: patId,
      id_questionnaires: promsId,
    });
    if (responseDeleg && responseDeleg.length > 0) {
      setPromsSendingStatus(true);
    } else {
      setPromsSendingStatus(false);
    }
  };

  useEffect(() => {
    if (selectedProms) {
      const foundProms = promsData.find(
        (promsElem) => promsElem.id == selectedProms
      );
      if (foundProms) {
        const surveys = foundProms.proms_surveys.map((item) => ({
          ...item,
          prom_key: foundProms.key,
        }));
        setPromsSelectedData(surveys);
        setPromsObj(foundProms);
        checkQuestAnswered(foundProms.id);
      }
    }
  }, [watchPromsSelect]);

  //Check if the user has answered the mandatory questions
  const checkAnswers = (data) => {
    switch (promsObj.key) {
      case "dash":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 27
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError1
          );
          return false;
        }
        break;
      case "vas_u":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError2
          );
          return false;
        }
        break;
      case "vas_w":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError2
          );
          return false;
        }
        break;
      case "visa_p":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "koos":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "visa_a":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "faam":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "hagos":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "ndi":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "quick_dash":
        let numQuestions = 0;
        promsSelectedData.forEach((psd) => {
          numQuestions += psd.proms_questions.length;
        });
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < numQuestions / 10
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError4
          );
          return false;
        }
        break;
      case "prwe":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "odi":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
      case "mmodified_tqr":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError2
          );
          return false;
        }
        break;
      case "rpe":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError2
          );
          return false;
        }
        break;
      case "wellness_score":
        if (
          !data.proms_answers ||
          data.proms_answers.filter((e) => e != null).length < 1
        ) {
          props.snackbarShowErrorMessage(
            labels.patient.monitoring.proms.errors.genericError3
          );
          return false;
        }
        break;
    }
    return true;
  };

  const savePromsAnswers = async (data) => {
    try {
      const promsAnswer = [];
      Object.keys(data?.proms_answers).map((item) => {
        promsAnswer.push(data?.proms_answers[item]);
      });
      const dataRefact = { ...data, proms_answers: promsAnswer };
      if (checkAnswers(dataRefact)) {
        const result = await sendPromsMonitoringAnswer({
          ...dataRefact,
          id_patient: patId,
          date: moment().format("YYYY-MM-DD"),
        });
        if (result?.error) {
          props.snackbarShowErrorMessage(result.error);
        } else {
          props.snackbarShowMessage(result.message);
          props.closeAssessmentAdd(false);
        }
      }
    } catch (error) {
      rollbar.error("PatientsMonitoringAddProms - savePromsAnswers", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const Questions = ({ selectedProms }) => {
    return selectedProms?.map((quest, idx) => {
      return (
        <Grid item xs={12}>
          <Controller
            style={{ width: "100%" }}
            render={(props) => (
              <SpSelect
                label={psTranslate(quest.question)}
                value={props.value}
                formControlStyle={{ width: "100%" }}
                // formControlWidth={200}
                onChange={(e) => {
                  props.onChange(e.target.value);
                  const answerSelected = quest.proms_answers.find(
                    ({ id }) => id == e.target.value
                  );
                  const updateScore = answerSelected?.score;
                  if (quest.pos == "ostrc_5") {
                    setInjuryOrIllness(answerSelected.answer);
                  }
                  setScore(score + parseInt(updateScore));
                }}
              >
                {quest.proms_answers.map((p) => (
                  <SpSelectMenuItem key={p.id} value={p.id}>
                    {psTranslate(p.answer)}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
            )}
            defaultValue={0}
            name={`proms_answers[${quest.pos}]`}
            control={control}
          />
        </Grid>
      );
    });
  };

  const sendPromsCompilationToPatient = async () => {
    try {
      const sendingResult = await sendPromsToPatient({
        id_patient: patId,
        id_questionnaires: selectedProms,
      });
      if (sendingResult) {
        props.snackbarShowMessage(sendingResult.message);
        fetchData();
        checkQuestAnswered(selectedProms);
      }
    } catch (error) {
      rollbar.error(
        "PatientsMonitoringAddProms - sendPromsCompilationToPatient",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <StyledBodyColumn>
      <form onSubmit={handleSubmit(savePromsAnswers)}>
        <StyledRow style={{ width: "33%" }}>
          <SpButton
            buttonType="accept"
            variant="none"
            style={{ width: "100%" }}
            text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
            onClick={() => props.closeAssessmentAdd()}
          />
        </StyledRow>
        <StyledRow>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Controller
                style={{ marginTop: "3%" }}
                render={(props) => (
                  <SpSelect
                    label={
                      labels.patient.presentation.add.stepper.step5.chooseProms
                    }
                    value={props.value}
                    formControlWidth={"66%"}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      handleChange(e.target.value);
                    }}
                  >
                    {promsData.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.name
                          ? psTranslate(p.name)
                          : labels.patient.presentation.add.stepper.step5
                              .chooseProms}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`proms`}
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledAdditionalInfoWrapper>
                {!promsSendingStatus ? (
                  <SpText textAlign="center" variant="h1PageSubtitle">
                    {
                      labels.patient.presentation.add.stepper.step2
                        .patientDelegSubtitle
                    }
                  </SpText>
                ) : (
                  <SpText textAlign="center" variant="h1PageSubtitle">
                    {
                      labels.patient.presentation.add.stepper.step2
                        .patientAlreadyDelegSubtitle
                    }
                  </SpText>
                )}
                {!promsSendingStatus && (
                  <SpButton
                    buttonType="accept"
                    disabled={!selectedProms}
                    text={
                      labels.patient.presentation.add.stepper.step2
                        .patientDelegSend
                    }
                    onClick={() => sendPromsCompilationToPatient()}
                    variant="column"
                  />
                )}
              </StyledAdditionalInfoWrapper>
            </Grid>
          </Grid>
        </StyledRow>

        {promsObj &&
          promsObj?.instructions &&
          promsObj?.instructions !== "null" && (
            <Grid container spacing={1}>
              <SpText variant="h4ComponentLabel">
                {psTranslate(promsObj?.instructions)}
              </SpText>
            </Grid>
          )}

        {promsSelectedData?.map((survey, idx) => {
          const headerProms = survey.proms_questions?.filter(
            (quest, idx) =>
              quest.pos == "ostrc_1" ||
              quest.pos == "ostrc_2" ||
              quest.pos == "ostrc_3" ||
              quest.pos == "ostrc_4"
          );

          const bodyProms = survey.proms_questions?.filter(
            (quest, idx) =>
              quest.pos == "ostrc_5" ||
              quest.pos == "ostrc_8" ||
              quest.pos == "ostrc_9" ||
              quest.pos == "ostrc_10" ||
              quest.pos == "ostrc_11"
          );
          const injuryProms = survey.proms_questions?.filter(
            (quest, idx) => quest.pos == "ostrc_6"
          );
          const illnessProms = survey.proms_questions?.filter(
            (quest, idx) => quest.pos == "ostrc_7"
          );

          const selectTitle = `${idx + 1}) ${psTranslate(survey.name)}`;
          if (survey.prom_key == "ostrc") {
            return (
              <Grid container direction="column" spacing={1}>
                <Questions selectedProms={headerProms} />
                {score > 0 && <Questions selectedProms={bodyProms} />}
                {injuryOrIllenss == "Infortunio" && (
                  <Questions selectedProms={injuryProms} />
                )}
                {injuryOrIllenss == "Malattia" && (
                  <Questions selectedProms={illnessProms} />
                )}
              </Grid>
            );
          } else
            return (
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} style={{ marginTop: "2%" }}>
                  <SpText textAlign="start" variant="text">
                    {selectTitle}
                  </SpText>
                </Grid>
                {survey.proms_questions?.map((quest, idx) => {
                  globalIndex.current++;

                  return (
                    <Grid item xs={12}>
                      <Controller
                        style={{ width: "100%" }}
                        render={(props) => (
                          <SpSelect
                            label={psTranslate(quest.question)}
                            value={props.value}
                            formControlStyle={{ width: "100%" }}
                            // formControlWidth={200}
                            onChange={(e) => {
                              props.onChange(e.target.value);
                            }}
                          >
                            {quest.proms_answers.map((p) => (
                              <SpSelectMenuItem key={p.id} value={p.id}>
                                {psTranslate(p.answer)}
                              </SpSelectMenuItem>
                            ))}
                          </SpSelect>
                        )}
                        defaultValue={0}
                        name={`proms_answers[${quest.pos}]`}
                        control={control}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
        })}
        {getValues("proms") && (
          <StyledRow>
            {/* <SpButton style={{ marginTop: "2%" }} buttonType='accept' text={"Salva"} type={"submit"} /> */}
            <SpButton
              style={{ marginTop: "2%", width: "33%" }}
              buttonType="accept"
              text={labels.patient.monitoring.proms.saveAndClose}
              onClick={() => {
                handleSubmit(savePromsAnswers)();
              }}
            />
          </StyledRow>
        )}
      </form>
    </StyledBodyColumn>
  );
};

export default withSnackbar(PatientsMonitoringAddProms);
