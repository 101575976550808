import { call } from "./utils";

export const createExcerciseTemplate = async (data) =>
  await call({ url: "/exercise/template/create", data: { data } });

export const updateExerciseTemplate = async (data) =>
  await call({ url: "/exercise/template/update", data: { data } });

export const duplicateExerciseTemplate = async (data) =>
  await call({
    url: "/exercise/template/duplicate",
    data: { data },
  });

export const getExerciseById = async (data) =>
  await call({
    url: "/exercise/by/id",
    data: { data },
  });

export const getAllExercises = async () =>
  await call({ method: "GET", url: "/exercise/list" });

export const getAllExerciseCategories = async () =>
  await call({ method: "GET", url: "/exercise/category/list" });

export const updateExerciseCategories = async (data) =>
  await call({
    url: "/exercise/category/update",
    data: { data },
  });

export const duplicateExercise = async (data) =>
  await call({
    url: "/exercise/duplicate",
    data: { data },
  });

export const createExercise = async (data) =>
  await call({
    url: "/exercise/create",
    data: { data },
  });

export const updateExercise = async (data) =>
  await call({
    url: "/exercise/update",
    data: { data },
  });

export const deleteExercise = async (data) =>
  await call({
    url: "/exercise/delete",
    data: { data },
  });

export const getExerciseTemplates = async () =>
  await call({ url: "/exercise/templates/list" });

export const getExerciseTemplateDetail = async (data) =>
  await call({
    url: "/exercise/template/by/id",
    data: { data },
  });
