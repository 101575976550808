import React, { useEffect, useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";
import SpButton from "../../../components/atoms/SpButton";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import SpLoader from "../../../components/atoms/SpLoader";
import { Grid, Typography } from "@material-ui/core";

import moment from "moment";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import { titleCase } from "../../../utils/common";

import {
  getBookingsList,
  updateBooking,
} from "../../../models/actions/Organization";
const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const RequestsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [requets, setRequests] = useState([]);

  const history = useHistory();

  const headCellsWrestlingDefault = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: `${labels.patient.table.header.name} ${labels.patient.table.header.surname}`,
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: labels.bodymap.dialogDetail.inputDateLabel,
      isAction: false,
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: labels.requestsList.time,
      isAction: false,
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: labels.requestsList.note,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.actions,
      isAction: true,
    },
  ];

  const fetchData = async () => {
    const ret = await getBookingsList();
    props.setMyLabNotification(ret.some(({ status }) => status == "pending"));
    setRequests(ret);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const RequestRow = ({ row, index }) => {
    const history = useHistory();
    return (
      <SpTableRow
        role="checkbox"
        id={row.id}
        tabIndex={index}
        key={`patient-${index}`}
      >
        <SpTableCell>
          <SpIconAndTextButton
            id={`patient-row-${row?.patient?.givenName}-${row?.patient?.familyName}`}
            isIconRight={true}
            text={titleCase(
              `${row?.patient?.givenName} ${row?.patient?.familyName}`
            )}
            textVariant={"tableTextDetails"}
            onClick={() => {
              history.push(`/patients/edit/${row?.id_patient}/plan`);
            }}
          >
            <ArrowForwardIosIcon
              style={{
                width: 15,
                color: theme.colors.primary.lightBlue,
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </SpTableCell>

        <SpTableCell>
          <SpText variant="tableTextDetails">
            {moment(row?.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </SpText>
        </SpTableCell>

        <SpTableCell>
          <SpText variant="tableTextDetails">
            {moment.utc(row?.start_time, "HH:mm:ss").local().format("HH:mm")}-
            {moment.utc(row?.end_time, "HH:mm:ss").local().format("HH:mm")}
          </SpText>
        </SpTableCell>

        <SpTableCell>
          <SpText variant="tableTextDetails">{psTranslate(row?.note)}</SpText>
        </SpTableCell>
        <SpTableCell>
          {row.status == "pending" ? (
            <>
              <SpButton
                variant={"standard"}
                text={labels.requestsList.approve}
                type="tableList"
                onClick={async () => {
                  setLoading(true);
                  const ret = await updateBooking({
                    id: row?.id,
                    status: "approved",
                  });
                  if (ret.message) {
                    props.snackbarShowMessage(ret.message);
                  } else {
                    props.snackbarShowErrorMessage(ret.error);
                  }
                  await fetchData();
                  setLoading(false);
                }}
              />
              <SpButton
                variant={"standardOpposite"}
                text={labels.requestsList.reject}
                type="tableList"
                onClick={async () => {
                  setLoading(true);
                  await updateBooking({ id: row?.id, status: "rejected" });
                  await fetchData();
                  setLoading(false);
                }}
              />
            </>
          ) : (
            <SpText variant="tableTextDetails">
              {psTranslate(labels.requestsList[row?.status])}
            </SpText>
          )}
        </SpTableCell>
      </SpTableRow>
    );
  };
  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"Requests list"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>

      {requets?.length > 0 ? (
        <SpTable
          sortable={true}
          pagination={true}
          headCells={headCellsWrestlingDefault}
          rows={requets}
          rowKey="id"
          padding={false}
          notCheckable={false}
        >
          <RequestRow />
        </SpTable>
      ) : (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{ height: "20vh" }}
        >
          <SpText variant="textWhiteArchivedBold">
            {labels.requestsList.noRequests}
          </SpText>
        </Grid>
      )}
    </Grid>
  );
};
export default withSnackbar(RequestsList);
