import React from "react";

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const tooltipContent = payload.map((entry, index) => (
      <p
        key={`tooltip-${index}`}
        style={{ color: "#b8b6b6", margin: "5px 0", paddingLeft: "10px" }}
      >
        {`${entry.name}: ${entry.value}`}
      </p>
    ));

    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "#1f1f1f", padding: "10px" }}
      >
        <b style={{ color: "#ffffff" }}> {`Data: ${label}`}</b>
        {tooltipContent}
      </div>
    );
  }

  return null;
};

export const CustomTooltip_test = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "#1f1f1f", padding: "5px" }}
      >
        <b style={{ color: "#ffffff", fontSize: "1.2em" }}>
          {" "}
          {`Data: ${data.date}`}
        </b>
        <p style={{ color: "#ffffff" }}> Value: {data.value.toFixed(2)}</p>
      </div>
    );
  }
  return null;
};
