import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import SpText from "../../../../components/atoms/SpText";
import { labels, psTranslate } from "../../../shared/translations";
import { theme } from "../../../../components/theme";
import { getResourceFromServer } from "../../../../models/actions/Professionals";

const LabelValue = ({ label, value }) =>
  value ? (
    <Grid direction="column" xs={12} style={{ marginBottom: "5px" }}>
      <SpText variant="text">{label}</SpText>
      <SpText variant="h4ComponentLabel">{value}</SpText>
    </Grid>
  ) : null;

const ExerciseDetails = ({ exercise }) => {
  const [gifUrl, setGifUrl] = useState([]);

  useEffect(async () => {
    //Fetch exercise file image
    if (exercise.file) {
      const files = await getResourceFromServer({ id_file: exercise.file.id });
      setGifUrl(files);
    }
  }, [exercise]);

  return (
    <Grid container xs={12}>
      <Divider
        style={{
          padding: "1px",
          width: "100%",
          backgroundColor: theme.colors.primary.lightBlue,
          marginTop: "1%",
          marginBottom: "1%",
        }}
      />
      <Grid
        container
        xs={12}
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
        }}
        direction="row"
      >
        <LabelValue
          label={labels.mylab.tempAdd.exercise.name}
          value={psTranslate(exercise.name)}
        />
        <LabelValue
          label={labels.mylab.tempAdd.exercise.tags}
          value={exercise.tags}
        />
        <Grid
          item
          xs={12}
          justifyContent="flex-end"
          style={{ marginTop: "15px" }}
        >
          <SpText variant="text">{labels.mylab.tempAdd.exercise.gif}</SpText>
          <img
            style={{
              marginTop: "5px",
              width: "250px",
              height: "250px",
              objectFit: "contain",
              border: "1px solid white",
            }}
            src={gifUrl}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExerciseDetails;
