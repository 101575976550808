import React, { useEffect, useState } from "react";
import "./Card.css"; // Import your styling if needed
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import { calculateMean, calculateStandardDeviation } from "./computation.jsx";
import { CustomTooltip_test } from "./customization.jsx";
import { theme } from "../../../../components/theme.js";
import { Grid } from "@material-ui/core";

const Card = ({
  title,
  description,
  lastV,
  lastV_d,
  lastV2,
  lastD,
  chartData,
  metric,
}) => {
  const arrVal = chartData.map((entry) => entry.value);
  const mean = calculateMean(arrVal);
  const stdDeviation = calculateStandardDeviation(arrVal) * 0.4;
  const upperlim = mean + stdDeviation;
  const lowerlim = mean - stdDeviation;
  const swc = calculateStandardDeviation(arrVal) * 0.6;

  // Create an array for the standard deviation range
  const stdDeviationData = chartData.map(({ date, value }) => ({
    date,
    upper: mean + stdDeviation,
    lower: mean - stdDeviation,
    value,
  }));

  // Determine the color based on the conditions
  let textColor = "#d6d6d6"; // Default color (gray)

  if (metric == "tempo (s)" || metric === "momento") {
    if (lastV2 - lastV > swc) {
      textColor = "#02b00b"; // Assign red if above upper threshold
    } else if (lastV2 - lastV < -swc) {
      textColor = "#ba0202"; // Assign green if between lower and upper thresholds
    }
  } else {
    if (lastV2 - lastV > swc) {
      textColor = "#ba0202"; // Assign green if above upper threshold
    } else if (lastV2 - lastV < -swc) {
      textColor = "#02b00b"; // Assign red if between lower and upper thresholds
    }
  }

  // Determine the color based on the conditions
  let textColor2 = "#d6d6d6"; // Default color (gray)

  if (metric == "tempo (s)" || metric === "momento") {
    if (lastV > upperlim) {
      textColor2 = "#ba0202"; // Assign red if above upper threshold
    } else if (lastV < lowerlim) {
      textColor2 = "#02b00b"; // Assign green if between lower and upper thresholds
    }
  } else {
    if (lastV > upperlim) {
      textColor2 = "#02b00b"; // Assign green if above upper threshold
    } else if (lastV < lowerlim) {
      textColor2 = "#ba0202"; // Assign red if between lower and upper thresholds
    }
  }

  // Set the background image URL
  const backgroundImageUrl = "./public/card_bg_2.jpg";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: `0.5px solid ${textColor2}`,
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        margin: "0px 0",
        boxShadow: `2px 2px 4px ${textColor2}`,
        minWidth: "29vw",
        minWidth: "31vw",
        boxSizing: "border-box",
        background: `url(${backgroundImageUrl}) center / cover no-repeat`,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            alignSelf: "flex-start",
            flex: "1",
            padding: "10px",
            margin: "0",
          }}
        >
          <h2
            style={{
              fontSize: "1.8em",
              marginBottom: "8px",
              color: "#fff",
              margin: "0",
            }}
          >
            {title}
          </h2>
          <p style={{ color: "#fff", margin: "0" }}>{description}</p>
          <h2></h2>
        </div>
        <div
          style={{
            alignSelf: "flex-start",
            flex: "1",
            padding: "10px",
            textAlign: "right",
            margin: "0",
          }}
        >
          <p
            style={{
              fontSize: "1.8em",
              color: "#fff",
              fontWeight: "bold",
              margin: "0",
            }}
          >
            {lastV.toFixed(2)}
          </p>
          <p
            style={{ color: textColor, margin: "0" }}
          >{`SWC vs 2nd last: ${lastV_d.toFixed(2)}`}</p>
          <h2></h2>
        </div>
      </div>
      <ResponsiveContainer
        width="100%"
        height={150}
        style={{ marginRight: "2%", marginLeft: "-2%" }}
      >
        <ComposedChart width={800} height={400} data={stdDeviationData}>
          <XAxis dataKey="date" />
          <YAxis
            domain={[
              Math.floor(
                Math.min(chartData.map((entry) => entry.value)).toFixed(1) * 10
              ) / 10,
              Math.ceil(
                Math.max(chartData.map((entry) => entry.value)).toFixed(1) * 10
              ) / 10,
            ]}
          />
          <Tooltip content={<CustomTooltip_test />} />

          <Area
            type="monotone"
            dataKey="upper"
            fill="gray"
            stroke={
              metric === "tempo (s)" || metric === "momento"
                ? "#ba0202"
                : "#02b00b"
            }
            strokeDasharray="3 2"
            strokeWidth={1}
            fillOpacity={0.3}
          />
          <Area
            type="monotone"
            dataKey="lower"
            fill="black"
            stroke={
              metric === "tempo (s)" || metric === "momento"
                ? "#02b00b"
                : "#ba0202"
            }
            strokeDasharray="3 2"
            strokeWidth={1}
            fillOpacity={1}
          />

          <Line
            type="monotone"
            dataKey="value"
            stroke="#3d988e"
            strokeWidth={3}
            dot={{
              stroke: theme.colors.primary.lightBlue,
              strokeWidth: 3,
              r: 6,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const Card_test = ({ data, test, metrica }) => {
  const [lastValue, setLastValue] = useState();
  const [secondToLastValue, setSecondToLastValue] = useState();
  const [diff, setDiff] = useState();
  const [lastDate, setLastDate] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    const lastEntry = Object.entries(data[0][test][metrica]).pop();
    if (lastEntry) {
      const [lastDateTemp, lastValueTemp] = lastEntry;
      setLastValue(parseFloat(lastValueTemp));
      setLastDate(lastDateTemp);
      const chartDataTemp = Object.entries(data[0][test][metrica]).map(
        ([date, value]) => ({ date, value })
      );
      setChartData(chartDataTemp);

      const entries = Object.entries(data[0][test][metrica]);
      let secondToLastValueTemp;
      let secondToLastDate;
      if (entries.length >= 2) {
        const secondToLastEntry = entries.slice(-2, -1).pop();
        [secondToLastDate, secondToLastValueTemp] = secondToLastEntry;
      } else {
        secondToLastValueTemp = lastValueTemp;
      }

      setSecondToLastValue(secondToLastValueTemp);
      const diffTemp =
        parseFloat(lastValueTemp) - parseFloat(secondToLastValueTemp);
      setDiff(diffTemp);
    }
  }, [data, test, metrica]);

  return (
    <Grid item xs={6}>
      <div
        style={{
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <div style={{ padding: "5px", boxSizing: "border-box" }}>
          {test &&
            metrica &&
            lastValue &&
            secondToLastValue &&
            lastDate &&
            chartData && (
              <Card
                title={test}
                description={metrica}
                lastV={lastValue}
                lastV2={secondToLastValue}
                lastV_d={diff}
                lastD={lastDate}
                chartData={chartData}
                metric={metrica}
              />
            )}
        </div>
      </div>
    </Grid>
  );
};

export default Card_test;
