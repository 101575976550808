import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { LinkContainer, MenuContainer, MenuNav } from "./style";
import "./nav.css";
import { theme } from "../../../components/theme";

const MenuAgency = (props) => {
  const getNavLinkClass = (path) => props.location.pathname === path;

  return (
    <>
      <MenuContainer>
        <MenuNav>
          <LinkContainer
            style={{
              backgroundColor:
                getNavLinkClass("/agency") && theme.colors.primary.lightBlue,
            }}
          >
            <NavLink exact to={`/agency`} activeClassName="active-page">
              PROFESSIONISTI
            </NavLink>
          </LinkContainer>
          <LinkContainer
            style={{
              backgroundColor:
                getNavLinkClass("/agency/licenses") &&
                theme.colors.primary.lightBlue,
            }}
          >
            <NavLink to={`/agency/licenses`} activeClassName="active-page">
              LICENZE
            </NavLink>
          </LinkContainer>
          <LinkContainer
            style={{
              backgroundColor:
                getNavLinkClass("/agency/account") &&
                theme.colors.primary.lightBlue,
            }}
          >
            <NavLink to={`/agency/account`} activeClassName="active-page">
              ACCOUNT
            </NavLink>
          </LinkContainer>
        </MenuNav>
      </MenuContainer>
    </>
  );
};

MenuAgency.propTypes = {};

export default withRouter(MenuAgency);
