import { call } from "./utils";

export const getTeamsByFederation = async (data) =>
  await call({ url: "/federation/get/teams", data: { data } });
export const deleteTeam = async (data) =>
  await call({ url: "/delete/team", data: { data } });
export const createTeam = async (data) =>
  await call({ url: "/create/team", data: { data } });
export const getTeamDetails = async (data) =>
  await call({ url: "/get/team/details", data: { data } });
export const updateTeam = async (data) =>
  await call({ url: "/update/team", data: { data } });
export const getProfessionalsTeams = async (data) =>
  await call({ url: "/get/professional/by/team", data: { data } });
export const getFederationInjuries = async (data) =>
  await call({ url: "/get/injury/by/federation", data: { data } });
