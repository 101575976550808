import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpButton from "../../../../components/atoms/SpButton";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import AddMonitoringAssessment from "../../../../components/bundles/assessment/AddMonitoringAssessment";
import {
  getMonitoringAssessmentToRepeat,
  getPresentationAssessmentToRepeat,
} from "../../../../models/actions/Assessment";
import { labels } from "../../../shared/translations";

const AssessmentRepeat = ({
  date, 
  assessmentDetail,
  close,
  setLoading,
  ...props
}) => {
  const [selectedCategoriesObj, setSelectedCategoriesObj] = useState();

  const { presentationId, patId } = useParams();

  useEffect(async () => {
    setLoading(true);
    try {
      const result = await (presentationId
        ? getPresentationAssessmentToRepeat
        : getMonitoringAssessmentToRepeat)({
        id_monitoring_assessment: assessmentDetail.id,
        id_presentation_assessment: assessmentDetail.id,
        id_patient: patId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setSelectedCategoriesObj(result);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  }, []);

  return (
    <>
      <SpButton
        buttonType="accept"
        style={{ maxWidth: "25%", marginBottom: "10px" }}
        onClick={() => close()}
        text={labels.patient.monitoring.closeAddAssessment}
      />
      {selectedCategoriesObj && (
        <AddMonitoringAssessment
          setLoading={setLoading}
          assessmentDetail={assessmentDetail}
          isRepeatAssessment={true}
          isRepeatFromTemplate={!!selectedCategoriesObj.templateAssessmentId}
          closeAssessmentAdd={close}
          selectedCategoriesObj={selectedCategoriesObj}
          date={date}
        />
      )}
    </>
  );
};

export default withSnackbar(AssessmentRepeat);
