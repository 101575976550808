import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { labels } from "../../../../shared/translations";
// material ui
// models
import {
  deleteGenericRiskFactor,
  getGenericRiskFactorList,
} from "../../../../../models/actions/GenericRiskFactor";
import { getDysfunctionsCategoriesByDisorderTypeAndStructure } from "../../../../../models/actions/Pathologies";
// components
import { styled } from "../../../../../components/styled";
import { theme } from "../../../../../components/theme";
// atoms
import { RASImpactedSection } from "./atoms/genericRiskFactorCompAdd_RASImpactedSection";
import { GRFInfoSection } from "./atoms/genericRiskFactorCompAdd_GRFInfoSection";
import { RuleSection } from "./atoms/genericRiskFactorCompAdd_ruleSection";
import SpButton from "../../../../../components/atoms/SpButton";
//utils
import {
  collectRFAndSend,
  calculateRuleText,
} from "../../genericRiskFactorFunction";
import { GRFScore } from "./atoms/genericRiskFactorCompAdd_Score";

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: theme.colors.primary.grey,
  borderColor: theme.colors.primary.lightBlue,
  border: "1px solid",
  "&:disabled": {
    color: theme.colors.primary.lightBlue,
  },
});

export const ComponentAddRF = ({
  selectedRf,
  listRegions,
  listAreas,
  listStructures,
  setLoading,
  rfList,
  setRfList,
  snackbarShowMessage,
  snackbarShowErrorMessage,
  setGeneralRiskList,
}) => {
  const [inputNameRef, setInputNameRef] = useState("");
  const [impactRef, setImpactRef] = useState(1);
  const [weightRef, setWeightRef] = useState(1);
  const [areaNotes, setAreaNotes] = useState("");
  const [areaReferences, setAreaReferences] = useState("");
  const [thresholdRef, setThresholdRef] = useState("");
  const [thresholdRef2, setThresholdRef2] = useState("");
  const [scores, setScores] = useState([]);
  const [selectedScore, setSelectedScore] = useState([]);
  const [dysfunctions, setDysfunctions] = useState([]);
  const [dysfunctionsType, setDysfunctionsType] = useState([]);
  const [dysfunctionCat, setDysfunctionCat] = useState([]);

  const [inputValues, setInputValues] = useState({
    sportSelected: null,
    sexSelected: null,
    rfStructure: null,
    intrinsicSelected: null,
    modifiableSelected: null,
    rfStructureName: null, //only used to refresh GRF table without DB call
    rfDysfunction: null,
    rfDysfunctionType: null,
    rfDysfunctionTypeName: null,
  });
  const [filterLocks, setFilterLocks] = useState({
    nameToggle: false,
    sportToggle: false,
    sexToggle: false,
    significativeToggle: false,
    intrinsicToggle: false,
    modifiableToggle: false,
    rfStructureToggle: false,
    rfDysfunctionToggle: false,
    rfDysfunctionTypeToggle: false,
    impListToggle: false,
    rulesListToggle: false,
  });
  const [impactedZone, setImpactedZone] = useState({
    region: null,
    area: null,
    structure: null,
    percentage: null,
    weight: null,
  });
  const [zonesImpacted, setZonesImpacted] = useState([]);
  const [ruleInputs, setRuleInputs] = useState({
    anatomicalParts: null,
    parameter: null,
    subparameter: null,
    logical: null,
  });
  const [ruleAdded, setRuleAdded] = useState([]);

  useEffect(() => {
    if (selectedRf && selectedRf?.name_rf) {
      setInputNameRef(selectedRf?.name_rf ? selectedRf?.name_rf : "");

      const categories = dysfunctionCat.filter(
        ({ id_dysfunction_category }) =>
          id_dysfunction_category ===
          selectedRf?.structure_dysfunction?.dysfunction?.dysfunction_category
            ?.id
      );
      setDysfunctionsType(categories);
      setInputValues({
        ...inputValues,
        sportSelected: selectedRf?.sport ? selectedRf?.sport : "",
        sexSelected: selectedRf?.sex ? selectedRf?.sex : "",
        intrinsicSelected: selectedRf?.is_intrinsic,
        modifiableSelected: selectedRf?.is_modifiable,
        rfStructure: selectedRf?.structure?.id ? selectedRf?.structure?.id : "",
        rfStructureName: selectedRf?.structure?.name
          ? selectedRf?.structure?.name
          : "",
        rfDysfunction: selectedRf?.structure_dysfunction?.dysfunction
          ?.dysfunction_category?.id
          ? selectedRf?.structure_dysfunction?.dysfunction?.dysfunction_category
              ?.id
          : "",
        rfDysfunctionType: selectedRf?.structure_dysfunction?.dysfunction?.id
          ? selectedRf?.structure_dysfunction?.dysfunction?.id
          : "",
      });
      setAreaNotes(selectedRf?.note ? selectedRf?.note : "");
      setAreaReferences(selectedRf?.reference ? selectedRf?.reference : "");
      setScores(selectedRf?.risk_score ? selectedRf?.risk_score : []);
      if (selectedRf?.rules) {
        const item = selectedRf?.rules.map((rule) => ({
          test: rule?.test,
          logical: rule?.logical,
          param: rule?.param,
          subparam: rule?.subparam,
          type: rule?.type,
          threshold: rule?.threshold,
          thresholdRef2: rule?.thresholdRef2,
          rule: rule?.rule
            ? rule.rule
            : calculateRuleText(
                rule?.test ? "test" : null,
                {
                  logical: rule?.logical,
                  test: { name: rule?.test },
                  parameter: rule?.param,
                  subparameter: rule?.subparam,
                },
                rule?.threshold,
                rule?.thresholdRef2
              ),
        }));
        setRuleAdded(item);
      } else {
        setRuleAdded([]);
      }

      if (selectedRf?.impacted_zones) {
        setZonesImpacted(
          selectedRf?.impacted_zones.map((item) => ({
            area: item?.area,
            percentage: item?.percentage,
            region: item?.region,
            structure: item?.structure,
            weight: item?.weight,
            zone: item?.zone,
          }))
        );
      } else {
        setZonesImpacted([]);
      }
    }
  }, [selectedRf, dysfunctionCat]);

  useEffect(() => {
    const fetchDysfunctions = async () => {
      if (inputValues?.rfStructure) {
        const result =
          await getDysfunctionsCategoriesByDisorderTypeAndStructure({
            id_structure: inputValues.rfStructure,
            id_disorder_type: 1,
          });

        const mappedResult = result.map(({ dysfunction }) => dysfunction);
        setDysfunctionCat(mappedResult);

        // filter for unique values of dysfunction category
        const unique = mappedResult.filter(
          ({ id_dysfunction_category }, index, array) =>
            array
              .map(({ id_dysfunction_category: idDysfCat }) => idDysfCat)
              .indexOf(id_dysfunction_category) === index
        );
        setDysfunctions(unique);
      }
    };

    fetchDysfunctions();
  }, [inputValues.rfStructure]);

  useEffect(() => {
    const categories = dysfunctionCat.filter(
      ({ id_dysfunction_category }) =>
        id_dysfunction_category === inputValues.rfDysfunction
    );
    setDysfunctionsType(categories);
  }, [inputValues.rfDysfunction]);

  const saveRf = async () => {
    setLoading(true);
    const save = async () => {
      const resultSend = await collectRFAndSend({
        rfnameRef: inputNameRef,
        inputs: inputValues,
        locks: filterLocks,
        setInputValues: setInputValues,
        impactedZone: impactedZone,
        setImpactedZone: setImpactedZone,
        impactRef: impactRef,
        setImpactRef: setImpactRef,
        weightRef: weightRef,
        setWeightRef: setWeightRef,
        zones: zonesImpacted,
        setZones: setZonesImpacted,
        ruleInputs: ruleInputs,
        setRuleInputs: setRuleInputs,
        thresholdRef: thresholdRef,
        setThresholdRef: setThresholdRef,
        thresholdRef2: thresholdRef2,
        setThresholdRef2: setThresholdRef2,
        rules: ruleAdded,
        setRules: setRuleAdded,
        notesRef: areaNotes,
        referencesRef: areaReferences,
        snackbarShowMessage: snackbarShowMessage,
        snackbarShowErrorMessage: snackbarShowErrorMessage,
        scores: scores,
      });
      if (Object.keys(resultSend).length > 0) {
        const newRf = {
          id: resultSend?.rfData.id,
          name_rf: resultSend?.rfData.name,
          sport: resultSend?.rfData.sport,
          sex: resultSend?.rfData.sex,
          note: resultSend?.rfData.notes,
          reference: resultSend?.rfData.reference,
          is_intrinsic: resultSend?.rfData.intrinsic ? 1 : 0,
          is_modifiable: resultSend?.rfData.modifiable ? 1 : 0,
          impacted_zones: resultSend?.rfData?.zones,
          id_structure_dysfunction: -999,
          structure_dysfunction: {
            dysfunction: { name: inputValues?.rfDysfunctionTypeName },
            structure: { name: inputValues?.rfStructureName },
          },
          rules: resultSend?.rfData.rules,
        };
        setRfList([...rfList, newRf]);
      }
    };
    if (selectedRf?.id) {
      const resDelete = await deleteGenericRiskFactor({
        id: selectedRf.id,
      });
      if (resDelete.message) {
        await save();
      }
    } else {
      await save();
    }

    setLoading(false);
  };

  return (
    <StyledGridContainer key="">
      <Grid container direction="row" spacing={2} alignItems="center">
        <GRFInfoSection
          snackbarShowErrorMessage={snackbarShowErrorMessage}
          snackbarShowMessage={snackbarShowMessage}
          structures={listStructures}
          dysfunctions={dysfunctions}
          dysfunctionsType={dysfunctionsType}
          inputNameRef={inputNameRef}
          setInputNameRef={setInputNameRef}
          inputValues={inputValues}
          setInputValues={setInputValues}
          filterLocks={filterLocks}
          setFilterLocks={setFilterLocks}
          areaNotes={areaNotes}
          setAreaNotes={setAreaNotes}
          areaReferences={areaReferences}
          setAreaReferences={setAreaReferences}
        />
        <RuleSection
          snackbarShowErrorMessage={snackbarShowErrorMessage}
          snackbarShowMessage={snackbarShowMessage}
          inputNameRef={inputNameRef}
          setInputNameRef={setInputNameRef}
          inputValues={inputValues}
          setInputValues={setInputValues}
          structures={listStructures}
          dysfunctions={dysfunctions}
          dysfunctionsType={dysfunctionsType}
          filterLocks={filterLocks}
          ruleInputs={ruleInputs}
          setRuleInputs={setRuleInputs}
          thresholdRef={thresholdRef}
          setThresholdRef={setThresholdRef}
          thresholdRef2={thresholdRef2}
          setThresholdRef2={setThresholdRef2}
          ruleAdded={ruleAdded}
          setRuleAdded={setRuleAdded}
          setFilterLocks={setFilterLocks}
        />

        <GRFScore
          ruleAdded={ruleAdded}
          ruleInputs={ruleInputs}
          setRuleInputs={setRuleInputs}
          scores={scores}
          setScores={setScores}
          selectedScore={selectedScore}
          setSelectedScore={setSelectedScore}
        />

        <RASImpactedSection
          snackbarShowErrorMessage={snackbarShowErrorMessage}
          snackbarShowMessage={snackbarShowMessage}
          impactRef={impactRef}
          setImpactRef={setImpactRef}
          weightRef={weightRef}
          setWeightRef={setWeightRef}
          impRegions={listRegions}
          impAreas={listAreas}
          impStructures={listStructures}
          impactedZone={impactedZone}
          setImpactedZone={setImpactedZone}
          zonesImpacted={zonesImpacted}
          setZonesImpacted={setZonesImpacted}
          inputValues={inputValues}
          setInputValues={setInputValues}
          filterLocks={filterLocks}
          setFilterLocks={setFilterLocks}
        />
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1.5%",
          }}
        >
          <SpButton
            id="addRiskFactorBtn"
            buttonType="accept"
            text={labels.mylab.generalRiskFactor.buttonAddRF}
            onClick={async () => {
              await saveRf();
              const res = await getGenericRiskFactorList();
              setGeneralRiskList(res);
              setRuleAdded([]);
              setInputNameRef("");
              setAreaNotes("");
              setAreaReferences("");
            }}
            variant="h1"
          />
        </Grid>
      </Grid>
    </StyledGridContainer>
  );
};
