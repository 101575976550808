import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import { SpStepper } from "../../components/bundles";
import { styled } from "../../components/styled";
import { findPatientByPk } from "../../models/actions/Patients";
import {
  closePresentation,
  deletePresentationById,
  getExitCriteriaByPresentation,
  getPresentationById,
} from "../../models/actions/Presentation";
import {
  getUserPermission,
  isFeatureFlagEnabled,
  useQueryParams,
} from "../../utils/common";
import { labels, psTranslate } from "../shared/translations";
import PatientsPresentationDetailStep0 from "./patientsPresentationDetail/PatientsPresentationDetailSummary";

import PatientsPresentationDetailStep1 from "./patientsPresentationDetail/PatientsPresentationDetailBodyMap";
import PatientsPresentationDetailStep2 from "./patientsPresentationDetail/PatientsPresentationDetailExitCriteria";
import PatientsPresentationDetailStep3 from "./patientsPresentationDetail/PatientsPresentationDetailMilestones";
import PatientsPresentationDetailStep4 from "./patientsPresentationDetail/PatientsPresentationDetailSins";
import PatientsPresentationDetailStep5 from "./patientsPresentationDetail/PatientsPresentationDetailAssessment";
import PatientsPresentationDetailStep6 from "./patientsPresentationDetail/PatientsPresentationDetailMainProms";
import PatientsPresentationDetailStep7 from "./patientsPresentationDetail/PatientsPresentationDetailInterview";
import PatientsPresentationDetailStep8 from "./patientsPresentationDetail/PatientsPresentationResources";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { theme } from "../../components/theme";
import { useAuth0 } from "@auth0/auth0-react";
import SpLoader from "../../components/atoms/SpLoader";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  // paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const feedbackArray = [
  { id: 1, answer: labels.patient.presentation.feedbackArray[0] },
  { id: 2, answer: labels.patient.presentation.feedbackArray[1] },
  { id: 3, answer: labels.patient.presentation.feedbackArray[2] },
  { id: 4, answer: labels.patient.presentation.feedbackArray[3] },
  { id: 5, answer: labels.patient.presentation.feedbackArray[4] },
];

const PatientsPresentationDetail = (props) => {
  const [currentPresentation, setCurrentPresentation] = useState();
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [openExitCriteria, setOpenExitCriteria] = useState(false);
  const [openDeletePres, setOpenDeletePres] = useState(false);
  const [presentationRegion, setPresentationRegion] = useState(null);
  const [exitCriteriaList, setExitCriteriaList] = useState([]);
  const [enableInjuryDetail, setEnableInjuryDetail] = useState(null);
  const [enableInjuryInterview, setEnableInjuryInterview] = useState(false);
  const [enableInjurySins, setEnableInjurySins] = useState(false);
  const [loading, setLoading] = useState(false);

  const query = useQueryParams();
  const initialStep = query.get("assessments")
    ? enableInjuryDetail
      ? 3
      : 2
    : 0;

  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: theme.colors.primary.lightBlue,
  };
  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const { register, errors, control, handleSubmit, reset } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const injuryDetailEnabled = await getUserPermission(
        "injury-detail",
        getAccessTokenSilently
      );
      setEnableInjuryDetail(injuryDetailEnabled);

      const interviesEnabled = await isFeatureFlagEnabled("injury-interview");
      setEnableInjuryInterview(interviesEnabled);
      const sinsEnabled = await isFeatureFlagEnabled("injury-sins");
      setEnableInjurySins(sinsEnabled);
      const presentationResult = await getPresentationById({
        id_presentation: presentationId,
        id_patient: patId,
      });
      setCurrentPresentation(presentationResult);
      setPresentationRegion(presentationResult.region);
      const responseDetail = await findPatientByPk({ userId: patId });
      await fetchExitCriteria();
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchExitCriteria = async () => {
    const results = await getExitCriteriaByPresentation({
      id_presentation: presentationId,
      id_patient: patId,
    });
    results?.exit_criteria_answers?.filter((elem) => !elem.satisfied);
    setExitCriteriaList(results?.exit_criteria_answers);
    reset({ exitCriteria: results?.exit_criteria_answers });
  };

  useEffect(() => {
    fetchExitCriteria();
  }, [openExitCriteria]);

  const endPresentation = async (data) => {
    await closePresentation({
      ...data,
      id_presentation: presentationId,
      id_patient: patId,
    });
    history.push(`/patients/edit/${patId}/patientsInjuryIllness`);
  };

  let bodyPartName = "";
  if (currentPresentation?.structure?.name) {
    bodyPartName = `${psTranslate(currentPresentation?.structure?.name)}`;
  } else {
    if (currentPresentation?.area?.name) {
      bodyPartName = `${psTranslate(currentPresentation?.area?.name)}`;
    } else {
      if (currentPresentation?.region?.name) {
        bodyPartName = `${psTranslate(currentPresentation?.region?.name)}`;
      }
    }
  }

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    (errors) =>
    ({ previous, next, index, isLastStep }) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      );

  const stepFiltered = labels.patient.presentation.steps.filter(
    ({ key }) =>
      (key !== "interview" && key != "sins" && key !== "detail") ||
      (key === "interview" && enableInjuryInterview) ||
      (key === "sins" && enableInjurySins) ||
      (key === "detail" && enableInjuryDetail)
  );

  const titles = stepFiltered.map(({ key, value }) => (
    <StepTitle key={key}>{value}</StepTitle>
  ));

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item container xs={12}>
            <StyledGridContainer container spacing={1}>
              <StyledBodyColumn>
                <StyledGridContainer container spacing={1}>
                  <Grid
                    container
                    item
                    direction="row"
                    xs={12}
                    spacing={2}
                    className="gridstepper"
                  >
                    {enableInjuryDetail !== null && (
                      <SpStepper
                        initialStep={initialStep}
                        titles={titles}
                        stepperControls={StepperControls(errors)}
                      >
                        {enableInjuryDetail && (
                          <PatientsPresentationDetailStep0
                            register={register}
                            control={control}
                            endDate={currentPresentation?.end_date}
                          />
                        )}
                        <PatientsPresentationDetailStep1
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        {enableInjuryInterview && (
                          <PatientsPresentationDetailStep7
                            register={register}
                            control={control}
                            {...props}
                            endDate={currentPresentation?.end_date}
                            presentationInterviewId={
                              currentPresentation?.id_interview
                            }
                          />
                        )}
                        <PatientsPresentationDetailStep5
                          {...props}
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep6
                          register={register}
                          control={control}
                          {...props}
                          endDate={currentPresentation?.end_date}
                        />
                        {enableInjurySins && (
                          <PatientsPresentationDetailStep4
                            register={register}
                            control={control}
                            endDate={currentPresentation?.end_date}
                          />
                        )}
                        <PatientsPresentationDetailStep3
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep2
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep8
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                      </SpStepper>
                    )}
                  </Grid>
                </StyledGridContainer>
              </StyledBodyColumn>
            </StyledGridContainer>
          </Grid>
        </Grid>
      </Grid>
      <SpDialog
        open={openExitCriteria}
        setOpen={setOpenExitCriteria}
        title={labels.patient.presentation.detail.exitCriteriaDialog.title}
      >
        <form onSubmit={handleSubmit(endPresentation)}>
          <Grid container direction="column">
            {exitCriteriaList.map((exitCriteria, idx) => (
              <Grid
                container
                direction="row"
                spacing={3}
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <SpTextInput
                    disabled
                    label={
                      labels.patient.presentation.detail.stepper.step2
                        .inputLabel
                    }
                    value={exitCriteria?.name}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={7} direction="row">
                  <Controller
                    as={
                      <RadioGroup
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          minWidth: 300,
                        }}
                      >
                        <FormControlLabel
                          style={formControlStyleCheck}
                          value="false"
                          control={<RedRadio />}
                          label={labels.patient.presentation.detail.stepper.step2.notSatisfied.toUpperCase()}
                        />
                        <FormControlLabel
                          id="deleteTrue"
                          style={formControlStyleCheck}
                          value="true"
                          control={<GreenRadio />}
                          label={labels.patient.presentation.detail.stepper.step2.satisfied.toUpperCase()}
                        />
                      </RadioGroup>
                    }
                    name={`exitCriteria[${idx}].satisfied`}
                    control={control}
                    defaultValue={exitCriteria.satistified}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} style={{ marginBottom: "4%", marginTop: "4%" }}>
              <Controller
                style={{ width: "100%", marginBottom: "2%" }}
                render={(props) => (
                  <SpSelect
                    label={labels.patient.presentation.detail.feedbackSelect}
                    value={props.value}
                    formControlStyle={{ width: "90%", maxWidth: 400 }}
                    formControlWidth={200}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  >
                    {feedbackArray.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.answer}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={0}
                name={`valutation`}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <SpButton
                buttonType="accept"
                type="submit"
                text={labels.patient.presentation.detail.closePresentation}
                variant="h1PageTitle"
              />
            </Grid>
          </Grid>
        </form>
      </SpDialog>
      <SpDialog
        open={openDeletePres}
        setOpen={setOpenDeletePres}
        title={labels.patient.presentation.detail.presentationDelete.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.patient.presentation.detail.presentationDelete.content}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                id="deleteTrue"
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={() => deletePresentation()}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenDeletePres(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetail);
