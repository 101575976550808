import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { labels, psTranslate } from "../../../../shared/translations";
// material ui
// models
// components
// atoms
import { SpAutocomplete } from "../../../../../components/atoms/SpAutocomplete";
import SpButton from "../../../../../components/atoms/SpButton";
import SpSearch from "../../../../../components/atoms/SpSearch";
// utils

const FiltersToolbar = () => {
  const regions = [],
    areas = [],
    structures = [];

  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [structureSelected, setStructureSelected] = useState(null);

  return (
    <Grid item xs={12} container alignItems={"center"}>
      <Grid item xs={9} container direction="row">
        <Grid item xs={4}>
          <SpAutocomplete
            style={{ marginRight: "1%" }}
            id="regionAutocomplete"
            formControlWidth={"100%"}
            value={regions.find(({ key }) => key == regionSelected?.key) ?? ""}
            displayLabel={false}
            selectPlaceholder={labels.patient.presentation.add.selectRegion}
            onChange={(e, newValue) => {
              setRegionSelected(newValue);
            }}
            options=""
            getOptionLabel={({ name }) => psTranslate(name)}
            getOptionSelected={({ id: id_opt }, { id: id_value }) =>
              id_opt === id_value
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SpAutocomplete
            style={{ marginRight: "1%", marginLeft: "1%" }}
            id="areaAutocomplete"
            formControlWidth={"100%"}
            value={areas.find(({ key }) => key == areaSelected?.key) ?? ""}
            displayLabel={false}
            selectPlaceholder={labels.patient.presentation.add.selectArea}
            onChange={async (e, newValue) => {
              await updateRegion({
                newRegion: newValue,
                regions,
                setRegionSelected,
              });
              setAreaSelected(newValue);
            }}
            options={areas}
            getOptionLabel={({ name }) => psTranslate(name)}
            getOptionSelected={({ id: id_opt }, { id: id_value }) =>
              id_opt === id_value
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SpAutocomplete
            style={{ marginLeft: "1%" }}
            id="structureAutocomplete"
            formControlWidth={"100%"}
            value={
              structures.find(({ id }) => id == structureSelected?.id) ?? ""
            }
            displayLabel={false}
            selectPlaceholder={labels.patient.presentation.add.selectStructure}
            onChange={async (e, newValue) => {
              await updateArea({
                newArea: newValue,
                regions,
                areas,
                setAreaSelected,
                setRegionSelected,
              });
              setStructureSelected(newValue);
            }}
            options={structures}
            getOptionLabel={({ name }) => psTranslate(name)}
            getOptionSelected={({ id: id_opt }, { id: id_value }) =>
              id_opt === id_value
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SearchToolbar = () => {
  return (
    <Grid container xs={12}>
      <Grid item xs={9}>
        <SpSearch></SpSearch>
      </Grid>
      <Grid item xs={3} container>
        <Grid
          item
          xs={12}
          style={{
            marginLeft: "2%",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <SpButton
            style={{
              width: "100%",
            }}
            buttonType="accept"
            text={labels.mylab.generalRiskFactor.searchComp.findAll}
            onClick={() => {}}
            variant="h1"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ComponentToolbarSearchRF = () => {
  return (
    <Grid xs={12} container>
      <Grid item xs={12} style={{ marginTop: "1%" }}>
        <SearchToolbar />
      </Grid>
    </Grid>
  );
};
