import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import PatientsResourcesMedical from "./PatientsResourcesMedical";
import PatientsResourcesPhysio from "./PatientsResourcesPhysio";
import { getUserPermission } from "../../utils/common";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const PatientsMedicalResourceInternalMenu = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [isMedicalResourcesEnabled, setIsMedicalResourcesEnabled] =
    useState(false);
  const [isPhysioResourcesEnabled, setIsPhysioResourcesEnabled] =
    useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();
  const [spMenuLabels, setspMenuLabels] = useState([]);

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  //Fetching patient data
  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  useEffect(async () => {
    let tmpLabels = labels.patient.medicalResourceInternalMenu.steps;
    const tempMedicalResourcesPermission = await getUserPermission(
      "manage:medical-resources",
      getAccessTokenSilently
    );
    const tempPhysioResourcesPermission = await getUserPermission(
      "manage:physio-resources",
      getAccessTokenSilently
    );

    setIsMedicalResourcesEnabled(tempMedicalResourcesPermission);
    setIsPhysioResourcesEnabled(tempPhysioResourcesPermission);

    if (!tempMedicalResourcesPermission)
      tmpLabels = tmpLabels.filter((item) => item !== "Risorse Mediche");
    if (!tempPhysioResourcesPermission)
      tmpLabels = tmpLabels.filter((item) => item !== "Risorse Fisioterapiche");
    setspMenuLabels(tmpLabels.map((t) => <StepTitle>{t}</StepTitle>));
  }, []);

  return (
    <div>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
              sectionName={labels.patient.sideBarPatEdit.medicalRis.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12} container spacing={2} direction="row">
            <StyledGridContainer container spacing={1}>
              <SpStepper
                titles={spMenuLabels}
                stepperControls={StepperControls(errors)}
              >
                {isMedicalResourcesEnabled && (
                  <PatientsResourcesMedical
                    register={register}
                    control={control}
                    setLoading={setLoading}
                  />
                )}
                {isPhysioResourcesEnabled && (
                  <PatientsResourcesPhysio
                    register={register}
                    control={control}
                    setLoading={setLoading}
                  />
                )}
              </SpStepper>
            </StyledGridContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withSnackbar(PatientsMedicalResourceInternalMenu);
