import axios from "axios";
import { call } from "./utils";
import { rollbar } from "../../utils/common";

export const loginProfessional = async (data) => {
  return await axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/auth/signin/professional", {
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //catch error
      rollbar.error("loginProfessional", JSON.stringify(err));

      if (err.response.status === 500)
        return Promise.reject("Errore server, contattare l'assistenza!");
      return err.response;
    });
};

export const loginCompany = async (data) => {
  return await axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/auth/signin/company", {
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      rollbar.error("loginCompany", JSON.stringify(err));

      //catch error
      if (err.response.status === 500)
        return Promise.reject("Errore server, contattare l'assistenza!");
      return err.response;
    });
};

export const registerProfessional = async (data) => {
  return await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/api/auth/signup/professional",
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      //catch error
      rollbar.error("registerProfessional", JSON.stringify(err));

      if (err.response.status === 500)
        return Promise.reject("Errore server, contattare l'assistenza!");
      return err.response;
    });
};

export const changePassword = async (data) => {
  return await axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/auth/change-password", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      rollbar.error("changePassword", JSON.stringify(err));

      //catch error
      if (err.response.status === 500)
        return Promise.reject("Errore server, contattare l'assistenza!");
      return err.response;
    });
};

export const logoutProfessional = async (data) =>
  await call({ url: "/auth/signout/professional", data: { data } });
