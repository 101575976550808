import React from "react";
import AddMonitoringAssessment from "../../../../components/bundles/assessment/AddMonitoringAssessment";
import SpButton from "../../../../components/atoms/SpButton";
import { labels } from "../../../shared/translations";

const AssessmentAdd = ({ date, close, setLoading, idTemplate }) => {
  return (
    <>
      <SpButton
        buttonType="accept"
        style={{ maxWidth: "25%", marginBottom: "10px" }}
        onClick={() => close()}
        text={labels.patient.monitoring.closeAddAssessment}
      />
      <AddMonitoringAssessment
        setLoading={setLoading}
        assessmentDetail={null}
        isRepeatAssessment={false}
        isRepeatFromTemplate={false}
        closeAssessmentAdd={close}
        selectedCategoriesObj={null}
        date={date}
        idTemplate={idTemplate}
      />
    </>
  );
};

export default AssessmentAdd;
