import { createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import SpExerciseDialog from "../../../../components/atoms/SpExerciseDialog";
import { SpList } from "../../../../components/bundles/SpList";
import { styled } from "../../../../components/styled";
import { theme } from "../../../../components/theme";
import {
  getAllExerciseCategories,
  getAllExercises,
} from "../../../../models/actions/Excercise";
import ExerciseSheetRow from "./Single/ExerciseSheetRow";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import { labels, psTranslate } from "../../../shared/translations";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const ExerciseSheetRows = ({ isPrint, rows, updateRow, ...props }) => {
  const [exercisesList, setExercisesList] = useState([]);
  const [exerciseCategoriesList, setExerciseCategoriesList] = useState([]);

  const [exerciseDetail, setExercisesDetail] = useState(null);
  const [dialogExerciseOpen, setDialogExerciseOpen] = useState(false);

  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) => option.name + option.tags,
  });

  useEffect(async () => {
    try {
      const [exercises, exerciseCats] = await Promise.all([
        getAllExercises(),
        getAllExerciseCategories(),
      ]);
      exerciseCats.push({
        id: null,
        name: labels.mylab.tempDetail.toolbar.uncategorized,
      });
      exercises.sort((a, b) =>
        psTranslate(a.name).localeCompare(psTranslate(b.name))
      );
      setExerciseCategoriesList(exerciseCats);
      setExercisesList(exercises);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <StyledRow
      style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
    >
      <SpList rows={rows} rowKey="id" checkable={false} deletable={false}>
        <ExerciseSheetRow
          isPrint={isPrint}
          exercisesList={exercisesList}
          exerciseCategoriesList={exerciseCategoriesList}
          updateRow={updateRow}
          setExercisesDetail={setExercisesDetail}
          setDialogExerciseOpen={setDialogExerciseOpen}
          filterOptions={filterOptions}
        />
      </SpList>
      <SpExerciseDialog
        isPrint={isPrint}
        dialogExerciseOpen={dialogExerciseOpen}
        setDialogExerciseOpen={setDialogExerciseOpen}
        exerciseDetail={exerciseDetail}
      />
    </StyledRow>
  );
};

export default withSnackbar(ExerciseSheetRows);
