import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../App.css";
import { labels } from "./shared/translations";
// material ui
import { Avatar, Grid, TableCell, makeStyles } from "@material-ui/core";
// models
import { getPatientsByProfessionalDetails } from "../models/actions/Professionals";
import { getPatientsWithUncheckedAlerts } from "../models/actions/PatientAlerts";
// components
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import SpSearch from "../components/atoms/SpSearch";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import { withSnackbar } from "../components/atoms/SpSnackBar";
// atoms
// utils
import {
  controlRoomStep,
  getLabelRangeFromPatientStatus,
  getUserPermission,
  riskScoreThreshold,
} from "../utils/common";
import SpDialog from "../components/atoms/SpDialog";
import PatientInjuryRiskDetails from "./patients/patientInjuryRiskDetails";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BasicPatients,
  headCellsDefault,
  headCellsNoRisk,
  headCellsWrestlingDefault,
  headCellsWrestlingNoRisk,
  PatientRequestRow,
  PatientRequestRowWrestling,
} from "./PatientsHelper";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

// Columns

const Patients = (props) => {
  const history = useHistory();
  const [patientList, setPatientsList] = useState([]);
  const [displayPatients, setDisplayPatients] = useState();
  const [patIdUnchekedAlert, setPatIdUnchekedAlert] = useState([]);
  const [patIdChekedAlert, setPatIdChekedAlert] = useState([]);
  const [searchString, setSearchString] = useState();
  const [dialogRedFlagOpen, setDialogRedFlagOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [isChartEnabled, setIsChartEnabled] = useState(false);
  const [isGroupEnabled, setIsGroupEnabled] = useState(false);
  const [isBasicPatientListEnabled, setIsBasicPatientListEnabled] =
    useState(false);
  const [isAdvancedPatientListEnabled, setIsAdvancedPatientListEnabled] =
    useState(false);
  const [isAlertEnabled, setIsAlertEnabled] = useState(false);
  const [headCells, setHeadCells] = useState(headCellsNoRisk);
  const [headCellsWrestling, setHeadCellsWrestling] = useState(
    headCellsWrestlingNoRisk
  );
  const [isPatientLabel, setIsPatientLabel] = useState(false);

  const { setLoading } = props;
  const classes = useStyles();

  const filterPatients = (e) => {
    setSearchString(e.currentTarget.value);
    let newDisplayFilters = patientList?.filter((patient) =>
      `${patient?.givenName} ${patient?.familyName}`
        .toString()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().toLowerCase())
    );
    setDisplayPatients(newDisplayFilters);
    if (e.currentTarget.value === "") setDisplayPatients(patientList);
  };
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      setLoading(true);

      let response = await getPatientsByProfessionalDetails();
      setPatientsList(response);
      setDisplayPatients(response);
      const resultAlerts = await getPatientsWithUncheckedAlerts({
        ids: response.map((atl) => atl?.id),
      });

      const patientLabelTemp = await getUserPermission(
        "patient-label",
        getAccessTokenSilently
      );
      setIsPatientLabel(patientLabelTemp);

      const tempChartPermission = await getUserPermission(
        "manage:chart",
        getAccessTokenSilently
      );
      setIsChartEnabled(tempChartPermission);
      const tempGroupPermission = await getUserPermission(
        "manage:groups",
        getAccessTokenSilently
      );
      setIsGroupEnabled(tempGroupPermission);
      const tempAlertPermission = await getUserPermission(
        "manage:alert",
        getAccessTokenSilently
      );
      setIsAlertEnabled(tempAlertPermission);
      const tempBasiPatientList = await getUserPermission(
        "patient-list-default",
        getAccessTokenSilently
      );
      setIsBasicPatientListEnabled(tempBasiPatientList);
      const tempAdvancedPatientList = await getUserPermission(
        "patient-list-advanced",
        getAccessTokenSilently
      );
      setIsAdvancedPatientListEnabled(tempAdvancedPatientList);
      if (tempAlertPermission) {
        setHeadCells(headCellsDefault);
        setHeadCellsWrestling(headCellsWrestlingDefault);
      }
      setPatIdUnchekedAlert(resultAlerts?.uncheckedIds);
      setPatIdChekedAlert(resultAlerts?.checkedIds);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          container
          xs={12}
          direction="row"
          spacing={3}
          alignItems="center"
        >
          <Grid item xs={4}>
            <SpText variant="h1" id={"backButtonLista"}>
              {isPatientLabel
                ? labels.nav.patients
                : labels.patient.component.backButtonLista}
            </SpText>
          </Grid>
          <Grid
            item
            container
            xs={8}
            direction="row"
            spacing={2}
            style={{ justifyContent: "flex-end" }}
          >
            <SpButton
              id={"patientCreate"}
              buttonType={"accept"}
              onClick={() => {
                history.push("/patients/create");
              }}
              text={
                isPatientLabel
                  ? labels.patient.list.addPatient
                  : labels.patient.list.addAthlete
              }
            />
            <SpButton
              id={"patientLink"}
              buttonType={"accept"}
              onClick={() => {
                history.push("/patients/link");
              }}
              style={{ marginLeft: "5px" }}
              text={
                isPatientLabel
                  ? labels.patient.list.linkPatient
                  : labels.patient.list.linkAthlete
              }
            />
            <SpSearch
              id={"patientSearch"}
              onChange={(e) => {
                filterPatients(e);
              }}
              value={searchString}
              style={{
                marginLeft: "5px",
                marginRight: 0,
              }}
            />
          </Grid>
        </Grid>
        {isBasicPatientListEnabled && (
          <BasicPatients displayPatients={displayPatients} />
        )}
        {isAdvancedPatientListEnabled && (
          <>
            {displayPatients && displayPatients?.length === 0 && (
              <Grid container item xs={12} style={{ marginTop: "1%" }}>
                <SpText variant="h1PageSubtitle">
                  {isPatientLabel
                    ? labels.patient.component.noPatients
                    : labels.patient.component.noAthletes}
                </SpText>
              </Grid>
            )}
            {displayPatients && displayPatients?.length > 0 && (
              <Grid item xs={12}>
                {displayPatients.filter(
                  ({ sports }) =>
                    sports.length == 0 ||
                    (!sports.map(({ code }) => code).includes("WRE") &&
                      !sports.map(({ code }) => code).includes("JUD") &&
                      !sports.map(({ code }) => code).includes("KTE"))
                )?.length > 0 && (
                  <SpTable
                    sortable={true}
                    pagination={true}
                    headCells={headCells}
                    rows={displayPatients.filter(
                      ({ sports }) =>
                        sports.length == 0 ||
                        (!sports.map(({ code }) => code).includes("WRE") &&
                          !sports.map(({ code }) => code).includes("JUD") &&
                          !sports.map(({ code }) => code).includes("KTE"))
                    )}
                    rowKey="id"
                    padding={false}
                    notCheckable={false}
                  >
                    <PatientRequestRow
                      isAlertEnabled={isAlertEnabled}
                      isGroupEnabled={isGroupEnabled}
                      isChartEnabled={isChartEnabled}
                      patIdUnchekedAlert={patIdUnchekedAlert}
                      patIdChekedAlert={patIdChekedAlert}
                    />
                  </SpTable>
                )}
                {displayPatients.filter(
                  ({ sports }) =>
                    sports.map(({ code }) => code).includes("WRE") ||
                    sports.map(({ code }) => code).includes("JUD") ||
                    sports.map(({ code }) => code).includes("KTE")
                )?.length > 0 && (
                  <SpTable
                    sortable={true}
                    pagination={true}
                    headCells={headCellsWrestling}
                    rows={displayPatients.filter(
                      ({ sports }) =>
                        sports.map(({ code }) => code).includes("WRE") ||
                        sports.map(({ code }) => code).includes("JUD") ||
                        sports.map(({ code }) => code).includes("KTE")
                    )}
                    rowKey="id"
                    padding={false}
                    notCheckable={false}
                  >
                    <PatientRequestRowWrestling
                      isAlertEnabled={isAlertEnabled}
                      isGroupEnabled={isGroupEnabled}
                      isChartEnabled={isChartEnabled}
                      patIdUnchekedAlert={patIdUnchekedAlert}
                      patIdChekedAlert={patIdChekedAlert}
                    />
                  </SpTable>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
      <SpDialog
        open={dialogRedFlagOpen}
        setOpen={setDialogRedFlagOpen}
        title={labels.patient.table.header.risk}
        val={
          selectedRow?.injury_risk?.score !== undefined &&
          selectedRow?.injury_risk?.score !== null
            ? getLabelRangeFromPatientStatus({
                value: selectedRow.injury_risk.score,
                threshold1: riskScoreThreshold.low,
                threshold2: riskScoreThreshold.moderate,
                threshold3: riskScoreThreshold.hight,
              })
            : labels.patient.injuryRisk.low
        }
        valN={selectedRow?.injury_risk?.score}
        show_vals={true}
      >
        <Grid
          style={{ paddingLeft: "1%", width: "100%" }}
          direction="row"
          container
          spacing={2}
        >
          <PatientInjuryRiskDetails
            selectedRow={selectedRow}
            selectedStep={controlRoomStep.default}
          />
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(Patients);
