import React from "react";
import { rollbar } from "./utils/common";
import { withRouter, Redirect, Route, useHistory } from "react-router-dom";
import { ampli } from "./ampli";

class ErrorBoundaryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, componentError: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const user = localStorage.getItem("user");
    ampli.newError({
      errorDetails:
        this?.props?.location?.state?.componentName +
        " - " +
        error +
        errorInfo.componentStack,
    });

    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
      componentError: this?.props?.location?.state?.componentName
        ? this?.props?.location?.state?.componentName
        : "",
    });

    Rollbar.configure({
      checkIgnore: () => false,
    });
    rollbar.error(
      "userId: " +
        user?.name +
        " - component: " +
        this?.props?.location?.state?.componentName
        ? this?.props?.location?.state?.componentName
        : "",
      " - " + error + errorInfo.componentStack
    );
    Rollbar.configure({
      checkIgnore: () => true,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              error: this.state.error,
              errorInfo: this.state.errorInfo,
              componentError: this.state.componentError,
            },
          }}
        />
      );
    } else {
      let children = React.Children.map(this.props.children, (child, index) =>
        React.cloneElement(child, {
          componentName: this.props?.location?.state?.componentName,
        })
      );

      return children;
    }
  }
}

const exportObj =
  process.env.NODE_ENV !== "development"
    ? withRouter(ErrorBoundaryClass)
    : ({ children }) => <>{children}</>;
export default exportObj;
