import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { labels } from "../../shared/translations";
import PatientsEditSidebar from "../shared/PatientsEditSidebar";
import { Grid, TableCell } from "@material-ui/core";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import {
  getPatientAlertsList,
  setPatientAlertChecked,
} from "../../../models/actions/PatientAlerts";
import { findPatientByPk } from "../../../models/actions/Patients";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { DialogDetails } from "./components/PatientAlertDialogs";
import { ComponetFilterToolbar } from "./components/PatientAlertsFilterToolbar";
import moment from "moment";
import { rollbar } from "../../../utils/common";
import SpTooltip from "../../../components/atoms/SpTooltip";

const PatientsAlerts = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [alertList, setAlertList] = useState([]);
  const [alertToShow, setAlerttoShow] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedToCheck, setSelectedToCheck] = useState([]);

  const [checkboxState, setCheckboxState] = useState({
    all: true,
    unchecked: 0,
    checked: 0,
    modifiable: 0,
    notModifiable: 0,
  });

  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();

  const fetchData = async () => {
    try {
      setLoading(true);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      const response = await getPatientAlertsList({
        id: patId,
      });
      setAlertList([...response]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackShowErrorMessage(error);
    }
  };

  const isNotCheked = ({ row }) =>
    (row.is_checked === 0 &&
      selectedToCheck.find((a) => a.id === row.id) === undefined) ||
    (row.is_checked === 1 &&
      selectedToCheck.find((a) => a.id === row.id) !== undefined);

  const tempToCheck = (row) => {
    if (selectedToCheck.find((a) => a.id === row.id) === undefined) {
      setSelectedToCheck([
        ...selectedToCheck,
        { id: row.id, is_checked: row.is_checked ? 0 : 1 },
      ]);
    } else {
      selectedToCheck.splice(selectedToCheck.indexOf(row.id), 1);
      setSelectedToCheck([...selectedToCheck]);
    }
  };

  const updateMarkedAlerts = async ({ alerts }) => {
    setLoading(true);
    // note: vedere perché errore
    try {
      const resUpdate = await setPatientAlertChecked({ alerts: alerts });
      if (resUpdate.message) {
        props.snackbarShowMessage(resUpdate.message);
      }
      alerts.forEach((al) => {
        let upalert = (alertList.find((a) => a.id === al.id).is_checked =
          al.is_checked);
      });
      setAlertList([...alertList]);
      setSelectedToCheck([]);
      setLoading(false);
    } catch (e) {
      rollbar.error(`Error while updating alerts - ${e}`);
      props.snackbarShowErrorMessage("Error while updating alerts");
      setLoading(false);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  // Columns
  const headCells = [
    {
      id: "data_alert",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.dataAlert,
      isAction: false,
    },
    {
      id: "data_alert",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.threshold,
      isAction: false,
    },
    {
      id: "data_alert",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.value,
      isAction: false,
    },
    {
      id: "data_feedback",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.dataFeed,
      isAction: false,
    },
    {
      id: "checked",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.checked,
      isAction: false,
    },
    {
      id: "modifiable",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.modifiable,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.patient.patientAlerts.table.header.actions,
      isAction: true,
    },
  ];

  const PatientAlertRow = ({ row, index }) => (
    <SpTableRow
      role="checkbox"
      id={row.id}
      tabIndex={index}
      key={`patient-alert-${index}`}
    >
      <SpTableCell>
        <SpText variant="tableTextDetails">{row.alert_data?.name}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{row.alert_data?.threshold}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">{row.alert_data.answers}</SpText>
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">
          {moment(row.alert_data?.alert_activity_date).format("DD-MM-YYYY") ??
            moment(row.createdAt).format("DD-MM-YYYY")}
        </SpText>
      </SpTableCell>
      <SpTableCell>
        {isNotCheked({ row: row }) ? (
          <SpTooltip
            id={`alertIconTooltip-${index}`}
            title={labels.patient.patientAlerts.table.checkTooltip.false}
          >
            <NotificationImportantIcon
              id={`alertIcon-${index}`}
              style={{
                cursor: "default",
                color: "red",
              }}
            />
          </SpTooltip>
        ) : (
          <SpTooltip
            id={`alertIconTooltip-${index}`}
            title={labels.patient.patientAlerts.table.checkTooltip.true}
          >
            <NotificationsRoundedIcon
              id={`alertIcon-${index}`}
              style={{
                cursor: "default",
                color: "yellow",
              }}
            />
          </SpTooltip>
        )}
      </SpTableCell>
      <SpTableCell>
        <SpText variant="tableTextDetails">
          {row.alert_data?.is_modifiable === 1
            ? labels.general.yes
            : labels.general.no}
        </SpText>
      </SpTableCell>
      <TableCell
        align={"right"}
        style={{
          padding: 0,
          verticalAlign: "bottom",
          borderBottom: 0,
        }}
      >
        <SpButton
          variant={"standard"}
          type="tableList"
          text={labels.patient.patientAlerts.table.button.note}
          onClick={() => {
            setSelectedRow(row);
            setDialog(true);
          }}
        />
        <SpButton
          variant={"standardOpposite"}
          type="tableList"
          text={
            isNotCheked({ row: row })
              ? labels.patient.patientAlerts.table.button.mark.checked
              : labels.patient.patientAlerts.table.button.mark.unchecked
          }
          onClick={() => {
            tempToCheck(row);
          }}
        />
      </TableCell>
    </SpTableRow>
  );

  useEffect(() => {
    let tempAlertToShow = [];
    if (checkboxState.all) {
      tempAlertToShow = alertList;
    } else {
      if (checkboxState.checked === 1)
        tempAlertToShow = alertList.filter(
          ({ is_checked }) => is_checked === 1
        );
      if (checkboxState.unchecked === 1)
        tempAlertToShow = alertList.filter(
          ({ is_checked }) => is_checked === 0
        );
      if (checkboxState.modifiable === 1)
        tempAlertToShow = alertList.filter(
          ({ alert_data }) => alert_data?.is_modifiable === 1
        );
      if (checkboxState.notModifiable === 1)
        tempAlertToShow = alertList.filter(
          ({ alert_data }) => alert_data?.is_modifiable === 0
        );
    }

    setAlerttoShow(tempAlertToShow);
  }, [checkboxState, alertList]);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item container xs={12}>
            {/* PAGE TITLE LABEL */}
            <Grid item container xs={8}>
              <SpText variant="h1PageTitle">
                {labels.patient.patientAlerts.title.toUpperCase()}
              </SpText>
            </Grid>
            {/* SAVE MARKED ALERT BUTTON */}
            {selectedToCheck.length > 0 && (
              <Grid
                item
                container
                xs={4}
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <SpButton
                  id="saveCheckedBulkBtn"
                  style={{ marginLeft: "2%" }}
                  buttonType="accept"
                  text={labels.patient.patientAlerts.saveMarked}
                  onClick={() => {
                    // funzione al BE per fare bulkUpdate
                    updateMarkedAlerts({ alerts: selectedToCheck });
                  }}
                  variant="h1"
                />
              </Grid>
            )}
          </Grid>
          {/* FILTER TOOLBAR */}
          <Grid item container xs={12}>
            <ComponetFilterToolbar
              setCheckboxState={setCheckboxState}
              checkboxState={checkboxState}
            />
          </Grid>
          {/* TABLE OF ALERTS*/}
          {alertList && alertList?.length === 0 && (
            <Grid container item xs={12} style={{ marginTop: "1%" }}>
              <SpText id="noAlertLabel" variant="h1PageSubtitle">
                {labels.patient.patientAlerts.table.noAlerts}
              </SpText>
            </Grid>
          )}
          {alertList && alertList?.length > 0 && (
            <Grid item xs={12}>
              <SpTable
                pagination={true}
                headCells={headCells}
                rows={alertToShow}
                rowKey="id"
                padding={false}
                notCheckable={false}
              >
                <PatientAlertRow />
              </SpTable>
            </Grid>
          )}
          {/* DIALOG DETAILS */}
          <SpDialog
            style={{ padding: "1%" }}
            open={dialog}
            setOpen={setDialog}
            title={labels.patient.patientAlerts.table.dialog.title}
          >
            <DialogDetails row={selectedRow} />
          </SpDialog>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsAlerts);
