import { Button as MuiButton, Collapse, Grid, Chip } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import SpSearch from "../../components/atoms/SpSearch";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { getGroupsById } from "../../models/actions/Groups";
import { rollbar } from "../../utils/common";
import { labels } from "../shared/translations";
import { deleteTeam, getProfessionalsTeams } from "../../models/actions/Team";
import { ManageTeam } from "./FederationGroupHelper";
import { theme } from "../../components/theme";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const FederationGroups = (props) => {
  const [openDeleteTeam, setOpenDeleteTeam] = useState(false);
  const [searchStringPat, setSearchStringPat] = useState(null);
  const [currentGroup, setCurrentGroup] = useState([]);

  const [teams, setTeams] = useState([]);
  const [teamsFind, setTeamsFind] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [showManageTeam, setShowManageTeam] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { groupId } = useParams();
  const { setLoading } = props;

  const history = useHistory();

  const fetchData = async () => {
    try {
      setLoading(true);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
      const tempTeams = await await getProfessionalsTeams({
        id_federation: groupId,
      });
      setTeams(tempTeams);
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const removeTeam = async (answer) => {
    try {
      if (answer) {
        await deleteTeam({ id_group: selectedTeam.id });
        setTeams(teams.filter(({ id }) => id !== selectedTeam.id));
      }
      setOpenDeleteTeam(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removePatientFromServer", error);
    }
  };

  // Columns
  const headCellsTeams = [
    {
      width: "20%",
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.teamList.groupName,
    },
    {
      width: "60%",
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.groups.federationAdd.teamList.groupProfessional,
    },
    {
      width: "20%",
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.actions,
    },
  ];

  const filterTeams = (e) => {
    const tempFiltered = teams.filter(({ name }) =>
      name.includes(e.target.value)
    );
    setTeamsFind(tempFiltered);
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  const GroupAddPatientRow = ({ row, index }) => {
    const { name, professionals } = row;

    return (
      <>
        <SpTableRow role="checkbox" key={row?.id} tabIndex={index}>
          <SpTableCell>
            <SpIconAndTextButton
              id={`team${index}`}
              isIconRight={true}
              text={name}
              textVariant={"tableTextDetails"}
              onClick={() => {
                setSelectedTeam(row);
                setShowManageTeam(true);
                setIsEditing(true);
              }}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 15,
                  color: theme.colors.primary.lightBlue,
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            {professionals?.map(({ email }) => (
              <Chip
                key={email}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                  margin: "8px",
                }}
                label={email}
                size="medium"
              />
            ))}
          </SpTableCell>
          <SpTableCell align={"right"}>
            <SpButton
              id={"deleteGroup"}
              variant={"standardOppositeFull"}
              type="tableList"
              text={labels.groups.toolbar.deleteExit}
              onClick={() => {
                setSelectedTeam(row);
                setOpenDeleteTeam(true);
              }}
            />
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>
          <Grid
            item
            container
            xs={2}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <SpButton
              id={"addTeam"}
              variant="h1PageTitle"
              buttonType={"accept"}
              text={labels.groups.federationAdd.addGroup}
              onClick={() => {
                setIsEditing(false);
                setShowManageTeam(true);
              }}
            />
          </Grid>

          <Grid xs={12} container direction="row">
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Collapse in={showManageTeam} collapsedSize={0}>
                  <ManageTeam
                    idGroup={groupId}
                    snackbarShowMessage={props.snackbarShowMessage}
                    snackbarShowErrorMessage={props.snackbarShowErrorMessage}
                    teams={teams}
                    setTeams={setTeams}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    selectedTeam={selectedTeam}
                  />
                </Collapse>
              </Grid>
            </Grid>
            <Grid container direction={"row"} xs={12}>
              <Grid item container xs={12}>
                <Grid item xs={10}>
                  <SpText variant="h1PageTitle">
                    {labels.group.sideBarGroup.federationsTeams}
                  </SpText>
                </Grid>
                <Grid item xs={12}>
                  <StyledRow>
                    <SpSearch
                      onKeyDown={prevent}
                      onChange={(e) => filterTeams(e)}
                      value={searchStringPat}
                    />
                  </StyledRow>
                </Grid>
                <SpTable
                  pagination={true}
                  headCells={headCellsTeams}
                  rows={teamsFind.length !== 0 ? teamsFind : teams}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                >
                  <GroupAddPatientRow />
                </SpTable>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteTeam}
        setOpen={setOpenDeleteTeam}
        title={labels.groups.groupDetail.deleteTeamDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deleteTeamDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deleteTeamDialog.yes}
                buttonType="accept"
                onClick={() => removeTeam(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deleteTeamDialog.no}
                buttonType="accept"
                onClick={() => removeTeam(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(FederationGroups);
