import React, { useEffect, useState } from "react";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { labels, psTranslate } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";

import SpLoader from "../../../components/atoms/SpLoader";
import { FilterAutocomplete } from "../../patients/patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import {
  getOrganizationSchedule,
  updateOrganizationSchedule,
} from "../../../models/actions/Organization";
import { theme } from "../../../components/theme";
import SpText from "../../../components/atoms/SpText";
import { Chip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { generateSlotsForDay, Schedule } from "./OrganizationHelper";

const OrganizationScheduler = ({ props, professionals }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [slotTime, setSlotTime] = useState(30);
  const [newDate, setNewDate] = useState();

  const saveSchedule = async () => {
    setLoading(true);
    const ret = await updateOrganizationSchedule({
      data: schedule,
    });
    if (ret?.message) {
      props.snackbarShowMessage(ret.message);
    }
    if (ret?.error) {
      props.snackbarShowErrorMessage(ret.error);
    }
    setLoading(false);
  };

  const handleSlotChange = (day, slotIndex, field, value, professional) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [professional]: {
        ...prevSchedule[professional],
        schedule: {
          ...prevSchedule[professional].schedule,
          [day]: prevSchedule[professional].schedule[day].map((slot, index) =>
            index === slotIndex ? { ...slot, [field]: value } : slot
          ),
        },
      },
    }));
  };
  const handleSlotRemove = (day, slot, professional) => {
    let newScehd = JSON.parse(JSON.stringify(schedule));
    let slots = newScehd[professional].schedule[day];
    slots = slots.filter((item) => item.startTime !== slot.startTime);
    newScehd[professional].schedule[day] = slots;
    setSchedule(newScehd);
  };

  const handleDaySelection = (newSelectedDays) => {
    let tempSchedule = JSON.parse(JSON.stringify(schedule));
    setSelectedDays(newSelectedDays);
    labels.week
      .filter(({ key }) => !newSelectedDays.map(({ key }) => key).includes(key))
      .map(({ key }) => {
        professionals.map(({ id }) => {
          delete tempSchedule[id].schedule[key];
        });
      });

    newSelectedDays.forEach(({ key }) => {
      professionals.map(({ id }) => {
        tempSchedule = {
          ...tempSchedule,
          [id]: {
            ...tempSchedule[id],
            schedule: {
              ...tempSchedule[id]?.schedule,
              [key]: generateSlotsForDay(slotTime, true, true),
            },
          },
        };
      });
    });
    setSchedule(tempSchedule);
  };

  const initVoidProfessional = (tempSchedule, profId) => {
    let temp = JSON.parse(JSON.stringify(tempSchedule));
    selectedDays.forEach(({ key }) => {
      professionals.map(({ id }) => {
        if (id == profId)
          temp = {
            ...temp,
            [id]: {
              ...temp[id],
              schedule: {
                ...temp[id]?.schedule,
                [key]: generateSlotsForDay(slotTime, true, true),
              },
            },
          };
      });
    });
    return temp;
  };

  const fetchData = async () => {
    let days = new Set();
    let tempUnavailable = {};
    let ret = await getOrganizationSchedule({});

    Object.keys(ret).map((idPat) => {
      if (!ret[idPat].schedule || ret[idPat].schedule == {}) {
        ret[idPat].schedule = {};
        ret = initVoidProfessional(ret, idPat);
      }
      if (!ret[idPat].unavailableDays) ret[idPat].unavailableDays = [];
      tempUnavailable[idPat] = [];
      Object.keys(ret[idPat].schedule).map((day) => {
        days.add(day);
      });
    });
    setSchedule(ret);
    setSelectedDays(labels.week.filter(({ key }) => [...days].includes(key)));
  };

  useEffect(() => {
    fetchData();
  }, [professionals]);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <div style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <SpTextInput
              name="slot_time"
              label={labels.requestsList.slot_time}
              type="number"
              maxValue="100"
              variant="text"
              style={{ width: "100%" }}
              value={slotTime}
              onChange={(e) => setSlotTime(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FilterAutocomplete
              placeholder={labels.patient.controlQuestions.daysOfWeek}
              value={selectedDays}
              onChange={(_, newValue) => {
                handleDaySelection(newValue);
              }}
              renderOptions={selectedDays}
              options={labels.week}
            />
          </Grid>
        </Grid>
        {professionals.map(({ id, name, surname }) => {
          return (
            <Grid style={{ padding: "1em" }}>
              <SpText variant="h1">{`${name} ${surname}`}</SpText>
              <Grid xs={12}>
                <Grid item xs={12} container direction="row">
                  {selectedDays.map(({ key, name }) => {
                    return (
                      <Grid container xs={12}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Typography variant="h6">
                                {`${name} ${labels.general.morning}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6">
                                {`${name} ${labels.general.afternoon}`}
                              </Typography>
                              <Grid item xs={12}></Grid>
                            </Grid>
                            <Grid item xs={6}>
                              {(schedule[id]?.schedule[key] || []).map(
                                (slot, index) => {
                                  if (
                                    moment(slot.startTime, "HH:mm").isBefore(
                                      moment("13:00", "HH:mm")
                                    ) ||
                                    moment(slot.startTime, "HH:mm").isSame(
                                      moment("13:00", "HH:mm"),
                                      "hour"
                                    )
                                  )
                                    return (
                                      <Schedule
                                        slot={slot}
                                        index={index}
                                        onChangeStart={(e) =>
                                          handleSlotChange(
                                            key,
                                            index,
                                            "startTime",
                                            e.target.value,
                                            id
                                          )
                                        }
                                        onChangeEnd={(e) =>
                                          handleSlotChange(
                                            key,
                                            index,
                                            "endTime",
                                            e.target.value,
                                            id
                                          )
                                        }
                                        onChangeRemove={(e) =>
                                          handleSlotRemove(key, slot, id)
                                        }
                                      />
                                    );
                                }
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              {(schedule[id]?.schedule[key] || []).map(
                                (slot, index) => {
                                  if (
                                    moment(slot.startTime, "HH:mm").isAfter(
                                      moment("13:00", "HH:mm")
                                    )
                                  )
                                    return (
                                      <Schedule
                                        slot={slot}
                                        index={index}
                                        onChangeStart={(e) =>
                                          handleSlotChange(
                                            key,
                                            index,
                                            "startTime",
                                            e.target.value,
                                            id
                                          )
                                        }
                                        onChangeEnd={(e) =>
                                          handleSlotChange(
                                            key,
                                            index,
                                            "endTime",
                                            e.target.value,
                                            id
                                          )
                                        }
                                        onChangeRemove={(e) =>
                                          handleSlotRemove(key, slot, id)
                                        }
                                      />
                                    );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={3}>
                    <SpTextInput
                      name="slot_time"
                      label={labels.requestsList.unavailablesDays}
                      type="date"
                      maxValue="100"
                      variant="text"
                      style={{ width: "100%" }}
                      onChange={(e) => setNewDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "22px" }}>
                    <SpButton
                      type="submit"
                      style={{ height: 40 }}
                      buttonType={"accept"}
                      variant="none"
                      onClick={async () => {
                        let newSched = JSON.parse(JSON.stringify(schedule));
                        newSched[id].unavailableDays.push(newDate);
                        setSchedule(newSched);
                      }}
                      text={labels.patient.medicalNotes.report.addButton}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "22px" }}>
                    {schedule[id]?.unavailableDays?.map((option, index) => (
                      <Chip
                        key={`date_${index}`}
                        style={{
                          backgroundColor: theme.colors.primary.lightBlue,
                          color: "white",
                        }}
                        deleteIcon={<CancelIcon style={{ color: "white" }} />}
                        onDelete={() => {
                          let newSched = JSON.parse(JSON.stringify(schedule));
                          newSched[id].unavailableDays = newSched[
                            id
                          ].unavailableDays.filter(
                            (day) => !moment(day).isSame(option)
                          );

                          setSchedule(newSched);
                        }}
                        label={psTranslate(
                          `${moment(option).format("DD/MM/YYYY")}`
                        )}
                        size="medium"
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <SpButton
          type="submit"
          style={{ height: 40 }}
          buttonType={"accept"}
          variant="none"
          onClick={async () => {
            await saveSchedule();
          }}
          text={labels.groups.groupDetail.actions.save}
        />
      </div>
    </Grid>
  );
};
export default withSnackbar(OrganizationScheduler);
