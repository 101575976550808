import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SpText from "../SpText";
import { labels, psTranslate } from "../../../pages/shared/translations";
import "../App.css";
import SpButton from "../SpButton";
import { SpSelect, SpSelectMenuItem } from "../SpSelect";
import { withSnackbar } from "../SpSnackBar";
import { updateStructureSymbolById } from "../../../models/actions/Pathologies";
import { useParams } from "react-router-dom";

import {
  isFeatureFlagEnabled,
  getLoggedEvents,
  ELEMENT_TYPE,
} from "../../../utils/common";
import { SpElementCrudDetails } from "../../bundles/SpElementCrudDetails";
import SpLoader from "../SpLoader";

const commonDateFormat = "YYYY-MM-DD";

const Sp3DModelSymbolDetails = ({
  editable,
  nowDate,
  selectedSymbol,
  updateSymbolDate,
  setDialogOpen,
  ...props
}) => {
  const { patId } = useParams();

  const [editSeverity, setEditSeverity] = useState();

  const [enableLogEvents, setEnableLogEvents] = useState(false);
  const [loggedEvents, setLoggedEvents] = useState({});
  const [loading, setLoading] = useState(false);

  const updateSymbol = async (severity) => {
    try {
      setLoading(true);
      setDialogOpen(false);
      //Update symbol history
      const results = await updateStructureSymbolById({
        id_patient: patId,
        id_symbol: selectedSymbol.id,
        date: nowDate.format(commonDateFormat),
        severity: severity,
      });
      //Refresh symbols
      updateSymbolDate(true, true);
      setLoading(false);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  /**
   * useEffect singolo (funzionante)
   * da sostituire ai due useEffect sotto quando si riuscirà
   * ad effettuare il test con questo.
   * 
   * fallisce in fase di test a causa di un doppio refresh necessario
   * del componente non riuscito ad effettuare nel test 
   * 
    useEffect(async () => {
      if (await isFeatureFlagEnabled("log_events")) {
        setLoggedEvents(
          await getLoggedEvents({
            record_table_id: selectedSymbol.id,
            element_type: ELEMENT_TYPE.SYMBOL,
          })
        );
        setEnableLogEvents(true);
      }
      setEditSeverity(selectedSymbol.severity);
    }, [selectedSymbol]);
  */

  useEffect(async () => {
    let featureIsEnabled = await isFeatureFlagEnabled("log_events");
    setEnableLogEvents(featureIsEnabled);

    setEditSeverity(selectedSymbol.severity);
  }, [selectedSymbol]);

  useEffect(async () => {
    if (enableLogEvents) {
      let result = await getLoggedEvents({
        record_table_id: selectedSymbol.id,
        element_type: ELEMENT_TYPE.SYMBOL,
      });
      setLoggedEvents(result);
    }
  }, [enableLogEvents]);

  return (
    <Grid container direction="row">
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={6} container>
        <Grid item xs={12}>
          <SpText variant="text">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.disorder.name
            )}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.name
            )}
          </SpText>
        </Grid>
        {selectedSymbol.structure_dysfunction?.dysfunction?.dysfunction_category
          ?.id_disorder == 1 && (
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.bodymap.dialogDetail.dysfunctionLabel}
            </SpText>
            <SpText variant="h4ComponentLabelRegular">
              {psTranslate(
                selectedSymbol.structure_dysfunction?.dysfunction?.name
              )}
            </SpText>
          </Grid>
        )}

        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.inputDateLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {moment(String(selectedSymbol.date)).format("DD/MM/yyyy")}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.structureLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(selectedSymbol.structure_dysfunction?.structure?.name)}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.areaLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.structure?.area?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.regionLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.structure?.area?.region
                ?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.severityLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(labels.bodymap.severityLabels[editSeverity])}
          </SpText>
        </Grid>

        {editSeverity && editable && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <SpButton
              buttonType={"accept"}
              text={labels.bodymap.deleteButton}
              onClick={() => updateSymbol(0)}
            />
          </Grid>
        )}
      </Grid>
      {enableLogEvents ? (
        <Grid item xs={6}>
          <Grid item container direction="column">
            <Grid item xs={6}>
              <Grid item xs={12}>
                <SpText variant="text">
                  {
                    labels.patient.monitoring.pathologiesStep.detail
                      .pathologyType
                  }
                </SpText>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(
                    selectedSymbol.structure_dysfunction?.dysfunction?.name
                  )}
                </SpText>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <SpText variant="text">
                  {
                    labels.patient.presentation.add.stepper.step0
                      .medicalDiagnose
                  }
                </SpText>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedSymbol?.pathology?.name)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <SpText variant="text">
                  {labels.patient.presentation.add.stepper.step0.osiicsCode}
                </SpText>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedSymbol?.pathology?.osiics_code)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <SpElementCrudDetails crudDetails={loggedEvents} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment />
      )}
    </Grid>
  );
};
export default withSnackbar(Sp3DModelSymbolDetails);
