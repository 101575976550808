/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import "./App.css";
import IconButton from "@material-ui/core/IconButton";

import SpText from "./SpText";
import { styled } from "../styled";
import { theme } from "../theme";

const variants = {
  lightBlue: {
    border: "2px solid " + theme.colors.secondary.lightBlue,
    color: theme.colors.primary.lightBlue,
    backgroundColor: theme.colors.primary.lightBlue,
    "&:hover": {
      background: "transparent !important",
    },
    "&:disabled": {
      color: theme.colors.greyScale.grey3,
      background: "transparent !important",
      opacity: 0.23,
    },
  },
  none: {
    border: "2px solid transparent",
    color: theme.colors.greyScale.grey3,
    "&:hover": {
      background: "transparent !important",
    },
    "&:disabled": {
      color: theme.colors.greyScale.grey3,
      background: "transparent !important",
      opacity: 0.23,
    },
  },
  lightGreenFill: {
    color: theme.colors.light,
    backgroundColor: theme.colors.primary.lightBlue,
    "&:hover": {
      color: theme.colors.primary.white,
      background: theme.colors.primary.lightBlue,
    },
    "&:disabled": {
      color: theme.colors.secondary.lightBlue,
      background: theme.colors.light.lightBlue + " !important",
      opacity: 0.23,
    },
  },
  lightBlueFill: {
    color: theme.colors.light,
    backgroundColor: theme.colors.primary.lightBlue,
    "&:hover": {
      color: theme.colors.dark.grey,
      background: theme.colors.light.lightBlue,
    },
    "&:disabled": {
      color: theme.colors.primary.white,
      background: theme.colors.primary.lightBlue + " !important",
      opacity: 0.23,
    },
  },
};

const CustomIconButton = styled(IconButton)(
  ({ variant = "yellow", small = false }) => ({
    borderRadius: 0,
    height: small ? 30 : 40,
    maxHeight: 30,
    marginTop: 4,
    marginBottom: 4,
    flex: 1,
    padding: 12,
    cursor: "pointer",
    justifyContent: "space-between",
    ...variants[variant],
  })
);

const getColor = (textVariant) => {
  switch (textVariant) {
    case "sidebarItem":
      return theme.colors.primary.lightBlue;
    case "disable":
      return "grey";

    default:
      return "#FFF";
  }
};

const SpIconAndTextButton = React.forwardRef(
  (
    {
      id,
      text,
      textVariant,
      isIconLeft,
      isIconRight,
      isUpperCase = false,
      isIconButton = false,
      children,
      ...rest
    },
    ref
  ) => (
    <CustomIconButton
      id={id}
      ref={ref}
      {...rest}
      disableRipple
      disableFocusRipple
      className={textVariant}
    >
      {isIconLeft && children}
      {text && (
        <SpText
          id={`${id}Text`}
          variant={textVariant}
          color={getColor(textVariant)}
          isIconButton={isIconButton}
          style={{
            flex: 2,
            textTransform: isUpperCase ? "uppercase" : "none",
            fontWeight:
              textVariant === "sidebarItem" || textVariant === "disable"
                ? "normal"
                : "bold",
          }}
        >
          {text}
        </SpText>
      )}
      {isIconRight && children}
    </CustomIconButton>
  )
);

export default SpIconAndTextButton;
