import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { getTrainingTemplateById } from "../../../models/actions/Activity";
import CommonRows from "../../mylab/MyLabAddTemp/Rows/CommonRows";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import SpButton from "../../../components/atoms/SpButton";

const TrainingInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  defaultParameter,
  control,
  setValue,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  handleSync,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
  snackbarShowErrorMessage,
  snackbarShowMessage,
}) => {
  const {
    fields: fieldsTraining,
    append: appendTraining,
    remove: removeTraining,
  } = useFieldArray({
    control,
    name: "training",
    keyName: "id",
    defaultValues: [],
  });

  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(async () => {
    if (selectedTemplate) {
      const templateData = await getTrainingTemplateById({
        id_template: selectedTemplate,
      });
      if (templateData) {
        removeTraining();
        setValue("quantity_training", templateData.number);
        appendTraining(templateData.row_training_templates);
      }
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeTraining();
      setValue("training_motivation", templateData.training_motivation);
      setValue("quantity_training", templateData.training?.length ?? 0);
      appendTraining(templateData.training);
    }
  }, [templateData]);

  const fieldTrainingUpdated = useWatch({ name: "training", control: control });

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="training_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeTraining(fieldsTraining.length - 1);
              if (fieldsTraining.length > 0)
                setValue("quantity_training", fieldsTraining.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            name="quantity_training"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.training.inputs.quantity}
            disabled={disabled}
            isPrint={isPrint}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendTraining({ id: fieldsTraining.length });
              setValue("quantity_training", fieldsTraining.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>
      {
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          internalLoadOnly={true}
        />
      }

      {fieldsTraining.length > 0 && (
        <Grid item xs={12} container style={{ marginTop: "10px" }}>
          <CommonRows
            rows={fieldTrainingUpdated}
            updateRow={(idx, key, value) => {
              setValue(`training[${idx}].${key}`, value);
            }}
          />
        </Grid>
      )}
      {allowFeedback && (
        <Grid item xs={12} style={{ paddingTop: "1em" }}>
          <SpButton
            style={{ width: "100%" }}
            text={
              showFeedback
                ? labels.patient.viewPlan.addActivity.inputForm.hideFeedbacks
                : labels.patient.viewPlan.addActivity.inputForm.showFeedbacks
            }
            onClick={() => {
              setShowFeedback(!showFeedback);
            }}
          />
        </Grid>
      )}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default TrainingInputDetails;
