/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */

export const routesGroup = {
  general: "general",
  teamStatus: "team-status",
  analytics: "analytics",
  activity: "activity",
  readiness: "readiness",
  statistics: "statistics",
  situation: "situation",
  availability: "availability",
  proms: "proms",
  federationsGeneral: "federationsGeneral",
  federationsTeams: "federationsTeams",
  professionalsTeams: "professionalsTeams",
  federationInjuries: "federationInjuries",
  statisticsAnalytics: "statistics-analytics",
  controlRoom: "controlRoom",
};

export const getGroupSidebarRoute = (route, groupId) => {
  let routeReturn = "";

  switch (route) {
    case "general":
      routeReturn = `/groups/details/${groupId}`;
      break;
    case "analytics":
      routeReturn = `/groups/analytics/${groupId}`;
      break;

    case "readiness":
      routeReturn = `/groups/readiness/${groupId}`;
      break;

    case "situation":
      routeReturn = `/groups/situation/${groupId}`;
      break;

    case "activity":
      routeReturn = `/groups/activity/${groupId}`;
      break;

    case "statistics":
      routeReturn = `/groups/statistics/${groupId}`;
      break;

    case "availability":
      routeReturn = `/groups/availability/${groupId}`;
      break;

    case "proms":
      routeReturn = `/groups/proms/${groupId}`;
      break;

    case "federationsGeneral":
      routeReturn = `/federations/details/${groupId}`;
      break;

    case "federationsTeams":
      routeReturn = `/federations/teams/${groupId}`;
      break;

    case "professionalsTeams":
      routeReturn = `/federations/professionals/teams/${groupId}`;
      break;

    case "federationInjuries":
      routeReturn = `/federations/injuries/${groupId}`;
      break;

    case "statistics-analytics":
      routeReturn = `/groups/edit/${groupId}/statistics-anlytics`;
      break;

    case "controlRoom":
      routeReturn = `/groups/edit/${groupId}/control-room`;
      break;

    default:
      routeReturn = `/groups/situation/${groupId}`;
      break;
  }
  return routeReturn;
};
