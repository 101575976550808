import React from "react";
import { LogoContainer, LogoImg } from "./style";
import LogoUrl from "../../../assets/icon/powerset_logo_web.png";

const Logo = (props) => {
  return (
    <LogoContainer>
      <LogoImg id={"logoImage"} src={LogoUrl} />
    </LogoContainer>
  );
};

Logo.propTypes = {};

export default Logo;
