import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useScreenshot } from "use-react-screenshot";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpButton from "../../../components/atoms/SpButton";
import SpLoader from "../../../components/atoms/SpLoader";
import { labels } from "../../shared/translations";
import { actionPDF, rollbar } from "../../../utils/common";
import printContext from "../../../utils/printContext";
const PatientsReportsPDF = (props) => {
  const { isPrint, setIsPrint } = useContext(printContext);
  const [image, takeScreenShot] = useScreenshot();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isPrint) {
      actionButtonPDF();
    }
  }, [isPrint]);

  const actionButtonPDF = async () => {
    setLoading(true);
    try {
      const resultpdf = await actionPDF(takeScreenShot, props);
      localStorage.setItem("pdfScreenshot", JSON.stringify(resultpdf));
      setIsPrint(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("PatientsReports - actionPDF", error);
      setIsPrint(false);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      {!props.hide && (
        <SpButton
          className="selectGraph"
          text={
            !props.selectedPDF.includes(props.label)
              ? labels.analytics.selectPDFGraph
              : labels.analytics.updatePDFGraph
          }
          onClick={() => {
            setIsPrint(true);
          }}
        />
      )}
    </>
  );
};

export default withSnackbar(PatientsReportsPDF);
