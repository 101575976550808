import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "../../../App.css";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { getAllergies } from "../../../models/actions/MedicalNotes";

const PatientsMedicalNotesAllergies = (props) => {
  const [allergies, setAllergies] = useState(null);
  const { patId } = useParams();

  const fetchData = async () => {
    const resAllergies = await getAllergies({ id: patId });
    setAllergies(resAllergies);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return allergies?.value ? (
    <>
      <Grid container direction="row">
        <Grid item container xs={2}>
          <SpText variant={"h1PageSubtitle"}>
            {psTranslate(labels.patient.medicalNotes.allergies.value)}:
          </SpText>
        </Grid>
        <Grid item container xs={4}>
          <SpText>{psTranslate(allergies.value)}</SpText>
        </Grid>
        <Grid item container xs={2}>
          <SpText variant={"h1PageSubtitle"}>
            {psTranslate(labels.patient.medicalNotes.allergies.note)}:
          </SpText>
        </Grid>
        <SpText item container xs={3}>
          <SpText>{allergies.note}</SpText>
        </SpText>
      </Grid>
    </>
  ) : (
    <>
      <SpText variant={"h1PageSubtitle"}>
        {psTranslate(labels.patient.medicalNotes.allergies.noAllergies)}
      </SpText>
    </>
  );
};

export default PatientsMedicalNotesAllergies;
