import { Checkbox, Chip, Divider, Grid } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import HumanBodyBackUrl from "../../../assets/human_body_back.png";
import HumanBodyFrontUrl from "../../../assets/human_body_front.png";
import {
  getAssessmentsTemplateCategories,
  getAssessmentTemplateDetail,
  getAssessmentTemplateElementsByCategoryIds,
} from "../../../models/actions/Assessment";
import { labels, psTranslate } from "../../../pages/shared/translations";
import { getSortedAndTranslatedArray } from "../../../utils/common";
import { SpAutocomplete } from "../../atoms/SpAutocomplete";
import SpCheckbox from "../../atoms/SpCheckbox";
import { withSnackbar } from "../../atoms/SpSnackBar";
import SpText from "../../atoms/SpText";
import { styled } from "../../styled";
import { theme } from "../../theme";
import AssessmentPrintSheet from "./AssessmentPrintSheet";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledRowListHeader = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const AddAssessmentTemplate = ({
  setTemplateDetailData,
  assessmentElements,
  setAssessmentElements,
  setName,
  isClone,
  isHandbook = false,
  printRef,
  setValue,
  ...props
}) => {
  const [assessmentsCategories, setAssessmentsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentQuestionnairesList, setCurrentQuestionnairesList] = useState(
    []
  );
  const [assessmentData, setAssessmentData] = useState([]);

  const { idElement } = useParams();
  const readOnly = idElement && !isClone;

  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) => option.name + option.tags,
  });

  //Effects
  const fetchData = async () => {
    try {
      const newAssessmentCategories = await getAssessmentsTemplateCategories();
      setAssessmentsCategories(newAssessmentCategories);
      const newSelectedCategories = newAssessmentCategories?.map((_) => false);

      //fetch template detail and setting the form to be displayed
      if (idElement) {
        const responseAssessmentCategory = await getAssessmentTemplateDetail({
          id_template: idElement,
        });
        if (responseAssessmentCategory) {
          setTemplateDetailData(responseAssessmentCategory);
          const newAssessmentData = [];
          const newAssessmentElements = {};
          responseAssessmentCategory.AssesmentElement.map((ass) => {
            const foundElem = newAssessmentData.find(
              (elem) =>
                elem?.id ==
                ass.assessment_assessment_category.assessment_category.id
            );
            const categoryId =
              ass.assessment_assessment_category.assessment_category.id;
            if (!foundElem) {
              newSelectedCategories[
                newAssessmentCategories.findIndex(
                  (cat) => cat.id === categoryId
                )
              ] = true;
              newAssessmentData.push({
                id: categoryId,
                name: ass.assessment_assessment_category.assessment_category
                  .name,
                assessment_elements: [],
              });
              newAssessmentElements[categoryId] = [];
            }
            newAssessmentData[
              newAssessmentData.length - 1
            ].assessment_elements.push(ass.name);
            newAssessmentElements[categoryId].push(ass);
          });
          if (isClone) newAssessmentData.forEach((data) => delete data.id);
          setAssessmentData(newAssessmentData);
          setAssessmentElements(newAssessmentElements);
        }
        setValue(
          "template_reference",
          responseAssessmentCategory.template_reference
        );
        setValue("template_note", responseAssessmentCategory.template_note);
        setName(responseAssessmentCategory.name);
      }

      setSelectedCategories(newSelectedCategories);
      updateQuestionnairesShownRows(
        newSelectedCategories,
        newAssessmentCategories
      );
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateQuestionnairesShownRows = (
    newSelectedCategories,
    newAssessmentCategories
  ) => {
    const foundIds = [];
    newAssessmentCategories.map((newCategoryElem, idx) => {
      if (newSelectedCategories[idx]) foundIds.push(newCategoryElem.id);
    });
    updateQuestionnairesList(foundIds);
  };

  useEffect(() => fetchData(), []);

  const updateQuestionnairesList = async (categoryIdsFiltered) => {
    try {
      if (categoryIdsFiltered) {
        const response = await getAssessmentTemplateElementsByCategoryIds({
          id_categories: categoryIdsFiltered,
        });
        setCurrentQuestionnairesList(response);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <Grid container xs={12}>
      {readOnly ? (
        <Grid container item xs={12} direction="row" alignItems="flex-start">
          <Grid container item xs={isHandbook ? 8 : 12}>
            {/*Detail assessment*/}
            {assessmentData?.map((item, idx) => (
              <Grid
                key={`assessment_data_${idx}`}
                container
                item
                xs={4}
                direction="row"
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  style={{
                    border: `1px solid ${theme.colors.primary.lightBlue}`,
                    padding: "8px",
                  }}
                >
                  <Grid item xs={12}>
                    <SpText variant="text">{psTranslate(item?.name)}</SpText>
                  </Grid>
                  {getSortedAndTranslatedArray(
                    item?.assessment_elements,
                    null
                  )?.map((currentAssessmentTemplateElement, idy) => (
                    <Grid
                      item
                      xs={12}
                      key={`current_assessment_template_element${idy}`}
                    >
                      <SpText variant="h4ComponentLabel">
                        {currentAssessmentTemplateElement}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/*Handbook bodies*/}
          {isHandbook && (
            <Grid xs={4} container item direction="column">
              <img
                src={HumanBodyFrontUrl}
                alt={"Human body front"}
                style={{ objectFit: "contain", height: "400px" }}
              />
              <img
                src={HumanBodyBackUrl}
                alt={"Human body back"}
                style={{ objectFit: "contain", height: "400px" }}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container direction="row" xs={12} style={{ marginTop: "1%" }}>
          {/*Add assessment*/}
          <Grid container item direction="column" xs={12} spacing={3}>
            <Grid item xs={12}>
              <SpText variant="h1PageSubtitle">
                {
                  labels.patient.presentation.add.stepper.step4
                    .assessmentDetailRequest.categoryTitle
                }
              </SpText>
            </Grid>
          </Grid>
          <StyledRow>
            <StyledRowListHeader container spacing={1}>
              {assessmentsCategories.map((category, idx) => (
                <Grid
                  key={`category_${category.id}`}
                  item
                  container
                  xs={6}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <SpCheckbox
                      rightlabel
                      variant="column"
                      onChange={(e) => {
                        const newSelectedCategories = [...selectedCategories];
                        newSelectedCategories[idx] = e.target.checked;
                        setSelectedCategories(newSelectedCategories);

                        updateQuestionnairesShownRows(
                          newSelectedCategories,
                          assessmentsCategories
                        );
                      }}
                      checked={selectedCategories[idx] ?? false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{ paddingLeft: "5px", alignSelf: "center" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate(category.name)}
                    </SpText>
                  </Grid>
                </Grid>
              ))}
            </StyledRowListHeader>
          </StyledRow>
          {/*Assessment element autocomplete*/}
          <Grid container direction="row" xs={12} style={{ marginTop: "1%" }}>
            {currentQuestionnairesList?.map((item) => (
              <Grid
                direction="column"
                container
                xs={6}
                style={{ padding: "5px" }}
                key={`current_questionnaire_list_${item.id}`}
              >
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    backgroundColor: theme.colors.primary.lightBlue,
                  }}
                />
                <SpText variant={"h4ComponentLabelColored"}>
                  {psTranslate(item?.category)?.toUpperCase()}
                </SpText>
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    backgroundColor: theme.colors.primary.lightBlue,
                  }}
                />
                <Grid item>
                  <SpAutocomplete
                    multiple
                    style={{ width: "100%", height: "100%", maxHeight: "100%" }}
                    formControlWidth={"100%"}
                    selectPlaceholder={labels.bundles.assessment.selectElements}
                    options={getSortedAndTranslatedArray(item.elements ?? [])}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(opt, value) => opt.id === value?.id}
                    value={assessmentElements[item.id] ?? []}
                    filterOptions={filterOptions}
                    onChange={(_, newValue) => {
                      const obj = { ...assessmentElements };
                      obj[item.id] = newValue;
                      setAssessmentElements(obj);
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={`option_${props.id}`}
                        style={{
                          color: option.selected
                            ? theme.colors.primary.white
                            : theme.colors.primary.black,
                        }}
                      >
                        <Checkbox
                          name={props.id}
                          icon={<CheckBoxOutlineBlankIcon small />}
                          checkedIcon={<CheckBoxIcon small />}
                          style={{
                            marginRight: 5,
                            color: option.selected
                              ? theme.colors.primary.white
                              : theme.colors.primary.black,
                          }}
                          checked={option.selected}
                        />
                        {psTranslate(props.name)}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.name}
                          style={{
                            backgroundColor: theme.colors.primary.lightBlue,
                            color: theme.colors.primary.white,
                          }}
                          label={psTranslate(option.name)}
                          size="medium"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {printRef && (
        <div style={{ display: "none" }}>
          <AssessmentPrintSheet
            ref={printRef}
            assessmentData={assessmentData}
          />
        </div>
      )}
    </Grid>
  );
};

export default withSnackbar(AddAssessmentTemplate);
