import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { labels } from "./shared/translations";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpLoadingButton from "../components/atoms/SpLoadingButton";
import { useAuth0 } from "@auth0/auth0-react";
import "./Login.css";
import LoginThumbnail from "./LoginThumbnail";
import icon from "../assets/icon/iconSymbol.png";
import { LogoLogin } from "./shared/logo/style";
import SpButton from "../components/atoms/SpButton";
import { useHistory } from "react-router-dom";
import LanguagesFlags from "../components/bundles/LanguagesFlags";

const LoginRegitration = ({ loginWithRedirect, ...props }) => {
  const history = useHistory();

  return (
    <>
      <div className="App">
        <Grid container direction="row">
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <LanguagesFlags />
          </Grid>
        </Grid>
        <div className="container">
          {
            <>
              <div>
                <div>
                  <LoginThumbnail />
                </div>
                <div className="main-content">{/* Contenuto principale */}</div>
              </div>
              <div className="login">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <LogoLogin src={icon} alt="language" />
                  </Grid>
                  <>
                    <Grid item xs={12}>
                      <SpLoadingButton
                        id="loginButton"
                        onClick={() => loginWithRedirect()}
                        style={{ marginTop: "3%", marginRight: "0 !important" }}
                        text={labels.login.buttons.login}
                        buttonType={"accept"}
                        variant="none"
                        type="submit"
                      />
                    </Grid>
                  </>
                </Grid>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

const LoginAuth0 = (props) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  if (!isLoading) {
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return loginWithRedirect();
    }
  } else {
    return (
      <SpLoadingButton
        loading={isLoading}
        style={{ marginTop: "3%", marginRight: "0 !important" }}
        text={labels.login.buttons.login}
        buttonType={"accept"}
        variant="none"
        type="submit"
      />
    );
  }
};

export default withSnackbar(LoginAuth0);
