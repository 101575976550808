import React, { useEffect, useState } from "react";
import { labels } from "../../../pages/shared/translations";
import SpSlider from "../SpSlider";
import SpText from "../SpText";
import Human3D from "../human3D/Human3D.jsx";
import SpButton from "../SpButton";
import SpCheckbox from "../SpCheckbox.js";
import "../App.css";
import Gradient from "../../../assets/Gradient.png";
import moment from "moment";
const Sp3DModelModel = ({
  markers,
  symbolsDysfunction,
  riskFactors,
  setSelectedSymbol,
  modelBehaviour,
  setSymbolDialogOpen,
  selectedRegion,
  setSelectedRegion,
  selectedArea,
  setSelectedArea,
  navigateToAreaDetail,
  updateSymbolsFromFilterDate,
  currentSymbols,
  isCurrent,
  setIsCurrent,
  ...props
}) => {
  const [humanOptions, setHumanOptions] = useState({
    skinOpacity: 1,
    musclesToggle: true,
    skeletonToggle: true,
    connectiveToggle: true,
    cartilageToggle: true,
    nervousToggle: true,
  });
  const [hoverName, setHoverName] = useState(null);
  const [showLegend, setShowLegend] = useState(false);

  return (
    <div style={{ height: "500px", position: "relative" }}>
      <div
        style={{
          zIndex: 1,
          height: "31px",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SpButton
          buttonType={"accept"}
          text={labels.bodymap.resetButton}
          onClick={() => {
            if (setSelectedArea) {
              setSelectedArea(null);
            }
            if (setSelectedRegion) {
              setSelectedRegion(null);
            }
          }}
        />
      </div>
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SpText style={{ marginRight: "1%", marginTop: "1%" }}>
          {hoverName}
        </SpText>
      </div>
      <div style={{ zIndex: 1, position: "absolute" }}>
        <SpSlider
          id={"skinOpacity"}
          label={labels.bodymap.skinOpacity}
          labelTop={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          defaultValue={1}
          min={0}
          max={1}
          step={0.01}
          value={humanOptions.skinOpacity}
          onChange={(_, value) =>
            setHumanOptions({ ...humanOptions, skinOpacity: value })
          }
        />

        <SpCheckbox
          id="musclesLabel"
          label={labels.bodymap.musclesLabel}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={humanOptions.musclesToggle}
          onChange={(evnt) => {
            setHumanOptions({
              ...humanOptions,
              musclesToggle: evnt.target.checked,
            });
          }}
        />

        <SpCheckbox
          id={"skeletonLabel"}
          label={labels.bodymap.skeletonLabel}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={humanOptions.skeletonToggle}
          onChange={(evnt) => {
            setHumanOptions({
              ...humanOptions,
              skeletonToggle: evnt.target.checked,
            });
          }}
        />
        <SpCheckbox
          id={"nervousLabel"}
          label={labels.bodymap.nervousLabel}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={humanOptions.nervousToggle}
          onChange={(evnt) => {
            setHumanOptions({
              ...humanOptions,
              nervousToggle: evnt.target.checked,
            });
          }}
        />
        <SpCheckbox
          id={"cartilageLabel"}
          label={labels.bodymap.cartilageLabel}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={humanOptions.cartilageToggle}
          onChange={(evnt) => {
            setHumanOptions({
              ...humanOptions,
              cartilageToggle: evnt.target.checked,
            });
          }}
        />
        <SpCheckbox
          id={"connectiveLabel"}
          label={labels.bodymap.connectiveLabel}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={humanOptions.connectiveToggle}
          onChange={(evnt) => {
            setHumanOptions({
              ...humanOptions,
              connectiveToggle: evnt.target.checked,
            });
          }}
        />

        <SpCheckbox
          id={"bodymap"}
          label={labels.bodymap.currentStatus}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={isCurrent}
          onChange={(evnt) => {
            setIsCurrent(evnt.target.checked);
            if (evnt.target.checked) {
              updateSymbolsFromFilterDate(null, 0, true);
              setShowLegend(false);
            } else {
              updateSymbolsFromFilterDate(null, 0, false);
            }
          }}
        />

        <SpCheckbox
          id={"bodymap"}
          label={labels.bodymap.legend}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            marginLeft: "5%",
            marginTop: "5%",
          }}
          checked={showLegend}
          onChange={(evnt) => {
            updateSymbolsFromFilterDate(moment(), 0, false);
            setIsCurrent(false);
            setShowLegend(evnt.target.checked);
          }}
        />

        <div style={{ marginLeft: "5%", marginTop: "2%", width: "180px" }}>
          <div style={{ display: showLegend ? "block" : "none" }}>
            <img
              src={Gradient}
              alt={"Gradient"}
              style={{ objectFit: "contain", width: "180px" }}
            />
            <SpText
              variant="textWhite"
              style={{ display: "inline-block", float: "left" }}
            >
              {labels.bodymap.legendValues.low}
            </SpText>
            <SpText
              variant="textWhite"
              style={{ display: "inline-block", float: "right" }}
            >
              {labels.bodymap.legendValues.high}
            </SpText>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "0",
          bottom: "0",
          right: "0",
          left: "0",
          height: "500px",
        }}
      >
        <Human3D
          humanOptions={humanOptions}
          behaviourType={modelBehaviour}
          zoomedRegion={selectedRegion ?? null}
          informativeCallbacks={{
            onEnter: (name) => {
              setHoverName(name);
            },
            onExit: (name) => {
              setHoverName("");
            },
            onRegionClick: (regionName) => {
              if (setSelectedRegion) {
                setSelectedRegion(regionName);
              }
            },
            onAreaClick: (areaName) => {
              if (setSelectedArea) {
                setSelectedArea(areaName);
              } else {
                navigateToAreaDetail(areaName);
              }
            },
          }}
          markers={markers}
          markerCallbacks={{
            onRegionZoomClick: (regionName) => {
              if (setSelectedRegion) {
                setSelectedRegion(regionName);
              }
            },
            onRegionMarkerClick: (regionName) => {
              //No region markers
            },
            onAreaZoomClick: (areaName) => {
              if (setSelectedArea) {
                setSelectedArea(areaName);
              }
            },
            onAreaMarkerClick: (marker) => {
              if (setSelectedArea) {
                setSelectedArea(marker.name);
              }
              setSelectedArea(marker.name);
              let symbol;
              if (!marker.riskFactor)
                symbol = symbolsDysfunction.find(
                  (elem) => elem.id === marker.id
                );
              else symbol = riskFactors.find((elem) => elem.id === marker.id);

              if (symbol) {
                setSelectedSymbol(symbol);
                setSymbolDialogOpen(true);
              }
            },
            onStructureMarkerClick: (marker) => {
              if (setSelectedArea) {
                setSelectedArea(marker.name);
              }
            },
            onEnter: (name) => {
              setHoverName(name);
            },
            onExit: (name) => {
              setHoverName("");
            },
          }}
        />
      </div>
    </div>
  );
};
export default Sp3DModelModel;
