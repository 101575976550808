import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import {
  getInterviewQuestionnairesById,
  getQuestionnairesAnswered,
  savePresentationInterview,
  sendInterviewToPatient,
} from "../../../models/actions/Interviews";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import { theme } from "../../../components/theme";

const PatientsPresentationDetailInterview = (props) => {
  const [interviewQuestionnaires, setInterviewQuestionnaires] = useState([]);
  const [interviewSendingStatus, setInterviewSendingStatus] = useState(false);

  const { endDate, presentationInterviewId, setLoading } = props;
  const { patId, presentationId } = useParams();

  const { control, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getInterviewQuestionnairesById({
        id_interview: presentationInterviewId,
        id_presentation: presentationId,
      });
      setInterviewQuestionnaires(response.interview_categories);
      const responseDeleg = await getQuestionnairesAnswered({
        id_patient: patId,
        id_questionnaires: presentationInterviewId,
      });
      if (responseDeleg && responseDeleg.length > 0)
        setInterviewSendingStatus(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveInterview = async (data) => {
    try {
      const response = await savePresentationInterview({
        ...data,
        id_presentation: presentationId,
      });
      if (response.error) {
        props.snackbarShowErrorMessage(response.error);
      } else {
        props.snackbarShowMessage(response.message);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const formControlStyleCheck = {
    width: "calc(100% - 4%)",
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    justifyContent: "flex-start",
  };

  const renderQuestionnaires = () => {
    let globalIndex = 0;
    return interviewQuestionnaires?.map((category, idx) => (
      <Grid item xs={12} key={`category_${idx}`}>
        {!endDate && (
          <SpText variant="h4ComponentLabel" style={{ marginTop: "3%" }}>
            {psTranslate(category.name)}
          </SpText>
        )}
        {category?.interview_category_interviews[0]?.interview_questions?.map(
          (question, questionIdx) => {
            globalIndex++;
            const foundInterviewAnswers = question?.interview_answers?.find(
              (elem) => elem.presentation_interview_answers?.length > 0
            );
            const currentInterviewUserAnswer =
              foundInterviewAnswers?.presentation_interview_answers?.find(
                (elem) => elem.id_presentation == presentationId
              );

            if (!endDate) {
              return !question.is_multiple ? (
                <>
                  <Controller
                    key={`question_not_mul_${questionIdx}`}
                    render={(props) => (
                      <SpSelect
                        allowDeselect={true}
                        selectPlaceholder={
                          labels.patient.presentation.add.selectAnswer
                        }
                        label={psTranslate(question.question)}
                        value={props.value}
                        formControlStyle={{ width: "100%" }}
                        formControlWidth={200}
                        disabled={endDate}
                        onChange={(e) => props.onChange(e.target.value)}
                      >
                        {question?.interview_answers?.map((p) => (
                          <SpSelectMenuItem key={p.id} value={p.id}>
                            {psTranslate(p.answer)}
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    defaultValue={
                      currentInterviewUserAnswer?.id_interview_answer
                    }
                    name={`interview_answers[${globalIndex}]`}
                    control={control}
                  />
                </>
              ) : (
                <>
                  <SpText variant="text">{question.question}</SpText>
                  {question?.interview_answers?.map((answer, ansIdx) => {
                    return (
                      <Controller
                        key={`ans_${ansIdx}`}
                        style={{ marginTop: "3%" }}
                        render={(props) => (
                          <Grid>
                            <SpCheckbox
                              formControlStyle={formControlStyleCheck}
                              rightlabel
                              style={{ width: "100%" }}
                              disabled={endDate}
                              checked={props.value}
                              onChange={(e) => {
                                props.onChange(e.target.checked);
                              }}
                              variant={"column"}
                              label={psTranslate(answer.answer)}
                            />
                          </Grid>
                        )}
                        defaultValue={currentInterviewUserAnswer?.id}
                        name={`interview_answers[${globalIndex}]`}
                        control={control}
                      />
                    );
                  })}
                </>
              );
            } else {
              if (currentInterviewUserAnswer) {
                if (questionIdx == 0) {
                  return (
                    <>
                      <SpText
                        variant="h4ComponentLabel"
                        style={{ marginTop: "3%" }}
                      >
                        {psTranslate(category.name)}
                      </SpText>
                      <SpText style={{ marginTop: "1%" }} variant="text">
                        {psTranslate(question.question)}
                      </SpText>
                      <SpText variant="h4ComponentLabelRegular">
                        {psTranslate(currentInterviewUserAnswer?.answer)}
                      </SpText>
                    </>
                  );
                } else {
                  return (
                    <>
                      <SpText style={{ marginTop: "1%" }} variant="text">
                        {psTranslate(question.question)}
                      </SpText>
                      <SpText variant="h4ComponentLabelRegular">
                        {psTranslate(currentInterviewUserAnswer?.answer)}
                      </SpText>
                    </>
                  );
                }
              }
            }
          }
        )}
      </Grid>
    ));
  };

  useEffect(
    () => presentationInterviewId && fetchData(),
    [presentationInterviewId]
  );

  const sendInterviewCompilationToPatient = async () => {
    try {
      const sendingResult = await sendInterviewToPatient({
        id_patient: patId,
        id_questionnaires: presentationInterviewId,
      });
      if (sendingResult.message) {
        props.snackbarShowMessage(sendingResult.message);
        await fetchData();
      } else {
        props.snackbarShowErrorMessage(sendingResult.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return presentationInterviewId ? (
    <Grid direction="column" container xs={12}>
      <Grid item xs={12} container>
        <Grid item xs={6} style={{ alignSelf: "center" }}></Grid>
        {!endDate && (
          <Grid
            item
            xs={6}
            style={{
              backgroundColor: "#333333",
              borderColor: theme.colors.primary.lightBlue,
              border: "1px solid",
              padding: "10px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              {!interviewSendingStatus ? (
                <SpText textAlign="center" variant="h1PageSubtitle">
                  {
                    labels.patient.presentation.add.stepper.step2
                      .patientDelegSubtitle
                  }
                </SpText>
              ) : (
                <SpText textAlign="center" variant="h1PageSubtitle">
                  {
                    labels.patient.presentation.add.stepper.step2
                      .patientAlreadyDelegSubtitle
                  }
                </SpText>
              )}
              {!interviewSendingStatus && (
                <SpButton
                  buttonType="accept"
                  text={
                    labels.patient.presentation.add.stepper.step2
                      .patientDelegSend
                  }
                  onClick={() => sendInterviewCompilationToPatient()}
                  variant="column"
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>

      <form onSubmit={handleSubmit(saveInterview)}>
        <Grid container style={{ marginBottom: "3%" }} direction="column">
          {renderQuestionnaires()}
        </Grid>
        {interviewQuestionnaires && !endDate && (
          <SpButton
            type="submit"
            buttonType="accept"
            text={labels.patient.presentation.detail.stepper.step7.saveButton}
            variant="column"
          />
        )}
      </form>
    </Grid>
  ) : (
    <Grid direction="column" container xs={12}>
      <Grid item xs={12} container>
        <Grid item xs={6} style={{ alignSelf: "center" }}>
          <SpText variant="h1PageTitle">
            {labels.patient.presentation.add.stepper.step2.no_interview.toUpperCase()}
          </SpText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsPresentationDetailInterview);
