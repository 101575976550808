import { Grid, Tooltip } from "@material-ui/core";
import React from "react";
import SpText from "../../components/atoms/SpText";
import { labels, psTranslate } from "../shared/translations";
import { titleCase } from "../../utils/common";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SpIconButton from "../../components/atoms/SpIconButton";
import moment from "moment-timezone";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const ColoredEventCellHelper = ({ event, type }) => {
  return (
    <div id={`event-${event.id}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: type === "week" ? "center" : "left",
        }}
      >
        <LightTooltip
          style={{ fontSize: "25px" }}
          title={
            event?.patients
              ? event.patients.map(
                  ({ patientName }) => `${titleCase(patientName)} `
                )
              : psTranslate(event.title)
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src={event?.media} style={{ width: 30, height: 30 }} />

            {type === "day" && (
              <SpText variant="h6BoldWhite" color={"white"}>
                {event?.data?.appointment_motivation
                  ? event?.data?.appointment_motivation
                  : psTranslate(event?.title)}
              </SpText>
            )}
            {type === "month" &&
              !event?.archiving_status?.includes("completed") && (
                <SpText variant="textWhiteArchivedBold" color={"white"}>
                  {moment(event.start).format("HH:mm")}
                  {" - "}
                  {moment(event.end).format("HH:mm")}
                </SpText>
              )}

            {event?.archiving_status?.includes("completed") && (
              <SpText variant="textWhiteArchivedBold" color={"white"}>
                {moment(event.start).format("HH:mm")}
                {" - "}
                {moment(event.end).format("HH:mm")}
              </SpText>
            )}
            {event?.archiving_status?.includes("completed") && (
              <CheckCircleIcon />
            )}
          </div>
        </LightTooltip>
      </div>
    </div>
  );
};

export default ColoredEventCellHelper;
