import React from "react";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { labels } from "../../shared/translations";

import {
  ResponsiveContainer,
  AreaChart,
  BarChart,
  XAxis,
  YAxis,
  Area,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { theme } from "../../../components/theme";

const TrainingReportA = (props) => {
  if (props.monthly === false) {
    const maxValue = Math.max(
      ...props.dataChartT.map((item) =>
        Math.max(
          item[props.labT + ["_ava"]] || 0,
          item[props.labM + ["_ava"]] || 0
        )
      )
    );

    return (
      <ResponsiveContainer
        width="100%"
        height={500 + props.dataChartT.length * 10}
      >
        <BarChart
          data={props.dataChartT}
          layout="vertical"
          margin={{ top: 0, right: 20, left: 80, bottom: 20 }}
          barCategoryGap={2.5} // Adjust the gap between categories
          barGap={1.5}
        >
          <defs>
            <linearGradient id="colorUv1" x1="1" y1="0" x2="0" y2="0">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv3" x1="1" y1="0" x2="0" y2="0">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            type="number"
            stroke="grey"
            position="insideBottom"
            label={{
              value: labels.injSur.yLabP,
              angle: 0,
              style: { fill: "grey" },
              dy: 15,
            }}
            domain={[0, maxValue + 1]}
          />
          <YAxis type="category" dataKey="patientName" stroke="grey" />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
          />
          <Legend wrapperStyle={{ color: "gray" }} verticalAlign="top" />
          <CartesianGrid
            stroke="#f5f5f530"
            vertical={true}
            horizontal={false}
          />
          <Bar
            type="monotone"
            dataKey={props.labT + ["_ava"]}
            fill="#9966ff90"
            stroke="#FFFFFF80"
          />
          <Bar
            type="monotone"
            dataKey={props.labM + ["_ava"]}
            fill="#ff668c80"
            stroke="#FFFFFF80"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={props.dataChartT}>
          <defs>
            <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.violet}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.surveillance.pink}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="groupDate" stroke="grey" />
          <YAxis
            allowDecimals={false}
            stroke="grey"
            label={{
              value: labels.injSur.yLabP,
              angle: -90,
              style: { fill: "grey" },
            }}
            labelOffset={-20}
          />
          <Tooltip
            contentStyle={{ backgroundColor: "black", color: "white" }}
            formatter={(value, name) => [value.toFixed(1), name]}
          />
          <Legend wrapperStyle={{ color: "gray" }} />
          <CartesianGrid stroke="#f5f5f530" vertical={false} />
          <Area
            type="monotone"
            dataKey={props.labT + ["_ava"]}
            stroke={theme.colors.surveillance.violet}
            fill="url(#colorUv1)"
          />
          <Area
            type="monotone"
            dataKey={props.labM + ["_ava"]}
            stroke={theme.colors.surveillance.pink}
            fill="url(#colorUv3)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
};

export default withSnackbar(TrainingReportA);
