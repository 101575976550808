import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getGroupsById } from "../../../models/actions/Groups";
import { labels } from "../../shared/translations";
import { rollbar } from "../../../utils/common";
import GroupEditSidebar from "../shared/GroupEditSidebar";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpLoader from "../../../components/atoms/SpLoader";
import { SpStepper, StepTitle } from "../../../components/bundles";
import { styled } from "../../../components/styled";
import SituationTable from "./SituationTable";
import DetailsSituation from "./DetailsSituation";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const DashboardSituation = ({ ...props }) => {
  const [currentGroup, setCurrentGroup] = useState([]);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { groupId } = useParams();
  const { errors, control } = useForm({
    shouldUnregister: false,
  });

  const StepperControls =
    (errors) =>
    ({ previous, next, index, isLastStep }) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      );

  const initialStep = 0;

  const titles = labels.group.situation.titles.map(({ key, value }) => (
    <StepTitle key={key}>{value}</StepTitle>
  ));
  const fetchData = async () => {
    try {
      //Obtain table data
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
    } catch (error) {
      setLoading(false);
      rollbar.error("SituationTable - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          container
          direction="row"
          style={{ margin: "auto 0", padding: 0 }}
        >
          <Grid item xs={2} style={{ margin: "auto 0", padding: 0 }}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ paddingTop: "2em" }}
          >
            <Grid item xs={12}>
              <SpStepper
                initialStep={initialStep}
                titles={titles}
                stepperControls={StepperControls(errors)}
              >
                <SituationTable control={control} />
                <DetailsSituation control={control} />
              </SpStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(DashboardSituation);
