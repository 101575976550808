import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withSnackbar } from "../../../../../components/atoms/SpSnackBar";
import SpText from "../../../../../components/atoms/SpText";
import { styled } from "../../../../../components/styled";
import { labels } from "../../../../shared/translations";
import { theme } from "../../../../../components/theme";
import SpLoader from "../../../../../components/atoms/SpLoader";
import SpCheckbox from "../../../../../components/atoms/SpCheckbox";
import { Box, Typography, IconButton, Paper } from "@material-ui/core";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import SpButton from "../../../../../components/atoms/SpButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SpIconButton from "../../../../../components/atoms/SpIconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

export const Repetition = ({
  item,
  index,
  exercise,
  setSchedaEsercizi,
  schedaEsercizi,
  id,
}) => {
  return (
    <Grid container direction="row" key={index}>
      <Grid item xs={12}>
        <Typography
          style={{
            flexGrow: 1,
            color: "white",
            fontWeight: "bold",
          }}
        >
          {`- ${labels.mylab.tempAdd.excerciseSheet.list.header.series} ${
            index + 1
          }`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          style={{
            width: "100%",
            paddingLeft: "1em",
          }}
        >
          <Grid item xs={6}>
            <SpText
              style={{
                paddingTop: "0.6em",
              }}
              variant={"textWhite"}
            >
              {`${labels.mylab.tempAdd.excerciseSheet.list.header.duration}`}
            </SpText>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              width: "100%",
            }}
          >
            <SpTextInput
              type="number"
              minValue={1}
              style={{
                width: "100%",
              }}
              onChange={(evnt) => {
                let newArray = [...exercise.repetition];
                newArray[index] = {
                  ...item,
                  repetition_time: evnt.target.value,
                };
                exercise.repetition = newArray;
                setSchedaEsercizi({
                  ...schedaEsercizi,
                  [id]: schedaEsercizi[id].map((ex) =>
                    ex.id_excercise === exercise.id_excercise
                      ? {
                          ...exercise,
                          repetition: newArray,
                        }
                      : ex
                  ),
                });
              }}
              value={item?.repetition_time}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          style={{
            width: "100%",
            paddingLeft: "1em",
          }}
        >
          <Grid item xs={6}>
            <SpText
              style={{
                paddingTop: "0.6em",
              }}
              variant={"textWhite"}
            >
              {`${labels.mylab.tempAdd.excerciseSheet.list.header.load}`}
            </SpText>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              width: "100%",
            }}
          >
            <SpTextInput
              type="number"
              minValue={1}
              style={{
                width: "100%",
              }}
              onChange={(evnt) => {
                let newArray = [...exercise.repetition];
                newArray[index] = {
                  ...item,
                  load: evnt.target.value,
                };
                exercise.repetition = newArray;
                setSchedaEsercizi({
                  ...schedaEsercizi,
                  [id]: schedaEsercizi[id].map((ex) =>
                    ex.id_excercise === exercise.id_excercise
                      ? {
                          ...exercise,
                          repetition: newArray,
                        }
                      : ex
                  ),
                });
              }}
              value={item?.load}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
