import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations.js";
import { extendMoment } from "moment-range";
import Moment from "moment";
const moment = extendMoment(Moment);
// Table data and formatting
export function TableComponent({ data }) {
  if (!data) {
    return <div>No data available</div>;
  }

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  const replaceNullWithEmptyString = (value) => (value === null ? "" : value);

  // Define column names and their corresponding labels
  const columnLabels = {
    playersName: labels.injSur.playersName,
    start_date: labels.injSur.start_date,
    end_date: labels.injSur.end_date,
    duration: labels.injSur.duration,
    severity: labels.injSur.severity,
    region: labels.injSur.region,
    structure: labels.injSur.structure,
    id_pathology: labels.injSur.patology,
    mechanism: labels.injSur.mechanism,
    action_type: labels.injSur.actType,
  };

  const columnsToRender = [
    "playersName",
    "start_date",
    "end_date",
    "duration",
    "severity",
    "region",
    "structure",
    "pathology",
    "mechanism",
    "action_type",
  ];

  // Iterate through each object and replace end_date property with formatted date
  data.forEach(function (obj) {
    obj.end_date = moment(obj.end_date).format("YYYY-MM-DD");
    obj.start_date = moment(obj.start_date).format("YYYY-MM-DD");
  });

  return (
    <Accordion
      style={{
        background: "transparent",
        width: "95vw",
        display: "block",
        padding: "2em",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#31caad" }} />}
        style={{ background: "transparent", border: "1px solid #31caad" }}
      >
        <SpText variant="text">Details</SpText>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ overflowX: "auto" }}>
          <table
            style={{
              color: "white",
              width: "90vw",
              tableLayout: "auto",
              borderSpacing: "0",
              borderCollapse: "separate",
              textAlign: "center",
            }}
          >
            <thead style={{ color: "#FFFF" }}>
              <tr>
                {columnsToRender.map((column) => (
                  <th key={column}>{columnLabels[column]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {columnsToRender.map((column, columnIndex) => (
                    <td key={columnIndex}>
                      {typeof item[column] === "object" &&
                      moment.isMoment(item[column])
                        ? item[column].format("YYYY-MM-DD")
                        : replaceNullWithEmptyString(item[column])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
