import { Avatar, Button as MuiButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  CloudUpload as MuiCloudUpload,
  DeleteForever,
} from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpIconButton from "../../components/atoms/SpIconButton";
import SpSearch from "../../components/atoms/SpSearch";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import {
  deleteProfessionalGroup,
  getGroupsById,
  updateGroup,
} from "../../models/actions/Groups";
import { getProfessionalById } from "../../models/actions/Professionals";
import { rollbar } from "../../utils/common";
import ProfessionalShareDialog from "../shared/dialogs/ProfessionalShareDialog";
import { labels } from "../shared/translations";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
}));

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "3%",
});
const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});
const rolesRows = [
  { id: 1, name: labels.groups.roles.admin, value: "admin" },
  { id: 2, name: labels.groups.roles.reader, value: "reader" },
];

const ProfessionalsGroup = (props) => {
  const [openProfessional, setOpenAddProfessional] = useState(false);
  const [openPatient, setOpenAddPatient] = useState(false);
  const [openDeleteProfessional, setOpenDeleteProfessional] = useState(false);
  const [openDeletePatient, setOpenDeletePatient] = useState(false);
  const [searchStringProf, setSearchStringProf] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [usersFound, setUsersFound] = useState([]);
  const [patientsList, setPatients] = useState([]);
  const [professionalsList, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [currentProfessionalRole, setCurrentProfessionalRole] = useState(null);
  const [professionalData, setProfessionalData] = useState({});
  const [currentGroup, setCurrentGroup] = useState([]);

  const { groupId } = useParams();
  const { setLoading } = props;

  const history = useHistory();

  const { reset, control, register, handleSubmit, getValues, setValue } =
    useForm({
      shouldUnregister: false,
    });

  const {
    fields: fieldsProf,
    append: appendProf,
    remove: removeProfessional,
  } = useFieldArray({
    control: control,
    key: "id",
    name: "professionals",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const ProfessionalProfile = await getProfessionalById();
      setProfessionalData(ProfessionalProfile);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);

      setPatients(groupResults.patients);
      setProfessionals(groupResults.professionals);
      const formattedProfessionalArray = [];
      groupResults.professionals.map((prof) => {
        if (prof.id == ProfessionalProfile.id)
          setCurrentProfessionalRole(prof.professional_group.role);
        formattedProfessionalArray.push({
          id: prof.id,
          professional: prof,
          role: prof.professional_group.role,
        });
      });
      reset({
        id: groupResults?.id,
        name: groupResults.name,
        description: groupResults.description,
        patients: groupResults.patients,
        professionals: formattedProfessionalArray,
      });
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateGroupData = async (data) => {
    try {
      const results = await updateGroup(data);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - updateGroupData", error);
    }
  };

  const addProfessionalToGroup = async (professional, selectValue) => {
    try {
      if (!selectValue) {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.selectRole
        );
        return;
      }
      if (
        fieldsProf.filter((prof) => prof?.id == professional?.id).length <= 0
      ) {
        appendProf({
          id: professional?.id,
          professional: professional,
          role: rolesRows[selectValue - 1].value,
        });
        setUsersFound([]);
        setSearchString(null);
        updateGroupData(getValues());
        setOpenAddProfessional(false);
      } else {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.professionalAlreadyExists
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - addProfessionalToGroup", error);
    }
  };

  const removeProfessionalFromServer = async (answer) => {
    try {
      if (answer) {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
            patients: patientsList,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      } else {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      }
      setOpenDeleteProfessional(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removeProfessionalFromServer", error);
    }
  };

  const resetStateDialog = () => {
    setUsersFound(null);
    setSearchString(null);
  };

  // Columns
  const headCellsProfessional = [
    {
      id: "professionalName",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.name,
    },
    {
      id: "professionalSurname",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.surname,
    },
    {
      id: "professionalEmail",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.email,
    },
    {
      id: "professionalRole",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.role,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.actions,
    },
  ];

  const filterProf = (e) => {
    setSearchStringProf(e.currentTarget.value);
    let newDisplayFilters = [];
    newDisplayFilters = professionalsList?.filter((professional) => {
      if (professional.name && professional.surname) {
        return `${professional.name ? professional.name : ""} ${
          professional.surname ? professional.surname : ""
        }`
          .toString()
          .trim()
          .toLowerCase()
          .includes(e.currentTarget.value.toString().trim().toLowerCase());
      } else {
        return `${professional.email ? professional.email : ""}`
          .toString()
          .trim()
          .toLowerCase()
          .includes(e.currentTarget.value.toString().trim().toLowerCase());
      }
    });
    setValue("professionals", newDisplayFilters);
    if (e.currentTarget.value === "")
      setValue("professionals", professionalsList);
  };

  const GroupAddProfessionalRow = ({
    row,
    rowKey,
    index,
    handleSelectedClick,
  }) => {
    let { professional, role } = row;
    if (!professional) {
      professional = row;
    }
    return (
      <>
        <SpTableRow role="checkbox" key={professional?.id} tabIndex={index}>
          <SpTableCell>
            <SpText variant="tableTextDetails">{professional?.name}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableTextDetails">{professional?.surname}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableTextDetails">
              {`${professional?.email ? professional?.email : ""}`}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableTextDetails">{role}</SpText>
          </SpTableCell>
          <SpTableCell align={"right"}>
            {professional?.id !== parseInt(professionalData.id) &&
              currentProfessionalRole == "admin" && (
                <SpIconButton
                  buttonType={"accept"}
                  onClick={() => {
                    setSelectedProfessional({
                      professional: professional ? professional?.id : row.id,
                      index: index,
                    });
                    setOpenDeleteProfessional(true);
                  }}
                >
                  <DeleteForever />
                </SpIconButton>
              )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid item xs={12} container spacing={1} direction="row">
            <form onSubmit={handleSubmit(updateGroupData)}>
              <Grid
                style={{ paddingLeft: "1%" }}
                direction="column"
                container
                spacing={2}
              >
                <Grid item xs={12} container>
                  <Grid container spacing={4} direction={"column"}>
                    <Grid item container spacing={2}>
                      <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item xs={4}>
                          <SpText variant="h1" id={"backButtonLista"}>
                            {
                              labels.groups.groupDetail.addProfessionalTable
                                .title
                            }
                          </SpText>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={8}
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "flex-end" }}
                        >
                          <SpButton
                            variant="h1PageTitle"
                            buttonType={"accept"}
                            disabled={currentProfessionalRole !== "admin"}
                            style={{ marginLeft: "5px" }}
                            text={
                              labels.groups.groupAdd.addProfessionalTable
                                .addProfessionalButton
                            }
                            onClick={() => setOpenAddProfessional(true)}
                          />
                          <SpSearch
                            style={{
                              marginLeft: "5px",
                              marginRight: 0,
                            }}
                            onKeyDown={prevent}
                            onChange={(e) => filterProf(e)}
                            value={searchStringProf}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <SpTable
                            style={{ minWidth: "100%" }}
                            headCells={headCellsProfessional}
                            rows={fieldsProf}
                            rowKey="id"
                            padding={false}
                            notCheckable={false}
                            tableContainerMaxHeight={
                              isMobile
                                ? "calc(100vh - 300px)"
                                : "calc(100vh - 320px)"
                            }
                          >
                            <GroupAddProfessionalRow />
                          </SpTable>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SpDialog
                style={{ padding: "1%" }}
                open={openPatient}
                setOpen={setOpenAddPatient}
                onCloseFunctions={resetStateDialog}
                title={labels.groups.groupDetail.searchPatient}
              >
                <Grid container direction="column">
                  <Grid
                    container
                    direction="row"
                    xs={12}
                    style={{ alignItems: "self-end", marginBottom: "10px" }}
                  >
                    <SpTextInput
                      value={searchString}
                      label={labels.patient.patientLink.inputSearch.label2}
                      onChange={(e) => setSearchString(e.currentTarget.value)}
                    />
                    <SpButton
                      text={
                        labels.patient.patientLink.inputSearch.buttons.search
                      }
                      style={{ marginLeft: "10px" }}
                      variant="none"
                      buttonType="accept"
                      onClick={() => searchPatients()}
                    />
                  </Grid>

                  <StyledWrapperPatientsList
                    item
                    container
                    direction="column"
                    xs={12}
                    style={{ marginBottom: "2%" }}
                  >
                    {usersFound ? (
                      usersFound.map((patientFound) => (
                        <StyledRowPatientFound
                          item
                          container
                          direction={"row"}
                          xs={12}
                          key={patientFound.id}
                        >
                          <Grid item xs={6} alignSelf={"center"}>
                            <SpText variant="text">{`${patientFound.givenName} ${patientFound.familyName} - ${patientFound.email}`}</SpText>
                          </Grid>
                          <Grid item xs={6}>
                            <SpButton
                              text={
                                labels.groups.groupAdd.addPatientTable.dialog
                                  .addProfessionalButton
                              }
                              variant="none"
                              style={{ width: "100%" }}
                              buttonType="accept"
                              onClick={() => addPatientToGroup(patientFound)}
                            />
                          </Grid>
                        </StyledRowPatientFound>
                      ))
                    ) : (
                      <SpText variant={"text"}>
                        {
                          labels.groups.groupAdd.addPatientTable.dialog
                            .usersFoundPlaceholder
                        }
                      </SpText>
                    )}
                  </StyledWrapperPatientsList>
                </Grid>
              </SpDialog>
              <ProfessionalShareDialog
                openProfessional={openProfessional}
                setOpenAddProfessional={setOpenAddProfessional}
                addCallback={addProfessionalToGroup}
                selectValues={rolesRows}
              />
            </form>
          </Grid>
        </Grid>
      </Grid>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deletePatientDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deletePatientDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={() => removePatientFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => removePatientFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteProfessional}
        setOpen={setOpenDeleteProfessional}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deleteProfessionalDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deleteProfessionalDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.yes}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.no}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(ProfessionalsGroup);
