import React from "react";
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { labels, psTranslate } from "../../../shared/translations";

const CustomTooltip = ({ active, payload, label }) => {
  try {
    if (active && payload?.length > 0) {
      const keysToShow = Object.keys(payload[0].payload).filter(
        (key) => key !== "Date"
      );
      const tooltipContent = keysToShow.map((key) => {
        return (
          <p
            key={`tooltip`}
            style={{ color: "#b8b6b6", margin: "5px 0", paddingLeft: "10px" }}
          >
            {psTranslate(key)}: {payload[0].payload[key]}
          </p>
        );
      });

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "#1f1f1f", padding: "10px" }}
        >
          <b style={{ color: "#ffffff" }}> {`Data: ${label}`}</b>
          {tooltipContent}
        </div>
      );
    }

    return null;
  } catch (error) {
    return null;
  }
};

const StackedAreaChart = ({ data, selP, features, general }) => {

  const domain =
    selP === 'HI'
      ? [0, 28]
      : ['Muscular ache', 'Fatigue level', 'Stress level', 'Sleep quality'].includes(selP)
      ? [0, 7]
      : undefined;

  data = data.slice(-28).map((entry) => entry);
  

  if (general === true) {
    return (
      <ResponsiveContainer width="100%" height={320}>
        <ComposedChart
          width={800}
          height={400}
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#ff0000" stopOpacity={0.4} />
              <stop offset="24%" stopColor="#ffa500" stopOpacity={0.4} />
              <stop offset="40%" stopColor="#1b9100" stopOpacity={0.4} />
              <stop offset="60%" stopColor="#1b9100" stopOpacity={0.5} />
              <stop offset="70%" stopColor="#1b9100" stopOpacity={0.7} />
            </linearGradient>
          </defs>

          <CartesianGrid
            stroke="#f5f5f5"
            strokeOpacity={0.1}
            fill="url(#colorUv)"
          />

          <XAxis dataKey="Date" tick={{ angle: -30, textAnchor: "end", dy: 5 }} />
          <YAxis
            yAxisId="left"
            domain={[0,28]}
            label={{
              value: 'Hopper index',
              angle: -90,
              position: 'Left',
              style: { fontSize: '20px', fill: '#FFFFFF' },
            }}
            width={80}
            orientation="left"
          />
          <YAxis
            yAxisId="right"
            label={{
              value: 'sRPE',
              angle: -90,
              position: 'Right',
              style: { fontSize: '20px', fill: '#FFFFFF' },
            }}
            width={80}
            orientation="right"
          />

          <Tooltip content={<CustomTooltip />} />

          <Legend verticalAlign="top" height={25} fontSize={25} />

          <Line
            type="monotone"
            dataKey={'HI'}
            stroke="#3dc5ff"
            strokeWidth={2}
            yAxisId="left"
            dot={{ fill: "#3dc5ff" }}
          />
          <Area
            type="monotone"
            dataKey={"Muscular ache"}
            stackId="1"
            stroke="#244353"
            fill="#31448a"
            yAxisId="left"
          />
          <Area
            type="monotone"
            dataKey={"Fatigue level"}
            stackId="1"
            stroke="#41545d"
            fill="#4d5d9b"
            yAxisId="left"
          />
          <Area
            type="monotone"
            dataKey={"Stress level"}
            stackId="1"
            stroke="#697479"
            fill="#7785bb"
            yAxisId="left"
          />
          <Area
            type="monotone"
            dataKey={"Sleep quality"}
            stackId="1"
            stroke="#848983"
            fill="#a6b1da"
            yAxisId="left"
          />

          <Line
            type="monotone"
            dataKey={'TL'}
            stroke="#ffffff"
            strokeWidth={2}
            yAxisId="right"
            dot={{ fill: "#3dc5ff" }}
          />

        </ComposedChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={320}>
        <ComposedChart
          width={800}
          height={400}
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#ff0000" stopOpacity={0.4} />
              <stop offset="24%" stopColor="#ffa500" stopOpacity={0.4} />
              <stop offset="40%" stopColor="#1b9100" stopOpacity={0.4} />
              <stop offset="60%" stopColor="#1b9100" stopOpacity={0.5} />
              <stop offset="70%" stopColor="#1b9100" stopOpacity={0.7} />
            </linearGradient>
          </defs>

          <CartesianGrid
            stroke="#f5f5f5"
            strokeOpacity={0.1}
            fill="url(#colorUv)"
          />

          <XAxis dataKey="Date" tick={{ angle: -30, textAnchor: "end", dy: 5 }} />
          <YAxis
            yAxisId="left"
            domain={domain}
            label={{
              value: selP,
              angle: -90,
              position: "Left",
              style: { fontSize: "20px", fill: "#FFFFFF" },
            }}
            width={80}
          />

          <Tooltip content={<CustomTooltip />} />

          <Legend verticalAlign="top" height={25} fontSize={25} />

          <Line
            type="monotone"
            dataKey={selP}
            stroke="#3dc5ff"
            strokeWidth={2}
            yAxisId="left"
            dot={{ fill: "#3dc5ff" }}
          />

        </ComposedChart>
      </ResponsiveContainer>
    );
  };
};

export default StackedAreaChart;
