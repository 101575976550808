import React, { useState } from "react";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import { Grid } from "@material-ui/core";
import SpButton from "../../../components/atoms/SpButton";
import PatientsAddSymbols from "../shared/PatientsAddSymbols";

const StyledColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const PatientsPresentationDetailBodyMap = (props) => {
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [isShowingUpdateSymbol, setIsShowingUpdateSymbol] = useState(false);
  const { setLoading } = props;

  const bodymap = (
    <StyledColumn>
      <Grid
        container
        spacing={3}
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Grid item xs={3}>
          <SpButton
            text={labels.general.update}
            buttonType="accept"
            onClick={() => setIsShowingUpdateSymbol(true)}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ minHeight: 500, marginBottom: "5%" }}>
          <Sp3DModel
            modelBehaviour="MARKER"
            type="PRESENTATION"
            setSelectedRegion={setSelectedRegion}
            setSelectedArea={setSelectedArea}
            selectedRegion={selectedRegion}
            selectedArea={selectedArea}
          />
        </Grid>
      </Grid>
    </StyledColumn>
  );

  const addSymbol = (
    <PatientsAddSymbols
      setLoading={setLoading}
      onBackPressed={() => setIsShowingUpdateSymbol(false)}
    />
  );

  return isShowingUpdateSymbol ? addSymbol : bodymap;
};

export default PatientsPresentationDetailBodyMap;
