import { Grid, makeStyles, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getProfessionalById,
  getResourceFromServer,
} from "../../../models/actions/Professionals";
import SpText from "../../../components/atoms/SpText";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { styled } from "../../../components/styled";
import { labels } from "../translations";
import LanguagesFlags from "../../../components/bundles/LanguagesFlags";
import { theme } from "../../../components/theme";

const TrialDiv = styled(Grid)({
  border: "1px yellow solid",
  borderRadius: "3px",
  paddingLeft: "5px",
  paddingRight: "5px",
});

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const Profile = () => {
  const [professionalData, setProfessionalData] = useState(null);
  const [profileImage, setProfileImg] = useState();

  const history = useHistory();
  const classes = useStyles();

  const fetchData = async () => {
    try {
      const response = await getProfessionalById();
      if (response.id_file) {
        const profileBase64 = await getResourceFromServer({
          id_file: response.id_file,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }
      setProfessionalData(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      container
      style={{ alignItems: "center", justifyContent: "flex-end" }}
    >
      {professionalData?.email && (
        <Grid
          id={"profileContainer"}
          container
          item
          xs={professionalData?.name && professionalData?.surname ? 12 : 1}
          direction="row"
          style={{ alignItems: "center" }}
        >
          {professionalData?.trial && (
            <TrialDiv>
              <SpText id={"trialText"} variant={"trialText"}>
                {labels.general.trialText}
              </SpText>
            </TrialDiv>
          )}
          <SpIconAndTextButton
            id={"profileIconButton"}
            isIconRight={true}
            text={
              professionalData?.name
                ? `${professionalData?.name} ${professionalData?.surname}`
                : ""
            }
            textVariant={"nameSurnameHeader"}
            onClick={() => history.push(`/account`)}
          >
            <Grid item style={{ padding: "3px" }}>
              <Avatar
                imgProps={{
                  style: {
                    width: "100%",
                    mHeight: "100%",
                    maxWidth: "100%",
                  },
                }}
                className={classes.largeAvatar}
                src={profileImage ? profileImage : ""}
              />
            </Grid>
          </SpIconAndTextButton>
          <LanguagesFlags />
        </Grid>
      )}
    </Grid>
  );
};

export default Profile;
