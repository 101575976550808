import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import {
  getAllExerciseCategories,
  getAllExercises,
  updateExerciseCategories,
} from "../../../models/actions/Excercise";
import { labels, psTranslate } from "../../shared/translations";
import ExerciseCategoryRow from "./Rows/Single/ExerciseCategoryRow";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddExerciseCategory = ({ setLoading, ...props }) => {
  const [exercises, setExercises] = useState([]);
  const [exerciseCategories, setExerciseCategories] = useState([]);

  const history = useHistory();

  const fetchData = async () => {
    try {
      const [cats, exs] = await Promise.all([
        getAllExerciseCategories(),
        getAllExercises(),
      ]);
      cats.forEach((cat) => {
        cat.id_exercises = cat.excercises.map((ex) => ex.id);
        delete cat.excercises;
      });
      exs.sort((a, b) =>
        psTranslate(a.name).localeCompare(psTranslate(b.name))
      );
      setExerciseCategories(cats);
      setExercises(exs);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addCategory = () => {
    const newCats = [...exerciseCategories];
    newCats.push({
      id: nanoid(),
      name: "",
      id_exercises: [],
      isNew: true,
    });
    setExerciseCategories(newCats);
  };

  const saveCategories = async () => {
    setLoading(true);
    try {
      if (exerciseCategories.every((cat) => cat.name)) {
        //Update all categories (only modified ones)
        exerciseCategories.forEach((cat) => {
          if (cat.isNew) {
            delete cat.id;
            delete cat.isNew;
          }
        });
        await updateExerciseCategories({
          exercise_categories: exerciseCategories,
        });
        history.goBack();
      } else {
        props.snackbarShowErrorMessage(
          labels.mylab.tempDetail.exerciseCategory.requiredName
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      await fetchData();
    }
    setLoading(false);
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.goBack()}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {labels.mylab.tempDetail.exerciseCategory.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {/*Add button*/}
          <Grid
            item
            xs={4}
            alignItems={"center"}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              variant="none"
              style={{ marginLeft: "2%" }}
              text={labels.mylab.tempDetail.exerciseCategory.add}
              buttonType="accept"
              onClick={() => addCategory()}
            />
          </Grid>
          {/*Top create button*/}
          <Grid
            item
            xs={4}
            alignItems={"center"}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              variant="none"
              style={{ marginLeft: "2%" }}
              text={labels.mylab.tempDetail.exerciseCategory.save}
              buttonType="accept"
              onClick={() => saveCategories()}
            />
          </Grid>
        </Grid>

        {/*Data rows*/}
        {exerciseCategories.map((category, index) => (
          <ExerciseCategoryRow
            key={category.id}
            exercises={exercises}
            exerciseCategory={category}
            updateExerciseCategory={(key, value) => {
              exerciseCategories[index][key] = value;
              setExerciseCategories([...exerciseCategories]);
            }}
            removeExerciseCategory={() => {
              exerciseCategories.splice(index, 1);
              setExerciseCategories([...exerciseCategories]);
            }}
          />
        ))}
      </Grid>
    </>
  );
};

export default withSnackbar(AddExerciseCategory);
