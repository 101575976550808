import Grid from "@material-ui/core/Grid";
import React from "react";
import SpText from "../../../../components/atoms/SpText";
import { SpList } from "../../../../components/bundles/SpList";
import { styled } from "../../../../components/styled";
import { theme } from "../../../../components/theme";
import { labels } from "../../../shared/translations";
import CommonRow from "./Single/CommonRow";

import { nanoid } from "nanoid";

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const SupplementRows = ({ rows, updateRow }) => {
  rows = rows ?? [];
  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={12}
        container
        style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
      >
        <Grid item xs={12}>
          <SpList
            rows={rows}
            rowKey="id"
            checkable={false}
            deletable={false}
            HeaderRow={
              <StyledRowList container style={{ justifyContent: "flex-start" }}>
                {labels.mylab.tempAdd.supplement.list.header.map((head) => (
                  <Grid key={head} item xs={4}>
                    <SpText variant="listHeader">{head}</SpText>
                  </Grid>
                ))}
              </StyledRowList>
            }
          >
            <CommonRow
              //row and index comes from SpList
              updateRow={updateRow}
            />
          </SpList>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SupplementRows;
