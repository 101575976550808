import React from "react";
import { labels } from "../../../shared/translations";
import { barColors, barWithDimension } from "../../../../utils/common";

import {
  selectedGroupsInjury as selectedGroupsInjuryGeneral,
  selectPatientsInjury as selectPatientsInjuryGeneral,
  injuryGroupsByGravity as injuryGroupsByGravityGeneral,
  injuryPatientsByGravity as injuryPatientsByGravityGeneral,
  selectPatientsTissueTypeInjury as selectPatientsTissueTypeInjuryGeneral,
  injuryGroupsByTissueTypes as injuryGroupsByTissueTypesGeneral,
  createBarschartDataPoints,
} from "./GeneralInjuryFunction";

const reinjuryProportion =
  labels.analytics.injuryReport.graphs.reinjuryProportion;

export const filterByReinjury = (patients) => {
  // workaround per evitare di passare l'oggetto per riferimento
  let tempPatients = JSON.parse(JSON.stringify(patients));
  let tempPatientsFiltered = [];
  tempPatients.forEach((patient) => {
    let patientFiltered = patient;
    let injuriesFilteredByReinjury = [];
    patient?.injuries.forEach((injury) => {
      if (injury?.reinjury === 2) injuriesFilteredByReinjury.push(injury);
    });
    patientFiltered.injuries = injuriesFilteredByReinjury;
    tempPatientsFiltered.push(patientFiltered);
  });
  return tempPatientsFiltered;
};

export const injuryGroupsByGravity = (
  injuriesPatient,
  gravities,
  isAvarageEnabled
) => {
  let tempinjuriesPatient = injuriesPatient;
  tempinjuriesPatient.forEach((group) => {
    group.patients = filterByReinjury(group.patients);
  });
  return injuryGroupsByGravityGeneral(
    tempinjuriesPatient,
    gravities,
    isAvarageEnabled
  );
};
export const injuryPatientsByGravity = (selectedInjuryPatient, gravities) => {
  selectedInjuryPatient = filterByReinjury(selectedInjuryPatient);
  return injuryPatientsByGravityGeneral(selectedInjuryPatient, gravities);
};

export const selectPatientsInjury = (
  newPatientsSelected,
  regionSelected,
  areaSelected
) => {
  let tempnewPatientsSelected = filterByReinjury(newPatientsSelected);
  return selectPatientsInjuryGeneral(
    tempnewPatientsSelected,
    regionSelected,
    areaSelected
  );
};

export const selectedGroupsInjury = (
  newSelectedGroups,
  regionSelected,
  areaSelected
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByReinjury(group.patients);
  });

  return selectedGroupsInjuryGeneral(
    newSelectedGroups,
    regionSelected,
    areaSelected
  );
};

export const selectPatientsTissueTypeInjury = (
  selectedInjuryPatient,
  tissueTypes
) => {
  selectedInjuryPatient = filterByReinjury(selectedInjuryPatient);
  return selectPatientsTissueTypeInjuryGeneral(
    selectedInjuryPatient,
    tissueTypes
  );
};

export const injuryGroupsByTissueTypes = (
  injuriesPatient,
  tissueTypes,
  isAvarageEnabled
) => {
  injuriesPatient.forEach((group) => {
    group.patients = filterByReinjury(group.patients);
  });
  return injuryGroupsByTissueTypesGeneral(
    injuriesPatient,
    tissueTypes,
    isAvarageEnabled
  );
};

export const injuryPatientProportion = (selectedInjuryPatient) => {
  const barColorValueTemp = barColors();
  let bars = [];
  let resultArray = [];
  for (let patient of selectedInjuryPatient) {
    let patientFilteredByReinjury = filterByReinjury([patient]);
    let item = {};
    item["groupDate"] = patient.givenName + " " + patient.familyName;
    item[reinjuryProportion] =
      patient.injuries.length > 0
        ? (
            (patientFilteredByReinjury[0].injuries.length /
              patient.injuries.length) *
            100
          ).toFixed(1)
        : 0;
    resultArray.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      reinjuryProportion,
      barWithDimension(resultArray.length),
      "#008080"
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const injuryGroupsProportion = (injuriesPatient) => {
  const barColorValueTemp = barColors();

  let bars = [];
  let resultArray = [];
  for (let { group, name, patients } of injuriesPatient) {
    let item = {};
    item["totalReinjury"] = 0;
    item["total"] = 0;
    item["groupDate"] = group ? group.name : name;
    for (let patient of patients) {
      const tempPatient = JSON.parse(JSON.stringify(patient));
      let patientFilteredByReinjury = filterByReinjury([tempPatient]);
      item["totalReinjury"] += patientFilteredByReinjury[0].injuries.length;
      item["total"] += patient.injuries.length;
    }
    item[reinjuryProportion] =
      item["total"] > 0
        ? ((item["totalReinjury"] / item["total"]) * 100).toFixed(1)
        : 0;
    resultArray.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      reinjuryProportion,
      barWithDimension(resultArray.length),
      "#008080"
    )
  );
  return { dataChart: resultArray, barChart: bars };
};
