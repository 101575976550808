import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { theme } from "../theme";

export const fontFamilies = {
  roboto: "Roboto",
  robotoCondensed: "Roboto Condensed",
  montserrat: "Montserrat, sans-serif !important",
};

export const fontWeights = {
  regular: "fontWeightRegular",
  medium: "fontWeightMedium",
  bold: "fontWeightBold",
};

const variants = {
  pageTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1.75rem",
  },
  h1: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.75rem",
  },
  h2: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.3125rem",
  },
  h3: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: "1.125rem",
  },
  h4: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.125rem",
  },
  h4Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1.125rem",
  },
  h5: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "0.875rem",
  },
  h5Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.875rem",
  },
  h6: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "0.875rem",
  },
  h6BoldWhite: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "0.775rem",
  },
  text: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.875rem",
  },
  textPrint: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: "#000",
  },
  textWhite: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.875rem",
    color: "white",
  },
  textWhiteArchived: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.7rem",
    color: "white",
    lineHeight: 1,
  },
  textWhiteArchivedBold: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: "white",
    lineHeight: 1,
  },
  inputError: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    color: "#FF0000",
    fontSize: "0.875rem",
  },
  columnHeader: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: "0.875rem",
  },
  cardHeader: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: "1.125rem",
  },
  cardContent: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.regular,
    fontSize: "1.125rem",
  },
  formError: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.875rem",
    color: "red",
    marginTop: 0,
  },
  feRegistrationTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "2.5rem",
    marginTop: 0,
  },
  textButton: {
    cursor: "pointer",
    color: "white",
  },
  h1PageTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.75rem",
    color: "#FFF",
  },
  h1GroupTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "4.75rem",
    color: "#FFF",
  },
  h1PageTitleDialog: {
    fontFamily: fontFamilies.montserrat,
    fontWeight: fontWeights.medium,
    fontSize: "1.75rem",
    color: "#FFF",
  },
  h1PageTitleDialogColored: {
    fontFamily: fontFamilies.montserrat,
    fontWeight: fontWeights.medium,
    fontSize: "1.20rem",
    color: theme.colors.primary.lightBlue,
  },

  iconButtonVariant: {
    fontFamily: fontFamilies.montserrat,
    fontWeight: fontWeights.bold,
    color: "#FFF",
    fontSize: 14,
  },
  h1PageSubtitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.15rem",
    color: "#FFF",
  },
  trialText: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.medium,
    fontSize: "1rem",
    color: "#FF0",
  },
  nameSurnameHeader: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.normal,
    fontSize: "1.15rem",
    color: "#FFF",
    textAlign: "right",
  },
  h1PageSubtitleColored: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.15rem",
    color: theme.colors.primary.lightBlue,
  },
  h2PageSubtitleColored: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.5rem",
    color: theme.colors.primary.lightBlue,
  },
  h4ComponentLabel: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: "#FFF",
  },
  h4ComponentLabelRegular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1rem",
    color: "#FFF",
  },
  h4ComponentLabelPrint: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1.15rem",
    color: "#C0C0C0",
  },
  h4ComponentLabelColored: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: theme.colors.primary.lightBlue,
  },
  h4ComponentLabelError: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: "#FF0000",
  },
  h5ComponentLabelErrorRegular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "0.875rem",
    color: "#FF0000",
  },
  h4ComponentLabelCenter: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: "#FFF",
    textAlign: "center",
  },
  listHeader: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: theme.colors.primary.lightBlue,
  },
  listHeaderCenter: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: theme.colors.primary.lightBlue,
    textAlign: "center",
  },
  checkListLabel: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: "1rem",
    color: theme.colors.primary.lightBlue,
    paddingLeft: "0.5rem",
  },
  selectItem: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1rem",
    color: "black",
    "&:selected": {
      color: "white",
    },
    "&:focused": {
      color: "black",
    },
  },
  tableText: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1.2rem",
    color: "white",
  },
  tableTextDetails: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "1.1rem",
    color: "white",
  },
  monthYearCalendar: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: "24px",
    color: "#C0C0C0",
  },
};

const ellipsisStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const SpText = ({
  id,
  isIconButton,
  variant,
  textAlign,
  children,
  color,
  style,
  margin = 1,
  ellipsis,
  className,
  ...rest
}) => (
  <Typography
    id={id}
    {...rest}
    component="div"
    style={{ ...style, color: !color ? theme.colors.primary.lightBlue : color }}
  >
    <Box
      className={className}
      textAlign={(textAlign = textAlign ? textAlign : "left")}
      {...variants[isIconButton ? "iconButtonVariant" : variant]}
      style={ellipsis && ellipsisStyle}
    >
      {children}
    </Box>
  </Typography>
);

export default SpText;
