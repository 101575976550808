import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { theme } from "../../../../../components/theme";
import { styled } from "../../../../../components/styled";
import { Controller, useForm } from "react-hook-form";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../../components/atoms/SpSelect";
import { labels } from "../../../../shared/translations";

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const CommonRowTreatment = ({ row, index, updateRow, subactivities }) => {
  const { control } = useForm({
    shouldUnregister: false,
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: theme.colors.primary.lightBlue,
          }}
        />
      )}
      <StyledRowList
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
        spacing={1}
      >
        <Grid item xs={4}>
          <Controller
            render={(props) => (
              <SpSelect
                value={row?.activity}
                formControlWidth={"100%"}
                onChange={(evnt) =>
                  updateRow(index, "activity", evnt.target.value)
                }
                isPrint={false}
              >
                {subactivities.map(({ id, name, key }) => (
                  <SpSelectMenuItem key={id} value={name}>
                    {name}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
            )}
            defaultValue={""}
            name={`id_subactivity`}
            control={control}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            type="number"
            minValue={1}
            style={{ width: "100%", paddingTop: "0.5em" }}
            value={row?.duration}
            onChange={(evnt) => updateRow(index, "duration", evnt.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            style={{ width: "100%", paddingTop: "0.5em" }}
            value={row?.description}
            onChange={(evnt) =>
              updateRow(index, "description", evnt.target.value)
            }
          />
        </Grid>
      </StyledRowList>
    </div>
  );
};

export default CommonRowTreatment;
